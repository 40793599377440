import cn from 'classnames';
import { getPrettyFormattedUtcDate } from 'utils/dates';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { workerAccessRevokingCategoryOptions } from 'containers/worker/utils';
import { JobsiteBan, BanReasonFormData } from './types';

export const getFormInputs = ({ isSuspension }: { isSuspension: boolean }): TypedFormInputs<BanReasonFormData> => {
  return {
    workerName: {
      element: FormInputTypes.OdinField,
      label: 'Name',
      elementProps: { disabled: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    jobsiteName: {
      element: FormInputTypes.OdinField,
      label: 'Name',
      elementProps: { disabled: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    bannedAt: {
      element: FormInputTypes.OdinField,
      label: `Start of ${isSuspension ? 'suspension' : 'ban'}`,
      elementProps: {
        disabled: true,
        fieldType: 'date',
        inputMode: 'numeric',
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    banExpiresAt: {
      element: FormInputTypes.OdinField,
      label: 'Suspension ends on',
      isHidden: !isSuspension,
      elementProps: {
        disabled: true,
        fieldType: 'date',
        inputMode: 'numeric',
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    category: {
      element: FormInputTypes.OdinSelect,
      label: 'Category',
      elementProps: {
        disabled: true,
        options: workerAccessRevokingCategoryOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3, cn('sm:odin-col-start-1')],
    },
    approvedBy: {
      element: FormInputTypes.OdinField,
      label: 'Approved By',
      elementProps: { disabled: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    reason: {
      element: FormInputTypes.OdinTextAreaField,
      label: 'Reason',
      elementProps: { disabled: true },
      layout: GridColSpan.SpanFull,
    },
  };
};

export const getDefaultValues = (jobsiteBan: JobsiteBan): BanReasonFormData => {
  const { workerName, jobsiteName, jobsiteWorker } = jobsiteBan ?? {};
  const { bannedAt, banExpiresAt, banCategory, bannedReason, siteAccessChangeApprovedBy } = jobsiteWorker ?? {};

  return {
    workerName: workerName ?? '',
    jobsiteName: jobsiteName ?? '',
    bannedAt: getPrettyFormattedUtcDate(bannedAt),
    banExpiresAt: getPrettyFormattedUtcDate(banExpiresAt),
    category: workerAccessRevokingCategoryOptions.find((opt) => opt.value === banCategory) ?? null,
    approvedBy: siteAccessChangeApprovedBy ?? '',
    reason: bannedReason,
  };
};
