import classNames from 'classnames/bind';
import moment, { Moment } from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  DateRangePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  OrientationShape,
  SingleDatePicker,
} from 'react-dates';
import 'react-dates/initialize';
import { FormGroup } from 'reactstrap';
import { useUpdatableState } from 'utils';
import { getISOFormattedDate } from 'utils/dates';

import { OdinDatePickerProps } from './types';
import * as styles from './scss/odin-date-picker.scss';

const cx = classNames.bind(styles);

export function OdinDatePicker(props: OdinDatePickerProps): ReactElement {
  // Single and Range pickers have different signatures for the onFocusChange callback
  const [focusedInput, setFocusedInput] = useState(null);
  const [isFocused, setIsFocused] = useState(null);

  const {
    name,
    label,
    subLabel,
    error,
    onChange,
    onDatesChange,
    isRange = false,
    value = [],
    dates = [],
    disabled,
    minDate,
    maxDate,
    className,
  } = props;

  const areEqual = (a: Moment, b: Moment): boolean => (!a && !b) || a?.isSame(b);
  const [startDate, setStartDate] = useUpdatableState(value[0] ? moment(value[0]) : null, areEqual);
  const [endDate, setEndDate] = useUpdatableState(value[1] ? moment(value[1]) : null, areEqual);

  useEffect(() => {
    if (disabled) {
      if (onChange) {
        if (isRange) {
          onChange([getISOFormattedDate(moment()), getISOFormattedDate(moment())]);
        } else {
          onChange([getISOFormattedDate(moment())]);
        }
      }
      if (onDatesChange) {
        onDatesChange(moment(), moment());
      }
    }
    if (dates.length > 0) {
      if (onChange) {
        if (isRange) {
          onChange([dates[0], dates[1]]);
        } else {
          onChange([dates[0]]);
        }
      }
      if (onDatesChange) {
        onDatesChange(dates[0], dates[1]);
      }
    }
  }, []);

  useEffect(() => {
    if (!disabled && !focusedInput && (onDatesChange || (onChange && isRange)) && startDate && endDate) {
      if (onChange && isRange) {
        onChange([getISOFormattedDate(startDate), getISOFormattedDate(endDate)]);
      } else {
        onDatesChange(startDate, endDate);
      }
    }
  }, [focusedInput, startDate, endDate]);

  const isOutsideRange = (day: Moment): boolean => {
    if (minDate == null && maxDate == null) {
      return false;
    }

    if (minDate && maxDate) {
      return !isInclusivelyAfterDay(day, minDate) || isInclusivelyAfterDay(day, maxDate);
    }

    if (maxDate && isInclusivelyBeforeDay(day, maxDate)) {
      return false;
    }

    return !(minDate && isInclusivelyAfterDay(day, minDate));
  };

  const smallDevice = window.matchMedia('(max-width: 400px)').matches;
  const orientation: OrientationShape = smallDevice ? 'vertical' : 'horizontal';

  return (
    <FormGroup className={cx('odin-date-picker', className)}>
      {label ? (
        <label className="mb-0" htmlFor={name}>
          {label}
        </label>
      ) : null}
      {subLabel ? (
        <label className="d-block font-size-sm" htmlFor={name}>
          {subLabel}
        </label>
      ) : null}
      <div className="d-block">
        {isRange ? (
          <DateRangePicker
            hideKeyboardShortcutsPanel
            orientation={orientation}
            startDate={startDate}
            startDateId={`${name}-start`}
            endDate={endDate}
            endDateId={`${name}-end`}
            disabled={disabled}
            isOutsideRange={isOutsideRange}
            minimumNights={0}
            onDatesChange={({
              startDate: newStartDate,
              endDate: newEndDate,
            }: {
              startDate: Moment;
              endDate: Moment;
            }): void => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(newFocusedInput): void => {
              setFocusedInput(newFocusedInput);
            }}
          />
        ) : (
          <SingleDatePicker
            hideKeyboardShortcutsPanel
            orientation={orientation}
            date={value[0] ? moment(value[0]) : null}
            onDateChange={(date: Moment): void => {
              if (onChange) {
                onChange([getISOFormattedDate(date)]);
              }
              if (onDatesChange) {
                onDatesChange(date, undefined);
              }
            }}
            block
            focused={isFocused}
            isOutsideRange={isOutsideRange}
            onFocusChange={({ focused }): void => setIsFocused(focused)}
            id={name}
          />
        )}
      </div>
      {error ? <div className={cx('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div> : null}
    </FormGroup>
  );
}
