import {
  APIConnector,
  AutocompleteQueryConfig,
  AutocompleteResponseState,
  QueryConfig,
  RequestState,
  ResponseState,
} from '@odin-labs/components';
import { ApolloClient } from '@apollo/client';
import {
  GetRecentUserSearchQueriesDocument,
  GetRecentUserSearchQueriesQuery,
  GetRecentUserSearchQueriesQueryVariables,
  SearchQueryType,
  SearchWorkersFromSearchBoxDocument,
  SearchWorkersFromSearchBoxQuery,
  SearchWorkersFromSearchBoxQueryVariables,
} from 'apollo/generated/client-operations';
import { ApolloState } from 'apollo/types';
import { getFormattedPhoneNumber } from 'utils';
import { SearchWorkerResult, UserSearchQuery } from './types';
import {
  DEFAULT_AUTOCOMPLETE_MINIMUM_CHARACTERS,
  DEFAULT_AUTOCOMPLETE_RESULTS_PER_PAGE,
  DEFAULT_AUTOCOMPLETE_CURRENT_PAGE,
  DEFAULT_SUGGESTIONS_SIZE,
} from './utils';

interface OdinAPIConnectorArgs {
  client: ApolloClient<ApolloState>;
  onAutocompleteSearchCompleted: () => void;
}
/**
 * Implementation of the APIConnector class responsible for transforming results from the `searchWorkers` GQL query
 * into the shape required by the SearchBox component
 */
export class OdinAPIConnector implements APIConnector {
  constructor(
    args: OdinAPIConnectorArgs,
    private readonly client: ApolloClient<ApolloState> = args.client,
    private readonly onAutocompleteSearchCompleted: () => void = args.onAutocompleteSearchCompleted,
  ) {}

  private async getWorkerResults(searchTerm: string, limit: number, offset: number): Promise<SearchWorkerResult[]> {
    const { data } = await this.client.query<SearchWorkersFromSearchBoxQuery, SearchWorkersFromSearchBoxQueryVariables>(
      {
        query: SearchWorkersFromSearchBoxDocument,
        variables: {
          searchString: searchTerm,
          limit,
          offset,
        },
        fetchPolicy: 'no-cache',
      },
    );
    const workerResults = data?.searchWorkers?.results?.map<SearchWorkerResult>((searchWorkerResult) => {
      const { worker } = searchWorkerResult;
      const { workerId, quickCode, trade, jobTitle, unionAffiliation, profilePicture, contractorWorkers } = worker;
      const { firstName, lastName, email, phoneNumber } = worker.user.identity ?? {};
      const badgeId = contractorWorkers.edges[0]?.node.jobsiteWorkers.edges[0]?.node.stickerNumber?.toString();

      return {
        id: workerId,
        searchTerm,
        firstName,
        lastName,
        email,
        phone: getFormattedPhoneNumber(phoneNumber),
        quickCode,
        trade,
        tradeClass: jobTitle,
        unionAffiliation,
        badgeId,
        imageUrl: profilePicture?.downloadUrl,
        jobsitesInfo: contractorWorkers.edges.flatMap(({ node: cw }) => {
          return cw.jobsiteWorkers.edges.map(({ node: jw }) => {
            return {
              jobsiteWorkerId: jw.jobsiteWorkerId,
              isJobsiteAccessAllowed: jw.currentAccess?.isAllowed ?? false,
              jobsiteName: jw.jobsiteContractor.jobsite.name,
              contractorName: cw.contractor?.organization?.name,
            };
          });
        }),
      };
    });
    return workerResults ?? [];
  }

  private async getUserWorkerSearchQueries(count: number): Promise<UserSearchQuery[]> {
    const result = await this.client.query<GetRecentUserSearchQueriesQuery, GetRecentUserSearchQueriesQueryVariables>({
      query: GetRecentUserSearchQueriesDocument, // GET_USER_SEARCH_QUERIES,
      variables: {
        searchQueryType: SearchQueryType.Worker,
        count,
      },
      fetchPolicy: 'no-cache',
    });
    return result.data?.getRecentUserSearchQueries?.results ?? [];
  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  async onSearch(state: RequestState, queryConfig: QueryConfig): Promise<ResponseState> {
    // Return a dummy object, since this hook will not be actively used
    return {
      totalPages: 0,
      totalResults: 0,
      requestId: '',
      facets: {},
      pagingStart: 0,
      pagingEnd: 0,
      wasSearched: false,
      resultSearchTerm: '',
      results: [],
      rawResponse: {},
    };
  }

  async onAutocomplete(state: RequestState, queryConfig: AutocompleteQueryConfig): Promise<AutocompleteResponseState> {
    const { searchTerm } = state;
    const { results, suggestions } = queryConfig;

    let workerResults: SearchWorkerResult[] = [];
    let previousSearchSuggestions: UserSearchQuery[] = [];

    // If the searchTerm (i.e. the search box input field) is empty, the "autocomplete" behavior will be to return
    // no results, but provide a set of suggestions representing recent user search queries
    if (!searchTerm) {
      const count = suggestions?.size ?? DEFAULT_SUGGESTIONS_SIZE;
      previousSearchSuggestions = await this.getUserWorkerSearchQueries(count);
      this.onAutocompleteSearchCompleted?.();
    } else if (searchTerm.length >= DEFAULT_AUTOCOMPLETE_MINIMUM_CHARACTERS) {
      const limit = results?.resultsPerPage ?? DEFAULT_AUTOCOMPLETE_RESULTS_PER_PAGE;
      const offset = limit * ((results?.current ?? DEFAULT_AUTOCOMPLETE_CURRENT_PAGE) - 1);
      workerResults = await this.getWorkerResults(searchTerm, limit, offset);
      this.onAutocompleteSearchCompleted?.();
    }

    return {
      autocompletedResults: workerResults,
      autocompletedResultsRequestId: null,
      autocompletedSuggestions: {
        // @elastic/react-search-ui SearchBox expects `autocompletedSuggestions` to have a specific format
        // eslint-disable-next-line max-len
        // https://github.com/elastic/search-ui/blob/f5a7604ef3db93488e254724d293bf1c20115a8a/packages/react-search-ui/src/containers/SearchBox.tsx#L141
        previousSearchSuggestions: previousSearchSuggestions.map((s) => ({ suggestion: s.searchQuery })),
      },
      autocompletedSuggestionsRequestId: null,
    };
  }

  /* eslint-disable class-methods-use-this,@typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function */
  // Unused, but required by the class interface
  onResultClick(params: unknown): void {}

  // Unused, but required by the class interface
  onAutocompleteResultClick(params: unknown): void {}
  /* eslint-enable class-methods-use-this,@typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function */
}
