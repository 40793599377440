import React from 'react';
import { useHistory } from 'react-router';

import { classes } from './WorkerHeaderActions.style';
import { WorkerHeaderButton } from './workerHeaderButton/WorkerHeaderButton';
import { WorkerHeaderActionsProps } from './types';

export function WorkerHeaderActions({
  children,
  baseRoute,
}: React.PropsWithChildren<WorkerHeaderActionsProps>): React.ReactElement {
  const history = useHistory();

  const handlePrintClick = (): void => {
    window.open(`${baseRoute}/print`, '_blank');
  };

  const handleReloadClick = (): void => {
    window.location.reload();
  };

  return (
    <>
      <div data-test="worker-header-actions-left">
        <WorkerHeaderButton
          className={classes.headerBackButton}
          iconClassName="fal fa-arrow-left"
          onClick={(): void => history.goBack()}
        >
          <span className={classes.headerBackButtonText}>Back</span>
        </WorkerHeaderButton>
      </div>
      <div data-test="worker-header-actions-right" className={classes.headerActionsRightContainer}>
        <WorkerHeaderButton iconClassName="fal fa-redo" onClick={handleReloadClick} />
        <WorkerHeaderButton iconClassName="fal fa-print" onClick={handlePrintClick} />
        {children}
      </div>
    </>
  );
}
