import React from 'react';
import { ServerFile } from 'types';
import { DocumentKey } from 'containers/worker/utils';
import { getFormattedDate, getUserFullName } from 'utils';
import { GridField } from 'components/gridField';
import { DocumentFilePreview } from 'components/documentFilePreview';
import { JfsHistoricalJobsiteWorkerDocument } from 'containers/jobsiteFormSubmission/print/types';

export type WorkerReportDocumentProps = {
  jobsiteDocument: JfsHistoricalJobsiteWorkerDocument;
  singleItemPerRow: boolean;
};

export function WorkerReportDocument(props: WorkerReportDocumentProps): React.ReactElement {
  const { jobsiteDocument, singleItemPerRow } = props;
  const { jobsiteWorkerDocumentType, latestVersion } = jobsiteDocument ?? {};

  const { workerDocumentType } = jobsiteWorkerDocumentType ?? {};
  const { key: docTypeKey, name: docTypeName } = workerDocumentType;
  const isGenericDoc = docTypeKey === DocumentKey.Generic;
  const cardTypeKey = isGenericDoc ? 'card-type' : 'type';
  const fieldValues = (latestVersion?.additionalFieldValues ?? []).filter(Boolean);
  const issueDate = fieldValues.find(({ key }) => key === 'issue-date')?.value;
  const expirationDate = fieldValues.find(({ key }) => key === 'expiration-date')?.value;
  const stateIssued = fieldValues.find(({ key }) => key === 'state-issued')?.value;
  const cardType = fieldValues.find(({ key }): boolean => key === cardTypeKey)?.value;

  const issueDateValue = getFormattedDate(issueDate);
  const expirationDateValue = getFormattedDate(expirationDate);

  const { updatedBy, createdBy } = latestVersion?.objectHistory ?? {};
  const uploadedBy = getUserFullName(updatedBy ?? createdBy);

  return (
    <div className="odin-h-full odin-border odin-border-gray-200 odin-rounded-md odin-p-6 odin-space-y-6">
      <div>
        <h3 className="odin-mb-1">{docTypeName}</h3>
        <div className="odin-text-sm odin-text-odin-black">{cardType ?? <>&nbsp;</>}</div>
      </div>
      <div className="odin-grid odin-grid-cols-1 odin-gap-y-3 sm:odin-grid-cols-2 sm:odin-gap-x-3">
        {latestVersion?.files.map((file: ServerFile) => (
          <DocumentFilePreview key={file.fileId} file={file} singleItemPerRow={singleItemPerRow} />
        ))}
      </div>
      <div className="odin-grid odin-grid-cols-2 odin-gap-y-3 sm:odin-grid-cols-4 sm:odin-gap-x-3">
        <GridField suppressWhenNoValue label="Date Issued" value={issueDateValue} />
        <GridField suppressWhenNoValue label="State Issued" value={stateIssued} />
        <GridField suppressWhenNoValue label="Expiration" value={expirationDateValue} />
        <GridField suppressWhenNoValue label="Uploaded by" value={uploadedBy} />
      </div>
    </div>
  );
}
