import React from 'react';

export const ClearanceEditingNotAvailableContent = React.forwardRef(
  (props, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    return (
      <div ref={ref}>
        <h3 className="odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900 odin-mt-0">Not Available</h3>
        <p>This jobsite is not configured to allow changing the jobsite clearance assignments</p>
      </div>
    );
  },
);
