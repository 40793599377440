import html2canvas, { Options } from 'html2canvas';

const exportWhitelistClass = 'html2canvas-whitelist';

/**
 * This is for handling the bad performance due to the fact that the whole document is cloned.
 * source: https://gist.github.com/fds-github/51c362f904f8e10fe6c973fd558a1998
 * Based on https://gist.github.com/K-D-K/bc8030505956c0a1476c401dee01ea1d
 *     from https://github.com/niklasvh/html2canvas/issues/1284
 * usage: html2canvasPruned.html2canvas(element, options)
 */
export const html2canvasPruned = {
  html2canvas(element: HTMLElement, options?: Partial<Options>): Promise<HTMLCanvasElement> {
    this.addWhiteListClass(element);
    return html2canvas(element, {
      ...options,
      ignoreElements: (el): boolean => {
        const shouldIgnore = !el.classList.contains(exportWhitelistClass);
        return shouldIgnore;
      },
      onclone: (clonedDocument) => {
        this.copyStyles(clonedDocument);
        this.removeWhiteListClass(element); // Clean up; might cause problems if running multiple in parallel
      },
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isElementToSkip(element: Element): boolean {
    return false;
  },
  addWhiteListClass(element: HTMLElement): void {
    let { parentElement } = element;
    // traverse parents
    while (parentElement) {
      parentElement.classList.add(exportWhitelistClass);
      parentElement = parentElement.parentElement;
    }

    const addWhiteListClassForChildren = (el: Element, className: string): void => {
      if (!this.isElementToSkip(el)) {
        el.classList.add(className);
        if (el.children.length) {
          Array.from(el.children).forEach((childElement) => {
            addWhiteListClassForChildren(childElement, className);
          });
        }
      }
    };

    // traverse children
    addWhiteListClassForChildren(element, exportWhitelistClass);
  },
  removeWhiteListClass(element: HTMLElement): void {
    let { parentElement } = element;
    // traverse parents
    while (parentElement) {
      parentElement.classList.remove(exportWhitelistClass);
      parentElement = parentElement.parentElement;
    }

    const removeWhiteListClassForChildren = (el: Element): void => {
      el.classList.remove(exportWhitelistClass);
      if (el.children.length) {
        Array.from(el.children).forEach((childElement) => {
          removeWhiteListClassForChildren(childElement);
        });
      }
    };

    // traverse children
    removeWhiteListClassForChildren(element);
  },
  styleSheets: undefined as CSSStyleSheet[],
  getStyleSheets(): CSSStyleSheet[] {
    if (!this.styleSheets) {
      const fileNames = ['.css']; // Array of endings of names of css files that are needed
      this.styleSheets = Object.values(document.styleSheets).filter((sheet) => {
        const fileLocation = sheet.href;
        if (fileLocation) {
          const shouldKeep = !fileNames.every((fileName) => {
            return !fileLocation.endsWith(fileName);
          });
          return shouldKeep;
        }
        return false;
      });
    }
    return this.styleSheets;
  },
  copyStyles(destDocument: Document): void {
    const styleElement = destDocument.createElement('style');
    destDocument.body.appendChild(styleElement);

    // don't add styles for now because it slows the process
    // leave the injected html to handle the styling

    // const styleElementSheet = styleElement.sheet;
    // this.getStyleSheets().forEach((styleSheet) => {
    //   Array.from(styleSheet.cssRules).forEach((rule) => {
    //     styleElementSheet.insertRule(rule.cssText);
    //   });
    // });
  },
};
