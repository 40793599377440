import React from 'react';
import { useMutation } from '@apollo/client';
import {
  MutationRemoveCardFromWorkerArgs,
  Success,
  AppErrorCode,
  useReactivateWorkerCardMutation,
  WorkerCard,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { REMOVE_CARD_FROM_WORKER } from 'containers/worker/helpers/queries';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { getIcon } from 'utils/ui';
import { useIsMounted } from 'utils';
import { getFormDefaultValues, getFormInputs } from './ReactivateBadgeModalContent.forms';
import { ReactivateWorkerBadgeData, ReassignBadgeState, WorkerCardAlreadyExistsErrorExtensions } from './types';

export interface ReactivateBadgeModalContentProps {
  setOpen: () => void;
  onAction: (reassignBadgeState?: ReassignBadgeState) => void;
  workerCardId: string;
  workerCards: WorkerCard[];
}

const CheckIcon = getIcon('fal fa-check');

export function ReactivateBadgeModalContent(props: ReactivateBadgeModalContentProps): React.ReactElement {
  const { setOpen, onAction, workerCardId, workerCards } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [reactivateWorkerCard] = useReactivateWorkerCardMutation();

  const [removeCardFromWorker] = useMutation<Success, MutationRemoveCardFromWorkerArgs>(REMOVE_CARD_FROM_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      setIsFetching(false);
    },
  });

  const onSubmit: FormOnSubmit<ReactivateWorkerBadgeData> = async (data, event): Promise<void> => {
    if (isFetching) return;
    setIsFetching(true);

    const { badges } = data;

    const reactivateCardAndRemoveExisting = async (options?: { forceCreate?: boolean }): Promise<void> => {
      await reactivateWorkerCard({
        variables: {
          workerCardIds: [workerCardId],
          forceCreate: options?.forceCreate,
        },
      });

      const workerCardIdsToDeactivate = badges
        .filter(({ selected }) => selected)
        .map(({ workerCard }) => workerCard.workerCardId);

      if (workerCardIdsToDeactivate.length) {
        await removeCardFromWorker({
          variables: {
            workerCardIds: workerCardIdsToDeactivate,
          },
        });
      }
    };

    try {
      await reactivateCardAndRemoveExisting();
      AlertInstance.alert('tc', 'success', 'Success', 'Badge reactivated');
      onAction();
    } catch (error) {
      event.preventDefault();

      const workerCardAlreadyExistsError = getGraphQLErrorByCode<WorkerCardAlreadyExistsErrorExtensions>(
        error,
        AppErrorCode.WorkerCardAlreadyExists,
      );
      if (workerCardAlreadyExistsError) {
        const { workerCardInfo } = workerCardAlreadyExistsError.extensions ?? {};

        if (workerCardInfo) {
          onAction({
            workerCardInfo,
            reassignBadgeAction: async (): Promise<void> => {
              await reactivateCardAndRemoveExisting({ forceCreate: true });
            },
          });
        } else {
          AlertInstance.alert('tc', 'danger', 'Something went wrong!', workerCardAlreadyExistsError.message);
        }
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const formInputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getFormDefaultValues(workerCards), [workerCards]);

  return (
    <ModalFormContent
      setOpen={setOpen}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Reactivate Badge"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
