import React from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
  MutationRemoveCardFromWorkerArgs,
  Success,
  WorkerCardType,
  AppErrorCode,
  useAddCardToWorkerMutation,
  WorkerCard,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { REMOVE_CARD_FROM_WORKER } from 'containers/worker/helpers/queries';
import { useProximityCardFormatOptions } from 'containers/worker/helpers/useProximityCardFormatOptions';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { getIcon } from 'utils/ui';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { AlreadyExistingBadgeMessage } from 'components/alreadyExistingBadgeMessage';
import { getFormDefaultValues, getFormInputsHook } from './AssignBadgeModalContent.forms';
import { AssignBadgeFormData, ReassignBadgeState, WorkerCardAlreadyExistsErrorExtensions } from './types';

export interface AssignBadgeModalContentProps {
  setOpen: () => void;
  onAction: (reassignBadgeState?: ReassignBadgeState) => void;
  jobsiteId: string;
  workerId: string;
  workerCards: WorkerCard[];
  reactivateBadge: (workerCardId: string) => void;
}

const CheckIcon = getIcon('fal fa-check');

export function AssignBadgeModalContent(props: AssignBadgeModalContentProps): React.ReactElement {
  const { setOpen, onAction, reactivateBadge, jobsiteId, workerId, workerCards } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [addCardToWorker] = useAddCardToWorkerMutation();

  const [removeCardFromWorker] = useMutation<Success, MutationRemoveCardFromWorkerArgs>(REMOVE_CARD_FROM_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      setIsFetching(false);
    },
  });

  const onSubmit: FormOnSubmit<AssignBadgeFormData> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isFetching) return;
    setIsFetching(true);

    const { badgeNumber, proximityCardFormatId, isPermanentBadge, expirationDate, tempBadgeReason, badges } =
      nullifyEmptyFields(data);
    const isTemporary = !isPermanentBadge;

    const addCardToWorkerAndRemoveExisting = async (options?: { forceCreate?: boolean }): Promise<void> => {
      await addCardToWorker({
        variables: {
          jobsiteId,
          workerId,
          forceCreate: options?.forceCreate,
          workerCardInput: {
            cardNumber: badgeNumber?.replace(/^0+/g, ''),
            workerCardFormatId: proximityCardFormatId.value,
            isTemporary,
            startDate: moment().toDate(),
            endDate: isTemporary ? expirationDate.endOf('day').toDate() : null,
            cardIssueReason: isTemporary ? tempBadgeReason : null,
            type: WorkerCardType.Proximity,
          },
        },
      });

      const workerCardIdsToDeactivate = badges
        .filter(({ selected }) => selected)
        .map(({ workerCard }) => workerCard.workerCardId);

      if (workerCardIdsToDeactivate.length) {
        await removeCardFromWorker({
          variables: {
            workerCardIds: workerCardIdsToDeactivate,
          },
        });
      }
    };

    try {
      await addCardToWorkerAndRemoveExisting();
      AlertInstance.alert('tc', 'success', 'Success', 'Badge assigned');
      onAction();
    } catch (error) {
      event.preventDefault();

      const workerCardAlreadyExistsError = getGraphQLErrorByCode<WorkerCardAlreadyExistsErrorExtensions>(
        error,
        AppErrorCode.WorkerCardAlreadyExists,
      );
      if (workerCardAlreadyExistsError) {
        const { scope, workerCardInfo, canBeReactivated } = workerCardAlreadyExistsError.extensions ?? {};

        if (scope === 'onDifferentWorker') {
          onAction({
            workerCardInfo,
            reassignBadgeAction: async (): Promise<void> => {
              await addCardToWorkerAndRemoveExisting({ forceCreate: true });
            },
          });
        } else {
          const { workerCardId } = workerCardInfo?.[0] ?? {};
          formApi.setError('badgeNumber', {
            message: (
              <AlreadyExistingBadgeMessage
                canBeReactivated={canBeReactivated}
                errorMessage={workerCardAlreadyExistsError.message}
                onClick={(): void => reactivateBadge(workerCardId)}
              />
            ),
            shouldFocus: true,
          });
        }
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const {
    proximityCardFormatOptions,
    defaultProximityCardFormatOption,
    error: getJobsiteCardFormatsError,
  } = useProximityCardFormatOptions(jobsiteId);

  const formInputs = getFormInputsHook(proximityCardFormatOptions);
  const defaultValues = React.useMemo(
    () => getFormDefaultValues(workerCards, defaultProximityCardFormatOption),
    // remove workerCards from dependency so that `defaultValues` won't get recalculated
    // when `workerCards` are re-fetched after adding/removing cards
    [defaultProximityCardFormatOption],
  );

  return (
    <ModalFormContent
      setOpen={setOpen}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Assign Badge"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
      error={getJobsiteCardFormatsError}
    />
  );
}
