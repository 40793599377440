import React from 'react';
import { LoadingError } from 'components/loadingError';
import { AuthContext } from 'auth';
import { areFeatureFlagsEnabled } from './helpers/config';
import { useLDProvider } from './helpers/useLDProvider';

export function FeatureFlagsProvider({ children }: React.PropsWithChildren<unknown>): React.ReactElement {
  const { currentUser: user } = React.useContext(AuthContext);
  const LDProvider = useLDProvider(user);

  if (LDProvider) {
    return <LDProvider>{children}</LDProvider>;
  }

  if (user.userAccountId && areFeatureFlagsEnabled()) {
    return <LoadingError loading />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
