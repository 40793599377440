import React from 'react';
import { Badge, Checkbox } from '@odin-labs/components';
import { CardStatus } from 'apollo/generated/client-operations';
import { BadgeChecklistProps, BadgeSelection } from './types';
import { BadgeChecklistHeader } from './BadgeChecklistHeader';

export const BadgeChecklist = React.forwardRef(
  (props: BadgeChecklistProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value, onChange, disableSelection = false } = props;

    const onCheckChangeHandler = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { id: workerCardId, checked } = e.target;
        const newValue = value?.map<BadgeSelection>((badge) =>
          badge.workerCard.workerCardId === workerCardId ? { ...badge, selected: checked } : badge,
        );
        onChange?.(newValue);
      },
      [value, onChange],
    );

    return (
      !!value?.length && (
        <div ref={ref}>
          <BadgeChecklistHeader />
          <table className="odin-min-w-full odin-divide-y odin-divide-gray-300">
            <tbody className="odin-divide-y odin-divide-gray-200 odin-bg-white">
              {value.map(({ workerCard, selected }: BadgeSelection) => (
                <tr key={workerCard.workerCardId} className="odin-h-12">
                  <td className="odin-pl-3">
                    <Checkbox
                      id={workerCard.workerCardId}
                      name={workerCard.workerCardId}
                      label={workerCard.cardNumber}
                      checked={selected}
                      onChange={onCheckChangeHandler}
                      disabled={disableSelection}
                    />
                  </td>
                  <td>
                    <Badge
                      text={workerCard.cardStatus}
                      color={workerCard.cardStatus === CardStatus.Active ? 'green' : 'gray'}
                      size="lg"
                    />
                  </td>
                  <td className="odin-text-center">{workerCard.isTemporary ? 'Temporary' : 'Permanent'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    );
  },
);
