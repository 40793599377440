import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { FormOnSubmit, ModalForm } from 'components/form';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { getPrettyFormattedDate } from 'utils/dates';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { AddWorkerModalProps } from './types';
import { getDefaultValues, getFormInputsHook, AddWorkerFormData, useContractorsOptions } from './AddWorkerModal.forms';

export function AddWorkerModal(props: AddWorkerModalProps): ReactElement {
  const { isOpen, closeModal, defaultJobsiteId: defaultJobsiteIdProp } = props;

  const history = useHistory();

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const onSubmit: FormOnSubmit<AddWorkerFormData> = (data): void => {
    const { jobsiteId, contractorId, orientationDate } = data;
    history.push(
      `/jobsite/${jobsiteId.value}/contractor/${contractorId.value}/worker/add?${queryString.stringify({
        orientationDate: getPrettyFormattedDate(orientationDate),
      })}`,
    );
  };

  const jobsitesOptions = React.useMemo(() => getJobsitesOptions(jobsites), [jobsites]);
  const defaultJobsiteId = defaultJobsiteIdProp ?? (jobsites?.length === 1 ? jobsites[0].jobsiteId : null);
  const defaultContractorsOptions = useContractorsOptions(defaultJobsiteId);

  const formInputs = getFormInputsHook({ jobsitesOptions });

  const defaultValues = React.useMemo(
    () =>
      getDefaultValues({
        jobsitesOptions,
        jobsiteId: defaultJobsiteId,
        contractorsOptions: defaultContractorsOptions,
      }),
    [defaultJobsiteId, defaultContractorsOptions, jobsitesOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      title="Add worker"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Continue"
      loading={userJobsitesLoading}
      error={userJobsitesError}
    />
  );
}
