import React from 'react';
import { GridFieldProps } from './types';
import { classes } from './GridField.style';

export function GridField(props: GridFieldProps): JSX.Element {
  const { label, value, labelClassName, valueClassName, suppressWhenNoValue } = props;

  if (!value && suppressWhenNoValue) {
    return null;
  }

  return (
    <>
      <div className={classes.label(labelClassName)}>{label}</div>
      <div className={classes.value(valueClassName)}>{value}</div>
    </>
  );
}
