import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { LoadingError } from 'components/loadingError';
import { JobsiteFormSubmission, JfsWorkerWithHistoricalData } from 'containers/jobsiteFormSubmission/print/types';
import { useUpdateQueryStatus } from 'containers/jobsiteFormSubmission/print/useUpdateQueryStatus';
import { useWorkerData } from 'containers/worker/data/useWorkerData';
import { WorkerReportHeader } from './WorkerReportHeader';
import { WorkerReportTitle } from './WorkerReportTitle';
import { WorkerReportPersonalInfo } from './workerReportPersonalInfo';
import { WorkerReportAccessEvents } from './workerReportAccessEvents';
import { WorkerReportDocuments } from './WorkerReportDocuments';
import { WorkerReportJobsites } from './WorkerReportJobsites';
import { WorkerReportAlert } from './WorkerReportAlert';
import { WorkerReportMedicalDocuments } from './workerReportMedicalDocuments';

export const classes = {
  container: cn('odin-space-y-9'),
  headerContainer: cn('odin-space-y-9 print:odin-break-inside-avoid'),
};

export type WorkerReportProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  jfsWorker: JfsWorkerWithHistoricalData;
  reportDateTime: moment.Moment;
  reportDateTimeText: string;
};

export function WorkerReport(props: WorkerReportProps): React.ReactElement {
  const { jfsWorker, jobsiteFormSubmission, reportDateTime, reportDateTimeText } = props;
  const { objectHistory, historicalData } = jfsWorker;
  const { workerId } = historicalData.jobsiteWorkerAccess.jobsiteWorker.contractorWorker.worker;
  const { createdAt } = objectHistory;
  const { participantType } = jfsWorker.extraData;

  const { loading, error, worker, developerIds, jobsiteIds } = useWorkerData(workerId);

  useUpdateQueryStatus(`worker-${workerId}`, { loading, error });

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  const participantAdditionDateTimeText = moment(createdAt).format('MM/DD/YYYY - hh:mm a');
  const alertText = `The data below were retrieved on ${participantAdditionDateTimeText}. 
  Any subsequent changes are not reflected in this report.`;

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <WorkerReportHeader jobsiteFormSubmission={jobsiteFormSubmission} reportDateTimeText={reportDateTimeText} />
        <WorkerReportTitle worker={worker} participantType={participantType} />
        <WorkerReportPersonalInfo worker={worker} />
      </div>
      <WorkerReportAlert text={alertText} />
      <WorkerReportJobsites jfsWorker={jfsWorker} />
      <WorkerReportAccessEvents reportDateTime={reportDateTime} jfsWorker={jfsWorker} />
      <WorkerReportDocuments
        jfsWorker={jfsWorker}
        developerIds={developerIds}
        jobsiteIds={jobsiteIds}
        singleItemPerRow
      />
      <WorkerReportMedicalDocuments jfsWorker={jfsWorker} jobsiteIds={jobsiteIds} />
    </div>
  );
}
