import { DatePicker } from 'components/datePicker';
import { BaseModal } from 'components/modals';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { parsePageQueryParams, momentISODateFormatter } from 'utils';

import { UpdateModalProps } from './types';

export function UpdateDateModalContainer({
  setModalOpen,
  modalOpen = false,
  allowFutureDate = false,
  title = 'Change date',
  subtitle = 'Please select the date you want to see in the dashboard',
}: UpdateModalProps): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const { date } = parsePageQueryParams(location);
  const [datePickerValue, setDatePickerValue] = useState<string>(date ? date.format() : moment().format());

  const options = !allowFutureDate
    ? {
        maxDate: moment().format(),
        formatDate: (formatDate: Date): string => {
          return moment(formatDate).format('MM/DD/YYYY');
        },
      }
    : {
        formatDate: (formatDate: Date): string => {
          return moment(formatDate).format('MM/DD/YYYY');
        },
      };

  return (
    <BaseModal
      title={title}
      subtitle={subtitle}
      isOpen={modalOpen}
      actionText="Update"
      toggle={(): void => {
        if (setModalOpen) {
          setModalOpen(!modalOpen);
        }
      }}
      onCancel={(): void => {
        if (setModalOpen) {
          setModalOpen(false);
        }
      }}
      onAction={(): void => {
        if (setModalOpen) {
          setModalOpen(false);
        }
        history.push(`${history?.location?.pathname}?date=${moment(datePickerValue).format(momentISODateFormatter)}`);
      }}
    >
      <Row>
        <Col xs="12">
          <DatePicker
            name="chosenDate"
            label="Date"
            value={datePickerValue}
            options={options}
            onChange={(dates: Date[]): void => {
              setDatePickerValue(dates[0].toISOString());
            }}
          />
        </Col>
      </Row>
    </BaseModal>
  );
}
