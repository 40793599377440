import { RoutePage } from 'pages/RoutePage';
import { ExtendedRouteProps } from 'routes/types';
import { FacialRecognitionContainer } from 'containers/facialRecognition/FacialRecognitionContainer';

export const facialRecognitionRoutes: ExtendedRouteProps[] = [
  {
    component: RoutePage,
    path: '/facial-recognition/:workerId/:workerCardId',
    title: 'Facial Recognition',
    exact: true,
    container: FacialRecognitionContainer,
    meta: [
      {
        name: 'description',
        content: 'Facial Recognition',
      },
    ],
  },
];
