import React from 'react';
import cn from 'classnames';
import { MomentInput } from 'moment';

import { getISODateTimeToFormattedDate } from 'utils/dates';

export const classes = {
  siteAccessText: cn('odin-text-2xs odin-text-odin-dark-gray'),
};

export function SiteStatusText({ isActive, endDate }: { isActive: boolean; endDate: MomentInput }): React.ReactElement {
  if (isActive && endDate) {
    return (
      <p className={cn('odin-mb-0', classes.siteAccessText)}>
        Planned completion by {getISODateTimeToFormattedDate(endDate)}
      </p>
    );
  }
  return endDate ? (
    <p className={cn('odin-mb-0', classes.siteAccessText)}>Completed on {getISODateTimeToFormattedDate(endDate)}</p>
  ) : null;
}
