import { LanguageOption } from './types';

export const languageOptions: LanguageOption[] = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
  { label: 'Chinese (Simplified)', value: 'zhCN' },
  { label: 'Chinese (Traditional)', value: 'zhTW' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Polish', value: 'pl' },
  { label: 'Russian', value: 'ru' },
];
export const defaultLanguage = languageOptions[0];
