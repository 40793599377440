import { SelectOptionElement } from '@odin-labs/components';
import { SessionDisplayColumns } from 'apollo/generated/client-operations';

export type DeveloperForOption = { developerId: string; organization: { name: string } };
export type JobsiteForOption = { jobsiteId: string; name: string; parentId?: string };

export const getDevelopersOptions = (developers: DeveloperForOption[]): SelectOptionElement[] =>
  developers?.map(({ developerId: value, organization: { name: label } }) => ({ value, label }));

export const getJobsiteOptions = (jobsites: JobsiteForOption[]): SelectOptionElement[] =>
  jobsites?.map((jobsite) => ({
    value: jobsite.jobsiteId,
    label: jobsite.name,
  }));

export const getSessionDisplayColumnOptions = (): SelectOptionElement[] =>
  Object.keys(SessionDisplayColumns).map((label) => ({
    label,
    value: SessionDisplayColumns[label as keyof typeof SessionDisplayColumns]?.toString(),
  }));
