import React, { MouseEventHandler } from 'react';
import { classes } from './WorkerHeaderButton.style';

interface WorkerHeaderButtonProps {
  // eslint-disable-next-line react/require-default-props
  className?: string;
  // eslint-disable-next-line react/require-default-props
  onClick?: MouseEventHandler<HTMLButtonElement>;
  // eslint-disable-next-line react/require-default-props
  iconClassName?: string;
}

// eslint-disable-next-line react/function-component-definition
export const WorkerHeaderButton: React.FC<WorkerHeaderButtonProps> = ({
  children,
  className,
  iconClassName,
  onClick,
}) => (
  <button className={classes.headerActionItemButton(className)} onClick={onClick}>
    <i className={classes.headerActionItemIcon(iconClassName)} />
    {children}
  </button>
);
