import React from 'react';
import cn from 'classnames';

export const classes = {
  container: cn('odin-flex odin-bg-yellow-50 odin-border-l-4 odin-border-yellow-400 odin-p-4'),
  iconHolder: cn('odin-flex-shrink-0'),
  icon: (icon: string): string => cn(icon, 'odin-text-yellow-500', 'odin-text-base', 'odin-font-light', 'odin-mr-1.5'),
  contentHolder: cn('odin-ml-3'),
};

export type WorkerReportAlertProps = React.PropsWithChildren<{
  text?: string;
}>;

export function WorkerReportAlert(props: WorkerReportAlertProps): React.ReactElement {
  const { text, children } = props;
  return (
    <div className={classes.container}>
      <div className={classes.iconHolder}>
        <i className={classes.icon('fal fa-exclamation-circle')} aria-hidden="true" />
      </div>
      <div className={classes.contentHolder}>{text ?? children}</div>
    </div>
  );
}
