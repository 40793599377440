import React from 'react';
import { Alert } from '@odin-labs/components';
import { ReviewCompletedProfiledTabProps, SelfOnboardingStepKey } from 'containers/selfOnboarding/types';
import { getVisibleDocuments } from 'containers/selfOnboarding/helpers/utils';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { documentInputsConfig } from 'containers/selfOnboarding/steps/documentStep';

export function ReviewCompletedProfileDocumentsAlerts(props: ReviewCompletedProfiledTabProps): React.ReactElement {
  const { state, jobsiteInvitation, navigation, localize } = props;
  const { jobsiteContractor } = jobsiteInvitation ?? {};
  const { jobsite } = jobsiteContractor ?? {};
  const { documents } = state;
  const { goToStep } = navigation;

  const isDocumentMissing = (documentKey: string): boolean => !documents?.some(({ key }) => key === documentKey);
  const visibleDocuments = getVisibleDocuments(jobsite?.modules);
  const missingDocuments = Object.entries(documentInputsConfig)
    .filter(([docKey]) => visibleDocuments.includes(docKey) && isDocumentMissing(docKey))
    .map(([docKey, { missingCopy }]) => ({ docKey, missingCopy }));

  if (!missingDocuments.length) {
    return null;
  }

  return (
    <div className="odin-space-y-5">
      {missingDocuments.map(({ docKey, missingCopy }) => (
        <Alert
          key={docKey}
          text={localize(missingCopy)}
          actionText={localize(Copy.add)}
          onAction={(): void => goToStep(docKey as SelfOnboardingStepKey)}
        />
      ))}
    </div>
  );
}
