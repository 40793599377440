import { MobileBadgeContainer } from 'containers/mobileBadge/MobileBadgeContainer';
import { RoutePage } from 'pages/RoutePage';
import { ExtendedRouteProps } from 'routes/types';

export const mobileBadgeRoutes: ExtendedRouteProps[] = [
  {
    component: RoutePage,
    path: '/mobile-badge/:workerId/:workerCardId',
    title: 'Mobile-badge',
    exact: true,
    container: MobileBadgeContainer,
    meta: [
      {
        name: 'description',
        content: 'Mobile-badge',
      },
    ],
  },
];
