import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Tab } from '@odin-labs/components';
import { RoutedTabsPage } from './RoutedTabsPage';

export type RoutedTabsPagesProps = {
  baseRoute: string;
  tabs?: Tab[];
  className?: string;
  componentProps?: any;
};

export function RoutedTabsPages(props: React.PropsWithChildren<RoutedTabsPagesProps>): React.ReactElement {
  const { baseRoute, tabs, className, children, componentProps } = props;

  const getTabPages = (): React.ReactElement[] =>
    tabs
      ?.filter((tab) => tab.component)
      ?.map((tab) => (
        <RoutedTabsPage
          key={tab.name}
          exact
          path={tab.path}
          component={tab.component}
          componentProps={{ tab, ...componentProps }}
        />
      ));

  const childrenWithComponentProps =
    children &&
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { componentProps } as unknown);
      }
      return child;
    });

  return (
    <div className={className}>
      <Switch>
        {childrenWithComponentProps ?? getTabPages()}
        <Route path={`${baseRoute}/?*?`}>
          <Redirect to={`${tabs[0]?.path ?? ''}`} />
        </Route>
      </Switch>
    </div>
  );
}
