import React from 'react';
import { Dialog } from 'dialog';
import { getTempId } from 'utils';
import { JobsiteFormSubmissionWorkerEdit } from 'containers/jobsiteFormSubmission/components/formSubmissionEditableWorkers/types';
import { FormSubmissionWorkerEditSearch } from 'containers/jobsiteFormSubmission/components/formSubmissionWorkerEditSearch';
import { AddFormSubmissionWorkerModalProps } from './types';

export function AddFormSubmissionWorkerModal(props: AddFormSubmissionWorkerModalProps): React.ReactElement {
  const { isOpen, closeModal, onConfirm, jobsiteId, jobsiteFormSubmissionId, associationType } = props;

  return (
    <Dialog id="editFormSubmissionWorker" open={isOpen} setOpen={closeModal} title="Add Worker" actionsPanel={null}>
      <FormSubmissionWorkerEditSearch
        jobsiteFormSubmissionId={jobsiteFormSubmissionId}
        onSelected={(searchWorkerResult): void => {
          const { jobsitesInfo } = searchWorkerResult;
          const jobsiteWorker =
            jobsitesInfo.find((ji) => ji.jobsiteWorker.jobsiteContractor.jobsite.jobsiteId === jobsiteId)
              ?.jobsiteWorker ?? jobsitesInfo[0]?.jobsiteWorker;

          const jfsWorkerEdit: JobsiteFormSubmissionWorkerEdit = {
            id: searchWorkerResult.id,
            jobsiteWorker,
            jobsitesInfo: searchWorkerResult.jobsitesInfo,
            editType: 'create',
            searchWorkerResult,
            associationType,
          };

          onConfirm(
            {
              id: getTempId(),
              jobsiteWorker,
              changeType: 'created',
              jobsitesInfo: jfsWorkerEdit.jobsitesInfo,
              associationType: jfsWorkerEdit.associationType,
            },
            jfsWorkerEdit.editType,
          );
        }}
      />
    </Dialog>
  );
}
