import React from 'react';
import moment from 'moment';
import { evalJsCode, getUserFullName } from 'utils';
import { FormData, FormInput, TypedFormInputs, getFormInputsFromTyped } from 'components/form';
import { useGetAvailableJobsiteWorkersQuery } from 'apollo/generated/client-operations';
import {
  AvailableJobsiteWorkerOption,
  AvailableJobsiteWorkerOptions,
  CustomFormDependency,
  CustomFormInputs,
  EditFormData,
  FormContext,
  FormSubmissionWorkerAssociationType,
  JobsiteFeaturesModule,
  JobsiteFormSubmission,
  JobsiteModule,
  WatchedFieldConfig,
} from './types';

export const getFeaturesModule = (modules: JobsiteModule[]): JobsiteFeaturesModule =>
  modules?.find((m): m is JobsiteFeaturesModule => {
    return m.__typename === 'JobsiteFeaturesModule'; // eslint-disable-line no-underscore-dangle
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const installedModules = __webpack_module_cache__; // eslint-disable-line camelcase
const modulesCache: Record<string, unknown> = {};

function requireModule(moduleSpecifier: string): unknown {
  const moduleNames = Object.keys(installedModules);
  const modulePatterns = [
    `^./${moduleSpecifier}.tsx?$`,
    `^./${moduleSpecifier}/index.tsx?$`,
    `^../node_modules/${moduleSpecifier}.[jt]sx?$`,
    `^../node_modules/${moduleSpecifier}/index.[jt]sx?$`,
    `^../node_modules/${moduleSpecifier}/${moduleSpecifier}.[jt]sx?$`,
    `^../node_modules/${moduleSpecifier}/dist/index.[jt]s$`,
  ].map((pattern) => new RegExp(pattern));

  const moduleId = moduleNames.find((m) => modulePatterns.some((pattern) => m.match(pattern)));
  const installedModule = installedModules[moduleId]?.exports;
  if (!installedModule) {
    // eslint-disable-next-line no-console
    console.error(`Module "${moduleSpecifier}" with moduleId "${moduleSpecifier}" could not be resolved`);
  }
  return installedModule;
}

const resolveModule = (moduleSpecifier: string, moduleId: string): unknown => {
  if (modulesCache[moduleSpecifier]) {
    return modulesCache[moduleSpecifier];
  }

  if (moduleId && installedModules[moduleId]) {
    const installedModule = installedModules[moduleId]?.exports;
    modulesCache[moduleSpecifier] = installedModule;
    return installedModule;
  }

  const installedModule = requireModule(moduleSpecifier);
  modulesCache[moduleSpecifier] = installedModule;
  return installedModule;
};

const getDependencies = async (dependenciesExpressions: CustomFormDependency[]): Promise<Record<string, unknown>> => {
  const modules = Object.fromEntries(
    dependenciesExpressions.map(({ moduleSpecifier, moduleId }) => {
      return [moduleSpecifier, resolveModule(moduleSpecifier, moduleId)];
    }),
  );
  const dependenciesCode = [
    ...dependenciesExpressions.map(({ moduleSpecifier, importClause }) => {
      return `const ${importClause} = modules['${moduleSpecifier}'];`;
    }),
    `return {
      ${dependenciesExpressions
        .map(({ importClause }) => (importClause.includes('{') ? `...${importClause},` : `${importClause},`))
        .join('\n')}
    }`,
  ].join('\n');

  const dependencies = evalJsCode<Record<string, unknown>>(dependenciesCode, { type: 'code', modules });
  return Promise.resolve(dependencies);
};

export const useDependencies = (dependenciesExpressions: CustomFormDependency[]): Record<string, unknown> => {
  const [dependencies, setDependencies] = React.useState<Record<string, unknown>>();

  React.useEffect(() => {
    setDependencies(null);
    if (dependenciesExpressions) {
      getDependencies(dependenciesExpressions).then(setDependencies);
    }
  }, [dependenciesExpressions]);

  return dependencies;
};

export const useDynCtx = (formContent: any): Record<string, unknown> => {
  const { dynCtx: dynCtxExpression, dependencies: dependenciesExpressions } =
    (formContent?.modal.dynCtx ? formContent?.modal : formContent?.edit) ?? {};

  const dependencies = useDependencies(dependenciesExpressions);

  return React.useMemo(() => {
    return dynCtxExpression && dependencies && evalJsCode(dynCtxExpression, { ctx: { dependencies } });
  }, [dependencies, dynCtxExpression]);
};

export const useWatchedFields = <TFormData extends EditFormData | FormData>(
  watchedFieldsConfig: WatchedFieldConfig<CustomFormInputs<TFormData>>[],
  watched: Partial<CustomFormInputs<TFormData>>,
  evalContext: { ctx: FormContext<TFormData> },
): void => {
  const prevWatchedRef = React.useRef(watched);

  React.useEffect(() => {
    watchedFieldsConfig
      .filter(
        (item): item is Extract<WatchedFieldConfig<CustomFormInputs<TFormData>>, { field: string }> =>
          typeof item === 'object',
      )
      .forEach(({ field, action: actionExpression }) => {
        if (watched[field] !== prevWatchedRef.current?.[field]) {
          prevWatchedRef.current[field] = watched[field];
          if (actionExpression && evalContext) {
            evalJsCode(actionExpression, evalContext);
          }
        }
      });
  }, [...Object.values(watched)]);
};

type UseAvailableJobsiteWorkerOptionsArgs = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  jobsiteId?: string;
  skip?: boolean;
};

type UseAvailableJobsiteWorkerOptionsResult = {
  availableJobsiteWorkerOptions: AvailableJobsiteWorkerOptions;
  loading: boolean;
};

export const createAvailableJobsiteWorkerOptions = (args?: {
  jobsiteFormSubmission?: JobsiteFormSubmission;
  items?: AvailableJobsiteWorkerOption[];
}): AvailableJobsiteWorkerOptions => {
  const { jobsiteFormSubmission, items } = args ?? {};
  const availableOptionsByAssociationType: Record<string, AvailableJobsiteWorkerOption[]> = {};

  return {
    jobsiteFormSubmission,
    items,
    byAssociationType(associationType: FormSubmissionWorkerAssociationType): AvailableJobsiteWorkerOption[] {
      if (!availableOptionsByAssociationType[associationType] && items?.length) {
        const jfsWorkersMap = Object.fromEntries(
          jobsiteFormSubmission?.jobsiteWorkers.edges
            .filter(({ node }) => node.associationType === associationType)
            .map(({ node }) => [node.jobsiteWorker.jobsiteWorkerId, node]) ?? [],
        );
        availableOptionsByAssociationType[associationType] = items.map((opt): AvailableJobsiteWorkerOption => {
          const { id, extraData } = jfsWorkersMap[opt.jobsiteWorker.jobsiteWorkerId] ?? {};
          return { ...opt, associationType, id, extraData };
        });
      }
      return availableOptionsByAssociationType[associationType] ?? [];
    },
  };
};

export const useAvailableJobsiteWorkerOptions = (
  args: UseAvailableJobsiteWorkerOptionsArgs,
): UseAvailableJobsiteWorkerOptionsResult => {
  const { jobsiteFormSubmission, skip } = args;

  const { jobsiteForm, jobsiteContractors, startAt } = jobsiteFormSubmission ?? {};
  const jobsiteId = jobsiteForm?.jobsite.jobsiteId ?? args.jobsiteId;
  const contractorIds = jobsiteContractors?.edges.map(({ node }) => node.jobsiteContractor.contractor.contractorId);

  const { data, loading } = useGetAvailableJobsiteWorkersQuery({
    fetchPolicy: 'no-cache',
    skip: skip || !jobsiteId,
    variables: {
      jobsiteId,
      jobsiteJobsiteWorkersInput: {
        contractorIds,
        onSiteDate: startAt ? moment.utc(startAt).toDate() : null,
      },
    },
  });

  const availableJobsiteWorkerOptions = React.useMemo(() => {
    const items = data?.getJobsite.jobsiteWorkers.edges.map<AvailableJobsiteWorkerOption>(({ node: jw }) => ({
      value: jw.jobsiteWorkerId,
      label: getUserFullName(jw.contractorWorker.worker.user),
      jobsiteWorker: jw,
      id: undefined,
      associationType: undefined,
      extraData: undefined,
    }));
    return items && createAvailableJobsiteWorkerOptions({ jobsiteFormSubmission, items });
  }, [data, jobsiteFormSubmission]);

  return {
    loading,
    availableJobsiteWorkerOptions,
  };
};

/**
 * It converts a form inputs structure from a map to an array one.
 * @param formInputs Inputs disposed in a tree structure where the key is the name of the component.
 * @returns form inputs structured as arrays
 */
export const formInputsAsArray = <TFormData extends EditFormData | FormData>(
  formInputs: TypedFormInputs<TFormData>,
): FormInput<CustomFormInputs<TFormData>>[] => {
  const inputs = getFormInputsFromTyped(formInputs);
  return inputs?.map((input) => {
    const { children, ...restInput } = input;
    if (children) {
      return {
        ...restInput,
        children: formInputsAsArray(children as TypedFormInputs<TFormData>),
      };
    }
    return input;
  }) as unknown as FormInput<CustomFormInputs<TFormData>>[];
};
