import React from 'react';
import { FileType } from 'apollo/generated/client-operations';
import { InputFile, ServerFile, TransformableFile } from 'types';
import { FileExcelIcon, FilePdfIcon, FileVideoIcon } from 'components/icons';
import { getFileContent, useResettableState } from 'utils';
import { FilePreviewInfo, FilePreviewSize, FilePreviewState, Icon } from './types';
import { html2canvasPruned } from './html2canvasPruned';

export const isServerFile = (file: InputFile): file is ServerFile => file && !(file instanceof File);

const getNewFileName = (file: File, newExtension: string): string => file.name.replace(/\.hei[cf]$/, newExtension);

const imageType = 'jpeg';
const toType = `image/${imageType}`;
export const convertHeic = async <T extends File | Blob>(blob: T): Promise<T> => {
  // This module will only be loaded on the client side
  const heic2any = (await import('heic2any')).default;
  const conversionResult = await heic2any({ blob, toType });
  const imageBlob = Array.isArray(conversionResult) ? conversionResult[0] : conversionResult;
  return (
    blob instanceof File
      ? new File([imageBlob], getNewFileName(blob, `.${imageType}`), { type: toType, lastModified: blob.lastModified })
      : imageBlob
  ) as T;
};

export const getHeicFileUrl = async (blob: Blob): Promise<string> => {
  const imageBlob = await convertHeic(blob);
  return URL.createObjectURL(imageBlob);
};

const htmlHostWidth = 1020;
const htmlHostHeight = 1320;

export const getHtmlFilePreviewInfo = async (
  htmlText: string,
  size: FilePreviewSize,
): Promise<Pick<FilePreviewInfo, 'fileUrl' | 'fileImgStyle'>> => {
  const { width, height } = size ?? {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  const htmlHostElement = document.createElement('div');
  htmlHostElement.setAttribute(
    'style',
    `position:absolute; top:-99999px; width:${htmlHostWidth}px; height:${htmlHostHeight}px; overflow:hidden`,
  );
  htmlHostElement.innerHTML = htmlText;
  try {
    document.body.appendChild(htmlHostElement);
    // const scale = Math.min(width / htmlHostWidth, height / htmlHostHeight);
    const canvas = await html2canvasPruned.html2canvas(htmlHostElement); // , { scale, logging: true });

    return {
      fileUrl: canvas.toDataURL('image/png'),
      fileImgStyle: { objectFit: 'contain' },
    };
  } finally {
    document.body.removeChild(htmlHostElement);
  }
};

export const getFilePreviewInfo = async (file: InputFile, size: FilePreviewSize): Promise<FilePreviewInfo> => {
  let fileUrl: string;
  let fileIcon: Icon;
  let fileImgStyle: FilePreviewInfo['fileImgStyle'];
  if (isServerFile(file)) {
    switch (file.fileType) {
      case FileType.Image:
        if (file.downloadUrl.includes('.heic') || file.downloadUrl.includes('heif')) {
          const response = await fetch(file.downloadUrl);
          const heicBlob = await response.blob();
          fileUrl = await getHeicFileUrl(heicBlob);
        } else {
          fileUrl = file.downloadUrl;
        }
        break;
      case FileType.Pdf:
        if (file?.downloadUrl) {
          fileUrl = file.downloadUrl;
        } else {
          fileIcon = FilePdfIcon;
        }
        break;
      case FileType.Video:
        if (file?.downloadUrl) {
          fileUrl = file.downloadUrl;
        } else {
          fileIcon = FileVideoIcon;
        }
        break;
      case FileType.Xlxs:
        fileIcon = FileExcelIcon;
        break;
      case FileType.Html: {
        const response = await fetch(file.downloadUrl);
        const htmlText = await response.text();
        const htmlFilePreviewInfo = await getHtmlFilePreviewInfo(htmlText, size);
        fileUrl = htmlFilePreviewInfo.fileUrl;
        fileImgStyle = htmlFilePreviewInfo.fileImgStyle;
        break;
      }
      default:
    }
  } else if (file?.type) {
    const fileType = file.type;
    if (fileType.includes('heic') || fileType.includes('heif')) {
      fileUrl = await getHeicFileUrl(file);
    } else if (fileType.includes('html')) {
      const htmlText = await getFileContent(file);
      const htmlFilePreviewInfo = await getHtmlFilePreviewInfo(htmlText, size);
      fileUrl = htmlFilePreviewInfo.fileUrl;
      fileImgStyle = htmlFilePreviewInfo.fileImgStyle;
    } else if (fileType.includes('image') || fileType.includes('pdf') || fileType.includes('video')) {
      fileUrl = URL.createObjectURL(file);
    } else if (fileType.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      fileIcon = FileExcelIcon;
    }
  }

  return { fileUrl, fileIcon, fileImgStyle };
};

const initialState: FilePreviewState = { fileUrl: null, fileIcon: null, fileImgStyle: undefined, isLoading: true };
export const useFilePreview = (file: InputFile, size?: FilePreviewSize): FilePreviewState => {
  const {
    value: state,
    setValue: setState,
    resetValue: resetState,
  } = useResettableState<FilePreviewState>(initialState, initialState);

  React.useEffect(() => {
    if ((file as TransformableFile)?.isTransformationInProgress) return;
    if (!state.isLoading) resetState();
    getFilePreviewInfo(file, size).then((filePreviewInfo) => setState({ ...filePreviewInfo, isLoading: false }));
  }, [file]);

  return state;
};
