import React from 'react';
import { Modal, ModalProps } from '@odin-labs/components';
import { FormData, ModalForm, ModalFormProps } from 'components/form';
import { useLegacyState } from 'utils/useLegacyState';
import { DialogContextState, DialogId } from './types';
import { DialogContext } from './dialogContext';

export type DialogProviderState = Record<DialogId, ModalFormProps<FormData> | ModalProps>;
const initialState: DialogProviderState = {} as DialogProviderState;

/**
 * Use this component together with `FormDialog` component to display a modal nested form (react-hook-form)
 * (i.e. when needed to edit a form field with another form).
 * If nested directly without using theses 2 components, a submission on the child form
 * will trigger submissions on both child and parent forms.
 */
export function DialogProvider({ children }: React.PropsWithChildren<unknown>): React.ReactElement {
  const [formDialogs, setFormDialogs] = useLegacyState<DialogProviderState>(initialState);

  const setFormDialogProps = React.useCallback(
    (id: DialogId, dialogProps: ModalFormProps<FormData> | ModalProps): void => {
      setFormDialogs({ [id]: dialogProps });
    },
    [setFormDialogs],
  );

  const dialogContextState = React.useMemo(
    (): DialogContextState => ({ setDialogProps: setFormDialogProps }),
    [setFormDialogProps],
  );

  const isForm = (modalProps: ModalFormProps<FormData> | ModalProps): modalProps is ModalFormProps<FormData> => {
    return !!(modalProps as ModalFormProps<FormData>).inputs;
  };

  const renderDialogs = (): React.ReactElement[] => {
    return Object.entries(formDialogs).map(([id, modalProps]) => {
      if (isForm(modalProps)) {
        return <ModalForm key={id} {...modalProps} />;
      }
      return <Modal key={id} {...modalProps} />;
    });
  };

  return (
    <DialogContext.Provider value={dialogContextState}>
      {children}
      {renderDialogs()}
    </DialogContext.Provider>
  );
}
