import { ApolloError, QueryResult } from '@apollo/client';
import { GetJobsitesQuery } from 'apollo/generated/client-operations';
import { useSelectedJobsiteIds } from './useSelectedJobsiteIds';
import { useAvailableJobsites } from './useAvailableJobsites';

export type Jobsite = GetJobsitesQuery['getCurrentSession']['user']['jobsites']['edges'][number]['node'];

export type UseJobsitesSelectionResult = {
  /**
   * Available jobsites to select from.
   */
  availableJobsites: Jobsite[];
  selectedJobsites: Jobsite[];
  selectedJobsiteIds: string[];
  areAllJobsitesSelected: boolean;
  /**
   * Used to get the updated selectedJobsitesIds immediately, right after setSelectedJobsiteIds
   */
  getSelectedJobsiteIds: () => string[];
  setSelectedJobsiteIds: (jobsiteIds: string[]) => string[];
  loading: boolean;
  error: ApolloError;
  client: QueryResult['client'];
};

export function useJobsitesSelection(args?: { includeAllJobsites?: boolean }): UseJobsitesSelectionResult {
  const { includeAllJobsites } = args ?? {};
  const {
    selectedJobsiteIds,
    getSelectedJobsiteIds,
    setSelectedJobsiteIds,
    loading: selectedJobsiteIdsLoading = true,
    error: selectedJobsiteIdsError,
    client,
  } = useSelectedJobsiteIds();

  const {
    jobsites: availableJobsites,
    loading: userJobsitesLoading = true,
    error: userJobsitesError,
  } = useAvailableJobsites({ includeAllJobsites });

  const setValidSelectedJobsiteIds = (jobsiteIds: string[]): string[] => {
    const validSelectedJobsiteIds =
      (jobsiteIds && availableJobsites?.filter((j) => jobsiteIds.includes(j.jobsiteId)).map((j) => j.jobsiteId)) ??
      null;
    setSelectedJobsiteIds(validSelectedJobsiteIds);
    return validSelectedJobsiteIds;
  };

  const selectedJobsites =
    selectedJobsiteIds && availableJobsites?.filter(({ jobsiteId }) => selectedJobsiteIds?.includes(jobsiteId));

  const loading = selectedJobsiteIdsLoading || userJobsitesLoading;
  const error = selectedJobsiteIdsError || userJobsitesError;
  const areAllJobsitesSelected = selectedJobsites?.length && selectedJobsites.length === availableJobsites?.length;

  return {
    availableJobsites,
    selectedJobsites,
    selectedJobsiteIds,
    getSelectedJobsiteIds,
    areAllJobsitesSelected,
    setSelectedJobsiteIds: setValidSelectedJobsiteIds,
    loading,
    error,
    client,
  };
}
