import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, TabsHeader, TabsHeaderProps } from '@odin-labs/components';
import { RoutedTabsHeaderItem } from './RoutedTabsHeaderItem';

export function RoutedTabsHeader(props: TabsHeaderProps): React.ReactElement {
  const history = useHistory();
  const { tabs, currentTab = tabs?.[0], ...restProps } = props;

  const onTabChange = React.useCallback(
    (tab: Tab, isMobile: boolean) => {
      if (isMobile) {
        history.replace(tab.location);
      }
    },
    [history],
  );

  return (
    <TabsHeader
      tabs={tabs}
      currentTab={currentTab}
      {...restProps}
      headerItem={RoutedTabsHeaderItem}
      onChange={onTabChange}
    />
  );
}
