import React from 'react';
import { AvatarCell, AvatarCellComponentProps, AvatarSkeleton, Column } from '@odin-labs/components';
import { JobsiteFormSubmissionWorker } from 'containers/jobsiteFormSubmission/print/types';
import { getInitialsForUser, getUserFullName } from 'utils';

export type JobsiteFormSubmissionWorkerColumn = Column<JobsiteFormSubmissionWorker>;

export const getColumns = ({
  extraColumns,
}: {
  extraColumns: JobsiteFormSubmissionWorkerColumn[];
}): JobsiteFormSubmissionWorkerColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: (formSubmissionWorker: JobsiteFormSubmissionWorker): string =>
      getUserFullName(formSubmissionWorker.jobsiteWorker.contractorWorker.worker.user),
    Cell: AvatarCell,
    CellLoading: <AvatarSkeleton size="xs" hideDetails />,
    componentProps: (formSubmissionWorker: JobsiteFormSubmissionWorker): AvatarCellComponentProps => ({
      src: formSubmissionWorker.jobsiteWorker.contractorWorker.worker.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(formSubmissionWorker.jobsiteWorker.contractorWorker.worker.user),
      objectFit: 'cover',
      size: 'xs',
    }),
  },
  ...(extraColumns ?? []),
];
