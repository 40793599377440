import QRCode from 'react-qr-code';
import { Header } from 'components/header';
import { LoadingError } from 'components/loadingError';
import { FullLayoutNavbarContainer } from 'containers/fullLayoutNavbar';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import { Badge, Col, Container, Row } from 'reactstrap';
import { useGetPrintWorkerDataQuery } from 'apollo/generated/client-operations';
import { getFullNameForUser, getCurrentDomain } from 'utils';
import { WorkerDocuments } from './tabs/WorkerDocuments';
import { WorkerOverview } from './tabs/WorkerOverview';

export function WorkerPrintContainer(): ReactElement {
  const { workerId } = useParams<{ workerId: string }>();
  const location = useLocation();

  const {
    data: workerData,
    error: workerError,
    loading: workerLoading,
  } = useGetPrintWorkerDataQuery({
    variables: { workerId },
    fetchPolicy: 'no-cache',
  });

  const worker = workerData?.getWorker;
  const { jobsiteWorkers, contractorWorkers, workerComments } = React.useMemo(
    () => ({
      jobsiteWorkers: worker?.jobsiteWorkers.edges.map(({ node }) => node) ?? [],
      contractorWorkers: worker?.contractorWorkers.edges.map(({ node }) => node) ?? [],
      workerComments: worker?.workerComments.edges.map(({ node }) => node) ?? [],
    }),
    [worker],
  );

  if (workerError || workerLoading) {
    return <LoadingError loading={workerLoading} error={workerError} />;
  }

  const leftContent = (): ReactElement => {
    return (
      <div className="mb-2 mt-3">
        <Badge className="badge badge-soft-primary">{worker?.quickCode}</Badge>
        <QRCode className="odin-ml-4" value={`${getCurrentDomain()}/worker/${worker?.workerId}`} size={100} />
      </div>
    );
  };

  return (
    <>
      <FullLayoutNavbarContainer fixed="false" />
      <Container>
        <Row>
          <Col xs="12">
            <Header
              pretitle="Worker"
              title={getFullNameForUser(worker?.user)}
              currentPathname={location.pathname}
              leftContent={leftContent()}
            />
            <Helmet title={`ODIN - ${getFullNameForUser(worker?.user)} Worker Profile`} />
            <WorkerOverview
              loading={workerLoading}
              worker={worker}
              workerComments={workerComments}
              jobsiteWorkers={jobsiteWorkers}
              contractorWorkers={contractorWorkers}
            />
            <WorkerDocuments worker={worker} jobsiteWorkers={jobsiteWorkers} contractorWorkers={contractorWorkers} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
