import React from 'react';
import cn from 'classnames';
import type { InfoListProps } from './types';

const classes = {
  container: (withBorder: boolean, extraClasses: string): string =>
    cn(withBorder && 'odin-divide-y odin-divide-gray-200', extraClasses),
};

export function InfoList(props: InfoListProps): React.ReactElement {
  const { children, className, loading, noEdit, noPadding, noBorders, withBottomBorder, noLgResponsiveness } = props;
  const withBorders = !noBorders;
  const lastIndex = children.length - 1;

  return (
    <dl className={classes.container(withBorders, className)}>
      {children &&
        React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              loading: child.props.loading ?? loading,
              hideEditIcon: child.props.hideEditIcon ?? noEdit,
              noPadding: child.props.noPadding ?? noPadding,
              noLgResponsiveness: child.props.noLgResponsiveness ?? noLgResponsiveness,
              withBottomBorder:
                child.props.withBottomBorder ?? (withBorders && withBottomBorder && index === lastIndex),
            });
          }
          return child;
        })}
    </dl>
  );
}
