import React, { Fragment } from 'react';
import cn from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { AuthContext } from 'auth';
import logo from 'images/ODIN.svg';
import type { ReactElement } from 'react';
import { generatePath } from 'utils/generatePath';
import { SidebarProps } from './types';
import { LockIcon } from './utils';

export function MobileSidebar(props: SidebarProps): ReactElement {
  const { isSidebarOpen, closeSidebar, sidebarRoutes, getRouteSidebarProps, classes, appVersion } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  return (
    <Transition.Root show={isSidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="odin-fixed odin-inset-0 odin-flex odin-z-40 md:odin-hidden"
        open={isSidebarOpen}
        onClose={closeSidebar}
      >
        <Transition.Child
          as={Fragment}
          enter="odin-transition-opacity odin-ease-linear odin-duration-300"
          enterFrom="odin-opacity-0"
          enterTo="odin-opacity-100"
          leave="odin-transition-opacity odin-ease-linear odin-duration-300"
          leaveFrom="odin-opacity-100"
          leaveTo="odin-opacity-0"
        >
          {/* overlay */}
          <div
            className={cn(
              'odin-fixed odin-inset-0',
              'odin-bg-gray-900 odin-bg-opacity-50', // dark:odin-bg-opacity-80
              'odin-transition-opacity',
            )}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="odin-transition odin-ease-in-out odin-duration-300 odin-transform"
          enterFrom="odin--translate-x-full"
          enterTo="odin-translate-x-0"
          leave="odin-transition odin-ease-in-out odin-duration-300 odin-transform"
          leaveFrom="odin-translate-x-0"
          leaveTo="odin--translate-x-full"
        >
          <Dialog.Panel className={cn(classes.background, 'odin-relative odin-flex odin-flex-col odin-max-w-xs')}>
            <Transition.Child
              as={Fragment}
              enter="odin-ease-in-out odin-duration-300"
              enterFrom="odin-opacity-0"
              enterTo="odin-opacity-100"
              leave="odin-ease-in-out odin-duration-300"
              leaveFrom="odin-opacity-100"
              leaveTo="odin-opacity-0"
            >
              <div className="odin-absolute odin-top-0 odin-right-0 odin--mr-12 odin-pt-2">
                <button
                  className={cn(
                    'odin-ml-1',
                    'odin-flex',
                    'odin-items-center',
                    'odin-justify-center',
                    'odin-h-10',
                    'odin-w-10',
                    'odin-rounded-full',
                    'focus:odin-outline-none',
                    'focus:odin-ring-2',
                    'focus:odin-ring-inset',
                    'focus:odin-ring-white',
                  )}
                  onClick={closeSidebar}
                >
                  <span className="odin-sr-only">Close sidebar</span>
                  <XIcon className="odin-h-6 odin-w-6 odin-text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="odin-flex-1 odin-h-0 odin-pt-5 odin-pb-4 odin-px-6 odin-overflow-y-auto">
              <div className="odin-flex odin-items-center odin-shrink-0 odin-px-2">
                <img className="odin-h-6 odin-w-auto odin-m-2" src={logo} alt="ODIN" />
              </div>
              <nav className="odin-mt-5 odin-space-y-1">
                {sidebarRoutes.map((route): ReactElement => {
                  if (!route?.acl || user.hasAnyRole(route?.acl)) {
                    const { isLocked, isActive } = getRouteSidebarProps(route);

                    return (
                      <Link
                        key={route?.sidebar?.pathname}
                        to={generatePath(route?.sidebar?.pathname, {})}
                        onClick={closeSidebar}
                        className={cn(classes.item(isActive), 'odin-p-3 odin-text-base hover:odin-text-white')}
                      >
                        <i className={cn(route?.sidebar?.icon, 'odin-text-lg odin-h-4 odin-w-6 odin-shrink-0')} />
                        <span className="odin-ml-3">{route?.sidebar?.item}</span>
                        {isLocked && <LockIcon />}
                      </Link>
                    );
                  }
                  return null;
                })}
              </nav>
            </div>
            <div className="odin-flex odin-justify-center odin-px-6 odin-py-5 odin-text-white odin-opacity-60">
              <span className="odin-text-base">v{appVersion}</span>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
