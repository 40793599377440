import React from 'react';
import { Avatar } from '@odin-labs/components';
import { getInitialsForUser, getUserFullName } from 'utils';
import { getPrettyFormattedDateTime } from 'utils/dates';
import { FormSubmissionPrintViewProps } from 'containers/jobsiteFormSubmission/print/types';
import {
  FormSubmissionReportDocuments as ReportDocuments,
  FormSubmissionReportWorkers as ReportWorkers,
  FormSubmissionReportWorkforceStats as ReportWorkforceStats,
  FormSubmissionReportHeader as ReportHeader,
  FormSubmissionReportTitle as ReportTitle,
  FormSubmissionReportDetails as ReportDetails,
  FormSubmissionReportWeather as ReportWeather,
  FormSubmissionReportData as ReportData,
  FormSubmissionReportDataItem as ReportDataItem,
} from './components';

export function FormSubmissionPrintView(props: FormSubmissionPrintViewProps): React.ReactElement {
  const { jobsiteFormSubmission, config, loading } = props;
  const { objectHistory } = jobsiteFormSubmission ?? {};

  const showDocuments = !!jobsiteFormSubmission?.documents.edges.length;

  const lastUpdatedAt = objectHistory.updatedAt ?? objectHistory.createdAt;
  const lastUpdatedBy = objectHistory.updatedBy ?? objectHistory.createdBy;

  const userName = getUserFullName(lastUpdatedBy);
  const userInitials = getInitialsForUser(lastUpdatedBy);
  const { profilePictureCropped } = lastUpdatedBy?.worker ?? {};

  const historySectionItems: ReportDataItem[] = [
    {
      label: 'Last Updated By',
      text: (
        <div className="odin-flex odin-gap-x-3">
          <Avatar size="xs" src={profilePictureCropped?.downloadUrl} placeholder={userInitials} />
          <span>{userName}</span>
        </div>
      ),
    },
    { label: 'Last Updated Date', text: getPrettyFormattedDateTime(lastUpdatedAt) },
  ];

  return (
    <>
      <ReportHeader jobsiteFormSubmission={jobsiteFormSubmission} items={config?.header?.items} />
      <ReportTitle jobsiteFormSubmission={jobsiteFormSubmission} />
      <ReportDetails jobsiteFormSubmission={jobsiteFormSubmission} details={config?.details} />
      {config?.showWeatherInfo && <ReportWeather jobsiteFormSubmission={jobsiteFormSubmission} />}
      {config?.sections.map((section) => (
        <ReportData key={section.text} title={section.text} items={section.items} columns={section.columns} />
      ))}
      {config?.showWorkforceStats && (
        <ReportWorkforceStats jobsiteFormSubmission={jobsiteFormSubmission} loading={loading} />
      )}
      {config?.showWorkers && (
        <ReportWorkers jobsiteFormSubmission={jobsiteFormSubmission} workersConfig={config.workers} loading={loading} />
      )}
      {showDocuments && <ReportDocuments jobsiteFormSubmission={jobsiteFormSubmission} singleItemPerRow />}
      <ReportData title="History" items={historySectionItems} columns={2} />
    </>
  );
}
