import React from 'react';
// import cn from 'classnames';
import { Column } from '@odin-labs/components';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistoryFormat } from 'components/objectHistory/types';
import { EntityChange } from 'containers/entityChange/types';
import { ensureDistinctItems, ensureNonEmptyItems } from 'utils';
import { renderValue } from 'containers/entityChange/worker/tabs/tables';

// comments table columns
export type WorkerChangeColumn = Column<EntityChange>;

type Change = { new: unknown; old?: unknown; newText?: string; oldText?: string };
export type ChangeValues = Record<string, Change>;

export const getColumns = (changes: EntityChange[], layout: 'vertical' | 'horizontal'): WorkerChangeColumn[] => {
  const changedFields =
    layout === 'horizontal'
      ? ensureDistinctItems(
          changes.flatMap((change) => (change.values.values as Array<{ key: string }>).map((v) => v.key)),
        )
      : [];

  return ensureNonEmptyItems([
    {
      id: 'entityId',
      Header: 'Document Version Id',
      // cellClassName: cn('odin-whitespace-pre-line odin-py-2'),
      accessor: 'entityId',
    },
    {
      id: 'entityDbId',
      Header: 'Document Version Db Id',
      accessor: 'entityDbId',
    },
    {
      id: 'apiDbHandler',
      Header: 'Api Db Handler',
      accessor: 'apiDbHandler',
    },
    {
      id: 'changedBy',
      Header: 'Changed By',
      accessor: (workerChange: EntityChange): EntityChange => workerChange,
      Cell: ({ value }: { value: EntityChange }): React.ReactElement => (
        <ObjectHistory object={value} format={ObjectHistoryFormat.Name} />
      ),
    },
    {
      id: 'changedAt',
      Header: 'Changed At',
      accessor: (workerChange: EntityChange): EntityChange => workerChange,
      Cell: ({ value }: { value: EntityChange }): React.ReactElement => (
        <ObjectHistory object={value} format={ObjectHistoryFormat.Time} />
      ),
    },
    ...(layout === 'vertical'
      ? [
          {
            id: 'fieldName',
            Header: 'Field',
            accessor: 'fieldName',
          },
          {
            id: 'newValue',
            Header: 'New Value',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.new?.toString(), 'new');
            },
          },
          {
            id: 'oldValue',
            Header: 'Old Value',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.old?.toString(), 'old');
            },
          },
          {
            id: 'newText',
            Header: 'New Text',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.newText, 'new');
            },
          },
          {
            id: 'oldText',
            Header: 'Old Text',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.oldText, 'old');
            },
          },
        ]
      : []),
    ...changedFields.reduce((result, fieldName) => {
      result.push({
        id: fieldName,
        Header: fieldName,
        accessor: (workerChange: EntityChange): Change =>
          (workerChange.values.values as Array<{ key: string; value: Change }>).find((v) => v.key === fieldName)
            ?.value ?? ({} as Change),
        Cell: ({ value }: { value: Change }): React.ReactElement => {
          return (
            <div className="odin-flex odin-flex-col odin-gap-y-0.5">
              <div className="odin-bg-green-200">{value?.new}</div>
              <div className="odin-bg-red-200">{value?.old}</div>
            </div>
          );
        },
      });
      if (fieldName.endsWith('Id')) {
        result.push({
          id: fieldName.slice(0, -2),
          Header: fieldName.slice(0, -2),
          accessor: (workerChange: EntityChange): Change =>
            (workerChange.values.values as Array<{ key: string; value: Change }>).find((v) => v.key === fieldName)
              ?.value ?? ({} as Change),
          Cell: ({ value }: { value: Change }): React.ReactElement => {
            return (
              <div className="odin-flex odin-flex-col odin-gap-y-0.5">
                <div className="odin-bg-green-200">{value?.newText}</div>
                <div className="odin-bg-red-200">{value?.oldText}</div>
              </div>
            );
          },
        });
      }

      return result;
    }, []),
  ]);
};

export const getChangesByLayout = (changes: EntityChange[], layout: 'vertical' | 'horizontal'): EntityChange[] => {
  return layout === 'horizontal'
    ? changes
    : changes.flatMap((change) => {
        const changeValues = (change.values.values as Array<{ key: string; value: Change }>) ?? [];
        return changeValues.map(({ key: fieldName, value }) => ({
          ...change,
          fieldName,
          value,
        }));
      });
};
