import React from 'react';
import cn from 'classnames';
import ReactPlayer from 'react-player';
import { useDidUpdateEffect } from '@odin-labs/components';
import { setRefFactory } from 'components/utils';
import { PlayerProgress, PlayerProps } from './types';

type IOSVideoElement = {
  /** Indicates whether the video is displaying in fullscreen mode */
  webkitDisplayingFullscreen: boolean;
  /** Exits fullscreen mode. */
  webkitExitFullscreen: () => void;
};
const isFullScreenOnIOS = (videoElement: unknown): videoElement is IOSVideoElement => {
  return (videoElement as IOSVideoElement)?.webkitDisplayingFullscreen;
};

export const Player = React.forwardRef(
  (props: PlayerProps, ref: React.ForwardedRef<ReactPlayer>): React.ReactElement => {
    const { className, url, value, onChange } = props;
    const progressRef = React.useRef<PlayerProgress>({ played: value?.fraction ?? 0, playedSeconds: undefined });
    const innerRef = React.useRef<ReactPlayer>();
    const videoRef = React.useRef<HTMLVideoElement>();

    // update progressRef when value.fraction changes
    useDidUpdateEffect(() => {
      progressRef.current = { played: value?.fraction ?? 0, playedSeconds: undefined };
    }, [value?.fraction]);

    /** This handler disables fast-forward feature in the player */
    const onSeekingHandler: HTMLVideoElement['onseeking'] = React.useCallback((ev) => {
      const videoElement = ev.target as HTMLVideoElement;
      const { playedSeconds } = progressRef.current;
      if (videoElement.currentTime > playedSeconds) {
        videoElement.currentTime = progressRef.current.playedSeconds;
      }
    }, []);

    /** This handler prevents playback speed from being changed by the user within the player */
    const onRateChangeHandler: HTMLVideoElement['onratechange'] = React.useCallback((ev) => {
      const videoElement = ev.target as HTMLVideoElement;
      if (videoElement.playbackRate !== 1) {
        videoElement.playbackRate = 1;
      }
    }, []);

    const setInnerRef = React.useCallback(
      (reactPlayer: ReactPlayer): void => {
        if (!reactPlayer && videoRef.current) {
          // destroy internalPlayer
          videoRef.current.removeEventListener('seeking', onSeekingHandler);
          videoRef.current.removeEventListener('ratechange', onRateChangeHandler);
          videoRef.current = null;
        }
        innerRef.current = reactPlayer;
      },
      [onSeekingHandler, onRateChangeHandler, innerRef, videoRef],
    );

    const setRef = setRefFactory<ReactPlayer>({ innerRef: setInnerRef, outerRef: ref });

    const onReadyHandler = React.useCallback(
      (reactPlayer: ReactPlayer): void => {
        const internalPlayer = reactPlayer?.getInternalPlayer();
        if (internalPlayer instanceof HTMLVideoElement && videoRef.current !== internalPlayer) {
          videoRef.current = internalPlayer;
          // initialize internalPlayer
          if (value?.fraction) {
            reactPlayer.seekTo(value?.fraction, 'fraction');
            progressRef.current.playedSeconds = reactPlayer.getCurrentTime();
          }
          videoRef.current.addEventListener('seeking', onSeekingHandler);
          videoRef.current.addEventListener('ratechange', onRateChangeHandler);
        }
      },
      [value, videoRef],
    );

    const onProgressHandler: ReactPlayer['props']['onProgress'] = React.useCallback(
      (state) => {
        if (state.played > progressRef.current.played) {
          progressRef.current = state;
          const { played, playedSeconds } = state;
          onChange?.({ fraction: played, value: playedSeconds });
        }
      },
      [onChange],
    );

    const onEndedHandler = React.useCallback((): void => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (isFullScreenOnIOS(videoRef.current)) {
        videoRef.current.webkitExitFullscreen();
      }
    }, [document]);

    return (
      <div className={cn(className)}>
        <ReactPlayer
          ref={setRef}
          url={url}
          onReady={onReadyHandler}
          onProgress={onProgressHandler}
          onEnded={onEndedHandler}
          controls
          width="100%"
          height="100%"
        />
      </div>
    );
  },
);
