import React from 'react';
import { Redirect, useParams } from 'react-router';
import { useGetWorkerCardQuery, WorkerCardType } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';

export function QrContainer(): React.ReactElement {
  const { idNumber } = useParams<{ idNumber: string }>();

  const { data, loading, error } = useGetWorkerCardQuery({
    fetchPolicy: 'network-only',
    variables: {
      cardNumber: idNumber,
      cardType: WorkerCardType.QrCode,
      includeArchived: true,
    },
  });

  const workerId = data?.getWorkerCard.worker.workerId;
  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  if (workerId) {
    return <Redirect to={`/worker/${workerId}`} />;
  }

  return <LoadingError error="Worker could not be found" />;
}
