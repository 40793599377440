import { Font } from '@react-pdf/renderer';
import FontIBMPlexSansThin from 'fonts/ibmplexsans/fonts/IBMPlexSans-Thin.ttf';
import FontIBMPlexSansThinItalic from 'fonts/ibmplexsans/fonts/IBMPlexSans-ThinItalic.ttf';
import FontIBMPlexSansExtraLight from 'fonts/ibmplexsans/fonts/IBMPlexSans-ExtraLight.ttf';
import FontIBMPlexSansExtraLightItalic from 'fonts/ibmplexsans/fonts/IBMPlexSans-ExtraLightItalic.ttf';
import FontIBMPlexSansLight from 'fonts/ibmplexsans/fonts/IBMPlexSans-Light.ttf';
import FontIBMPlexSansLightItalic from 'fonts/ibmplexsans/fonts/IBMPlexSans-LightItalic.ttf';
import FontIBMPlexSansRegular from 'fonts/ibmplexsans/fonts/IBMPlexSans-Regular.ttf';
import FontIBMPlexSansItalic from 'fonts/ibmplexsans/fonts/IBMPlexSans-Italic.ttf';
import FontIBMPlexSansMedium from 'fonts/ibmplexsans/fonts/IBMPlexSans-Medium.ttf';
import FontIBMPlexSansMediumItalic from 'fonts/ibmplexsans/fonts/IBMPlexSans-MediumItalic.ttf';
import FontIBMPlexSansSemiBold from 'fonts/ibmplexsans/fonts/IBMPlexSans-SemiBold.ttf';
import FontIBMPlexSansSemiBoldItalic from 'fonts/ibmplexsans/fonts/IBMPlexSans-SemiBoldItalic.ttf';
import FontIBMPlexSansBold from 'fonts/ibmplexsans/fonts/IBMPlexSans-Bold.ttf';
import FontIBMPlexSansBoldItalic from 'fonts/ibmplexsans/fonts/IBMPlexSans-BoldItalic.ttf';

export type PdfImageData = {
  data: string;
  width: number;
  height: number;
};

export function getImageXmlData(element: Element): PdfImageData {
  if (element.nodeName === 'IMG') {
    const { src: data, naturalWidth: width, naturalHeight: height } = element as HTMLImageElement;
    return { data, width, height };
  }
  const serializer = new XMLSerializer();
  const elementAsString = serializer.serializeToString(element);
  const data = `data:image/svg+xml;base64,${window.btoa(elementAsString)}`;
  const { width, height } =
    element.nodeName === 'svg'
      ? {
          width: Number(element.getAttribute('width') ?? 0),
          height: Number(element.getAttribute('height') ?? 0),
        }
      : { width: element.clientWidth, height: element.clientHeight };
  return { data, width, height };
}

export async function getImagePngData(imgData: PdfImageData): Promise<string> {
  const { data: imgXmlData, width, height } = imgData;

  const canvas = document.createElement('canvas');
  canvas.setAttribute('style', 'display: none');
  document.body.appendChild(canvas);

  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('no canvas 2d context');
  }

  const img = document.createElement('img');
  img.src = imgXmlData;

  return new Promise<string>((resolve) => {
    img.onload = function load(): void {
      ctx.drawImage(img, 0, 0, width, height);
      const url = canvas.toDataURL('image/png');
      canvas.remove();
      resolve(url);
    };
  });
}

export async function getPdfImageData(element: Element): Promise<PdfImageData> {
  const imgXmlData = getImageXmlData(element);
  const { width, height } = imgXmlData;
  const data = await getImagePngData(imgXmlData);
  return { data, width, height };
}

export const registerPdfFonts = (): void => {
  Font.register({
    family: 'IBM Plex Sans',
    fonts: [
      {
        src: FontIBMPlexSansThin,
        fontWeight: 'thin',
        fontStyle: 'normal',
      },
      {
        src: FontIBMPlexSansThinItalic,
        fontWeight: 'thin',
        fontStyle: 'italic',
      },
      {
        src: FontIBMPlexSansExtraLight,
        fontWeight: 'ultralight',
        fontStyle: 'normal',
      },
      {
        src: FontIBMPlexSansExtraLightItalic,
        fontWeight: 'ultralight',
        fontStyle: 'italic',
      },
      {
        src: FontIBMPlexSansLight,
        fontWeight: 'light',
        fontStyle: 'normal',
      },
      {
        src: FontIBMPlexSansLightItalic,
        fontWeight: 'light',
        fontStyle: 'italic',
      },
      {
        src: FontIBMPlexSansRegular,
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      {
        src: FontIBMPlexSansItalic,
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
      {
        src: FontIBMPlexSansMedium,
        fontWeight: 'medium',
        fontStyle: 'normal',
      },
      {
        src: FontIBMPlexSansMediumItalic,
        fontWeight: 'medium',
        fontStyle: 'italic',
      },
      {
        src: FontIBMPlexSansSemiBold,
        fontWeight: 'semibold',
        fontStyle: 'normal',
      },
      {
        src: FontIBMPlexSansSemiBoldItalic,
        fontWeight: 'semibold',
        fontStyle: 'italic',
      },
      {
        src: FontIBMPlexSansBold,
        fontWeight: 'bold',
        fontStyle: 'normal',
      },
      {
        src: FontIBMPlexSansBoldItalic,
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
    ],
  });
};
