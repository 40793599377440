import React from 'react';
import { Route } from 'react-router-dom';

export type RouterTabsPageProps = React.ComponentProps<typeof Route> & {
  componentProps?: any;
};

export function RoutedTabsPage({
  component: TabComponent,
  componentProps,
  ...props
}: RouterTabsPageProps): React.ReactElement {
  return (
    <Route {...props}>
      <TabComponent {...componentProps} />
    </Route>
  );
}
