import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AuthContext } from 'auth';
import logo from 'images/ODIN.svg';
import type { ReactElement } from 'react';
import { generatePath } from 'utils/generatePath';
import { SidebarProps } from './types';
import { LockIcon } from './utils';

export function TabletSidebar(props: SidebarProps): ReactElement {
  const { sidebarRoutes, getRouteSidebarProps, classes, appVersion } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  return (
    <div className={cn(classes.background, 'odin-hidden md:odin-flex md:odin-flex-col xl:odin-hidden')}>
      <div className="odin-w-full odin-py-6 odin-flex odin-flex-col odin-grow odin-items-center odin-overflow-y-auto">
        <div className="odin-flex odin-items-center odin-shrink-0 odin-px-3">
          <img className="odin-h-6 odin-w-auto odin-m-2" src={logo} alt="ODIN" />
        </div>
        <nav className="odin-flex-1 odin-mt-5 odin-w-full odin-px-3 odin-space-y-1.5">
          {sidebarRoutes.map((route): ReactElement => {
            if (!route?.acl || user.hasAnyRole(route?.acl)) {
              const { isLocked, isActive } = getRouteSidebarProps(route);
              return (
                <Link
                  key={route?.sidebar?.pathname}
                  to={generatePath(route?.sidebar?.pathname, {})}
                  className={cn(
                    classes.item(isActive),
                    'odin-flex-col odin-px-1 odin-py-1.5 odin-text-sm',
                    'hover:odin-text-white',
                  )}
                >
                  <i
                    className={cn(
                      route?.sidebar?.icon,
                      'odin-text-xl odin-w-8 odin-h-8 !odin-flex odin-justify-center odin-items-center',
                    )}
                  />
                  <span className="odin-mt-1">{route?.sidebar?.item}</span>
                  {isLocked && <LockIcon />}
                </Link>
              );
            }
            return null;
          })}
        </nav>
      </div>
      <div className="odin-flex odin-justify-center odin-px-6 odin-py-5 odin-text-white odin-opacity-60">
        <span className="odin-text-2xs">v{appVersion}</span>
      </div>
    </div>
  );
}
