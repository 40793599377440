import React from 'react';
import cn from 'classnames';
import moment, { MomentInput } from 'moment';
import { WeatherConditions } from 'apollo/generated/client-operations';
import { WeatherItem } from './WeatherItem';

export type WeatherProps = {
  weatherConditions: WeatherConditions[];
  timeZone: string;
  startAt?: MomentInput;
  endAt?: MomentInput;
  wrap?: boolean;
};

const classes = {
  container: (wrap: boolean): string =>
    cn(
      'odin-flex odin-overflow-x-auto odin-gap-x-3 odin-gap-y-3',
      wrap ? 'odin-flex-wrap' : 'odin-pb-3', // bottom padding for scrollbar when not wrap
    ),
};

export const Weather = React.forwardRef<HTMLDivElement, WeatherProps>((props, ref): React.ReactElement => {
  const { weatherConditions, timeZone, startAt, endAt, wrap } = props;

  const filteredWeatherConditions =
    startAt && endAt
      ? weatherConditions.filter(({ timestamp }) => moment(timestamp).isBetween(startAt, endAt))
      : weatherConditions;

  return (
    <div ref={ref} className={classes.container(wrap)}>
      {filteredWeatherConditions?.map((weather) => (
        <WeatherItem key={new Date(weather.timestamp).getTime()} weather={weather} timeZone={timeZone} />
      ))}
    </div>
  );
});
