import React from 'react';
import { classes } from './FormSubmissionWorkerEditSearchInProgress.style';

const ROW_COUNT = 5;
const rows = [...Array(ROW_COUNT)].map((_, idx) => idx);

export function FormSubmissionWorkerEditSearchInProgress(): React.ReactElement {
  return (
    <div className={classes.inProgressListContainer}>
      <ul className={classes.inProgressList}>
        {rows.map((index) => (
          <li key={index} className="!odin-mx-0 !odin-px-0">
            <div className={classes.inProgressItemContainer}>
              <div className="odin-flex odin-animate-pulse odin-flex-row odin-items-center">
                <div className="odin-bg-gray-300 odin-w-10 odin-h-10 odin-rounded-full odin-shrink-0" />
                <div className="odin-flex odin-flex-col odin-space-y-1 odin-ml-4 odin-grow">
                  <div className="odin-bg-gray-300 odin-h-4 odin-rounded-md" />
                  <div className="odin-w-5/6 odin-bg-gray-300 odin-h-4 odin-rounded-md" />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
