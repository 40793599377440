import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { localizeFactory } from 'utils/localization';
import { isSsr } from 'utils';
import { FacialRecognitionHeader } from './FacialRecognitionHeader';
import { classes } from './FacialRecognitionContainer.style';
import { UserValidationStep, ProfilePhotoStep, ConfirmationStep } from './steps';
import { FacialRecognitionStep, FacialRecognitionStepComponent, StepStatusInfo } from './types';
import { defaultLanguage } from './utils';

const componentsByStep: Record<FacialRecognitionStep, FacialRecognitionStepComponent> = {
  [FacialRecognitionStep.UserValidation]: UserValidationStep,
  [FacialRecognitionStep.ProfilePhoto]: ProfilePhotoStep,
  [FacialRecognitionStep.ProfilePhotoConfirmation]: ConfirmationStep,
};

const pageNumberByStep: Record<FacialRecognitionStep, number> = {
  [FacialRecognitionStep.UserValidation]: 1,
  [FacialRecognitionStep.ProfilePhoto]: 2,
  [FacialRecognitionStep.ProfilePhotoConfirmation]: 3,
};

const getStepNumberInfo = (currentStep: FacialRecognitionStep): StepStatusInfo => {
  return {
    stepsCount: 4,
    currentStep: pageNumberByStep[currentStep],
  };
};

export function FacialRecognitionContainer(): ReactElement {
  const { workerId, workerCardId } = useParams<{ workerId: string; workerCardId: string }>();
  const [language, setLanguage] = useState(defaultLanguage);
  const [currentStep, setCurrentStep] = useState(FacialRecognitionStep.UserValidation);
  const localize = localizeFactory(language.value);

  if (isSsr()) {
    return null;
  }

  const renderStepContent = (): ReactElement => {
    const StepComponent: FacialRecognitionStepComponent = componentsByStep[currentStep];
    return (
      <StepComponent
        workerId={workerId}
        workerCardId={workerCardId}
        language={language.value}
        onLanguageChange={setLanguage}
        localize={localize}
        setCurrentStep={setCurrentStep}
        stepNumberInfo={getStepNumberInfo(currentStep)}
      />
    );
  };

  return (
    <div className={classes.fullContainer(false)}>
      <FacialRecognitionHeader
        language={language.value}
        onLanguageChange={setLanguage}
        localize={localize}
        canGoToPreviousStep={false}
      />
      <div className={classes.container}>
        <div className={classes.stepContainer}>{renderStepContent()}</div>
      </div>
    </div>
  );
}
