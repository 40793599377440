import cn from 'classnames';
import React, { ReactElement } from 'react';

export type StepInfoProps = React.PropsWithChildren<{
  showOnMobileOnly?: boolean;
}>;

export function StepInfo({ children, showOnMobileOnly }: StepInfoProps): ReactElement {
  return (
    <div
      className={cn(
        'odin-text-gray-900 odin-pr-0 print:odin-hidden sm:odin-pr-9',
        showOnMobileOnly && 'sm:odin-hidden',
      )}
    >
      {children}
    </div>
  );
}
