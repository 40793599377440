import React from 'react';
import cn from 'classnames';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons';
import { Avatar, getFaIcon } from '@odin-labs/components';

const BriefcaseIcon = getFaIcon({ icon: faBriefcase, className: cn('odin-text-odin-primary') });

export const classes = {
  titleSkeleton: cn(
    'odin-text-transparent odin-text-2xl',
    'odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
  ),
};

export function JobsiteTitle({
  name,
  addressCity,
  addressState,
  organizationName,
}: {
  name: string;
  addressCity?: string;
  addressState?: string;
  organizationName?: string;
}): React.ReactElement {
  const locationString = [addressCity, addressState].filter(Boolean).join(', ');
  return (
    <div className="odin-flex odin-gap-x-8">
      <Avatar icon={BriefcaseIcon} type="rounded" objectFit="cover" size="2xl" />
      <div className="odin-flex odin-flex-col odin-justify-around">
        <h1 className="odin-text-xl odin-font-bold odin-text-odin-black odin-mb-0 sm:odin-text-2xl sm:odin-truncate">
          {name ?? <span className={classes.titleSkeleton}>Loading Contractor Data</span>}
        </h1>
        <span>
          {locationString && (
            <span className="odin-text-sm">
              <span className="fal fa-map-marker-alt odin-text-base odin-text-odin-primary odin-mr-2" />
              {locationString}
            </span>
          )}
          {organizationName && (
            <span className="odin-text-sm odin-ml-4">
              <span className="fal fa-landmark odin-text-base odin-text-odin-primary odin-mr-2" />
              {organizationName}
            </span>
          )}
        </span>
      </div>
    </div>
  );
}
