import { gql } from '@apollo/client';

export type QueryGetSelectedJobsiteIdsResponse = {
  selectedJobsiteIds: string[];
};

export const GET_SELECTED_JOBSITE_IDS = gql`
  query GetSelectedJobsiteIds {
    selectedJobsiteIds @client
  }
`;

export type QueryGetAppEnvironmentResponse = {
  environment: string;
};

export const GET_APP_ENVIRONMENT = gql`
  query GetAppEnvironment {
    environment @client
  }
`;

export type QueryGetAppVersionResponse = {
  appVersion: string;
};

export const GET_APP_VERSION = gql`
  query GetAppVersion {
    appVersion @client
  }
`;
