import React from 'react';
import { faTrash, faWrench } from '@fortawesome/pro-light-svg-icons';
import {
  Checkbox,
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { EditableCCureClearance } from 'containers/jobsiteConfiguration/components/cCureClearances/modals';
import { JobsiteCCureClearance } from 'containers/jobsiteConfiguration/types';

export type CCureClearanceColumn = Column<JobsiteCCureClearance>;

const WrenchIcon = getFixedWidthFaIcon({ icon: faWrench });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

export const getColumns = ({
  openEditModal: openEditCCureClearanceModal,
  openDeleteModal: openDeleteCCureClearanceModal,
}: {
  openEditModal: (cCureClearance: EditableCCureClearance) => void;
  openDeleteModal: (cCureClearance: EditableCCureClearance) => void;
}): CCureClearanceColumn[] => [
  {
    id: 'environment',
    Header: 'Environment',
    accessor: (cCureClearance: JobsiteCCureClearance): string => cCureClearance?.cCureEnvironment.namespace,
  },
  {
    id: 'clearanceName',
    Header: 'C-Cure Clearance Name',
    accessor: 'name',
  },
  {
    id: 'isDefault',
    Header: 'Default',
    accessor: (cCureClearance: JobsiteCCureClearance): React.ReactElement => {
      if (!cCureClearance) return undefined;
      const { isDefault, cCureClearanceId } = cCureClearance ?? {};
      return <Checkbox id={`${cCureClearanceId}-default`} checked={isDefault} disabled />;
    },
  },
  {
    id: 'isSelectable',
    Header: 'Selectable',
    accessor: (cCureClearance: JobsiteCCureClearance): React.ReactElement => {
      if (!cCureClearance) return undefined;
      const { isSelectable, cCureClearanceId } = cCureClearance ?? {};
      return <Checkbox id={`${cCureClearanceId}-selectable`} checked={isSelectable} disabled />;
    },
  },
  {
    Header: '',
    id: 'actions',
    Cell: DropdownButtonCell,
    CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
    componentProps: (cCureClearance: EditableCCureClearance): DropdownButtonCellComponentProps => ({
      cellAlignment: 'right',
      menuItems: [
        {
          icon: WrenchIcon,
          text: 'Edit Clearance',
          theme: 'secondary',
          onClick: (): void => openEditCCureClearanceModal(cCureClearance),
        },
        {
          icon: TrashIcon,
          text: 'Delete Clearance',
          theme: 'danger',
          onClick: (): void => openDeleteCCureClearanceModal(cCureClearance),
        },
      ],
    }),
  },
];
