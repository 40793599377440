import React, { ReactElement, useEffect, useState } from 'react';
import { useAuthRedirectStorage, useReportingRedirectStorage } from 'auth/hooks';
import { LoadingError } from 'components/loadingError';
import { isPreAuthRoute } from 'routes';
import { useTrackDatadogRumUser } from 'utils/useTrackDatadogRumUser';
import {
  AuthUser,
  userIsAllowed,
  ObjectPermission,
  to,
  userHasRole,
  userHasAnyRole,
  userHasSomeRoleOtherThan,
} from 'acl';
import { isContractorMemberUser } from 'utils/contractorMemberUser';
import { UserRoleKey, useGetCurrentSessionLazyQuery } from 'apollo/generated/client-operations';
import { AuthContextState, AuthProviderProps } from './types';
import { AuthContext } from './authContext';

const pathsNotToRedirect = ['/', '/auth/login'];

export function AuthProvider({ children, authState }: AuthProviderProps): ReactElement {
  const [, setRedirect] = useAuthRedirectStorage();
  const [reportingRedirect, setReportingRedirect, clearReportingRedirect] = useReportingRedirectStorage();
  const [hasBadToken, setHasBadToken] = useState(false);
  const [isSessionInitialized, setIsSessionInitialized] = useState(false);

  const [fetchCurrentSession, { error: sessionError, data: sessionData }] = useGetCurrentSessionLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { includeComputedRoles: true },
  });

  React.useEffect(() => {
    authState?.initializeSessionFetcher({ fetchCurrentSession });
  }, [authState, fetchCurrentSession]);

  const getAuthContextState = (): AuthContextState => {
    const { user } = sessionData?.getCurrentSession ?? {};

    const currentUser: AuthUser = {
      ...user,
      isAllowed: undefined,
      hasRole: (aclRole): boolean => userHasRole(user, aclRole),
      hasAnyRole: (aclRoles): boolean => userHasAnyRole(user, aclRoles),
      hasSomeRoleOtherThan: (aclRoles): boolean => userHasSomeRoleOtherThan(user, aclRoles),
      isContractor: isContractorMemberUser(user),
      isAdmin: userHasRole(user, UserRoleKey.Admin),
    };

    Object.assign(currentUser, {
      isAllowed: <T extends to>(permission: T, ...objectId: T extends ObjectPermission ? [string] : []): boolean =>
        userIsAllowed(permission, currentUser, ...objectId),
    });

    return {
      authState,
      fetchCurrentSession,
      currentUser,
      currentSession: {
        ...sessionData?.getCurrentSession,
        user: currentUser,
      },
    };
  };

  const [authContextState, setAuthContextState] = React.useState<AuthContextState>(getAuthContextState());

  React.useEffect(() => {
    if (sessionData) {
      const newAuthContextState = getAuthContextState();
      setAuthContextState(newAuthContextState);
    }
  }, [authState, sessionData]);

  const { location } = window;
  const { pathname: locationPathName, search: locationSearch } = location;
  const urlParams = new URLSearchParams(locationSearch);
  const wasRedirected = locationPathName === '/auth/complete';

  // Check if user was sent by reporting app
  const reportingRedirectParam = urlParams.get('redirect');

  const shouldRedirectToLoginPage =
    !authState.getIsAuthenticated() && !isPreAuthRoute(locationPathName) && !wasRedirected;

  // User was previously redirected from reporting app to FA and back, we need to set the updated cookies
  // before sending them back to the reporting app
  const shouldReportingRedirect = wasRedirected && reportingRedirect.current != null;

  useEffect(() => {
    if (reportingRedirectParam != null) {
      setReportingRedirect(reportingRedirectParam);
    }

    if (shouldRedirectToLoginPage) {
      if (!pathsNotToRedirect.includes(locationPathName)) {
        setRedirect(locationPathName);
      }
      location.href = authState.getLoginUrl();
    } else if (wasRedirected) {
      const params = new URLSearchParams(locationSearch);
      const accessToken = params.get('accessToken');
      const refreshToken = params.get('refreshToken');

      // explicitly check if we got a nullish value as a query param from the server side.
      // usually this means the Web App and/or FusionAuth env vars are misconfigured.
      if (
        accessToken === 'null' ||
        accessToken === 'undefined' ||
        refreshToken === 'null' ||
        refreshToken === 'undefined'
      ) {
        setHasBadToken(true);
      } else {
        authState.signIn(accessToken, refreshToken);
      }
    }

    if (shouldReportingRedirect) {
      const redirectUrl = reportingRedirect.current;
      clearReportingRedirect();
      location.href = redirectUrl;
    }
  }, [authState]);

  useEffect(() => {
    const accessToken = authState.getAccessToken();
    if (accessToken != null && accessToken !== 'null') {
      fetchCurrentSession();
    } else {
      setIsSessionInitialized(true);
    }
  }, [authState]);

  useEffect(() => {
    if (sessionData !== undefined) {
      setIsSessionInitialized(true);
    }
  }, [sessionData]);

  useTrackDatadogRumUser(authContextState);

  if (hasBadToken) {
    return <LoadingError error="Received an invalid token when redirected back from FusionAuth" />;
  }

  const sessionDataLoading = !isSessionInitialized;
  if (sessionDataLoading || sessionError) {
    return <LoadingError error={sessionError} loading={sessionDataLoading} />;
  }

  const userIsNotAuthenticated = !authState.getIsAuthenticated();
  const thereIsNoValidSession = !authContextState.currentSession.session;
  if ((userIsNotAuthenticated || thereIsNoValidSession) && !isPreAuthRoute(locationPathName)) {
    return null;
  }

  return <AuthContext.Provider value={authContextState}>{children}</AuthContext.Provider>;
}
