import { JobsiteUpdateInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import moment from 'moment';
import { DeepMap } from 'react-hook-form';
import { ensureNonEmptyItems, stringifyEmptyFields } from 'utils';
import { emailValidation } from 'utils/validation';

const emailListValidator = (listInput: string): string => {
  if (!listInput?.trim()?.length) return null;
  const items = listInput.trim().split(',');
  // eslint-disable-next-line no-restricted-syntax
  for (const email of items) {
    if (!email?.trim()?.length) return 'Blank emails not allowed in a list.';
    if (!email.trim().match(emailValidation)) return `Invalid email address [${email.trim()}]`;
  }
  return null;
};

export const notificationsSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['notifications']> = {
  hardwareStatusChange: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Hardware Status Changes',
    layout: [GridColSpan.SpanFull],
    elementProps: { placeholder: 'Enter email addresses, separated by comma' },
    validation: { validate: emailListValidator },
  },
  bansAndSuspensions: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Bans & Suspensions',
    layout: [GridColSpan.SpanFull],
    elementProps: { placeholder: 'Enter email addresses, separated by comma' },
    validation: { validate: emailListValidator },
  },
  escortPrivilegeChanges: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Escort Privilege Changes',
    layout: [GridColSpan.SpanFull],
    elementProps: { placeholder: 'Enter email addresses, separated by comma' },
    validation: { validate: emailListValidator },
  },
  jobsiteAssignmentChanges: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Jobsite Assignment Changes',
    layout: [GridColSpan.SpanFull],
    elementProps: { placeholder: 'Enter email addresses, separated by comma' },
    validation: { validate: emailListValidator },
  },
  rejectedSwipes: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Access Denials',
    layout: [GridColSpan.SpanFull],
    elementProps: { placeholder: 'Enter email addresses, separated by comma' },
    validation: { validate: emailListValidator },
  },
  dailyAccessReport: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Daily Access Report',
    layout: [GridColSpan.SpanFull],
    elementProps: { placeholder: 'Enter email addresses, separated by comma' },
    validation: { validate: emailListValidator },
  },
  weeklyAccessReport: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Weekly Access Report',
    layout: [GridColSpan.SpanFull],
    elementProps: { placeholder: 'Enter email addresses, separated by comma' },
    validation: { validate: emailListValidator },
  },
  reportingTimeSlotHour: {
    element: FormInputTypes.OdinField,
    label: 'Send worker access report at time of day',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { fieldType: 'number', innerRightLabel: 'Hours' },
  },
};

const EMAILS_SEPARATOR = ',';

export const getNotificationsSectionDefaultValues = (
  jobsite: Jobsite,
): EditJobsiteConfigurationFormData['notifications'] => {
  const {
    deviceStatusChangeEmails,
    accessChangeEmails,
    escortChangeEmails,
    assignmentChangeEmails,
    rejectedSwipeEmails,
    dailyAccessReportEmails,
    weeklyAccessReportEmails,
    reportingTimeSlot,
  } = jobsite ?? {};

  const getEmailsText = (emails: string[]): string => {
    if (!emails || !emails.length) return null;
    return ensureNonEmptyItems(emails)?.join(`${EMAILS_SEPARATOR} `);
  };
  const reportingTimeSlotHour =
    reportingTimeSlot && moment(reportingTimeSlot, 'HH:mm:ss').toDate().getHours().toString();

  return stringifyEmptyFields({
    hardwareStatusChange: getEmailsText(deviceStatusChangeEmails),
    bansAndSuspensions: getEmailsText(accessChangeEmails),
    escortPrivilegeChanges: getEmailsText(escortChangeEmails),
    jobsiteAssignmentChanges: getEmailsText(assignmentChangeEmails),
    rejectedSwipes: getEmailsText(rejectedSwipeEmails),
    dailyAccessReport: getEmailsText(dailyAccessReportEmails),
    weeklyAccessReport: getEmailsText(weeklyAccessReportEmails),
    reportingTimeSlotHour,
  });
};

type NotificationSectionUpdateInput = Required<
  Pick<
    JobsiteUpdateInput,
    | 'deviceStatusChangeEmails'
    | 'accessChangeEmails'
    | 'escortChangeEmails'
    | 'assignmentChangeEmails'
    | 'rejectedSwipeEmails'
    | 'dailyAccessReportEmails'
    | 'weeklyAccessReportEmails'
    | 'reportingTimeSlot'
  >
>;

export const getNotificationsSectionUpdateInput = (
  notifications: EditJobsiteConfigurationFormData['notifications'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['notifications'], true>,
): NotificationSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(notifications, dirtyFields);

  const getEmails = (text: string): string[] =>
    text === null || text === '' ? null : text?.split(EMAILS_SEPARATOR).map((email) => email.trim());
  const reportingTimeSlotHourValue = getUpdateInputValue('reportingTimeSlotHour');
  const reportingTimeSlot = reportingTimeSlotHourValue && moment(reportingTimeSlotHourValue, 'HH').format('HH:mm:ss');

  return {
    accessChangeEmails: getEmails(getUpdateInputValue('bansAndSuspensions')),
    deviceStatusChangeEmails: getEmails(getUpdateInputValue('hardwareStatusChange')),
    escortChangeEmails: getEmails(getUpdateInputValue('escortPrivilegeChanges')),
    assignmentChangeEmails: getEmails(getUpdateInputValue('jobsiteAssignmentChanges')),
    rejectedSwipeEmails: getEmails(getUpdateInputValue('rejectedSwipes')),
    dailyAccessReportEmails: getEmails(getUpdateInputValue('dailyAccessReport')),
    weeklyAccessReportEmails: getEmails(getUpdateInputValue('weeklyAccessReport')),
    reportingTimeSlot,
  };
};
