import React, { ReactElement } from 'react';
import cn from 'classnames';
import { Avatar } from '@odin-labs/components';
import jobsiteImage from 'images/jobsite.png';
import { JobsiteInvitation } from 'containers/selfOnboarding/types';

export const classes = {
  jobsiteInfoHolder: cn(
    'odin-px-7.5 odin-flex odin-gap-x-4.5 odin-py-5 odin-bg-odin-light-gray',
    'sm:odin-p-0 sm:odin-bg-none',
  ),
  jobsite: 'odin-text-sm odin-font-medium',
  contractor: 'odin-text-sm',
};

export type StepJobsiteInfoProps = {
  jobsiteInvitation?: JobsiteInvitation;
};

export function StepJobsiteInfo({ jobsiteInvitation }: StepJobsiteInfoProps): ReactElement {
  const jobsiteName = jobsiteInvitation?.jobsiteContractor.jobsite.name;
  const contractorName = jobsiteInvitation?.jobsiteContractor.contractor.organization.name;

  return (
    <div className={classes.jobsiteInfoHolder}>
      <Avatar src={jobsiteImage} type="rounded" />
      <div>
        <div className={classes.jobsite}>{jobsiteName}</div>
        <div className={classes.contractor}>{contractorName}</div>
      </div>
    </div>
  );
}
