import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useAssignContractorWorkerToJobsiteMutation } from 'apollo/generated/client-operations';
import { AssignWorkerFormData } from './types';

export type UseAssignContractorWorkerToJobsiteArgs = { workerId: string };

export type UseAssignContractorWorkerToJobsiteReturn = {
  assignJobsiteAndGoToPersonalInformation: (data: AssignWorkerFormData) => Promise<void>;
};

export function useAssignContractorWorkerToJobsite(
  props: UseAssignContractorWorkerToJobsiteArgs,
): UseAssignContractorWorkerToJobsiteReturn {
  const { workerId } = props;
  const history = useHistory();

  const [assignContractorWorkerToJobsite] = useAssignContractorWorkerToJobsiteMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: ['GetJobsiteWorkers'],
  });

  const assignJobsiteAndGoToPersonalInformation = async (data: AssignWorkerFormData): Promise<void> => {
    const { jobsiteId, contractorId, orientationDate } = data;

    // assign worker to the jobsite and contractor
    const response = await assignContractorWorkerToJobsite({
      variables: {
        workerId,
        jobsiteId: jobsiteId.value,
        contractorId: contractorId.value,
        jobsiteWorkerInput: {
          orientationDate: orientationDate.startOf('day').toDate(),
          startDate: moment().toDate(),
        },
      },
    });

    const assignContractorWorkerToJobsiteResponse = response.data.assignContractorWorkerToJobsite;
    history.push(`/onboarding/${assignContractorWorkerToJobsiteResponse?.jobsiteWorkerId}/personal-information`);
  };

  return {
    assignJobsiteAndGoToPersonalInformation,
  };
}
