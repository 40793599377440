import { ApolloError } from '@apollo/client';
import { useGetJobsiteWorkerDashboardReportQuery } from 'apollo/generated/client-operations';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { useUpdateQueryStatus } from './useUpdateQueryStatus';

export type WorkforceStats = {
  uniqueWorkers: number;
  totalManPower: number;
  totalHours: number;
  averageHours: number;
};

export type UseWorkforceStatsData = {
  loading: boolean;
  error: ApolloError;
  workforceStats: WorkforceStats;
};

const fieldsMapping: { [key: string]: keyof WorkforceStats } = {
  'Total Manpower': 'totalManPower',
  'Unique Workers': 'uniqueWorkers',
  'Average Hours': 'averageHours',
  'Total Hours': 'totalHours',
};

const initialWorkforceStats: WorkforceStats = {
  uniqueWorkers: 0,
  totalManPower: 0,
  totalHours: 0,
  averageHours: 0,
};

export const useWorkforceStatsData = (jobsiteFormSubmission: JobsiteFormSubmission): UseWorkforceStatsData => {
  const { startAt } = jobsiteFormSubmission;
  const jobsiteIds = [jobsiteFormSubmission.jobsiteForm.jobsite.jobsiteId];
  // jobsiteFormSubmission.jobsiteWorkers.edges.map(
  //   ({ node }) => node.jobsiteWorker.jobsiteContractor.jobsite.jobsiteId,
  // );

  const { data, loading, error } = useGetJobsiteWorkerDashboardReportQuery({
    variables: {
      jobsiteIds,
      startDate: startAt,
      endDate: startAt,
    },
    fetchPolicy: 'no-cache',
  });

  useUpdateQueryStatus('workforceStats', { loading, error });

  const workforceStats: WorkforceStats =
    data?.getJobsiteWorkerDashboard.overviewBreakdowns.reduce((result, item): WorkforceStats => {
      const field = fieldsMapping[item.name];
      if (field) {
        return {
          ...result,
          [field]: item.name === 'Average Hours' ? item.compliantCount : item.count,
        };
      }
      return result;
    }, initialWorkforceStats) ?? initialWorkforceStats;

  return {
    loading,
    error,
    workforceStats,
  };
};
