import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { ChangeType, JobsiteUpdateFormSubmissionInput } from 'apollo/generated/client-operations';
import { ensureNonEmptyItems } from 'utils';
import { CustomFormInputs, EditFormData, JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/types';

type Form = JobsiteFormSubmission['jobsiteForm']['form'];

type ContractorsUpdateInput = Required<Pick<JobsiteUpdateFormSubmissionInput, 'jobsiteContractors'>>;
type JobsiteContractorUpdateInput = ContractorsUpdateInput['jobsiteContractors'][number];

export const getJobsiteContractorsUpdateInput = (args: {
  data: CustomFormInputs<EditFormData>;
  dirtyFields: DeepMap<CustomFormInputs<EditFormData>, true>;
  defaultValues: CustomFormInputs<EditFormData>;
  form: Form;
}): ContractorsUpdateInput => {
  const { data, dirtyFields, defaultValues, form } = args;
  const jobsiteContractorFields = (form.content.edit.jobsiteContractorFields as string[]) ?? [];

  return {
    jobsiteContractors: jobsiteContractorFields?.flatMap((jwField): JobsiteContractorUpdateInput[] => {
      if (dirtyFields?.[jwField]) {
        const jcFieldValue = data?.[jwField];
        if (Array.isArray(jcFieldValue)) {
          return jcFieldValue
            ?.filter((jfsC) => jfsC.changeType)
            .map((jfsC) => ({
              changeType: jfsC.changeType as ChangeType,
              id: jfsC.id,
              jobsiteContractorId: jfsC.jobsiteContractor.id,
              associationType: jfsC.associationType,
              extraData: jfsC.extraData,
            }));
        }
        if (jcFieldValue || defaultValues[jwField]) {
          const oldValue = defaultValues[jwField] as SelectOptionElement;
          const newValue = jcFieldValue as SelectOptionElement;
          return ensureNonEmptyItems([
            // remove the initial value
            oldValue && {
              changeType: ChangeType.Removed,
              id: oldValue.id,
              jobsiteContractorId: oldValue.jobsiteContractor.id,
              associationType: oldValue.associationType,
            },
            // add the new value
            {
              changeType: ChangeType.Created,
              id: newValue.id,
              jobsiteContractorId: newValue.jobsiteContractor.id,
              associationType: newValue.associationType,
            },
          ]);
        }
      }
      return undefined;
    }),
  };
};
