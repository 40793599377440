import React, { ReactElement } from 'react';
import { SelectOptionElement } from '@odin-labs/components';

import {
  JobsiteAnnouncementFilterSpecification,
  useGetJobsiteAnnouncementCandidateCountsQuery,
  useGetJobsiteAnnouncementsContainerContractorsSessionQuery,
} from 'apollo/generated/client-operations';
import {
  MakeAnnouncementModalContainer,
  FilterRecipientsModalContainer,
  DraftAnnouncementModalContainer,
  ReviewAnnouncementModalContainer,
  ScheduleAnnouncementModalContainer,
} from 'containers/announcements/modals/';
import { CreateAnnouncementData, Jobsite, JobsiteSelectOptionElement } from 'containers/announcements/types';
import { emptyAnnouncementData } from 'containers/announcements/utils';
import { useLegacyState } from 'utils/useLegacyState';

export type AddAnnouncementModalProps = {
  isOpen?: boolean;
  closeModal: () => void;
  jobsiteOptions: JobsiteSelectOptionElement[];
  jobsites: Jobsite[];
};

export function AddAnnouncementModal(props: AddAnnouncementModalProps): ReactElement {
  const { jobsiteOptions, jobsites, closeModal, isOpen: isModalOpen = true } = props;
  const [step, setStep] = React.useState(1);
  const [announcement, updateAnnouncement] = useLegacyState<CreateAnnouncementData>(emptyAnnouncementData);

  const { data: contractorsData } = useGetJobsiteAnnouncementsContainerContractorsSessionQuery({
    fetchPolicy: 'no-cache',
    skip: !announcement.filterJobsiteAccessStatus,
    variables: {
      userContractorsInput: {
        jobsiteIds: announcement.filterJobsiteIds,
      },
    },
  });

  const contractorOptions: SelectOptionElement[] = React.useMemo(() => {
    const contractors = contractorsData?.getCurrentSession.user.contractors?.edges.map(({ node }) => node);
    return contractors?.map((contractor) => ({
      label: contractor?.organization?.name,
      value: contractor.contractorId,
    }));
  }, [contractorsData?.getCurrentSession.user.contractors?.edges]);

  const getRecipientFilters = (): JobsiteAnnouncementFilterSpecification => {
    const {
      filterJobsiteIds,
      filterContractorIds,
      filterTrades,
      filterTitles,
      filterLanguages,
      filterJobsiteAccessStatus,
      filterAccessWithinDayRange,
      announcementType: filterAnnouncementType,
    } = announcement;

    return {
      filterJobsiteIds,
      filterContractorIds,
      filterTrades,
      filterTitles,
      filterLanguages,
      filterJobsiteAccessStatus,
      filterAccessWithinDayRange,
      announcementType: filterAnnouncementType,
      paginationInput: { offset: 0, limit: 0 },
    };
  };

  const { data: countsData } = useGetJobsiteAnnouncementCandidateCountsQuery({
    fetchPolicy: 'no-cache',
    skip: !announcement.filterJobsiteAccessStatus,
    variables: { input: getRecipientFilters() },
  });
  const recipientCounts = countsData?.getJobsiteAnnouncementCandidateRecipients;

  return (
    <>
      {isModalOpen && step === 1 && (
        <MakeAnnouncementModalContainer
          jobsiteOptions={jobsiteOptions}
          announcement={announcement}
          updateAnnouncement={updateAnnouncement}
          onCancel={closeModal}
          onAction={(): void => setStep(2)}
        />
      )}
      {isModalOpen && step === 2 && (
        <FilterRecipientsModalContainer
          jobsiteOptions={jobsiteOptions}
          jobsites={jobsites}
          contractorOptions={contractorOptions}
          announcement={announcement}
          updateAnnouncement={updateAnnouncement}
          onBack={(): void => setStep(1)}
          onCancel={closeModal}
          onAction={(): void => setStep(3)}
          counts={recipientCounts}
        />
      )}
      {isModalOpen && step === 3 && (
        <DraftAnnouncementModalContainer
          jobsiteOptions={jobsiteOptions}
          announcement={announcement}
          updateAnnouncement={updateAnnouncement}
          onBack={(): void => setStep(2)}
          onCancel={closeModal}
          onAction={(): void => setStep(4)}
        />
      )}
      {isModalOpen && step === 4 && (
        <ScheduleAnnouncementModalContainer
          announcement={announcement}
          updateAnnouncement={updateAnnouncement}
          onBack={(): void => setStep(3)}
          onCancel={closeModal}
          onAction={(): void => setStep(5)}
        />
      )}
      {isModalOpen && step === 5 && (
        <ReviewAnnouncementModalContainer
          jobsiteOptions={jobsiteOptions}
          contractorOptions={contractorOptions}
          counts={recipientCounts}
          announcement={announcement}
          updateAnnouncement={updateAnnouncement}
          onBack={(): void => setStep(4)}
          onCancel={closeModal}
          onAction={closeModal}
        />
      )}
    </>
  );
}
