import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { stringifyEmptyFields } from 'utils';
import { getIcon } from 'utils/ui';
import { dateValidation, emailValidation, phoneNumberValidation, ssnValidation } from 'utils/validation';
import { getFields } from 'types';
import { EditJobsiteConfigurationFormData } from 'containers/jobsiteConfiguration/types';
import { BasicInfoFormData } from './types';

const jobsiteConfigWorkerInfo = getFields<EditJobsiteConfigurationFormData['workerInfo']>();
const onboardingBasicInfo = getFields<BasicInfoFormData>();

// this maps onboarding fields to jobsiteConfiguration field whenever they differ
export const configFieldMap: Record<string, keyof typeof jobsiteConfigWorkerInfo> = {
  [onboardingBasicInfo.phoneNumber]: jobsiteConfigWorkerInfo.phone,
};

const EnvelopeIcon = getIcon('fal fa-envelope');

export const getFormInputs = (): TypedFormInputs<BasicInfoFormData> => ({
  firstName: {
    element: FormInputTypes.OdinField,
    label: 'First name',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  middleInitial: {
    element: FormInputTypes.OdinField,
    label: 'Middle initial',
    elementProps: {
      fieldType: 'custom',
      cleaveOptions: { blocks: [1] },
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  lastName: {
    element: FormInputTypes.OdinField,
    label: 'Last name',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  suffix: {
    element: FormInputTypes.OdinField,
    label: 'Suffix',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  birthDate: {
    element: FormInputTypes.OdinField,
    label: 'Date of birth',
    elementProps: {
      placeholder: 'MM/DD/YYYY',
      fieldType: 'pastDate',
    },
    validation: {
      pattern: dateValidation,
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  ssnLastFour: {
    element: FormInputTypes.OdinField,
    label: 'Last 4 of Social Security Number',
    elementProps: {
      fieldType: 'ssn4',
    },
    validation: {
      pattern: ssnValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  email: {
    element: FormInputTypes.OdinField,
    label: 'Email',
    elementProps: {
      icon: EnvelopeIcon,
    },
    validation: {
      pattern: emailValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  phoneNumber: {
    element: FormInputTypes.OdinField,
    label: 'Mobile Phone',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: {
      pattern: phoneNumberValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
});

export const getDefaultValues = (basicInfo: BasicInfoFormData): BasicInfoFormData => {
  const { firstName, lastName, middleInitial, suffix, birthDate, ssnLastFour, email, phoneNumber } = basicInfo ?? {};
  return stringifyEmptyFields({
    firstName,
    lastName,
    middleInitial,
    suffix,
    birthDate,
    ssnLastFour,
    email,
    phoneNumber,
  });
};
