import classNames from 'classnames/bind';
import { Form } from 'components/form';
import { Header } from 'components/header';
import { LoadingError } from 'components/loadingError';
import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useRef } from 'react';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { setRefFactory } from 'components/utils';
import { WizardProps, WizardStepProps } from './types';

function BaseWizard<TFields>({
  name,
  steps,
  loading,
  forwardedRef,
  textAlign = 'left',
  shouldResetSteps = true,
  finalSubmitText = 'Submit',
  backEnabled = true,
}: WizardProps<TFields>): ReactElement {
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>();

  let position = 0;
  steps.forEach((step: WizardStepProps<TFields>, index) => {
    const match = matchPath(step.path, { path: location?.pathname });
    if (match && match?.isExact) {
      position = index;
    }
  });

  useImperativeHandle(forwardedRef, () => ({
    onNextStep: (): void => {
      if (position < steps.length) {
        history.push(steps[position + 1]?.path);
      }
    },
    onPreviousStep: (): void => {
      if (position > 0) {
        history.push(steps[position - 1]?.path);
      }
    },
  }));

  useEffect(() => {
    const windowListener = (): void => {
      if (window != null) {
        window.onbeforeunload = (): string => {
          return 'Reload site?';
        };
      }
    };

    const checkRedirect = (): void => {
      const match = matchPath(steps[0].path, { path: location?.pathname });
      if (!match && !match?.isExact && shouldResetSteps) {
        history.push(steps[0]?.path);
      } else {
        windowListener();
      }
    };

    checkRedirect();

    return (): void => {
      if (window != null) {
        window.onbeforeunload = null;
      }
    };
  }, []);

  return (
    <Row className="justify-content-center flex-column" id={name}>
      <Switch>
        {steps.map((step: WizardStepProps<TFields>) => {
          const { ref: outerRef, ...stepFormProps } = step.form;
          const setRef = setRefFactory<HTMLFormElement>({ innerRef: formRef, outerRef });

          return (
            <Route path={step.path} key={step.name} exact>
              <Col xs="12" lg={{ size: 8, offset: 2 }}>
                <Row className="align-items-center">
                  <Col id={step?.name}>
                    <Header
                      textAlign={textAlign}
                      title={step?.title}
                      pretitle={`Step: ${position + 1} of ${steps.length}`}
                      subtitle={step.subtitle}
                    />
                    {loading && (
                      <div className="table-backdrop odin-m--5">
                        <LoadingError loading />
                      </div>
                    )}
                    <Form ref={setRef} {...stepFormProps} />
                  </Col>
                </Row>
                <hr />
                {!loading ? (
                  <Row className="align-items-center mb-5">
                    <Col className="auto">
                      {position > 0 && backEnabled ? (
                        <Button
                          size="lg"
                          className="btn-white mr-auto"
                          onClick={(): void => {
                            history.push(steps[position - 1]?.path);
                          }}
                        >
                          Back
                        </Button>
                      ) : null}
                    </Col>
                    <Col />
                    <Col className="auto text-right">
                      <Button
                        color="primary"
                        size="lg ml-auto"
                        onClick={(): void => {
                          formRef.current.requestSubmit();
                        }}
                      >
                        {position + 1 === steps.length ? finalSubmitText : 'Submit'}
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                <h6
                  className={classNames('header-pretitle', 'mb-4', {
                    'text-left': textAlign === 'left',
                    'text-center': textAlign === 'center',
                    'text-right': textAlign === 'right',
                  })}
                >
                  {`Step: ${position + 1} of ${steps.length}`}
                </h6>
              </Col>
            </Route>
          );
        })}
        <Route path="*" exact>
          <Redirect to={steps[0]?.path} />
        </Route>
      </Switch>
    </Row>
  );
}

export const Wizard = forwardRef((props: any, ref: any): ReactElement => {
  return <BaseWizard {...props} forwardedRef={ref} />;
});
