import React, { ReactElement } from 'react';
import { hot } from 'react-hot-loader';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { SelfOnboardingLayoutProps } from 'layouts/selfOnboarding/types';

export function SelfOnboardingLayoutWithoutHot(props: SelfOnboardingLayoutProps): ReactElement {
  const { route } = props;

  return <div>{renderRoutes(route.routes as RouteConfig[])}</div>;
}

export const SelfOnboardingLayout = hot(module)(SelfOnboardingLayoutWithoutHot);
