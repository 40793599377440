import React from 'react';
import { BriefcaseIcon } from 'components/icons';
import { Logo } from 'components/logo';

export function AcknowledgmentDocumentHeader({ jobsiteName }: { jobsiteName: string }): React.ReactElement {
  return (
    <div className="odin-mt-6 odin-mb-9 odin-flex odin-justify-between odin-items-center">
      <Logo />
      <div className="odin-flex odin-items-center odin-gap-x-1.5">
        <BriefcaseIcon className="odin-text-odin-primary odin-h-4" />
        <span>{jobsiteName}</span>
      </div>
    </div>
  );
}
