import React from 'react';
import { AlertInstance } from 'components/alertNotification';
import { FormOnSubmit, ModalForm } from 'components/form';
import { EditPersonalInfoFormData } from 'containers/worker/types';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import {
  AppErrorCode,
  UserErrorCode,
  useUserUpdateIdentityMutation,
  useUpdateWorkerMutation,
} from 'apollo/generated/client-operations';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { EmailAlreadyExistsErrorExtensions, PhoneAlreadyExistsErrorExtensions } from 'types';
import { getDefaultValues, getFormInputsHook, getPersonalInfoUpdateInput } from './EditPersonalInfoModal.forms';
import { EditPersonalInfoModalProps } from './types';

export function EditPersonalInfoModal(props: EditPersonalInfoModalProps): React.ReactElement {
  const { isOpen = false, onCancel, worker, refetch } = props;
  const [isSaving, setIsSaving] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const [updateUserIdentity] = useUserUpdateIdentityMutation();
  const [updateWorker] = useUpdateWorkerMutation();

  const onSubmit: FormOnSubmit<EditPersonalInfoFormData> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    const { userUpdateIdentityInput, workerId, workerInput } = getPersonalInfoUpdateInput(worker, data, dirtyFields);

    try {
      if (userUpdateIdentityInput.userIdentityInput) {
        await updateUserIdentity({ variables: { input: userUpdateIdentityInput } });
      }

      if (workerInput) {
        await updateWorker({ variables: { workerId, workerInput } });
      }

      AlertInstance.alert('tc', 'success', 'Success', 'Personal information successfully updated');
      setIsSaving(false);
      onCancel();
      refetch();
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);

      const emailAlreadyExistsError = getGraphQLErrorByCode<EmailAlreadyExistsErrorExtensions>(
        error,
        UserErrorCode.EmailAlreadyExists,
      );
      if (emailAlreadyExistsError) {
        formApi.setError('email', {
          message: <AlreadyExistingItem itemType="Email" workerId={emailAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        return;
      }

      const phoneAlreadyExistsError = getGraphQLErrorByCode<PhoneAlreadyExistsErrorExtensions>(
        error,
        AppErrorCode.PhoneAlreadyExists,
      );
      if (phoneAlreadyExistsError) {
        formApi.setError('phoneNumber', {
          message: <AlreadyExistingItem itemType="Phone" workerId={phoneAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        return;
      }

      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const defaultValues = React.useMemo(() => getDefaultValues(worker), [worker]);
  const formInputs = getFormInputsHook({ worker, defaultValues });

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      onIsDirtyChange={setIsFormDirty}
      title="Edit Personal Information"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-12 odin-gap-6"
      actionText={isSaving ? 'Saving ...' : 'Save'}
      actionButtonEnabled={isFormDirty}
      actionButtonWithSpinner={isSaving}
    />
  );
}
