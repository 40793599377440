import React, { ReactElement } from 'react';
import { Select } from 'components/select';

import { SelectContractorModalProps } from './types';
import { BaseModal } from './BaseModal';

export function SelectContractorModal({
  isOpen = false,
  toggle,
  onCancel,
  onAction,
  dropdownValue,
  dropdownOptions,
  setSelectedContractorId,
}: SelectContractorModalProps): ReactElement {
  return (
    <BaseModal
      onCancel={onCancel}
      onAction={onAction}
      isOpen={isOpen}
      toggle={toggle}
      cancelText="Cancel"
      actionText="OK"
      title="Please select a contractor to associate with this worker."
    >
      <Select
        value={dropdownValue}
        options={dropdownOptions}
        placeholder="Select one"
        onChangeHook={(value: string): void => {
          setSelectedContractorId(value);
        }}
      />
    </BaseModal>
  );
}
