import cn from 'classnames';
import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, TypedFormInputs } from 'components/form';
import { CreateBadgeInfo } from 'containers/worker/types';

export const getFormInputs = (proximityCardFormatOptions: SelectOptionElement[]): TypedFormInputs<CreateBadgeInfo> => {
  return {
    proximityCardNumber: {
      element: FormInputTypes.OdinField,
      label: 'New Badge Number',
      elementProps: { fieldType: 'number' },
      validation: {
        required: {
          value: true,
          message: 'Badge Number Required',
        },
      },
      layout: cn('odin-col-span-6'),
    },
    proximityCardFormatId: {
      element: FormInputTypes.OdinSelect,
      label: 'Card Format',
      elementProps: {
        disabled: (proximityCardFormatOptions?.length ?? 0) < 2,
        options: proximityCardFormatOptions ?? [],
      },
      layout: cn('odin-col-span-6'),
    },
  };
};

export const getFormDefaultValues = (proximityCardFormatOption: SelectOptionElement): CreateBadgeInfo => {
  return {
    proximityCardFormatId: proximityCardFormatOption ?? null,
  };
};
