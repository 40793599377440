import React from 'react';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { Weather } from 'components/weather';

const classes = {
  title: 'odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium odin-mr-9',
};

export type FormSubmissionReportWeatherProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
};

export function FormSubmissionReportWeather(props: FormSubmissionReportWeatherProps): React.ReactElement {
  const { jobsiteFormSubmission } = props;
  const { timeZone, weatherConditions } = jobsiteFormSubmission ?? {};

  return (
    <div>
      <div className="odin-mb-6">
        <span className={classes.title}>Weather</span>
      </div>
      <Weather weatherConditions={weatherConditions} timeZone={timeZone} wrap />
    </div>
  );
}
