import React from 'react';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  FilterItems,
} from '@odin-labs/components';
import { isAfterToday } from 'utils/dates';
import { DeveloperIcon } from 'containers/developer/types';
import { Developer, DevelopersFilters } from 'containers/developers/types';

export type DeveloperColumn = Column<Developer>;

export const getColumns = (): DeveloperColumn[] => [
  {
    id: 'developer',
    Header: 'Name',
    accessor: (developer: Developer): string => {
      return developer.organization.name;
    },
    Cell: AvatarCell,
    CellLoading: <AvatarSkeleton size="xs" type="rounded" hideDetails />,
    componentProps: (developer: Developer): AvatarCellComponentProps => ({
      icon: DeveloperIcon,
      type: 'rounded',
      placeholder: (developer.organization.name.substring(0, 2) || '??').toUpperCase(),
      objectFit: 'cover',
      size: 'xs',
    }),
  },
  {
    id: 'location',
    Header: 'Location',
    accessor: (developer: Developer): string => {
      const { addressCity, addressState } = developer?.organization ?? {};
      return [addressCity, addressState].filter(Boolean).join(', ');
    },
  },
  {
    id: 'jobsitesCount',
    Header: '# of jobsites',
    accessor: (developer: Developer): string => {
      const { count } = developer?.jobsites ?? {};
      return count.toString();
    },
  },
  {
    id: 'jobsites',
    Header: 'Jobsites',
    accessor: (): string[] => [],
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    cellClassName: 'odin-py-3',
    componentProps: (developer: Developer): ChipsCellComponentProps => ({
      chips: developer.jobsites.edges.map(({ node }) => ({
        text: node.name,
        secondaryText: `${node.jobsiteWorkers.count} workers `,
        isActive: !node.endDate || isAfterToday(node.endDate),
      })),
    }),
    disableSortBy: true,
  },
];

export const getFilterItems = ({ search }: { search: string }): FilterItems<DevelopersFilters> => ({
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
