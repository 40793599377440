import React, { ReactElement, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import classNames from 'classnames/bind';
import { BaseModal } from 'components/modals';
import { Select, SelectOptionsElement } from 'components/select';
import { Field } from 'components/field';
import { Container, Navbar } from 'reactstrap';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { EllipsisDropdownButton, NewButton, NewMenuItemProps, getFaIcon } from '@odin-labs/components';
import {
  JobsiteWorker,
  JobsiteWorkerOrientationStatus,
  MutationUpdateJobsiteWorkerArgs,
} from 'apollo/generated/client-operations';
import { skipStepReasons } from 'utils/constants';
import { JobsiteWorkerOnboardingFooterNavbarProps } from 'containers/workerOnboarding/types';
import { OdinDatePicker } from 'components/datePicker';
import { useBoolean } from 'utils';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_JOBSITE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import { QueryGetAppEnvironmentResponse, GET_APP_ENVIRONMENT } from 'graphql/client/general';
import { RouterPrompt } from 'components/routerPrompt';
import * as styles from 'containers/workerOnboarding/scss/worker-onboarding.scss';

const cx = classNames.bind(styles);

const SaveIcon = getFaIcon({ icon: faSave });

export function JobsiteWorkerOnboardingFooterNavbar(props: JobsiteWorkerOnboardingFooterNavbarProps): ReactElement {
  const {
    onSave,
    isFormDirty,
    hideSkipOptions,
    hideSave,
    onResetComplete,
    onForceComplete,
    showConditionalPassExpiration = false,
    showExemptionReason = false,
    jobsiteWorker,
    hasNoWorkerCards,
  } = props;

  const showSkipOptions = !hideSkipOptions;
  const showSaveButton = !hideSave;

  const {
    value: isNoWorkerCardWarningModalOpen,
    setTrue: openNoWorkerCardWarningModal,
    setFalse: closeNoWorkerCardWarningModal,
  } = useBoolean(false);
  const [showForceCompleteModal, setShowForceCompleteModal] = useState<boolean>(false);
  const [forceCompleteModalReason, setShowForceCompleteModalReason] = useState<string>(null);
  const [forceCompleteModalReasonCustom, setShowForceCompleteModalReasonCustom] = useState<string>(null);
  const [expirationDate, setExpirationDate] = useState<Moment>(null);
  const [exemptionReason, setExemptionReason] = useState<string>('');
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    fetchPolicy: 'no-cache',
    refetchQueries: ['GetJobsiteWorker'],
  });

  const {
    data: { environment },
  } = useQuery<QueryGetAppEnvironmentResponse>(GET_APP_ENVIRONMENT);

  useEffect(() => {
    if (environment !== 'main-prd') {
      setShowSkipButton(true);
    }
  }, [environment]);

  const completeOrientation = (): void => {
    const currentDateTime = getCurrentISOFormattedDateTime();
    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId: jobsiteWorker?.jobsiteWorkerId,
        jobsiteWorkerInput: {
          profileCompletedAt: currentDateTime,
          badgingCompletedAt: currentDateTime,
          passedMedicalExamAt: currentDateTime,
          onboardingSignedAt: currentDateTime,
          signatureCompletedAt: currentDateTime,
          siteSpecificTrainingCompletedAt: currentDateTime,
          documentsCompletedAt: currentDateTime,
          orientationStatus: JobsiteWorkerOrientationStatus.Complete,
        },
      },
    });
  };

  const closeModal = (): void => {
    setShowForceCompleteModal(false);
    setShowForceCompleteModalReason(null);
    setShowForceCompleteModalReasonCustom(null);
  };

  const getActionButtonEnabled = (): boolean => {
    return !!forceCompleteModalReason;
  };

  const getForceCompleteReasons = (): Array<SelectOptionsElement> => {
    const options = skipStepReasons.map((skipStepReason) => {
      return {
        name: skipStepReason,
        value: skipStepReason,
      };
    });

    if (showExemptionReason) {
      options.splice(options.length - 1, 0, {
        name: 'Exempt from having to provide documents',
        value: 'Exempt from having to provide documents',
      });
    }

    return options;
  };

  const save = React.useCallback(async (): Promise<void | boolean> => {
    if (hasNoWorkerCards) {
      return openNoWorkerCardWarningModal();
    }
    return onSave();
  }, [hasNoWorkerCards, openNoWorkerCardWarningModal, onSave]);

  const menuItems: NewMenuItemProps[] = [
    {
      text: 'Force complete status',
      theme: 'secondary',
      onClick: (): void => setShowForceCompleteModal(true),
    },
    {
      text: 'Reset status',
      theme: 'secondary',
      onClick: onResetComplete,
    },
  ];

  return (
    <Navbar light className={cx('jobsite-worker-orientation-footer-navbar-container border-top')} fixed="bottom">
      <RouterPrompt when={isFormDirty} onConfirm={save} />
      <Container fluid>
        <div className="odin-flex odin-flex-grow odin-justify-end odin-items-center odin-gap-x-1.5">
          {showSkipButton && <NewButton text="Force skip orientation" onClick={completeOrientation} />}
          {showSkipOptions && <EllipsisDropdownButton size="xl" menuItems={menuItems} popupWidthFitContent />}
          {showSaveButton && <NewButton text="Save information" icon={SaveIcon} onClick={save} hideTextOnMobile />}
        </div>
      </Container>
      <BaseModal
        title="Warning"
        subtitle="Warning -- This worker doesn't have a badge number assigned to them. If you have printed a badge,
                  you will need to enter the proximity card number before leaving this page otherwise the worker will
                  not be able to enter the jobsite."
        isOpen={isNoWorkerCardWarningModalOpen}
        actionText="OK"
        actionColor="warning"
        onAction={(): void => {
          closeNoWorkerCardWarningModal();
          onSave();
        }}
        onCancel={closeNoWorkerCardWarningModal}
        toggle={closeNoWorkerCardWarningModal}
      />
      <BaseModal
        title="Force skip this step?"
        toggle={closeModal}
        isOpen={showForceCompleteModal}
        onCancel={closeModal}
        onAction={(): void => {
          onForceComplete(forceCompleteModalReasonCustom || forceCompleteModalReason, expirationDate, exemptionReason);
          closeModal();
        }}
        actionButtonEnabled={getActionButtonEnabled()}
      >
        <>
          <Select
            onChange={(_, value): void => {
              setShowForceCompleteModalReason(value);
            }}
            value={forceCompleteModalReason}
            placeholder="Select one"
            options={getForceCompleteReasons()}
            label="Reason for skipping this step"
          />
          {showConditionalPassExpiration && forceCompleteModalReason === 'Temporarily allowing to skip step' ? (
            <OdinDatePicker
              minDate={moment()}
              value={[expirationDate]}
              name="expirationDate"
              label="Due date to provide documentation (optional)"
              onDatesChange={(date: Moment): void => {
                setExpirationDate(date);
              }}
            />
          ) : null}
          {showConditionalPassExpiration && forceCompleteModalReason === 'Exempt from having to provide documents' ? (
            <Field
              value={exemptionReason}
              name="exemptionReason"
              label="Reason for exempt status"
              onChange={(value): void => setExemptionReason(value)}
            />
          ) : null}
          {forceCompleteModalReason === 'Other' ? (
            <Field
              name="force-custom-reason"
              value={forceCompleteModalReasonCustom}
              onChange={(value): void => setShowForceCompleteModalReasonCustom(value)}
              placeholder="Skip notes"
              label="Reason"
            />
          ) : null}
        </>
      </BaseModal>
    </Navbar>
  );
}
