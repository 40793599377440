/* eslint-disable max-len */
import { addTranslations } from 'utils/localization';

export enum Copy {
  onboarding_instructions_title = 'onboarding_instructions_title',
  onboarding_instructions_subtitle = 'onboarding_instructions_subtitle',
  onboarding_instructions_step_1_text = 'onboarding_instructions_step_1_text',
  onboarding_instructions_step_2_text = 'onboarding_instructions_step_2_text',
  onboarding_instructions_step_3_text = 'onboarding_instructions_step_3_text',
  onboarding_instructions_scan_to_begin = 'onboarding_instructions_scan_to_begin',
}

const en: Record<Copy, string> = {
  [Copy.onboarding_instructions_title]: 'Start Onboarding',
  [Copy.onboarding_instructions_subtitle]: 'Please complete the steps below to get jobsite access',
  [Copy.onboarding_instructions_step_1_text]: 'Scan the QR code below on your mobile device',
  [Copy.onboarding_instructions_step_2_text]:
    'Enter your personal information, upload documents, add a profile photo, and verify your phone number',
  [Copy.onboarding_instructions_step_3_text]:
    'When you arrive at the jobsite, let the on-site team know you filled out your profile information',
  [Copy.onboarding_instructions_scan_to_begin]: 'Scan to begin',
};

const es: Record<Copy, string> = {
  [Copy.onboarding_instructions_title]: 'Comenzar integración',
  [Copy.onboarding_instructions_subtitle]:
    'Por favor, completa los siguientes pasos para obtener acceso al lugar de trabajo',
  [Copy.onboarding_instructions_step_1_text]:
    'Por favor, completa los siguientes pasos para obtener acceso al lugar de trabajo',
  [Copy.onboarding_instructions_step_2_text]:
    'Ingrese su información personal, cargue documentos, agregue una foto de perfil y verifique su número de teléfono',
  [Copy.onboarding_instructions_step_3_text]:
    'Cuando llegue al lugar de trabajo, informe al equipo in situ que completó la información de su perfil.',
  [Copy.onboarding_instructions_scan_to_begin]: 'Escanear para comenzar',
};

const zhCN: Record<Copy, string> = {
  [Copy.onboarding_instructions_title]: 'Start Onboarding',
  [Copy.onboarding_instructions_subtitle]: 'Please complete the steps below to get jobsite access',
  [Copy.onboarding_instructions_step_1_text]: 'Scan the QR code below on your mobile device',
  [Copy.onboarding_instructions_step_2_text]:
    'Enter your personal information, upload documents, add a profile photo, and verify your phone number',
  [Copy.onboarding_instructions_step_3_text]:
    'When you arrive at the jobsite, let the on-site team know you filled out your profile information',
  [Copy.onboarding_instructions_scan_to_begin]: 'Scan to begin',
};

const zhTW: Record<Copy, string> = {
  [Copy.onboarding_instructions_title]: 'Start Onboarding',
  [Copy.onboarding_instructions_subtitle]: 'Please complete the steps below to get jobsite access',
  [Copy.onboarding_instructions_step_1_text]: 'Scan the QR code below on your mobile device',
  [Copy.onboarding_instructions_step_2_text]:
    'Enter your personal information, upload documents, add a profile photo, and verify your phone number',
  [Copy.onboarding_instructions_step_3_text]:
    'When you arrive at the jobsite, let the on-site team know you filled out your profile information',
  [Copy.onboarding_instructions_scan_to_begin]: 'Scan to begin',
};

const pl: Record<Copy, string> = {
  [Copy.onboarding_instructions_title]: 'Start Onboarding',
  [Copy.onboarding_instructions_subtitle]: 'Please complete the steps below to get jobsite access',
  [Copy.onboarding_instructions_step_1_text]: 'Scan the QR code below on your mobile device',
  [Copy.onboarding_instructions_step_2_text]:
    'Enter your personal information, upload documents, add a profile photo, and verify your phone number',
  [Copy.onboarding_instructions_step_3_text]:
    'When you arrive at the jobsite, let the on-site team know you filled out your profile information',
  [Copy.onboarding_instructions_scan_to_begin]: 'Scan to begin',
};

const ru: Record<Copy, string> = {
  [Copy.onboarding_instructions_title]: 'Start Onboarding',
  [Copy.onboarding_instructions_subtitle]: 'Please complete the steps below to get jobsite access',
  [Copy.onboarding_instructions_step_1_text]: 'Scan the QR code below on your mobile device',
  [Copy.onboarding_instructions_step_2_text]:
    'Enter your personal information, upload documents, add a profile photo, and verify your phone number',
  [Copy.onboarding_instructions_step_3_text]:
    'When you arrive at the jobsite, let the on-site team know you filled out your profile information',
  [Copy.onboarding_instructions_scan_to_begin]: 'Scan to begin',
};

const hu: Record<Copy, string> = {
  [Copy.onboarding_instructions_title]: 'Start Onboarding',
  [Copy.onboarding_instructions_subtitle]: 'Please complete the steps below to get jobsite access',
  [Copy.onboarding_instructions_step_1_text]: 'Scan the QR code below on your mobile device',
  [Copy.onboarding_instructions_step_2_text]:
    'Enter your personal information, upload documents, add a profile photo, and verify your phone number',
  [Copy.onboarding_instructions_step_3_text]:
    'When you arrive at the jobsite, let the on-site team know you filled out your profile information',
  [Copy.onboarding_instructions_scan_to_begin]: 'Scan to begin',
};

export const Languages = {
  en,
  es,
  zhCN,
  zhTW,
  pl,
  ru,
  hu,
};

addTranslations({ ...Languages, en_US: en });
