import React from 'react';

export function BadgeChecklistHeader(): React.ReactElement {
  return (
    <>
      <h3 className="odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900 odin-mt-0">
        This worker has other active badges
      </h3>
      <p>
        We recommend deactivating all other active badges for security purposes. The following badges will be
        deactivated unless you uncheck them.
      </p>
    </>
  );
}
