import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AuthContext } from 'auth';
import type { ReactElement } from 'react';
import { RoutePropsWithSidebar, SidebarContainerProps, SidebarProps } from './types';
import { getClasses } from './utils';
import { MobileSidebar } from './MobileSidebar';
import { TabletSidebar } from './TabletSidebar';
import { DesktopSidebar } from './DesktopSidebar';

export function SidebarContainer(props: SidebarContainerProps): ReactElement {
  const { routes, isSidebarOpen, closeSidebar } = props;
  const { pathname: locationPathname } = useLocation();
  const [appVersion, setAppVersion] = useState<string>('');

  const { currentUser: user } = React.useContext(AuthContext);

  useEffect(() => {
    async function fetchHealthCheck(): Promise<void> {
      const res = await fetch('/health');
      const { version } = await res.json();
      setAppVersion(version);
    }

    fetchHealthCheck();
  }, []);

  const sidebarRoutes = routes.filter((r) => r?.sidebar).sort((a, b) => a.sidebar.order - b.sidebar.order);
  const getRouteSidebarProps = (route: RoutePropsWithSidebar): { isLocked: boolean; isActive: boolean } => {
    const { locked, pathname } = route.sidebar;
    return {
      isLocked: typeof locked === 'function' ? locked(user) : locked,
      isActive: !!matchPath(locationPathname, { path: pathname }),
    };
  };

  const sidebarProps: SidebarProps = {
    isSidebarOpen,
    closeSidebar,
    sidebarRoutes,
    getRouteSidebarProps,
    classes: getClasses(),
    appVersion,
  };

  return (
    <>
      <MobileSidebar {...sidebarProps} />
      <TabletSidebar {...sidebarProps} />
      <DesktopSidebar {...sidebarProps} />
    </>
  );
}
