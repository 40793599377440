import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, Table, TableContainer } from '@odin-labs/components';
import { paginationSizePerPage as limit } from 'utils/constants';
import {
  ContractorTabProps,
  ContractorWorkersFilters,
  Subcontractor,
  contractorUrl,
} from 'containers/contractor/types';
import { getSubcontractorColumns, getSubcontractorFilterItems } from 'containers/contractor/helpers/tables';
import { useSubcontractors } from 'containers/contractor/helpers/useSubcontractors';
import { usePageQueryParams } from 'utils/usePageQueryParams';

export function ContractorSubcontractorsTabContainer(props: ContractorTabProps): ReactElement {
  const { userJobsites: jobsites, contractor, loading, onTabApiChange, refetchContractorData } = props;
  const history = useHistory();

  // currently Can't paginate subcontractors
  const { /* page, */ jobsiteIds, search, updateUrl } = usePageQueryParams();
  // const offset = page * limit;

  /*
  // When API supports direct subcontractor access, 
  // we can use this here instead of at container level

  const {
    data,
    loading,
    error,
  } = useGetContractorContainerContractorSubcontractorsQuery({
        fetchPolicy: 'no-cache',
        variables: {
          contractorId: contractorId,
          includeArchived: true,
          contractorContractorWorkersInput: {},
        },
      });

  const contractor = data?.getContractor;
*/

  const columns = React.useMemo(() => getSubcontractorColumns(), []);
  const filterItems = React.useMemo(
    () => getSubcontractorFilterItems({ jobsites, jobsiteIds, search }),
    [jobsites, jobsiteIds, search],
  );

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<ContractorWorkersFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data }: { data: Subcontractor }): void => {
    history.push(`${contractorUrl}${data?.contractorId}`);
  };

  const subcontractors = useSubcontractors(contractor);
  const subcontractorsCount = subcontractors.length ?? 0;
  const pageCount = Math.ceil(subcontractorsCount / limit);

  React.useEffect(() => onTabApiChange({ refetchData: refetchContractorData }), [refetchContractorData]);

  return (
    <TableContainer className="contractor-subcontractors-container">
      <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
      <Table
        loading={loading}
        columns={columns}
        data={subcontractors}
        initialState={{ pageSize: limit }}
        pageCount={pageCount}
        remote
        enablePagination
        onRowClick={onRowClickHandler}
        onPageChange={onPageChangeHandler}
        disableGlobalFilter
      />
    </TableContainer>
  );
}
