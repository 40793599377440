import React from 'react';
import { Link } from 'react-router-dom';

export type AlreadyExistingItemProps = {
  itemType: 'Email' | 'Phone';
  workerId?: string;
};

export function AlreadyExistingItem(props: AlreadyExistingItemProps): React.ReactElement {
  const { itemType, workerId } = props;
  return (
    <span>
      {`${itemType} already used by other profile`}
      {workerId ? (
        <>
          {', click '}
          <Link to={`/worker/${workerId}`} className="odin-text-odin-primary odin-underline" target="_blank">
            here
          </Link>
          {' to view profile.'}
        </>
      ) : (
        '.'
      )}
    </span>
  );
}
