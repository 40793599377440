import { Checkbox } from '@odin-labs/components';
import React from 'react';
import { ClearanceChecklistHeader } from './ClearanceChecklistHeader';
import { ClearanceEditingNotAvailableContent } from './ClearanceEditingNotAvailableContent';
import { ClearanceChecklistProps, ClearanceSelection } from './types';

export const ClearanceChecklist = React.forwardRef(
  (props: ClearanceChecklistProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value = [], onChange, clearanceEditingEnabled, hideLegend } = props;

    const showChecklist = React.useMemo(() => {
      if (clearanceEditingEnabled !== undefined) return clearanceEditingEnabled;
      if (value.length && !value.some((cs) => cs.clearanceAssignment)) return true;
      return value.some((c) => c.clearance.isSelectable);
    }, [value, clearanceEditingEnabled]);

    const sorted = React.useMemo(() => {
      const autoAssigned = value.filter((c) => !c.clearance.isSelectable);
      const selectable = value
        .filter((c) => c.clearance.isSelectable)
        .sort((a, b) => {
          // sort the entries by placing the default clearances first
          if (a.clearance.isDefault && !b.clearance.isDefault) return -1;
          if (!a.clearance.isDefault && b.clearance.isDefault) return 1;
          return 0;
        });
      return [...autoAssigned, ...selectable];
    }, [value]);

    const instanceSlugs = Array.from(new Set(value.map((c) => c.clearance.cCureEnvironment.instanceSlug)));

    const onCheckChangeHandler = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { id: clearanceId, checked } = e.target;
        const newValue = value?.map<ClearanceSelection>((clearanceSelection) => {
          if (clearanceSelection.clearance.cCureClearanceId === clearanceId) {
            return { ...clearanceSelection, selected: checked };
          }
          return clearanceSelection;
        });
        onChange?.(newValue);
      },
      [value, onChange],
    );

    return (
      <div ref={ref}>
        {showChecklist && (
          <>
            <table className="odin-min-w-full">
              <tbody>
                {sorted.map((clearanceSelection) => {
                  const { clearance, selected } = clearanceSelection;
                  const {
                    cCureClearanceId: id,
                    name,
                    isSelectable,
                    isDefault,
                    cCureEnvironment: { instanceSlug },
                  } = clearance;
                  const disabled = !isSelectable;
                  const label = isDefault ? `${name} *` : name;
                  return (
                    <tr key={id}>
                      <td className="odin-py-0.5">
                        <Checkbox
                          id={id}
                          name={id}
                          label={label}
                          checked={selected}
                          disabled={disabled}
                          onChange={onCheckChangeHandler}
                        />
                      </td>
                      <td className="odin-py-0.5">{instanceSlugs.length > 1 && instanceSlug}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!hideLegend && <ClearanceChecklistHeader />}
          </>
        )}
        {!showChecklist && <ClearanceEditingNotAvailableContent />}
      </div>
    );
  },
);
