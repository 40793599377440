import React, { ReactElement, useEffect, useState } from 'react';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, NewButton, Table } from '@odin-labs/components';
import {
  GetWorkerCardsDocument,
  useGetWorkerCardsQuery,
  WorkerCard,
  WorkerCardType,
  CardPrintJob,
  CardStatus,
  JobsiteWorkerOrientationStatus,
  MutationReissueMobileBadgeArgs,
  QueryGetCurrentCardPrintJobArgs,
  Success,
} from 'apollo/generated/client-operations';
import { to } from 'acl';
import { AuthContext } from 'auth';
import { AlertInstance } from 'components/alertNotification';
import { BadgePreview } from 'components/badgePreview';
import { LoadingError } from 'components/loadingError';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { BaseModal } from 'components/modals';
import { generateApiUrl } from 'config/app';
import {
  GET_CURRENT_CARD_PRINT_JOB,
  getCurrentCardPrintJobResponse,
  REISSUE_MOBILE_BADGE,
} from 'containers/worker/helpers/queries';
import { getOnboardingModule } from 'containers/worker/helpers/utils';
import { AddBadgeModal, AddBluetoothCardModal, DeactivateBadgeModal } from 'containers/worker/modals';
import { getJobsitesOptions } from 'containers/worker/modals/editJobsiteWorkerModal/utils';
import { BadgingState, WorkerTabProps } from 'containers/worker/types';
import { MatchedWorkerCard } from 'containers/workerOnboarding/types';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getCurrentDomain, isQueryLoading, useResettableState } from 'utils';
import { getGraphQLError } from 'utils/error';
import { useLegacyState } from 'utils/useLegacyState';
import { PlusIcon } from 'components/icons';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { WorkerBadgesAlerts } from './WorkerBadgesAlerts';
import { getJobsiteAccessHistoryColumns, getWorkerCardTableColumns } from './WorkerBadges.tables';

export type AddBadgeModalState = {
  isOpen: boolean;
  badgingState?: BadgingState;
  prevBadgingState?: BadgingState;
  workerCardIdToReactivate?: string;
};

export function WorkerBadges(props: WorkerTabProps): ReactElement {
  const {
    worker,
    jobsiteWorkers,
    jobsiteNames,
    showJobsiteAccessHistory = true,
    isLocked,
    refetchWorkerData,
    updateWorkerState,
    onTabApiChange,
  } = props;
  const { workerId } = worker ?? {};

  const {
    value: selectedWorkerCard,
    setValue: openAddBluetoothCardModal,
    resetValue: closeAddBluetoothCardModal,
  } = useResettableState<MatchedWorkerCard>(null, null);

  const {
    value: workerCardToDeactivate,
    setValue: openDeactivateBadgeModal,
    resetValue: closeDeactivateBadgeModal,
  } = useResettableState<MatchedWorkerCard>(null, null);

  const [currentCardPrintJob, setCurrentCardPrintJob] = useState<CardPrintJob>(null);
  const [showBadgeModalOpen, setShowBadgeModalOpen] = useState<boolean>(false);

  const [addBadgeModalState, setAddBadgeModalSate] = useLegacyState<AddBadgeModalState>({ isOpen: false });
  const setBadgingState = React.useCallback(
    (badgingState: BadgingState, workerCardIdToReactivate?: string) =>
      setAddBadgeModalSate((prevState) => ({
        prevBadgingState: prevState.badgingState,
        badgingState,
        workerCardIdToReactivate,
      })),
    [setAddBadgeModalSate],
  );
  const openAddBadgeModal = React.useCallback(
    (badgingState: BadgingState, workerCardIdToReactivate?: string) =>
      setAddBadgeModalSate((prevState) => ({
        prevBadgingState: prevState.badgingState,
        badgingState,
        workerCardIdToReactivate,
        isOpen: true,
      })),
    [setAddBadgeModalSate],
  );
  const closeAddBadgeModal = React.useCallback(() => setAddBadgeModalSate({ isOpen: false }), [setAddBadgeModalSate]);
  const { badgingState, prevBadgingState, workerCardIdToReactivate, isOpen: isAddBadgeModalOpen } = addBadgeModalState;

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorUser = user.isContractor;
  const isFeatureLocked = !user.hasPaidAccess;

  const {
    data: workerCardsData,
    error: workerCardsError,
    networkStatus: workerCardsNetworkStatus,
    refetch: refetchWorkerCards,
  } = useGetWorkerCardsQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
    skip: isLocked || isFeatureLocked || !workerId,
    variables: {
      workerId,
      includeArchived: true,
    },
  });

  // Filter out failed cards from showing up in the UI
  const workerCards = workerCardsData?.getWorkerCards?.filter(
    (wc) => wc.cardStatus !== CardStatus.Failed,
  ) as WorkerCard[];
  const hasExtraActiveCards = React.useMemo(
    () =>
      workerCards?.filter(
        ({ workerCardFormat, cardStatus }) =>
          workerCardFormat?.cardType === WorkerCardType.Proximity && cardStatus === CardStatus.Active,
      ).length > 1,
    [workerCards],
  );

  const hasExtraActiveQRCodes = React.useMemo(
    () =>
      workerCards?.filter(
        ({ workerCardFormat, cardStatus }) =>
          workerCardFormat?.cardType === WorkerCardType.QrCode && cardStatus === CardStatus.Active,
      ).length > 1,
    [workerCards],
  );

  const { data: currentCardPrintJobData, refetch: refetchCurrentCardPrintJob } = useQuery<
    getCurrentCardPrintJobResponse,
    QueryGetCurrentCardPrintJobArgs
  >(GET_CURRENT_CARD_PRINT_JOB, {
    fetchPolicy: 'network-only',
    pollInterval: window.location.href.includes('.local.') ? undefined : 4000,
    skip: !workerId,
    variables: {
      workerId,
    },
  });

  useEffect(() => {
    /**
     * The CreateNewBadgeModalContainer can also set the current card print job (the setter is passed)
     * And there doesn't seem to be a hook connected to polling completion, so use a separate
     * currentCardPrintJob to keep things straight
     */
    setCurrentCardPrintJob(currentCardPrintJobData?.getCurrentCardPrintJob);
  }, [currentCardPrintJobData]);

  const { jobsites: userJobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const openReactivateBadgeModal = React.useCallback(
    (workerCard: MatchedWorkerCard): void => {
      openAddBadgeModal(BadgingState.ReactivateBadge, workerCard.workerCardId);
    },
    [openAddBadgeModal],
  );

  const [reissueMobileBadge] = useMutation<Success, MutationReissueMobileBadgeArgs>(REISSUE_MOBILE_BADGE, {
    onCompleted: () => {
      AlertInstance.alert('tc', 'success', 'Success!', '');
    },
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: [GetWorkerCardsDocument],
  });

  const submitIssueMobileBadge = React.useCallback(
    async (workerCard: MatchedWorkerCard, target?: 'worker' | 'apple' | 'google'): Promise<void> => {
      const { workerCardId } = workerCard;
      if (target === 'apple' || target === 'google') {
        const url = generateApiUrl('wallet', target, 'mobile-badge', 'generate', workerId, workerCardId);
        window.open(url, '_blank');
      } else {
        await reissueMobileBadge({
          variables: {
            workerCardId,
            newPhoneNumber: null,
          },
        });
      }
    },
    [reissueMobileBadge, workerId],
  );

  const { allowedToPrintJobsites, jobsitesOptions, isAddBadgeAvailable } = React.useMemo(() => {
    const allowedJobsiteAccesses = jobsiteWorkers?.filter((jobsiteWorker) => {
      const { jobsiteContractor, orientationStatus } = jobsiteWorker;
      const { jobsite } = jobsiteContractor;
      const { badgeAssignmentAllowed, badgePrintingAllowed } = getOnboardingModule(jobsite.modules) ?? {};

      const userJobsiteIds = userJobsites.map((j) => j.jobsiteId);

      return (
        userJobsiteIds?.includes(jobsite.jobsiteId) &&
        ((badgeAssignmentAllowed ?? true) ||
          badgePrintingAllowed ||
          orientationStatus === JobsiteWorkerOrientationStatus.Complete)
      );
    });
    const allowedJobsites = allowedJobsiteAccesses?.map((jw) => jw.jobsiteContractor.jobsite);
    const allowedJobsiteIds = allowedJobsites.map((j) => j.jobsiteId);

    return {
      allowedToPrintJobsites: allowedJobsites,
      jobsitesOptions: getJobsitesOptions(allowedJobsites),
      isAddBadgeAvailable: userJobsites
        .filter((j) => allowedJobsiteIds.includes(j.jobsiteId))
        .some((j) => j.jobsiteCardFormats?.edges?.length),
    };
  }, [jobsiteWorkers, userJobsites]);

  const badgesColumns = React.useMemo(
    () =>
      getWorkerCardTableColumns({
        openReactivateBadgeModal,
        openDeactivateBadgeModal,
        submitIssueMobileBadge,
        openAddBluetoothCardModal,
        user,
      }),
    [openReactivateBadgeModal, openDeactivateBadgeModal, openAddBluetoothCardModal, user],
  );

  const jobsiteAccessHistoryColumns = React.useMemo(() => getJobsiteAccessHistoryColumns(), []);

  const badges: MatchedWorkerCard[] = React.useMemo(
    () =>
      workerCards
        ?.filter(({ workerCardFormat: { cardType } }) => cardType !== WorkerCardType.Bluetooth)
        .map((wc): MatchedWorkerCard => {
          const matchingWorkerCard =
            workerCards.find((innerWc) => innerWc.matchingWorkerCardId === wc.workerCardId) ?? null;
          return { ...wc, matchingWorkerCard };
        }),
    [workerCards],
  );

  const jobsiteAccessHistory = React.useMemo(
    () => worker?.accessHistory?.filter((access) => jobsiteNames?.includes(access.jobsiteName)) ?? [],
    [worker?.accessHistory, jobsiteNames],
  );

  React.useEffect(() => onTabApiChange?.({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  const error = workerCardsError ?? userJobsitesError;
  if (error) {
    return <LoadingError error={error} />;
  }

  if (isFeatureLocked) {
    return (
      <div className="odin-p-8">
        <LockedFeatureAlert />
      </div>
    );
  }

  const loading = isQueryLoading(workerCardsNetworkStatus) || userJobsitesLoading;

  return (
    <Row>
      <Col xl="12" className="odin-space-y-3">
        <WorkerBadgesAlerts
          currentCardPrintJob={currentCardPrintJob}
          openAddBadgeModal={openAddBadgeModal}
          hasExtraActiveCards={hasExtraActiveCards}
          hasExtraActiveQRCodes={hasExtraActiveQRCodes}
        />
        <Card>
          <CardHeader className="!odin-border-b-0">
            <div className="odin-flex odin-items-center odin-justify-between">
              <div>
                <h3 className="card-header-title odin-mr-1 odin-inline">Badges</h3>
                <Badge text={badges?.length.toString()} color="gray" />
              </div>
              <div>
                {isAddBadgeAvailable && user.isAllowed(to.addBadges) && jobsitesOptions.length && !isContractorUser ? (
                  <NewButton
                    icon={PlusIcon}
                    size={['base', 'md:sm']}
                    onClick={(): void => {
                      openAddBadgeModal(BadgingState.ChooseBadgeType);
                    }}
                    text="Add Badge"
                    hideTextOnMobile
                  />
                ) : null}
              </div>
            </div>
          </CardHeader>
          <Table
            columns={badgesColumns}
            data={badges}
            loading={loading}
            enablePagination={false}
            remote
            cellClassName="!odin-pl-5"
            disableGlobalFilter
            disableSortBy
          />
        </Card>
        {showJobsiteAccessHistory && (
          <Card>
            <CardHeader className="!odin-border-b-0">
              <h3 className="card-header-title">Jobsite access history</h3>
            </CardHeader>
            <Table
              columns={jobsiteAccessHistoryColumns}
              data={jobsiteAccessHistory}
              loading={loading}
              noResultsText="No jobsite access history to show"
              enablePagination={false}
              remote
              disableGlobalFilter
              disableSortBy
            />
          </Card>
        )}
      </Col>
      <AddBadgeModal
        isOpen={isAddBadgeModalOpen}
        badgingState={badgingState}
        prevBadgingState={prevBadgingState}
        setBadgingState={setBadgingState}
        worker={worker}
        jobsites={allowedToPrintJobsites}
        currentCardPrintJob={currentCardPrintJob}
        workerCards={workerCards}
        workerCardIdToReactivate={workerCardIdToReactivate}
        refetchWorkerCards={refetchWorkerCards}
        closeModal={closeAddBadgeModal}
        setCurrentCardPrintJob={setCurrentCardPrintJob}
        refetchWorker={refetchWorkerData}
        refetchCurrentCardPrintJob={refetchCurrentCardPrintJob}
        updateWorkerState={updateWorkerState}
      />
      <AddBluetoothCardModal
        isOpen={!!selectedWorkerCard}
        closeModal={closeAddBluetoothCardModal}
        workerId={workerId}
        workerCard={selectedWorkerCard}
        jobsitesOptions={jobsitesOptions}
      />
      <DeactivateBadgeModal
        isOpen={!!workerCardToDeactivate}
        closeModal={closeDeactivateBadgeModal}
        onConfirm={closeDeactivateBadgeModal}
        workerCardId={workerCardToDeactivate?.workerCardId}
      />
      <BaseModal
        isOpen={showBadgeModalOpen}
        onCancel={(): void => setShowBadgeModalOpen(false)}
        toggle={(): void => setShowBadgeModalOpen(false)}
        shouldHideAction
        cancelText="OK"
      >
        <BadgePreview
          name={`${worker?.user?.identity?.firstName} ${worker?.user?.identity?.lastName}`}
          trade={worker?.trade}
          title={worker?.jobTitle}
          imageUrl={worker?.profilePictureCropped?.downloadUrl}
          qrUrl={`${getCurrentDomain()}/worker/${worker?.workerId}`}
        />
      </BaseModal>
    </Row>
  );
}
