import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { localizeFactory } from 'utils/localization';
import { isSsr } from 'utils';
import { MobileBadgeHeader } from './MobileBadgeHeader';
import { classes } from './MobileBadgeContainer.style';
import { UserValidationStep, MobileBadgeDownloadStep, MobileBadgeStep } from './steps';
import { defaultLanguage } from './utils';

export function MobileBadgeContainer(): ReactElement {
  const { workerId, workerCardId } = useParams<{ workerId: string; workerCardId: string }>();
  const [language, setLanguage] = useState(defaultLanguage);
  const [currentStep, setCurrentStep] = useState(MobileBadgeStep.UserValidation);
  const localize = localizeFactory(language.value);

  if (isSsr()) {
    return null;
  }

  const renderStepContent = (): ReactElement => {
    switch (currentStep) {
      case MobileBadgeStep.UserValidation:
        return (
          <UserValidationStep
            workerId={workerId}
            workerCardId={workerCardId}
            language={language.value}
            onLanguageChange={setLanguage}
            localize={localize}
            setCurrentStep={setCurrentStep}
          />
        );
      case MobileBadgeStep.BadgeDownload:
        return <MobileBadgeDownloadStep workerId={workerId} workerCardId={workerCardId} localize={localize} />;
      default:
        return null;
        break;
    }
  };

  return (
    <div className={classes.fullContainer(false)}>
      <MobileBadgeHeader
        language={language.value}
        onLanguageChange={setLanguage}
        localize={localize}
        canGoToPreviousStep={false}
      />
      <div className={classes.container}>
        <div className={classes.stepContainer}>{renderStepContent()}</div>
      </div>
    </div>
  );
}
