import React from 'react';
import { QueryStatus } from 'containers/jobsiteFormSubmission/print/types';
import { FormSubmissionPrintContext } from 'containers/jobsiteFormSubmission/print/FormSubmissionPrintContext';

export const useUpdateQueryStatus = (queryName: string, queryStatus: QueryStatus): void => {
  const { loading, error } = queryStatus;
  const { updateQueryStatus } = React.useContext(FormSubmissionPrintContext);

  React.useEffect(() => {
    updateQueryStatus(queryName, queryStatus);
  }, [loading, error]);
};
