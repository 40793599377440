import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { dateValidation } from 'utils/validation';
import { UserValidationFormData } from 'containers/userPendingChange/steps/types';

export const getFormInputs = (): TypedFormInputs<UserValidationFormData> => {
  return {
    birthDate: {
      element: FormInputTypes.OdinField,
      label: 'Date of birth',
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'pastDate',
        inputMode: 'numeric',
      },
      validation: { required: true, pattern: dateValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};
