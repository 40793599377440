import moment, { Moment } from 'moment';
import { SelectOptionElement } from '@odin-labs/components';
import { AuthUser } from 'auth/types';
import { to } from 'acl';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { getIcon } from 'utils/ui';
import { JobsiteWorker, Worker } from 'containers/worker/types';
import { JobsiteOptionElement, FALSE, TRUE } from './types';

export type EditJobsiteWorkerFormData = {
  helmetNumber: string;
  firstName: string;
  lastName: string;
  jobsiteId: JobsiteOptionElement;
  hasEscortPrivileges: SelectOptionElement;
  isExemptFromSwipeInactivity: SelectOptionElement;
  payrollId: string;
  isExemptFromSiteSpecificRefresher: SelectOptionElement;
  startDate: Moment;
};

const BriefcaseIcon = getIcon('fal fa-briefcase');

const booleanOptions: SelectOptionElement[] = [
  {
    label: 'Yes',
    value: TRUE,
  },
  {
    label: 'No',
    value: FALSE,
  },
];

export const getFormInputs = ({
  jobsitesOptions,
  user,
}: {
  jobsitesOptions: SelectOptionElement[];
  user: AuthUser;
}): TypedFormInputs<EditJobsiteWorkerFormData> => ({
  firstName: {
    element: FormInputTypes.OdinField,
    label: 'First Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { disabled: true },
  },
  lastName: {
    element: FormInputTypes.OdinField,
    label: 'Last Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { disabled: true },
  },
  jobsiteId: {
    element: FormInputTypes.OdinSelect,
    label: 'Jobsite',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      options: jobsitesOptions,
      icon: BriefcaseIcon,
      disabled: true,
    },
    validation: {
      required: true,
    },
  },
  helmetNumber: {
    element: FormInputTypes.OdinField,
    label: 'Helmet number',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { fieldType: 'number' },
  },
  hasEscortPrivileges: {
    element: FormInputTypes.OdinSelect,
    label: 'Allow escort privileges',
    layout: user.isAllowed(to.seeAllowEscortPrivileges) ? [GridColSpan.SpanFull, GridColSpan.SmSpan3] : 'odin-hidden',
    elementProps: { options: booleanOptions, isClearable: false },
    validation: { required: true },
  },
  isExemptFromSwipeInactivity: {
    element: FormInputTypes.OdinSelect,
    label: 'Exempt from 30 days of inactivity',
    layout: user.isAllowed(to.seeExemptFrom30DaysOfInactivity)
      ? [GridColSpan.SpanFull, GridColSpan.SmSpan3]
      : 'odin-hidden',
    elementProps: { options: booleanOptions, isClearable: false },
    validation: { required: true },
  },
  payrollId: {
    element: FormInputTypes.OdinField,
    label: 'Payroll ID',
    layout: user.isAllowed(to.seePayrollID) ? [GridColSpan.SpanFull, GridColSpan.SmSpan3] : 'odin-hidden',
  },
  isExemptFromSiteSpecificRefresher: {
    element: FormInputTypes.OdinSelect,
    label: 'Exempt from site refresher training',
    layout: user.isAllowed(to.seeExemptFromSiteRefresherTraining)
      ? [GridColSpan.SpanFull, GridColSpan.SmSpan3]
      : 'odin-hidden',
    elementProps: { options: booleanOptions, isClearable: false },
    validation: { required: true },
  },
  startDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'Onboard Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
});

export const getDefaultValues = (args: {
  jobsitesOptions: JobsiteOptionElement[];
  jobsiteWorker: JobsiteWorker;
  worker: Worker;
}): EditJobsiteWorkerFormData => {
  const { worker, jobsiteWorker, jobsitesOptions } = args;
  const {
    stickerNumber,
    isAntipassbackExempt,
    payrollId,
    isExemptFromSwipeInactivity,
    isExemptFromSiteSpecificRefresher,
    startDate,
  } = jobsiteWorker ?? {};
  const { firstName, lastName } = worker?.user?.identity ?? {};

  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    jobsiteId: jobsitesOptions?.[0] ?? null,
    helmetNumber: stickerNumber?.toString() ?? '',
    hasEscortPrivileges: booleanOptions.find((opt) => opt.value === (isAntipassbackExempt ?? false).toString()),
    isExemptFromSwipeInactivity: booleanOptions.find(
      (opt) => opt.value === (isExemptFromSwipeInactivity ?? false).toString(),
    ),
    payrollId: payrollId ?? '',
    isExemptFromSiteSpecificRefresher: booleanOptions.find(
      (opt) => opt.value === (isExemptFromSiteSpecificRefresher ?? false).toString(),
    ),
    startDate: startDate ? moment(startDate) : null,
  };
};
