import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { localizeFactory } from 'utils/localization';
import { LoadingError } from 'components/loadingError';
import { useGetUserPendingChangeQuery } from 'apollo/generated/client-operations';
import { useBoolean } from 'utils';
import { UserValidationStep, ConfirmationStep, PendingChangeInfoStep, ErrorStep, NoPendingChangeStep } from './steps';
import { UserPendingChangeStep, UserPendingChangeStepComponent } from './types';
import { defaultLanguage, resolveChangeType } from './utils';
import { CancelUserChangeModal } from './modals';
import { UserPendingChangeHeader } from './UserPendingChangeHeader';
import { classes } from './UserPendingChangeContainer.style';

const componentsByStep: Record<UserPendingChangeStep, UserPendingChangeStepComponent> = {
  [UserPendingChangeStep.PendingChangeInfo]: PendingChangeInfoStep,
  [UserPendingChangeStep.UserValidation]: UserValidationStep,
  [UserPendingChangeStep.PendingChangeConfirmation]: ConfirmationStep,
  [UserPendingChangeStep.NoPendingChange]: NoPendingChangeStep,
  [UserPendingChangeStep.Error]: ErrorStep,
};

export function UserPendingChangeContainer(): ReactElement {
  const { changeType: changeTypeParam, workerId } = useParams<{ changeType: string; workerId: string }>();
  const [language, setLanguage] = useState(defaultLanguage);
  const [currentStep, setCurrentStep] = useState(UserPendingChangeStep.PendingChangeInfo);
  const localize = localizeFactory(language.value);

  const {
    value: isCancelUserChangeModalOpen,
    setTrue: openCancelUserChangeModal,
    setFalse: closeCancelUserChangeModal,
  } = useBoolean();

  const changeType = resolveChangeType(changeTypeParam);

  const { data, loading, error } = useGetUserPendingChangeQuery({
    fetchPolicy: 'no-cache',
    skip: !workerId || !changeType,
    variables: { workerId, changeType },
  });
  const { change } = data?.getUserPendingChange ?? {};

  if (loading) {
    return <LoadingError loading={loading} />;
  }

  const getStepToRender = (): UserPendingChangeStep => {
    if (error || !changeType) {
      return UserPendingChangeStep.Error;
    }
    if (!change) {
      return UserPendingChangeStep.NoPendingChange;
    }
    return currentStep;
  };

  const renderStepContent = (): ReactElement => {
    const stepToRender = getStepToRender();
    const StepComponent: UserPendingChangeStepComponent = componentsByStep[stepToRender];
    return (
      <StepComponent
        workerId={workerId}
        changeType={changeType}
        change={change}
        language={language.value}
        onLanguageChange={setLanguage}
        localize={localize}
        setCurrentStep={setCurrentStep}
        openCancelUserChangeModal={openCancelUserChangeModal}
      />
    );
  };

  return (
    <div className={classes.fullContainer(false)}>
      <UserPendingChangeHeader
        language={language.value}
        onLanguageChange={setLanguage}
        localize={localize}
        canGoToPreviousStep={false}
      />
      <div className={classes.container}>
        <div className={classes.stepContainer}>{renderStepContent()}</div>
      </div>
      <CancelUserChangeModal
        isOpen={isCancelUserChangeModalOpen}
        onCancel={closeCancelUserChangeModal}
        onConfirm={closeCancelUserChangeModal}
        workerId={workerId}
        changeType={changeType}
      />
    </div>
  );
}
