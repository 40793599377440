import React, { ReactElement } from 'react';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { getFaIcon } from '@odin-labs/components';
import { Jobsite } from 'apollo/generated/client-operations';
import { ModalFormContent } from 'components/form';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { getFormInputsHook, getFormDefaultValues } from './ChooseBadgeTypeModalContent.forms';
import { BadgeTypeOption, ChooseBadgeTypeData } from './types';

const ArrowRightIcon = getFaIcon({ icon: faArrowRight });

export interface ChooseBadgeTypeModalContentProps {
  setOpen: () => void;
  onAction: (jobsiteId: string, badgeType: BadgeTypeOption) => void;
  jobsites: Pick<Jobsite, 'jobsiteId' | 'name'>[];
}

export function ChooseBadgeTypeModalContent(props: ChooseBadgeTypeModalContentProps): ReactElement {
  const { onAction, setOpen, jobsites } = props;

  const onSubmit = (data: ChooseBadgeTypeData): void => {
    const { jobsiteId, badgeType } = data;
    onAction(jobsiteId.value, badgeType);
  };

  const jobsitesOptions = React.useMemo(() => getJobsitesOptions(jobsites), [jobsites]);
  const formInputs = getFormInputsHook({ jobsitesOptions });
  const defaultValues = getFormDefaultValues({ jobsitesOptions });

  return (
    <ModalFormContent
      setOpen={setOpen}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Continue"
      actionIcon={ArrowRightIcon}
    />
  );
}
