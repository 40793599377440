import { SiteAccessBadge } from '@odin-labs/components';
import cn from 'classnames';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { MergeUnionType } from 'types';

import { getFormattedDate } from 'utils';
import { DeveloperJobsiteDetail } from './DeveloperJobsiteDetail';
import { SiteStatusText } from './SiteStatusText';

import { DeveloperJobsiteCardLoadingProps, DeveloperJobsiteCardProps } from './types';

const classes = {
  skeleton: cn('odin-text-transparent odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1'),
  actionsSkeleton: cn('odin-animate-pulse odin-bg-gray-300 odin-h-8.5 odin-w-8.5 odin-rounded-md -odin-ml-1'),
};

export function DeveloperJobsiteCard(
  props: DeveloperJobsiteCardProps | DeveloperJobsiteCardLoadingProps,
): ReactElement {
  const { jobsite, loading } = props as MergeUnionType<DeveloperJobsiteCardProps | DeveloperJobsiteCardLoadingProps>;

  const { addressLine1, addressLine2, addressCity, addressZipCode, addressState, startDate, endDate } = jobsite ?? {};

  const jobsiteAddress = jobsite && (
    <>
      {addressLine1} {addressLine2} <br />
      {[addressCity, addressState].filter(Boolean).join(', ')} {addressZipCode}
    </>
  );

  const today = new Date();
  const isActive = !endDate || new Date(endDate) > today;

  const startDateValue = startDate ? 'Not Set' : getFormattedDate(startDate);

  const jobsiteLink = <Link to={`/jobsite/${jobsite?.jobsiteId}`}>{jobsite?.name}</Link>;
  return (
    <div className="odin-flex odin-gap-x-4 odin-border odin-shadow-odin-normal odin-rounded-md odin-p-6">
      <div className="odin-flex-grow odin-grid odin-grid-cols-1 odin-gap-y-4 sm:odin-grid-cols-3 sm:odin-gap-x-3">
        <div className="odin-space-y-3">
          <h1 className="odin-mb-0 odin-mt-1">
            <span className={cn(loading && classes.skeleton)}>{loading ? 'Loading Jobsite Data' : jobsiteLink}</span>
          </h1>
          <SiteAccessBadge loading={loading} className="odin-flex-grow-0" status={isActive ? 'active' : 'completed'} />
          <SiteStatusText isActive={isActive} endDate={endDate} />
        </div>
        <div className="odin-col-span-2 odin-flex odin-flex-col odin-gap-y-3">
          <DeveloperJobsiteDetail label="Jobsite Address" loading={loading} value={jobsiteAddress} />
          <DeveloperJobsiteDetail label="Start" loading={loading} value={startDateValue} />
        </div>
      </div>
    </div>
  );
}
