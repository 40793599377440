/* eslint-disable max-len */
import { addTranslations } from 'utils/localization';

export enum Copy {
  language = 'language',
  language_selector_label = 'language_selector_label',
  current_phone_number_label = 'current_phone_number_label',
  new_phone_number_label = 'new_phone_number_label',
  current_email_label = 'current_email_label',
  new_email_label = 'new_email_label',
  birth_date_label = 'birth_date_label',
  birth_date_mask_text = 'birth_date_mask_text',
  birth_date_invalid_value_validation = 'birth_date_invalid_value_validation',
  birth_date_missing_value_validation = 'birth_date_missing_value_validation',
  primary_language_label = 'primary_language_label',
  field_required_label = 'field_required_label',
  field_optional_label = 'field_optional_label',
  picklist_mask_general = 'picklist_mask_general',
  thank_you_message = 'thank_you_message',
  continue = 'continue',
  skip_for_now = 'skip_for_now',
  save = 'save',
  cancel = 'cancel',
  add = 'add',
  get_started = 'get_started',
  cancel_change = 'cancel_change',
  auth_validation_error = 'auth_validation_error',
  invalid_image_header = 'invalid_image_header',
  invalid_image_message = 'invalid_image_message',
  phone_pending_change_header = 'phone_pending_change_header',
  phone_pending_change_instructions = 'phone_pending_change_instructions',
  email_pending_change_header = 'email_pending_change_header',
  email_pending_change_instructions = 'email_pending_change_instructions',
  user_validation_header = 'user_validation_header',
  user_validation_phone_change_instructions = 'user_validation_phone_change_instructions',
  user_validation_email_change_instructions = 'user_validation_email_change_instructions',
  pending_change_confirmation_header = 'pending_change_confirmation_header',
  phone_pending_change_confirmation_text = 'phone_pending_change_confirmation_text',
  email_pending_change_confirmation_text = 'email_pending_change_confirmation_text',
  user_pending_change_error_header = 'user_pending_change_error_header',
  user_pending_change_error_text = 'user_pending_change_error_text',
  no_phone_pending_change_header = 'no_phone_pending_change_header',
  no_phone_pending_change_text = 'no_phone_pending_change_text',
  no_email_pending_change_header = 'no_email_pending_change_header',
  no_email_pending_change_text = 'no_email_pending_change_text',
}

const en: Record<Copy, string> = {
  [Copy.language]: 'English',
  [Copy.language_selector_label]: 'Form Language',
  [Copy.current_phone_number_label]: 'Old phone number',
  [Copy.new_phone_number_label]: 'New phone number',
  [Copy.current_email_label]: 'Old email address',
  [Copy.new_email_label]: 'New email address',
  [Copy.birth_date_label]: 'Date of Birth',
  [Copy.birth_date_mask_text]: 'MM/DD/YYYY',
  [Copy.birth_date_invalid_value_validation]: 'Enter a valid date',
  [Copy.birth_date_missing_value_validation]: 'Date of birth required',
  [Copy.primary_language_label]: 'Preferred Language',
  [Copy.field_required_label]: '(Required)',
  [Copy.field_optional_label]: '(Optional)',
  [Copy.picklist_mask_general]: 'Select...',
  [Copy.thank_you_message]: 'Thank you!',
  [Copy.continue]: 'Continue',
  [Copy.skip_for_now]: 'Skip for now',
  [Copy.save]: 'Save',
  [Copy.cancel]: 'Cancel',
  [Copy.add]: 'Add',
  [Copy.get_started]: 'Get Started',
  [Copy.cancel_change]: 'Cancel change',
  [Copy.auth_validation_error]:
    'The date entered does not match what is in Odin. Please see the onsite team to update your date of birth and then resubmit.',
  [Copy.invalid_image_header]: 'Profile photo not accepted',
  [Copy.invalid_image_message]:
    'Please submit a clear photo of your face: be sure to capture your entire face in the center of the photo.',
  [Copy.phone_pending_change_header]: 'Change Phone Number',
  [Copy.phone_pending_change_instructions]:
    '{{userName}} would like to change the phone number associated with your worker profile in Odin',
  [Copy.email_pending_change_header]: 'Change Email Address',
  [Copy.email_pending_change_instructions]:
    '{{userName}} would like to change the email address associated with your worker profile in Odin',
  [Copy.user_validation_header]: 'Verify Your Identity',
  [Copy.user_validation_phone_change_instructions]:
    'To verify your identity and confirm the change to your phone number, please enter your date of birth',
  [Copy.user_validation_email_change_instructions]:
    'To verify your identity and confirm the change to your email address, please enter your date of birth',
  [Copy.pending_change_confirmation_header]: `Congrats, you're all done!`,
  [Copy.phone_pending_change_confirmation_text]:
    'Your phone number has been successfully updated in Odin. No additional action is necessary at this time.',
  [Copy.email_pending_change_confirmation_text]:
    'Your email address has been successfully updated in Odin. No additional action is necessary at this time.',
  [Copy.user_pending_change_error_header]: 'Sorry, something went wrong',
  [Copy.user_pending_change_error_text]:
    'There may be an issue with the information you provided: please check with your on-site supervisor or jobsite staff to complete orientation.',
  [Copy.no_phone_pending_change_header]: 'This change request is no longer valid',
  [Copy.no_phone_pending_change_text]:
    'If you would like to change your phone number in Odin, please see the onsite team.',
  [Copy.no_email_pending_change_header]: 'This change request is no longer valid',
  [Copy.no_email_pending_change_text]:
    'If you would like to change your email address in Odin, please see the onsite team.',
};

const es: Record<Copy, string> = {
  [Copy.language]: 'Español',
  [Copy.language_selector_label]: 'Idioma del formulario',
  [Copy.current_phone_number_label]: 'Número de teléfono antiguo',
  [Copy.new_phone_number_label]: 'Nuevo número de teléfono',
  [Copy.current_email_label]: 'Dirección de correo electrónico antigua',
  [Copy.new_email_label]: 'Nueva dirección de correo electrónico',
  [Copy.birth_date_label]: 'Fecha de nacimiento',
  [Copy.birth_date_mask_text]: 'MES/DÍA/AÑO',
  [Copy.birth_date_invalid_value_validation]: 'Introduce una fecha válida',
  [Copy.birth_date_missing_value_validation]: 'Fecha de nacimiento requerida',
  [Copy.primary_language_label]: 'Idioma preferido',
  [Copy.field_required_label]: '(Obligatorio)',
  [Copy.field_optional_label]: '(Opcional)',
  [Copy.picklist_mask_general]: 'Seleccionar...',
  [Copy.thank_you_message]: '¡Gracias!',
  [Copy.continue]: 'Continuar',
  [Copy.skip_for_now]: 'Saltar por ahora',
  [Copy.save]: 'Guardar',
  [Copy.cancel]: 'Cancelar',
  [Copy.add]: 'Agregar',
  [Copy.get_started]: 'Empezar',
  [Copy.cancel_change]: 'Cancelar cambio',
  [Copy.auth_validation_error]:
    'La fecha ingresada no coincide con la que está en Odin. Consulte al equipo en el sitio para actualizar su fecha de nacimiento y luego volver a enviarla.',
  [Copy.invalid_image_header]: 'Foto de perfil no aceptada',
  [Copy.invalid_image_message]: 'Vuelva a tomar la foto: asegúrese de capturar toda su cara en el centro de la foto.',
  [Copy.phone_pending_change_header]: 'Cambiar número de teléfono',
  [Copy.phone_pending_change_instructions]:
    '{{userName}} desea cambiar el número de teléfono asociado con su perfil de trabajador en Odin',
  [Copy.email_pending_change_header]: 'Cambiar dirección de correo electrónico',
  [Copy.email_pending_change_instructions]:
    '{{userName}} desea cambiar la dirección de correo electrónico asociada con su perfil de trabajador en Odin',
  [Copy.user_validation_header]: 'Verifica tu identidad',
  [Copy.user_validation_phone_change_instructions]:
    'Para verificar su identidad y confirmar el cambio en su número de teléfono, ingrese su fecha de nacimiento',
  [Copy.user_validation_email_change_instructions]:
    'Para verificar su identidad y confirmar el cambio en su dirección de correo electrónico, ingrese su fecha de nacimiento',
  [Copy.pending_change_confirmation_header]: `Felicidades, ¡ya has terminado!`,
  [Copy.phone_pending_change_confirmation_text]:
    'Tu número de teléfono se ha actualizado correctamente en Odin. No es necesaria ninguna acción adicional en este momento.',
  [Copy.email_pending_change_confirmation_text]:
    'Su dirección de correo electrónico se ha actualizado correctamente en Odin. No es necesaria ninguna acción adicional en este momento.',
  [Copy.user_pending_change_error_header]: 'Lo sentimos, algo ha ido mal',
  [Copy.user_pending_change_error_text]:
    'Es posible que haya algún problema con la información que ha facilitado: consulte con su supervisor o con el personal del lugar de trabajo para completar la orientación.',
  [Copy.no_phone_pending_change_header]: 'Esta solicitud de cambio ya no es válida',
  [Copy.no_phone_pending_change_text]:
    'Si desea cambiar su número de teléfono en Odin, consulte al equipo en el sitio.',
  [Copy.no_email_pending_change_header]: 'Esta solicitud de cambio ya no es válida',
  [Copy.no_email_pending_change_text]:
    'Si desea cambiar su dirección de correo electrónico en Odin, consulte al equipo en el sitio.',
};

const zhCN: Record<Copy, string> = {
  [Copy.language]: '简体中文',
  [Copy.language_selector_label]: '表单语言',
  [Copy.current_phone_number_label]: '旧电话号码',
  [Copy.new_phone_number_label]: '新电话号码',
  [Copy.current_email_label]: '旧电子邮件地址',
  [Copy.new_email_label]: '新电子邮件地址',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '请输入一个有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.primary_language_label]: '首选语言',
  [Copy.field_required_label]: '(必填）',
  [Copy.field_optional_label]: '(可选）',
  [Copy.picklist_mask_general]: '选择...',
  [Copy.thank_you_message]: '谢谢！',
  [Copy.continue]: '继续',
  [Copy.skip_for_now]: '暂时跳过',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.add]: '添加',
  [Copy.get_started]: '开始吧',
  [Copy.cancel_change]: '取消更改',
  [Copy.auth_validation_error]: '输入的日期与奥丁中的日期不符。 请联系现场团队更新您的出生日期，然后重新提交。',
  [Copy.invalid_image_header]: '个人资料照片不被接受',
  [Copy.invalid_image_message]: '请重新拍摄照片：请务必将您的整个脸部拍摄到照片的中央。',
  [Copy.phone_pending_change_header]: '更改电话号码',
  [Copy.phone_pending_change_instructions]: '{{userName}}想要更改与您在 Odin 中的工作人员资料关联的电话号码',
  [Copy.email_pending_change_header]: '更改电子邮件地址',
  [Copy.email_pending_change_instructions]: '{{userName}} 希望更改与您在 Odin 中的工作人员个人资料关联的电子邮件地址',
  [Copy.user_validation_header]: '验证您的身份',
  [Copy.user_validation_phone_change_instructions]: '为了验证您的身份并确认对您的电话号码的更改，请输入您的出生日期',
  [Copy.user_validation_email_change_instructions]:
    '为了验证您的身份并确认对您的电子邮件地址的更改，请输入您的出生日期',
  [Copy.pending_change_confirmation_header]: `恭喜，您已经完成了！`,
  [Copy.phone_pending_change_confirmation_text]: '您的电话号码已在 Odin 中成功更新。 目前无需采取任何额外行动',
  [Copy.email_pending_change_confirmation_text]: '您的电子邮件地址已在 Odin 中成功更新。 目前无需采取任何额外行动',
  [Copy.user_pending_change_error_header]: '抱歉,出了一些问题',
  [Copy.user_pending_change_error_text]: '您提供的信息可能有问题：请咨询您的现场主管或现场工作人员以完成入职培训。',
  [Copy.no_phone_pending_change_header]: '此更改请求不再有效',
  [Copy.no_phone_pending_change_text]: '如果您想更改奥丁的电话号码，请联系现场团队。',
  [Copy.no_email_pending_change_header]: '此更改请求不再有效',
  [Copy.no_email_pending_change_text]: '如果您想更改 Odin 中的电子邮件地址，请联系现场团队。',
};

const zhTW: Record<Copy, string> = {
  [Copy.language]: '繁體中文',
  [Copy.language_selector_label]: '表單語言',
  [Copy.current_phone_number_label]: '舊電話號碼',
  [Copy.new_phone_number_label]: '新電話號碼',
  [Copy.current_email_label]: '舊電子郵件地址',
  [Copy.new_email_label]: '新電子郵件地址',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '請輸入一個有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.primary_language_label]: '首選語言',
  [Copy.field_required_label]: '(必填）',
  [Copy.field_optional_label]: '(可選）',
  [Copy.picklist_mask_general]: '選擇...',
  [Copy.thank_you_message]: '謝謝！',
  [Copy.continue]: '繼續',
  [Copy.skip_for_now]: '暫時跳過',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.add]: '添加',
  [Copy.get_started]: '開始吧',
  [Copy.cancel_change]: '取消更改',
  [Copy.auth_validation_error]: '輸入的日期與奧丁中的日期不符。 請聯絡現場團隊更新您的出生日期，然後重新提交',
  [Copy.invalid_image_header]: '個人資料照片不被接受',
  [Copy.invalid_image_message]: '請重新拍攝照片：請務必將您的整個臉部拍攝到照片的中央。',
  [Copy.phone_pending_change_header]: '更改電話號碼',
  [Copy.phone_pending_change_instructions]: '{{userName}}想要更改與您在 Odin 中的工作人員資料關聯的電話號碼',
  [Copy.email_pending_change_header]: '更改電子郵件地址',
  [Copy.email_pending_change_instructions]: '{{userName}} 希望更改與您在 Odin 中的工作人員個人資料關聯的電子郵件地址',
  [Copy.user_validation_header]: '驗證您的身分',
  [Copy.user_validation_phone_change_instructions]: '為了驗證您的身份並確認對您的電話號碼的更改，請輸入您的出生日期',
  [Copy.user_validation_email_change_instructions]:
    '為了驗證您的身份並確認對您的電子郵件地址的更改，請輸入您的出生日期',
  [Copy.pending_change_confirmation_header]: `恭喜，您已經完成了！`,
  [Copy.phone_pending_change_confirmation_text]: '您的電話號碼已在 Odin 中成功更新。 目前無需採取任何額外行動',
  [Copy.email_pending_change_confirmation_text]: '您的電子郵件地址已在 Odin 中成功更新。 目前無需採取任何額外行動',
  [Copy.user_pending_change_error_header]: '抱歉,出了一些問題',
  [Copy.user_pending_change_error_text]: '您提供的信息可能有問題：請諮詢您的現場主管或現場工作人員以完成入職培訓。',
  [Copy.no_phone_pending_change_header]: '此更改請求不再有效',
  [Copy.no_phone_pending_change_text]: '如果您想更改奧丁的電話號碼，請聯繫現場團隊。',
  [Copy.no_email_pending_change_header]: '此更改請求不再有效',
  [Copy.no_email_pending_change_text]: '如果您想更改 Odin 中的電子郵件地址，請聯繫現場團隊。',
};

const pl: Record<Copy, string> = {
  [Copy.language]: 'Polski',
  [Copy.language_selector_label]: 'Język formularza',
  [Copy.current_phone_number_label]: 'Stary numer telefonu',
  [Copy.new_phone_number_label]: 'Nowy numer telefonu',
  [Copy.current_email_label]: 'Stary adres e-mail',
  [Copy.new_email_label]: 'Nowy adres email',
  [Copy.birth_date_label]: 'Data urodzenia',
  [Copy.birth_date_mask_text]: 'MM/DD/RRRR',
  [Copy.birth_date_invalid_value_validation]: 'Wpisz poprawną datę',
  [Copy.birth_date_missing_value_validation]: 'Podanie daty urodzenia jest wymagane',
  [Copy.primary_language_label]: 'Preferowany język',
  [Copy.field_required_label]: '(wymagane)',
  [Copy.field_optional_label]: '(opcjonalne)',
  [Copy.picklist_mask_general]: 'Wybierz...',
  [Copy.thank_you_message]: 'Dziękuję Ci!',
  [Copy.continue]: 'Kontynuuj',
  [Copy.skip_for_now]: 'Pomiń to na razie',
  [Copy.save]: 'Zapisz',
  [Copy.cancel]: 'Anuluj',
  [Copy.add]: 'Dodać',
  [Copy.get_started]: 'Zaczynaj',
  [Copy.cancel_change]: 'Anuluj zmianę',
  [Copy.auth_validation_error]:
    'Wprowadzona data nie jest zgodna z datą znajdującą się w Odynie. Skontaktuj się z zespołem na miejscu, aby zaktualizować swoją datę urodzenia, a następnie prześlij ją ponownie.',
  [Copy.invalid_image_header]: 'Zdjęcie profilowe nie zostało zaakceptowane',
  [Copy.invalid_image_message]: 'Zrób zdjęcie ponownie: pamiętaj, aby uchwycić całą twarz na środku zdjęcia.',
  [Copy.phone_pending_change_header]: 'Zmień numer telefonu',
  [Copy.phone_pending_change_instructions]:
    '{{userName}} chce zmienić numer telefonu powiązany z Twoim profilem pracownika w Odinie',
  [Copy.email_pending_change_header]: 'Zmień adres email',
  [Copy.email_pending_change_instructions]:
    '{{userName}} chce zmienić adres e-mail powiązany z Twoim profilem pracownika w Odinie',
  [Copy.user_validation_header]: 'Zweryfikować Twoją tożsamość',
  [Copy.user_validation_phone_change_instructions]:
    'Aby zweryfikować swoją tożsamość i potwierdzić zmianę numeru telefonu, prosimy o podanie daty urodzenia',
  [Copy.user_validation_email_change_instructions]:
    'Aby zweryfikować swoją tożsamość i potwierdzić zmianę adresu e-mail, prosimy o podanie daty urodzenia',
  [Copy.pending_change_confirmation_header]: `Gratulacje, wszystko jest już gotowe!`,
  [Copy.phone_pending_change_confirmation_text]:
    'Twój numer telefonu został pomyślnie zaktualizowany w Odinie. W tej chwili nie są konieczne żadne dodatkowe działania.',
  [Copy.email_pending_change_confirmation_text]:
    'Twój adres e-mail został pomyślnie zaktualizowany w Odinie. W tej chwili nie są konieczne żadne dodatkowe działania.',
  [Copy.user_pending_change_error_header]: 'Przepraszamy, coś poszło nie tak',
  [Copy.user_pending_change_error_text]:
    'Możliwe, że wystąpił problem z podanymi danymi: skontaktuj się ze swoim przełożonym lub personelem w miejscu pracy, aby uzyskać pełne przeszkolenie.',
  [Copy.no_phone_pending_change_header]: 'To żądanie zmiany nie jest już ważne',
  [Copy.no_phone_pending_change_text]:
    'Jeśli chcesz zmienić swój numer telefonu w Odinie, skontaktuj się z zespołem na miejscu.',
  [Copy.no_email_pending_change_header]: 'To żądanie zmiany nie jest już ważne',
  [Copy.no_email_pending_change_text]:
    'Jeśli chcesz zmienić swój adres e-mail w Odinie, skontaktuj się z zespołem na miejscu.',
};

const ru: Record<Copy, string> = {
  [Copy.language]: 'Русский',
  [Copy.language_selector_label]: 'Язык формы',
  [Copy.current_phone_number_label]: 'Старый номер телефона',
  [Copy.new_phone_number_label]: 'Новый номер телефона',
  [Copy.current_email_label]: 'Старый адрес электронной почты',
  [Copy.new_email_label]: 'Новый E-mail адрес',
  [Copy.birth_date_label]: 'Дата рождения',
  [Copy.birth_date_mask_text]: 'ММ/ДД/ГГГГ',
  [Copy.birth_date_invalid_value_validation]: 'Введите действительное значение для поля "Дата рождения"',
  [Copy.birth_date_missing_value_validation]: 'Требуется заполнить поле "Дата рождения"',
  [Copy.primary_language_label]: 'Предпочитаемый язык',
  [Copy.field_required_label]: '(Обязательно)',
  [Copy.field_optional_label]: '(Необязательно)',
  [Copy.picklist_mask_general]: 'Выберите...',
  [Copy.thank_you_message]: 'Благодарю вас!',
  [Copy.continue]: 'Продолжить',
  [Copy.skip_for_now]: 'Пропустить сейчас',
  [Copy.save]: 'Сохранить',
  [Copy.cancel]: 'Отменить',
  [Copy.add]: 'Добавлять',
  [Copy.get_started]: 'Начать',
  [Copy.cancel_change]: 'Отменить изменение',
  [Copy.auth_validation_error]:
    'Введенная дата не соответствует дате, указанной в Odin. Пожалуйста, обратитесь к местной команде, чтобы обновить дату вашего рождения, а затем отправьте заявку повторно.',
  [Copy.invalid_image_header]: 'Фотография профиля не принята',
  [Copy.invalid_image_message]:
    'Пожалуйста, сделайте фотографию еще раз: обязательно запечатлейте все свое лицо в центре фотографии.',
  [Copy.phone_pending_change_header]: 'Изменить номер телефона',
  [Copy.phone_pending_change_instructions]:
    'Пользователь {{userName}} хотел бы изменить номер телефона, связанный с вашим рабочим профилем в Odin',
  [Copy.email_pending_change_header]: 'Изменить адрес электронной почты',
  [Copy.email_pending_change_instructions]:
    'Пользователь {{userName}} хотел бы изменить адрес электронной почты, связанный с вашим рабочим профилем в Odin',
  [Copy.user_validation_header]: 'Подтвердите вашу личность',
  [Copy.user_validation_phone_change_instructions]:
    'Чтобы подтвердить свою личность и подтвердить изменение номера телефона, введите дату рождения',
  [Copy.user_validation_email_change_instructions]:
    'Чтобы подтвердить свою личность и подтвердить изменение адреса электронной почты, введите дату рождения',
  [Copy.pending_change_confirmation_header]: `Поздравляем, все готово!`,
  [Copy.phone_pending_change_confirmation_text]:
    'Ваш номер телефона был успешно обновлен в Odin. Никаких дополнительных действий на данный момент не требуется.',
  [Copy.email_pending_change_confirmation_text]:
    'Ваш адрес электронной почты был успешно обновлен в Odin. Никаких дополнительных действий на данный момент не требуется.',
  [Copy.user_pending_change_error_header]: 'Извините, что-то пошло не так',
  [Copy.user_pending_change_error_text]:
    'Возможно, возникла проблема с предоставленной вами информацией: пожалуйста, обратитесь к непосредственному руководителю или работникам на стройплощадке.',
  [Copy.no_phone_pending_change_header]: 'Этот запрос на изменение больше недействителен',
  [Copy.no_phone_pending_change_text]:
    'Если вы хотите изменить свой номер телефона в Odin, обратитесь к местной команде.',
  [Copy.no_email_pending_change_header]: 'Этот запрос на изменение больше недействителен',
  [Copy.no_email_pending_change_text]:
    'Если вы хотите изменить свой адрес электронной почты в Odin, обратитесь к местной команде.',
};

const hu: Record<Copy, string> = {
  [Copy.language]: 'Magyar',
  [Copy.language_selector_label]: 'Formanyelv',
  [Copy.current_phone_number_label]: 'Régi telefonszám',
  [Copy.new_phone_number_label]: 'Új telefonszám',
  [Copy.current_email_label]: 'Régi e-mail cím',
  [Copy.new_email_label]: 'Új e-mail cím',
  [Copy.birth_date_label]: 'Születési dátum',
  [Copy.birth_date_mask_text]: 'HH/NN/ÉÉÉÉ',
  [Copy.birth_date_invalid_value_validation]: 'Adjon meg egy érvényes dátumot',
  [Copy.birth_date_missing_value_validation]: 'Születési dátum kötelező',
  [Copy.primary_language_label]: 'Preferált nyelv',
  [Copy.field_required_label]: '(Kötelező)',
  [Copy.field_optional_label]: '(Nem kötelező)',
  [Copy.picklist_mask_general]: 'Válassza ki...',
  [Copy.thank_you_message]: 'Köszönöm!',
  [Copy.continue]: 'Folytatni',
  [Copy.skip_for_now]: 'Most hagyd ki',
  [Copy.save]: 'Menteni',
  [Copy.cancel]: 'Megszüntetni',
  [Copy.add]: 'Hozzáadás',
  [Copy.get_started]: 'Fogj neki',
  [Copy.cancel_change]: 'Módosítás visszavonása',
  [Copy.auth_validation_error]:
    'A beírt dátum nem egyezik az Odinban szereplővel. Kérjük, keresse fel a helyszíni csapatot, hogy frissítse születési dátumát, majd küldje be újra.',
  [Copy.invalid_image_header]: 'A profilkép nem fogadható el',
  [Copy.invalid_image_message]:
    'Kérjük, készítse újra a fényképet: ügyeljen arra, hogy a teljes arcát rögzítse a fénykép közepén.',
  [Copy.phone_pending_change_header]: 'Telefonszám módosítása',
  [Copy.phone_pending_change_instructions]:
    '{{userName}} szeretné megváltoztatni az Ön Odinban lévő dolgozói profiljához társított telefonszámot',
  [Copy.email_pending_change_header]: 'Email cím megváltoztatása',
  [Copy.email_pending_change_instructions]:
    '{{userName}} szeretné megváltoztatni az Ön Odinban lévő dolgozói profiljához társított e-mail címet',
  [Copy.user_validation_header]: 'Igazolja a személyazonosságát',
  [Copy.user_validation_phone_change_instructions]:
    'A személyazonossága igazolásához és a telefonszám módosításának megerősítéséhez adja meg születési dátumát',
  [Copy.user_validation_email_change_instructions]:
    'A személyazonossága igazolásához és az e-mail cím módosításának megerősítéséhez adja meg születési dátumát',
  [Copy.pending_change_confirmation_header]: `Gratulálunk, minden kész!`,
  [Copy.phone_pending_change_confirmation_text]:
    'A telefonszámát sikeresen frissítettük az Odinban. Jelenleg nincs szükség további teendőkre.',
  [Copy.email_pending_change_confirmation_text]:
    'Az e-mail címét sikeresen frissítettük az Odinban. Jelenleg nincs szükség további teendőkre.',
  [Copy.user_pending_change_error_header]: 'Sajnáljuk, valami rosszul sült el',
  [Copy.user_pending_change_error_text]:
    'Lehet, hogy probléma van az Ön által megadott adatokkal: kérjük, érdeklődjön a helyszíni felügyelőjénél vagy a munkaterület munkatársainál, hogy befejezhesse az eligazodást.',
  [Copy.no_phone_pending_change_header]: 'Ez a módosítási kérelem már nem érvényes',
  [Copy.no_phone_pending_change_text]:
    'Ha meg szeretné változtatni telefonszámát Odinban, forduljon a helyszíni csapathoz.',
  [Copy.no_email_pending_change_header]: 'Ez a módosítási kérelem már nem érvényes',
  [Copy.no_email_pending_change_text]:
    'Ha meg szeretné változtatni az e-mail címét az Odinban, forduljon a helyszíni csapathoz.',
};

export const Languages = {
  en,
  es,
  zhCN,
  zhTW,
  pl,
  ru,
  hu,
};

addTranslations({ ...Languages, en_US: en });
