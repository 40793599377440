import React from 'react';
import { useAuthenticateLimitedTokenDetailsMutation } from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { Copy } from 'containers/facialRecognition/helpers/languages';
import { StepActions } from 'containers/facialRecognition/steps/components/StepActions';
import { StepInfo } from 'containers/facialRecognition/steps/components/StepInfo';
import { StepInfoTitle } from 'containers/facialRecognition/steps/components/StepInfoTitle';
import { UserValidationFormData } from 'containers/facialRecognition/steps/types';
import { FacialRecognitionStep, FacialRecognitionStepProps } from 'containers/facialRecognition/types';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { isNotEmpty, useIsMounted } from 'utils';
import { setJwtCookie } from 'utils/cookies';
import { getGraphQLError } from 'utils/error';
import { getFormInputs } from './UserValidationStep.forms';

export function UserValidationStep(props: FacialRecognitionStepProps): React.ReactElement {
  const { localize, language, setCurrentStep, workerId, stepNumberInfo } = props;
  // const urlSearchParams = useQueryParams();
  // let mobilePlatform = urlSearchParams.get('mobilePlatform');

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState(false);
  const [authenticateLimitedTokenDetails] = useAuthenticateLimitedTokenDetailsMutation();

  const onSubmit: FormOnSubmit<UserValidationFormData> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      if (isNotEmpty(dirtyFields)) {
        const { birthDate } = data;
        const response = await authenticateLimitedTokenDetails({ variables: { input: { birthDate, workerId } } });
        const { token } = response.data.authenticateLimitedTokenDetails;

        if (token) {
          setJwtCookie({ accessToken: token });
          setCurrentStep(FacialRecognitionStep.ProfilePhoto);
        } else {
          AlertInstance.alert('tc', 'danger', 'Authentication Failed', 'Authentication was unsuccessful');
        }
      }
    } catch (error) {
      event.preventDefault();
      if ((error as Error)?.message?.includes('passwordless')) {
        formApi.setError('birthDate', {
          message: localize(Copy.auth_validation_error),
          shouldFocus: true,
        });
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const inputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo((): UserValidationFormData => ({ birthDate: '' }), []);
  const localization = React.useMemo(() => ({ localize, copy: Copy }), [localize]);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.facial_recognition_header)}
          subtitle={localize(Copy.auth_validation_instructions)}
          description={localize(Copy.auth_validation_body)}
          stepNumberInfo={stepNumberInfo}
        />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        renderBelow={<StepActions localize={localize} actions="continue" continueActionWithSpinner={isSaving} />}
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        localization={localization}
      />
    </div>
  );
}
