import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import type { User } from 'auth';
import { areFeatureFlagsEnabled, getProviderConfig } from './config';

type LDProvider = React.FunctionComponent<{ children: React.ReactNode }>;

export const useLDProvider = (user: User): LDProvider => {
  const { userAccountId } = user ?? {};

  const [ldProvider, setLDProvider] = React.useState<LDProvider>();

  React.useEffect(() => {
    if (userAccountId && areFeatureFlagsEnabled()) {
      const providerConfig = getProviderConfig(user);
      asyncWithLDProvider(providerConfig).then((component) => setLDProvider(() => component));
    }
  }, [userAccountId]);

  return ldProvider;
};
