import React, { ReactElement } from 'react';
import { NewButton } from '@odin-labs/components';
import { AuthContext } from 'auth';

export function GeneralInformation(): ReactElement {
  const { currentUser: user, authState: auth } = React.useContext(AuthContext);
  const { firstName, lastName, email } = user?.identity ?? {};

  return (
    <>
      <div className="odin-bg-white odin-shadow odin-overflow-hidden sm:odin-rounded-lg">
        <div className="odin-px-4 odin-py-5 sm:odin-px-6">
          <h3 className="odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900">Account Information</h3>
          <p className="odin-mt-1 odin-max-w-2xl odin-text-sm odin-text-gray-500">Personal details</p>
        </div>
        <div className="odin-border-t odin-border-gray-200 odin-px-4 odin-py-5 sm:odin-p-0">
          <div className="odin-px-4 odin-py-5 sm:odin-p-0">
            <dl className="sm:odin-divide-y sm:odin-divide-gray-200 odin-mb-0">
              <div className="odin-py-4 sm:odin-py-5 sm:odin-grid sm:odin-grid-cols-3 sm:odin-gap-4 sm:odin-px-6">
                <dt className="odin-text-sm odin-font-medium odin-text-gray-500">Name</dt>
                <dd className="odin-mt-1 odin-text-sm odin-text-gray-900 sm:odin-mt-0 sm:odin-col-span-2">
                  {firstName} {lastName}
                </dd>
              </div>
              <div className="odin-py-4 sm:odin-py-5 sm:odin-grid sm:odin-grid-cols-3 sm:odin-gap-4 sm:odin-px-6">
                <dt className="odin-text-sm odin-font-medium odin-text-gray-500">Email</dt>
                <dd className="odin-mt-1 odin-text-sm odin-text-gray-900 sm:odin-mt-0 sm:odin-col-span-2">{email}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <NewButton theme="danger" text="Logout" className="mt-4" onClick={auth.signOut} />
    </>
  );
}
