import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { WorkerBaseInfoInput } from 'apollo/generated/client-operations';
import { FormInputTypes, GridColSpan, TypedFormInputs, UseFormMethods, UseInputs } from 'components/form';
import moment from 'moment/moment';
import React from 'react';
import { DeepMap } from 'react-hook-form';
import { ensureNonUndefinedFields } from 'utils';
import { primaryLanguageOptions as primaryLanguageOptionsValues, toFancySelectOptions } from 'utils/constants';
import { momentFormatter } from 'utils/dates';
import { dateValidation } from 'utils/validation';
import { languageOptions } from 'containers/mobileBadge/utils';
import { UserValidationFormData } from './types';

export const primaryLanguageOptions = toFancySelectOptions(primaryLanguageOptionsValues);
const getFormInputs = (): TypedFormInputs<UserValidationFormData> => {
  return {
    birthDate: {
      element: FormInputTypes.OdinField,
      label: 'Date of birth',
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'pastDate',
        inputMode: 'numeric',
      },
      validation: { required: true, pattern: dateValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};

const getPrimaryLanguage = (language: string): SelectOptionElement => {
  const languageName = languageOptions.find((opt) => opt.value === language).label ?? 'English';
  return primaryLanguageOptions.find((opt) => languageName.startsWith(opt.value)) ?? null;
};

export const getFormInputsHook =
  (args: { language: string }): UseInputs<UserValidationFormData> =>
  ({ setValue }: UseFormMethods<UserValidationFormData>): TypedFormInputs<UserValidationFormData> => {
    const { language } = args;

    useDidUpdateEffect(() => {
      const primaryLanguage = getPrimaryLanguage(language);
      setValue('primaryLanguage', primaryLanguage, { shouldDirty: true });
    }, [language]);

    return React.useMemo(() => getFormInputs(), []);
  };

export type MobileBadgeUserValidationInput = Pick<WorkerBaseInfoInput, 'birthDate'> & { workerId: string };

export const getUpdateInput = (
  workerId: string,
  data: UserValidationFormData,
  dirtyFields: DeepMap<UserValidationFormData, true>,
): MobileBadgeUserValidationInput => {
  const birthDateInputValue = dirtyFields.birthDate
    ? moment(data.birthDate, momentFormatter).toDate() || null
    : undefined;

  return ensureNonUndefinedFields<MobileBadgeUserValidationInput>({
    workerId,
    birthDate: birthDateInputValue,
  });
};
