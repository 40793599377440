import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { FormGroup } from 'reactstrap';

import { DatePickerProps } from './types';
import * as styles from './scss/date-picker.scss';

const cx = classNames.bind(styles);

type RenderMethodType = React.ComponentProps<typeof Flatpickr>['render'];

type CustomInputProps = Omit<DateTimePickerProps, 'options' | 'render'> & {
  icon: () => ReactElement;
  onIconClick: () => void;
};

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ defaultValue, icon: Icon, onIconClick }: CustomInputProps, ref) => {
    return (
      <div className="date-picker-container">
        <span onClick={onIconClick}>{Icon && <Icon />}</span>
        <input className="odin-ml-1.5" defaultValue={defaultValue} ref={ref} />
      </div>
    );
  },
);

export function DatePicker(props: DatePickerProps): ReactElement {
  const { name, label, error, className, icon, ...restProps } = props;
  const pickerRef = React.useRef<Flatpickr>();

  const onIconClick = (): void => {
    pickerRef.current?.flatpickr.open();
  };

  const renderMethod: RenderMethodType = icon
    ? (renderProps, ref): ReactElement => {
        return <CustomInput icon={icon} onIconClick={onIconClick} {...renderProps} ref={ref} />;
      }
    : undefined;

  return (
    <FormGroup className={cx('date-picker')}>
      {label ? (
        <div className="odin-field">
          <label htmlFor={name} className="odin-field__label">
            {label}
          </label>
        </div>
      ) : null}
      <Flatpickr
        name={name}
        className={cx('form-control', { 'is-invalid': error }, className)}
        render={renderMethod}
        ref={pickerRef}
        {...restProps}
      />
      {error ? <div className={cx('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div> : null}
    </FormGroup>
  );
}
