import React from 'react';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { UserAssignmentType, UserRoleKey, UserUpdateAssignmentInput } from 'apollo/generated/client-operations';
import {
  FormInputTypes,
  GridColSpan,
  TypedFormInputs,
  UseInputs,
  UseFormMethods,
  getUpdateInputValueFunction,
} from 'components/form';
import { DeepMap } from 'react-hook-form';
import { ensureNonUndefinedFields } from 'utils';
import { EditUserRoleFormData, UserRoleType } from './types';
import { getUserRoleType } from './utils';

export type EditUserRoleInputsArgs = {
  editType: 'create' | 'update';
  userRolesOptions: SelectOptionElement<UserRoleKey>[];
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  userRoleType?: UserRoleType;
};

const getFormInputs = ({
  editType,
  userRoleType,
  userRolesOptions,
  contractorsOptions,
  jobsitesOptions,
}: EditUserRoleInputsArgs): TypedFormInputs<EditUserRoleFormData> => ({
  userRoleKey: {
    element: FormInputTypes.OdinSelect,
    label: 'User Role',
    elementProps: {
      placeholder: 'Please choose',
      options: userRolesOptions,
      disabled: (userRolesOptions?.length ?? 0) < 2,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  title: {
    element: FormInputTypes.OdinField,
    label: 'Job Title',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  contractorId: {
    element: FormInputTypes.OdinSelect,
    label: 'Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: contractorsOptions,
      disabled: editType === 'update' || (contractorsOptions?.length ?? 0) < 2,
    },
    validation: {
      required: userRoleType === UserAssignmentType.Contractor,
    },
    layout: userRoleType === UserAssignmentType.Contractor ? GridColSpan.SpanFull : 'odin-hidden',
  },
  jobsiteIds: {
    element: FormInputTypes.OdinSelect,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsites',
      options: jobsitesOptions,
      disabled: editType === 'update' || (jobsitesOptions?.length ?? 0) < 2,
      multiple: true,
    },
    validation: {
      required: userRoleType === UserAssignmentType.Jobsite,
    },
    layout: userRoleType === UserAssignmentType.Jobsite ? GridColSpan.SpanFull : 'odin-hidden',
  },
});

export const getFormInputsHook =
  (args: EditUserRoleInputsArgs): UseInputs<EditUserRoleFormData> =>
  ({ watch, setValue }: UseFormMethods<EditUserRoleFormData>): TypedFormInputs<EditUserRoleFormData> => {
    const { userRolesOptions, contractorsOptions, jobsitesOptions, editType } = args;
    const selectedUserRoleOption = watch('userRoleKey');
    const userRoleType = args.userRoleType ?? getUserRoleType(selectedUserRoleOption?.value);

    useDidUpdateEffect(() => {
      if (userRoleType === UserAssignmentType.Jobsite) {
        setValue('contractorId', null);
      } else if (userRoleType === UserAssignmentType.Contractor) {
        setValue('jobsiteIds', null);
      }
    }, [userRoleType]);

    return React.useMemo(
      () => getFormInputs({ editType, userRoleType, userRolesOptions, contractorsOptions, jobsitesOptions }),
      [editType, userRoleType, userRolesOptions, contractorsOptions, jobsitesOptions],
    );
  };

export const getDefaultValues = ({
  userRoleKey,
  title,
  userAssignmentType,
  userAssignmentObjectId,
  userRolesOptions,
  jobsitesOptions,
  contractorsOptions,
}: {
  userRoleKey: UserRoleKey;
  title: string;
  userAssignmentType: UserAssignmentType;
  userAssignmentObjectId: string;
  userRolesOptions: SelectOptionElement<UserRoleKey>[];
  jobsitesOptions: SelectOptionElement[];
  contractorsOptions: SelectOptionElement[];
}): EditUserRoleFormData => ({
  userRoleKey: userRolesOptions?.find((option) => option.value === userRoleKey) ?? null,
  contractorId:
    (userAssignmentType === UserAssignmentType.Contractor
      ? contractorsOptions?.find((option) => option.value === userAssignmentObjectId)
      : undefined) ?? null,
  jobsiteIds:
    userAssignmentType === UserAssignmentType.Jobsite
      ? jobsitesOptions?.filter((option) => option.value === userAssignmentObjectId)
      : [],
  title: title ?? '',
});

export const getUpdateInput = (
  userAssignmentType: UserAssignmentType,
  userAssignmentId: string,
  data: EditUserRoleFormData,
  dirtyFields: DeepMap<EditUserRoleFormData, true>,
): UserUpdateAssignmentInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return ensureNonUndefinedFields<UserUpdateAssignmentInput>({
    userAssignmentType,
    userAssignmentId,
    userRoleKey: getUpdateInputValue('userRoleKey'),
    title: getUpdateInputValue('title'),
  });
};
