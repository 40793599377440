import React from 'react';
import { useGetJobsiteAndContractorsQuery } from 'apollo/generated/client-operations';
import { EditType } from 'types';
import { getTempId } from 'utils';
import { AlertInstance } from 'components/alertNotification';
import { FormSubmissionEditableContractor } from './FormSubmissionEditableContractor';
import { EditableContractor, FormSubmissionEditableContractorsProps } from './types';

export const FormSubmissionEditableContractors = React.forwardRef<
  HTMLDivElement,
  FormSubmissionEditableContractorsProps
>((props, ref): React.ReactElement => {
  const { value, onChange, jobsiteId, associationType } = props;

  const { data: jobsiteContractorsData, loading: jobsiteContractorsLoading } = useGetJobsiteAndContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteId,
    variables: { jobsiteId },
  });

  const { visibleJfsContractors } = React.useMemo<{
    visibleJfsContractors: EditableContractor[];
  }>(() => {
    const jobsiteContractors =
      jobsiteContractorsData?.getJobsite.jobsiteContractors.edges.map(({ node }) => node) ?? [];

    const jobsiteContractorIds = jobsiteContractors.map((jc) => jc.id);
    const jfsContractorsByJcId = Object.fromEntries(value.map((jfsC) => [jfsC.jobsiteContractor.id, jfsC]) ?? []);

    const jfsContractors = jobsiteContractors.map<EditableContractor>((jobsiteContractor) => {
      const jfsContractor = jfsContractorsByJcId[jobsiteContractor.id];
      return {
        ...jfsContractor,
        jobsiteContractor: {
          ...jfsContractor?.jobsiteContractor,
          ...jobsiteContractor,
        },
      };
    });

    const orphanJfsContractors = value.filter(
      (jfsContractor) => !jobsiteContractorIds.includes(jfsContractor.jobsiteContractor.id),
    );

    return {
      visibleJfsContractors: [...jfsContractors, ...orphanJfsContractors],
    };
  }, [value, jobsiteContractorsData]);

  const updateValue = React.useCallback(
    (jfsContractor: EditableContractor, editType: EditType): void => {
      const { id: jobsiteContractorId } = jfsContractor.jobsiteContractor;
      let newValue: EditableContractor[];
      if (editType === 'create' || (editType === 'update' && !jfsContractor.id)) {
        const existingJfsC = value?.find((jfsC) => {
          return jfsC.jobsiteContractor.id === jobsiteContractorId;
        });
        // if jfsW has been previously removed then it will be updated
        if (existingJfsC?.changeType === 'removed') {
          newValue = value.map((jfsW) =>
            // existing jfsW id is preserved
            jfsW.id !== existingJfsC.id ? jfsW : { ...jfsW, ...jfsContractor, id: jfsW.id, changeType: 'updated' },
          );
        } else if (existingJfsC) {
          AlertInstance.alert('tc', 'danger', '', 'Worker has already been assigned!');
        } else {
          // add new participant
          newValue = [...value, { id: getTempId(), ...jfsContractor, changeType: 'created', associationType }];
        }
      } else {
        newValue = value.map((jfsW) => (jfsW.id !== jfsContractor.id ? jfsW : { ...jfsW, ...jfsContractor }));
      }
      if (newValue) {
        onChange?.(newValue);
      }
    },
    [onChange, value],
  );

  const loading = jobsiteContractorsLoading;

  return (
    <div ref={ref} className="odin-flex odin-flex-col odin-gap-y-4.5">
      <div className="odin-flex odin-flex-col odin-gap-y-5">
        {loading
          ? value.map((jfsWorker) => <FormSubmissionEditableContractor key={jfsWorker.jobsiteContractor.id} loading />)
          : visibleJfsContractors.map((jfsContractor) => (
              <FormSubmissionEditableContractor
                key={jfsContractor.jobsiteContractor.id}
                jfsContractor={jfsContractor}
                updateValue={updateValue}
              />
            ))}
      </div>
    </div>
  );
});
