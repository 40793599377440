import { Filter, MenuItemProps, Table, TableContainer } from '@odin-labs/components';

import { useGetOrganizationsContainerCurrentSessionQuery } from 'apollo/generated/client-operations';
import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { paginationSizePerPage as limit } from 'utils/constants';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { getColumns, getFilterItems, OrganizationColumn } from './helpers/tables';
import { Organization, OrganizationsFilters } from './types';

const organizationProfileUrl = '/organization/';

export function OrganizationsContainer(): ReactElement {
  const history = useHistory();

  const { page, search, updateUrl, loading: isUrlLoading } = usePageQueryParams();
  const offset = page * limit;

  const {
    data: organizationsData,
    loading: organizationsLoading,
    error: organizationsError,
    refetch,
  } = useGetOrganizationsContainerCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    skip: isUrlLoading,
    notifyOnNetworkStatusChange: true,
    variables: {
      userOrganizationsInput: {
        paginationInput: { limit, offset },
        search,
      },
    },
  });

  const { user: userData } = organizationsData?.getCurrentSession ?? {};
  const organizations = userData?.organizations.edges.map((c) => c.node);

  const organizationsCount = userData?.organizations.count ?? 0;
  const pageCount = Math.ceil(organizationsCount / limit);

  const columns = React.useMemo<OrganizationColumn[]>(() => getColumns(), []);

  const filterItems = React.useMemo(() => getFilterItems({ search }), [search]);

  const loading = organizationsLoading;
  const error = organizationsError;

  if (error) {
    return <LoadingError error={error} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<OrganizationsFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: organization }: { data: Organization }): void => {
    const { organizationId } = organization;
    history.push(`${organizationProfileUrl}${organizationId}`);
  };

  const menuItems = [] as MenuItemProps[];

  return (
    <Container className="contractors-container">
      <NewHeader
        title="Organizations"
        titleInfo={organizationsCount}
        actionsProps={{ menuItems, onReloadPressed: refetch }}
      />
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={organizations}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
    </Container>
  );
}
