import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { faBars, faCog } from '@fortawesome/pro-light-svg-icons';
import { Avatar, getFaIcon } from '@odin-labs/components';
import { SearchBoxWrapper } from 'components/searchBox';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { getInitialsForUser } from 'utils';
import { navbarStyles } from './NavbarContainer.style';

const SettingsIcon = getFaIcon({ icon: faCog, className: navbarStyles.settingsIcon });
const MobileMenuIcon = getFaIcon({ icon: faBars, className: navbarStyles.mobileMenuIcon });

type NavbarContainerProps = {
  openSidebar: () => void;
};

export function NavbarContainer(props: NavbarContainerProps): ReactElement {
  const { openSidebar } = props;
  const { currentUser: user } = React.useContext(AuthContext);
  return (
    <header className={navbarStyles.header}>
      <button className={navbarStyles.mobileMenuButton} onClick={openSidebar}>
        <MobileMenuIcon aria-hidden="true" />
        <span className={navbarStyles.mobileMenuText}>Menu</span>
      </button>
      <div className={navbarStyles.bodyContainer}>
        {user.isAllowed(to.searchForWorker) ? <SearchBoxWrapper /> : <div />}
        <div className={navbarStyles.actionsContainer}>
          <Link to={`/worker/${user?.worker.workerId}`}>
            <Avatar
              size="sm"
              placeholder={getInitialsForUser(user)}
              objectFit="cover"
              src={user?.profilePictureCropped?.downloadUrl}
            />
          </Link>
          <Link to="/settings">
            <SettingsIcon />
          </Link>
        </div>
      </div>
    </header>
  );
}
