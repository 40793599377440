import React from 'react';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AuthContext } from 'auth';
import { useBoolean } from 'utils';
import {
  getDefaultValues,
  getFormInputsHook,
  getAccessRevokingTypeOptions,
} from './ChooseAccessRevokingTypeModalContent.forms';
import { AccessRevokingTypeFormData, ChooseAccessRevokingTypeModalContentProps } from './types';

export function ChooseAccessRevokingTypeModalContent(
  props: ChooseAccessRevokingTypeModalContentProps,
): React.ReactElement {
  const { closeModal, onConfirm, jobsiteWorker } = props;
  const { jobsiteId } = jobsiteWorker?.jobsiteContractor.jobsite ?? {};
  const { currentUser: user } = React.useContext(AuthContext);
  const { value: isContinueButtonEnabled, setTrue: enableContinueButton } = useBoolean();

  const onSubmit: FormOnSubmit<AccessRevokingTypeFormData> = async (data): Promise<void> => {
    const { accessRevokingType } = data;
    onConfirm(accessRevokingType.value);
  };

  const accessRevokingTypeOptions = React.useMemo(
    () => getAccessRevokingTypeOptions({ user, jobsiteId }),
    [user, jobsiteId],
  );

  const defaultValues = React.useMemo(
    () => getDefaultValues({ accessRevokingTypeOptions }),
    [accessRevokingTypeOptions],
  );

  const formInputs = getFormInputsHook({ accessRevokingTypeOptions, enableContinueButton });

  return (
    <ModalFormContent
      setOpen={closeModal}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Continue"
      actionButtonEnabled={isContinueButtonEnabled}
    />
  );
}
