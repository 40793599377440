import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Query {
    selectedJobsiteIds: [String]
    environment: String
    appVersion: String
    beta: Boolean
    isSidebarOpen: Boolean
  }
`;
