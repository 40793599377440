import { Modal, ModalProps } from '@odin-labs/components';
import {
  CardPrintJob,
  CardPrintJobStatus,
  CardStatus,
  WorkerCard,
  WorkerCardType,
} from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { BadgingState, Jobsite, Worker, WorkerTabProps } from 'containers/worker/types';
import React from 'react';
import { getIcon } from 'utils/ui';
import {
  AssignBadgeModalContent,
  AssignQRFormData,
  AssignQRModalContent,
  BadgeType,
  BadgeTypeOption,
  ChooseBadgeTypeModalContent,
  CreateCardPrintJobModalContent,
  DeactivateBadgesModalContent,
  EnterPrintJobCardNumberModalContent,
  ReactivateBadgeModalContent,
  ReassignBadgeModalContent,
  ReassignBadgeState,
  ReassignQRModalContent,
} from './components';
import { IssueFacialRecognitionBadgeModalContent } from './components/IssueFacialRecognitionBadgeModalContent';
import { IssueMobileBadgeModalContent } from './components/IssueMobileBadgeModalContent';

export interface AddBadgeModalProps {
  worker: Worker;
  jobsites: Jobsite[];
  currentCardPrintJob?: CardPrintJob;
  setCurrentCardPrintJob: (value: CardPrintJob) => void;
  workerCards: WorkerCard[];
  workerCardIdToReactivate: string;
  refetchWorker: () => void;
  updateWorkerState: WorkerTabProps['updateWorkerState'];
  refetchWorkerCards: () => Promise<unknown>;
  refetchCurrentCardPrintJob: () => Promise<unknown>;
  closeModal: () => void;
  isOpen: boolean;
  badgingState: BadgingState;
  prevBadgingState: BadgingState;
  setBadgingState: (badgingState: BadgingState, workerCardIdToReactivate?: string) => void;
}

const RedoIcon = getIcon('fal fa-redo');
const CheckIcon = getIcon('fal fa-check');

const isCardPrintJobUnread = (cardPrintJob: CardPrintJob): boolean =>
  cardPrintJob?.status === CardPrintJobStatus.Printed && cardPrintJob?.workerCard == null;

const isCardPrintJobComplete = (cardPrintJob: CardPrintJob): boolean =>
  cardPrintJob?.status === CardPrintJobStatus.Printed && cardPrintJob?.workerCard != null;

export function AddBadgeModal(props: AddBadgeModalProps): React.ReactElement {
  const {
    worker,
    jobsites,
    workerCards = [],
    workerCardIdToReactivate,
    currentCardPrintJob = null,
    setCurrentCardPrintJob,
    refetchWorkerCards,
    refetchCurrentCardPrintJob,
    closeModal,
    isOpen,
    badgingState,
    prevBadgingState,
    setBadgingState,
    updateWorkerState,
  } = props;

  const [jobsiteId, setJobsiteId] = React.useState<string>(null);
  const [workerCardFormatId, setWorkerCardFormatId] = React.useState<string>(null);
  const [reassignBadgeState, setReassignBadgeState] = React.useState<ReassignBadgeState>(null);
  const [reassignQRData, setReassignQRData] = React.useState<AssignQRFormData>(null);

  const badgesToDeactivate = React.useMemo(
    () =>
      workerCards?.filter(
        (workerCard) =>
          workerCard.workerCardId !== currentCardPrintJob?.workerCard?.workerCardId &&
          workerCard.cardStatus === CardStatus.Active &&
          workerCard.workerCardFormat.cardType === WorkerCardType.Proximity,
      ) ?? [],
    [workerCards, currentCardPrintJob],
  );

  const qrCodesToDeactivate = React.useMemo(
    () =>
      badgingState === BadgingState.QRCodeDeactivationPrompt
        ? workerCards?.filter(
            (workerCard) =>
              workerCard.cardStatus === CardStatus.Active &&
              workerCard.workerCardFormat.cardType === WorkerCardType.QrCode,
          )
        : undefined,
    [workerCards, badgingState],
  );

  React.useEffect(() => {
    if (currentCardPrintJob && badgingState === BadgingState.Printing) {
      if (isCardPrintJobComplete(currentCardPrintJob)) {
        setBadgingState(BadgingState.BadgeCreated);
      } else if (isCardPrintJobUnread(currentCardPrintJob)) {
        setBadgingState(BadgingState.PrintCompletedNoCardNumber);
      } else if (currentCardPrintJob.status === CardPrintJobStatus.Failed) {
        setBadgingState(BadgingState.Failed);
      }
    }
  }, [currentCardPrintJob, badgingState]);

  React.useEffect(() => {
    if (badgingState === BadgingState.BadgeCreated) {
      const newBadgingState = badgesToDeactivate.length
        ? BadgingState.ProximityCardDeactivationPrompt
        : BadgingState.ConfirmFinished;
      setBadgingState(newBadgingState);
    }
  }, [badgingState, badgesToDeactivate]);

  const reactivateBadge = (workerCardId: string): void => {
    setBadgingState(BadgingState.ReactivateBadge, workerCardId);
  };

  const getModalProps = (): Omit<ModalProps, 'open' | 'setOpen'> => {
    switch (badgingState) {
      case BadgingState.ChooseBadgeType:
        return {
          actionsPanel: null,
          title: 'Add New Badge',
          children: (
            <ChooseBadgeTypeModalContent
              setOpen={closeModal}
              onAction={(selectedJobsiteId: string, selectedBadgeTypeOption: BadgeTypeOption): void => {
                setJobsiteId(selectedJobsiteId);
                setWorkerCardFormatId(selectedBadgeTypeOption.workerCardFormatId);
                switch (selectedBadgeTypeOption.value) {
                  case BadgeType.FacialRecognition:
                    setBadgingState(BadgingState.IssueFacialEnrollment);
                    break;
                  case BadgeType.MobileBadge:
                    setBadgingState(BadgingState.IssueMobileBadge);
                    break;
                  case BadgeType.QrCode:
                    setBadgingState(BadgingState.AssignQRCode);
                    break;
                  case BadgeType.ExistingBadge:
                    setBadgingState(BadgingState.AssignExistingBadge);
                    break;
                  case BadgeType.NewBadge:
                    setBadgingState(BadgingState.CreatePrintJob);
                    break;
                  default:
                    break;
                }
              }}
              jobsites={jobsites}
            />
          ),
        };
      case BadgingState.IssueMobileBadge:
        return {
          actionsPanel: null,
          title: 'Issue Mobile Badge',
          children: (
            <IssueMobileBadgeModalContent
              setOpen={closeModal}
              onAction={(): void => {
                closeModal();
                refetchWorkerCards();
              }}
              jobsiteId={jobsiteId}
              workerId={worker.workerId}
              workerCardFormatId={workerCardFormatId}
              workerCards={workerCards}
              worker={worker}
            />
          ),
        };
      case BadgingState.IssueFacialEnrollment:
        return {
          actionsPanel: null,
          title: 'Start facial recognition enrollment',
          children: (
            <IssueFacialRecognitionBadgeModalContent
              setOpen={closeModal}
              onAction={(): void => {
                closeModal();
                refetchWorkerCards();
              }}
              refetchWorkerCards={refetchWorkerCards}
              jobsiteId={jobsiteId}
              workerId={worker.workerId}
              workerCardFormatId={workerCardFormatId}
              workerCards={workerCards}
              worker={worker}
            />
          ),
        };
      case BadgingState.AssignQRCode:
        return {
          actionsPanel: null,
          title: 'Assign QR Code',
          children: (
            <AssignQRModalContent
              setOpen={closeModal}
              onAction={(assignQRData?: AssignQRFormData): void => {
                setReassignQRData(assignQRData);
                if (assignQRData) {
                  setBadgingState(BadgingState.ReassignQRCode);
                } else {
                  closeModal();
                  refetchWorkerCards();
                }
              }}
              jobsiteId={jobsiteId}
              workerId={worker.workerId}
              workerCards={workerCards}
              workerCardFormatId={workerCardFormatId}
            />
          ),
        };
      case BadgingState.ReassignQRCode:
        return {
          actionsPanel: null,
          title: 'This QR Code is currently assigned to another worker',
          subtitle: 'Would you like to reassign this QR Code to this worker?',
          children: (
            <ReassignQRModalContent
              setOpen={(): void => {
                setReassignQRData(null);
                closeModal();
              }}
              onAction={(): void => {
                setReassignQRData(null);
                closeModal();
                refetchWorkerCards();
              }}
              jobsiteId={jobsiteId}
              workerId={worker.workerId}
              assignQRData={reassignQRData}
              workerCardFormatId={workerCardFormatId}
            />
          ),
        };
      case BadgingState.AssignExistingBadge:
        return {
          actionsPanel: null,
          title: 'Assign Existing Badge',
          children: (
            <AssignBadgeModalContent
              setOpen={closeModal}
              onAction={(reassignState): void => {
                setReassignBadgeState(reassignState);
                if (reassignState) {
                  setBadgingState(BadgingState.ReassignExistingBadge);
                } else {
                  closeModal();
                  refetchWorkerCards();
                }
              }}
              reactivateBadge={reactivateBadge}
              jobsiteId={jobsiteId}
              workerId={worker.workerId}
              workerCards={workerCards}
            />
          ),
        };
      case BadgingState.ReassignExistingBadge:
        return {
          actionsPanel: null,
          title: `This badge is currently assigned to the following worker${
            reassignBadgeState?.workerCardInfo?.length > 1 ? 's' : ''
          }:`,
          children: (
            <ReassignBadgeModalContent
              setOpen={(): void => {
                setReassignBadgeState(null);
                closeModal();
              }}
              onAction={(): void => {
                setReassignBadgeState(null);
                refetchWorkerCards();
                if (prevBadgingState === BadgingState.PrintCompletedNoCardNumber) {
                  setBadgingState(BadgingState.BadgeCreated);
                } else {
                  closeModal();
                }
              }}
              jobsiteId={jobsiteId}
              workerId={worker.workerId}
              reassignBadgeState={reassignBadgeState}
            />
          ),
        };
      case BadgingState.CreatePrintJob:
        return {
          actionsPanel: null,
          title: 'Add New Badge',
          children: (
            <CreateCardPrintJobModalContent
              setOpen={closeModal}
              onAction={(cardPrintJob: CardPrintJob): void => {
                setCurrentCardPrintJob(cardPrintJob);
                setBadgingState(BadgingState.Printing);
              }}
              worker={worker}
              jobsiteId={
                prevBadgingState === BadgingState.ChooseBadgeType ? jobsiteId : currentCardPrintJob?.jobsite?.jobsiteId
              }
              updateWorkerState={updateWorkerState}
            />
          ),
        };
      case BadgingState.Printing:
        return {
          title: 'Badge printing...',
          subtitle: `Status: ${currentCardPrintJob?.statusMessage ?? currentCardPrintJob?.status}`,
          size: 'sm',
          cancelText: 'Close',
          onAction: (): void => {
            setBadgingState(BadgingState.CreatePrintJob);
          },
          actionText: 'New Print Job',
          actionIcon: RedoIcon,
          children: <LoadingError noPadding loading />,
        };
      case BadgingState.PrintCompletedNoCardNumber:
        return {
          actionsPanel: null,
          title: 'Please enter the badge number on the printed card',
          children: (
            <EnterPrintJobCardNumberModalContent
              setOpen={closeModal}
              onAction={(reassignState): void => {
                setReassignBadgeState(reassignState);
                if (reassignState) {
                  setBadgingState(BadgingState.ReassignExistingBadge);
                } else {
                  refetchWorkerCards();
                  setBadgingState(BadgingState.BadgeCreated);
                }
              }}
              cardPrintJob={currentCardPrintJob}
              refetchCardPrintJob={refetchCurrentCardPrintJob}
              jobsiteId={jobsiteId}
            />
          ),
        };
      case BadgingState.ReactivateBadge:
        return {
          actionsPanel: null,
          title: 'Reactivate Badge',
          subtitle: 'Would you like to reactivate this badge?',
          children: (
            <ReactivateBadgeModalContent
              setOpen={closeModal}
              onAction={(reassignState): void => {
                setReassignBadgeState(reassignState);
                if (reassignState) {
                  setBadgingState(BadgingState.ReassignExistingBadge);
                } else {
                  closeModal();
                  refetchWorkerCards();
                }
              }}
              workerCardId={workerCardIdToReactivate}
              workerCards={workerCards}
            />
          ),
        };
      case BadgingState.ProximityCardDeactivationPrompt:
        return {
          actionsPanel: null,
          title: 'The badge has been printed and assigned to this worker',
          children: (
            <DeactivateBadgesModalContent
              setOpen={closeModal}
              onAction={(): void => {
                closeModal();
                refetchWorkerCards();
              }}
              workerCards={badgesToDeactivate}
              workerCardType={WorkerCardType.Proximity}
            />
          ),
        };
      case BadgingState.QRCodeDeactivationPrompt:
        return {
          actionsPanel: null,
          title: 'The QR Code has been assigned to this worker',
          children: (
            <DeactivateBadgesModalContent
              setOpen={closeModal}
              onAction={(): void => {
                closeModal();
                refetchWorkerCards();
              }}
              workerCards={qrCodesToDeactivate}
              workerCardType={WorkerCardType.QrCode}
            />
          ),
        };
      case BadgingState.ConfirmFinished:
        return {
          title: 'The badge has been printed and assigned to this worker',
          size: 'sm',
          onAction: (): void => {
            closeModal();
            refetchWorkerCards();
          },
          actionText: 'Finish',
          actionIcon: CheckIcon,
        };
      case BadgingState.Failed:
        return {
          size: 'sm',
          title: 'Your badge failed to print',
          onAction: (): void => {
            setBadgingState(BadgingState.CreatePrintJob);
          },
          actionText: 'Try Again',
          actionIcon: RedoIcon,
          children: <p>The printer returned the following error: {currentCardPrintJob?.statusMessage}</p>,
        };
      default:
        return {};
    }
  };

  const modalProps = getModalProps();

  return <Modal open={isOpen} setOpen={closeModal} size="md" {...modalProps} />;
}
