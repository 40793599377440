import { ContractorTabProps } from 'containers/contractor/types';
import React, { ReactElement } from 'react';

import { useGetContractorContainerContractorInfoQuery } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { EditContractorModal } from 'containers/contractors/modals/EditContractorModal';
import { useBoolean } from 'utils';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { InfoList } from 'components/infoList';
import { InfoRow } from 'components/infoRow';
import { getInfoRows } from './utils';

export function ContractorInfoTabContainer(props: ContractorTabProps): ReactElement {
  const { contractorId, onTabApiChange } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    value: isEditContractorModalOpen,
    setTrue: openEditContractorModal,
    setFalse: closeEditContractorModal,
  } = useBoolean(false);

  const { data, loading, error, refetch } = useGetContractorContainerContractorInfoQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      contractorId,
      includeArchived: true,
    },
  });

  const contractor = data?.getContractor;
  const contractorInfoRows = getInfoRows(contractor);

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const allowEdit = user.isAllowed(to.editContractors);

  return (
    <>
      <InfoList withBottomBorder loading={loading}>
        {contractorInfoRows.map((row) => (
          <InfoRow key={row.label} {...row} allowEdit={allowEdit} onEditClick={openEditContractorModal} />
        ))}
      </InfoList>
      <EditContractorModal
        isOpen={isEditContractorModalOpen}
        onCancel={closeEditContractorModal}
        onConfirm={closeEditContractorModal}
        contractor={contractor}
      />
    </>
  );
}
