import React from 'react';
import cn from 'classnames';
import { PanelChildrenGridColumns, PanelGap, PanelProps } from './types';

const childrenGridColumnsClasses: { [key in PanelChildrenGridColumns]: string } = {
  1: cn('md:odin-grid-cols-1'),
  2: cn('md:odin-grid-cols-2'),
  3: cn('md:odin-grid-cols-3'),
  4: cn('md:odin-grid-cols-4'),
  5: cn('md:odin-grid-cols-5'),
  6: cn('md:odin-grid-cols-6'),
  7: cn('md:odin-grid-cols-7'),
  8: cn('md:odin-grid-cols-8'),
  9: cn('md:odin-grid-cols-9'),
  10: cn('md:odin-grid-cols-10'),
  11: cn('md:odin-grid-cols-11'),
  12: cn('md:odin-grid-cols-12'),
};

const horizontalGapClasses: { [key in PanelGap]: string } = {
  xs: cn('md:odin-gap-x-4'),
  sm: cn('md:odin-gap-x-5'),
  base: cn('md:odin-gap-x-6'),
  lg: cn('md:odin-gap-x-7'),
  xl: cn('md:odin-gap-x-8'),
};

const verticalGapClasses: { [key in PanelGap]: string } = {
  xs: cn('md:odin-gap-y-4'),
  sm: cn('md:odin-gap-y-5'),
  base: cn('md:odin-gap-y-6'),
  lg: cn('md:odin-gap-y-7'),
  xl: cn('md:odin-gap-y-8'),
};

export function Panel(props: PanelProps): React.ReactElement {
  const { children, className, horizontalGap, verticalGap, childrenGridColumns = 6 } = props;
  return (
    <div
      className={cn(
        'odin-shadow-sm odin-border odin-border-gray-100 odin-rounded-lg odin-p-6',
        'odin-flex odin-flex-col odin-gap-y-6',
        'md:odin-grid md:odin-gap-6',
        childrenGridColumnsClasses[childrenGridColumns],
        horizontalGapClasses[horizontalGap],
        verticalGapClasses[verticalGap],
        className,
      )}
    >
      {children}
    </div>
  );
}
