import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Banner } from '@odin-labs/components';
import { environment as writeEnvironmentToCache, appVersion as writeAppVersionToCache } from 'apollo/cache';
import { useIsMounted, usePrevious } from 'utils';

import { Page } from './Page';
import { RoutePageProps } from './types';

export function RoutePage(props: RoutePageProps): ReactElement {
  const { route } = props;
  const { container: Container } = route;
  const isMounted = useIsMounted();
  const [showUpdateBanner, setShowUpdateBanner] = useState<boolean>(false);
  const [appVersion, setAppVersion] = useState<string>('');
  const prevAppVersion = usePrevious(appVersion);
  const intervalRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    async function fetchHealthCheck(updateLocalVersion: boolean): Promise<void> {
      const res = await fetch('/health');
      const json = await res.json();
      const { version, env } = json;

      if (isMounted()) {
        setAppVersion(version);
      }
      writeEnvironmentToCache(env);
      if (updateLocalVersion) {
        writeAppVersionToCache(version);
      }
    }

    // Write the local app version on initial render only
    fetchHealthCheck(true);

    intervalRef.current = setInterval(() => {
      fetchHealthCheck(false);
    }, 120000);

    return (): void => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (isMounted() && prevAppVersion && appVersion !== prevAppVersion) {
      setShowUpdateBanner(true);
    }
  }, [appVersion]);

  return (
    <Page route={route}>
      <>
        <Container {...props} />
        <Banner
          title="There is an updated version of ODIN App available. Please reload the page to update."
          action={{
            text: 'Reload page',
            onClick: (): void => {
              window.location.reload();
            },
          }}
          shown={showUpdateBanner}
          icon={ExclamationIcon}
          onClose={(): void => {
            setShowUpdateBanner(false);
            clearInterval(intervalRef.current);
          }}
        />
      </>
    </Page>
  );
}
