import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Table, TableContainer } from '@odin-labs/components';
import { momentFormatter } from 'utils';
import { ReportSectionTitle } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/components/reportSectionTitle';
import { getJobsiteIncidentAccessEventsInterval } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/helpers/utils';
import { JfsWorkerWithHistoricalData } from 'containers/jobsiteFormSubmission/print/types';
import { getColumns, WorkerAccessEventColumn } from './helpers/tables';

const classes = {
  container: cn('print:odin-break-inside-avoid'),
};

export type WorkerReportAccessEventsProps = {
  reportDateTime: moment.Moment;
  jfsWorker: JfsWorkerWithHistoricalData;
};

export function WorkerReportAccessEvents(props: WorkerReportAccessEventsProps): React.ReactElement {
  const { reportDateTime, jfsWorker } = props;

  const { startMoment, endMoment } = getJobsiteIncidentAccessEventsInterval(reportDateTime);

  const { contractorWorker, jobsiteContractor } = jfsWorker.historicalData.jobsiteWorkerAccess.jobsiteWorker;
  const { jobsite } = jobsiteContractor ?? {};
  const { jobsiteAccessEvents } = contractorWorker.worker ?? {};
  const workerAccessEvents = jobsiteAccessEvents.edges.map(({ node }) => ({ ...node, jobsite })) ?? [];

  const columns = React.useMemo<WorkerAccessEventColumn[]>(() => getColumns(), []);

  const datesText = [startMoment.format(momentFormatter), endMoment.format(momentFormatter)].join(' - ');

  return (
    <TableContainer className={classes.container}>
      <ReportSectionTitle title="Jobsite Access Events" count={workerAccessEvents.length} dates={datesText} />
      <Table columns={columns} data={workerAccessEvents} remote disableGlobalFilter disableSortBy />
    </TableContainer>
  );
}
