import React from 'react';
import { Link } from 'react-router-dom';
import { TabsHeaderItemProps } from '@odin-labs/components';

export function RoutedTabsHeaderItem(props: React.PropsWithChildren<TabsHeaderItemProps>): React.ReactElement {
  const {
    // prevent `tab` from being passed to the button component
    tab, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...restProps
  } = props;

  return <Link replace to={tab.location} {...restProps} />;
}
