import moment from 'moment';
import { SelectOptionElement } from '@odin-labs/components';
import { getCurrentISOFormattedDate } from 'utils';
import { getIcon } from 'utils/ui';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { EditJobsiteConfigurationFormData } from 'containers/jobsiteConfiguration/types';
import { citizenshipStatusOptions } from 'containers/worker/helpers/utils';
import { WorkerInfoFormData, BadgeInfoFormData, Jobsite } from 'containers/issueTempBadge/types';
import { toFancySelectOptions, tradeOptions as tradeOptionsValues } from 'utils/constants';
import { dateValidation, emailValidation, phoneNumberValidation } from 'utils/validation';
import { getFields } from 'types';
import { OnboardingStepKey, getStepFieldsConfig } from './utils';

const tradeOptions = toFancySelectOptions(tradeOptionsValues);
const EnvelopeIcon = getIcon('fal fa-envelope');

const jobsiteConfigWorkerInfo = getFields<EditJobsiteConfigurationFormData['workerInfo']>();

export const getStep1FormInputs = ({
  jobsite,
  contractorOptions,
}: {
  jobsite: Jobsite;
  contractorOptions: SelectOptionElement[];
}): TypedFormInputs<WorkerInfoFormData> => {
  const fieldsConfig = getStepFieldsConfig(OnboardingStepKey.PersonalInfo, jobsite);
  const getIsRequired = (configFieldKey: keyof typeof jobsiteConfigWorkerInfo): boolean =>
    fieldsConfig[configFieldKey]?.isRequired ?? false;
  const isCitizenshipStatusRequired = getIsRequired(jobsiteConfigWorkerInfo.citizenshipStatus);

  return {
    firstName: {
      element: FormInputTypes.OdinField,
      label: 'First Name',
      validation: { required: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    lastName: {
      element: FormInputTypes.OdinField,
      label: 'Last Name',
      validation: { required: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    dateOfBirth: {
      element: FormInputTypes.OdinField,
      label: 'Date of birth',
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'pastDate',
      },
      validation: { required: true, pattern: dateValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    trade: {
      element: FormInputTypes.OdinSelect,
      label: 'Trade',
      elementProps: {
        note: 'Optional',
        options: tradeOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    email: {
      element: FormInputTypes.OdinField,
      label: 'Email',
      elementProps: {
        note: 'Optional',
        icon: EnvelopeIcon,
      },
      validation: { pattern: emailValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    phoneNumber: {
      element: FormInputTypes.OdinField,
      label: 'Mobile phone',
      elementProps: {
        fieldType: 'phone',
        showDefaultIcon: true,
        note: 'Optional',
      },
      validation: { pattern: phoneNumberValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    company: {
      element: FormInputTypes.OdinSelect,
      label: 'Company',
      elementProps: {
        options: contractorOptions,
      },
      validation: { required: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    citizenshipStatus: {
      element: FormInputTypes.OdinSelect,
      label: 'Citizenship status',
      elementProps: {
        note: isCitizenshipStatusRequired ? null : 'Optional',
        options: citizenshipStatusOptions,
      },
      validation: {
        required: isCitizenshipStatusRequired,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};

export const getStep1DefaultValues = (): WorkerInfoFormData => ({
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  trade: null,
  email: '',
  company: null,
  phoneNumber: '',
  citizenshipStatus: null,
});

export const getStep2FormInputs = (cardFormatOptions: SelectOptionElement[]): TypedFormInputs<BadgeInfoFormData> => ({
  tapCardNumber: {
    element: FormInputTypes.OdinField,
    label: 'Proximity card number',
    elementProps: {
      fieldType: 'number',
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  bluetoothBadgeNumber: {
    element: FormInputTypes.OdinField,
    label: 'Bluetooth badge number',
    elementProps: {
      upperCase: true,
      note: 'Optional',
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  badgeExpirationDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'Expiration date of badge',
    elementProps: {
      maxDate: moment().add(1, 'week'),
      minDate: moment(),
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  accessReason: {
    element: FormInputTypes.OdinField,
    label: 'Reason for access',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  cardFormatId: {
    element: FormInputTypes.OdinSelect,
    label: 'Proximity card Format',
    elementProps: {
      options: cardFormatOptions,
      disabled: cardFormatOptions.length < 2,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
});

export const getStep2DefaultValues = (cardFormatOptions: SelectOptionElement[]): BadgeInfoFormData => ({
  tapCardNumber: '',
  bluetoothBadgeNumber: '',
  badgeExpirationDate: moment.utc(getCurrentISOFormattedDate()),
  accessReason: '',
  cardFormatId: cardFormatOptions[0] ?? null,
});
