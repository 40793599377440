import { BrowserLogging, DatadogLoggerManager } from '@odin-labs/core/utils/logging/browser';
import type { BrowserLoggerOptions, LogPayload } from '@odin-labs/core/utils/logging/types';
import { LogLevel } from '@odin-labs/core/utils/logging/types';
import { BaseLogger, BaseMetadataProvider } from '@odin-labs/core/utils/logging/base';
import { HandlerType } from '@datadog/browser-logs';

/**
 * A custom MetadataProvider that injects the correct logger version info into browser logs.
 */
class LogMetadataProvider extends BaseMetadataProvider {
  constructor(private readonly loggerVersion: string) {
    super();
  }

  public override getDefaultMetadata(loggerName: string): Partial<LogPayload> {
    return {
      logger: {
        name: loggerName,
        // The default StandardMetadataProvider in odin-core attempts to inject the logger version
        // by inspecting `process.env` for the `npm_package_version`, but this will not work in the
        // browser environment, so we inject the version directly here
        version: this.loggerVersion,
      },
    };
  }
}

export const loggingConfig: { logging: BrowserLogging; logger: BaseLogger } = {
  /**
   * A Logging instance that can be used to create additional loggers
   */
  logging: null,
  /**
   * A base logger that can be imported and used directly
   */
  logger: null,
};

export type LoggingConfig = {
  logLevel: string;
  appVersion: string;
  datadogEnabled?: boolean;
};

/**
 * Initialize Logging and Logger instances in `loggingConfig`.
 *
 * After this function has executed, clients may log to the browser console (and DataDog) as follows:
 * ```
 * loggingConfig.logger.debug('message', optionalContextObject, ...);
 * ```
 * For module-specific loggers:
 * ```
 * const logger = loggingConfig.logging.getLogger('myModule');
 * logger.debug('message', optionalContextObject, ...);
 * ```
 */
export const initializeLoggingConfig = (config: LoggingConfig): void => {
  // Ensure logs are sent both to the browser console and the DataDog API if DataDog is enabled
  const logHandlers = config.datadogEnabled ? [HandlerType.console, HandlerType.http] : [HandlerType.console];
  const loggerOptions: BrowserLoggerOptions = {
    logLevel: LogLevel[config.logLevel as keyof typeof LogLevel] || LogLevel.INFO,
    loggerManager: new DatadogLoggerManager({ logHandlers }),
    metadataProviders: [new LogMetadataProvider(config.appVersion)],
  };
  loggingConfig.logging = new BrowserLogging(loggerOptions);
  loggingConfig.logger = loggingConfig.logging.getLogger('client');
};
