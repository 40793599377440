import React, { ReactElement } from 'react';
import JSONTree from 'react-json-tree';

import { BaseModal } from './BaseModal';
import { JsonTreeModalProps } from './types';

export function JsonTreeModal({ isOpen = false, closeModal, onClosed, data, title }: JsonTreeModalProps): ReactElement {
  return (
    <BaseModal
      isOpen={isOpen}
      onCancel={closeModal}
      onAction={closeModal}
      toggle={closeModal}
      onClosed={onClosed}
      title={title}
      actionText="OK"
      shouldHideCancel
    >
      <div style={{ maxHeight: '300px', overflow: 'auto' }}>
        <JSONTree data={data} />
      </div>
    </BaseModal>
  );
}
