import { MomentInput } from 'moment';
import { SelectOptionElement } from '@odin-labs/components';
import { Jobsite } from 'apollo/generated/client-operations';
import { JobsiteWorker, Worker } from 'containers/worker/types';

export type JobsiteForOption = Pick<Jobsite, 'jobsiteId' | 'name' | 'startDate'>;
export type JobsiteOptionElement = SelectOptionElement & { startDate: MomentInput };

export const TRUE = true.toString();
export const FALSE = false.toString();

export interface EditJobsiteWorkerModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  closeModal: () => void;
  onClosed: () => void;
  jobsiteWorker: JobsiteWorker;
  worker: Worker;
}
