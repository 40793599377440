import classNames from 'classnames/bind';
import * as styles from './scss/search-box-autocomplete-list.scss';

const cn = classNames.bind(styles);

export const classes = {
  autoCompleteListContainer: cn('autocomplete-list-container'),
  autoCompleteList: cn(
    'sui-search-box__results-list',
    'odin--my-5 odin-divide-y odin-divide-gray-200',
    'odin-overflow-y-scroll',
    'odin-cursor-pointer',
    'odin-max-h-[75vh]',
    'sm:odin-max-h-[80vh]',
  ),
  autoCompleteListItem: cn('!odin-mx-0 !odin-px-0 !odin-py-0'),

  seeAllResultsContainer: cn('odin-py-2', 'odin-bg-odin-primary', 'odin-rounded-b-md', 'odin-shadow-odin-normal'),
  seeAllResultsButton: cn(
    'odin-w-full',
    'odin-flex',
    'odin-justify-center',
    'odin-items-center',
    'odin-px-4',
    'odin-py-3',
    'odin-font-medium',
    'odin-text-sm',
    'odin-text-white',
    'hover:odin-text-odin-white',
  ),
  seeAllResultsIcon: (icon: string): string => cn(icon, 'odin-font-normal', 'odin-mr-3'),
};
