import React, { ReactElement } from 'react';
import { Alert, ModalActionsPanel } from '@odin-labs/components';
import { LoadingError } from 'components/loadingError';
import { Form } from './Form';
import { ModalFormContentProps } from './types';

const forwardedModalFormContent = React.forwardRef(
  <TFields,>(props: ModalFormContentProps<TFields>, ref: React.ForwardedRef<HTMLFormElement>): ReactElement => {
    const {
      // modal props
      setOpen,

      // loading-error
      loading,
      error,

      // modal actions props
      actionsContainerClassName,
      onAction,
      actionButton,
      actionButtonTheme,
      actionText,
      actionButtonEnabled,
      actionButtonWithSpinner,
      actionIcon,

      onCancel,
      cancelButton,
      cancelButtonTheme,
      cancelText,
      cancelButtonEnabled,
      cancelButtonWithSpinner,
      cancelIcon,

      // alert props
      alertProps,

      // form props
      inputs,
      onSubmit,
      defaultValues,
      inputsContainerClassName,
      renderAbove,
      onIsDirtyChange,
    } = props;

    const formProps = {
      inputs,
      onSubmit,
      defaultValues,
      inputsContainerClassName,
      renderAbove,
      onIsDirtyChange,
    };

    const actionsPanelProps = {
      actionsContainerClassName,
      onAction,
      actionButton,
      actionButtonTheme,
      actionText,
      actionButtonEnabled,
      actionButtonWithSpinner,
      actionIcon,

      onCancel,
      cancelButton,
      cancelButtonTheme,
      cancelText,
      cancelButtonEnabled,
      cancelButtonWithSpinner,
      cancelIcon,
    };

    const onCancelHandler = React.useCallback((): void => {
      setOpen(false);
      onCancel?.();
    }, [setOpen, onCancel]);

    return loading || error ? (
      <LoadingError loading={loading} error={error} noPadding />
    ) : (
      <Form
        {...formProps}
        ref={ref}
        renderBelow={(): ReactElement => (
          <>
            {alertProps && (
              <div className="odin-mt-2">
                <Alert {...alertProps} />
              </div>
            )}
            <ModalActionsPanel
              {...actionsPanelProps}
              onCancel={onCancelHandler}
              className={actionsContainerClassName}
              actionButtonType="submit"
            />
          </>
        )}
      />
    );
  },
);

type ForwardedModalFormContent = <TFields>(
  props: ModalFormContentProps<TFields> & {
    ref?: React.ForwardedRef<HTMLFormElement>;
  },
) => ReturnType<typeof forwardedModalFormContent>;

export const ModalFormContent = forwardedModalFormContent as ForwardedModalFormContent;
