import { gql } from '@apollo/client';

export const GET_JOBSITE_CONTRACTORS = gql`
  query GetJobsiteContractors($jobsiteIds: [ID!], $contractorId: ID) {
    getJobsiteContractors(jobsiteIds: $jobsiteIds, contractorId: $contractorId) {
      results {
        contractor {
          contractorId
          organization {
            name
          }
          isDefaultContractor
        }
        jobsite {
          jobsiteId
          name
          developer {
            developerId
          }
        }
      }
    }
  }
`;
