import React from 'react';
import { WeatherConditions } from 'apollo/generated/client-operations';

export type WeatherDetailsProps = {
  weatherConditions: WeatherConditions;
};

export function WeatherDetails({ weatherConditions }: WeatherDetailsProps): React.ReactElement {
  const { icon, temp, description, windSpeed, windDirectionCardinal } = weatherConditions ?? {};
  return (
    <div className="odin-flex odin-items-center">
      <img src={icon} alt="weather-img" width={46} height={28} />
      <div>
        {`${temp}º, `}
        <span className="odin-capitalize">{description}</span>
        <br />
        Wind: {windSpeed} MPH {windDirectionCardinal}
      </div>
    </div>
  );
}
