import React from 'react';

export const useInterval = (callback: () => void, delay: any): any => {
  const callbackRef = React.useRef<() => void>(null);

  // Remember the latest callback.
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    const tick = (): void => {
      callbackRef.current?.();
    };

    const timer = delay != null ? setInterval(tick, delay) : null;
    return (): void => timer && clearInterval(timer);
  }, [delay]);
};
