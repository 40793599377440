import { useLocation, matchPath } from 'react-router-dom';
import { ExtraProps, Tab, useTabs, UseTabsArgs, UseTabsResult } from '@odin-labs/components';

const useCurrentRoutedTab = (tabs: Tab[]): Tab => {
  const location = useLocation();

  const foundTab = tabs.find((tab) => {
    const matchResult = matchPath(location.pathname, { path: tab.path });
    return matchResult?.isExact;
  });

  return foundTab ?? tabs[0];
};

export type UseRoutedTabsArgs<T extends ExtraProps = ExtraProps> = Omit<UseTabsArgs<T>, 'currentTabIndex'>;
export type UseRoutedTabsResult = UseTabsResult;

export const useRoutedTabs = <T extends ExtraProps = ExtraProps>(args: UseRoutedTabsArgs<T>): UseRoutedTabsResult =>
  useTabs<T>({
    ...args,
    useCurrentTab: useCurrentRoutedTab,
  });
