import React from 'react';
import { SearchBoxAutocompleteViewProps } from '@odin-labs/components';
import { classes } from './SearchBoxSuggestionsList.style.ts';

export function SearchBoxSuggestionsList(props: SearchBoxAutocompleteViewProps): React.ReactElement {
  const { autocompletedSuggestions, getItemProps } = props;
  const previousSearchSuggestions = Object.values(autocompletedSuggestions ?? {})[0];

  return (
    <div className={classes.suggestionsListContainer}>
      <div className="odin-grow">
        <div className="odin-text-sm odin-text-gray-900 odin-mb-6">Recent Searches</div>
        <ul className={classes.suggestionsList}>
          {previousSearchSuggestions.map((prevSearch, i) => {
            const itemProps = getItemProps({
              key: prevSearch.suggestion,
              item: prevSearch,
              index: i,
            });

            return (
              <li {...itemProps} className={classes.suggestionItem}>
                <div className={classes.suggestionItemText}>{prevSearch.suggestion}</div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={classes.infoContainer}>
        <div>
          <i className={classes.infoIcon} aria-hidden="true" />
        </div>
        <div className="odin-text-base odin-mt-6 odin-text-gray-900">
          To find a worker, search using any combination of worker name, contractor, jobsite, badge number, quick code,
          or contact info
        </div>
      </div>
    </div>
  );
}
