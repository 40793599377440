import classNames from 'classnames/bind';
import * as styles from './scss/search-box-in-progress-list.scss';

const cn = classNames.bind(styles);

export const classes = {
  inProgressListContainer: cn('in-progress-list-container'),
  inProgressList: cn('sui-search-box__in-progress-list', 'odin--my-5 odin-divide-y odin-divide-gray-200'),

  inProgressItemContainer: cn(
    'search-result-item-container',
    'odin-flex',
    'odin-flex-col',
    'odin-p-6',
    'odin-space-y-2',
  ),
};
