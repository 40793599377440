import { JobsiteUpdateInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { DeepMap } from 'react-hook-form';
import { toggleBorderClasses } from './utils';

export const cameraSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['cameras']> = {
  avigilonEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Uses Avigilon cameras on site',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
};

export const getCameraSectionDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['cameras'] => {
  const { avigilonEnabled = false } = jobsite ?? {};

  return {
    avigilonEnabled,
  };
};

type CameraSectionUpdateInput = Required<Pick<JobsiteUpdateInput, 'avigilonEnabled'>>;

export const getCameraSectionUpdateInput = (
  cameras: EditJobsiteConfigurationFormData['cameras'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['cameras'], true>,
): CameraSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(cameras, dirtyFields);
  return {
    avigilonEnabled: getUpdateInputValue('avigilonEnabled'),
  };
};
