import React, { ReactElement } from 'react';
import { LoadingError } from 'components/loadingError';

import { WorkerTabProps } from 'containers/worker/beta/types';
import { WorkerPersonalInfo } from './workerPersonalInfo';

export function WorkerTailwindOverview({ worker, jobsiteWorkers, refetch }: WorkerTabProps): ReactElement {
  if (!worker) {
    return <LoadingError loading />;
  }
  return (
    <div>
      Overview
      {jobsiteWorkers?.map((jobsiteWorker) => {
        return <h3 key={jobsiteWorker.jobsiteWorkerId}>{jobsiteWorker.jobsiteContractor?.jobsite?.name}</h3>;
      })}
      <WorkerPersonalInfo worker={worker} refetch={refetch} />
    </div>
  );
}
