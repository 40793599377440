import React from 'react';
import {
  CardPrintJob,
  useCreateCardPrintJobMutation,
  useUpdateWorkerMutation,
  useUploadSingleFileMutation,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError, hasGraphQLErrorMessage, MatchBy } from 'utils/error';
import { uploadCompressedFile, useIsMounted } from 'utils';
import { getIcon } from 'utils/ui';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { Worker, WorkerTabProps } from 'containers/worker/types';
import { RequiredField } from 'utils/validation';
import { CreateCardPrintJobFormData } from './types';
import {
  getFormDefaultValues,
  getFormInputs,
  getWorkerUpdateInput,
  useJobsiteBadgeTemplateOptions,
} from './CreateCardPrintJobModalContent.forms';

export interface CreateCardPrintJobModalContentProps {
  setOpen: () => void;
  onAction: (cardPrintJob: CardPrintJob) => void;
  jobsiteId: string;
  worker: Worker;
  updateWorkerState: WorkerTabProps['updateWorkerState'];
}

const PrintBadgeIcon = getIcon('fal fa-print');

const instructions =
  'Please review the following worker data. ' +
  'Any changes made here will be saved to the worker’s profile. ' +
  'All fields are required to print a badge.';

export function CreateCardPrintJobModalContent(props: CreateCardPrintJobModalContentProps): React.ReactElement {
  const { worker, jobsiteId, setOpen, onAction, updateWorkerState } = props;
  const { workerId } = worker ?? {};

  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const isMounted = useIsMounted();

  /* When a new profile picture is dropped in the modal, we have to upload it first */
  const [uploadFile] = useUploadSingleFileMutation();

  /* If worker data such as trade is updated, we need to update the worker before printing */
  const [updateWorker] = useUpdateWorkerMutation();

  /* When the above are either avoided or executed, we can then create the print job */
  const [createCardPrintJob] = useCreateCardPrintJobMutation();

  /* Combines the above to add a new badge with any new updated data */
  const onSubmit: FormOnSubmit<CreateCardPrintJobFormData> = async (
    data,
    event,
    dirtyFields,
    formApi,
  ): Promise<void> => {
    if (isFetching) return;
    setIsFetching(true);

    try {
      const { profilePictureFile } = data ?? {};
      let profilePictureFileId: string;
      if (profilePictureFile) {
        try {
          profilePictureFileId = await uploadCompressedFile(profilePictureFile, uploadFile);
        } catch (error) {
          AlertInstance.alert('tc', 'danger', 'Error uploading profile picture', getGraphQLError(error));
          return;
        }
      }

      const workerInput = getWorkerUpdateInput(profilePictureFileId);
      if (workerInput) {
        try {
          const updateWorkerResult = await updateWorker({ variables: { workerId, workerInput } });
          const { profilePictureCropped } = updateWorkerResult.data.updateWorker;
          updateWorkerState?.({ profilePictureCropped });
        } catch (error) {
          AlertInstance.alert('tc', 'danger', 'Error updating worker data!', getGraphQLError(error));
          return;
        }
      }

      try {
        const currentCardPrintJob = await createCardPrintJob({
          variables: {
            workerId,
            jobsiteId,
            cardTemplateName: data.badgeTemplateName.value,
            trade: dirtyFields?.trade ? data.trade : undefined,
            jobTitle: dirtyFields?.jobTitle ? data.jobTitle : undefined,
          },
        });
        onAction(currentCardPrintJob?.data?.createCardPrintJob as CardPrintJob);
      } catch (error) {
        if (hasGraphQLErrorMessage(error, 'Cannot crop profile image for worker', MatchBy.startOfMessage)) {
          formApi.setError('profilePictureFile', {
            message: RequiredField`Photo`,
            shouldFocus: true,
          });
        } else {
          AlertInstance.alert('tc', 'danger', 'Error sending card print request', getGraphQLError(error));
        }
        return;
      }
    } finally {
      if (isMounted()) setIsFetching(false);
    }
  };

  const workerPhotoUrl = worker?.profilePictureCropped?.downloadUrl;
  const badgeTemplateOptions = useJobsiteBadgeTemplateOptions(jobsiteId);
  const formInputs = getFormInputs({ workerPhotoUrl, badgeTemplateOptions });
  const defaultValues = getFormDefaultValues({ worker, badgeTemplateOptions });

  return (
    <ModalFormContent
      setOpen={setOpen}
      renderAbove={(): React.ReactElement => <p>{instructions}</p>}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Print Badge"
      actionIcon={PrintBadgeIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
