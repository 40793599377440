import React from 'react';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { getFaIcon } from '@odin-labs/components';
import { getCurrentDomain } from 'utils';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { GradientCard } from 'containers/selfOnboarding/steps/components';

const classes = {
  workerTabPage: '',
  qrCodeHolder: cn(
    'odin-w-64 odin-h-64 odin-rounded-full odin-bg-white odin-shadow-xl',
    'odin-flex odin-items-center odin-justify-center',
  ),
};

const ShieldCheckIcon = getFaIcon({ icon: faShieldCheck, className: 'odin-text-odin-primary odin-h-5' });

export function UnableToVerifyAccountStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, localize } = props;
  const { matchedWorker } = state;
  const { quickCode, workerId } = matchedWorker ?? {};
  const qrUrl = `${getCurrentDomain()}/worker/${workerId}`;

  return (
    <div className={commonClasses.stepContainer}>
      <div className={commonClasses.stepHeader}>
        <GradientCard
          headerText={localize(Copy.self_onboarding_unable_to_verify_account_header)}
          text={localize(Copy.self_onboarding_unable_to_verify_account_instructions)}
        />
      </div>
      <div className="odin-mt-9 sm:odin-col-span-3 odin-flex odin-justify-center odin-items-center">
        <div className={classes.qrCodeHolder}>
          <QRCode value={qrUrl} size={150} />
        </div>
      </div>
      <div className="odin-mt-8 sm:odin-col-span-3 odin-flex odin-justify-center">
        <ShieldCheckIcon />
        <span className="odin-ml-1.5 odin-text-base odin-text-gray-900 odin-font-medium">{quickCode}</span>
      </div>
    </div>
  );
}
