import React, { ReactElement } from 'react';
import cn from 'classnames';
import { hot } from 'react-hot-loader';
import { AuthLayoutProps } from 'layouts/auth/types';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { isSsr } from 'utils';

export function AuthLayoutWithoutHot(props: AuthLayoutProps): ReactElement {
  const { route } = props;

  if (isSsr()) return null;

  return (
    <div
      className={cn(
        'auth-layout',
        'd-flex',
        'align-items-center',
        'bg-auth',
        'border-top',
        'border-top-2',
        'border-primary',
      )}
    >
      {renderRoutes(route.routes as RouteConfig[])}
    </div>
  );
}

export const AuthLayout = hot(module)(AuthLayoutWithoutHot);
