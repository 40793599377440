import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { JobsiteMember } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { GET_MEMBER_JOBSITES } from 'graphql/server/queries/jobsite';
import { Table, BadgesCell, BadgesSkeleton } from '@odin-labs/components';
import { QueryGetMemberJobsitesResponse } from 'graphql/server/queries/types';
import { AuthContext } from 'auth';

export function JobsiteInformation(): ReactElement {
  const { currentUser: user } = React.useContext(AuthContext);
  const { userAccountId } = user ?? {};

  const {
    data: jobsiteData,
    error: jobsiteDataError,
    loading: jobsiteDataLoading,
  } = useQuery<QueryGetMemberJobsitesResponse>(GET_MEMBER_JOBSITES, {
    variables: {
      memberUserAccountId: userAccountId,
    },
    skip: userAccountId == null,
    fetchPolicy: 'no-cache',
  });

  if (jobsiteDataError) {
    return <LoadingError error={jobsiteDataError} />;
  }

  const columns = [
    {
      Header: 'Jobsite',
      accessor: 'name',
      Cell: BadgesCell,
      CellLoading: BadgesSkeleton,
    },
    {
      Header: 'Role',
      accessor: 'title',
      Cell: BadgesCell,
      CellLoading: BadgesSkeleton,
    },
  ];

  const tableData = (jobsiteData?.getMemberJobsites?.jobsiteMembers ?? []).map((mappedJobsite: JobsiteMember): any => {
    return {
      name: mappedJobsite?.jobsite?.name,
      title: mappedJobsite?.title,
    };
  });

  return (
    <Table loading={jobsiteDataLoading} columns={columns} data={tableData} remote disableGlobalFilter disableSortBy />
  );
}
