import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

type PDFDownloadLinkProps = React.ComponentProps<typeof PDFDownloadLink>;
export type PdfDocumentDownloadLinkProps = Required<Pick<PDFDownloadLinkProps, 'document' | 'fileName'>> & {
  text: string;
  icon: ({ className, ...props }: { className?: string }) => React.ReactElement;
};

export const classes = {
  skeleton: 'odin-text-transparent odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
};

export function PdfDocumentDownloadLink(props: PdfDocumentDownloadLinkProps): React.ReactElement {
  const { document, fileName, text, icon: Icon } = props;

  return (
    <PDFDownloadLink document={document} fileName={fileName}>
      {({ loading, error }): React.ReactNode => {
        if (error) {
          return null;
        }

        if (loading) {
          return <span className={classes.skeleton}>Loading document...</span>;
        }

        return (
          <>
            {Icon && <Icon className="odin-mr-2" />}
            {text && <span>{text}</span>}
          </>
        );
      }}
    </PDFDownloadLink>
  );
}
