import React, { useState, ReactElement } from 'react';
import { GetWorkerDataDocument, useUpdateJobsiteWorkerMutation } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getJobsitesOptions } from './utils';
import { EditJobsiteWorkerModalProps, TRUE } from './types';
import { getDefaultValues, getFormInputs, EditJobsiteWorkerFormData } from './EditJobsiteWorkerModal.forms';

export function EditJobsiteWorkerModal(props: EditJobsiteWorkerModalProps): ReactElement {
  const { isOpen, closeModal, onClosed, onConfirm, worker, jobsiteWorker } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { currentUser: user } = React.useContext(AuthContext);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetWorkerDataDocument],
  });

  const onSubmit: FormOnSubmit<EditJobsiteWorkerFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const {
      hasEscortPrivileges,
      isExemptFromSwipeInactivity,
      isExemptFromSiteSpecificRefresher,
      payrollId,
      helmetNumber,
      startDate,
    } = data;

    try {
      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId: jobsiteWorker?.jobsiteWorkerId,
          jobsiteWorkerInput: {
            isAntipassbackExempt: hasEscortPrivileges.value === TRUE,
            isExemptFromSwipeInactivity: isExemptFromSwipeInactivity.value === TRUE,
            isExemptFromSiteSpecificRefresher: isExemptFromSiteSpecificRefresher.value === TRUE,
            stickerNumber: helmetNumber ? Number(helmetNumber) : null,
            payrollId: payrollId || null,
            startDate: startDate?.toDate() ?? null,
          },
        },
      });

      onConfirm();
      AlertInstance.alert('tc', 'success', 'Success', 'Worker data successfully updated');
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};
  const jobsitesOptions = React.useMemo(() => getJobsitesOptions([jobsite].filter(Boolean)), [jobsite]);
  const formInputs = React.useMemo(() => getFormInputs({ jobsitesOptions, user }), [jobsitesOptions, user]);
  const defaultValues = React.useMemo(
    () => getDefaultValues({ worker, jobsiteWorker, jobsitesOptions }),
    [worker, jobsiteWorker, jobsitesOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      afterLeave={onClosed}
      onIsDirtyChange={setIsFormDirty}
      title="Update worker data"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionButtonEnabled={isFormDirty}
      actionButtonWithSpinner={isFetching}
    />
  );
}
