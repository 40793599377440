import { PDFDocument } from 'pdf-lib';
import { ValidateResult } from 'react-hook-form';
import { validateFiles } from 'utils';

export function range(start: number, end: number): number[] {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i - 1);
}

export type PdfFile = File & {
  isMultiPagePdf?: boolean;
};

export async function extractPdfPages(
  pdf: File | string | Uint8Array | ArrayBuffer,
  indices: number[],
): Promise<PdfFile> {
  const pdfDoc = pdf instanceof File ? await pdf.arrayBuffer() : pdf;
  const pdfSrcDoc = await PDFDocument.load(pdfDoc);
  const pdfNewDoc = await PDFDocument.create();
  const pages = await pdfNewDoc.copyPages(pdfSrcDoc, indices);
  pages.forEach((page) => pdfNewDoc.addPage(page));
  const pdfBytes = await pdfNewDoc.save();
  const pdfName = pdf instanceof File ? pdf.name : '';
  const file = new File([pdfBytes], pdfName, {
    type: 'application/pdf',
  });
  return Object.assign(file, { isMultiPagePdf: !!pdfSrcDoc.getPageIndices().length });
}

export async function extractPdfFirstPage(pdf: File | string | Uint8Array | ArrayBuffer): Promise<PdfFile> {
  return extractPdfPages(pdf, [0]);
}

export const dropzoneValidation = async (data: File[]): Promise<ValidateResult> => {
  if (data?.length) {
    return validateFiles(data);
  }
  return true;
};
