import { TabConfig } from '@odin-labs/components';
import {
  useGetDeveloperContainerDeveloperInfoQuery,
  useGetRolesQuery,
  UserAssignmentType,
  UserRoleKey,
} from 'apollo/generated/client-operations';

import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { DeveloperJobsitesTabContainer } from 'containers/developer/tabs/DeveloperJobsitesTabContainer';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { UsersTab } from 'containers/users/UsersTab';

import { DeveloperTitle } from './components/DeveloperTitle';
import { DeveloperInfoTabContainer } from './tabs/DeveloperInfoTabContainer';
import { DeveloperWorkersTabContainer } from './tabs/DeveloperWorkersTabContainer';
import { DeveloperIcon, DeveloperInfo, DeveloperTabApi, DeveloperTabProps } from './types';

const getTabsConfig = (developer: DeveloperInfo): TabConfig<DeveloperTabProps>[] => [
  { name: 'Client Info', relativePath: '', component: DeveloperInfoTabContainer, leftIcon: DeveloperIcon },
  {
    name: `Jobsites`,
    relativePath: '/jobsites',
    component: DeveloperJobsitesTabContainer,
    badge: developer?.jobsites?.count?.toString(),
  },
  {
    name: `Workers`,
    relativePath: '/workers',
    component: DeveloperWorkersTabContainer,
  },
  {
    name: `Users`,
    relativePath: '/users',
    component: UsersTab,
  },
];

export function DeveloperContainer(): ReactElement {
  const { clientId } = useParams<{ clientId: string }>();
  const baseRoute = `/client/${clientId}`;

  const { data, loading, error, refetch } = useGetDeveloperContainerDeveloperInfoQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      developerId: clientId,
      includeArchived: false,
    },
  });

  const developer = data?.getDeveloper;
  const jobsites = developer?.jobsites?.edges?.map((e) => e.node);
  const filterJobsiteIds = jobsites?.map((j) => j.jobsiteId);

  const tabsConfig = getTabsConfig(developer);
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig, baseRoute });

  const [tabApi, setTabApi] = React.useState<DeveloperTabApi>(null);

  const { data: rolesData } = useGetRolesQuery({ fetchPolicy: 'network-only' });
  const userRoles = rolesData?.getRoles.filter(({ key }) => key !== UserRoleKey.Worker);

  const tabsPageProps: DeveloperTabProps = {
    loading,
    error,
    refetchDeveloperData: refetch,
    onTabApiChange: setTabApi,
    developer,
    jobsites,
    userAssignmentType: UserAssignmentType.Jobsite,
    userRoles,
    initialJobsiteIds: filterJobsiteIds,
  };
  return (
    <Container className="client-container">
      <NewHeader
        title={<DeveloperTitle developer={developer} />}
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          headerActions: ['back'],
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
    </Container>
  );
}
