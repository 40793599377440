import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';

import { useGetContractorStripePaymentMethodsDetailsQuery } from 'apollo/generated/client-operations';
import { AddStripePaymentMethodModalProps } from './types';

let loadedStripe: Promise<Stripe | null>;

export function AddStripePaymentMethodModal(props: AddStripePaymentMethodModalProps): ReactElement {
  const {
    isOpen,
    // contractor,
    contractor: { contractorId },
    onCancel,
    onConfirm,
  } = props;

  const { data } = useGetContractorStripePaymentMethodsDetailsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !isOpen,
    variables: {
      contractorId,
      includeArchived: true,
    },
  });

  const { stripePromise, checkoutOptions } = React.useMemo(() => {
    const stripeDetails = data?.getContractor?.stripeMappingDetails;
    if (!stripeDetails || !isOpen) return {};
    if (!loadedStripe) {
      loadedStripe = loadStripe(stripeDetails?.publicKey);
    }
    const options = { clientSecret: stripeDetails?.clientSecret };
    return { stripePromise: loadedStripe, checkoutOptions: options };
  }, [data, isOpen]);

  if (isOpen && stripePromise) {
    return (
      <Modal open={isOpen} setOpen={onCancel} onAction={onConfirm} actionsPanel={null}>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ ...checkoutOptions, onComplete: onConfirm }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </Modal>
    );
  }
  return null;
}
