import React from 'react';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { Modal, getFaIcon } from '@odin-labs/components';

export type UnsavedChangesWarningModalProps = {
  open: boolean;
  onSaveChanges: () => void;
  onDiscardChanges: () => void;
  onCancel: () => void;
};

const TimesIcon = getFaIcon({ icon: faTimes, className: 'odin-text-odin-primary' });
const CheckIcon = getFaIcon({ icon: faCheck });

export function UnsavedChangesWarningModal(props: UnsavedChangesWarningModalProps): React.ReactElement {
  const { open, onSaveChanges, onDiscardChanges, onCancel } = props;
  return (
    <Modal
      size="xs"
      open={open}
      setOpen={onCancel}
      title="You have unsaved changes"
      titleAlignment="center"
      text={'If you leave this page without saving, you will lose any \nchanges made since the last save.'}
      textAlignment="center"
      closeButtonBehavior="dismiss"
      cancelIcon={TimesIcon}
      cancelText="Leave without saving"
      onCancel={onDiscardChanges}
      actionIcon={CheckIcon}
      actionText="Save Changes"
      onAction={onSaveChanges}
    />
  );
}
