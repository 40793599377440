import React from 'react';
import { useGetEntityChangesQuery } from 'apollo/generated/client-operations';
import { EntityChange } from 'containers/entityChange/types';
import { NewHeader } from 'components/header/NewHeader';
import { WorkerChangeTabProps } from 'containers/entityChange/worker/types';
import { LoadingError } from 'components/loadingError';
import { getChangesByLayout, getColumns } from 'containers/entityChange/worker/tabs/tables';
import { Table, TableContainer } from '@odin-labs/components';
import { ensureNonEmptyItems } from 'utils';

function getChangesByEntityId(
  entityIds: string[],
  changes: (EntityChange & { objectMemberId: string })[],
): Record<string, EntityChange[]> {
  const initialValue: Record<string, EntityChange[]> = entityIds.reduce(
    (result, entityId) => Object.assign(result, { [entityId]: [] }),
    {},
  );
  return (
    changes?.reduce((result, change) => {
      result[change.objectMemberId].push(change);
      return result;
    }, initialValue) ?? initialValue
  );
}

export function WorkerUserRoles(props: WorkerChangeTabProps): React.ReactElement {
  const { loading: parentLoading, worker, changesLayout, onTabApiChange } = props;
  const { workerId } = worker ?? {};

  const userRoles = React.useMemo(() => worker?.user.roles ?? [], [worker]);
  const { jmIds, cmIds, urmIds } = React.useMemo(() => {
    return userRoles.reduce(
      (result, ur) => {
        const { type, objectMemberId } = ur.object.objectDetails;
        (type === 'Jobsite' ? result.jmIds : result.cmIds).push(objectMemberId);
        result.urmIds.push(ur.mappingId);
        return result;
      },
      { jmIds: [] as string[], cmIds: [] as string[], urmIds: [] as string[] },
    );
  }, [userRoles]);

  const objectMemberIdByUserRole = Object.fromEntries(
    userRoles.map((ur) => [ur.mappingId, ur.object.objectDetails.objectMemberId]),
  );

  const {
    data: userRolesChangesData,
    loading: userRolesChangesLoading,
    error: userRolesChangesError,
    refetch: refetchUserRolesChanges,
  } = useGetEntityChangesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        // paginationInput: { limit, offset },
        filters: ensureNonEmptyItems([
          urmIds.length && { tableName: 'user_role_mapping', entityIds: urmIds },
          jmIds.length && { tableName: 'jobsite_member', entityIds: jmIds },
          cmIds.length && { tableName: 'contractor_member', entityIds: cmIds },
        ]),
      },
    },
    skip: !workerId || (!jmIds.length && !cmIds.length && !urmIds.length),
  });

  const userRolesChanges = React.useMemo(() => {
    return getChangesByEntityId(
      [...urmIds, ...jmIds, ...cmIds],
      getChangesByLayout(userRolesChangesData?.getEntityChanges.edges.map(({ node }) => node) ?? [], changesLayout).map(
        (change) => ({
          ...change,
          objectMemberId:
            change.tableName === 'user_role_mapping' ? objectMemberIdByUserRole[change.entityId] : change.entityId,
        }),
      ),
    );
  }, [jmIds, urmIds, cmIds, userRolesChangesData, changesLayout]);

  React.useEffect(() => onTabApiChange({ refetchData: refetchUserRolesChanges }), [refetchUserRolesChanges]);

  const loading = parentLoading || userRolesChangesLoading;
  const error = userRolesChangesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <div className="odin-flex odin-flex-col odin-gap-9">
      {userRoles.map((userRole) => {
        const { type, name, objectMemberId } = userRole.object.objectDetails;
        const urChanges = userRolesChanges[objectMemberId];
        const urColumns = React.useMemo(() => getColumns(urChanges, changesLayout), [urChanges, changesLayout]);
        const urTitle = `${type}: ${name} / ${userRole.displayName}`;

        return (
          <div key={objectMemberId} className="odin-flex odin-flex-col odin-gap-y-6">
            <TableContainer>
              <NewHeader size="md" title={`${urTitle} - Changes`} actionsProps={{ headerActions: null }} />
              <Table
                loading={loading}
                columns={urColumns}
                data={urChanges}
                // initialState={{ sortBy: tableSortBy, pageSize: limit }}
                // pageCount={pageCount}
                // pageIndex={page}
                // remote
                // enablePagination
              />
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
}
