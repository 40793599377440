import classNames from 'classnames/bind';
import backgroundImage from 'images/crane.jpg';
import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'reactstrap';

import * as styles from './scss/mobile-only.scss';

const cx = classNames.bind(styles);

export function MobileOnlyContainer(): ReactElement {
  return (
    <div className={cx('d-flex', 'align-items-center', 'bg-auth', 'border-top', 'border-top-2', 'border-primary')}>
      <Container fluid className={cx('mobile-only-container')}>
        <Row className={cx('align-items-center', 'justify-content-center')}>
          <Col xs="12" md="5" lg="6" xl="6" className={cx('px-lg-6', 'my-5')}>
            <div className={cx('text-center')}>
              <h6 className={cx('text-uppercase text-muted mb-4')}>Please use our app</h6>
              <h1 className={cx('display-4', 'mb-3')}>
                Please download the mobile app from the Apple app store or Google play to login.
              </h1>
              <p className={cx('text-muted mb-4')}>Click the link below to login on your mobile device</p>
              <a href="https://useodin.com/download" className={cx('btn btn-lg btn-primary')}>
                Download Now
              </a>
            </div>
          </Col>
          <Col xs="12" md="7" lg="6" xl="6" className={cx('d-none', 'd-lg-block')}>
            <div
              className={cx('bg-cover', 'vh-100', 'mt-n1', 'mr-n3')}
              style={{ backgroundImage: `url(${backgroundImage})` }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
