import cn from 'classnames';
import React from 'react';

export const classes = {
  detailContainer: cn('odin-flex odin-text-gray-600'),
  detailLabel: cn('odin-text-sm odin-w-5/12 sm:odin-w-4/12'),
  detailValue: cn('odin-text-base odin-w-7/12 sm:odin-w-8/12 odin-ml-4'),
  skeleton: cn('odin-text-transparent odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1'),
};

export type DeveloperJobsiteDetailProps = React.PropsWithChildren<{
  label: string;
  value?: React.ReactNode;
  loading?: boolean;
}>;

export function DeveloperJobsiteDetail(props: DeveloperJobsiteDetailProps): React.ReactElement {
  const { label, value, children, loading } = props;
  return (
    <div className={classes.detailContainer}>
      <div className={classes.detailLabel}>{label}</div>
      <div className={cn(classes.detailValue, loading && classes.skeleton)}>
        {loading ? 'Loading' : value ?? children}
      </div>
    </div>
  );
}
