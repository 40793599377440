import { RoutePage } from 'pages/RoutePage';
import { ExtendedRouteProps } from 'routes/types';
import { UserPendingChangeContainer } from 'containers/userPendingChange/UserPendingChangeContainer';

export const userChangeConfirmationRoutes: ExtendedRouteProps[] = [
  {
    component: RoutePage,
    path: '/user-change-confirmation/:changeType/:workerId',
    title: 'User data update',
    exact: true,
    container: UserPendingChangeContainer,
    meta: [
      {
        name: 'description',
        content: 'User data update',
      },
    ],
  },
];
