import React from 'react';
import cn from 'classnames';
import { Logo } from '@odin-labs/components';
import { Copy, languageOptions } from 'containers/selfOnboarding/steps/utils';
import { SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';

const classes = {
  languageStepContainer: cn('odin-flex odin-flex-col odin-items-center odin-py-9 odin-gap-y-9'),
  list: cn(
    'odin-px-7 odin-py-2',
    'odin-bg-white odin-shadow odin-rounded-xl',
    'odin-divide-y odin-divide-gray-200',
    'odin-w-5/6 odin-max-w-sm',
  ),
  button: cn('odin-text-odin-primary odin-font-medium odin-text-base', 'odin-text-center odin-py-4 odin-w-full'),
  text: cn('odin-mb-0 odin-text-center odin-text-white odin-font-medium odin-text-1.5xl odin-w-5/6'),
};

export function LanguageSelectionStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { localize, onLanguageChange, navigation } = props;
  const { goToNextStep } = navigation;

  const [languageIndex, setLanguageIndex] = React.useState(0);
  const language = languageOptions[languageIndex].value;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setLanguageIndex((current) => (current + 1) % (languageOptions.length - 1));
    }, 2000);

    return (): void => clearInterval(timer);
  }, []);

  return (
    <div className={classes.languageStepContainer}>
      <Logo logoStyle="stacked" size="lg" />
      <h2 className={classes.text}>{localize(Copy.self_onboarding_get_started_text, { locale: language })}</h2>
      <ul className={classes.list}>
        {languageOptions.map((opt) => (
          <li key={opt.value} className="odin-text-center">
            <button
              className={classes.button}
              onClick={(): void => {
                onLanguageChange(opt);
                goToNextStep();
              }}
            >
              {localize(Copy.language, { locale: opt.value })}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
