/* eslint-disable max-len */
import { addTranslations } from 'utils/localization';

export enum Copy {
  language = 'language',
  language_selector_label = 'language_selector_label',
  birth_date_label = 'birth_date_label',
  birth_date_mask_text = 'birth_date_mask_text',
  birth_date_invalid_value_validation = 'birth_date_invalid_value_validation',
  birth_date_missing_value_validation = 'birth_date_missing_value_validation',
  primary_language_label = 'primary_language_label',
  field_required_label = 'field_required_label',
  field_optional_label = 'field_optional_label',
  picklist_mask_general = 'picklist_mask_general',
  thank_you_message = 'thank_you_message',
  mobile_badging_auth_error = 'mobile_badging_auth_error',
  mobile_badging_header = 'mobile_badging_header',
  mobile_badging_instructions = 'mobile_badging_instructions',
  mobile_badging_download_header = 'mobile_badging_download_header',
  mobile_badging_download_instructions = 'mobile_badging_download_instructions',
  continue = 'continue',
  skip_for_now = 'skip_for_now',
  save = 'save',
  cancel = 'cancel',
  add = 'add',
}

const en: Record<Copy, string> = {
  [Copy.language]: 'English',
  [Copy.language_selector_label]: 'Form Language',
  [Copy.birth_date_label]: 'Date of Birth',
  [Copy.birth_date_mask_text]: 'MM/DD/YYYY',
  [Copy.birth_date_invalid_value_validation]: 'Enter a valid date',
  [Copy.birth_date_missing_value_validation]: 'Date of birth required',
  [Copy.primary_language_label]: 'Preferred Language',
  [Copy.field_required_label]: '(Required)',
  [Copy.field_optional_label]: '(Optional)',
  [Copy.picklist_mask_general]: 'Select...',
  [Copy.thank_you_message]: 'Thank you!',
  [Copy.mobile_badging_auth_error]: 'The date of birth you entered does not match.',
  [Copy.mobile_badging_header]: 'Mobile Badging',
  [Copy.mobile_badging_instructions]: 'Please validate your date of birth.',
  [Copy.mobile_badging_download_header]: 'Add Mobile Badge to Wallet',
  [Copy.mobile_badging_download_instructions]: 'TBD - Download app text...',
  [Copy.continue]: 'Continue',
  [Copy.skip_for_now]: 'Skip for now',
  [Copy.save]: 'Save',
  [Copy.cancel]: 'Cancel',
  [Copy.add]: 'Add',
};

const es: Record<Copy, string> = {
  [Copy.language]: 'Español',
  [Copy.language_selector_label]: 'Idioma del formulario',
  [Copy.birth_date_label]: 'Fecha de nacimiento',
  [Copy.birth_date_mask_text]: 'MES/DÍA/AÑO',
  [Copy.birth_date_invalid_value_validation]: 'Introduce una fecha válida',
  [Copy.birth_date_missing_value_validation]: 'Fecha de nacimiento requerida',
  [Copy.primary_language_label]: 'Idioma preferido',
  [Copy.field_required_label]: '(Obligatorio)',
  [Copy.field_optional_label]: '(Opcional)',
  [Copy.picklist_mask_general]: 'Seleccionar...',
  [Copy.thank_you_message]: '¡Gracias!',
  [Copy.mobile_badging_auth_error]: 'La fecha de nacimiento que ingresó no coincide.',
  [Copy.mobile_badging_header]: 'Credencialización Móvil',
  [Copy.mobile_badging_instructions]: 'Por favor valide su fecha de nacimiento',
  [Copy.mobile_badging_download_header]: 'Agregar insignia móvil a la billetera',
  [Copy.mobile_badging_download_instructions]: 'La fecha de nacimiento que ingresó no coincide.',
  [Copy.continue]: 'Continuar',
  [Copy.skip_for_now]: 'Saltar por ahora',
  [Copy.save]: 'Guardar',
  [Copy.cancel]: 'Cancelar',
  [Copy.add]: 'Agregar',
};

const zhCN: Record<Copy, string> = {
  [Copy.language]: '简体中文',
  [Copy.language_selector_label]: '表单语言',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '请输入一个有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.primary_language_label]: '首选语言',
  [Copy.field_required_label]: '(必填）',
  [Copy.field_optional_label]: '(可选）',
  [Copy.picklist_mask_general]: '选择...',
  [Copy.thank_you_message]: '谢谢！',
  [Copy.mobile_badging_auth_error]: '您輸入的出生日期不匹配',
  [Copy.mobile_badging_header]: '移动徽章',
  [Copy.mobile_badging_instructions]: '请验证您的出生日期',
  [Copy.mobile_badging_download_header]: '将移动徽章添加到钱包',
  [Copy.mobile_badging_download_instructions]: '点击下面的链接之一即可添加到您的移动钱包。',
  [Copy.continue]: '继续',
  [Copy.skip_for_now]: '暂时跳过',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.add]: '添加',
};

const zhTW: Record<Copy, string> = {
  [Copy.language]: '繁體中文',
  [Copy.language_selector_label]: '表單語言',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '請輸入一個有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.primary_language_label]: '首選語言',
  [Copy.field_required_label]: '(必填）',
  [Copy.field_optional_label]: '(可選）',
  [Copy.picklist_mask_general]: '選擇...',
  [Copy.thank_you_message]: '謝謝！',
  [Copy.mobile_badging_auth_error]: '您輸入的出生日期不匹配',
  [Copy.mobile_badging_header]: '移動徽章',
  [Copy.mobile_badging_instructions]: '請驗證您的出生日期',
  [Copy.mobile_badging_download_header]: '將移動徽章添加到錢包',
  [Copy.mobile_badging_download_instructions]: '點擊下面的鏈接之一即可添加到您的移動錢包。',
  [Copy.continue]: '繼續',
  [Copy.skip_for_now]: '暫時跳過',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.add]: '添加',
};

const pl: Record<Copy, string> = {
  [Copy.language]: 'Polski',
  [Copy.language_selector_label]: 'Język formularza',
  [Copy.birth_date_label]: 'Data urodzenia',
  [Copy.birth_date_mask_text]: 'MM/DD/RRRR',
  [Copy.birth_date_invalid_value_validation]: 'Wpisz poprawną datę',
  [Copy.birth_date_missing_value_validation]: 'Podanie daty urodzenia jest wymagane',
  [Copy.primary_language_label]: 'Preferowany język',
  [Copy.field_required_label]: '(wymagane)',
  [Copy.field_optional_label]: '(opcjonalne)',
  [Copy.picklist_mask_general]: 'Wybierz...',
  [Copy.thank_you_message]: 'Dziękuję Ci!',
  [Copy.mobile_badging_auth_error]: 'Wprowadzona data urodzenia nie zgadza się',
  [Copy.mobile_badging_header]: 'Odznaka mobilna',
  [Copy.mobile_badging_instructions]: 'Potwierdź swoją datę urodzenia',
  [Copy.mobile_badging_download_header]: 'Dodaj odznakę mobilną do portfela',
  [Copy.mobile_badging_download_instructions]: 'Stuknij jedno z poniższych łączy, aby dodać je do portfela mobilnego.',
  [Copy.continue]: 'Kontynuuj',
  [Copy.skip_for_now]: 'Pomiń to na razie',
  [Copy.save]: 'Zapisz',
  [Copy.cancel]: 'Anuluj',
  [Copy.add]: 'Dodać',
};

const ru: Record<Copy, string> = {
  [Copy.language]: 'Русский',
  [Copy.language_selector_label]: 'Язык формы',
  [Copy.birth_date_label]: 'Дата рождения',
  [Copy.birth_date_mask_text]: 'ММ/ДД/ГГГГ',
  [Copy.birth_date_invalid_value_validation]: 'Введите действительное значение для поля "Дата рождения"',
  [Copy.birth_date_missing_value_validation]: 'Требуется заполнить поле "Дата рождения"',
  [Copy.primary_language_label]: 'Предпочитаемый язык',
  [Copy.field_required_label]: '(Обязательно)',
  [Copy.field_optional_label]: '(Необязательно)',
  [Copy.picklist_mask_general]: 'Выберите...',
  [Copy.thank_you_message]: 'Благодарю вас!',
  [Copy.mobile_badging_auth_error]: 'Введенная дата рождения не совпадает.',
  [Copy.mobile_badging_header]: 'Мобильный бейдж',
  [Copy.mobile_badging_instructions]: 'Пожалуйста, подтвердите свою дату рождения.',
  [Copy.mobile_badging_download_header]: 'Добавить значок мобильного телефона в кошелек',
  [Copy.mobile_badging_download_instructions]:
    'Нажмите на одну из ссылок ниже, чтобы добавить в свой мобильный кошелек.',
  [Copy.continue]: 'Продолжить',
  [Copy.skip_for_now]: 'Пропустить сейчас',
  [Copy.save]: 'Сохранить',
  [Copy.cancel]: 'Отменить',
  [Copy.add]: 'Добавлять',
};

const hu: Record<Copy, string> = {
  [Copy.language]: 'Magyar',
  [Copy.language_selector_label]: 'Formanyelv',
  [Copy.birth_date_label]: 'Születési dátum',
  [Copy.birth_date_mask_text]: 'HH/NN/ÉÉÉÉ',
  [Copy.birth_date_invalid_value_validation]: 'Adjon meg egy érvényes dátumot',
  [Copy.birth_date_missing_value_validation]: 'Születési dátum kötelező',
  [Copy.primary_language_label]: 'Preferált nyelv',
  [Copy.field_required_label]: '(Kötelező)',
  [Copy.field_optional_label]: '(Nem kötelező)',
  [Copy.picklist_mask_general]: 'Válassza ki...',
  [Copy.thank_you_message]: 'Köszönöm!',
  [Copy.mobile_badging_auth_error]: 'A megadott születési dátum nem egyezik.',
  [Copy.mobile_badging_header]: 'Mobil jelvény',
  [Copy.mobile_badging_instructions]: 'Kérjük, erősítse meg születési dátumát.',
  [Copy.mobile_badging_download_header]: 'Adja hozzá a mobil jelvényt a pénztárcához',
  [Copy.mobile_badging_download_instructions]:
    'Koppintson az alábbi hivatkozások egyikére, hogy hozzáadja mobiltárcájához.',
  [Copy.continue]: 'Folytatni',
  [Copy.skip_for_now]: 'Most hagyd ki',
  [Copy.save]: 'Menteni',
  [Copy.cancel]: 'Megszüntetni',
  [Copy.add]: 'Hozzáadás',
};

export const Languages = {
  en,
  es,
  zhCN,
  zhTW,
  pl,
  ru,
  hu,
};

addTranslations({ ...Languages, en_US: en });
