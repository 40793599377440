import { LanguageOption } from 'containers/mobileBadge/types';
import { Localize } from 'utils/localization';

export enum MobileBadgeStep {
  UserValidation = 'user-validation',
  BadgeDownload = 'badge-download',
}

export type UserValidationFormData = {
  birthDate: string;
  primaryLanguage?: string;
};

export type UserValidationStepProps = {
  workerId: string;
  workerCardId: string;
  language: string;
  onLanguageChange: (language: LanguageOption) => void;
  localize: Localize;
  setCurrentStep: (step: MobileBadgeStep) => void;
};

export type MobileBadgeDownloadStepProps = {
  workerId: string;
  workerCardId: string;
  localize: Localize;
};
