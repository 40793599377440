import React from 'react';
import moment from 'moment';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { getIcon } from 'utils/ui';
import { GetWorkerCardsDocument, useAddCardToWorkerMutation, WorkerCardType } from 'apollo/generated/client-operations';
import { useIsMounted } from 'utils';
import { AssignQRFormData } from './types';

export type ReassignQRModalContentProps = {
  setOpen: () => void;
  onAction: () => void;
  jobsiteId: string;
  workerId: string;
  assignQRData: AssignQRFormData;
  workerCardFormatId: string;
};

const CheckIcon = getIcon('fal fa-check');

export function ReassignQRModalContent(props: ReassignQRModalContentProps): React.ReactElement {
  const { setOpen, onAction, jobsiteId, workerId, assignQRData, workerCardFormatId } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [addCardToWorker] = useAddCardToWorkerMutation({
    refetchQueries: [GetWorkerCardsDocument],
  });

  const onSubmit: FormOnSubmit<unknown> = async (_data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { qrCodeNumber } = assignQRData;

    try {
      await addCardToWorker({
        variables: {
          workerId,
          jobsiteId,
          forceCreate: true,
          workerCardInput: {
            isTemporary: false,
            cardIssueReason: null,
            cardNumber: qrCodeNumber,
            startDate: moment().toDate(),
            endDate: null,
            type: WorkerCardType.QrCode,
            workerCardFormatId,
          },
        },
      });
      onAction();
      AlertInstance.alert('tc', 'success', 'Success', 'QR Code assigned');
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  return (
    <ModalFormContent
      loading={isFetching}
      setOpen={setOpen}
      inputs={null}
      onSubmit={onSubmit}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Yes"
      actionIcon={CheckIcon}
      cancelText="No"
      actionButtonWithSpinner={isFetching}
    />
  );
}
