import React, { ReactElement } from 'react';
import { Alert, ModalActionsPanel } from '@odin-labs/components';
import { LoadingError } from 'components/loadingError';
import { getIcon } from 'utils/ui';
import { Form } from './Form';
import { ModalFormContentProps, BackButtonProps } from './types';

const forwardedModalFormContent = React.forwardRef(
  <TFields,>(
    props: ModalFormContentProps<TFields> & BackButtonProps,
    ref: React.ForwardedRef<HTMLFormElement>,
  ): ReactElement => {
    const {
      // modal props
      setOpen,

      // loading-error
      loading,
      error,

      // modal actions props
      actionsContainerClassName,
      onAction,
      actionButton,
      actionButtonTheme,
      actionText,
      actionButtonEnabled,
      actionButtonWithSpinner,
      actionIcon,

      onCancel,

      // alert props
      alertProps,

      onBack,
      backIcon = getIcon('fal fa-arrow-left'),
      backButtonTheme = 'naked',
      backText = 'Back',
      backButtonEnabled = true,
      backButtonWithSpinner = false,

      // form props
      inputs,
      onSubmit,
      defaultValues,
      inputsContainerClassName,
      renderAbove,
    } = props;

    const formProps = {
      inputs,
      onSubmit,
      defaultValues,
      inputsContainerClassName,
      renderAbove,
    };

    const actionsPanelProps = {
      actionsContainerClassName,
      onAction,
      actionButton,
      actionButtonTheme,
      actionText,
      actionButtonEnabled,
      actionButtonWithSpinner,
      actionIcon,

      onCancel,
      onBack,
    };

    const onCancelHandler = React.useCallback((): void => {
      setOpen(false);
      onCancel?.();
    }, [setOpen, onCancel]);

    return loading || error ? (
      <LoadingError loading={loading} error={error} noPadding />
    ) : (
      <Form
        {...formProps}
        ref={ref}
        renderBelow={(): ReactElement => (
          <>
            {alertProps && (
              <div className="odin-mt-2">
                <Alert {...alertProps} />
              </div>
            )}
            <ModalActionsPanel
              {...actionsPanelProps}
              onCancel={onCancelHandler}
              onBack={onBack}
              showBackButton
              cancelText={backText}
              cancelIcon={backIcon}
              cancelButtonTheme={backButtonTheme}
              cancelButtonEnabled={backButtonEnabled}
              cancelButtonWithSpinner={backButtonWithSpinner}
              className={actionsContainerClassName}
              actionButtonType="submit"
            />
          </>
        )}
      />
    );
  },
);

type ForwardedModalFormContent = <TFields>(
  props: ModalFormContentProps<TFields> & {
    ref?: React.ForwardedRef<HTMLFormElement>;
  },
) => ReturnType<typeof forwardedModalFormContent>;

export const ModalFormContentWithBackButton = forwardedModalFormContent as ForwardedModalFormContent;
