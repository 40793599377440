/* eslint-disable max-len */
import { addTranslations } from 'utils/localization';

export enum Copy {
  language = 'language',
  language_selector_label = 'language_selector_label',
  birth_date_label = 'birth_date_label',
  birth_date_mask_text = 'birth_date_mask_text',
  birth_date_invalid_value_validation = 'birth_date_invalid_value_validation',
  birth_date_missing_value_validation = 'birth_date_missing_value_validation',
  primary_language_label = 'primary_language_label',
  field_required_label = 'field_required_label',
  field_optional_label = 'field_optional_label',
  picklist_mask_general = 'picklist_mask_general',
  thank_you_message = 'thank_you_message',
  continue = 'continue',
  skip_for_now = 'skip_for_now',
  save = 'save',
  cancel = 'cancel',
  add = 'add',
  auth_validation_error = 'auth_validation_error',
  invalid_image_header = 'invalid_image_header',
  invalid_image_message = 'invalid_image_message',
  facial_recognition_header = 'facial_recognition_header',
  auth_validation_instructions = 'auth_validation_instructions',
  auth_validation_body = 'auth_validation_body',
  facial_recognition_confirmation_header = 'facial_recognition_confirmation_header',
  facial_recognition_confirmation_body = 'facial_recognition_confirmation_body',
}

const en: Record<Copy, string> = {
  [Copy.language]: 'English',
  [Copy.language_selector_label]: 'Form Language',
  [Copy.birth_date_label]: 'Date of Birth',
  [Copy.birth_date_mask_text]: 'MM/DD/YYYY',
  [Copy.birth_date_invalid_value_validation]: 'Enter a valid date',
  [Copy.birth_date_missing_value_validation]: 'Date of birth required',
  [Copy.primary_language_label]: 'Preferred Language',
  [Copy.field_required_label]: '(Required)',
  [Copy.field_optional_label]: '(Optional)',
  [Copy.picklist_mask_general]: 'Select...',
  [Copy.thank_you_message]: 'Thank you!',
  [Copy.continue]: 'Continue',
  [Copy.skip_for_now]: 'Skip for now',
  [Copy.save]: 'Save',
  [Copy.cancel]: 'Cancel',
  [Copy.add]: 'Add',
  [Copy.auth_validation_error]: 'The date of birth you entered does not match.',
  [Copy.invalid_image_header]: 'Profile photo not accepted',
  [Copy.invalid_image_message]:
    'Please submit a clear photo of your face: be sure to capture your entire face in the center of the photo.',
  [Copy.facial_recognition_header]: 'Register for Facial Recognition',
  [Copy.auth_validation_instructions]: 'Please enter your date of birth to verify your identity.',
  [Copy.auth_validation_body]:
    'You have been invited to register your face to access a jobsite using facial recognition.',
  [Copy.facial_recognition_confirmation_header]: `Congrats, you're all done!`,
  [Copy.facial_recognition_confirmation_body]:
    'You will complete the face registration process when you arrive at the jobsite: please reach out to your onsite point of contact to continue.',
};

const es: Record<Copy, string> = {
  [Copy.language]: 'Español',
  [Copy.language_selector_label]: 'Idioma del formulario',
  [Copy.birth_date_label]: 'Fecha de nacimiento',
  [Copy.birth_date_mask_text]: 'MES/DÍA/AÑO',
  [Copy.birth_date_invalid_value_validation]: 'Introduce una fecha válida',
  [Copy.birth_date_missing_value_validation]: 'Fecha de nacimiento requerida',
  [Copy.primary_language_label]: 'Idioma preferido',
  [Copy.field_required_label]: '(Obligatorio)',
  [Copy.field_optional_label]: '(Opcional)',
  [Copy.picklist_mask_general]: 'Seleccionar...',
  [Copy.thank_you_message]: '¡Gracias!',
  [Copy.continue]: 'Continuar',
  [Copy.skip_for_now]: 'Saltar por ahora',
  [Copy.save]: 'Guardar',
  [Copy.cancel]: 'Cancelar',
  [Copy.add]: 'Agregar',
  [Copy.auth_validation_error]: 'La fecha de nacimiento que ingresó no coincide.',
  [Copy.invalid_image_header]: 'Foto de perfil no aceptada',
  [Copy.invalid_image_message]: 'Vuelva a tomar la foto: asegúrese de capturar toda su cara en el centro de la foto.',
  [Copy.facial_recognition_header]: 'Regístrese para el reconocimiento facial',
  [Copy.auth_validation_instructions]: 'Por favor ingrese su fecha de nacimiento para verificar su identidad.',
  [Copy.auth_validation_body]:
    'Se le ha invitado a registrar su rostro para acceder a un lugar de trabajo mediante reconocimiento facial.',
  [Copy.facial_recognition_confirmation_header]: `Felicidades, ¡ya has terminado!`,
  [Copy.facial_recognition_confirmation_body]:
    'Completará el proceso de registro facial cuando llegue al lugar de trabajo: comuníquese con su punto de contacto en el sitio para continuar.',
};

const zhCN: Record<Copy, string> = {
  [Copy.language]: '简体中文',
  [Copy.language_selector_label]: '表单语言',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '请输入一个有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.primary_language_label]: '首选语言',
  [Copy.field_required_label]: '(必填）',
  [Copy.field_optional_label]: '(可选）',
  [Copy.picklist_mask_general]: '选择...',
  [Copy.thank_you_message]: '谢谢！',
  [Copy.continue]: '继续',
  [Copy.skip_for_now]: '暂时跳过',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.add]: '添加',
  [Copy.auth_validation_error]: '您輸入的出生日期不匹配',
  [Copy.invalid_image_header]: '个人资料照片不被接受',
  [Copy.invalid_image_message]: '请重新拍摄照片：请务必将您的整个脸部拍摄到照片的中央。',
  [Copy.facial_recognition_header]: '注册人脸识别',
  [Copy.auth_validation_instructions]: '请输入您的出生日期以验证您的身份。',
  [Copy.auth_validation_body]: '您已被邀请注册您的面部，以便使用面部识别功能访问工作现场。',
  [Copy.facial_recognition_confirmation_header]: `恭喜，您已经完成了！`,
  [Copy.facial_recognition_confirmation_body]: '当您到达工作现场时，您将完成面部登记过程：请联系您的现场联系人以继续。',
};

const zhTW: Record<Copy, string> = {
  [Copy.language]: '繁體中文',
  [Copy.language_selector_label]: '表單語言',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '請輸入一個有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.primary_language_label]: '首選語言',
  [Copy.field_required_label]: '(必填）',
  [Copy.field_optional_label]: '(可選）',
  [Copy.picklist_mask_general]: '選擇...',
  [Copy.thank_you_message]: '謝謝！',
  [Copy.continue]: '繼續',
  [Copy.skip_for_now]: '暫時跳過',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.add]: '添加',
  [Copy.auth_validation_error]: '您輸入的出生日期不匹配',
  [Copy.invalid_image_header]: '個人資料照片不被接受',
  [Copy.invalid_image_message]: '請重新拍攝照片：請務必將您的整個臉部拍攝到照片的中央。',
  [Copy.facial_recognition_header]: '註冊人臉辨識',
  [Copy.auth_validation_instructions]: '請輸入您的出生日期以驗證您的身分。',
  [Copy.auth_validation_body]: '您已被邀請註冊您的臉部，以便使用臉部辨識功能訪問工作現場。',
  [Copy.facial_recognition_confirmation_header]: `恭喜，您已經完成了！`,
  [Copy.facial_recognition_confirmation_body]: '當您到達工作現場時，您將完成臉部登記程序：請聯絡您的現場聯絡人以繼續。',
};

const pl: Record<Copy, string> = {
  [Copy.language]: 'Polski',
  [Copy.language_selector_label]: 'Język formularza',
  [Copy.birth_date_label]: 'Data urodzenia',
  [Copy.birth_date_mask_text]: 'MM/DD/RRRR',
  [Copy.birth_date_invalid_value_validation]: 'Wpisz poprawną datę',
  [Copy.birth_date_missing_value_validation]: 'Podanie daty urodzenia jest wymagane',
  [Copy.primary_language_label]: 'Preferowany język',
  [Copy.field_required_label]: '(wymagane)',
  [Copy.field_optional_label]: '(opcjonalne)',
  [Copy.picklist_mask_general]: 'Wybierz...',
  [Copy.thank_you_message]: 'Dziękuję Ci!',
  [Copy.continue]: 'Kontynuuj',
  [Copy.skip_for_now]: 'Pomiń to na razie',
  [Copy.save]: 'Zapisz',
  [Copy.cancel]: 'Anuluj',
  [Copy.add]: 'Dodać',
  [Copy.auth_validation_error]: 'Wprowadzona data urodzenia nie zgadza się',
  [Copy.invalid_image_header]: 'Zdjęcie profilowe nie zostało zaakceptowane',
  [Copy.invalid_image_message]: 'Zrób zdjęcie ponownie: pamiętaj, aby uchwycić całą twarz na środku zdjęcia.',
  [Copy.facial_recognition_header]: 'Zarejestruj się w celu rozpoznawania twarzy',
  [Copy.auth_validation_instructions]: 'Podaj swoją datę urodzenia, aby zweryfikować swoją tożsamość.',
  [Copy.auth_validation_body]:
    'Zaproszono Cię do zarejestrowania swojej twarzy, aby uzyskać dostęp do miejsca pracy za pomocą funkcji rozpoznawania twarzy.',
  [Copy.facial_recognition_confirmation_header]: `Gratulacje, wszystko jest już gotowe!`,
  [Copy.facial_recognition_confirmation_body]:
    'Proces rejestracji twarzy zakończy się po przybyciu na miejsce pracy: skontaktuj się ze swoim punktem kontaktowym na miejscu, aby kontynuować.',
};

const ru: Record<Copy, string> = {
  [Copy.language]: 'Русский',
  [Copy.language_selector_label]: 'Язык формы',
  [Copy.birth_date_label]: 'Дата рождения',
  [Copy.birth_date_mask_text]: 'ММ/ДД/ГГГГ',
  [Copy.birth_date_invalid_value_validation]: 'Введите действительное значение для поля "Дата рождения"',
  [Copy.birth_date_missing_value_validation]: 'Требуется заполнить поле "Дата рождения"',
  [Copy.primary_language_label]: 'Предпочитаемый язык',
  [Copy.field_required_label]: '(Обязательно)',
  [Copy.field_optional_label]: '(Необязательно)',
  [Copy.picklist_mask_general]: 'Выберите...',
  [Copy.thank_you_message]: 'Благодарю вас!',
  [Copy.continue]: 'Продолжить',
  [Copy.skip_for_now]: 'Пропустить сейчас',
  [Copy.save]: 'Сохранить',
  [Copy.cancel]: 'Отменить',
  [Copy.add]: 'Добавлять',
  [Copy.auth_validation_error]: 'Введенная дата рождения не совпадает.',
  [Copy.invalid_image_header]: 'Фотография профиля не принята',
  [Copy.invalid_image_message]:
    'Пожалуйста, сделайте фотографию еще раз: обязательно запечатлейте все свое лицо в центре фотографии.',
  [Copy.facial_recognition_header]: 'Зарегистрируйтесь для распознавания лиц',
  [Copy.auth_validation_instructions]: 'Пожалуйста, введите дату рождения, чтобы подтвердить свою личность.',
  [Copy.auth_validation_body]:
    'Zaproszono Cię do zarejestrowania swojej twarzy, aby uzyskać dostęp do miejsca pracy za pomocą funkcji rozpoznawania twarzy.',
  [Copy.facial_recognition_confirmation_header]: `Поздравляем, все готово!`,
  [Copy.facial_recognition_confirmation_body]:
    'Вы завершите процесс регистрации лица по прибытии на рабочую площадку: для продолжения обратитесь к своему контактному лицу на месте.',
};

const hu: Record<Copy, string> = {
  [Copy.language]: 'Magyar',
  [Copy.language_selector_label]: 'Formanyelv',
  [Copy.birth_date_label]: 'Születési dátum',
  [Copy.birth_date_mask_text]: 'HH/NN/ÉÉÉÉ',
  [Copy.birth_date_invalid_value_validation]: 'Adjon meg egy érvényes dátumot',
  [Copy.birth_date_missing_value_validation]: 'Születési dátum kötelező',
  [Copy.primary_language_label]: 'Preferált nyelv',
  [Copy.field_required_label]: '(Kötelező)',
  [Copy.field_optional_label]: '(Nem kötelező)',
  [Copy.picklist_mask_general]: 'Válassza ki...',
  [Copy.thank_you_message]: 'Köszönöm!',
  [Copy.continue]: 'Folytatni',
  [Copy.skip_for_now]: 'Most hagyd ki',
  [Copy.save]: 'Menteni',
  [Copy.cancel]: 'Megszüntetni',
  [Copy.add]: 'Hozzáadás',
  [Copy.auth_validation_error]: 'A megadott születési dátum nem egyezik.',
  [Copy.invalid_image_header]: 'A profilkép nem fogadható el',
  [Copy.invalid_image_message]:
    'Kérjük, készítse újra a fényképet: ügyeljen arra, hogy a teljes arcát rögzítse a fénykép közepén.',
  [Copy.facial_recognition_header]: 'Regisztráljon az arcfelismerésre',
  [Copy.auth_validation_instructions]: 'Kérjük, adja meg születési dátumát személyazonosságának igazolásához.',
  [Copy.auth_validation_body]:
    'Meghívást kapott, hogy regisztrálja az arcát, hogy arcfelismeréssel hozzáférhessen egy munkaterülethez.',
  [Copy.facial_recognition_confirmation_header]: `Gratulálunk, minden kész!`,
  [Copy.facial_recognition_confirmation_body]:
    'Az arcregisztrációs folyamatot akkor fejezi be, amikor megérkezik a munkahelyre: a folytatáshoz forduljon a helyszíni kapcsolattartóhoz.',
};

export const Languages = {
  en,
  es,
  zhCN,
  zhTW,
  pl,
  ru,
  hu,
};

addTranslations({ ...Languages, en_US: en });
