import React from 'react';
import { getCleaveDateFromISODate } from 'utils/dates';
import { WorkerDocumentsDataType } from 'containers/workerOnboarding/types';
import { FormDefaultValue, FormInput, FormInputTypes, UseFormMethods, UseInputs } from 'components/form';
import { AcknowledgmentProgress } from 'components/acknowledgmentProgress';
import {
  confinedSpacesTrainingDocumentInput,
  generateJobsiteWorkerDocument,
  getFilesData,
  governmentIdDocumentInput,
  lirrBlueTrainingDocumentInput,
  newYorkSstDocumentInput,
  oshaWorkerDocumentInput,
  signatureFormInput,
} from 'containers/workerOnboarding/helpers/forms';
import { DocumentKey } from 'containers/worker/utils';
import { JobsiteWorkerDocumentVersion } from 'apollo/generated/client-operations';
import { cleaveDateValidation } from 'utils/validation';
import { useDidUpdateEffect } from '@odin-labs/components';
import { SelectOptionsElement } from 'components/select';
import { otherCardTypes } from 'utils/constants';

const getDocumentFormInput = (workerDocumentTypeKey: string): FormInput<WorkerDocumentsDataType> => {
  switch (workerDocumentTypeKey) {
    case DocumentKey.OshaCard:
      return oshaWorkerDocumentInput({ areInputsRequired: true });
    case DocumentKey.NycSiteSafetyTrainingCard:
      return newYorkSstDocumentInput({
        // TODO: for now while editing metadata on an existing doc version, always assume they're not exempt
        sstExempt: false,
        sstRequired: true,
        areInputsRequired: true,
      });
    case DocumentKey.GovernmentIssuedId:
      return governmentIdDocumentInput({ areInputsRequired: true });
    case DocumentKey.LIRRBlueTraining:
      return lirrBlueTrainingDocumentInput({ areInputsRequired: true });
    case DocumentKey.ConfinedSpacesTraining:
      return confinedSpacesTrainingDocumentInput({ areInputsRequired: true });
    case DocumentKey.SiteSpecificOrientation:
      return generateJobsiteWorkerDocument({
        documentKey: workerDocumentTypeKey,
        label: 'Site Specific Orientation',
        requiredFields: ['front'],
        children: {
          [`${workerDocumentTypeKey}-orientation-date`]: {
            element: FormInputTypes.Cleave,
            label: 'Date of training',
            elementProps: { cleaveType: 'date', placeholder: 'Date of training' },
            validation: { pattern: cleaveDateValidation, required: true },
            layout: 'col-sm-6',
          },
        },
      });
    case DocumentKey.HudsonYardsEmployeeOrientation:
      return generateJobsiteWorkerDocument({
        documentKey: workerDocumentTypeKey,
        label: 'Hudson Yards Employee Orientation',
      });
    case DocumentKey.Signature:
      return signatureFormInput();
    case DocumentKey.JobsiteSafetyVideo:
      return generateJobsiteWorkerDocument({
        documentKey: DocumentKey.JobsiteSafetyVideo,
        label: 'Worker Safety Video Acknowledgment',
        requiredFields: ['front'],
        children: {
          stickerNumber: undefined,
          [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-status`]: {
            element: FormInputTypes.ToggleField,
            label: 'Worker has watched the video',
            elementProps: {
              toggleDescription: 'Worker completed in self-onboarding',
              disabled: true,
            },
            layout: 'col-sm-5',
          },
          [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-progress-fraction`]: {
            element: FormInputTypes.CustomInput,
            label: 'Acknowledgment progress',
            elementProps: { customInput: AcknowledgmentProgress },
            layout: 'col-sm-4',
          },
          [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-date`]: {
            element: FormInputTypes.Cleave,
            label: 'Acknowledgment date',
            elementProps: {
              placeholder: 'mm/dd/yyyy',
              cleaveType: 'date',
            },
            validation: { pattern: cleaveDateValidation, required: true },
            layout: 'col-sm-3',
          },
        },
      });
    case DocumentKey.JobsiteSafetyDocument:
      return generateJobsiteWorkerDocument({
        documentKey: DocumentKey.JobsiteSafetyDocument,
        label: 'Worker Safety Document Acknowledgment',
        requiredFields: ['front'],
        children: {
          stickerNumber: undefined,
          [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-status`]: {
            element: FormInputTypes.ToggleField,
            label: 'Worker has read the document',
            elementProps: {
              toggleDescription: 'Worker completed in self-onboarding',
              disabled: true,
            },
            layout: 'col-sm-6',
          },
          [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-progress-fraction`]: {
            element: FormInputTypes.CustomInput,
            label: 'Acknowledgment progress',
            elementProps: { customInput: AcknowledgmentProgress },
            layout: 'odin-hidden',
          },
          [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-date`]: {
            element: FormInputTypes.Cleave,
            label: 'Acknowledgment date',
            elementProps: {
              placeholder: 'mm/dd/yyyy',
              cleaveType: 'date',
            },
            validation: { pattern: cleaveDateValidation, required: true },
            layout: 'col-sm-6',
          },
        },
      });
    case DocumentKey.AdditionalCertifications:
      return generateJobsiteWorkerDocument({
        documentKey: workerDocumentTypeKey,
        label: 'Additional Certification',
        requiredFields: ['front'],
      });
    case DocumentKey.Generic:
      return generateJobsiteWorkerDocument({
        documentKey: workerDocumentTypeKey,
        label: 'Additional document',
        options: otherCardTypes.map((otherCardType) => ({ name: otherCardType, value: otherCardType })),
        hasBack: true,
        requiredFields: ['front'],
      });
    default:
      return generateJobsiteWorkerDocument({
        documentKey: workerDocumentTypeKey,
        label: 'Additional document',
        requiredFields: ['front'],
        hasBack: true,
        children: {
          [`${workerDocumentTypeKey}-issue-date`]: {
            element: FormInputTypes.Cleave,
            label: 'Issue date',
            elementProps: { cleaveType: 'date', placeholder: 'Issue date' },
            validation: { pattern: cleaveDateValidation, required: true },
            layout: 'col-sm-6',
          },
          [`${workerDocumentTypeKey}-expiration-date`]: {
            element: FormInputTypes.Cleave,
            label: 'Expiration date',
            elementProps: { cleaveType: 'date', placeholder: 'Expiration date' },
            validation: { pattern: cleaveDateValidation, required: true },
            layout: 'col-sm-6',
          },
        },
      });
  }
};

const useAcknowledgmentProgressUpdate = (
  documentKey: DocumentKey,
  form: UseFormMethods<WorkerDocumentsDataType>,
): void => {
  const { watch, setValue } = form;
  const frontFile = watch(`${documentKey}-front`);

  useDidUpdateEffect(() => {
    const value = frontFile ? 1 : 0;
    setValue(`${documentKey}-acknowledgment-progress-fraction`, value.toString());
  }, [frontFile]);
};

type GetFormInputsArgs = {
  workerDocumentTypeKey: string;
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<WorkerDocumentsDataType> =>
  (form: UseFormMethods<WorkerDocumentsDataType>): FormInput<WorkerDocumentsDataType>[] => {
    const { workerDocumentTypeKey } = args;

    useAcknowledgmentProgressUpdate(DocumentKey.JobsiteSafetyVideo, form);
    useAcknowledgmentProgressUpdate(DocumentKey.JobsiteSafetyDocument, form);

    return React.useMemo(() => {
      const documentFormInput = getDocumentFormInput(workerDocumentTypeKey);
      return [documentFormInput];
    }, [workerDocumentTypeKey]);
  };

const getInitialDefaultValues = (workerDocumentTypeKey: string): Record<string, unknown> => {
  switch (workerDocumentTypeKey) {
    case DocumentKey.NycSiteSafetyTrainingCard:
      return {
        [`${DocumentKey.NycSiteSafetyTrainingCard}-exemption-reason`]: '',
        [`${DocumentKey.NycSiteSafetyTrainingCard}-is-training-connect-card`]: false,
        [`${DocumentKey.NycSiteSafetyTrainingCard}-type`]: null as SelectOptionsElement,
        [`${DocumentKey.NycSiteSafetyTrainingCard}-issue-date`]: '',
        [`${DocumentKey.NycSiteSafetyTrainingCard}-expiration-date`]: '',
      };

    case DocumentKey.OshaCard:
      return {
        [`${DocumentKey.OshaCard}-type`]: null as SelectOptionsElement,
        [`${DocumentKey.OshaCard}-issue-date`]: '',
        [`${DocumentKey.OshaCard}-number`]: '',
      };

    case DocumentKey.GovernmentIssuedId:
      return {
        [`${DocumentKey.GovernmentIssuedId}-type`]: null as SelectOptionsElement,
        [`${DocumentKey.GovernmentIssuedId}-state-issued`]: '',
        [`${DocumentKey.GovernmentIssuedId}-number`]: '',
        [`${DocumentKey.GovernmentIssuedId}-issue-date`]: '',
        [`${DocumentKey.GovernmentIssuedId}-expiration-date`]: '',
      };

    case DocumentKey.LIRRBlueTraining:
      return {
        [`${DocumentKey.LIRRBlueTraining}-expiration-date`]: '',
      };

    case DocumentKey.ConfinedSpacesTraining:
      return {
        [`${DocumentKey.ConfinedSpacesTraining}-expiration-date`]: '',
      };

    default:
      return {};
  }
};

export const getDefaultValues = (
  workerDocumentTypeKey: string,
  jobsiteWorkerDocumentVersion: JobsiteWorkerDocumentVersion,
): FormDefaultValue<WorkerDocumentsDataType>[] => {
  const { files, additionalFieldValues } = jobsiteWorkerDocumentVersion ?? {};
  const initialValues = Object.entries(getInitialDefaultValues(workerDocumentTypeKey)).map(([name, value]) => ({
    name,
    value,
  }));
  return [
    ...initialValues,
    ...getFilesData(workerDocumentTypeKey, files),
    ...(additionalFieldValues?.map((additionalField) => ({
      name: `${workerDocumentTypeKey}-${additionalField?.key}`,
      value: additionalField?.key?.includes('date')
        ? getCleaveDateFromISODate(additionalField?.value)
        : additionalField?.value,
    })) ?? []),
  ];
};

type GetDocumentTypeNameArgs = {
  workerDocumentTypeKey: string;
  workerDocumentTypeName: string;
};

export const getDocumentTypeName = (args: GetDocumentTypeNameArgs): string => {
  const { workerDocumentTypeKey, workerDocumentTypeName } = args;

  if (workerDocumentTypeName) {
    return workerDocumentTypeName;
  }

  const input = getDocumentFormInput(workerDocumentTypeKey);
  return input?.element !== FormInputTypes.Section && input?.element !== FormInputTypes.Panel
    ? input?.elementProps?.label ?? input?.label
    : '';
};
