import React, { ReactElement } from 'react';
import { classes } from './StepInfoTitle.style';

export type StepInfoTitleProps = {
  title?: string;
  subtitle?: string;
  withBottomBorder?: boolean;
  hideOnMobile?: boolean;
};

export function StepInfoTitle(props: StepInfoTitleProps): ReactElement {
  const { title, subtitle, withBottomBorder, hideOnMobile } = props;

  return (
    <div className={classes.container(withBottomBorder, hideOnMobile)}>
      <div className="odin-flex odin-justify-between sm:odin-block sm:odin-space-y-2">
        <div className="odin-space-y-2">
          <h2 className={classes.title}>{title}</h2>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
      </div>
    </div>
  );
}
