import cn from 'classnames';

export const navbarStyles = {
  header: cn(
    'odin-focus:outline-none',
    'odin-sticky',
    'odin-top-0',
    'odin-z-10',
    'odin-px-4 sm:odin-px-6 md:odin-px-8',
    'odin-bg-odin-primary md:odin-bg-white',
    'odin-flex odin-gap-x-4',
    'odin-h-16 odin-shrink-0',
  ),
  mobileMenuButton: cn('odin-flex odin-items-center', 'focus:odin-outline-none', 'md:odin-hidden'),
  mobileMenuText: cn('odin-sr-only'),
  mobileMenuIcon: cn('odin-text-xl odin-font-light odin-text-white'),
  bodyContainer: cn('odin-flex odin-grow odin-items-center odin-justify-between'),
  actionsContainer: cn('odin-flex odin-items-center odin-gap-x-3 odin-ml-4'),
  settingsIcon: cn('odin-text-white md:odin-text-odin-primary'),
  searchLabel: cn('odin-sr-only'),
  searchContainer: cn('odin-relative', 'odin-w-full', 'odin-text-gray-400', 'focus-within:odin-text-gray-600'),
  searchIconContainer: cn(
    'odin-absolute',
    'odin-inset-y-0',
    'odin-left-0',
    'odin-flex',
    'odin-items-center',
    'odin-pointer-events-none',
  ),
  searchIcon: cn('odin-h-5', 'odin-w-5'),
  searchInput: cn(
    'odin-block',
    'odin-w-full',
    'odin-h-full',
    'odin-pl-8',
    'odin-pr-3',
    'odin-py-2',
    'odin-border-transparent',
    'odin-text-gray-900',
    'odin-placeholder-gray-500',
    'focus:odin-outline-none',
    'focus:odin-placeholder-gray-400',
    'focus:odin-ring-0',
    'focus:odin-border-transparent',
    'sm:odin-text-sm',
  ),
};
