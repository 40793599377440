import React from 'react';
import { SelectOptionElement } from '@odin-labs/components';
import {
  Contractor,
  JobsiteContractor,
  useGetJobsiteAndContractorsQuery,
  useGetJobsiteFormsQuery,
} from 'apollo/generated/client-operations';
import { JobsiteFormOptionElement } from './types';

export const useJobsiteFormsOptions = (formKey: string): JobsiteFormOptionElement[] => {
  const { data: jobsiteForms, loading } = useGetJobsiteFormsQuery({
    fetchPolicy: 'no-cache',
    skip: !formKey,
    variables: { jobsiteFormsInput: { formKey } },
  });

  return React.useMemo(() => {
    if (loading) return [];
    return jobsiteForms?.getCurrentSession.user.jobsiteForms.edges.map<JobsiteFormOptionElement>(
      ({ node: { id, jobsite } }) => ({
        value: id,
        label: jobsite.name,
        jobsiteId: jobsite.jobsiteId,
        timeZone: jobsite.timeZone,
      }),
    );
  }, [jobsiteForms]);
};

export type JobsiteContractorForOption = Pick<JobsiteContractor, 'id'> & {
  contractor: Pick<Contractor, 'contractorId' | 'isDefaultContractor'> & {
    organization: Pick<Contractor['organization'], 'name'>;
  };
};

export const getJobsiteContractorsOptions = (jobsiteContractors: JobsiteContractorForOption[]): SelectOptionElement[] =>
  jobsiteContractors
    ?.map(
      ({
        id: value,
        contractor: {
          organization: { name: label },
          isDefaultContractor,
        },
      }) => ({ label, value, isDefaultContractor }),
    )
    .filter(({ isDefaultContractor }) => !isDefaultContractor)
    .sort((a, b) => a.label.localeCompare(b.label));

export const useJobsiteContractorsOptions = (args: { jobsiteId: string; skip?: boolean }): SelectOptionElement[] => {
  const { jobsiteId, skip } = args;
  const { data: jobsiteContractors, loading } = useGetJobsiteAndContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !!skip || !jobsiteId,
    variables: { jobsiteId },
  });

  return React.useMemo(() => {
    if (loading) return [];
    const selectedJobsiteContractors = jobsiteContractors?.getJobsite.jobsiteContractors.edges.map(({ node }) => node);
    return getJobsiteContractorsOptions(selectedJobsiteContractors);
  }, [jobsiteContractors]);
};
