import React from 'react';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { getFaIcon } from '@odin-labs/components';
import { Localize } from 'utils/localization';
import { Copy as FacialRecognitionCopy } from 'containers/facialRecognition/helpers/languages';

const ExclamationTriangleIcon = getFaIcon({ icon: faExclamationTriangle });

type InvalidPhotoAlertProps = {
  localize: Localize;
};

export function InvalidPhotoAlert(props: InvalidPhotoAlertProps): React.ReactElement {
  const { localize } = props;
  return (
    <div className="odin-mt-4 sm:odin-col-span-3">
      <div className="odin-p-6 odin-rounded-xl odin-bg-odin-light-red odin-text-center">
        <ExclamationTriangleIcon className="odin-text-3xl odin-text-odin-danger" />
        <h2 className="odin-my-3 odin-text-1.5xl odin-text-gray-900 odin-font-medium">
          {localize(FacialRecognitionCopy.invalid_image_header)}
        </h2>
        <div className="odin-text-sm odin-text-gray-600">{localize(FacialRecognitionCopy.invalid_image_message)}</div>
      </div>
    </div>
  );
}
