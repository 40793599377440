import { useQuery } from '@apollo/client';
import { FileType, JobsiteWorkerDocument, QueryGetWorkerDocumentsArgs } from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { LoadingError } from 'components/loadingError';
import { GET_WORKER_DOCUMENTS } from 'containers/worker/helpers/queries';
import React, { ReactElement } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import noSurveysImage from 'images/checklist.png';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { NoResults } from 'components/noResults';
import moment from 'moment';
import { GetWorkerDocumentsResponse, WorkerTabProps } from 'containers/print/types';
import { DocumentKey } from 'containers/worker/utils';
import { PdfPreview } from 'components/thumbnail/PdfPreview';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { kebabToTitleCase } from 'utils';

export function WorkerDocuments({ worker }: WorkerTabProps): ReactElement {
  const {
    data: workerDocumentsData,
    error: workerDocumentsError,
    loading: workerDocumentsLoading,
  } = useQuery<GetWorkerDocumentsResponse, QueryGetWorkerDocumentsArgs>(GET_WORKER_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      workerId: worker?.workerId,
      includePhi: false,
    },
  });

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const { workerDocuments } = workerDocumentsData?.getWorkerDocuments ?? {};
  const filteredDocuments = React.useMemo(() => {
    const jobsiteIds = jobsites?.map((j) => j.jobsiteId);
    return workerDocuments?.filter((jobsiteDocument) => {
      const { workerDocumentType, jobsite } = jobsiteDocument?.jobsiteWorkerDocumentType ?? {};
      const { jobsiteId } = jobsite ?? {};
      const workerDocumentTypeKey = workerDocumentType?.key;
      return (
        !workerDocumentTypeKey?.includes('medical') &&
        !workerDocumentTypeKey?.includes(DocumentKey.Signature) &&
        (workerDocumentType?.isGlobal || jobsiteIds?.includes(jobsiteId))
      );
    });
  }, [workerDocuments, jobsites]);

  const getDocumentName = (jobsiteDocument: JobsiteWorkerDocument): string => {
    const { key, name } = jobsiteDocument?.jobsiteWorkerDocumentType?.workerDocumentType ?? {};
    return key === DocumentKey.Generic ? 'Other' : name;
  };

  const error = workerDocumentsError ?? userJobsitesError;
  const loading = workerDocumentsLoading || userJobsitesLoading;

  if (error || loading) {
    return <LoadingError loading={loading} error={error} />;
  }

  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardHeader>
            <h3 className="card-header-title">Documents</h3>
          </CardHeader>
          <CardBody>
            {filteredDocuments?.length > 0 ? (
              <ul className="list-group list-group-lg list-group-flush list my-n4">
                {filteredDocuments?.map((jobsiteDocument: JobsiteWorkerDocument): ReactElement => {
                  const jWDocumentVersionCount = jobsiteDocument?.jobsiteWorkerDocumentVersions.length;
                  const jwDocumentLatestVersion =
                    jobsiteDocument?.jobsiteWorkerDocumentVersions[jWDocumentVersionCount - 1];
                  return (
                    <li className="list-group-item" key={jobsiteDocument?.jobsiteWorkerDocumentId}>
                      <div className="row">
                        <div className="col">
                          <h3 className="mb-1 name">{getDocumentName(jobsiteDocument)}</h3>
                          <div className="d-flex mb-1">
                            {jobsiteDocument?.jobsiteWorkerDocumentType?.jobsite?.name ? (
                              <p className="mb-0 mr-4">
                                {jobsiteDocument?.jobsiteWorkerDocumentType?.workerDocumentType?.isGlobal
                                  ? 'Global'
                                  : `
                                  Jobsite 
                                  ${jobsiteDocument?.jobsiteWorkerDocumentType?.jobsite?.name}
                                  `}
                              </p>
                            ) : null}
                            {jwDocumentLatestVersion && (
                              <p className="mb-0">
                                Uploaded on:&nbsp;
                                {getISODateTimeToFormattedDate(
                                  convertUTCtoLocalDate(jwDocumentLatestVersion.objectHistory.createdAt),
                                )}
                              </p>
                            )}
                          </div>
                          <div className="d-flex mb-1">
                            {jwDocumentLatestVersion?.additionalFieldValues?.map((additionalField) => {
                              return (
                                <p className="mb-0 mr-4" key={additionalField.key}>
                                  {kebabToTitleCase(additionalField.key)}
                                  :&nbsp;
                                  {Date.parse(additionalField.value) &&
                                  moment(additionalField.value, moment.ISO_8601, true).isValid()
                                    ? getISODateTimeToFormattedDate(additionalField.value)
                                    : additionalField.value}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        {jwDocumentLatestVersion.files?.map((document: ServerFile) => {
                          return document.fileType === FileType.Pdf ? (
                            <PdfPreview
                              key={document.fileId}
                              fileURL={document.downloadUrl}
                              alt={document.originalFileName}
                              className="col-12"
                            />
                          ) : (
                            <a
                              href={document?.downloadUrl}
                              target="_blank"
                              download
                              className="col-6"
                              rel="noreferrer"
                              key={document?.downloadUrl}
                            >
                              <img
                                src={document?.downloadUrl || noSurveysImage}
                                alt={document?.downloadUrl}
                                className="w-100 rounded"
                              />
                            </a>
                          );
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <NoResults message="No worker documents to show" image={noSurveysImage} />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
