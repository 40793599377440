import React, { ReactElement } from 'react';
import classNames from 'classnames/bind';

import logo from 'images/odin-logo.svg';
import logoDemo from 'images/odin-logo-demo.svg';
import logoStg from 'images/odin-logo-stg.svg';
import logoDev from 'images/odin-logo-dev.svg';
import logoLocal from 'images/odin-logo-local.svg';
import logoQa from 'images/odin-logo-qa.svg';
import whiteLogo from 'images/odin-logo-white.svg';

import { getCurrentDomain } from 'utils';
import { LogoProps } from './types';

import * as styles from './scss/logo.scss';

const cx = classNames.bind(styles);

export const Logo = React.forwardRef(
  (
    { white, large, medium, small, style = {}, className = {} }: LogoProps,
    ref: React.ForwardedRef<HTMLImageElement>,
  ): ReactElement => {
    const domain = getCurrentDomain();

    const getLogoImage = (): string => {
      if (domain.includes('.demo.')) return logoDemo;
      if (domain.includes('.stg.')) return logoStg;
      if (domain.includes('.qa.')) return logoQa;
      if (domain.includes('.dev.')) return logoDev;
      if (domain.includes('.local.') || domain.includes('localhost:')) return logoLocal;
      return logo;
    };

    return (
      <img
        ref={ref}
        className={cx(
          'logo',
          {
            'logo--large': large,
            'logo--medium': medium,
            'logo--small': small,
          },
          className,
        )}
        src={white ? whiteLogo : getLogoImage()}
        alt="ODIN Logo"
        style={style}
      />
    );
  },
);
