import React from 'react';
import { CheckListOption, SelectOptionElement } from '@odin-labs/components';
import {
  SelfOnboardingStepKey,
  JobsitePreOnboardingField,
  GetJobsiteInvitationQuery,
  GetSelfOnboardingContainerSessionQuery,
  StateAbbreviation,
  Worker,
  useGetSelfOnboardingContainerSessionQuery,
  WorkerCitizenshipStatus,
  WorkerErrorInfo,
} from 'apollo/generated/client-operations';
import { Languages } from 'containers/selfOnboarding/helpers/languages';
import { DocumentKey } from 'containers/worker/utils';
import { Localize } from 'utils/localization';
import { AcknowledgmentProgress } from 'types';

export { SelfOnboardingStepKey };

export type JobsiteInvitation = GetJobsiteInvitationQuery['getJobsiteInvitation'];
export type Jobsite = JobsiteInvitation['jobsiteContractor']['jobsite'];
export type JobsiteModule = Jobsite['modules'][number];
export type DocumentType = Jobsite['documentTypes']['edges'][number]['node'];
export type AcknowledgmentFile = DocumentType['files'][number];
export type JobsiteFeaturesModule = Extract<JobsiteModule, { __typename?: 'JobsiteFeaturesModule' }>;
export type JobsiteOnboardingModule = Extract<JobsiteModule, { __typename?: 'JobsiteOnboardingModule' }>;
export type JobsitePreOnboardingModule = Extract<JobsiteModule, { __typename?: 'JobsitePreOnboardingModule' }>;
export type JobsiteOnboardingDocument = JobsiteOnboardingModule['documents'][number];
export type JobsiteOnboardingStep = JobsiteOnboardingModule['steps'][number];

export type SelfOnboardingUser = GetSelfOnboardingContainerSessionQuery['getCurrentSession']['user'];
export type SelfOnboardingWorker = SelfOnboardingUser['worker'];
export type SelfOnboardingJobsiteWorker = SelfOnboardingWorker['jobsiteWorkers']['edges'][number]['node'];
export type SelfOnboardingDocument = SelfOnboardingWorker['documents']['edges'][number]['node'];
export type SelfOnboardingServerFile = SelfOnboardingDocument['latestVersion']['files'][number];

export type InputFile = File | SelfOnboardingServerFile;

export enum AcknowledgmentStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Confirmed = 'confirmed',
}

export type SelfOnboardingBasicInfoFormData = {
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  birthDate: string;
  ssnLastFour: string;
  phoneNumber: string;
  email: string;
  workerConsentStatus: AcknowledgmentStatus[];
};

export type SelfOnboardingProfileFormData = {
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: SelectOptionElement<StateAbbreviation>;
  addressZipCode: string;
  primaryLanguage: SelectOptionElement;
  citizenshipStatus: SelectOptionElement<WorkerCitizenshipStatus>;
  race: SelectOptionElement;
  gender: SelectOptionElement;
  isVeteran: SelectOptionElement;
  trade: SelectOptionElement;
  jobTitle: SelectOptionElement;
  unionAffiliation: SelectOptionElement;
};

export type AcknowledgmentOption = CheckListOption<AcknowledgmentStatus>;

export type SelfOnboardingDocumentFormData = Partial<{
  versionId: string;
  key: DocumentKey;
  acknowledgmentProgress: AcknowledgmentProgress;
  acknowledgmentStatus: AcknowledgmentStatus[];
  acknowledgmentDate: Date;
  acknowledgmentFile: AcknowledgmentFile;
  frontFile: InputFile;
  backFile: InputFile;
  type: SelectOptionElement;
  stateIssued: SelectOptionElement;
  number: string;
  issueDate: string;
  expirationDate: string;
  isTrainingConnectCard: boolean;
}>;

export type SelfOnboardingEmergencyContactFormData = {
  emergencyContactName: string;
  emergencyContactRelationship: SelectOptionElement;
  emergencyContactPhone: string;
};

export type SelfOnboardingProfilePhotoFormData = {
  file: InputFile;
};

export type SelfOnboardingVerificationPhoneNumberFormData = {
  verificationPhoneNumber: string;
};

export type SelfOnboardingStateWorker = Pick<
  Worker,
  'workerId' | 'quickCode' | 'editableFields' | 'isAssignedToMultipleJobsites' | 'selfOnboardingCurrentStep'
>;

export type SelfOnboardingState = {
  /** It's used when an existing worker self-onboards on a different jobsite */
  matchedWorker: WorkerErrorInfo;
  worker: SelfOnboardingStateWorker;
  jobsiteWorkerId: string;
  userAccountId: string;
  didWorkerMatch: boolean;
  basicInfo: SelfOnboardingBasicInfoFormData;
  profile: SelfOnboardingProfileFormData;
  emergencyContact: SelfOnboardingEmergencyContactFormData;
  documents: SelfOnboardingDocumentFormData[];
  documentTypes: Record<string, { id: string; acknowledgmentFile: AcknowledgmentFile }>;
  profilePhoto: SelfOnboardingProfilePhotoFormData;
} & SelfOnboardingVerificationPhoneNumberFormData;

export type SelfOnboardingStepConfig = (
  | Omit<JobsiteOnboardingStep, 'fields'>
  | Omit<JobsiteOnboardingDocument, 'fields'>
) & {
  fields: Record<string, JobsitePreOnboardingField>;
};

export interface LanguageOption {
  label: string;
  value: keyof typeof Languages;
}

export type SelfOnboardingStepNumberInfo = {
  stepsCount: number;
  currentStep: number;
};

export type SelfOnboardingLocationState = {
  autoFocusField?: string;
  isReview?: boolean;
};

export interface SelfOnboardingNavigation {
  loading: boolean;
  currentStep: SelfOnboardingStepKey;
  state: SelfOnboardingLocationState;
  goToStep: (step: SelfOnboardingStepKey, options?: { tab?: string; state?: SelfOnboardingLocationState }) => void;
  goToNextStep: () => void;
  canGoToPreviousStep: (step: SelfOnboardingStepKey) => boolean;
  goToPreviousStep: () => void;
  goToLastStep: (tab?: 'workerInfo' | 'documents') => void;
  getStepNumberInfo: () => SelfOnboardingStepNumberInfo;
  getStepIndex: (step: SelfOnboardingStepKey) => number;
  getNextStep: () => SelfOnboardingStepKey;
}

export type SelfOnboardingStepProps = {
  loading: boolean;
  updateState: (data: Partial<SelfOnboardingState>) => void;
  user: SelfOnboardingUser;
  state: SelfOnboardingState;
  refetchData: ReturnType<typeof useGetSelfOnboardingContainerSessionQuery>['refetch'];
  navigation: SelfOnboardingNavigation;
  jobsiteInvitation?: JobsiteInvitation;
  stepConfig: SelfOnboardingStepConfig;
  language: string;
  onLanguageChange: (language: LanguageOption) => void;
  localize: Localize;
};

export type { Localize };

export type SelfOnboardingStepComponent = (props: SelfOnboardingStepProps) => React.ReactElement;

export type ReviewCompletedProfiledTabProps = Pick<
  SelfOnboardingStepProps,
  'state' | 'loading' | 'jobsiteInvitation' | 'navigation' | 'localize'
>;

export interface FormInputArgs {
  document?: SelfOnboardingDocumentFormData;
  jobsiteInvitation?: JobsiteInvitation;
  state?: SelfOnboardingState;
}
