import { useCallback, useState } from 'react';

export type UseResettableStateReturn<T> = {
  value: T;
  setValue: (value: T) => void;
  resetValue: () => void;
};

export type ResettableValue = any;
const DefaultResettingValue: ResettableValue = undefined;

/**
 * The `useResettableState` Hook is used to deal with resettable values,
 * and provides functions to set and reset the value.
 * @param initialValue The value which will be returned during the initial render.
 * @param resettingValue The value to which the current value will get reset.
 * @returns an object containing the following fields:
 * - value: The current value of the boolean
 * - setValue: Sets the current value
 * - resetValue: Resets the current value to the resetting value.
 */
export const useResettableState = <T>(
  initialValue: T = DefaultResettingValue,
  resettingValue: React.SetStateAction<T> = DefaultResettingValue,
): UseResettableStateReturn<T> => {
  const [value, setValue] = useState<T>(initialValue);
  const resetValue = useCallback((): void => setValue(resettingValue), [resettingValue]);

  return { value, setValue, resetValue };
};
