import classNames from 'classnames/bind';
import React from 'react';
import { FormGroup } from 'reactstrap';
import Cleave from 'cleave.js/react';
import { ReactInstanceWithCleave } from 'cleave.js/react/props'; // eslint-disable-line import/no-unresolved
import 'cleave.js/dist/addons/cleave-phone.us';
import moment from 'moment';

import { setRefFactory } from 'components/utils';
import { CleaveInputProps, CleaveOptions, ChangeEvent } from './types';

const ENTER_KEY = 'Enter';

export const CleaveInput = React.forwardRef(
  (props: CleaveInputProps, ref: React.ForwardedRef<HTMLInputElement>): React.ReactElement => {
    const {
      value,
      name,
      label,
      error,
      onChange,
      onValueChange,
      placeholder,
      cleaveType,
      dateFormat = ['m', 'd', 'Y'],
      onSave,
      className,
      disabled,
    } = props;
    const cleaveOptions: CleaveOptions = {};
    if (cleaveType === 'tel') {
      cleaveOptions.phone = true;
      cleaveOptions.phoneRegionCode = 'US';
      cleaveOptions.numericOnly = true;
    }

    if (cleaveType === 'date') {
      cleaveOptions.date = true;
      cleaveOptions.datePattern = dateFormat;
    }

    if (cleaveType === 'birthDate') {
      cleaveOptions.date = true;
      cleaveOptions.datePattern = dateFormat;
      cleaveOptions.dateMax = moment().format('MM/DD/YYYY');
    }

    if (cleaveType === 'initial') {
      cleaveOptions.blocks = [1];
    }

    if (cleaveType === 'numeral') {
      cleaveOptions.numeral = true;
      cleaveOptions.numeralThousandsGroupStyle = 'thousand';
    }

    if (cleaveType === 'number') {
      cleaveOptions.numericOnly = true;
    }

    if (cleaveType === 'zipcode') {
      cleaveOptions.numericOnly = true;
      cleaveOptions.blocks = [5];
    }

    if (cleaveType === 'ssn4') {
      cleaveOptions.numericOnly = true;
      cleaveOptions.blocks = [4];
    }

    if (cleaveType === 'lockCode') {
      cleaveOptions.numericOnly = true;
      cleaveOptions.delimiter = '-';
      cleaveOptions.blocks = [3, 3];
    }

    const cleaveRef = React.useRef<ReactInstanceWithCleave>(null);
    const setRef = setRefFactory<HTMLInputElement>({ outerRef: ref });

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        const targetRawValue = event.target.rawValue;
        let newValue: string;

        // check if any transformations are needed
        if (cleaveType === 'tel') {
          if (targetRawValue?.startsWith('1')) {
            newValue = `+${targetRawValue}`;
          } else if (targetRawValue?.startsWith('+') && !targetRawValue.startsWith('+1')) {
            newValue = targetRawValue.substring(1);
          } else if (targetRawValue?.startsWith('0')) {
            newValue = targetRawValue.replace(/\D|^0+/g, '');
          }
        }

        if (newValue !== undefined) {
          // new value is needed - onChange will be called with original the new transformed value
          cleaveRef.current.setRawValue(newValue);
          onChange(newValue);
        } else {
          // no transformation was needed - onChange will be called with original rawValue
          onChange(targetRawValue);
        }
      }

      onValueChange?.(event.target.value);
    };

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === ENTER_KEY && onSave) {
        onSave(value);
      }
    };

    return (
      <FormGroup className={classNames({ [className]: Boolean(className) })}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        <Cleave
          name={name}
          value={value}
          id={name}
          disabled={disabled}
          htmlRef={setRef}
          onInit={(cleave: ReactInstanceWithCleave): void => {
            cleaveRef.current = cleave;
          }}
          onKeyDown={onKeyDownHandler}
          onChange={onChangeHandler}
          options={cleaveOptions}
          placeholder={placeholder}
          className={classNames('form-control', { 'is-invalid': error })}
        />
        {error ? (
          <div className={classNames('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div>
        ) : null}
      </FormGroup>
    );
  },
);
