import React from 'react';
import { LoadingError } from 'components/loadingError';
import { useIsMounted } from 'utils';
import { useJobsiteUpdateFormSubmissionMutation } from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { CustomFormInputs, EditFormData, FormSubmissionTabProps } from 'containers/jobsiteFormSubmission/types';
import { Form, FormOnSubmit } from 'components/form';
import { AuthContext } from 'auth';
import { getGraphQLError } from 'utils/error';
import { useDependencies, useAvailableJobsiteWorkerOptions, useDynCtx } from 'containers/jobsiteFormSubmission/utils';
import { useJobsiteFormsOptions } from 'containers/forms/modals/utils';
import {
  getContextSelectOptions,
  getDefaultValues,
  getEvalContext,
  getFormInputsHook,
  getJobsiteFormSubmissionUpdateInput,
} from './FormSubmissionEdit.forms';

export const FormSubmissionEdit = React.forwardRef<HTMLFormElement, FormSubmissionTabProps>(
  (props, ref): React.ReactElement => {
    const {
      jobsiteFormSubmission,
      loading,
      onIsDirtyChange,
      refetchJobsiteFormSubmission,
      isSaving,
      setIsSaving,
      setTabsDefinition,
      deferredSubmission,
    } = props;
    const isMounted = useIsMounted();

    const { currentUser: user } = React.useContext(AuthContext);

    const [jobsiteUpdateFormSubmission] = useJobsiteUpdateFormSubmissionMutation({ fetchPolicy: 'no-cache' });

    const formContent = jobsiteFormSubmission?.jobsiteForm.form.content;
    const {
      inputs: inputsExpression,
      defaultValues: defaultValuesExpression,
      updateInputs: updateInputsExpression,
      dependencies: dependenciesExpressions,
      extraDataFields,
    } = formContent?.edit ?? {};

    const { availableJobsiteWorkerOptions } = useAvailableJobsiteWorkerOptions({
      jobsiteFormSubmission,
      skip:
        !(inputsExpression as string)?.includes('ctx.options.availableJobsiteWorkers') &&
        !(defaultValuesExpression as string)?.includes('ctx.options.availableJobsiteWorkers'),
    });

    const dependencies = useDependencies(dependenciesExpressions);
    const dynCtx = useDynCtx(formContent);
    const jobsiteFormsOptions = useJobsiteFormsOptions(jobsiteFormSubmission?.jobsiteForm.form.key);

    const evalContext = React.useMemo(() => {
      return (
        dependencies &&
        getEvalContext({
          dependencies,
          dynCtx,
          user,
          jobsiteFormSubmission,
          options: getContextSelectOptions({
            jobsiteForms: jobsiteFormsOptions,
            availableJobsiteWorkers: availableJobsiteWorkerOptions,
          }),
        })
      );
    }, [dependencies, dynCtx, user, jobsiteFormSubmission, jobsiteFormsOptions, availableJobsiteWorkerOptions]);

    const defaultValues = React.useMemo(() => {
      return getDefaultValues({ jobsiteFormSubmission, evalContext });
    }, [jobsiteFormSubmission, evalContext]);

    const inputs = getFormInputsHook({
      jobsiteFormSubmission,
      defaultValues,
      evalContext,
      setTabsDefinition,
    });

    const onSubmit: FormOnSubmit<CustomFormInputs<EditFormData>> = async (data, event, dirtyFields): Promise<void> => {
      if (isSaving) {
        AlertInstance.alert('tc', 'warning', 'Saving in progress!', '');
        return;
      }
      setIsSaving(true);

      try {
        const input = await getJobsiteFormSubmissionUpdateInput({
          jobsiteFormSubmission,
          evalContext,
          updateInputsExpression,
          extraDataFields,
          defaultValues,
          data,
          dirtyFields,
        });
        await jobsiteUpdateFormSubmission({ variables: { input } });

        AlertInstance.alert('tc', 'success', 'Success', 'Form submission successfully updated');
        if (isMounted()) {
          refetchJobsiteFormSubmission();
        }

        deferredSubmission.resolve(true);
      } catch (error) {
        event.preventDefault();
        deferredSubmission.reject(error);
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      } finally {
        if (isMounted()) setIsSaving(false);
      }
    };

    if (loading || !inputs || !defaultValues) {
      return <LoadingError loading />;
    }

    return (
      <Form
        ref={ref}
        inputs={inputs}
        onSubmit={onSubmit}
        onIsDirtyChange={onIsDirtyChange}
        defaultValues={defaultValues}
        className="odin-pr-3"
        inputsContainerClassName="odin-space-y-5 sm:odin-space-y-9 odin-divide-y odin-divide-gray-200 odin-pb-9"
      />
    );
  },
);
