import React from 'react';
import cn from 'classnames';
import { faMoonStars } from '@fortawesome/pro-solid-svg-icons';
import { BadgeColor, getFaIcon } from '@odin-labs/components';

import {
  JobsiteAccessEvent,
  JobsiteAccessEventStatus,
  JobsiteWorkerCheckInLocationStatus,
} from 'apollo/generated/client-operations';

export const statusText: Record<JobsiteAccessEventStatus, string> = {
  Admit: 'Admitted',
  Reject: 'Rejected',
  archived: 'Archived',
};
export const statusColor: Record<JobsiteAccessEventStatus, BadgeColor> = {
  Admit: 'green',
  Reject: 'red',
  archived: 'gray',
};

export const locationStatusText: Record<JobsiteWorkerCheckInLocationStatus, string> = {
  LocationNotAvailable: 'Location Not Available',
  Nearby: 'Nearby',
  Offsite: 'Offsite',
  Onsite: 'Onsite',
};

export const locationStatusColor: Record<JobsiteWorkerCheckInLocationStatus, BadgeColor> = {
  LocationNotAvailable: 'gray',
  Nearby: 'yellow',
  Offsite: 'red',
  Onsite: 'green',
};

const directionIcon: Record<string, string> = {
  inbound: cn('fal fa-arrow-to-right odin-text-odin-primary'),
  outbound: cn('fal fa-arrow-to-left odin-text-gray-600'),
};

const classes = {
  defaultText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  jobsite: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-medium'),
  date: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  supportingText: cn('odin-text-gray-600 odin-text-xs odin-leading-4 odin-font-normal'),
  directionIcon: (direction: string): string => cn(directionIcon[direction], 'odin-text-base odin-font-light'),
  directionGatewayText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal odin-ml-1.5'),
};

export type DateTimeCellData = {
  date: Date;
  timeZoneName?: string;
  isOvernight?: boolean;
};

export type SessionDateCellData = {
  date: string;
  isOvernight?: boolean;
};

export function JobsiteCell({ value }: { value: string }): React.ReactElement {
  return <div className={classes.jobsite}>{value}</div>;
}

const OvernightIcon = getFaIcon({ icon: faMoonStars, title: 'Overnight session' });
export function DateTimeCell({ value }: { value: DateTimeCellData }): React.ReactElement {
  return (
    <div className="odin-flex odin-items-baseline odin-gap-x-1 md:odin-block">
      <div className={classes.date}>
        {value?.date?.toLocaleDateString()}
        {value?.isOvernight && <OvernightIcon className="odin-ml-3 odin-text-odin-primary" />}
      </div>
      <div className={classes.supportingText}>
        {value?.date?.toLocaleTimeString()} {value?.timeZoneName}
      </div>
    </div>
  );
}
export function SessionDateCell({ value }: { value: SessionDateCellData }): React.ReactElement {
  return (
    <div className={classes.date}>
      {value?.date}
      {value?.isOvernight && <OvernightIcon className="odin-ml-3 odin-text-odin-primary" />}
    </div>
  );
}

export function DirectionGatewayCell({ value }: { value: JobsiteAccessEvent }): React.ReactElement {
  return (
    <div>
      <i className={classes.directionIcon(value.direction)} />
      <span className={classes.directionGatewayText}>{value.gateway}</span>
    </div>
  );
}

export function JobsiteAccessDefaultCell({ value }: { value: string }): React.ReactElement {
  return <span className={classes.defaultText}>{value}</span>;
}
