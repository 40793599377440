import { InfoRowProps } from 'components/infoRow/types';
import { AcknowledgmentStatus, Localize, SelfOnboardingDocumentFormData } from 'containers/selfOnboarding/types';
import {
  SelfOnboardingDocumentVisibleData,
  getDocumentInputsConfig,
} from 'containers/selfOnboarding/steps/documentStep';
import { camelToTitleCase } from 'utils';

type DocumentInfoField = keyof Required<
  Omit<SelfOnboardingDocumentVisibleData, 'frontFile' | 'backFile' | 'acknowledgmentProgress'>
>;
export type DocumentInfoRowConfig = Omit<InfoRowProps, 'onEditClick' | 'allowEdit'>;

export function getInfoRows(document: SelfOnboardingDocumentFormData, localize: Localize): DocumentInfoRowConfig[] {
  const { type, stateIssued, number, issueDate, expirationDate, isTrainingConnectCard, acknowledgmentStatus } =
    document;
  const { visibleKeys, typeCopy } = getDocumentInputsConfig(document.key);

  const documentFields: Record<DocumentInfoField, DocumentInfoRowConfig> = {
    type: { label: localize(typeCopy), value: type?.label },
    stateIssued: { label: camelToTitleCase('stateIssued'), value: stateIssued?.label },
    number: { label: camelToTitleCase('number'), value: number },
    issueDate: { label: camelToTitleCase('issueDate'), value: issueDate },
    expirationDate: { label: camelToTitleCase('expirationDate'), value: expirationDate },
    isTrainingConnectCard: {
      label: camelToTitleCase('isTrainingConnectCard'),
      value: isTrainingConnectCard ? 'Yes' : 'No',
    },
    acknowledgmentStatus: {
      label: camelToTitleCase('status'),
      value: acknowledgmentStatus?.includes(AcknowledgmentStatus.Confirmed) ? 'Confirmed' : 'Not Confirmed',
    },
  };

  return visibleKeys
    .filter((fieldKey) => fieldKey !== 'frontFile' && fieldKey !== 'backFile')
    .map((fieldKey) => documentFields[fieldKey as DocumentInfoField])
    .filter(Boolean);
}
