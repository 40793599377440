import { FormInputTypes, TypedFormInputs, GridColSpan } from 'components/form';
import {
  toFancySelectOptions,
  emergencyContactRelationshipOptions as emergencyContactRelationshipOptionsValues,
} from 'utils/constants';
import { phoneNumberValidation } from 'utils/validation';
import { SelfOnboardingVerificationPhoneNumberFormData } from 'containers/selfOnboarding/steps/types';

export const emergencyContactRelationshipOptions = toFancySelectOptions(emergencyContactRelationshipOptionsValues);

export const getFormInputs = (): TypedFormInputs<SelfOnboardingVerificationPhoneNumberFormData> => {
  return {
    verificationPhoneNumber: {
      element: FormInputTypes.OdinField,
      elementProps: {
        fieldType: 'phone',
        inputMode: 'numeric',
        showDefaultIcon: true,
      },
      validation: { required: true, pattern: phoneNumberValidation },
      layout: [GridColSpan.SpanFull],
    },
  };
};

export const getDefaultValues = (
  data: SelfOnboardingVerificationPhoneNumberFormData,
): SelfOnboardingVerificationPhoneNumberFormData => {
  const { verificationPhoneNumber } = data ?? {};

  return {
    verificationPhoneNumber: verificationPhoneNumber ?? '',
  };
};
