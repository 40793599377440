import { RoutePage } from 'pages/RoutePage';
import { SelfOnboardingContainer } from 'containers/selfOnboarding';
import { SelfOnboardingRouteProps } from 'routes/types';

export const selfOnboardingRoutes: SelfOnboardingRouteProps[] = [
  {
    component: RoutePage,
    path: '/self-onboarding/:jobsiteInvitationId/:step?/:tab?',
    title: 'Self-onboarding',
    exact: true,
    container: SelfOnboardingContainer,
    meta: [
      {
        name: 'description',
        content: 'Self-onboarding',
      },
    ],
  },
];
