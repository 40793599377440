import React, { ReactElement } from 'react';
import { WorkerChangeTabProps } from 'containers/entityChange/worker/types';

export function WorkerAdminActions(props: WorkerChangeTabProps): ReactElement {
  const { loading, worker, onTabApiChange } = props; // eslint-disable-line @typescript-eslint/no-unused-vars

  // get worker jobsites changes

  // React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  return <div>Worker Admin Actions Changes</div>;
}
