import React from 'react';
import { SelectOptionElement } from '@odin-labs/components';
import { JobsiteWorkerDocumentType } from 'apollo/generated/client-operations';
import { Jobsite, JobsiteWorker } from 'containers/worker/types';
import { useJobsiteWorkerDocumentTypes } from 'containers/workerOnboarding/helpers/useJobsiteWorkerDocumentTypes';

export interface UseSelectDocumentTypeModalDataArgs {
  jobsiteWorkers: JobsiteWorker[];
  documentTypes?: Record<string, string>;
}

export interface SelectDocumentTypeModalState {
  selectedJobsite: SelectOptionElement | null;
  selectedJobsiteError?: string;
  selectedDocumentType: SelectOptionElement | null;
  selectedDocumentTypeError?: string;
}

const emptyState: SelectDocumentTypeModalState = {
  selectedJobsite: null,
  selectedDocumentType: null,
};

export interface SelectDocumentTypeModalData {
  jobsiteOptions: SelectOptionElement[];
  documentTypeOptions: SelectOptionElement[];
  state: SelectDocumentTypeModalState;
  updateState: (upd: Partial<SelectDocumentTypeModalState>) => void;
  resetState: () => void;
  filteredDocumentTypeIds: Record<string, string>;
  jobsiteWorker: JobsiteWorker;
}

export function useSelectDocumentTypeModalData(args: UseSelectDocumentTypeModalDataArgs): SelectDocumentTypeModalData {
  const { jobsiteWorkers, documentTypes } = args;

  const jobsiteOptions: SelectOptionElement[] = React.useMemo(() => {
    const distinctJobsites: Record<string, Jobsite> = jobsiteWorkers
      .filter((jw) => jw.jobsiteContractor?.jobsite)
      .reduce((result, { jobsiteContractor: { jobsite } }) => ({ ...result, [jobsite.jobsiteId]: jobsite }), {});

    return Object.values(distinctJobsites).map(({ name, jobsiteId }) => ({
      label: name,
      value: jobsiteId,
    }));
  }, [jobsiteWorkers]);

  const initialState: SelectDocumentTypeModalState = {
    ...emptyState,
    selectedJobsite: jobsiteOptions?.length === 1 ? jobsiteOptions[0] : null,
  };

  const [state, setState] = React.useState<SelectDocumentTypeModalState>(initialState);

  const updateState = (upd: Partial<SelectDocumentTypeModalState>): void =>
    setState((prevState) => ({
      ...prevState,
      ...upd,
    }));

  const resetState = (): void => setState(initialState);

  const selectedJobsiteId = state.selectedJobsite?.value;
  const jobsiteWorker = React.useMemo(
    () => jobsiteWorkers?.find((jw) => jw?.jobsiteContractor?.jobsite?.jobsiteId === selectedJobsiteId),
    [jobsiteWorkers, selectedJobsiteId],
  );

  const { jobsiteWorkerDocumentTypes } = useJobsiteWorkerDocumentTypes(
    jobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId,
  );

  const filteredDocumentTypeIds: Record<string, string> = React.useMemo(
    () =>
      jobsiteWorkerDocumentTypes.reduce(
        (result, jwDocumentType: JobsiteWorkerDocumentType) => ({
          ...result,
          [jwDocumentType?.workerDocumentType?.key]: jwDocumentType?.id,
        }),
        {},
      ),
    [jobsiteWorkerDocumentTypes],
  );

  const documentTypeOptions: SelectOptionElement[] = React.useMemo(
    () =>
      Object.entries(documentTypes ?? {})
        .filter(([docType]) => filteredDocumentTypeIds[docType])
        .map(([value, label]) => ({ value, label })),
    [documentTypes, filteredDocumentTypeIds],
  );

  return {
    jobsiteOptions,
    documentTypeOptions,
    filteredDocumentTypeIds,
    jobsiteWorker,
    state,
    updateState,
    resetState,
  };
}
