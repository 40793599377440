import React from 'react';
import { ModalProps } from '@odin-labs/components';
import { DialogContext } from 'dialog/dialogContext';
import { DialogId } from './types';

const forwardedDialog = React.forwardRef(
  (props: ModalProps & { id: DialogId }, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { setDialogProps } = React.useContext(DialogContext);
    const deps = [...Object.values(props), ref];

    React.useEffect(() => {
      setDialogProps(props.id, { ...props, ref });
    }, deps);

    return null;
  },
);

type ForwardedDialog = (
  props: ModalProps & { id: DialogId } & {
    ref?: React.ForwardedRef<HTMLDivElement>;
  },
) => ReturnType<typeof forwardedDialog>;

/**
 * This component should be used to display a modal nested form (react-hook-form)
 * (i.e. when needed to edit a form field with another form).
 * If nested directly without using this component, a submission on the child form
 * will trigger submissions on both child and parent forms.
 */
export const Dialog = forwardedDialog as ForwardedDialog;
