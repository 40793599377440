import { CardStatus, WorkerCard, WorkerCardType } from 'apollo/generated/client-operations';
import cn from 'classnames';
import { BadgeChecklist } from 'components/badgeChecklist/BadgeChecklist';
import { FormInputTypes, TypedFormInputs } from 'components/form';
import { DeactivateWorkerBadgesData } from './types';

export const getFormInputs = (): TypedFormInputs<DeactivateWorkerBadgesData> => {
  return {
    badges: {
      element: FormInputTypes.CustomInput,
      elementProps: {
        customInput: BadgeChecklist,
      },
      layout: cn('odin-col-span-6'),
    },
  };
};

export const getFormDefaultValues = (workerCards: WorkerCard[]): DeactivateWorkerBadgesData => {
  return {
    badges: workerCards
      ?.filter(
        ({ cardStatus, workerCardFormat }) =>
          cardStatus === CardStatus.Active && workerCardFormat.cardType === WorkerCardType.Proximity,
      )
      ?.map((workerCard) => ({ workerCard, selected: true })),
  };
};
