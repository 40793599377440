import cn from 'classnames';
import React from 'react';

type IconProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
type Icon = (props: IconProps) => React.ReactElement;

export const getIcon = (iconClassName: string): Icon =>
  function Icon({ className, ...props }: IconProps): React.ReactElement {
    return React.createElement('i', { ...props, className: cn(iconClassName, className) });
  };

export const getMenuItemIcon = (icon: string): Icon => getIcon(cn(icon, 'odin-h-full'));

export const getIconWithAutoHeight = (icon: string): Icon => getIcon(cn(icon, '!odin-h-auto'));
