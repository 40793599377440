import React from 'react';
import type { Localize } from 'utils/localization';
import { Languages } from 'containers/userPendingChange/helpers/languages';
import {
  GetUserPendingChangeQuery,
  UserPendingChangeType as PendingChangeType,
} from 'apollo/generated/client-operations';

export { PendingChangeType };
export type UserPendingChange = GetUserPendingChangeQuery['getUserPendingChange']['change'];

export type UserValidationFormData = {
  birthDate: string;
};

export type PendingChangeInfoFormData = {
  currentPhoneNumber: string;
  currentEmail: string;
  newPhoneNumber: string;
  newEmail: string;
};

export type { Localize } from 'utils/localization';

export interface LanguageOption {
  label: string;
  value: keyof typeof Languages;
}

export enum UserPendingChangeStep {
  PendingChangeInfo = 'pending-change-info',
  UserValidation = 'user-validation',
  PendingChangeConfirmation = 'pending-change-confirmation',
  NoPendingChange = 'no-pending-change',
  Error = 'error',
}

export type UserPendingChangeStepProps = {
  workerId: string;
  changeType: PendingChangeType;
  change: UserPendingChange;
  language: string;
  onLanguageChange: (language: LanguageOption) => void;
  localize: Localize;
  setCurrentStep: (step: UserPendingChangeStep) => void;
  openCancelUserChangeModal: () => void;
};

export type UserPendingChangeStepComponent = (props: UserPendingChangeStepProps) => React.ReactElement;
