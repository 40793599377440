import React from 'react';

export type UseModalStateReturn<T> = {
  value: T;
  isModalOpen: boolean;
  openModal: (value: T) => void;
  closeModal: () => void;
  resetModalValue: () => void;
};

export type ResettableValue = any;
const DefaultResettingValue: ResettableValue = undefined;

/**
 * The `useModalState` Hook provides state and functions to reset state for a modal.
 * It's useful when a value needs to be passed when opening a modal (i.e. when editing an entity).
 * @param initialValue The value which will be returned during the initial render.
 * @returns an object containing the following fields:
 * - value: The current value (edited entity) within the modal.
 * - isModalOpen: Boolean specifying if the modal should be open.
 * - openModal: Function to open the modal and passes an value (entity) as parameter.
 * - closeModal: Sets `isModalOpen` to `false`. The value (entity) is not reset.
 * - resetModalValue: Sets `isModalOpen` to `false`. The value (entity) is not reset.
 */
export const useModalState = <T>(initialValue: T = DefaultResettingValue): UseModalStateReturn<T> => {
  const [{ value, isModalOpen }, setValue] = React.useState<{ value: T; isModalOpen: boolean }>({
    value: initialValue,
    isModalOpen: false,
  });

  return {
    value,
    isModalOpen,
    openModal: (updValue: T): void => setValue({ value: updValue, isModalOpen: true }),
    closeModal: (): void => setValue((state) => ({ ...state, isModalOpen: false })),
    resetModalValue: (): void => setValue({ value: initialValue, isModalOpen: false }),
  };
};
