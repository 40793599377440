import React, { ReactElement } from 'react';
import classNames from 'classnames/bind';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';

import { BaseModal } from './BaseModal';
import { WorkersFoundModalProps } from './types';

import * as styles from './scss/workers-found-modal.scss';

const cx = classNames.bind(styles);

export function WorkersFoundModal({
  isOpen = false,
  workers,
  subtitle,
  setWorkerMatch,
  workerMatch,
  cancelText,
  actionText,
  toggle,
  onCancel,
  onAction,
}: WorkersFoundModalProps): ReactElement {
  return (
    <BaseModal
      onCancel={onCancel}
      onAction={onAction}
      isOpen={isOpen}
      toggle={toggle}
      cancelText={cancelText || 'Create new worker'}
      actionText={actionText || 'View worker profile'}
      actionButtonEnabled={!!workerMatch}
    >
      <>
        <h2 className="font-weight-bold mb-3 text-center">This person may already have a profile in ODIN!</h2>
        {subtitle ? <p className="text-center">{subtitle}</p> : null}
        <div className="row">
          {workers.filter(Boolean).map((worker) => {
            return (
              <div
                key={worker.workerId}
                onClick={(): void => {
                  if (worker.workerId === workerMatch?.workerId) {
                    setWorkerMatch(null);
                  } else {
                    setWorkerMatch(worker);
                  }
                }}
                className="p-3 col-6"
              >
                {worker.workerId === workerMatch?.workerId ? (
                  <div className={cx('workers__found__checkbox')}>
                    <input className="custom-control-input" type="checkbox" checked />
                  </div>
                ) : null}
                <div className="card workers__found text-center">
                  <div className="card-body">
                    <h3 className="card-title">
                      {worker.firstName} {worker.lastName}
                    </h3>
                    <p>
                      {worker.email}
                      <br />
                      {getISODateTimeToFormattedDate(convertUTCtoLocalDate(worker.birthDate))}
                    </p>
                    <div className="card-text">
                      {worker.title ? <div className="badge badge-soft-secondary mr-3">{worker.title}</div> : null}
                      {worker.trade ? <div className="badge badge-soft-secondary">{worker.trade}</div> : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </BaseModal>
  );
}
