import React, { useEffect } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';
import { TabConfig } from '@odin-labs/components';
import {
  JobsiteAnnouncement,
  JobsiteAnnouncementFilterSpecification,
  useGetJobsiteAnnouncementCandidateCountsLazyQuery,
  useGetJobsiteAnnouncementQuery,
} from 'apollo/generated/client-operations';
import { Container } from 'components/container';
import { ScrollSpyTabsPages, useScrollSpyTabs } from 'components/tabs';
import { NewHeader, classes as headerClasses } from 'components/header/NewHeader';
import { NewHeaderActionsProps } from 'components/header/types';
import { JobsiteAnnouncementTab } from './tabs/summary';
import { AnnouncementTypeBadge, announcementTitle } from './components';

export const classes = {
  container: cn('odin-h-full'),
  tabPage: cn('odin-overflow-y-scroll odin-bg-white'),
  headerTitleContainer: cn('odin-flex odin-space-x-5 odin-items-center'),
  headerTitleBadgesContainer: cn('odin-space-x-2'),
};

export const tabsConfig: TabConfig<{ announcement: JobsiteAnnouncement; counts: any }>[] = [
  { name: 'Summary', relativePath: '', component: JobsiteAnnouncementTab },
  { name: 'Filters', relativePath: '#filters' },
  { name: 'Performance', relativePath: '#performance' },
];

export function AnnouncementContainer(): React.ReactElement {
  const { announcementId } = useParams<{ announcementId: string }>();
  const baseRoute = `/announcement/${announcementId}`;

  const headerActionsProps = React.useMemo<NewHeaderActionsProps>(
    () => ({
      baseRoute,
      headerActions: ['back'],
    }),
    [],
  );

  const { data } = useGetJobsiteAnnouncementQuery({
    variables: { announcementId },
    fetchPolicy: 'no-cache',
    skip: !announcementId,
  });

  const announcement = data?.getJobsiteAnnouncement;

  const getRecipientFilters = (): JobsiteAnnouncementFilterSpecification => {
    return {
      filterJobsiteIds: announcement?.jobsites.edges?.map((e) => e?.node.jobsiteId),
      filterContractorIds: announcement?.contractors?.edges?.map((e) => e?.node?.contractorId),
      filterTrades: announcement?.trades,
      filterTitles: announcement?.titles,
      filterLanguages: announcement?.languages,
      filterJobsiteAccessStatus: announcement?.jobsiteAccessStatus,
      filterAccessWithinDayRange: announcement?.accessWithinDayRange,
      announcementType: announcement?.announcementType,
      paginationInput: { offset: 0, limit: 0 },
    } as unknown as JobsiteAnnouncementFilterSpecification;
  };

  const [loadCounts, { data: countsData }] = useGetJobsiteAnnouncementCandidateCountsLazyQuery({
    variables: { input: getRecipientFilters() },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (announcement?.jobsites?.edges?.length) loadCounts();
  }, [announcement]);

  const { tabs, currentTab } = useScrollSpyTabs({ tabsConfig, baseRoute });

  const headerTitle = (
    <div className={classes.headerTitleContainer}>
      {announcement ? (
        <>
          <h1 className={headerClasses.title}>{announcementTitle(announcement, 50)}</h1>
          <AnnouncementTypeBadge announcement={announcement} />
        </>
      ) : (
        <>Loading...</>
      )}
    </div>
  );

  const tabsPageProps = {
    tab: currentTab,
    announcement,
    counts: countsData?.getJobsiteAnnouncementCandidateRecipients,
  };

  return announcement ? (
    <Container className={classes.container}>
      <NewHeader title={headerTitle} tabsProps={{ tabs, currentTab }} actionsProps={headerActionsProps} />
      <ScrollSpyTabsPages
        className={classes.tabPage}
        baseRoute={baseRoute}
        tabs={tabs}
        componentProps={tabsPageProps}
      />
    </Container>
  ) : null;
}
