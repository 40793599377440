import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, Table, TableContainer } from '@odin-labs/components';
import { useGetDeveloperWorkersContainerCurrentSessionQuery } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { paginationSizePerPage as limit } from 'utils/constants';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { LoadingError } from 'components/loadingError';
import { DeveloperTabProps, DeveloperWorker, DeveloperWorkersFilters } from 'containers/developer/types';
import { getWorkerColumns, getWorkerFilterItems } from 'containers/developer/helpers/tables';

const workerProfileUrl = '/worker/';

export function DeveloperWorkersTabContainer(props: DeveloperTabProps): ReactElement {
  const { jobsites, onTabApiChange } = props ?? {};

  const { currentUser: user } = React.useContext(AuthContext);
  const history = useHistory();

  const { page, search, updateUrl, loading: isUrlLoading } = usePageQueryParams();
  let { jobsiteIds: selectedJobsiteIds } = usePageQueryParams({ updateJobsiteSelection: true });
  const offset = page * limit;

  const jobsiteIds = jobsites?.map((j) => j.jobsiteId);
  if (!selectedJobsiteIds?.length) {
    selectedJobsiteIds = jobsiteIds;
  }

  const {
    data,
    loading: workersLoading,
    error: workersError,
    refetch: refetchWorkers,
  } = useGetDeveloperWorkersContainerCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    skip: isUrlLoading,
    notifyOnNetworkStatusChange: true,
    variables: {
      userWorkersInput: {
        paginationInput: { limit, offset },
        jobsiteIds: selectedJobsiteIds,
        search,
      },
    },
  });

  const workers = React.useMemo(() => {
    return data?.getCurrentSession?.user?.workers?.edges?.map((we) => we.node) ?? [];
  }, [data]);

  const workersCount = data?.getCurrentSession?.user?.workers?.count ?? 0;
  const pageCount = Math.ceil(workersCount / limit);

  const columns = React.useMemo(() => getWorkerColumns(), [user]);
  const filterItems = React.useMemo(
    () => getWorkerFilterItems({ jobsites, jobsiteIds, search }),
    [jobsites, jobsiteIds, search],
  );

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkers }), [refetchWorkers]);

  if (workersError) {
    return <LoadingError error={workersError} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<DeveloperWorkersFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: developerWorker }: { data: DeveloperWorker }): void => {
    history.push(`${workerProfileUrl}${developerWorker.workerId}`);
  };

  return (
    <TableContainer className="client-workers-container">
      <Filter items={filterItems} loading={workersLoading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
      <Table
        loading={workersLoading}
        columns={columns}
        data={workers}
        initialState={{ pageSize: limit }}
        pageCount={pageCount}
        pageIndex={page}
        remote
        enablePagination
        onRowClick={onRowClickHandler}
        onPageChange={onPageChangeHandler}
        disableGlobalFilter
      />
    </TableContainer>
  );
}
