import React from 'react';

export type FormSubmissionReportLoadingProps = {
  loading: boolean;
};

export function FormSubmissionReportLoading({ loading }: FormSubmissionReportLoadingProps): React.ReactElement {
  if (!loading) {
    return null;
  }

  return (
    <div className="odin-hidden print:odin-flex odin-h-[95vh] odin-justify-center odin-items-center">
      <div className="odin-w-[550px] odin-rounded-xl odin-bg-odin-highlight odin-p-10">
        <div className="odin-text-center">
          <h2 className="odin-text-2xl odin-font-medium odin-text-black">Loading Log Report...</h2>
          <p className="odin-mt-2 odin-text-lg odin-text-black">
            Please wait while we load the relevant data for the report.
            <br />
            This process can take up to a minute.
          </p>
        </div>
      </div>
    </div>
  );
}
