import { GraphQLError } from 'graphql';
import { ApolloError } from '@apollo/client';
import { AppErrorCode, UserErrorCode } from 'apollo/generated/client-operations';

type Extensions = Record<string, unknown>;

type CustomGraphQLError<T extends Extensions> = Omit<GraphQLError, 'extensions'> & {
  extensions: T;
};

export const isApolloError = (error: any): error is ApolloError => !!error?.graphQLErrors;

export enum MatchBy {
  exactMessage,
  startOfMessage,
  endOfMessage,
  innerContentOfMessage,
}

export const unpackGraphQLError = (error: any): any => {
  const errors: { [errorKey: string]: string } = {};
  if (isApolloError(error)) {
    error?.graphQLErrors.forEach((issue: any) => {
      errors[issue.extensions.code as string] = issue.message;
    });
  }
  return errors;
};

export const getGraphQLError = (error: any): string => {
  const unpacked = unpackGraphQLError(error);
  const keys = Object.keys(unpacked);
  if (keys.length > 0) {
    return unpacked[Object.keys(unpacked)[0]];
  }
  if (error && !isApolloError(error)) console.error(error); // eslint-disable-line no-console
  return '';
};

export const getGraphQLErrorByCode = <T extends Extensions = Extensions>(
  error: any,
  errorCode: AppErrorCode | UserErrorCode | string,
): CustomGraphQLError<T> => {
  if (isApolloError(error)) {
    return error?.graphQLErrors.find(
      (gqlErr) => gqlErr.extensions.code === errorCode,
    ) as unknown as CustomGraphQLError<T>;
  }
  return null;
};

export const hasGraphQLErrorCode = (
  error: any,
  ...errorCodes: Array<AppErrorCode | UserErrorCode | string>
): boolean => {
  return (errorCodes ?? []).some((errorCode) => Boolean(getGraphQLErrorByCode(error, errorCode)));
};

export const getGraphQLErrorByName = <T extends Extensions = Extensions>(
  error: any,
  errorName: string,
): CustomGraphQLError<T> => {
  if (isApolloError(error)) {
    return error?.graphQLErrors.find(
      (gqlErr) => gqlErr.extensions.name === errorName,
    ) as unknown as CustomGraphQLError<T>;
  }
  return null;
};

export const hasGraphQLErrorName = (error: any, errorName: string): boolean => {
  return Boolean(getGraphQLErrorByName(error, errorName));
};

export const getGraphQLErrorByMessage = (
  error: any,
  errorMessage: string,
  matchType: MatchBy = MatchBy.exactMessage,
): GraphQLError => {
  if (isApolloError(error)) {
    return error?.graphQLErrors.find((gqlErr) => {
      switch (matchType) {
        case MatchBy.startOfMessage:
          return gqlErr.message?.startsWith(errorMessage);
        case MatchBy.endOfMessage:
          return gqlErr.message?.endsWith(errorMessage);
        case MatchBy.innerContentOfMessage:
          return gqlErr.message?.includes(errorMessage);
        case MatchBy.exactMessage:
        default:
          return gqlErr.message === errorMessage;
      }
    });
  }
  return null;
};

export const hasGraphQLErrorMessage = (
  error: any,
  errorMessage: string,
  matchType: MatchBy = MatchBy.exactMessage,
): boolean => {
  return Boolean(getGraphQLErrorByMessage(error, errorMessage, matchType));
};
