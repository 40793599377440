import React, { useState, ReactElement } from 'react';
import moment from 'moment';
import {
  AppErrorCode,
  GetWorkerDataDocument,
  useWorkerUnlockProfileMutation,
  WorkerUnlockProfileType,
} from 'apollo/generated/client-operations';
import { FormOnSubmit, ModalForm } from 'components/form';
import { ensureNonEmptyItems, useIsMounted } from 'utils';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { AlertProps } from '@odin-labs/components';
import { getIcon } from 'utils/ui';
import { UnlockProfileData, UnlockProfileModalProps } from './types';
import { getFormDefaultValues, getFormInputsHook, getProfileUnlockTypeOptions } from './UnlockProfileModal.forms';

export function UnlockProfileModal(props: UnlockProfileModalProps): ReactElement {
  const { isOpen, closeModal, worker } = props;
  const { identity } = worker.user;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<AlertProps>(null);

  const availableUnlockTypes = React.useMemo(
    () =>
      ensureNonEmptyItems<WorkerUnlockProfileType>([
        worker.birthDate && WorkerUnlockProfileType.BirthDate,
        identity.phoneNumber && WorkerUnlockProfileType.PhoneNumber,
        identity.email && WorkerUnlockProfileType.Email,
        worker.ssnLastFour && WorkerUnlockProfileType.SsnLastFour,
      ]),
    [worker],
  );

  const [unlockProfile] = useWorkerUnlockProfileMutation({
    refetchQueries: [GetWorkerDataDocument],
  });

  const onSubmit: FormOnSubmit<UnlockProfileData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { profileUnlockType, dateOfBirth, phoneNumber, emailAddress, lastFourDigitsOfSsn } = data;

    try {
      await unlockProfile({
        variables: {
          input: {
            workerId: worker.workerId,
            unlockType: profileUnlockType.value,
            birthDate: dateOfBirth ? moment.utc(dateOfBirth).toDate() : null,
            phoneNumber,
            email: emailAddress,
            ssnLastFour: lastFourDigitsOfSsn,
          },
        },
      });
      AlertInstance.alert('tc', 'success', 'Success', 'Profile unlocked');
      closeModal();
    } catch (error) {
      event.preventDefault();
      const unlockProfileDataNoMatchError = getGraphQLErrorByCode(error, AppErrorCode.UnlockWorkerProfileDataNoMatch);
      if (unlockProfileDataNoMatchError) {
        setAlertProps({ type: 'danger', text: unlockProfileDataNoMatchError.message });
      } else {
        setAlertProps(null);
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const profileUnlockTypeOptions = React.useMemo(
    () => getProfileUnlockTypeOptions({ availableUnlockTypes }),
    [availableUnlockTypes],
  );
  const defaultValues = React.useMemo(
    () => getFormDefaultValues({ profileUnlockTypeOptions }),
    [profileUnlockTypeOptions],
  );
  const inputs = React.useMemo(
    () => getFormInputsHook({ profileUnlockTypeOptions, onProfileUnlockTypeChange: () => setAlertProps(null) }),
    [profileUnlockTypeOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      title="Unlock Profile"
      actionText="Unlock Profile"
      actionIcon={getIcon('fal fa-unlock')}
      actionButtonWithSpinner={isFetching}
      inputs={inputs}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      alertProps={alertProps}
    />
  );
}
