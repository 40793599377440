import cn from 'classnames';
import { FormInput, FormDefaultValue, FormInputTypes } from 'components/form';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { EditPersonalInfoFormData, EditPersonalInfoFormDefaultData } from 'containers/worker/beta/types';
import {
  statesOptions,
  genderOptions,
  jobTitleOptions,
  primaryLanguageOptions,
  raceOptions,
  tradeOptions,
  unionAffiliationOptions,
  toFancySelectOptions,
} from 'utils/constants';
import { cleaveDateValidation, phoneNumberValidation, ssnValidation, zipCodeValidation } from 'utils/validation';

export const personalFormInput = (canAddUnions?: boolean): FormInput<EditPersonalInfoFormData>[] => [
  {
    name: 'firstName',
    element: FormInputTypes.Field,
    label: 'First name*',
    validation: {
      required: true,
    },
    layout: cn('odin-col-span-10', 'odin-text-sm'),
  },
  {
    name: 'middleInitial',
    element: FormInputTypes.Cleave,
    label: 'Middle initial',
    elementProps: {
      cleaveType: 'initial',
    },
    layout: cn('odin-col-span-2', 'odin-text-sm'),
  },
  {
    name: 'lastName',
    element: FormInputTypes.Field,
    label: 'Last name*',
    elementProps: {
      placeholder: 'Last name',
    },
    validation: {
      required: true,
    },
    layout: cn('odin-col-span-10', 'odin-text-sm'),
  },
  {
    name: 'suffix',
    element: FormInputTypes.Field,
    label: 'Suffix',
    elementProps: {
      placeholder: 'Suffix',
    },
    layout: cn('odin-col-span-2', 'odin-text-sm'),
  },
  {
    name: 'birthDate',
    element: FormInputTypes.Cleave,
    label: 'Date of birth',
    elementProps: {
      placeholder: 'MM/DD/YYYY',
      cleaveType: 'birthDate',
    },
    validation: {
      pattern: cleaveDateValidation,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'ssnLastFour',
    element: FormInputTypes.Cleave,
    label: 'Last 4 of SSN',
    elementProps: {
      placeholder: 'Last 4 of SSN',
      cleaveType: 'ssn4',
    },
    validation: {
      pattern: ssnValidation,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'primaryLanguage',
    element: FormInputTypes.FancySelect,
    label: 'Primary language',
    elementProps: {
      placeholder: 'Select one',
      options: toFancySelectOptions(primaryLanguageOptions),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'addressLine1',
    element: FormInputTypes.PlacesAutocomplete,
    label: 'Address',
    elementProps: {
      placeholder: 'Address',
      onCommit: fillAddressDetails,
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  {
    name: 'addressLine2',
    element: FormInputTypes.Field,
    label: 'Address line 2',
    elementProps: {
      placeholder: 'Address line 2',
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  {
    name: 'addressCity',
    element: FormInputTypes.Field,
    label: 'City',
    elementProps: {
      placeholder: 'City',
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  {
    name: 'addressState',
    element: FormInputTypes.FancySelect,
    label: 'State',
    elementProps: {
      placeholder: 'State',
      options: statesOptions,
    },
    layout: cn('odin-col-span-3', 'odin-text-sm'),
  },
  {
    name: 'addressZipCode',
    element: FormInputTypes.Cleave,
    label: 'Zip code',
    elementProps: {
      placeholder: 'Zip code',
      cleaveType: 'zipcode',
    },
    validation: {
      pattern: zipCodeValidation,
    },
    layout: cn('odin-col-span-3', 'odin-text-sm'),
  },
  {
    name: 'phoneNumber',
    element: FormInputTypes.Cleave,
    label: 'Phone number',
    elementProps: {
      placeholder: 'Phone number',
      cleaveType: 'tel',
    },
    validation: {
      pattern: phoneNumberValidation,
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  {
    name: 'email',
    element: FormInputTypes.Field,
    label: 'Email address',
    elementProps: {
      placeholder: 'Email address',
      disabled: true,
      type: 'email',
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  {
    name: 'trade',
    element: FormInputTypes.FancySelect,
    label: 'Trade',
    elementProps: {
      placeholder: 'Select one',
      options: toFancySelectOptions(tradeOptions),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'jobTitle',
    element: FormInputTypes.FancySelect,
    label: 'Job Title',
    elementProps: {
      placeholder: 'Select one',
      options: toFancySelectOptions(jobTitleOptions),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'unionAffiliation',
    element: FormInputTypes.FancySelect,
    label: 'Union Affiliation',
    elementProps: {
      isCreateable: canAddUnions,
      placeholder: 'Select one',
      options: toFancySelectOptions(unionAffiliationOptions),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'gender',
    element: FormInputTypes.FancySelect,
    label: 'Gender',
    elementProps: {
      placeholder: 'Select one',
      options: toFancySelectOptions(genderOptions),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'race',
    element: FormInputTypes.FancySelect,
    label: 'Race',
    elementProps: {
      placeholder: 'Select one',
      options: toFancySelectOptions(raceOptions),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  {
    name: 'isVeteran',
    element: FormInputTypes.FancySelect,
    label: 'Veteran status',
    elementProps: {
      placeholder: 'Select one',
      options: toFancySelectOptions(['Veteran', 'Non veteran']),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
];

export const createEditPersonalInfoDefaultData = (
  keys: Array<keyof EditPersonalInfoFormData>,
  formData: EditPersonalInfoFormDefaultData,
): FormDefaultValue<EditPersonalInfoFormData>[] => {
  return keys
    .filter((key) => key !== 'cancelButton' && key !== 'submitButton')
    .map((key) => key as keyof EditPersonalInfoFormDefaultData)
    .map((key) => {
      if (key === 'isVeteran') {
        return {
          name: key,
          value: {
            label: formData[key] ? 'Veteran' : 'Non veteran',
            value: formData[key] ? 'Veteran' : 'Non veteran',
          },
        };
      }

      const fancySelectKeys = [
        'race',
        'gender',
        'primaryLanguage',
        'isVeteran',
        'addressState',
        'trade',
        'jobTitle',
        'unionAffiliation',
      ];

      if (fancySelectKeys.includes(key) && formData[key]) {
        return {
          name: key,
          value: {
            label: formData[key],
            value: formData[key],
          },
        };
      }

      return {
        name: key,
        value: formData[key],
      };
    });
};
