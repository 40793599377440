import React from 'react';
import { WorkerPersonalInfoFieldProps } from './types';
import { classes } from './WorkerPersonalInfoField.style';

export function WorkerPersonalInfoField({
  label,
  value,
  labelClassName,
  valueClassName,
}: WorkerPersonalInfoFieldProps): JSX.Element {
  return (
    <>
      <div className={classes.label(labelClassName)}>{label}</div>
      <div className={classes.value(valueClassName)}>{value}</div>
    </>
  );
}
