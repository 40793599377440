import React from 'react';
import moment from 'moment';
import { Modal, ModalProps } from '@odin-labs/components';
import { camelToTitleCase, getUserFullName } from 'utils';
import { ChangeWorkerAccessModalProps } from './types';
import {
  AccessRestoringType,
  ChangeWorkerAccessState,
  ChangeWorkerAccessStep,
  ChooseAccessRevokingTypeModalContent,
  RevokeWorkerAccessModalContent,
  RestoreWorkerAccessModalContent,
} from './components';

export function ChangeWorkerAccessModal(props: ChangeWorkerAccessModalProps): React.ReactElement {
  const { isOpen, closeModal, onClosed, worker, jobsiteWorker } = props;
  const jobsiteName = jobsiteWorker?.jobsiteContractor.jobsite.name;
  const workerName = getUserFullName(worker?.user);

  const [changeWorkerAccessState, setChangeWorkerAccessState] = React.useState<ChangeWorkerAccessState>();

  const getInitialState = (): ChangeWorkerAccessState | undefined => {
    const isJobsiteWorkerOffboarded = !!jobsiteWorker?.endDate;
    const isJobsiteWorkerBanned = jobsiteWorker?.isBanned && !jobsiteWorker?.banExpiresAt;
    const isJobsiteWorkerSuspended =
      jobsiteWorker?.isBanned && jobsiteWorker?.banExpiresAt && moment(jobsiteWorker.banExpiresAt).isAfter(moment());

    if (isJobsiteWorkerSuspended) {
      return { step: ChangeWorkerAccessStep.RestoreAccess, accessRestoringType: AccessRestoringType.Unsuspend };
    }
    if (isJobsiteWorkerBanned) {
      return { step: ChangeWorkerAccessStep.RestoreAccess, accessRestoringType: AccessRestoringType.Unban };
    }
    if (isJobsiteWorkerOffboarded) {
      return { step: ChangeWorkerAccessStep.RestoreAccess, accessRestoringType: AccessRestoringType.Onboard };
    }
    return { step: ChangeWorkerAccessStep.ChooseAccessRevokingType };
  };

  React.useEffect(() => {
    if (isOpen) {
      const initialState = getInitialState();
      if (initialState) {
        setChangeWorkerAccessState(initialState);
      }
    }
  }, [isOpen]);

  const getModalProps = (): Omit<ModalProps, 'open' | 'setOpen'> => {
    switch (changeWorkerAccessState?.step) {
      case ChangeWorkerAccessStep.ChooseAccessRevokingType: {
        return {
          actionsPanel: null,
          title: `Revoke Jobsite Access for ${workerName} on ${jobsiteName}`,
          children: (
            <ChooseAccessRevokingTypeModalContent
              closeModal={closeModal}
              onConfirm={(accessRevokingType): void => {
                setChangeWorkerAccessState({ step: ChangeWorkerAccessStep.RevokeAccess, accessRevokingType });
              }}
              jobsiteWorker={jobsiteWorker}
            />
          ),
        };
      }

      case ChangeWorkerAccessStep.RevokeAccess: {
        const { accessRevokingType } = changeWorkerAccessState ?? {};
        return {
          actionsPanel: null,
          title: `${camelToTitleCase(accessRevokingType)} ${workerName} from ${jobsiteName}`,
          children: (
            <RevokeWorkerAccessModalContent
              closeModal={closeModal}
              onConfirm={closeModal}
              jobsiteWorker={jobsiteWorker}
              accessRevokingType={accessRevokingType}
            />
          ),
        };
      }

      case ChangeWorkerAccessStep.RestoreAccess: {
        const { accessRestoringType } = changeWorkerAccessState ?? {};
        const isOnboarding = accessRestoringType === AccessRestoringType.Onboard;
        return {
          actionsPanel: null,
          title: `${camelToTitleCase(accessRestoringType)} ${workerName} at ${jobsiteName}`,
          subtitle: isOnboarding ? 'Are you sure you want to re-onboard this worker?' : null,
          titleAlignment: isOnboarding ? 'center' : undefined,
          children: (
            <RestoreWorkerAccessModalContent
              closeModal={closeModal}
              onConfirm={closeModal}
              jobsiteWorker={jobsiteWorker}
              accessRestoringType={accessRestoringType}
            />
          ),
        };
      }

      default:
        return {};
    }
  };

  const modalProps = getModalProps();
  return <Modal open={isOpen} afterLeave={onClosed} setOpen={closeModal} {...modalProps} />;
}
