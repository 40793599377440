import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';

import { JobsiteWorkerOrientationStatus } from 'apollo/generated/client-operations';
import { ensureNonEmptyItems } from 'utils';
import { getVisibleSteps } from 'containers/workerOnboardings/helpers/utils';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';
import { OnboardingProgressProps } from './types';

import * as styles from './scss/orientation-status.scss';

const cx = classNames.bind(styles);

type IconAndStatusProps = {
  completed: boolean;
  skipReason: string;
  icon: string;
  stepKey: string;
  bannedReason: string;
  workerId: string;
};

function IconAndStatus(props: IconAndStatusProps): ReactElement {
  const { workerId, completed, skipReason, icon, stepKey, bannedReason } = props;
  return (
    <>
      <i
        id={`tooltip-status-${workerId}-${stepKey}`}
        className={cx(icon, {
          'orientation-status__default': !completed,
          'orientation-status__completed': completed,
          'orientation-status__warning': skipReason,
          'orientation-status__banned': bannedReason,
        })}
      />
      {bannedReason || skipReason ? (
        <UncontrolledTooltip placement="top" target={`tooltip-status-${workerId}-${stepKey}`}>
          {bannedReason || skipReason}
        </UncontrolledTooltip>
      ) : null}
    </>
  );
}

export function OnboardingProgress({ jobsiteWorker, className = {} }: OnboardingProgressProps): ReactElement {
  const { workerId } = jobsiteWorker?.contractorWorker?.worker ?? {};
  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const visibleSteps = getVisibleSteps(jobsite);

  return (
    <h1 className={cx('mb-0', className)}>
      <Badge
        color="none"
        className={cx(
          'badge odin-space-x-3',
          jobsiteWorker?.bannedReason
            ? 'badge-soft-danger'
            : [
                'orientation-status',
                jobsiteWorker?.orientationStatus === JobsiteWorkerOrientationStatus.SelfOnboarding
                  ? 'odin-bg-yellow-100'
                  : 'odin-bg-gray-100',
              ],
        )}
      >
        <IconAndStatus
          completed={!!jobsiteWorker?.profileCompletedAt}
          skipReason={jobsiteWorker?.profileCompleteSkipReason}
          icon="fal fa-user"
          stepKey="personal-info"
          bannedReason={jobsiteWorker?.bannedReason}
          workerId={workerId}
        />
        {ensureNonEmptyItems([
          visibleSteps?.includes(OnboardingStepKey.WorkDocuments) && (
            <IconAndStatus
              key={OnboardingStepKey.WorkDocuments}
              completed={!!jobsiteWorker?.documentsCompletedAt}
              skipReason={jobsiteWorker?.documentsCompletedSkipReason}
              icon="fal fa-file-alt"
              stepKey="documents"
              bannedReason={jobsiteWorker?.bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.MedicalInfo) && (
            <IconAndStatus
              key={OnboardingStepKey.MedicalInfo}
              completed={!!jobsiteWorker?.passedMedicalExamAt}
              skipReason={jobsiteWorker?.medicalExamSkipReason}
              icon="fal fa-comment-medical"
              stepKey="medical"
              bannedReason={jobsiteWorker?.bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.OnboardingTraining) && (
            <IconAndStatus
              key={OnboardingStepKey.OnboardingTraining}
              completed={!!jobsiteWorker?.onboardingSignedAt}
              skipReason={jobsiteWorker?.onboardingSkipReason}
              icon="fal fa-newspaper"
              stepKey="onboarding"
              bannedReason={jobsiteWorker?.bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.Signature) && (
            <IconAndStatus
              key={OnboardingStepKey.Signature}
              completed={!!jobsiteWorker?.signatureCompletedAt}
              skipReason={jobsiteWorker?.signatureSkipReason}
              icon="fal fa-file-signature"
              stepKey="signature"
              bannedReason={jobsiteWorker?.bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.Badging) && (
            <IconAndStatus
              key={OnboardingStepKey.Badging}
              completed={!!jobsiteWorker?.badgingCompletedAt}
              skipReason={jobsiteWorker?.badgingSkipReason}
              icon="fal fa-id-badge"
              stepKey="badging"
              bannedReason={jobsiteWorker?.bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.SiteSpecificOrientation) && (
            <IconAndStatus
              key={OnboardingStepKey.SiteSpecificOrientation}
              completed={!!jobsiteWorker?.siteSpecificTrainingCompletedAt}
              skipReason={jobsiteWorker?.siteSpecificTrainingSkipReason}
              icon="fal fa-hard-hat"
              stepKey="site-specific"
              bannedReason={jobsiteWorker?.bannedReason}
              workerId={workerId}
            />
          ),
        ])}
      </Badge>
    </h1>
  );
}
