import React from 'react';

/**
 * Unlike the class component `setState`, the updates are not allowed to be partial
 * This hook enhances `setState` to accept partial updates
 */
export function useLegacyState<S>(
  initialState?: S | (() => S),
): [S, (updState: Partial<S> | ((prevState: Partial<S>) => Partial<S>)) => void] {
  const [state, setState] = React.useState(initialState);
  const setPartialState = React.useCallback(
    (updState: Partial<S> | ((prevState: Partial<S>) => Partial<S>)) =>
      setState(
        (prevState: S): S => ({
          ...prevState,
          ...(typeof updState === 'function' ? updState(prevState) : updState),
        }),
      ),
    [setState],
  );
  return [state, setPartialState];
}
