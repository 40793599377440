import React, { ReactElement, forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import type { FormData, FormProps, HTMLFormElementWithApi } from './types';
import { resolveFormDefaultValues } from './utils';
import { useFormBuilder } from './useFormBuilder';

const forwardedForm = forwardRef(
  <TFields extends FormData>(
    props: FormProps<TFields>,
    ref: React.ForwardedRef<HTMLFormElement | HTMLFormElementWithApi<TFields>>,
  ): ReactElement => {
    const { defaultValues: defaultValuesProp, renderAbove, renderBelow, ...restProps } = props;
    const { inputsContainerClassName } = props;

    const defaultValues = React.useMemo(() => resolveFormDefaultValues(defaultValuesProp), [defaultValuesProp]);
    const methods = useForm<TFields>({ defaultValues });
    const { formOnSubmit, formClassName, formElements } = useFormBuilder<TFields>({
      methods,
      defaultValues,
      ...restProps,
    });

    const innerRef = React.useRef<HTMLFormElement>();

    React.useImperativeHandle(ref, () => Object.assign(innerRef.current, { api: methods }));

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(formOnSubmit)} ref={innerRef} className={formClassName}>
          {typeof renderAbove === 'function' ? renderAbove?.() : renderAbove}
          {inputsContainerClassName ? <div className={inputsContainerClassName}>{formElements}</div> : formElements}
          {typeof renderBelow === 'function' ? renderBelow?.() : renderBelow}
        </form>
      </FormProvider>
    );
  },
);

type ForwardedForm = <TFields extends FormData>(
  props: FormProps<TFields> & {
    ref?: React.ForwardedRef<HTMLFormElement | HTMLFormElementWithApi<TFields>>;
  },
) => ReturnType<typeof forwardedForm>;

export const Form = forwardedForm as ForwardedForm;
