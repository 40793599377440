import React, { ReactElement } from 'react';
import { useMutation } from '@apollo/client';
import { faMinusOctagon } from '@fortawesome/pro-light-svg-icons';
import { Modal, getFaIcon } from '@odin-labs/components';
import { GetWorkerCardsDocument, MutationRemoveCardFromWorkerArgs, Success } from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import { REMOVE_CARD_FROM_WORKER } from 'containers/worker/helpers/queries';
import { DeactivateBadgeModalProps } from './types';

const FaMinusOctagonIcon = getFaIcon({ icon: faMinusOctagon });

export function DeactivateBadgeModal(props: DeactivateBadgeModalProps): ReactElement {
  const { isOpen, workerCardId, closeModal: onCancel, onConfirm } = props;

  const [removeCardFromWorker, { loading }] = useMutation<Success, MutationRemoveCardFromWorkerArgs>(
    REMOVE_CARD_FROM_WORKER,
    {
      onCompleted: () => {
        onConfirm();
        AlertInstance.alert('tc', 'success', 'Success!', '');
      },
      onError: (error) => {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      },
      variables: { workerCardIds: [workerCardId] },
      refetchQueries: [GetWorkerCardsDocument],
    },
  );

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title="Deactivate badge"
      titleAlignment="center"
      text="Would you like to deactivate this badge?"
      textAlignment="center"
      onAction={removeCardFromWorker}
      actionText="Deactivate"
      actionButtonTheme="danger"
      actionIcon={FaMinusOctagonIcon}
      actionButtonWithSpinner={loading}
    />
  );
}
