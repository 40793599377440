import { ApolloError, useMutation } from '@apollo/client';
import { AlertInstance } from 'components/alertNotification';
import { ARCHIVE_WORKER } from 'containers/worker/helpers/queries';
import React, { ReactElement, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { getGraphQLError } from 'utils/error';
import { BaseModal } from './BaseModal';
import { ArchiveWorkerModalProps } from './types';

export function ArchiveWorkerModal({
  workerId,
  isOpen = false,
  onCancel,
  onAction,
}: ArchiveWorkerModalProps): ReactElement {
  const [fetching, setFetching] = useState<boolean>(false);
  const [archiveWorkerMutation] = useMutation(ARCHIVE_WORKER, {
    onCompleted: () => {
      setFetching(false);
      onAction();
      AlertInstance.alert('tc', 'success', 'Worker successfully archived', 'This worker has been archived');
    },
    onError: (archiveError: ApolloError) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(archiveError));
    },
  });

  const archiveWorker = (): void => {
    if (fetching) {
      return;
    }
    setFetching(true);
    archiveWorkerMutation({
      variables: {
        workerId,
      },
    });
  };

  return (
    <BaseModal
      onCancel={onCancel}
      onAction={archiveWorker}
      isOpen={isOpen}
      toggle={onCancel}
      cancelText="Cancel"
      actionText="Archive"
      title="Are you sure?"
      actionButtonEnabled={!fetching}
      actionColor="danger"
    >
      <ModalBody>
        <h3>Are you sure you want to archive this worker? This action cannot be undone!</h3>
      </ModalBody>
    </BaseModal>
  );
}
