import { FormData } from 'components/form';
import React from 'react';
import { Change, Editable } from 'types';
import { useResettableState } from './useResettableState';

export type UseModalChangeActionsReturn<T> = {
  change: Change<T>;
  closeModal: () => void;
  openAddModal: () => void;
  openEditModal: (item: T) => void;
  openDeleteModal: (item: T) => void;
  updateValueAndCloseModal: (newItem: T) => void;
  deleteItem: (item: T) => void;
};

export type UseModalChangeActionsArgs<T> = {
  items: T[];
  onChange: (value: T[]) => void;
  idField: keyof T;
};

/**
 * The `useModalChangeAction` Hook provides a convenient way to deal with creation, update and removal modals.
 * @returns an object containing the following fields:
 * - change: The current value of the boolean
 * - setValue: Sets the current value
 * - resetValue: Resets the current value to the resetting value.
 */
export const useModalChangeActions = <T extends Editable<FormData>>(
  args?: UseModalChangeActionsArgs<T>,
): UseModalChangeActionsReturn<T> => {
  const { items, onChange, idField } = args ?? {};
  const { value: change, setValue: openModal, resetValue: closeModal } = useResettableState<Change<T>>(null, null);

  const openAddModal = React.useCallback(() => openModal({ type: 'create' }), [openModal]);
  const openEditModal = React.useCallback((item: T) => openModal({ type: 'update', item }), [openModal]);
  const openDeleteModal = React.useCallback((item: T) => openModal({ type: 'remove', item }), [openModal]);

  const updateValueAndCloseModal = React.useCallback(
    (newItem: T): void => {
      const newItemId = newItem[idField];
      let newValue: T[];
      switch (change?.type) {
        case 'create':
          newValue = [...(items ?? []), { ...newItem, changeType: 'created' }];
          break;
        case 'update':
          newValue = items.map((item) =>
            item[idField] !== newItemId
              ? item
              : { ...item, ...newItem, changeType: item.changeType === 'created' ? 'created' : 'updated' },
          );
          break;
        case 'remove':
          newValue =
            newItem.changeType === 'created'
              ? items.filter((item) => item[idField] !== newItemId)
              : items.map((item) => (item[idField] !== newItemId ? item : { ...item, changeType: 'removed' }));
          break;
        default:
          break;
      }

      if (newValue) {
        onChange?.(newValue);
      }

      closeModal();
    },
    [items, onChange, idField, change, closeModal],
  );

  const deleteItem = React.useCallback(
    (itemToDelete: T): void => {
      const itemToDeleteId = itemToDelete[idField];
      const newValue =
        itemToDelete.changeType === 'created'
          ? items.filter((item) => item[idField] !== itemToDeleteId)
          : items.map((item) => (item[idField] !== itemToDeleteId ? item : { ...item, changeType: 'removed' }));

      if (newValue) {
        onChange?.(newValue);
      }
    },
    [items, onChange, idField],
  );

  return { change, openAddModal, openEditModal, openDeleteModal, closeModal, updateValueAndCloseModal, deleteItem };
};
