import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { getIcon } from 'utils/ui';
import { RequiredField } from 'utils/validation';

export type AddWorkerCommentFormData = {
  comment: string;
  jobsiteId: SelectOptionElement;
};

export type AddWorkerInputsArgs = {
  jobsitesOptions: SelectOptionElement[];
};

const BriefcaseIcon = getIcon('fal fa-briefcase');

export const getFormInputs = ({ jobsitesOptions }: AddWorkerInputsArgs): TypedFormInputs<AddWorkerCommentFormData> => ({
  comment: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Do not include any PHI',
    elementProps: {
      placeholder: 'Comment',
      size: 'base',
      autosize: true,
    },
    validation: { required: { value: true, message: RequiredField`Comment` } },
    layout: [GridColSpan.SpanFull],
  },
  jobsiteId: {
    element: FormInputTypes.OdinSelect,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsitesOptions,
      icon: BriefcaseIcon,
      disabled: (jobsitesOptions?.length ?? 0) < 2,
    },
    validation: { required: true },
    layout: GridColSpan.SpanFull,
  },
});

export const getDefaultValues = (args: { jobsitesOptions: SelectOptionElement[] }): AddWorkerCommentFormData => {
  const { jobsitesOptions } = args;

  return {
    comment: '',
    jobsiteId: jobsitesOptions?.length === 1 ? jobsitesOptions[0] : null,
  };
};
