import { gql } from '@apollo/client';

export const CREATE_CONTRACTOR_WORKER = gql`
  mutation CreateContractorWorker($createContractorWorkerInput: CreateContractorWorkerInput!) {
    createContractorWorker(createContractorWorkerInput: $createContractorWorkerInput) {
      contractor {
        contractorId
      }
      worker {
        workerId
        ssnLastFour
        birthDate
        user {
          userAccountId
          identity {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
