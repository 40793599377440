import React, { ReactElement } from 'react';
import { getMenuItemIcon } from 'utils/ui';
import { NewDropdownButton } from '@odin-labs/components';
import type { NewMenuItemProps } from '@odin-labs/components';
import { classes } from './WorkerHeaderAdminActions.style';

export function WorkerHeaderAdminActions(): ReactElement {
  const onMenuClick = (): void => alert('Admin action'); // eslint-disable-line no-alert

  const menuItems: NewMenuItemProps[] = [
    {
      onClick: onMenuClick,
      text: 'Send Password Reset',
      icon: getMenuItemIcon('fal fa-redo'),
      theme: 'secondary',
    },
    {
      onClick: onMenuClick,
      text: 'Add Odin Role to User',
      icon: getMenuItemIcon('fal fa-user-plus'),
      theme: 'secondary',
    },
    {
      onClick: onMenuClick,
      text: 'See All Jobsites',
      icon: getMenuItemIcon('fal fa-list-ul'),
      theme: 'secondary',
    },
    {
      onClick: onMenuClick,
      text: 'Get CCure Data',
      icon: getMenuItemIcon('fal fa-database'),
      theme: 'secondary',
    },
    {
      onClick: onMenuClick,
      text: 'Archive User',
      icon: getMenuItemIcon('fal fa-trash-alt'),
      theme: 'danger',
    },
  ];

  return (
    <div className={classes.headerAdminButtonContainer}>
      <NewDropdownButton hideArrow menuItems={menuItems} theme="naked" icon={getMenuItemIcon('fal fa-cog')}>
        <span className={classes.headerAdminButtonText}>Admin</span>
      </NewDropdownButton>
    </div>
  );
}
