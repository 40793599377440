import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { QueryGetWorkerArgs } from 'apollo/generated/client-operations';
import { RoutedTabsHeader, RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { TabConfig } from '@odin-labs/components';
import { useWorkerData } from 'containers/worker/data/useWorkerData';
import { GetWorkerResponse, WorkerTabProps } from './types';
import { GET_WORKER } from './helpers/queries';

import {
  WorkerHeader,
  WorkerHeaderRow as WhRow,
  WorkerHeaderActions as WhActions,
  WorkerHeaderAddDropdown as WhAddDropdown,
  WorkerHeaderPicture as WhPicture,
  WorkerHeaderInfo as WhInfo,
  WorkerHeaderAdminActions as WhAdminActions,
} from './workerHeader';

import { classes } from './WorkerTailwindContainer.style';
import { WorkerTailwindOverview } from './tabs/WorkerTailwindOverview';
import { WorkerTailwindDocuments } from './tabs/WorkerTailwindDocuments';
import { WorkerTailwindBadges } from './tabs/WorkerTailwindBadges';
import { WorkerTailwindComments } from './tabs/WorkerTailwindComments';
import { WorkerTailwindActivity } from './tabs/WorkerTailwindActivity';

export const workerTabConfig: TabConfig<WorkerTabProps>[] = [
  { name: 'Overview', relativePath: '', component: WorkerTailwindOverview },
  { name: 'Documents', relativePath: '/documents', component: WorkerTailwindDocuments },
  { name: 'Badges', relativePath: '/badges', component: WorkerTailwindBadges },
  { name: 'Comments', relativePath: '/comments', component: WorkerTailwindComments },
  { name: 'Activity', relativePath: '/activity', component: WorkerTailwindActivity },
];

export function WorkerTailwindContainer(): ReactElement {
  const { workerId } = useParams<{ workerId: string }>();
  const baseRoute = `/worker/${workerId}`;
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: workerTabConfig, baseRoute });

  const workerData = useWorkerData(workerId);

  const { data } = useQuery<GetWorkerResponse, QueryGetWorkerArgs>(GET_WORKER, {
    variables: {
      workerId,
      includeArchived: true,
    },
  });

  const worker = data?.getWorker;
  const { jobsiteWorkers, refetch } = workerData;
  const tabsPageProps: WorkerTabProps = {
    workerId,
    worker,
    jobsiteWorkers,
    refetch,
  };

  return (
    <div data-test="worker-tailwind-container" className="ip">
      <WorkerHeader>
        <WhRow data-test="worker-header-actions-container" className={classes.headerActionsContainer}>
          <WhActions baseRoute={baseRoute}>
            <WhAddDropdown worker={worker} workerData={workerData} />
          </WhActions>
        </WhRow>
        <WhRow data-test="worker-header-info-container" className={`odin--ml-7 ${classes.headerInfoContainer}`}>
          <WhPicture worker={worker} className="odin-ml-7" />
          <WhInfo worker={worker} className="odin-m-7" />
        </WhRow>
        <WhRow data-test="worker-header-navigation-container" className={classes.headerNavigationContainer}>
          <RoutedTabsHeader tabs={tabs} currentTab={currentTab} />
          <WhAdminActions />
        </WhRow>
      </WorkerHeader>
      <RoutedTabsPages
        className={classes.workerTabPage}
        baseRoute={baseRoute}
        tabs={tabs}
        componentProps={tabsPageProps}
      />
    </div>
  );
}
