import { DeepMap } from 'react-hook-form';
import { JobsiteOnboardingFieldInput, JobsiteOnboardingStepInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';
import { getOnboardingModule } from 'containers/jobsiteConfiguration/helpers/utils';
import {
  EditJobsiteConfigWorkerInfoField,
  EditJobsiteConfigurationFormData,
  Jobsite,
} from 'containers/jobsiteConfiguration/types';
import { WorkerInfoField } from 'containers/jobsiteConfiguration/components/workerInfoField';
import { toggleBorderClasses } from './utils';

export const workerInfoSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['workerInfo']> = {
  phone: {
    element: FormInputTypes.CustomInput,
    label: 'Phone Number',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  email: {
    element: FormInputTypes.CustomInput,
    label: 'Email Address',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  ssnLastFour: {
    element: FormInputTypes.CustomInput,
    label: 'Last 4 Digits of SSN',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  race: {
    element: FormInputTypes.CustomInput,
    label: 'Race',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  gender: {
    element: FormInputTypes.CustomInput,
    label: 'Gender',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  trade: {
    element: FormInputTypes.CustomInput,
    label: 'Trade',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  title: {
    element: FormInputTypes.CustomInput,
    label: 'Title',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  emergencyContact: {
    element: FormInputTypes.CustomInput,
    label: 'Emergency Contact',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  address: {
    element: FormInputTypes.CustomInput,
    label: 'Address',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  addressZipCode: {
    element: FormInputTypes.CustomInput,
    label: 'Address Zip Code',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  veteranStatus: {
    element: FormInputTypes.CustomInput,
    label: 'Veteran Status',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  citizenshipStatus: {
    element: FormInputTypes.CustomInput,
    label: 'Citizenship Status',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
  unionAffiliation: {
    element: FormInputTypes.CustomInput,
    label: 'Union Affiliation',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { customInput: WorkerInfoField },
  },
};

export const getWorkerInfoSectionDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['workerInfo'] => {
  const onboardingModule = getOnboardingModule(jobsite?.modules);
  const workerInfoStep = onboardingModule?.steps?.find((s) => s.key === OnboardingStepKey.PersonalInfo);
  const { fields } = workerInfoStep ?? {};

  const getWorkerInfoField = (
    fieldKey: keyof EditJobsiteConfigurationFormData['workerInfo'],
  ): EditJobsiteConfigWorkerInfoField => {
    const { isRequired, isHidden } = fields?.find((f) => f.key === fieldKey) ?? {};
    return { isRequired: !!isRequired, isHidden: !!isHidden };
  };

  return {
    phone: getWorkerInfoField('phone'),
    email: getWorkerInfoField('email'),
    ssnLastFour: getWorkerInfoField('ssnLastFour'),
    race: getWorkerInfoField('race'),
    gender: getWorkerInfoField('gender'),
    trade: getWorkerInfoField('trade'),
    title: getWorkerInfoField('title'),
    emergencyContact: getWorkerInfoField('emergencyContact'),
    address: getWorkerInfoField('address'),
    addressZipCode: getWorkerInfoField('addressZipCode'),
    veteranStatus: getWorkerInfoField('veteranStatus'),
    citizenshipStatus: getWorkerInfoField('citizenshipStatus'),
    unionAffiliation: getWorkerInfoField('unionAffiliation'),
  };
};

type WorkerInfoSectionUpdateInput = {
  personalInfoStep: JobsiteOnboardingStepInput;
};

export const getWorkerInfoSectionUpdateInput = (
  workerInfo: EditJobsiteConfigurationFormData['workerInfo'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['workerInfo'], true>,
): WorkerInfoSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(workerInfo, dirtyFields);

  const fields: JobsiteOnboardingFieldInput[] = Object.keys(workerInfo)
    .map((key) => ({
      key,
      ...getUpdateInputValue(key as keyof typeof workerInfo),
    }))
    .filter(({ isRequired, isHidden }) => isRequired !== undefined || isHidden !== undefined);

  return {
    personalInfoStep: fields.length
      ? {
          key: OnboardingStepKey.PersonalInfo,
          isVisible: true,
          fields,
        }
      : undefined,
  };
};
