import { SelectOptionElement } from '@odin-labs/components';
import React, { useState, ReactElement } from 'react';
import {
  GetContractorContainerContractorJobsitesDocument,
  StripePaymentMethod,
  useGetContractorContainerPaymentMethodsQuery,
  useJobsiteContractorUpdateStripePaymentMethodMutation,
} from 'apollo/generated/client-operations';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { generateStripePaymentMethodDisplayValue } from 'containers/contractor/helpers';
import {
  AssociateJobsiteContractorPaymentMethodFormData,
  getDefaultValues,
  getUpdateInput,
  getFormInputs,
} from './AssociateJobsiteContractorPaymentMethod.forms';
import { AssociateJobsiteContractorPaymentMethodModalProps } from './types';

export function AssociateJobsiteContractorPaymentMethodModal(
  props: AssociateJobsiteContractorPaymentMethodModalProps,
): ReactElement {
  const { jobsiteContractor, isOpen, contractor, onCancel } = props;
  const { contractorId } = contractor ?? {};

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { data: contractorData } = useGetContractorContainerPaymentMethodsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !isOpen,
    variables: {
      contractorId,
      includeArchived: true,
    },
  });
  const stripePaymentMethods = React.useMemo(
    () => contractorData?.getContractor?.stripePaymentMethods,
    [contractorData],
  );

  const [jobsiteContractorUpdateStripePaymentMethod] = useJobsiteContractorUpdateStripePaymentMethodMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetContractorContainerContractorJobsitesDocument],
  });

  const onSubmit: FormOnSubmit<AssociateJobsiteContractorPaymentMethodFormData> = async (
    data,
    event,
    dirtyFields,
  ): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const input = getUpdateInput(jobsiteContractor.id, data, dirtyFields);
      await jobsiteContractorUpdateStripePaymentMethod({ variables: { input } });

      AlertInstance.alert('tc', 'success', 'Success', 'Payment method successfully updated');
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const getStripePaymentMethodsOptions = (paymentMethods: StripePaymentMethod[]): SelectOptionElement[] => {
    return paymentMethods?.map((paymentMethod) => ({
      label: generateStripePaymentMethodDisplayValue(paymentMethod),
      value: paymentMethod.id,
    }));
  };

  const stripePaymentMethodsOptions = React.useMemo(
    () => getStripePaymentMethodsOptions(stripePaymentMethods),
    [stripePaymentMethods],
  );

  const formInputs = getFormInputs({
    jobsiteContractor,
    stripePaymentMethodsOptions,
  });

  const defaultValues = React.useMemo(
    () => getDefaultValues({ jobsiteContractor, stripePaymentMethodsOptions }),
    [jobsiteContractor, stripePaymentMethods],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Edit Stripe Payment Method"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionButtonWithSpinner={isSaving}
    />
  );
}
