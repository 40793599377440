import { useDidUpdateEffect } from '@odin-labs/components';
import { WorkerCardFormat } from 'apollo/generated/client-operations';
import cn from 'classnames';
import { FormInput, FormInputTypes, TypedFormInputs, UseInputs, UseFormMethods } from 'components/form';
import { SelectOptionElement } from 'components/select/types';
import { CreateBadgeInfo, EditEmployerInfoFormData, JobsiteAccessEventFormData } from 'containers/worker/types';
import { History } from 'history';
import moment from 'moment';
import React from 'react';
import { getCurrentISOFormattedDate } from 'utils';
import { getJobsiteDateTimeNow } from 'utils/dates';
import { getIconWithAutoHeight } from 'utils/ui';
import { phoneNumberValidation, timeValidation } from 'utils/validation';

export const employerFormInput = (history?: History, workerId?: string): FormInput<EditEmployerInfoFormData>[] => [
  {
    name: 'supervisorName',
    element: FormInputTypes.Field,
    elementProps: {
      placeholder: 'Supervisor Name',
      label: 'Supervisor Name',
    },
    validation: {
      required: {
        value: true,
        message: 'Supervisor Name Required',
      },
    },
    layout: 'col-5',
  },
  {
    name: 'supervisorPhoneNumber',
    element: FormInputTypes.Cleave,
    elementProps: {
      placeholder: 'Supervisor Phone Number',
      label: 'Supervisor Phone Number',
      cleaveType: 'tel',
    },
    validation: {
      pattern: phoneNumberValidation,
      required: {
        value: true,
        message: 'Supervisor Phone Number Required',
      },
    },
    layout: 'col-5 offset-1',
  },
  {
    name: 'cancelButton',
    element: FormInputTypes.Button,
    elementProps: {
      label: 'Cancel',
      type: 'button',
      color: 'white',
      buttonType: 'button',
      onClick: (): void => {
        history.push(`/worker/${workerId}`);
      },
    },
    layout: 'col-8',
  },
  {
    name: 'submitButton',
    element: FormInputTypes.Button,
    elementProps: {
      label: 'Submit',
      buttonType: 'submit',
    },
    layout: 'col-3 text-right',
  },
];

export const getCreateBadgeForm = (
  proximityCardFormats: WorkerCardFormat[],
  isTempBadge = false,
): FormInput<CreateBadgeInfo>[] => {
  const proximityCardFormatOptions: SelectOptionElement[] = proximityCardFormats.map((workerCardFormat) => ({
    value: workerCardFormat?.workerCardFormatId,
    label: `${workerCardFormat?.name} - ${workerCardFormat?.facilityCode}`,
  }));

  const formInputs: FormInput<CreateBadgeInfo>[] = [
    {
      name: 'proximityCardNumber',
      element: FormInputTypes.Cleave,
      elementProps: {
        placeholder: 'Proximity card number',
        label: 'Proximity card number',
        cleaveType: 'number',
      },
      validation: {
        required: true,
      },
      layout: 'col-12',
    },
    {
      name: 'bluetoothCardNumber',
      element: FormInputTypes.Field,
      elementProps: {
        placeholder: 'Bluetooth badge number',
        label: 'Bluetooth badge number',
        upperCase: true,
      },
      layout: 'col-12',
    },
    {
      name: 'proximityCardFormatId',
      element: FormInputTypes.FancySelect,
      elementProps: {
        placeholder: 'Proximity card format',
        label: 'Proximity card format',
        disabled: proximityCardFormats.length === 1,
        options: proximityCardFormatOptions,
      },
      layout: 'col-12',
    },
  ];

  if (isTempBadge) {
    formInputs.push({
      name: 'badgeExpirationDate',
      element: FormInputTypes.OdinDatepicker,
      elementProps: {
        placeholder: 'Expiration date of badge',
        label: 'Expiration date of badge',
        maxDate: moment().add(1, 'week'),
        minDate: moment(),
        dates: [getCurrentISOFormattedDate()],
      },
      validation: {
        required: true,
      },
      layout: 'col-6',
    });

    formInputs.push({
      name: 'accessReason',
      element: FormInputTypes.Field,
      elementProps: {
        placeholder: 'Reason for access',
        label: 'Reason for access',
      },
      validation: {
        required: true,
      },
      layout: 'col-6',
    });
  }

  return formInputs;
};

const checkInDirectionOptions: SelectOptionElement[] = ['Inbound', 'Outbound'].map((direction) => ({
  value: direction,
  label: direction,
}));

const reasonOptions: SelectOptionElement[] = [
  'Worker forgot to clock in',
  'Worker forgot to clock out',
  'Worker does not have Mobile App',
].map((reason) => ({ value: reason, label: reason }));

export const getAddJobsiteAccessEventFormInputsHook =
  ({ jobsiteOptions }: { jobsiteOptions: SelectOptionElement[] }): UseInputs<JobsiteAccessEventFormData> =>
  ({ watch, setValue }: UseFormMethods<JobsiteAccessEventFormData>): TypedFormInputs<JobsiteAccessEventFormData> => {
    const selectedJobsiteOption = watch('jobsiteId');

    useDidUpdateEffect(() => {
      if (selectedJobsiteOption) {
        const { date, time } = getJobsiteDateTimeNow(selectedJobsiteOption);
        setValue('checkInDate', date);
        setValue('checkInTime', time);
      }
    }, [selectedJobsiteOption?.timeZone]);

    return React.useMemo<TypedFormInputs<JobsiteAccessEventFormData>>(
      () => ({
        jobsiteId: {
          element: FormInputTypes.OdinSelect,
          elementProps: {
            placeholder: 'Select jobsite',
            label: 'Jobsite',
            options: jobsiteOptions,
            icon: getIconWithAutoHeight('fal fa-briefcase'),
            disabled: (jobsiteOptions?.length ?? 0) < 2,
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6'),
        },
        checkInDirection: {
          element: FormInputTypes.OdinSelect,
          elementProps: {
            placeholder: 'Select direction',
            label: 'Direction',
            options: checkInDirectionOptions,
            icon: getIconWithAutoHeight('fal fa-arrow-to-right'),
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6'),
        },
        checkInDate: {
          element: FormInputTypes.NewDatePicker,
          elementProps: {
            label: 'Date',
            maxDate: moment().add(1, 'month'),
            showDefaultIcon: true,
            numberOfMonths: 1,
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6 sm:odin-col-span-3'),
        },
        checkInTime: {
          element: FormInputTypes.OdinField,
          elementProps: {
            label: 'Time',
            placeholder: 'hh:mm',
            fieldType: 'time',
            showDefaultIcon: true,
          },
          validation: {
            pattern: timeValidation,
            required: true,
          },
          layout: cn('odin-col-span-6 sm:odin-col-span-3'),
        },
        reason: {
          element: FormInputTypes.OdinSelect,
          elementProps: {
            placeholder: 'Select reason',
            label: 'Reason',
            options: reasonOptions,
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6'),
        },
      }),
      [jobsiteOptions],
    );
  };

export const getAddJobsiteAccessEventFormDefaultValues = ({
  jobsiteOptions,
}: {
  jobsiteOptions: JobsiteAccessEventFormData['jobsiteId'][];
}): JobsiteAccessEventFormData => {
  const defaultJobsiteOption = jobsiteOptions?.length === 1 ? jobsiteOptions[0] : null;
  const { date = '', time = '' } = defaultJobsiteOption ? getJobsiteDateTimeNow(defaultJobsiteOption) : {};

  return {
    jobsiteId: jobsiteOptions?.length === 1 ? jobsiteOptions[0] : null,
    checkInDirection: null,
    checkInDate: date,
    checkInTime: time,
    reason: null,
  };
};
