import React from 'react';
import cn from 'classnames';
import { Column } from '@odin-labs/components';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistoryFormat } from 'components/objectHistory/types';
import { WorkerComment } from 'containers/worker/types';

// comments table columns
export type WorkerCommentColumn = Column<WorkerComment>;

export const getCommentsColumns = (): WorkerCommentColumn[] => [
  {
    id: 'comment',
    Header: 'Comment',
    cellClassName: cn('odin-whitespace-pre-line odin-py-2'),
    accessor: 'comment',
  },
  {
    id: 'jobsite',
    Header: 'Jobsite',
    accessor: (comment: WorkerComment): string => comment?.jobsite?.name,
  },
  {
    id: 'createdBy',
    Header: 'Created By',
    accessor: (comment: WorkerComment): WorkerComment => comment,
    Cell: ({ value }: { value: WorkerComment }): React.ReactElement => (
      <ObjectHistory object={value} format={ObjectHistoryFormat.Name} />
    ),
  },
  {
    id: 'createdAt',
    Header: 'Date',
    accessor: (comment: WorkerComment): WorkerComment => comment,
    Cell: ({ value }: { value: WorkerComment }): React.ReactElement => (
      <ObjectHistory object={value} format={ObjectHistoryFormat.Time} />
    ),
  },
];
