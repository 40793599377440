import React from 'react';
import { useGetJobsiteContainerJobsiteInfoQuery } from 'apollo/generated/client-operations';
import { InfoList } from 'components/infoList';
import { InfoRow } from 'components/infoRow';
import { LoadingError } from 'components/loadingError';
import { JobsiteTabProps } from 'containers/jobsite/types';

import { getInfoRows } from './utils';

export function JobsiteInfoTabContainer(props: JobsiteTabProps): React.ReactElement {
  const { jobsiteId, onTabApiChange } = props;
  const { data, loading, error, refetch } = useGetJobsiteContainerJobsiteInfoQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      jobsiteId,
    },
  });

  const jobsite = data?.getJobsite;
  const jobsiteInfoRows = getInfoRows(jobsite);

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <InfoList noEdit>
      {jobsiteInfoRows.map((row) => (
        <InfoRow key={row.label} loading={loading} {...row} />
      ))}
    </InfoList>
  );
}
