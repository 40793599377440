import React, { ReactElement, useState } from 'react';
import { Col } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  JobsiteWorker,
  MutationUpdateJobsiteWorkerArgs,
  MutationUploadSingleFileArgs,
  AdditionalFieldValue,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { AuthContext } from 'auth';
import { getCurrentISOFormattedDateTime, getISOFormattedDateFromCleave } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import {
  getCurrentISOFormattedDate,
  isServerFile,
  uploadCompressedFile,
  useDeferredFormSubmission,
  useIsMounted,
} from 'utils';
import { DocumentKey } from 'containers/worker/utils';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import {
  CREATE_JOBSITE_WORKER_DOCUMENT,
  CREATE_JOBSITE_WORKER_DOCUMENT_VERSION,
  FILE_UPLOAD,
  UPDATE_JOBSITE_WORKER,
} from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { SiteSafetyTrainingDataType, OnboardingStepProps, UploadFileResponse } from 'containers/workerOnboarding/types';
import { StepLoading } from './StepLoading';
import { getDefaultValues, getFormInputsHook } from './SiteSpecificTrainingStep.forms';

const documentKeys = [
  DocumentKey.SiteSpecificOrientation,
  DocumentKey.JobsiteSafetyVideo,
  DocumentKey.JobsiteSafetyDocument,
  DocumentKey.WorkerConsentDocument,
  DocumentKey.AdditionalCertifications,
];

export function SiteSpecificTrainingStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<SiteSafetyTrainingDataType>();

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments();
      AlertInstance.alert('tc', 'success', 'Successfully updated', null);
    },
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT, {
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT_VERSION);

  const onSubmit: FormOnSubmit<SiteSafetyTrainingDataType> = async (data, _event, dirtyFields): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      const dirtyFieldsKeys = Object.keys(dirtyFields);

      await Promise.all(
        documentKeys.map(async (documentKey: string) => {
          const isDirty = dirtyFieldsKeys.some((val) => val.includes(documentKey));
          if (!isDirty) return;

          const additionalFieldValues: (AdditionalFieldValue & { type: string })[] = [];

          additionalFieldValues.push({
            key: 'orientation-date',
            value: getCurrentISOFormattedDate(),
            type: 'string',
          });

          const acknowledgmentProgressFractionRaw = data[`${documentKey}-acknowledgment-progress-fraction`] as string;
          if (acknowledgmentProgressFractionRaw) {
            additionalFieldValues.push({
              key: 'acknowledgment-progress-fraction',
              value: acknowledgmentProgressFractionRaw,
              type: 'string',
            });
          }

          const acknowledgmentConfirmationRaw = data[`${documentKey}-acknowledgment-status`];
          if (acknowledgmentConfirmationRaw) {
            const acknowledgmentConfirmation = acknowledgmentConfirmationRaw ? 'confirmed' : null;
            additionalFieldValues.push({
              key: 'acknowledgment-status',
              value: acknowledgmentConfirmation,
              type: 'string',
            });
          }

          const acknowledgmentDateRaw = data[`${documentKey}-acknowledgment-date`] as string;
          if (acknowledgmentDateRaw) {
            const acknowledgmentDate = getISOFormattedDateFromCleave(acknowledgmentDateRaw);
            additionalFieldValues.push({
              key: 'acknowledgment-date',
              value: acknowledgmentDate,
              type: 'date',
            });
          }

          const files = data[`${documentKey}-front`] as Array<File | ServerFile>;
          const fileIds = files?.length
            ? await Promise.all(
                files.map(async (file: File | ServerFile): Promise<string> => {
                  if (isServerFile(file)) {
                    return file.fileId;
                  }
                  return uploadCompressedFile(file, uploadFile);
                }),
              )
            : [];

          const jobsiteWorkerDocumentId = documents.find(({ key }) => key === documentKey)?.id;

          if (jobsiteWorkerDocumentId) {
            await createJobsiteWorkerDocumentVersion({
              variables: {
                jobsiteWorkerDocumentId,
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues,
                },
              },
            });
          } else {
            await createJobsiteWorkerDocument({
              variables: {
                jobsiteWorkerDocumentInput: {
                  jobsiteWorkerId,
                  jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[documentKey],
                  key: documentKey,
                },
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues,
                },
              },
            });
          }
        }),
      );

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            stickerNumber: parseInt(data?.stickerNumber, 10),
            siteSpecificTrainingCompletedAt: getCurrentISOFormattedDateTime(),
            siteSpecificTrainingSkipReason: null,
          },
        },
      });

      deferredSubmission.resolve(true);
    } catch (error) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    }
  };

  const overrideComplete = (reason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          siteSpecificTrainingCompletedAt: getCurrentISOFormattedDateTime(),
          siteSpecificTrainingSkipReason: reason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          siteSpecificTrainingCompletedAt: null,
          siteSpecificTrainingSkipReason: null,
        },
      },
    });
  };

  const inputs = getFormInputsHook({
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
  });

  const defaultValues = React.useMemo(
    () => getDefaultValues(jobsiteWorker, defaultFormValues),
    [defaultFormValues, jobsiteWorker],
  );

  const loading = parentLoading || fetching;

  return (
    <>
      <Col xs="12">
        <fieldset disabled={!!isContractorMember}>
          <Header title="Site specific orientation" />
          <StepLoading loading={loading} />
          <Form
            inputs={inputs}
            ref={formRef}
            defaultValues={defaultValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
          />
        </fieldset>
      </Col>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        showExemptionReason
        hideLockSession
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
      />
    </>
  );
}
