import classNames from 'classnames/bind';
import { Status } from 'components/status/Status';
import React, { ReactElement } from 'react';
import { getFormattedDate } from 'utils';
import { convertUTCtoLocalDate } from 'utils/dates';
import { JobsiteWorkerAccessComponentTypes } from './types';
import * as styles from './scss/jobsite-worker-access-component.scss';

const cn = classNames.bind(styles);

export function JobsiteWorkerAccessComponent(props: JobsiteWorkerAccessComponentTypes): ReactElement {
  const { jobsiteWorker, type, showEndDate } = props;
  const { endDate, isAllowed } = jobsiteWorker?.currentAccess ?? {};

  const siteAccessText = showEndDate ? `Allowed until ${getFormattedDate(convertUTCtoLocalDate(endDate))}` : 'Allowed';

  switch (type) {
    case 'badge':
      return (
        <div
          className={cn('my-3 access-button', isAllowed ? 'access-button__allowed' : 'access-button__disabled')}
          color={isAllowed ? 'success' : 'danger'}
        >
          {isAllowed ? (
            <>
              <i className="far fa-check worker-access__icon--status-success mr-2 text-white" />
              Allowed on Site
            </>
          ) : (
            <>
              <i className="far fa-exclamation-triangle worker-access__icon--status-error mr-2 text-white" />
              Not Allowed on Site
            </>
          )}
        </div>
      );
    case 'status':
      return (
        <Status label={isAllowed ? siteAccessText : 'Not Allowed on Site'} status={isAllowed ? 'success' : 'danger'} />
      );
    default:
      return null;
  }
}
