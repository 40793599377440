import React, { ReactElement, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  GetWorkerDataDocument,
  MutationUploadSingleFileArgs,
  MutationUpdateWorkerArgs,
  Worker,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { FILE_UPLOAD, UPDATE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import { UploadFileResponse } from 'containers/workerOnboarding/types';
import { uploadCompressedFile, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';

import { BaseModal } from 'components/modals';
import { Dropzone } from 'components/dropzone';
import { AlertInstance } from 'components/alertNotification';
import { LoadingError } from 'components/loadingError';
import { UpdateProfilePictureModalContainerProps } from './types';

export function UpdateProfilePictureModalContainer({
  worker,
  isOpen = false,
  onCancel,
  onAction,
}: UpdateProfilePictureModalContainerProps): ReactElement {
  const isMounted = useIsMounted();
  const initialProfilePictureFile = worker?.profilePictureCropped;
  const [profilePictureFile, setProfilePictureFile] = useState<File | ServerFile>(initialProfilePictureFile);
  const [fetching, setFetching] = useState<boolean>(false);
  const profilePictureUrlRef = useRef<string>();

  // this modal doesn't unmount when closed, so we need
  // to reset profilePictureFile every time the modal opens
  // if the profilePictureFile was altered by user
  React.useEffect(() => {
    if (isOpen && profilePictureFile !== initialProfilePictureFile) {
      setProfilePictureFile(initialProfilePictureFile);
    }
  }, [isOpen]);

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onCompleted: (data) => {
      profilePictureUrlRef.current = data.uploadSingleFile.downloadUrl;
    },
    onError: (error) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  const [updateWorker] = useMutation<Worker, MutationUpdateWorkerArgs>(UPDATE_WORKER, {
    onCompleted: () => {
      if (isMounted()) {
        setFetching(false);
      }
    },
    onError: (error) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: [GetWorkerDataDocument],
  });

  function isFile(file: File | ServerFile): file is File {
    return !!(file as File)?.name;
  }

  const onConfirm = async (): Promise<void> => {
    if (isFile(profilePictureFile)) {
      setFetching(true);
      const fileId = await uploadCompressedFile(profilePictureFile, uploadFile);
      await updateWorker({
        variables: {
          workerId: worker.workerId,
          workerInput: {
            profilePictureFileId: fileId,
          },
        },
      });
      onAction(profilePictureUrlRef.current);
    }
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onAction={onConfirm}
      toggle={onCancel}
      onCancel={onCancel}
      actionButtonEnabled={isFile(profilePictureFile) && !fetching}
    >
      {fetching ? (
        <LoadingError loading />
      ) : (
        <>
          <h2>Update profile photo</h2>
          <Dropzone
            name="profilePicture"
            label="Profile Picture"
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
            value={profilePictureFile ? [profilePictureFile] : null}
            onChange={(files): void => {
              const [file = null] = files ?? [];
              setProfilePictureFile(file);
            }}
          />
        </>
      )}
    </BaseModal>
  );
}
