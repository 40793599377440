import { addTranslations } from 'utils/localization';

/* eslint-disable max-len */
export enum Copy {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  LOGOUT_OF_ODIN = 'LOGOUT_OF_ODIN',
  SIGNUP = 'SIGNUP',
  FORGOT = 'FORGOT',
  EMAIL = 'EMAIL',
  WELCOME = 'WELCOME',
  PASSWORD = 'PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_OF_USE = 'TERMS_OF_USE',
  CHOOSE_LANGUAGE = 'CHOOSE_LANGUAGE',
  SETTINGS = 'SETTINGS',
  ABOUT = 'ABOUT',
  BACK = 'BACK',
  RESOURCES = 'RESOURCES',
  RESULTS = 'RESULTS',
  HINTS = 'HINTS',
  TEST = 'TEST',
  CHOOSE_JOBSITE = 'CHOOSE_JOBSITE',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  CONTINUE = 'CONTINUE',
  BIRTH_DATE = 'BIRTH_DATE',
  PREFERRED_LANGUAGE = 'PREFERRED_LANGUAGE',
  LAST_FOUR_SSN = 'LAST_FOUR_SSN',
  RESET_EMAIL = 'RESET_EMAIL',
  AGREEMENT_SIGNUP = 'AGREEMENT_SIGNUP',
  HEALTH_AND_WELLNESS_CHECK = 'HEALTH_AND_WELLNESS_CHECK',
  HELMET_BADGE_ID = 'HELMET_BADGE_ID',
  NEED_HELP = 'NEED_HELP',
  PASSWORD_HINT = 'PASSWORD_HINT',
  ALREADY_HAVE_ACCOUNT = 'ALREADY_HAVE_ACCOUNT',
  LOG_IN = 'LOG_IN',
  AND = 'AND',
  MY_PROFILE = 'MY_PROFILE',
  VERSION = 'VERSION',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CURRENT_JOBSITE = 'CURRENT_JOBSITE',
  INVALID_INPUT = 'INVALID_INPUT',
  SUBMIT = 'SUBMIT',
  LOGIN_ERROR_MESSAGE = 'LOGIN_ERROR_MESSAGE',
  NETWORK_ERROR_MESSAGE = 'NETWORK_ERROR_MESSAGE',
  UNKNOWN_ERROR_MESSAGE = 'UNKNOWN_ERROR_MESSAGE',
  CHOOSE_YOUR_JOBSITE = 'CHOOSE_YOUR_JOBSITE',
  SAVE = 'SAVE',
  SAVE_CHANGES = 'SAVE_CHANGES',
  PLEASE_SELECT_JOBSITE = 'PLEASE_SELECT_JOBSITE',
  PLEASE_SELECT_LANGUAGE = 'PLEASE_SELECT_LANGUAGE',
  SITE_CONTACT = 'SITE_CONTACT',
  RESOURCES_ISSUE = 'RESOURCES_ISSUE',
  RESOURCES_HELP = 'RESOURCES_HELP',
  ENTER_SITE = 'ENTER_SITE',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PHONE = 'INVALID_PHONE',
  INVALID_INPUT_FIELDS = 'INVALID_INPUT_FIELDS',
  LOGIN_INSTEAD_MESSAGE = 'LOGIN_INSTEAD_MESSAGE',
  LOGIN_INSTEAD_TITLE = 'LOGIN_INSTEAD_TITLE',
  INVALID_PASSWORD_INPUT = 'INVALID_PASSWORD_INPUT',
  PASSWORD_DONT_MATCH = 'PASSWORD_DONT_MATCH',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET',
  ENTER_VALID_EMAIL = 'ENTER_VALID_EMAIL',
  PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT',
  COMPLETED = 'COMPLETED',
  NEEDS_TO_BE_FILLED_OUT = 'NEEDS_TO_BE_FILLED_OUT',
  CONTACT_US = 'CONTACT_US',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  NEW_ANNOUNCEMENTS = 'NEW_ANNOUNCEMENTS',
  YOU_WILL_BE_ELIGIBLE = 'YOU_WILL_BE_ELIGIBLE',
  NO_ANNOUNCEMENTS = 'NO_ANNOUNCEMENTS',
  PLEASE_CHECK_BACK = 'PLEASE_CHECK_BACK',
  HOME = 'HOME',
  ACCESS = 'ACCESS',
  JOBSITES = 'JOBSITES',
  JOBSITE = 'JOBSITE',
  YOUR_QUICK_CODE = 'YOUR_QUICK_CODE',
  SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE',
  ACCOUNT_VERIFICATION_PREV_WORKER_TITLE = 'ACCOUNT_VERIFICATION_PREV_WORKER_TITLE',
  ACCOUNT_VERIFICATION_PREV_WORKER_TEXT = 'ACCOUNT_VERIFICATION_PREV_WORKER_TEXT',
  ACCOUNT_VERIFICATION_NO_PREV_WORKER_TITLE = 'ACCOUNT_VERIFICATION_NO_PREV_WORKER_TITLE',
  ACCOUNT_VERIFICATION_NO_PREV_WORKER_TEXT = 'ACCOUNT_VERIFICATION_NO_PREV_WORKER_TEXT',
  ACCOUNT_VERIFICATION_CONTINUE = 'ACCOUNT_VERIFICATION_CONTINUE',
  NO_PHONE_MATCH_TITLE = 'NO_PHONE_MATCH_TITLE',
  NO_PHONE_MATCH_TEXT = 'NO_PHONE_MATCH_TEXT',
  ENTER_RECEIVED_CODE = 'ENTER_RECEIVED_CODE',
  CONFIRM_LOGIN_DETAILS = 'CONFIRM_LOGIN_DETAILS',
  CONFIRM_LOGIN_REQUEST_ANOTHER_CODE = 'CONFIRM_LOGIN_REQUEST_ANOTHER_CODE',
  COMPLETE_PROFILE_PROVIDE_INFORMATION = 'COMPLETE_PROFILE_PROVIDE_INFORMATION',
  COMPLETE_PROFILE_MISSING_DATA_DIALOG_HEADER = 'COMPLETE_PROFILE_MISSING_DATA_DIALOG_HEADER',
  COMPLETE_PROFILE_MISSING_DATA_DIALOG_TEXT = 'COMPLETE_PROFILE_MISSING_DATA_DIALOG_TEXT',
  ADDRESS = 'ADDRESS',
  ADDRESS_2 = 'ADDRESS_2',
  APT_UNIT = 'APT_UNIT',
  CITY = 'CITY',
  STATE = 'STATE',
  ZIP = 'ZIP',
  PHONE_NUMBER = 'PHONE_NUMBER',
  LOGIN_PHONE_NUMBER = 'LOGIN_PHONE_NUMBER',
  EMERGENCY_CONTACT = 'EMERGENCY_CONTACT',
  NAME = 'NAME',
  RELATIONSHIP = 'RELATIONSHIP',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  SKIP_STEP = 'SKIP_STEP',
  WELCOME_TO_ODIN = 'WELCOME_TO_ODIN',
  ENTER_INFO_TO_GET_STARTED = 'ENTER_INFO_TO_GET_STARTED',
  DASHBOARD_WELCOME_TO_ODIN_TEXT = 'DASHBOARD_WELCOME_TO_ODIN_TEXT',
  DASHBOARD_JOBSITE_ONBOARDING_TITLE = 'DASHBOARD_JOBSITE_ONBOARDING_TITLE',
  DASHBOARD_JOBSITE_ONBOARDING_TEXT = 'DASHBOARD_JOBSITE_ONBOARDING_TEXT',
  DASHBOARD_JOBSITE_ONBOARDING_BOTTOM = 'DASHBOARD_JOBSITE_ONBOARDING_BOTTOM',
  DRIVERS_LICENSE_OR_GOVERNMENT_ISSUED_ID = 'DRIVERS_LICENSE_OR_GOVERNMENT_ISSUED_ID',
  OSHA_CARD = 'OSHA_CARD',
  STATE_OR_LOCAL_SAFETY_CERTIFICATION = 'STATE_OR_LOCAL_SAFETY_CERTIFICATION',
  OTHER_CERTIFICATIONS = 'OTHER_CERTIFICATIONS',
  PASSWORDLESS_LOG_IN_TITLE = 'PASSWORDLESS_LOG_IN_TITLE',
  PASSWORDLESS_LOG_IN_TEXT = 'PASSWORDLESS_LOG_IN_TEXT',
  FIRST_TIME_USING_ODIN = 'FIRST_TIME_USING_ODIN',
  GET_STARTED = 'GET_STARTED',
  NETWORK_ERROR_TITLE = 'NETWORK_ERROR_TITLE',
  NETWORK_ERROR_TEXT = 'NETWORK_ERROR_TEXT',
  NETWORK_ERROR_LINK_TEXT = 'NETWORK_ERROR_LINK_TEXT',
  LOCATION_ERROR_TITLE = 'LOCATION_ERROR_TITLE',
  LOCATION_ERROR_TEXT = 'LOCATION_ERROR_TEXT',
  LOCATION_ERROR_LINK_TEXT = 'LOCATION_ERROR_LINK_TEXT',
  NO_JOBSITE_ACCESS_TITLE = 'NO_JOBSITE_ACCESS_TITLE',
  NO_JOBSITE_ACCESS_TEXT = 'NO_JOBSITE_ACCESS_TEXT',
  NO_ACCESS_HISTORY_TITLE = 'NO_ACCESS_HISTORY_TITLE',
  NO_ACCESS_HISTORY_TEXT = 'NO_ACCESS_HISTORY_TEXT',
  NO_JOBSITES_TITLE = 'NO_JOBSITES_TITLE',
  NO_JOBSITES_TEXT = 'NO_JOBSITES_TEXT',
  NO_RESULTS_TITLE = 'NO_RESULTS_TITLE',
  NO_RESULTS_TEXT = 'NO_RESULTS_TEXT',
  ACCESS_HISTORY = 'ACCESS_HISTORY',
  CLOCK_IN = 'CLOCK_IN',
  CLOCK_OUT = 'CLOCK_OUT',
  CLOCK_IN_TO = 'CLOCK_IN_TO',
  CLOCK_OUT_OF = 'CLOCK_OUT_OF',
  CLOCK_IN_OUT_POPUP_HEADER = 'CLOCK_IN_OUT_POPUP_HEADER',
  CLOCK_INTO_ANOTHER_JOBSITE = 'CLOCK_INTO_ANOTHER_JOBSITE',
  CONTACT_INFO = 'CONTACT_INFO',
  EDIT_CONTACT_INFO = 'EDIT_CONTACT_INFO',
  PHONE = 'PHONE',
  EDIT_EMERGENCY_CONTACT_INFO = 'EDIT_EMERGENCY_CONTACT_INFO',
  HAVE_A_NEW_PHONE_NUMBER_TITLE = 'HAVE_A_NEW_PHONE_NUMBER_TITLE',
  HAVE_A_NEW_PHONE_NUMBER_TEXT = 'HAVE_A_NEW_PHONE_NUMBER_TEXT',
  CONTRACTOR = 'CONTRACTOR',
  TRADE = 'TRADE',
  TRADE_CLASS = 'TRADE_CLASS',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  UPDATE_YOUR_JOBSITE_STATUS = 'UPDATE_YOUR_JOBSITE_STATUS',
  PLEASE_CHOOSE = 'PLEASE_CHOOSE',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  CANCEL = 'CANCEL',
  UPDATE_STATUS = 'UPDATE_STATUS',
  SITE_ACCESS_EXPIRES_AT = 'SITE_ACCESS_EXPIRES_AT',
  GLOBAL_BAN = 'GLOBAL_BAN',
  UNTIL = 'UNTIL',
  BANNED = 'BANNED',
  PERMANENTLY = 'PERMANENTLY',
  ALLOWED_ON_SITE = 'ALLOWED_ON_SITE',
  NOT_ALLOWED_ON_SITE = 'NOT_ALLOWED_ON_SITE',
  TO_COMPLETE = 'TO_COMPLETE',
  SUGGESTED_ACTION = 'SUGGESTED_ACTION',
  BASED_ON_YOUR_LOCATION = 'BASED_ON_YOUR_LOCATION',
  APP_VERSION_CHECKER_TITLE = 'APP_VERSION_CHECKER_TITLE',
  APP_VERSION_CHECKER_SUBTITLE = 'APP_VERSION_CHECKER_SUBTITLE',
  APP_VERSION_CHECKER_BUTTON_TEXT = 'APP_VERSION_CHECKER_BUTTON_TEXT',
  REQUIRED = 'REQUIRED',
  SELECT_ONE = 'SELECT_ONE',
  EMERGENCY_CONTACT_PHONE_NUMBER = 'EMERGENCY_CONTACT_PHONE_NUMBER',
  OPTIONAL = 'OPTIONAL',
  MIDDLE_INITIAL = 'MIDDLE_INITIAL',
  CITIZENSHIP_STATUS = 'CITIZENSHIP_STATUS',
  VETERAN_STATUS = 'VETERAN_STATUS',
  JOB_TITLE_CLASS = 'JOB_TITLE_CLASS',
  JOB_TITLE = 'JOB_TITLE',
  UNION_AFFILIATION = 'UNION_AFFILIATION',
  ZIP_CODE_5_DIGITS = 'ZIP_CODE_5_DIGITS',
  CITIZEN = 'CITIZEN',
  NON_CITIZEN = 'NON_CITIZEN',
  VETERAN = 'VETERAN',
  NON_VETERAN = 'NON_VETERAN',
  GENDER = 'GENDER',
  RACE = 'RACE',
  RACE_OPTION_ASIAN_NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER = 'race_option_asian_native_hawaiian_or_pacific_islander',
  RACE_OPTION_BLACK_AFRICAN_AMERICAN = 'race_option_black_african_american',
  RACE_OPTION_NATIVE_AMERICAN_ALASKAN_NATIVE = 'race_option_native_american_alaskan_native',
  RACE_OPTION_HISPANIC_LATINO = 'race_option_hispanic_latino',
  RACE_OPTION_WHITE = 'race_option_white',
  RACE_OPTION_OTHER_PREFER_NOT_TO_RESPOND = 'race_option_other_prefer_not_to_respond',
  SSN_4_DIGIT = 'SSN_4_DIGIT',
  INVALID_DATE = 'INVALID_DATE',
  SUFFIX = 'SUFFIX',
}

const en = {
  [Copy.LOGIN]: 'Login',
  [Copy.LOGOUT]: 'Logout',
  [Copy.LOGOUT_OF_ODIN]: 'Log Out of Odin',
  [Copy.SIGNUP]: 'Sign Up',
  [Copy.FORGOT]: 'Forgot',
  [Copy.EMAIL]: 'Email',
  [Copy.WELCOME]: 'Hello',
  [Copy.PASSWORD]: 'Password',
  [Copy.FORGOT_PASSWORD]: 'Forgot Password?',
  [Copy.PRIVACY_POLICY]: 'Privacy Policy',
  [Copy.TERMS_OF_USE]: 'Terms of Use',
  [Copy.CHOOSE_LANGUAGE]: 'Choose Language',
  [Copy.SETTINGS]: 'Settings',
  [Copy.ABOUT]: 'About',
  [Copy.BACK]: 'Back',
  [Copy.RESOURCES]: 'Resources',
  [Copy.RESULTS]: 'Results',
  [Copy.HINTS]: 'Hints',
  [Copy.TEST]: 'Answer Every Question',
  [Copy.CHOOSE_JOBSITE]: 'Choose Jobsite',
  [Copy.FIRST_NAME]: 'First Name',
  [Copy.LAST_NAME]: 'Last Name',
  [Copy.CONFIRM_PASSWORD]: 'Confirm password',
  [Copy.CONTINUE]: 'Continue',
  [Copy.BIRTH_DATE]: 'Date of Birth',
  [Copy.PREFERRED_LANGUAGE]: 'Preferred Language',
  [Copy.LAST_FOUR_SSN]: 'Last four digits of your Social Security Number',
  [Copy.RESET_EMAIL]: 'Email address',
  [Copy.AGREEMENT_SIGNUP]: 'By creating an account you agree to both our',
  [Copy.HEALTH_AND_WELLNESS_CHECK]: 'Wellness daily check',
  [Copy.HELMET_BADGE_ID]: 'Helmet Badge Id (optional)',
  [Copy.NEED_HELP]: 'Need help?',
  [Copy.PASSWORD_HINT]: 'Password must contain at least 8 characters with at least one number and one capital letter.',
  [Copy.ALREADY_HAVE_ACCOUNT]: 'Already have an account?',
  [Copy.LOG_IN]: 'Log In',
  [Copy.AND]: 'And',
  [Copy.MY_PROFILE]: 'Profile',
  [Copy.VERSION]: 'Version',
  [Copy.SUCCESS]: 'Success',
  [Copy.ERROR]: 'Error',
  [Copy.CURRENT_JOBSITE]: 'Current Jobsite',
  [Copy.INVALID_INPUT]: 'Invalid input',
  [Copy.SUBMIT]: 'Submit',
  [Copy.LOGIN_ERROR_MESSAGE]: 'Provided code is incorrect, please try again.',
  [Copy.NETWORK_ERROR_MESSAGE]:
    'There seems to be some technical issues. Please check your network connectivity and try again.',
  [Copy.UNKNOWN_ERROR_MESSAGE]: 'There seems to be some technical issues.',
  [Copy.CHOOSE_YOUR_JOBSITE]: 'Choose your jobsite',
  [Copy.SAVE]: 'Save',
  [Copy.SAVE_CHANGES]: 'Save Changes',
  [Copy.PLEASE_SELECT_JOBSITE]: 'Please select your jobsite',
  [Copy.PLEASE_SELECT_LANGUAGE]: 'Please select language',
  [Copy.SITE_CONTACT]: 'Site Contact',
  [Copy.RESOURCES_ISSUE]:
    'Email us with your questions or issues and we’ll get back to you ASAP.  If your issue is urgent, please see the security manager for immediate assistance.',
  [Copy.RESOURCES_HELP]:
    'Whether you are having a problem with the daily Health & wellness checklist or have a question about the jobsite, we are here to help.',
  [Copy.ENTER_SITE]: 'You can enter the site',
  [Copy.INVALID_EMAIL]: 'Invalid email address, please enter a valid email address.',
  [Copy.INVALID_PHONE]: 'Invalid phone number, please enter a valid phone number.',
  [Copy.INVALID_INPUT_FIELDS]: 'Invalid input, please fill out all fields',
  [Copy.LOGIN_INSTEAD_TITLE]: 'Looks like you already have an ODIN account.',
  [Copy.LOGIN_INSTEAD_MESSAGE]: 'Did you mean to login instead?',
  [Copy.INVALID_PASSWORD_INPUT]:
    'Invalid input, passwords must be at least 8 characters with at least one number and one capital letter.',
  [Copy.PASSWORD_DONT_MATCH]: 'Passwords do not match!',
  [Copy.SOMETHING_WENT_WRONG]: 'Something went wrong',
  [Copy.SEND_PASSWORD_RESET]: 'Send Password Reset',
  [Copy.ENTER_VALID_EMAIL]: 'Please enter a valid email address.',
  [Copy.PASSWORD_RESET_SENT]: 'A password reset email has been sent to the email address provided below.',
  [Copy.COMPLETED]: 'Completed',
  [Copy.NEEDS_TO_BE_FILLED_OUT]: 'Needs to be filled out',
  [Copy.CONTACT_US]: 'Contact Us',
  [Copy.ANNOUNCEMENTS]: 'Announcements',
  [Copy.ANNOUNCEMENT]: 'Announcement',
  [Copy.NEW_ANNOUNCEMENTS]: 'New announcements',
  [Copy.YOU_WILL_BE_ELIGIBLE]: 'You will be eligible to fill out the checklist on',
  [Copy.NO_ANNOUNCEMENTS]: 'No announcements at the moment',
  [Copy.PLEASE_CHECK_BACK]: 'Please check back later',
  [Copy.HOME]: 'Dashboard',
  [Copy.ACCESS]: 'Access',
  [Copy.JOBSITES]: 'Jobsites',
  [Copy.JOBSITE]: 'Jobsite',
  [Copy.YOUR_QUICK_CODE]: 'Your quick code',
  [Copy.SEND_VERIFICATION_CODE]: 'Send Verification Code',
  [Copy.ACCOUNT_VERIFICATION_PREV_WORKER_TITLE]: 'It looks like you’ve worked with Odin before!',
  [Copy.ACCOUNT_VERIFICATION_PREV_WORKER_TEXT]:
    'Based on the information you provided, you may already have an Odin profile. Please confirm your phone number below to connect to your account.',
  [Copy.ACCOUNT_VERIFICATION_NO_PREV_WORKER_TITLE]: 'Just one more step...',
  [Copy.ACCOUNT_VERIFICATION_NO_PREV_WORKER_TEXT]:
    'To complete your registration, we’ll need to verify your mobile phone number. We’ll send you a unique code to ensure your account stays secure.',
  [Copy.ACCOUNT_VERIFICATION_CONTINUE]: 'To continue, please provide your phone number',
  [Copy.NO_PHONE_MATCH_TITLE]: 'Unfortunately, we were unable to verify your phone number.',
  [Copy.NO_PHONE_MATCH_TEXT]:
    'Please let the site safety manager know that you’re having issues setting up your Odin account. Please make sure you have a valid mobile number associated with your account.',
  [Copy.ENTER_RECEIVED_CODE]: 'Please enter the code you received',
  [Copy.CONFIRM_LOGIN_DETAILS]:
    'You should have received a 6 digit code to the phone number you provided. If you haven’t received the code after a few minutes, you can',
  [Copy.CONFIRM_LOGIN_REQUEST_ANOTHER_CODE]: 'request another code here.',
  [Copy.COMPLETE_PROFILE_PROVIDE_INFORMATION]: 'To complete your profile, please provide the following information',
  [Copy.COMPLETE_PROFILE_MISSING_DATA_DIALOG_HEADER]: 'Are you sure you want to continue?',
  [Copy.COMPLETE_PROFILE_MISSING_DATA_DIALOG_TEXT]:
    'It looks like some profile information is missing. We recommend filling everything out to avoid issues and save time on site.',
  [Copy.ADDRESS]: 'Address',
  [Copy.ADDRESS_2]: 'Address 2',
  [Copy.APT_UNIT]: 'Apt/Unit',
  [Copy.CITY]: 'City',
  [Copy.STATE]: 'State',
  [Copy.ZIP]: 'Zip code',
  [Copy.PHONE_NUMBER]: 'Mobile Phone',
  [Copy.LOGIN_PHONE_NUMBER]: 'Login Phone Number',
  [Copy.EMERGENCY_CONTACT]: 'Emergency Contact',
  [Copy.EMERGENCY_CONTACT_PHONE_NUMBER]: 'Emergency Contact Phone Number',
  [Copy.NAME]: 'Name',
  [Copy.RELATIONSHIP]: 'Emergency Contact Relationship',
  [Copy.EMAIL_ADDRESS]: 'Email',
  [Copy.COMPLETE_PROFILE]: 'Complete Profile',
  [Copy.SKIP_STEP]: 'Skip this step',
  [Copy.WELCOME_TO_ODIN]: 'Welcome to Odin!',
  [Copy.ENTER_INFO_TO_GET_STARTED]: 'Please enter your information below to get started.',
  [Copy.DASHBOARD_WELCOME_TO_ODIN_TEXT]:
    'Below you’ll find your dashboard, where we show your assignments, announcements, and more.',
  [Copy.DASHBOARD_JOBSITE_ONBOARDING_TITLE]: 'Before you get to the jobsite',
  [Copy.DASHBOARD_JOBSITE_ONBOARDING_TEXT]:
    'Thanks for creating an Odin account! Your account will be completed when you go to the jobsite for onboarding. To make sure things go smoothly, please remember to bring the following with you:',
  [Copy.DASHBOARD_JOBSITE_ONBOARDING_BOTTOM]:
    'Please note that some job sites also require a drug test be taken as part of onboarding.',
  [Copy.DRIVERS_LICENSE_OR_GOVERNMENT_ISSUED_ID]: 'Drivers License or government issued ID',
  [Copy.OSHA_CARD]: 'OSHA Card',
  [Copy.STATE_OR_LOCAL_SAFETY_CERTIFICATION]: 'State or local safety certification',
  [Copy.OTHER_CERTIFICATIONS]: 'Any other certifications relevant to your job',
  [Copy.PASSWORDLESS_LOG_IN_TITLE]: 'Welcome back!',
  [Copy.PASSWORDLESS_LOG_IN_TEXT]:
    'To log in, please enter your mobile phone number and we’ll text you a unique one-time code.',
  [Copy.FIRST_TIME_USING_ODIN]: 'First time using Odin?',
  [Copy.GET_STARTED]: 'Get Started',
  [Copy.NETWORK_ERROR_TITLE]: 'We were unable to connect to the Odin servers',
  [Copy.NETWORK_ERROR_TEXT]: 'Please make sure your device is connected to the internet and try again.',
  [Copy.NETWORK_ERROR_LINK_TEXT]: 'Try Again',
  [Copy.LOCATION_ERROR_TITLE]: 'Please turn on location sharing',
  [Copy.LOCATION_ERROR_TEXT]: 'Odin uses your phone’s location to safely and securely verify your jobsite access.',
  [Copy.LOCATION_ERROR_LINK_TEXT]: 'Open Location Settings',
  [Copy.NO_JOBSITE_ACCESS_TITLE]: 'Sorry, none of your jobsites are using Odin Mobile Access',
  [Copy.NO_JOBSITE_ACCESS_TEXT]:
    'If you’re assigned to a new jobsite that allows mobile access, you’ll be able to clock in and out from here. No badge required!',
  [Copy.NO_ACCESS_HISTORY_TITLE]: 'No access history found',
  [Copy.NO_ACCESS_HISTORY_TEXT]:
    'Check back here once you’ve entered a jobsite to keep track of when you clocked in and out.',
  [Copy.NO_JOBSITES_TITLE]: 'We couldn’t find any active jobsite assignments',
  [Copy.NO_JOBSITES_TEXT]: 'Please check back here to see valuable information about all of your active Odin jobsites.',
  [Copy.NO_RESULTS_TITLE]: 'Looks like you’re all caught up!',
  [Copy.NO_RESULTS_TEXT]: 'Check back here later for assignments, announcements, documents, and more.',
  [Copy.ACCESS_HISTORY]: 'Access History',
  [Copy.CLOCK_IN]: 'Clock In',
  [Copy.CLOCK_OUT]: 'Clock Out',
  [Copy.CLOCK_IN_TO]: 'Clock in to',
  [Copy.CLOCK_OUT_OF]: 'Clock out of',
  [Copy.CLOCK_IN_OUT_POPUP_HEADER]: 'Which direction are you going?',
  [Copy.CLOCK_INTO_ANOTHER_JOBSITE]: 'Clock into Another Jobsite',
  [Copy.CONTACT_INFO]: 'Contact Info',
  [Copy.PHONE]: 'Phone',
  [Copy.EDIT_CONTACT_INFO]: 'Edit Contact Info',
  [Copy.EDIT_EMERGENCY_CONTACT_INFO]: 'Edit Emergency Contact Info',
  [Copy.HAVE_A_NEW_PHONE_NUMBER_TITLE]: 'Have a new phone number?',
  [Copy.HAVE_A_NEW_PHONE_NUMBER_TEXT]:
    'Please see your on site safety manager to request a phone number change. Make sure to bring the phone you’ll be using to log in, too.',
  [Copy.CONTRACTOR]: 'Contractor',
  [Copy.TRADE]: 'Trade',
  [Copy.TRADE_CLASS]: 'Trade class',
  [Copy.START_DATE]: 'Start date',
  [Copy.END_DATE]: 'End date',
  [Copy.UPDATE_YOUR_JOBSITE_STATUS]: 'Update your jobsite status',
  [Copy.PLEASE_CHOOSE]: 'Please choose',
  [Copy.TODAY]: 'Today',
  [Copy.YESTERDAY]: 'Yesterday',
  [Copy.CANCEL]: 'Cancel',
  [Copy.UPDATE_STATUS]: 'Update Status',
  [Copy.SITE_ACCESS_EXPIRES_AT]: 'Site access expires at',
  [Copy.GLOBAL_BAN]: 'Global Ban',
  [Copy.UNTIL]: 'until',
  [Copy.BANNED]: 'Banned',
  [Copy.PERMANENTLY]: 'permanently',
  [Copy.ALLOWED_ON_SITE]: 'Allowed on site',
  [Copy.NOT_ALLOWED_ON_SITE]: 'Not allowed on site',
  [Copy.TO_COMPLETE]: 'to complete',
  [Copy.SUGGESTED_ACTION]: 'Suggested Action',
  [Copy.BASED_ON_YOUR_LOCATION]: 'Based on your location',
  [Copy.APP_VERSION_CHECKER_TITLE]: 'Application version outdated',
  [Copy.APP_VERSION_CHECKER_SUBTITLE]: 'Touch here to be redirected to',
  [Copy.APP_VERSION_CHECKER_BUTTON_TEXT]: 'Redirect to store',
  [Copy.REQUIRED]: 'required',
  [Copy.SELECT_ONE]: 'Select one',
  [Copy.OPTIONAL]: '(optional)',
  [Copy.MIDDLE_INITIAL]: 'Middle initial',
  [Copy.CITIZENSHIP_STATUS]: 'Citizenship status',
  [Copy.VETERAN_STATUS]: 'Veteran status',
  [Copy.JOB_TITLE_CLASS]: 'Job title class',
  [Copy.JOB_TITLE]: 'Job title',
  [Copy.UNION_AFFILIATION]: 'Union affiliation',
  [Copy.ZIP_CODE_5_DIGITS]: 'ZIP code must be 5 digits',
  [Copy.CITIZEN]: 'Citizen',
  [Copy.NON_CITIZEN]: 'Non-Citizen',
  [Copy.VETERAN]: 'Veteran',
  [Copy.NON_VETERAN]: 'Non Veteran',
  [Copy.GENDER]: 'Gender',
  [Copy.RACE]: 'Race',
  [Copy.RACE_OPTION_ASIAN_NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER]: 'Asian/Native Hawaiian or Pacific Islander',
  [Copy.RACE_OPTION_BLACK_AFRICAN_AMERICAN]: 'Black/African American',
  [Copy.RACE_OPTION_NATIVE_AMERICAN_ALASKAN_NATIVE]: 'Native American/Alaskan Native',
  [Copy.RACE_OPTION_HISPANIC_LATINO]: 'Hispanic/Latino',
  [Copy.RACE_OPTION_WHITE]: 'White',
  [Copy.RACE_OPTION_OTHER_PREFER_NOT_TO_RESPOND]: 'Other/Prefer not to respond',
  [Copy.SSN_4_DIGIT]: 'Social security number must be 4 digits',
  [Copy.INVALID_DATE]: 'Must provide valid date.',
  [Copy.SUFFIX]: 'Suffix',
};

const es = {
  [Copy.LOGIN]: 'Iniciar Sesión',
  [Copy.LOGOUT]: 'Cerrar Sesión',
  [Copy.LOGOUT_OF_ODIN]: 'Salir de Odin',
  [Copy.SIGNUP]: 'Registrarse',
  [Copy.FORGOT]: 'Olvidar',
  [Copy.EMAIL]: 'Correo Electrónico',
  [Copy.WELCOME]: 'Hola',
  [Copy.PASSWORD]: 'Contraseña',
  [Copy.FORGOT_PASSWORD]: '¿Olvidó su Contraseña?',
  [Copy.PRIVACY_POLICY]: 'Política de Privacidad',
  [Copy.TERMS_OF_USE]: 'Términos de Uso',
  [Copy.CHOOSE_LANGUAGE]: 'Elegir Idioma',
  [Copy.SETTINGS]: 'Ajustes',
  [Copy.ABOUT]: 'Acerca de',
  [Copy.BACK]: 'Regresar',
  [Copy.RESOURCES]: 'Recursos',
  [Copy.RESULTS]: 'Resultados',
  [Copy.HINTS]: 'Consejos',
  [Copy.TEST]: 'Responda cada una de las preguntas',
  [Copy.CHOOSE_JOBSITE]: 'Elegir el sitio de trabajo',
  [Copy.FIRST_NAME]: 'Nombre',
  [Copy.LAST_NAME]: 'Apellido',
  [Copy.CONFIRM_PASSWORD]: 'Confirmar contraseña',
  [Copy.CONTINUE]: 'Continuar',
  [Copy.BIRTH_DATE]: 'Fecha de nacimiento',
  [Copy.PREFERRED_LANGUAGE]: 'Idioma preferido',
  [Copy.LAST_FOUR_SSN]: 'Últimos cuatro dígitos del número de su Seguro Social',
  [Copy.RESET_EMAIL]: 'Restablecer su correo electrónico',
  [Copy.AGREEMENT_SIGNUP]: 'Al crear una cuenta acepta nuestro',
  [Copy.HEALTH_AND_WELLNESS_CHECK]: 'Control diario de bienestar',
  [Copy.HELMET_BADGE_ID]: 'Id de Helmet Badge (opcional)',
  [Copy.NEED_HELP]: '¿Necesita ayuda?',
  [Copy.PASSWORD_HINT]:
    'La contraseña debe contener al menos 8 caracteres con al menos un número y una letra mayúscula.',
  [Copy.ALREADY_HAVE_ACCOUNT]: '¿Ya tiene una cuenta? Iniciar Sesión.',
  [Copy.LOG_IN]: 'Iniciar sesión',
  [Copy.AND]: 'Y',
  [Copy.MY_PROFILE]: 'Mi Perfil',
  [Copy.VERSION]: 'Versión',
  [Copy.SUCCESS]: 'Éxito',
  [Copy.ERROR]: 'Error',
  [Copy.CURRENT_JOBSITE]: 'Sitio de trabajo actual',
  [Copy.INVALID_INPUT]: 'Entrada inválida',
  [Copy.SUBMIT]: 'Enviar',
  [Copy.LOGIN_ERROR_MESSAGE]: 'El código proporcionado es incorrecto, por favor inténtalo de nuevo.',
  [Copy.NETWORK_ERROR_MESSAGE]:
    'Parece que hay algunos problemas técnicos. Por favor, verifica la conectividad de tu red e inténtalo de nuevo.',
  [Copy.UNKNOWN_ERROR_MESSAGE]: 'Parece que hay algunos problemas técnicos.',
  [Copy.CHOOSE_YOUR_JOBSITE]: 'Elegir el sitio de trabajo',
  [Copy.SAVE]: 'Guardar',
  [Copy.SAVE_CHANGES]: 'Guardar cambios',
  [Copy.PLEASE_SELECT_JOBSITE]: 'Seleccione su sitio de trabajo',
  [Copy.PLEASE_SELECT_LANGUAGE]: 'Por favor, selecciona el idioma',
  [Copy.SITE_CONTACT]: 'Contacto del Sitio',
  [Copy.RESOURCES_ISSUE]:
    'Envíenos un correo electrónico con su pregunta o problema y le responderemos tan pronto como sea posible. Si tiene una urgencia',
  [Copy.RESOURCES_HELP]:
    'Si tiene algún problema con la lista de verificación diaria de Bienestar y salud o tiene alguna duda sobre el sitio de trabajo',
  [Copy.ENTER_SITE]: 'Usted puede ingresar al sitio',
  [Copy.INVALID_EMAIL]: 'Dirección de correo electrónica inválida',
  [Copy.INVALID_INPUT_FIELDS]: 'Entrada inválida',
  [Copy.LOGIN_INSTEAD_TITLE]: 'Parece que ya tienes una cuenta ODIN.',
  [Copy.LOGIN_INSTEAD_MESSAGE]: '¿Quería iniciar sesión en lugar de ello?',
  [Copy.INVALID_PASSWORD_INPUT]: 'Entrada inválida',
  [Copy.PASSWORD_DONT_MATCH]: '¡Las contraseñas no coinciden!',
  [Copy.SOMETHING_WENT_WRONG]: 'Algó salió mal',
  [Copy.SEND_PASSWORD_RESET]: 'Reestalecimiento de contraseña enviado',
  [Copy.ENTER_VALID_EMAIL]: 'Ingrese un correo electrónico válido.',
  [Copy.PASSWORD_RESET_SENT]:
    'Se ha enviado un correo de restablecimiento de contraseña al correo electrónico proporcionado.',
  [Copy.COMPLETED]: 'Completo',
  [Copy.NEEDS_TO_BE_FILLED_OUT]: 'Necesita ser llenado',
  [Copy.CONTACT_US]: 'Contacta con nosotros',
  [Copy.ANNOUNCEMENTS]: 'Anuncios',
  [Copy.ANNOUNCEMENT]: 'Anuncio',
  [Copy.NEW_ANNOUNCEMENTS]: 'Nuevos anuncios',
  [Copy.YOU_WILL_BE_ELIGIBLE]: 'Usted será elegible para completar la lista de verificación en',
  [Copy.NO_ANNOUNCEMENTS]: 'No hay anuncios por el momento',
  [Copy.PLEASE_CHECK_BACK]: 'Por favor',
  [Copy.HOME]: 'Inicio',
  [Copy.ACCESS]: 'Acceso',
  [Copy.JOBSITES]: 'Sitios de trabajo',
  [Copy.JOBSITE]: 'Sitio de trabajo',
  [Copy.YOUR_QUICK_CODE]: 'Tu código rápido',
  [Copy.SEND_VERIFICATION_CODE]: 'Enviar código de verificación',
  [Copy.ACCOUNT_VERIFICATION_PREV_WORKER_TITLE]: 'Parece que ya has trabajado con Odin.',
  [Copy.ACCOUNT_VERIFICATION_PREV_WORKER_TEXT]:
    'En base a la información que has proporcionado, es posible que ya tengas un perfil de Odin. Por favor, confirma tu número de teléfono a continuación para conectarte a tu cuenta.',
  [Copy.ACCOUNT_VERIFICATION_NO_PREV_WORKER_TITLE]: 'Sólo un paso más...',
  [Copy.ACCOUNT_VERIFICATION_NO_PREV_WORKER_TEXT]:
    'Para completar el registro, necesitaremos verificar tu número de teléfono móvil. Te enviaremos un código único para garantizar la seguridad de tu cuenta.',
  [Copy.ACCOUNT_VERIFICATION_CONTINUE]: 'Para continuar, indica tu número de teléfono',
  [Copy.NO_PHONE_MATCH_TITLE]: 'Lamentablemente, no hemos podido verificar tu número de teléfono.',
  [Copy.NO_PHONE_MATCH_TEXT]:
    'Por favor, informa al gestor de seguridad del sitio de que estás teniendo problemas para configurar tu cuenta de Odin. Por favor, asegúrate de que tienes un número de móvil válido asociado a tu cuenta.',
  [Copy.ENTER_RECEIVED_CODE]: 'Por favor, introduce el código que has recibido',
  [Copy.CONFIRM_LOGIN_DETAILS]:
    'Deberías haber recibido un código de 6 dígitos en el número de teléfono que proporcionaste. Si no ha recibido el código después de unos minutos, puede',
  [Copy.CONFIRM_LOGIN_REQUEST_ANOTHER_CODE]: 'solicitar otro código aquí.',
  [Copy.COMPLETE_PROFILE_PROVIDE_INFORMATION]: 'Para completar su perfil, proporcione la siguiente información',
  [Copy.COMPLETE_PROFILE_MISSING_DATA_DIALOG_HEADER]: '¿Estás seguro de que quieres continuar?',
  [Copy.COMPLETE_PROFILE_MISSING_DATA_DIALOG_TEXT]:
    'Parece que falta alguna información del perfil. Le recomendamos que lo rellene todo para evitar problemas y ahorrar tiempo en el sitio.',
  [Copy.ADDRESS]: 'Dirección',
  [Copy.ADDRESS_2]: 'Dirección 2',
  [Copy.APT_UNIT]: 'Apartamento/Unidad',
  [Copy.CITY]: 'Ciudad',
  [Copy.STATE]: 'Estado',
  [Copy.ZIP]: 'Código postal',
  [Copy.PHONE_NUMBER]: 'Número de teléfono',
  [Copy.EMERGENCY_CONTACT]: 'Contacto de emergencia',
  [Copy.EMERGENCY_CONTACT_PHONE_NUMBER]: 'Número de teléfono de contacto de emergencia',
  [Copy.NAME]: 'Nombre',
  [Copy.RELATIONSHIP]: 'Relación de contacto de emergencia',
  [Copy.EMAIL_ADDRESS]: 'Dirección de correo electrónico',
  [Copy.COMPLETE_PROFILE]: 'Perfil completo',
  [Copy.SKIP_STEP]: 'Omitir este paso',
  [Copy.WELCOME_TO_ODIN]: '¡Bienvenido a Odin!',
  [Copy.ENTER_INFO_TO_GET_STARTED]: 'Por favor, introduce tu información a continuación para empezar.',
  [Copy.DASHBOARD_WELCOME_TO_ODIN_TEXT]:
    'A continuación encontrarás tu panel de control, donde mostramos tus asignaciones, anuncios y mucho más.',
  [Copy.DASHBOARD_JOBSITE_ONBOARDING_TITLE]: 'Antes de llegar al sitio de trabajo',
  [Copy.DASHBOARD_JOBSITE_ONBOARDING_TEXT]:
    'Gracias por crear una cuenta en Odin. Su cuenta se completará cuando vaya al sitio de trabajo para su incorporación. Para que todo vaya bien, recuerde llevar lo siguiente:',
  [Copy.DASHBOARD_JOBSITE_ONBOARDING_BOTTOM]:
    'Recuerda que algunos sitios de trabajo también requieren que se realice una prueba de drogas como parte de la incorporación.',
  [Copy.DRIVERS_LICENSE_OR_GOVERNMENT_ISSUED_ID]:
    'Licencia de conducir o documento de identidad emitido por el gobierno',
  [Copy.OSHA_CARD]: 'Tarjeta OSHA',
  [Copy.STATE_OR_LOCAL_SAFETY_CERTIFICATION]: 'Certificado de seguridad estatal o local',
  [Copy.OTHER_CERTIFICATIONS]: 'Cualquier otra certificación relevante para su trabajo',
  [Copy.PASSWORDLESS_LOG_IN_TITLE]: '¡Te damos la bienvenida!',
  [Copy.PASSWORDLESS_LOG_IN_TEXT]:
    'Para iniciar sesión, introduce tu número de teléfono móvil y te enviaremos un código único por mensaje de texto.',
  [Copy.FIRST_TIME_USING_ODIN]: '¿Es la primera vez que utilizas Odin?',
  [Copy.GET_STARTED]: 'Comenzar',
  [Copy.NETWORK_ERROR_TITLE]: 'No hemos podido conectar con los servidores de Odin',
  [Copy.NETWORK_ERROR_TEXT]: 'Asegurate de que tu dispositivo está conectado a internet e inténtalo de nuevo.',
  [Copy.NETWORK_ERROR_LINK_TEXT]: 'Intentar de nuevo',
  [Copy.LOCATION_ERROR_TITLE]: 'Por favor, activa el uso compartido de la ubicación',
  [Copy.LOCATION_ERROR_TEXT]:
    '"Odin" utiliza la ubicación de su teléfono para verificar de forma segura su acceso al lugar de trabajo.',
  [Copy.LOCATION_ERROR_LINK_TEXT]: 'Abra los ajustes de ubicación',
  [Copy.NO_JOBSITE_ACCESS_TITLE]: 'Lo sentimos, ninguno de tus sitios de trabajo está utilizando Odin Mobile Access',
  [Copy.NO_JOBSITE_ACCESS_TEXT]:
    'Si se te asigna un nuevo lugar de trabajo que permita el acceso móvil, podrás registrar tu entrada y salida desde aquí. No se necesita una tarjeta de identificación.',
  [Copy.NO_ACCESS_HISTORY_TITLE]: 'No se encontró un historial de acceso',
  [Copy.NO_ACCESS_HISTORY_TEXT]:
    'Vuelve a revisar una vez que hayas entrado en un lugar de trabajo para hacer un seguimiento de cuándo has entrado y salido.',
  [Copy.NO_JOBSITES_TITLE]: 'No hemos podido encontrar ninguna asignación de trabajo activa',
  [Copy.NO_JOBSITES_TEXT]:
    'Por favor, vuelve a consultar aquí para ver información valiosa sobre todas las obras activas de Odin.',
  [Copy.NO_RESULTS_TITLE]: 'Parece que ya se ha puesto al día.',
  [Copy.NO_RESULTS_TEXT]: 'Vuelve aquí más tarde para ver las asignaciones, los anuncios, los documentos y mucho más.',
  [Copy.ACCESS_HISTORY]: 'Historial de acceso',
  [Copy.CLOCK_IN]: 'Registro de entrada',
  [Copy.CLOCK_OUT]: 'Registro de salida',
  [Copy.CLOCK_IN_TO]: 'Registro de entrada en',
  [Copy.CLOCK_OUT_OF]: 'Registro de salida en',
  [Copy.CLOCK_IN_OUT_POPUP_HEADER]: '¿En qué dirección vas?',
  [Copy.CLOCK_INTO_ANOTHER_JOBSITE]: 'Entrar en otro sitio de trabajo',
  [Copy.CONTACT_INFO]: 'Información de contacto',
  [Copy.PHONE]: 'Teléfono',
  [Copy.EDIT_CONTACT_INFO]: 'Editar información de contacto',
  [Copy.EDIT_EMERGENCY_CONTACT_INFO]: 'Editar información de contacto de emergencia',
  [Copy.HAVE_A_NEW_PHONE_NUMBER_TITLE]: '¿Tienes un nuevo número de teléfono?',
  [Copy.HAVE_A_NEW_PHONE_NUMBER_TEXT]:
    'Por favor, consulta a tu responsable de seguridad en el sitio para solicitar un cambio de número de teléfono. Asegúrate también de llevar el teléfono que utilizarás para iniciar la sesión.',
  [Copy.LOGIN_PHONE_NUMBER]: 'Número de teléfono de acceso',
  [Copy.CONTRACTOR]: 'Contratista',
  [Copy.TRADE]: 'Comercio',
  [Copy.TRADE_CLASS]: 'Clase de oficio',
  [Copy.START_DATE]: 'Fecha de inicio',
  [Copy.END_DATE]: 'Fecha de finalización',
  [Copy.UPDATE_YOUR_JOBSITE_STATUS]: 'Actualiza el estado de tu obra',
  [Copy.PLEASE_CHOOSE]: 'Por favor, elige',
  [Copy.TODAY]: 'Hoy',
  [Copy.YESTERDAY]: 'Ayer',
  [Copy.CANCEL]: 'Cancelar',
  [Copy.UPDATE_STATUS]: 'Actualizar estado',
  [Copy.SITE_ACCESS_EXPIRES_AT]: 'El acceso al sitio expira en',
  [Copy.GLOBAL_BAN]: 'Baneo global',
  [Copy.UNTIL]: 'hasta',
  [Copy.BANNED]: 'Baneado',
  [Copy.PERMANENTLY]: 'permanentemente',
  [Copy.ALLOWED_ON_SITE]: 'Permitido en el sitio',
  [Copy.NOT_ALLOWED_ON_SITE]: 'No permitido en el sitio',
  [Copy.TO_COMPLETE]: 'completar',
  [Copy.SUGGESTED_ACTION]: 'Acción sugerida',
  [Copy.BASED_ON_YOUR_LOCATION]: 'Según tu ubicación',
  [Copy.REQUIRED]: 'requerido',
  [Copy.SELECT_ONE]: 'Seleccione uno',
  [Copy.OPTIONAL]: '(opcional)',
  [Copy.MIDDLE_INITIAL]: 'Inicial de segundo nombre',
  [Copy.CITIZENSHIP_STATUS]: 'Estado de ciudadanía',
  [Copy.VETERAN_STATUS]: 'Condición de veterano',
  [Copy.JOB_TITLE_CLASS]: 'Clase de título del puesto',
  [Copy.JOB_TITLE]: 'Título del puesto',
  [Copy.UNION_AFFILIATION]: 'Afiliación sindical',
  [Copy.ZIP_CODE_5_DIGITS]: 'El código postal debe tener 5 dígitos',
  [Copy.CITIZEN]: 'Ciudadano',
  [Copy.NON_CITIZEN]: 'No ciudadano',
  [Copy.VETERAN]: 'Veterano',
  [Copy.NON_VETERAN]: 'No veterano',
  [Copy.GENDER]: 'Sexo',
  [Copy.RACE]: 'Raza',
  [Copy.RACE_OPTION_ASIAN_NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER]: 'Asiático/nativo de Hawái o de las islas del Pacífico',
  [Copy.RACE_OPTION_BLACK_AFRICAN_AMERICAN]: 'Negro/Afroamericano',
  [Copy.RACE_OPTION_NATIVE_AMERICAN_ALASKAN_NATIVE]: 'Nativo americano/nativo de Alaska',
  [Copy.RACE_OPTION_HISPANIC_LATINO]: 'Hispano / latino',
  [Copy.RACE_OPTION_WHITE]: 'Blanco',
  [Copy.RACE_OPTION_OTHER_PREFER_NOT_TO_RESPOND]: 'Otro/Prefiero no responder',
  [Copy.SSN_4_DIGIT]: 'El número de seguridad social debe tener 4 dígitos',
  [Copy.INVALID_DATE]: 'Debes proporcionar una fecha válida.',
  [Copy.SUFFIX]: 'Sufijo',
};

export const Languages = {
  es,
  en,
};

addTranslations({ ...Languages, en_US: en });
