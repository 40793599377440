import moment from 'moment';
import { Column, BadgeCell, BadgeCellComponentProps } from '@odin-labs/components';
import {
  DateTimeCell,
  JobsiteAccessDefaultCell,
  DirectionCell,
  JobsiteCell,
  statusColor,
  statusText,
  MethodCell,
  DateTimeCellData,
} from 'containers/jobsiteFormSubmission/print/jobsiteSafety/helpers/cells';
import { JfsHistoricalJobsiteAccessEvent, JfsHistoricalJobsite } from 'containers/jobsiteFormSubmission/print/types';

type WorkerAccessEvent = JfsHistoricalJobsiteAccessEvent & { jobsite: JfsHistoricalJobsite };

export type WorkerAccessEventColumn = Column<WorkerAccessEvent>;

export const getColumns = (): WorkerAccessEventColumn[] => [
  {
    Header: 'Jobsite',
    accessor: (workerAccessEvent: WorkerAccessEvent): string => workerAccessEvent.jobsite?.name ?? '',
    Cell: JobsiteCell,
  },
  {
    Header: 'Date & Time',
    accessor: (workerAccessEvent: WorkerAccessEvent): DateTimeCellData => {
      const { eventOccurredAt, timeZone, overnightSession: isOvernight } = workerAccessEvent ?? {};
      const timeZoneName = moment.tz(eventOccurredAt, timeZone).zoneAbbr();
      return eventOccurredAt && { date: new Date(eventOccurredAt), timeZoneName, isOvernight };
    },
    Cell: DateTimeCell,
  },
  {
    Header: 'Direction',
    accessor: 'direction',
    Cell: DirectionCell,
  },
  {
    Header: 'Method',
    accessor: (workerAccessEvent: WorkerAccessEvent): WorkerAccessEvent => workerAccessEvent,
    Cell: MethodCell,
  },
  {
    Header: 'Status',
    Cell: BadgeCell,
    componentProps: ({ status }: WorkerAccessEvent): BadgeCellComponentProps => ({
      text: statusText[status],
      color: statusColor[status],
      withDot: true,
      background: 'transparent',
      size: 'lg',
    }),
  },
  {
    Header: 'Card Number',
    accessor: 'credentialId',
    Cell: JobsiteAccessDefaultCell,
  },
];
