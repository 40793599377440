import React from 'react';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import { Avatar, NewButton, TabConfig, getFaIcon } from '@odin-labs/components';
import { faTicket } from '@fortawesome/pro-light-svg-icons';
import { NewDropzone } from 'components/dropzone';
import { ArrowToBottomIcon } from 'components/icons';
import { RoutedTabsHeader, RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { ReviewCompletedProfiledTabProps, SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';
import { Copy, getBaseRoute } from 'containers/selfOnboarding/steps/utils';
import { SearchWorkerInfoItem } from 'components/searchBox/searchWorkerInfo/SearchWorkerInfoItem';
import { getCurrentDomain, getInitialsForUser } from 'utils';
import { useFilePreview } from 'components/filePreview';
import { getFeaturesModule } from 'containers/selfOnboarding/helpers/utils';
import { GradientCard } from 'containers/selfOnboarding/steps/components';
import { ReviewCompletedProfileWorkerInfoTab, ReviewCompletedProfileDocumentsTab } from './tabs';
import { ReviewProfilePhotoContainer } from './components';

const TicketIcon = getFaIcon({ icon: faTicket });

const classes = {
  workerTabPage: '',
  qrCodeHolder: cn(
    'odin-relative -odin-ml-6',
    'odin-w-30 odin-h-30 odin-rounded-full odin-bg-white odin-shadow-md',
    'odin-flex odin-items-center odin-justify-center',
  ),
};

export const tabsConfig: TabConfig<ReviewCompletedProfiledTabProps>[] = [
  { name: 'workerInfo', relativePath: '', component: ReviewCompletedProfileWorkerInfoTab },
  { name: 'documents', relativePath: '/documents', component: ReviewCompletedProfileDocumentsTab },
];

const tabsCopies: Record<string, Copy> = {
  workerInfo: Copy.worker_info,
  documents: Copy.documents,
};

export function ReviewCompletedProfileStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, navigation, loading, jobsiteInvitation, localize } = props;
  const { currentStep } = navigation;
  const { file } = state.profilePhoto ?? {};
  const { jobsiteInvitationId, jobsiteContractor } = jobsiteInvitation ?? {};
  const { jobsite } = jobsiteContractor ?? {};
  const { worker, basicInfo } = state;
  const { quickCode, workerId } = worker ?? {};
  const { firstName, lastName } = basicInfo ?? {};

  const isWorkerAppEnabled = !!getFeaturesModule(jobsite?.modules)?.workerAppEnabled;

  const { fileUrl } = useFilePreview(file);

  const baseRoute = `${getBaseRoute(jobsiteInvitationId)}/${currentStep}`;
  const localizedTabsConfig = tabsConfig.map((tab) => ({ ...tab, text: localize(tabsCopies[tab.name]) }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: localizedTabsConfig, baseRoute });

  const tabsPageProps: ReviewCompletedProfiledTabProps = React.useMemo(
    () => ({ jobsiteInvitation, state, loading, navigation, localize }),
    [jobsiteInvitation, state, loading, navigation, localize],
  );

  const qrUrl = `${getCurrentDomain()}/worker/${workerId}`;

  return (
    <div className="odin-p-7.5 odin-flex odin-flex-col odin-gap-y-6">
      {isWorkerAppEnabled && (
        <GradientCard
          headerText={localize(Copy.self_onboarding_review_completed_profile_header)}
          text={localize(Copy.self_onboarding_review_completed_profile_instructions)}
        />
      )}
      {/* TODO: change `odin-justify-center` with `odin-justify-between` when Add Mobile Badge is enabled */}
      {isWorkerAppEnabled && (
        <div className="odin-flex odin-gap-x-3 odin-justify-center lg:odin-hidden">
          <NewButton className="odin-hidden" theme="white" size="xs" text="Add Mobile Badge" icon={TicketIcon} />
          <NewButton
            as="a"
            className="odin-w-full odin-justify-center"
            size="lg"
            text="Download Odin Construction App"
            icon={ArrowToBottomIcon}
            href="https://useodin.com/download"
          />
        </div>
      )}
      <div>
        <div className="sm:odin-hidden odin-flex odin-justify-center">
          <div className="odin-flex">
            <ReviewProfilePhotoContainer navigation={navigation}>
              <NewDropzone
                name="profilePicture"
                value={file}
                isAvatar
                avatarSize="sm"
                accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
                hideAvatarChangePhotoButton
              />
            </ReviewProfilePhotoContainer>
            <div className={classes.qrCodeHolder}>
              <QRCode value={qrUrl} size={72} />
            </div>
          </div>
        </div>
        <div className="sm:odin-flex sm:odin-items-center sm:odin-gap-x-7.5">
          <Avatar
            size="2xl"
            src={fileUrl}
            placeholder={getInitialsForUser({ identity: { firstName, lastName } })}
            objectFit="cover"
            className="odin-hidden sm:odin-block"
          />
          <div>
            <h1 className="odin-mt-3 odin-mb-1.5 odin-text-center odin-text-gray-900 odin-text-2.66xl odin-font-bold">
              {firstName} {lastName}
            </h1>
            <div className="odin-flex odin-justify-center sm:odin-block">
              <SearchWorkerInfoItem type="quickCode" text={quickCode} />
            </div>
          </div>
        </div>
      </div>
      <RoutedTabsHeader tabs={tabs} currentTab={currentTab} />
      <RoutedTabsPages
        className={classes.workerTabPage}
        baseRoute={baseRoute}
        tabs={tabs}
        componentProps={tabsPageProps}
      />
    </div>
  );
}
