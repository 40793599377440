import { UserPendingChangeType } from 'apollo/generated/client-operations';
import { emailValidation, phoneNumberValidation } from 'utils/validation';
import { EnvelopeIcon } from 'components/icons';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { PendingChangeInfoFormData, UserPendingChange } from 'containers/userPendingChange/steps/types';

export type GetFormInputsArgs = { changeType: UserPendingChangeType };

export const getFormInputs = (args: GetFormInputsArgs): TypedFormInputs<PendingChangeInfoFormData> => {
  const { changeType: type } = args;
  return {
    currentPhoneNumber: {
      element: FormInputTypes.OdinField,
      label: 'Old phone number',
      isHidden: type !== UserPendingChangeType.Phone,
      elementProps: {
        fieldType: 'phone',
        showDefaultIcon: true,
        disabled: true,
      },
      validation: { required: true, pattern: phoneNumberValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    newPhoneNumber: {
      element: FormInputTypes.OdinField,
      label: 'New phone number',
      isHidden: type !== UserPendingChangeType.Phone,
      elementProps: {
        fieldType: 'phone',
        showDefaultIcon: true,
        disabled: true,
      },
      validation: { required: true, pattern: phoneNumberValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    currentEmail: {
      element: FormInputTypes.OdinField,
      label: 'Old email address',
      isHidden: type !== UserPendingChangeType.Email,
      elementProps: {
        disabled: true,
        icon: EnvelopeIcon,
      },
      validation: { required: true, pattern: emailValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    newEmail: {
      element: FormInputTypes.OdinField,
      label: 'New email address',
      isHidden: type !== UserPendingChangeType.Email,
      elementProps: {
        icon: EnvelopeIcon,
        disabled: true,
      },
      validation: { required: true, pattern: emailValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};

export const getDefaultValues = (
  changeType: UserPendingChangeType,
  change: UserPendingChange,
): PendingChangeInfoFormData => {
  return changeType === UserPendingChangeType.Phone
    ? {
        currentPhoneNumber: change.currentValue ?? '',
        newPhoneNumber: change.newValue ?? '',
        currentEmail: '',
        newEmail: '',
      }
    : {
        currentPhoneNumber: '',
        newPhoneNumber: '',
        currentEmail: change.currentValue ?? '',
        newEmail: change.newValue ?? '',
      };
};
