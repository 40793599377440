import { useMutation } from '@apollo/client';
import {
  GetWorkerDataDocument,
  JobsiteWorker,
  JobsiteWorkerInput,
  MutationUpdateJobsiteWorkerArgs,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { Field } from 'components/field';
import { LoadingError } from 'components/loadingError';

import { BaseModal } from 'components/modals/BaseModal';
import { FancySelect } from 'components/select/FancySelect';
import { SelectOptionElement } from 'components/select/types';
import { UPDATE_JOBSITE_WORKER } from 'containers/worker/helpers/queries';
import React, { ReactElement, useEffect, useState } from 'react';
import { Col, FormGroup, ModalBody, Row } from 'reactstrap';
import { getGraphQLError } from 'utils/error';

import { useIsMounted } from 'utils';
import { DocumentExemptModalContainerProps } from './types';

const documentExemptReasons: Array<SelectOptionElement> = [
  {
    label: 'Not exempt',
    value: null,
  },
  {
    label: 'Exempt from safety training',
    value: 'siteSpecificTrainingSkipReason',
  },
  {
    label: 'Exempt from SST documents',
    value: 'nycSstExemptionReason',
  },
  {
    label: 'Exempt from all safety documents',
    value: 'documentExemptionReason',
  },
];

export function DocumentExemptModalContainer(props: DocumentExemptModalContainerProps): ReactElement {
  const { isOpen, closeModal, onClosed, jobsiteWorker } = props;
  const isMounted = useIsMounted();
  const [documentExemptStatus, setDocumentExemptStatus] = useState<SelectOptionElement>(documentExemptReasons[0]);
  const [documentExemptStatusMessage, setDocumentExemptStatusMessage] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    if (jobsiteWorker?.siteSpecificTrainingSkipReason) {
      setDocumentExemptStatus(documentExemptReasons[1]);
      setDocumentExemptStatusMessage(jobsiteWorker?.siteSpecificTrainingSkipReason);
    } else if (jobsiteWorker?.nycSstExemptionReason) {
      setDocumentExemptStatus(documentExemptReasons[2]);
      setDocumentExemptStatusMessage(jobsiteWorker?.nycSstExemptionReason);
    } else if (jobsiteWorker?.documentExemptionReason) {
      setDocumentExemptStatus(documentExemptReasons[3]);
      setDocumentExemptStatusMessage(jobsiteWorker?.documentExemptionReason);
    }
  }, [jobsiteWorker]);

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
    onCompleted: () => {
      if (isMounted()) setIsFetching(false);
      closeModal();
    },
    refetchQueries: [GetWorkerDataDocument],
  });

  const modalOnSubmit = async (): Promise<void> => {
    if (documentExemptStatus.value !== null && !documentExemptStatusMessage) {
      AlertInstance.alert('tc', 'danger', 'You must put a exempt message!', null);
      return;
    }

    const jobsiteWorkerInput: JobsiteWorkerInput = {};

    if (documentExemptStatus.value === 'siteSpecificTrainingSkipReason') {
      jobsiteWorkerInput.siteSpecificTrainingSkipReason = documentExemptStatusMessage;
    } else if (documentExemptStatus.value === 'documentExemptionReason') {
      jobsiteWorkerInput.documentExemptionReason = documentExemptStatusMessage;
    } else if (documentExemptStatus.value === 'nycSstExemptionReason') {
      jobsiteWorkerInput.nycSstExemptionReason = documentExemptStatusMessage;
    }

    setIsFetching(true);
    await updateJobsiteWorker({
      variables: {
        jobsiteWorkerId: jobsiteWorker?.jobsiteWorkerId,
        jobsiteWorkerInput,
      },
    });
  };

  if (isFetching) {
    return <LoadingError loading={isFetching} />;
  }

  return (
    <BaseModal
      title="Update worker exemptions"
      isOpen={isOpen}
      actionText="Save"
      cancelText="Cancel"
      onAction={modalOnSubmit}
      onCancel={closeModal}
      toggle={closeModal}
      onClosed={onClosed}
    >
      <ModalBody className="p-0">
        <Row>
          <Col>
            <FormGroup>
              <Col xs="12">
                <FancySelect
                  value={documentExemptStatus}
                  options={documentExemptReasons}
                  label="Worker exemptions"
                  isCreateable={false}
                  onChange={(value: SelectOptionElement): void => {
                    setDocumentExemptStatus(value);
                  }}
                />
              </Col>
              {documentExemptStatus?.value !== null ? (
                <Col xs="12">
                  <Field
                    value={documentExemptStatusMessage}
                    label="Reason for exemption"
                    onChange={setDocumentExemptStatusMessage}
                  />
                </Col>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
    </BaseModal>
  );
}
