import React from 'react';
import { Alert, RadioGroupOption, useDidUpdateEffect } from '@odin-labs/components';
import { AuthUser, to } from 'acl';
import { FormInputTypes, GridColSpan, TypedFormInputs, UseFormMethods, UseInputs } from 'components/form';
import { AccessRevokingTypeFormData, AccessRevokingType } from './types';

export type GetWorkerAccessChangeTypeOptionsArgs = {
  user: AuthUser;
  jobsiteId: string;
};

export type AccessRevokingTypeOption = RadioGroupOption<AccessRevokingType>;

export const getAccessRevokingTypeOptions = ({
  user,
  jobsiteId,
}: GetWorkerAccessChangeTypeOptionsArgs): AccessRevokingTypeOption[] => [
  {
    value: AccessRevokingType.Suspend,
    label: 'Suspend worker',
    description: 'Use this option to temporarily remove site access until a future date.',
    disabled: !user.isAllowed(to.suspendWorkers, jobsiteId),
    disabledDescription: "You don't have permission to make this change.",
  },
  {
    value: AccessRevokingType.Ban,
    label: 'Permanently ban worker',
    description: 'Use this option to permanently ban this worker from the jobsite.',
    disabled: !user.isAllowed(to.banWorkers, jobsiteId),
    disabledDescription: "You don't have permission to make this change.",
  },
  {
    value: AccessRevokingType.Offboard,
    label: 'Offboard worker',
    description:
      'Use this option if the worker’s jobsite assignment is completed: ' +
      'you can onboard the worker in the future if they need to return to work.',
    disabled: !user.isAllowed(to.offboardWorkers, jobsiteId),
    disabledDescription: "You don't have permission to make this change.",
  },
];

const getFormInputs = ({
  accessRevokingTypeOptions,
  selectedAccessRevokingTypeValue,
}: {
  accessRevokingTypeOptions: AccessRevokingTypeOption[];
  selectedAccessRevokingTypeValue: AccessRevokingType;
}): TypedFormInputs<AccessRevokingTypeFormData> => ({
  accessRevokingType: {
    element: FormInputTypes.RadioGroup,
    label: 'What kind of change would you like to make?',
    elementProps: {
      options: accessRevokingTypeOptions,
    },
    layout: GridColSpan.SpanFull,
  },
  alert: {
    element: FormInputTypes.CustomContent,
    isHidden: selectedAccessRevokingTypeValue !== AccessRevokingType.Ban,
    elementProps: {
      content: (
        <Alert
          type="warn"
          text={
            <div className="odin-text-sm odin-leading-5 odin-font-normal">
              <div>Please note, banning a worker is meant to be permanent.</div>
              <div className="odin-font-semibold">
                You may not be able to undo this action if you don’t have sufficient privileges.
              </div>
            </div>
          }
        />
      ),
    },
    layout: GridColSpan.SpanFull,
  },
});

export const getFormInputsHook =
  ({
    accessRevokingTypeOptions,
    enableContinueButton,
  }: {
    accessRevokingTypeOptions: AccessRevokingTypeOption[];
    enableContinueButton: () => void;
  }): UseInputs<AccessRevokingTypeFormData> =>
  ({ watch }: UseFormMethods<AccessRevokingTypeFormData>): TypedFormInputs<AccessRevokingTypeFormData> => {
    const selectedAccessRevokingTypeOption = watch('accessRevokingType');
    const selectedAccessRevokingTypeValue = selectedAccessRevokingTypeOption?.value;
    useDidUpdateEffect(() => {
      if (selectedAccessRevokingTypeValue) {
        enableContinueButton();
      }
    }, [selectedAccessRevokingTypeValue]);

    return React.useMemo(
      () => getFormInputs({ accessRevokingTypeOptions, selectedAccessRevokingTypeValue }),
      [accessRevokingTypeOptions, selectedAccessRevokingTypeValue],
    );
  };

export const getDefaultValues = ({
  accessRevokingTypeOptions,
}: {
  accessRevokingTypeOptions: AccessRevokingTypeOption[];
}): AccessRevokingTypeFormData => {
  return {
    accessRevokingType: accessRevokingTypeOptions?.length === 1 ? accessRevokingTypeOptions[0] : null,
    alert: undefined,
  };
};
