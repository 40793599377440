import React from 'react';
import { useParams } from 'react-router';
import { WorkerChangeContainer } from './worker/WorkerChangeContainer';

type EntityChangeContainerType = (props: { entityId: string }) => React.ReactElement;
const entityChangeContainers: { [key: string]: EntityChangeContainerType } = {
  worker: WorkerChangeContainer,
};

export function EntityChangeContainer(): React.ReactElement {
  const { entity, entityId } = useParams<{ entity: string; entityId: string }>();
  const EntityChangeContainerComponent = entityChangeContainers[entity];
  return EntityChangeContainerComponent && <EntityChangeContainerComponent entityId={entityId} />;
}
