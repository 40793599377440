import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { SequencedModalFormProps } from './types';
import { ModalFormContentWithBackButton } from './ModalFormContentWithBackButton';

const forwardedModalForm = React.forwardRef(
  <TFields,>(props: SequencedModalFormProps<TFields>, ref: React.ForwardedRef<HTMLFormElement>): ReactElement => {
    const {
      // modal props
      open,
      setOpen,
      title,
      subtitle,
      size,
      titleAlignment,
      textAlignment,
      afterLeave,
      overlayCloseDisabled,

      ...modalFormContentProps
    } = props;

    const modalProps = {
      open,
      setOpen,
      title,
      subtitle,
      size,
      titleAlignment,
      textAlignment,
      afterLeave,
      overlayCloseDisabled,
    };

    return (
      <Modal {...modalProps} actionsPanel={null}>
        <ModalFormContentWithBackButton {...modalFormContentProps} setOpen={setOpen} ref={ref} />
      </Modal>
    );
  },
);

type ForwardedModalForm = <TFields>(
  props: SequencedModalFormProps<TFields> & {
    ref?: React.ForwardedRef<HTMLFormElement>;
  },
) => ReturnType<typeof forwardedModalForm>;

export const SequencedModalForm = forwardedModalForm as ForwardedModalForm;
