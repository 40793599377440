import { getFaIcon, SelectOptionElement } from '@odin-labs/components';
import { duration } from 'moment';
import { DeepMap } from 'react-hook-form';
import { faHourglassClock } from '@fortawesome/pro-light-svg-icons';
import { JobsiteUpdateInput, SessionDisplayColumns } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { stringifyEmptyFields } from 'utils';
import { getAsDurationString, toggleBorderClasses } from './utils';

const SessionTimerIcon = getFaIcon({ icon: faHourglassClock });

export const getSiteAccessRulesSectionInputs = ({
  sessionDisplayColumnOptions,
}: {
  sessionDisplayColumnOptions: SelectOptionElement[];
}): TypedFormInputs<EditJobsiteConfigurationFormData['siteAccessRules']> => ({
  onboardingGracePeriodInterval: {
    element: FormInputTypes.OdinField,
    label: 'Onboarding Grace Period Interval',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { fieldType: 'number', innerRightLabel: 'Hours' },
  },
  siteAccessInactivityInterval: {
    element: FormInputTypes.OdinField,
    label: 'Site Access Inactivity Interval',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { fieldType: 'number', innerRightLabel: 'Days' },
  },
  maximumSessionLengthInterval: {
    element: FormInputTypes.OdinField,
    label: 'Maximum Session Interval',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { fieldType: 'number', innerRightLabel: 'Hours' },
  },
  sessionDisplayColumn: {
    element: FormInputTypes.OdinSelect,
    label: 'Session Display Value',
    elementProps: {
      options: sessionDisplayColumnOptions,
      icon: SessionTimerIcon,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  allowOvernightSessions: {
    element: FormInputTypes.OdinToggle,
    label: 'Allow sessions spanning overnight',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
});

export const getSiteAccessRulesSectionDefaultValues = (
  jobsite: Jobsite,
  sessionDisplayColumnOptions: SelectOptionElement[],
): EditJobsiteConfigurationFormData['siteAccessRules'] => {
  const {
    onboardingGracePeriod,
    swipeInactivityThreshold,
    maximumSessionLength,
    allowOvernightSessions,
    sessionDisplayColumn,
  } = jobsite ?? {};

  const onboardingGracePeriodInterval =
    onboardingGracePeriod && Math.trunc(duration(onboardingGracePeriod).asHours()).toString();
  const siteAccessInactivityInterval =
    swipeInactivityThreshold && Math.trunc(duration(swipeInactivityThreshold).asDays()).toString();
  const maximumSessionLengthInterval =
    maximumSessionLength && Math.trunc(duration(maximumSessionLength).asHours()).toString();
  const sessionDisplayColumnOption = sessionDisplayColumnOptions.find(
    (option) => option.value === sessionDisplayColumn,
  );

  return stringifyEmptyFields({
    onboardingGracePeriodInterval,
    siteAccessInactivityInterval,
    allowOvernightSessions,
    sessionDisplayColumn: sessionDisplayColumnOption,
    maximumSessionLengthInterval,
  });
};

type SiteAccessRulesSectionUpdateInput = Required<
  Pick<
    JobsiteUpdateInput,
    | 'onboardingGracePeriod'
    | 'swipeInactivityThreshold'
    | 'maximumSessionLength'
    | 'allowOvernightSessions'
    | 'sessionDisplayColumn'
  >
>;

export const getSiteAccessRulesSectionUpdateInput = (
  siteAccessRules: EditJobsiteConfigurationFormData['siteAccessRules'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['siteAccessRules'], true>,
): SiteAccessRulesSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(siteAccessRules, dirtyFields);
  return {
    onboardingGracePeriod: getAsDurationString(getUpdateInputValue('onboardingGracePeriodInterval'), 'hours'),
    swipeInactivityThreshold: getAsDurationString(getUpdateInputValue('siteAccessInactivityInterval'), 'days'),
    maximumSessionLength: getAsDurationString(getUpdateInputValue('maximumSessionLengthInterval'), 'hours'),
    allowOvernightSessions: getUpdateInputValue('allowOvernightSessions'),
    sessionDisplayColumn: getUpdateInputValue('sessionDisplayColumn') as SessionDisplayColumns,
  };
};
