import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { EditSelfOnboardingWorkerWarningModalProps } from './types';

export function EditSelfOnboardingWorkerWarningModal(props: EditSelfOnboardingWorkerWarningModalProps): ReactElement {
  const { isOpen, closeModal, onConfirm, onClosed } = props;

  return (
    <Modal
      open={isOpen}
      setOpen={closeModal}
      afterLeave={onClosed}
      size="sm"
      title="Edit Self-Onboarding worker?"
      titleAlignment="center"
      text={`This worker is completing Self-Onboarding: if you make changes to the worker's profile, 
      they will not be able to continue self-onboarding. Do you want to proceed?`}
      textAlignment="center"
      onAction={onConfirm}
      actionText="Continue"
    />
  );
}
