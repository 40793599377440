import { SelectOptionElement } from '@odin-labs/components';
import { useGetJobsiteCardFormatsQuery, WorkerCardType } from 'apollo/generated/client-operations';
import { FormInputTypes, GridColSpan, TypedFormInputs, UseFormMethods, UseInputs } from 'components/form';
import React from 'react';
import { ensureNonEmptyItems } from 'utils';
import { getIcon } from 'utils/ui';
import { BadgeType, BadgeTypeOption, ChooseBadgeTypeData } from './types';

const JobsiteIcon = getIcon('fal fa-briefcase');

const getBadgeTypeOptions = (
  qrCodeCardFormatId: string,
  mobileBadgeCardFormatId: string,
  facialRecognitionFormatId: string,
  canHandleBadge: boolean,
): BadgeTypeOption[] =>
  ensureNonEmptyItems<BadgeTypeOption>([
    !!qrCodeCardFormatId && {
      value: BadgeType.QrCode,
      label: 'Assign a QR Code sticker',
      // disabled: !qrCodeCardFormatId,
      disabledDescription: "This jobsite doesn't support this badge type",
      workerCardFormatId: qrCodeCardFormatId,
    },
    !!mobileBadgeCardFormatId && {
      value: BadgeType.MobileBadge,
      label: 'Issue Mobile Badge',
      description: 'Use this option to issue or reissue a mobile badge for this user',
      disabledDescription: 'This worker already has an active mobile badge, please re-issue it',
      workerCardFormatId: mobileBadgeCardFormatId,
    },
    !!facialRecognitionFormatId && {
      value: BadgeType.FacialRecognition,
      label: 'Start facial recognition enrollment',
      description: 'Use this to set up facial recognition',
      disabledDescription: 'This worker already has an active facial recognition badge, please re-issue it',
      workerCardFormatId: facialRecognitionFormatId,
    },
    canHandleBadge && {
      value: BadgeType.ExistingBadge,
      label: 'Assign an existing badge',
      description: 'Use this option for temporary badges, or if the worker already has an Odin badge with their photo.',
      // disabled: !canHandleBadge,
      disabledDescription: "This jobsite doesn't support badge assignment",
    },
    canHandleBadge && {
      value: BadgeType.NewBadge,
      label: 'Print a new badge',
      // disabled: !canHandleBadge,
      disabledDescription: "This jobsite doesn't support this badge type",
    },
  ]);

const getFormInputs = ({
  jobsitesOptions,
  badgeTypeOptions,
}: {
  jobsitesOptions: SelectOptionElement[];
  badgeTypeOptions: BadgeTypeOption[];
}): TypedFormInputs<ChooseBadgeTypeData> => {
  return {
    jobsiteId: {
      element: FormInputTypes.OdinSelect,
      label: 'Jobsite',
      elementProps: {
        placeholder: 'Select one',
        icon: JobsiteIcon,
        disabled: (jobsitesOptions?.length ?? 0) < 2,
        options: jobsitesOptions ?? [],
      },
      validation: {
        required: true,
      },
      layout: GridColSpan.SpanFull,
    },
    badgeType: {
      element: FormInputTypes.RadioGroup,
      label: badgeTypeOptions?.length ? 'What kind of badge would you like to add?' : null,
      elementProps: {
        options: badgeTypeOptions ?? [],
      },
      layout: GridColSpan.SpanFull,
    },
  };
};

type BadgeTypeOptionsData = {
  badgeTypeOptions: BadgeTypeOption[];
};

export const useBadgeTypeOptions = (jobsiteId: string): BadgeTypeOptionsData => {
  const { data: jobsiteCardFormatsData } = useGetJobsiteCardFormatsQuery({
    fetchPolicy: 'no-cache',
    variables: { jobsiteId },
    skip: !jobsiteId,
  });

  const jobsiteCardFormats = jobsiteCardFormatsData?.getJobsiteCardFormats;

  return React.useMemo((): BadgeTypeOptionsData => {
    const qrCodeCardFormatId = jobsiteCardFormats?.find(
      (jcf) => jcf.workerCardFormat.cardType === WorkerCardType.QrCode,
    )?.workerCardFormat.workerCardFormatId;

    const canHandleBadge = jobsiteCardFormats?.some(
      (jcf) => jcf.workerCardFormat.cardType === WorkerCardType.Proximity,
    );

    const mobileBadgeFormatId = jobsiteCardFormats?.find(
      (jcf) => jcf.workerCardFormat.cardType === WorkerCardType.MobileBadge,
    )?.workerCardFormat.workerCardFormatId;

    const facialRecognitionFormatId = jobsiteCardFormats?.find(
      (jcf) => jcf.workerCardFormat.cardType === WorkerCardType.FacialRecognition,
    )?.workerCardFormat.workerCardFormatId;

    return {
      badgeTypeOptions: getBadgeTypeOptions(
        qrCodeCardFormatId,
        mobileBadgeFormatId,
        facialRecognitionFormatId,
        canHandleBadge,
      ),
    };
  }, [jobsiteCardFormats]);
};

export const getFormInputsHook =
  ({ jobsitesOptions }: { jobsitesOptions: SelectOptionElement[] }): UseInputs<ChooseBadgeTypeData> =>
  ({ watch, setValue, getValues }: UseFormMethods<ChooseBadgeTypeData>): TypedFormInputs<ChooseBadgeTypeData> => {
    const jobsiteIdElement = watch('jobsiteId');
    const jobsiteId = jobsiteIdElement?.value;

    const { badgeTypeOptions } = useBadgeTypeOptions(jobsiteId);

    React.useEffect(() => {
      const selectedOption = getValues().badgeType;
      const firstSelectableOption = badgeTypeOptions.find((bto) => !bto.disabled);
      if (selectedOption?.value !== firstSelectableOption?.value) {
        setValue('badgeType', firstSelectableOption);
      }
    }, [badgeTypeOptions]);

    return React.useMemo(
      () => getFormInputs({ jobsitesOptions, badgeTypeOptions }),
      [jobsitesOptions, badgeTypeOptions],
    );
  };

export const getFormDefaultValues = ({
  jobsitesOptions,
}: {
  jobsitesOptions: SelectOptionElement[];
}): ChooseBadgeTypeData => {
  return {
    jobsiteId: jobsitesOptions?.length === 1 ? jobsitesOptions[0] : null,
    badgeType: null,
  };
};
