import React from 'react';
import cn from 'classnames';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  NewMenuItemProps,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import { ensureNonEmptyItems } from 'utils';
import { AuthUser, to } from 'acl';
import { getIcon } from 'utils/ui';
import { JobsiteContractor, JobsiteContractorColumn, JobsiteContractorFilters } from './types';

const EditIcon = getFixedWidthFaIcon({ icon: faEdit });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });
const ContractorIcon = getIcon(cn('fal fa-wrench odin-text-base odin-text-odin-primary'));

export const getColumns = ({
  user,
  openEditContractorAssignmentModal,
  openRemoveContractorAssignmentModal,
}: {
  user: AuthUser;
  openEditContractorAssignmentModal: (value: JobsiteContractor) => void;
  openRemoveContractorAssignmentModal: (value: JobsiteContractor) => void;
}): JobsiteContractorColumn[] => {
  const canEditContractorAssignments = user.isAllowed(to.editContractorJobsiteAssignments);
  const canRemoveContractorAssignments = user.isAllowed(to.removeContractorJobsiteAssignments);
  const isActionsButtonVisible = canEditContractorAssignments || canRemoveContractorAssignments;

  return ensureNonEmptyItems<JobsiteContractorColumn>([
    {
      id: 'contactor',
      Header: 'Contractor',
      accessor: (jobsiteContractor: JobsiteContractor): string => {
        return jobsiteContractor.contractor?.organization?.name;
      },
      Cell: AvatarCell,
      CellLoading: <AvatarSkeleton size="xs" type="rounded" hideDetails />,
      componentProps: (jobsiteContractor: JobsiteContractor): AvatarCellComponentProps => ({
        icon: ContractorIcon,
        type: 'rounded',
        placeholder: (jobsiteContractor?.contractor.organization.name.substring(0, 2) || '??').toUpperCase(),
        objectFit: 'cover',
        size: 'xs',
      }),
      disableSortBy: true,
    },
    {
      id: 'workerCount',
      Header: <>Worker&nbsp;Count</>,
      accessor: (jobsiteContractor: JobsiteContractor): string =>
        jobsiteContractor?.jobsiteWorkers.count.toString() || '',
      disableSortBy: true,
    },
    isActionsButtonVisible && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (jobsiteContractor: JobsiteContractor): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<NewMenuItemProps>([
          user.isAllowed(to.editContractorJobsiteAssignments) && {
            icon: EditIcon,
            text: 'Edit Assignment',
            theme: 'secondary',
            onClick: (): void => openEditContractorAssignmentModal(jobsiteContractor),
          },
          user.isAllowed(to.removeContractorJobsiteAssignments) && {
            icon: TrashIcon,
            text: 'Delete Assignment',
            theme: 'danger',
            onClick: (): void => openRemoveContractorAssignmentModal(jobsiteContractor),
          },
        ]),
      }),
    },
  ]);
};

export const getFilterItems = ({ search }: { search: string }): FilterItems<JobsiteContractorFilters> => ({
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
