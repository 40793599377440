import React, { ReactElement } from 'react';
import cn from 'classnames';
import { ServerFile } from 'types';
import { FilePreview } from 'components/filePreview';
import { PdfPreview } from './PdfPreview';

export type ThumbnailProps = {
  file: ServerFile;
  className?: string;
  suppressAvatarClasses?: boolean;
};

export function Thumbnail({ file, className, suppressAvatarClasses }: ThumbnailProps): ReactElement {
  const { fileType, originalFileName, downloadUrl } = file ?? {};
  const isPdf = fileType?.toLowerCase() === 'pdf';
  const alt = originalFileName;

  const classNames = ['rounded'];
  if (!suppressAvatarClasses) {
    classNames.push('avatar', 'avatar-lg');

    if (isPdf) {
      classNames.push('img-thumbnail');
      classNames.push('img-fluid');
    } else {
      classNames.push('avatar-img');
    }
  }

  if (className) {
    classNames.push(className);
  }

  if (isPdf) {
    return <PdfPreview isThumbnail fileURL={downloadUrl} alt={alt} className={cn(classNames)} />;
  }
  return <FilePreview file={file} alt={alt} className={cn(classNames)} />;
}
