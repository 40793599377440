import React from 'react';
import cn from 'classnames';
import { setRefFactory } from 'components/utils';
import { HtmlRendererProps } from './types';

export const HtmlRenderer = React.forwardRef(
  (props: HtmlRendererProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { header, footer, className, url } = props;

    const [html, setHtml] = React.useState('');

    React.useEffect(() => {
      if (url) {
        fetch(url).then((res) => res.text().then(setHtml));
      }
    }, [url]);

    const setRef = setRefFactory<HTMLDivElement>({ outerRef: ref });

    return (
      <>
        {typeof header === 'function' ? header() : header}
        <div
          className={cn(className)}
          ref={setRef}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {typeof footer === 'function' ? footer() : footer}
      </>
    );
  },
);
