import { GoogleAnalyticsLocation, GoogleAnalyticsProps } from 'analytics/types';
import React, { Component, ReactElement } from 'react';
import ReactGA from 'react-ga4';
import { Route } from 'react-router-dom';
import { Maybe } from 'types';
import { AuthContext, AuthUser } from 'auth';
import { AppConfig } from 'config/types';
import { appConfig } from 'config/app';

class GoogleAnalytics extends Component<GoogleAnalyticsProps> {
  static currentUser: AuthUser;

  static logPageChange(location: GoogleAnalyticsLocation): void {
    const { pathname, search } = location;
    // not sure if this is needed on every event, it's conceivable we could take this out
    const { workerId } = GoogleAnalytics.currentUser?.worker ?? {};
    const { email } = GoogleAnalytics.currentUser?.identity ?? {};

    const page = pathname + search;
    const details = { hitType: 'pageview', page, user_id: workerId, isInternalUser: email?.includes('@useodin.com') };

    ReactGA.send(details);
  }

  componentDidMount(): void {
    const { location } = this.props;
    GoogleAnalytics.logPageChange(location);
  }

  componentDidUpdate(componentDidUpdateArgs: { location: GoogleAnalyticsLocation }): void {
    const { location } = this.props;
    const { location: prevLocation } = componentDidUpdateArgs;
    const { pathname, search } = location;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      GoogleAnalytics.logPageChange(location);
    }
  }

  render(): null {
    return null;
  }
}

const init = (config: Maybe<AppConfig>, user?: AuthUser): boolean => {
  const { gaTrackingID, appBuildVersion } = config ?? {};
  const didInit = gaTrackingID?.length > 0;
  if (!didInit) {
    return false;
  }
  const { workerId } = user?.worker ?? {};
  const { email } = user?.identity ?? {};

  const isInternalUser = email?.includes('@useodin.com');
  GoogleAnalytics.currentUser = user;
  ReactGA.initialize(gaTrackingID, {
    gaOptions: { appVersion: appBuildVersion, user_id: workerId },
    gtagOptions: {
      custom_map: {
        dimension1: 'isInternalUser',
      },
    },
  });
  ReactGA.gtag('set', 'user_properties', { isInternalUser });
  // ReactGA.set({ user_properties: { isInternalUser } });
  return true;
};

function RouteTracker(): ReactElement {
  const { currentUser } = React.useContext(AuthContext);
  return init(appConfig, currentUser) && <Route component={GoogleAnalytics} />;
}

export { GoogleAnalytics, RouteTracker, init };
