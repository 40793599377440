import React from 'react';
import { BadgeColor } from '@odin-labs/components';
import { JobsiteWorkerOrientationStatus } from 'apollo/generated/client-operations';
import { OnboardingProgress } from 'components/onboardingProgress';
import { OnboardingProgressProps } from 'components/onboardingProgress/types';

export const statusText: Record<JobsiteWorkerOrientationStatus, string> = {
  [JobsiteWorkerOrientationStatus.NotStarted]: 'Not Started',
  [JobsiteWorkerOrientationStatus.SelfOnboarding]: 'Self-Onboarding',
  [JobsiteWorkerOrientationStatus.InProgress]: 'In Progress',
  [JobsiteWorkerOrientationStatus.Complete]: 'Complete',
};
export const statusColor: Record<JobsiteWorkerOrientationStatus, BadgeColor> = {
  [JobsiteWorkerOrientationStatus.NotStarted]: 'gray',
  [JobsiteWorkerOrientationStatus.SelfOnboarding]: 'yellow',
  [JobsiteWorkerOrientationStatus.InProgress]: 'gray',
  [JobsiteWorkerOrientationStatus.Complete]: 'green',
};

export type OnboardingProgressCellProps = {
  componentProps: OnboardingProgressProps;
};

export function OnboardingProgressCell({ componentProps }: OnboardingProgressCellProps): React.ReactElement {
  return <OnboardingProgress {...componentProps} />;
}
