import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { dropzoneValidation } from 'components/dropzone/utils';
import { EditFormSubmissionDocumentFormData, EditableJobsiteFormSubmissionDocument } from './types';

export const getFormInputs = (): TypedFormInputs<EditFormSubmissionDocumentFormData> => ({
  file: {
    element: FormInputTypes.NewDropzone,
    label: 'Attachment',
    layout: GridColSpan.SpanFull,
    elementProps: {
      accept: {
        'application/pdf': [],
        'image/*': ['.png', '.jpg', '.jpeg', '.heic', '.heif'],
      },
    },
    validation: {
      required: true,
      validate: dropzoneValidation,
    },
  },
  description: {
    element: FormInputTypes.OdinField,
    label: 'Description',
    layout: GridColSpan.SpanFull,
    validation: { required: true },
  },
});

export const getDefaultValues = (
  document?: EditableJobsiteFormSubmissionDocument,
): EditFormSubmissionDocumentFormData => {
  const { files, description } = document ?? {};
  return {
    file: files?.[0] ?? null,
    description: description ?? '',
  };
};
