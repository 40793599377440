import { JobsiteUpdateInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { DeepMap } from 'react-hook-form';

export const procoreSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['procore']> = {
  companyId: {
    element: FormInputTypes.OdinField,
    label: 'Company ID',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  productId: {
    element: FormInputTypes.OdinField,
    label: 'Project ID',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
};

export const getProcoreSectionDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['procore'] => {
  const { procoreCompanyId, procoreProjectId } = jobsite ?? {};

  return {
    companyId: procoreCompanyId ?? '',
    productId: procoreProjectId ?? '',
  };
};

type ProcoreSectionUpdateInput = Required<Pick<JobsiteUpdateInput, 'procoreCompanyId' | 'procoreProjectId'>>;

export const getProcoreSectionUpdateInput = (
  procore: EditJobsiteConfigurationFormData['procore'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['procore'], true>,
): ProcoreSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(procore, dirtyFields);
  return {
    procoreCompanyId: getUpdateInputValue('companyId'),
    procoreProjectId: getUpdateInputValue('productId'),
  };
};
