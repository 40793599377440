import { useQuery } from '@apollo/client';
import { LoadingError } from 'components/loadingError';
import { GET_APP_VERSION, QueryGetAppVersionResponse } from 'graphql/client/general';
import { AppLayoutProps } from 'layouts/application/types';
import React, { ReactElement, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { matchPath, Redirect, useHistory, useLocation } from 'react-router-dom';
import { UserRoleKey, useUpdateSessionAppVersionMutation } from 'apollo/generated/client-operations';
import { isSsr } from 'utils';
import { AuthContext } from 'auth';
import { AppLayoutContent } from './AppLayoutContent';

function AppLayoutWithoutHot(props: AppLayoutProps): ReactElement {
  const location = useLocation();
  const history = useHistory();
  const authContext = React.useContext(AuthContext);
  const { currentSession, currentUser } = authContext ?? {};
  const { session } = currentSession ?? {};
  const { urlLock } = session ?? {};

  const { data: appVersionData } = useQuery<QueryGetAppVersionResponse>(GET_APP_VERSION);
  const [updateSessionAppVersion] = useUpdateSessionAppVersionMutation();

  useEffect(() => {
    // Update the session with the current app version if the version is out-of-date
    if (session && appVersionData?.appVersion && session.appVersion !== appVersionData.appVersion) {
      updateSessionAppVersion({ variables: { appVersion: appVersionData.appVersion } });
    }
  }, [session]);

  const { route } = props;

  if (!session) {
    if (isSsr()) {
      return <LoadingError loading />;
    }
    return <Redirect push to="/auth/login" />;
  }

  if (!currentUser?.hasRole(UserRoleKey.Worker) && !currentUser.userAccountId) {
    return <Redirect to="/mobile-only" />;
  }

  if (urlLock && history?.location?.pathname !== urlLock) {
    return <Redirect to={urlLock} />;
  }

  const currentRoute = route?.routes.find((mappedRoute) => {
    const match = matchPath(location.pathname, { path: mappedRoute.path });
    return match?.isExact;
  });

  return (
    <AppLayoutContent
      routes={route.routes}
      currentRoute={currentRoute}
      history={history}
      pathName={location.pathname}
      currentSession={currentSession}
    />
  );
}

export const AppLayout = hot(module)(AppLayoutWithoutHot);
