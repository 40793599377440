import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Path, Svg } from '@react-pdf/renderer';

type SvgProps = React.ComponentProps<typeof Svg>;
export type Flatten<T> = T extends Array<unknown> ? T[number] : T;

interface FontAwesomeIconPdfProps {
  faIcon: IconDefinition;
  style?: Flatten<SvgProps['style']>;
}

export function FontAwesomeIconPdf({ faIcon: { icon }, style = {} }: FontAwesomeIconPdfProps): React.ReactElement {
  const duotone = Array.isArray(icon[4]);
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]];
  const color = style.color || 'black';
  return (
    <Svg viewBox={`0 0 ${icon[0]} ${icon[1]}`} style={style}>
      {paths?.map((d, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Path d={d} key={index} fill={color} fillOpacity={duotone && index === 0 ? 0.4 : 1.0} />
      ))}
    </Svg>
  );
}
