import { JobsitePreOnboardingStep } from 'apollo/generated/client-operations';
import {
  SelfOnboardingStepKey,
  SelfOnboardingStepConfig,
  JobsiteModule,
  JobsiteFeaturesModule,
  JobsiteOnboardingModule,
  JobsitePreOnboardingModule,
  SelfOnboardingState,
  JobsiteOnboardingDocument,
} from 'containers/selfOnboarding/types';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';
import { isStringEnumValue } from 'utils';
import { DocumentKey } from 'containers/worker/utils';
import { sequentialSteps } from './useSelfOnboardingNavigation.config';

export const getFeaturesModule = (modules: JobsiteModule[]): JobsiteFeaturesModule =>
  modules?.find((m): m is JobsiteFeaturesModule => {
    return m.__typename === 'JobsiteFeaturesModule'; // eslint-disable-line no-underscore-dangle
  });

export const getOnboardingModule = (modules: JobsiteModule[]): JobsiteOnboardingModule =>
  modules?.find((m): m is JobsiteOnboardingModule => {
    return m.__typename === 'JobsiteOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getPreOnboardingModule = (modules: JobsiteModule[]): JobsitePreOnboardingModule =>
  modules?.find((m): m is JobsitePreOnboardingModule => {
    return m.__typename === 'JobsitePreOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getStepsConfig = (modules: JobsiteModule[]): Partial<Record<string, JobsitePreOnboardingStep>> => {
  const module = getPreOnboardingModule(modules);
  return module?.steps?.reduce((result, step) => ({ ...result, [step.key]: step }), {}) ?? {};
};

const onboardingStepKey: Partial<Record<SelfOnboardingStepKey, string>> = {
  [SelfOnboardingStepKey.BasicInfo]: OnboardingStepKey.PersonalInfo,
  [SelfOnboardingStepKey.Profile]: OnboardingStepKey.PersonalInfo,
  [SelfOnboardingStepKey.EmergencyContactInfo]: OnboardingStepKey.PersonalInfo,
};

// this config is generated based on onboarding module
export const getStepConfig = (modules: JobsiteModule[], stepKey: SelfOnboardingStepKey): SelfOnboardingStepConfig => {
  const module = getOnboardingModule(modules);

  // get the step config from document if the step corresponds to a document
  const stepConfig = isStringEnumValue(DocumentKey, stepKey)
    ? module?.documents?.find((doc) => doc.key === stepKey)
    : module?.steps?.find((step) => step.key === onboardingStepKey[stepKey] ?? stepKey);

  if (stepConfig) {
    return {
      ...stepConfig,
      fields: stepConfig.fields?.reduce((result, field) => ({ ...result, [field.key]: field }), {}) ?? {},
    };
  }

  return { key: stepKey, name: '', fields: {} };
};

export const getDocument = (key: DocumentKey, modules: JobsiteModule[]): JobsiteOnboardingDocument => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return jobsiteOnboardingModule?.documents?.find((doc) => doc.key === key);
};

export const isDocumentVisible = (key: DocumentKey, modules: JobsiteModule[]): boolean => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return !!jobsiteOnboardingModule?.documents?.some((doc) => doc.key === key && doc.isVisible);
};

export const getVisibleDocuments = (modules: JobsiteModule[]): string[] => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return jobsiteOnboardingModule?.documents?.filter((doc) => doc.isVisible).map(({ key }) => key);
};

export const getVisibleSequentialSteps = (
  modules: JobsiteModule[],
  state: SelfOnboardingState,
): SelfOnboardingStepKey[] => {
  const visibleDocuments = getVisibleDocuments(modules);
  return sequentialSteps
    .filter((step) => {
      if (typeof step === 'string') {
        return !isStringEnumValue(DocumentKey, step) || visibleDocuments?.includes(step);
      }
      return (
        step.isVisible(state) && (!isStringEnumValue(DocumentKey, step.key) || visibleDocuments?.includes(step.key))
      );
    })
    .map((step) => (typeof step === 'string' ? step : step.key));
};

export function jobsiteDoesNotAllowToSkipSteps(modules: JobsiteModule[]): boolean {
  return !!getFeaturesModule(modules)?.doNotAllowToSkipSelfOnboardingSteps;
}
