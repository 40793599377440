import React from 'react';
import moment from 'moment';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError, hasGraphQLErrorCode } from 'utils/error';
import { useUpdateJobsiteWorkerMutation } from 'apollo/generated/client-operations';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { useIsMounted } from 'utils';
import {
  getDefaultValues,
  getFormInputs,
  getJobsiteAssignmentTypeOptions,
} from './CompleteJobsiteAssignmentModalContent.forms';
import {
  AssignWorkerStep,
  CompleteJobsiteAssignmentFormData,
  CompleteJobsiteAssignmentModalContentProps,
  JobsiteAssignmentType,
} from './types';
import { useAssignContractorWorkerToJobsite } from './useAssignContractorWorkerToJobsite';

export function CompleteJobsiteAssignmentModalContent(
  props: CompleteJobsiteAssignmentModalContentProps,
): React.ReactElement {
  const {
    closeModal,
    onConfirm,
    selectedWorker: { workerId },
    assignWorkerModalState,
  } = props;
  const { jobsiteId, siblingJobsiteWorkers } = assignWorkerModalState;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation();
  const { assignJobsiteAndGoToPersonalInformation } = useAssignContractorWorkerToJobsite({ workerId });

  const onSubmit: FormOnSubmit<CompleteJobsiteAssignmentFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { jobsiteAssignmentType } = data;
    try {
      // single jobsite is desired so lets remove all the previous ones
      if (jobsiteAssignmentType.value === JobsiteAssignmentType.WorkingAtOneSiteOnly) {
        const endDate = moment().startOf('day').toDate();
        await Promise.all(
          siblingJobsiteWorkers?.map((jw) => {
            return updateJobsiteWorker({
              variables: {
                jobsiteWorkerId: jw.jobsiteWorkerId,
                jobsiteWorkerInput: { endDate },
              },
            });
          }),
        );
      }

      await assignJobsiteAndGoToPersonalInformation(assignWorkerModalState);
    } catch (error) {
      if (hasGraphQLErrorCode(error, 'BAD_USER_INPUT')) {
        onConfirm(AssignWorkerStep.AlreadyAssignedWorker);
      } else {
        event.preventDefault();
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const jobsiteName = jobsiteId.label;

  const { formInputs, defaultValues } = React.useMemo(() => {
    const assignmentTypeOptions = getJobsiteAssignmentTypeOptions({ jobsiteName });
    return {
      formInputs: getFormInputs({ jobsiteName, assignmentTypeOptions }),
      defaultValues: getDefaultValues({ assignmentTypeOptions }),
    };
  }, [jobsiteName]);

  return (
    <ModalFormContent
      setOpen={closeModal}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Submit"
      actionButtonWithSpinner={isFetching}
    />
  );
}
