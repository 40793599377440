import React from 'react';
import { ContractorInfo } from 'containers/contractor/types';
import { getPrettyFormattedDate } from 'utils/dates';
import { InfoRowProps } from 'components/infoRow/types';

export type OrganizationInfoRowConfig = Omit<InfoRowProps, 'onEditClick' | 'allowEdit'>;

const getName = ({ firstName, lastName }: { firstName?: string; lastName?: string } = {}): string =>
  [firstName, lastName].filter(Boolean).join(' ');

export function getInfoRows(contractor: ContractorInfo): OrganizationInfoRowConfig[] {
  const { organization, billingContact, objectHistory, websiteUrl, description } = contractor ?? {};
  const createdByIdentity = objectHistory?.createdBy?.identity;

  const { addressLine1, addressLine2, addressCity, addressState, addressZipCode } = organization ?? {};
  const organizationAddress = organization && (
    <>
      {addressLine1} {addressLine2} <br />
      {[addressCity, addressState].filter(Boolean).join(', ')} {addressZipCode}
    </>
  );

  const { email: billingContactEmail, phoneNumber: billingContactPhoneNumber } = billingContact ?? {};
  const billingContactEmailLink = billingContactEmail && (
    <a href={`mailto:${billingContactEmail}`} target="blank">
      {billingContactEmail}
    </a>
  );
  const billingContactPhoneNumberLink = billingContactPhoneNumber && (
    <a href={`tel:${billingContactPhoneNumber}`}>{billingContactPhoneNumber}</a>
  );

  const urlWithProtocol = websiteUrl?.toLowerCase().startsWith('www') ? `//${websiteUrl}` : websiteUrl;
  const websiteLink = websiteUrl && (
    <a href={urlWithProtocol} target="blank">
      {websiteUrl}
    </a>
  );

  return [
    { label: 'Display Name', value: organization?.name },
    { label: 'Legal Name', value: organization?.legalName },
    { label: 'Address', value: organizationAddress },
    { label: 'Billing Contact Name', value: getName(billingContact) },
    { label: 'Billing Email', value: billingContactEmailLink },
    { label: 'Billing Phone', value: billingContactPhoneNumberLink },
    { label: 'Website URL', value: websiteLink },
    { label: 'Description', value: description },
    { label: 'Created On', value: getPrettyFormattedDate(objectHistory?.createdAt) },
    { label: 'Created By', value: getName(createdByIdentity) },
  ];
}
