import React, { ReactElement, useState } from 'react';
import { FormDialog } from 'dialog';
import { getIcon } from 'utils/ui';
import { EditCCureClearanceFormData } from 'containers/jobsiteConfiguration/types';
import { EditCCureClearanceModalProps } from './types';
import { getDefaultValues, getFormInputsHook } from './EditCCureClearanceModal.forms';

export function EditCCureClearanceModal(props: EditCCureClearanceModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, cCureClearance, cCureEnvironmentsOptions, cCureClearancesOptions } = props;
  const [clearanceName, setClearanceName] = useState<string>();

  const onSubmit = React.useCallback(
    (data: EditCCureClearanceFormData): void => {
      const { cCureEnvironment, cCureClearance: selectedClearance, isSelectable, isDefault } = data;
      onConfirm?.({
        ...cCureClearance,
        isDefault,
        isSelectable,
        cCureClearanceObjectId: selectedClearance?.entity?.objectId,
        name: selectedClearance?.label,
        cCureEnvironment: {
          cCureEnvironmentId: cCureEnvironment.value,
          namespace: cCureEnvironment.label,
        },
      });
    },
    [onConfirm, cCureClearance],
  );

  React.useEffect(() => {
    if (!cCureClearance) return;
    const {
      name,
      cCureEnvironment: { namespace },
    } = cCureClearance;
    setClearanceName(`${name} | ${namespace}`);
  }, [cCureClearance]);

  const formInputs = getFormInputsHook({ cCureEnvironmentsOptions, cCureClearancesOptions, cCureClearance });
  const defaultValues = React.useMemo(
    () => getDefaultValues({ cCureClearance, cCureEnvironmentsOptions, cCureClearancesOptions }),
    [cCureClearance, cCureEnvironmentsOptions, cCureClearancesOptions],
  );

  return (
    <FormDialog
      id="editCCureClearance"
      open={isOpen}
      setOpen={onCancel}
      title={`Edit ${clearanceName} Clearance`}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionIcon={getIcon('fal fa-check')}
    />
  );
}
