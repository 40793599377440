import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { NewDropdownButton, NewMenuItemProps, TabConfig, getFaIcon } from '@odin-labs/components';
import { useGetFormsQuery } from 'apollo/generated/client-operations';
import { useModalState } from 'utils';
import { AuthContext } from 'auth';
import { NewHeader } from 'components/header/NewHeader';
import { Container } from 'components/container';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { LoadingError } from 'components/loadingError';
import { FormsTab } from './formsTab';
import { AddFormSubmissionModal } from './modals/AddFormSubmissionModal';
import { AddFormSubmissionModalProps } from './modals/types';
import { Form, FormsTabApi, FormsTabProps } from './formsTab/types';

const PlusIcon = getFaIcon({ icon: faPlus });

const lockedFeatureMessage = (
  <>
    This feature is part of Forms. Forms lets you track the work performed by your onsite staff and subcontractors.
    Contact&nbsp;
    <a href="mailto:support@useodin.com" target="blank">
      support@useodin.com
    </a>
    &nbsp;to learn more.
  </>
);

const baseRoute = `/forms`;

const getTabsConfig = (forms: Form[]): TabConfig<FormsTabProps>[] => {
  return forms?.map((form) => ({
    name: form.key,
    text: form.name,
    relativePath: `/${form.key}`,
    component: FormsTab,
  }));
};

export function FormsContainer(): ReactElement {
  const history = useHistory();
  const [tabApi, setTabApi] = React.useState<FormsTabApi>(null);
  const {
    value: formToAdd,
    isModalOpen: isAddFormSubmissionModalOpen,
    openModal: openAddFormSubmissionModal,
    closeModal: closeAddFormSubmissionModal,
    resetModalValue: resetFormToAdd,
  } = useModalState<Form>(null);

  const { currentUser: user } = React.useContext(AuthContext);
  const isFeatureLocked = !user.hasFormsEnabled;

  const {
    data: formsData,
    loading: formsLoading,
    error: formsError,
  } = useGetFormsQuery({ fetchPolicy: 'network-only' });
  const forms = formsData?.getCurrentSession.user.forms.edges.map(({ node }) => node);

  const { tabsConfig, menuItems } = React.useMemo(() => {
    return {
      tabsConfig: getTabsConfig(forms) ?? [],
      menuItems: forms?.map(
        (form): NewMenuItemProps => ({
          text: form.name,
          onClick: (): void => openAddFormSubmissionModal(form),
          disabled: !user.hasFormsEnabled,
        }),
      ),
    };
  }, [forms]);

  const { tabs, currentTab } = useRoutedTabs({ tabsConfig, baseRoute });
  const currentForm = forms?.find((form) => form.key === currentTab?.name);

  const tabsPageProps: FormsTabProps = {
    onTabApiChange: setTabApi,
    form: currentForm,
  };

  const closeAddFormSubmissionModalAndNavigateToFormSubmission: AddFormSubmissionModalProps['onConfirm'] =
    React.useCallback(
      (jobsiteFormSubmission): void => {
        const formToAddKey = formToAdd.key;
        closeAddFormSubmissionModal();
        history.push(`/forms/${formToAddKey}/${jobsiteFormSubmission.id}`);
      },
      [closeAddFormSubmissionModal, history, formToAdd],
    );

  if (isFeatureLocked) {
    return (
      <div className="odin-p-8">
        <LockedFeatureAlert message={lockedFeatureMessage} />
      </div>
    );
  }

  if (formsLoading || formsError) {
    return <LoadingError loading={formsLoading} error={formsError} />;
  }

  return (
    <Container className="forms-container">
      <NewHeader
        title="Forms"
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          children: !!menuItems?.length && <NewDropdownButton menuItems={menuItems} icon={PlusIcon} text="Add" />,
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      <AddFormSubmissionModal
        isOpen={isAddFormSubmissionModalOpen}
        onCancel={closeAddFormSubmissionModal}
        onConfirm={closeAddFormSubmissionModalAndNavigateToFormSubmission}
        onClosed={resetFormToAdd}
        form={formToAdd}
      />
    </Container>
  );
}
