import React from 'react';
import cn from 'classnames';
import { Spinner } from '@odin-labs/components';
import { FileType } from 'apollo/generated/client-operations';
import { MergeUnionType } from 'types';
import { PdfPreview } from 'components/thumbnail/PdfPreview';
import { FilePreviewProps } from './types';
import { isServerFile, useFilePreview } from './utils';

export function FilePreview(props: FilePreviewProps): React.ReactElement {
  const {
    file,
    alt,
    style,
    size = { width: 64, height: 64 },
    linkContainer: LinkContainer,
    fileUrl: _ignore1, // eslint-disable-line @typescript-eslint/no-unused-vars
    fileIcon: _ignore2, // eslint-disable-line @typescript-eslint/no-unused-vars
    fileImgStyle: _ignore3, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...restProps
  } = props as MergeUnionType<FilePreviewProps>;

  const isPdf = isServerFile(file) ? file.fileType === FileType.Pdf : file?.type.includes('pdf');
  const isVideo = isServerFile(file) ? file.fileType === FileType.Video : file?.type.includes('video');

  const filePreview = useFilePreview(file, size);

  const {
    fileUrl,
    fileIcon: FileIcon,
    fileImgStyle,
  } = file ? filePreview : (props as MergeUnionType<FilePreviewProps>);

  const isLoading = file ? filePreview.isLoading : false;

  if (isLoading) {
    const { className } = restProps;
    return (
      <div className={cn(className, 'odin-flex odin-items-center odin-justify-center')} style={style}>
        <Spinner size="base" className="odin-text-odin-primary" />
      </div>
    );
  }

  const renderPreviewContent = (): React.ReactElement => {
    const { className } = restProps;
    if (isPdf) {
      return (
        <PdfPreview isThumbnail fileURL={fileUrl} alt="dropzone-file" className={cn(className, 'img-thumbnail')} />
      );
    }

    if (FileIcon) {
      return <FileIcon className={className} />;
    }

    if (isVideo) {
      return (
        <video className={className} controls playsInline>
          <source src={fileUrl} />
          <track kind="captions" />
        </video>
      );
    }

    return <img {...restProps} alt={alt} src={fileUrl} style={{ ...style, ...fileImgStyle }} />;
  };

  return LinkContainer ? (
    <LinkContainer file={file} filePreview={filePreview}>
      {renderPreviewContent()}
    </LinkContainer>
  ) : (
    renderPreviewContent()
  );
}
