import React from 'react';
import { Prompt, useHistory, useLocation } from 'react-router';
import { Location, Action } from 'history';
import { useBoolean } from 'utils';
import { UnsavedChangesWarningModal } from './UnsavedChangesWarningModal';

export interface RouterPromptProps {
  when?: boolean | undefined;
  /**
   * If it returns `true` or `undefined` the modal will close and the navigation will be performed.
   * If it returns `false` the modal will close and the navigation will be canceled.
   * If it `throws an error` the modal won't close and the navigation will be canceled.
   * @returns
   */
  onConfirm: () => void | boolean | Promise<void | boolean>;
}

type NavigationInfo = {
  location: Location;
  action: Action;
};

export function RouterPrompt(props: RouterPromptProps): React.ReactElement {
  const { when, onConfirm } = props;

  const history = useHistory();
  const currentLocation = useLocation();

  const { value: isModalVisible, setTrue: openModal, setFalse: closeModal } = useBoolean(false);
  const [navigationInfo, setNavigationInfo] = React.useState<NavigationInfo | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const handlePromptBeforeNavigation = (location: Location, action: Action): boolean => {
    const locationChanged = currentLocation.pathname !== location.pathname;
    if (locationChanged && !confirmedNavigation) {
      openModal();
      setNavigationInfo({ location, action });
      return false;
    }
    return true;
  };

  const onSaveChangesHandler = async (): Promise<void> => {
    const result = await onConfirm?.();
    closeModal();
    if (result === undefined || result === true) {
      setConfirmedNavigation(true);
    }
  };

  const onDiscardChangesHandler = (): void => {
    closeModal();
    setConfirmedNavigation(true);
  };

  const navigate = React.useCallback(
    ({ location, action }: NavigationInfo): void => {
      switch (action) {
        case 'PUSH':
          history.push(location.pathname);
          break;
        case 'POP':
          history.goBack();
          break;
        case 'REPLACE':
          history.replace(location.pathname);
          break;
        default:
          break;
      }
    },
    [history],
  );

  React.useEffect(() => {
    if (confirmedNavigation && navigationInfo) {
      // Navigate to the previously blocked location
      navigate(navigationInfo);
    }
  }, [confirmedNavigation, navigationInfo]);

  return (
    <>
      <Prompt when={when} message={handlePromptBeforeNavigation} />
      <UnsavedChangesWarningModal
        open={isModalVisible}
        onSaveChanges={onSaveChangesHandler}
        onDiscardChanges={onDiscardChangesHandler}
        onCancel={closeModal}
      />
    </>
  );
}
