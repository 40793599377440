import React from 'react';
import cn from 'classnames';
import { JobsiteWorkerDocument, WorkerDocumentLevel } from 'apollo/generated/client-operations';
import { JfsWorkerWithHistoricalData } from 'containers/jobsiteFormSubmission/print/types';
import { ReportSectionTitle } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/components/reportSectionTitle';
import { WorkerReportDocument } from './WorkerReportDocument';

const classes = {
  container: '',
  documentsHeader: cn('odin-mt-9'),
  documentsText: cn('odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900'),
  documentsCount: cn('odin-ml-3'),
  documentsContainer: cn('odin-h-fit odin-border-separate odin-border-spacing-6 -odin-mx-6'),
};

export type WorkerReportDocumentsProps = {
  jfsWorker: JfsWorkerWithHistoricalData;
  developerIds: string[];
  jobsiteIds: string[];
  singleItemPerRow?: boolean;
};

export function WorkerReportDocuments(props: WorkerReportDocumentsProps): React.ReactElement {
  const { jfsWorker, developerIds, jobsiteIds, singleItemPerRow } = props;

  const workerDocuments =
    jfsWorker?.historicalData.jobsiteWorkerAccess.jobsiteWorker.contractorWorker.worker.documents.edges
      ?.map(({ node }) => node)
      .filter((jobsiteDocument) => {
        const { jobsiteWorkerDocumentType } = jobsiteDocument ?? {};
        const { workerDocumentType, jobsite } = jobsiteWorkerDocumentType ?? {};
        const { key: documentKey, documentLevel, isGlobal } = workerDocumentType ?? {};

        if (!documentKey?.includes('medical') && !documentKey.includes('post-employment')) {
          if (documentLevel === WorkerDocumentLevel.Organization) {
            return developerIds.includes(jobsite?.developer?.developerId);
          }
          return isGlobal || jobsiteIds.includes(jobsite?.jobsiteId);
        }
        return false;
      }) ?? [];

  const workerDocumentsByRows: Array<JobsiteWorkerDocument[]> = workerDocuments.reduce(
    (result, item, index): JobsiteWorkerDocument[] => {
      if (singleItemPerRow || index % 2 === 0) {
        result.push([item]);
      } else {
        result[result.length - 1].push(item);
      }
      return result;
    },
    [],
  );

  const documentRows = workerDocumentsByRows.map((rowDocs) => (
    <tr key={rowDocs[0].jobsiteWorkerDocumentId}>
      {rowDocs.map(
        (workerDocument): React.ReactElement => (
          <td key={workerDocument.jobsiteWorkerDocumentId}>
            <WorkerReportDocument
              key={workerDocument.jobsiteWorkerDocumentId}
              jobsiteDocument={workerDocument}
              singleItemPerRow={singleItemPerRow}
            />
          </td>
        ),
      )}
    </tr>
  ));

  return (
    <div className={classes.container}>
      <ReportSectionTitle title="Documents" count={workerDocuments?.length} />
      {/* use table here as fragmentation is not supported for grid */}
      <table className={classes.documentsContainer}>
        <tbody>{documentRows}</tbody>
      </table>
    </div>
  );
}
