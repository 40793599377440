import { UpdateDateModalContainer } from './UpdateDateModalContainer';
import { DocumentExemptModalContainer } from './DocumentExemptModalContainer';
import { WorkerDocumentFormModalContainer } from './WorkerDocumentFormModalContainer';
import { UploadMedicalDocumentModalContainer } from './UploadMedicalDocumentModalContainer';
import { UpdateProfilePictureModalContainer } from './UpdateProfilePictureModalContainer';

const name = 'ModalContainers';

export {
  UpdateDateModalContainer,
  DocumentExemptModalContainer,
  WorkerDocumentFormModalContainer,
  UploadMedicalDocumentModalContainer,
  UpdateProfilePictureModalContainer,
  name,
};
