import React from 'react';
import cn from 'classnames';
import { getOverviewStats } from './utils';
import { DashboardStatsOverviewItemProps, DashboardStatsOverviewProps } from './types';

export const classes = {
  title: cn('odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium odin-mr-9'),
  container: (isCurrentlyOnsiteVisible: boolean): string =>
    cn(
      'odin-grid odin-grid-cols-2 odin-gap-4 sm:odin-gap-x-6',
      isCurrentlyOnsiteVisible ? 'sm:odin-grid-cols-4 lg:odin-grid-cols-7' : 'sm:odin-grid-cols-3 lg:odin-grid-cols-6',
    ),
  detailValue: (loading: boolean): string =>
    cn(
      'odin-text-2xl odin-leading-7 odin-font-bold odin-text-gray-900',
      loading && 'odin-flex odin-justify-center sm:odin-block',
    ),
  detailLabel: cn('odin-text-xs odin-leading-5 odin-font-normal odin-text-gray-900'),
  detailValueSkeleton: cn('odin-animate-pulse odin-w-4/6 odin-bg-gray-300 odin-h-8 odin-rounded-md -odin-ml-1'),
  icon: (faClasses: string): string => cn(faClasses, 'odin-text-odin-primary odin-w-6 odin-text-2xl'),
};

function OverviewStatsItem(props: DashboardStatsOverviewItemProps): React.ReactElement {
  const { label, value, iconClassName, loading } = props;
  const valueContent = loading ? <div className={classes.detailValueSkeleton} /> : value;
  return (
    <div className="odin-text-center sm:odin-text-left">
      {iconClassName && <i className={iconClassName} />}
      <div className={classes.detailValue(loading)}>{valueContent}</div>
      <div className={classes.detailLabel}>{label}</div>
    </div>
  );
}

export function DashboardStatsOverview(props: DashboardStatsOverviewProps): React.ReactElement {
  const { workerReportsData, isCurrentlyOnsiteVisible, loading } = props;
  const overviewStatsItems = getOverviewStats(workerReportsData, isCurrentlyOnsiteVisible);

  return (
    <div className={classes.container(isCurrentlyOnsiteVisible)}>
      {overviewStatsItems.map((item) => (
        <OverviewStatsItem loading={loading} {...item} />
      ))}
    </div>
  );
}
