import i18n from 'i18n-js';

// Set the locale once at the beginning of your app.
// i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.defaultLocale = 'en';
i18n.fallbacks = true;

export const addTranslations = (translations: Record<string, Record<string, string>>): void => {
  i18n.translations = {
    ...i18n.translations,
    ...Object.fromEntries(
      Object.entries(translations).map(([language, value]) => [language, { ...i18n.translations[language], ...value }]),
    ),
  };
};

export type Localize = (key: string, options?: i18n.TranslateOptions & { locale?: string }) => string;

export const localizeFactory = (defaultLanguage?: string): Localize => {
  return (key: string, options?: i18n.TranslateOptions): string => i18n.t(key, { locale: defaultLanguage, ...options });
};
