import React from 'react';
import { useGetJobsiteCardFormatsQuery, WorkerCardType } from 'apollo/generated/client-operations';
import { SelectOptionElement } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import { getWorkerCardFormatOptions } from './utils';

export function useProximityCardFormatOptions(jobsiteId: string): {
  proximityCardFormatOptions: SelectOptionElement[];
  defaultProximityCardFormatOption: SelectOptionElement;
  error: ApolloError;
} {
  const { data: getJobsiteCardFormatsData, error } = useGetJobsiteCardFormatsQuery({
    variables: {
      jobsiteId,
    },
    skip: !jobsiteId,
    fetchPolicy: 'no-cache',
  });

  const { proximityCardFormatOptions, defaultProximityCardFormatOption } = React.useMemo(() => {
    const proximityJobsiteCardFormats = getJobsiteCardFormatsData?.getJobsiteCardFormats
      ?.filter(({ workerCardFormat }) => workerCardFormat?.cardType === WorkerCardType.Proximity)
      .sort(({ isDefaultForCardType }) => (isDefaultForCardType != null ? -1 : 1));

    const proximityCardFormats = proximityJobsiteCardFormats?.map(({ workerCardFormat }) => workerCardFormat);
    const defaultProximityCardFormat =
      proximityJobsiteCardFormats?.find(({ isDefaultForCardType }) => isDefaultForCardType)?.workerCardFormat ?? null;

    const cardFormatOptions = getWorkerCardFormatOptions(proximityCardFormats);
    const defaultCardFormatOption =
      defaultProximityCardFormat &&
      cardFormatOptions.find(({ value }) => value === defaultProximityCardFormat.workerCardFormatId);

    return {
      proximityCardFormatOptions: cardFormatOptions,
      defaultProximityCardFormatOption: defaultCardFormatOption,
    };
  }, [getJobsiteCardFormatsData?.getJobsiteCardFormats]);

  return {
    proximityCardFormatOptions,
    defaultProximityCardFormatOption,
    error,
  };
}
