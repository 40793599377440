import moment from 'moment';
import { getUpdateInputValueFunction } from 'components/form';
import { DeepMap } from 'react-hook-form';
import { ensureNonUndefinedFields, getPhoneNumberAsE164 } from 'utils';
import { JobsiteAddContractorsInput } from 'apollo/generated/client-operations';
import {
  EditContractorAssignmentFormElements,
  EditContractorAssignmentInputsArgs,
} from './EditContractorAssignmentModal.forms';

export { type EditContractorAssignmentFormElements, getFormInputsHook } from './EditContractorAssignmentModal.forms';

export const getDefaultValues = (
  args: Omit<EditContractorAssignmentInputsArgs, 'editType' | 'jobsiteId' | 'jobsiteContractor'>,
): EditContractorAssignmentFormElements => {
  const { contractorsOptions, jobsitesOptions } = args;

  return {
    jobsiteId: jobsitesOptions?.length === 1 ? jobsitesOptions[0] : null,
    contractorIds: contractorsOptions?.length === 1 ? contractorsOptions : null,
    startDate: moment(),
    parentContractorId: null,
    contactInfoLabel: undefined,

    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
  };
};

export const getAddInput = (
  data: EditContractorAssignmentFormElements,
  dirtyFields: DeepMap<EditContractorAssignmentFormElements, true>,
): JobsiteAddContractorsInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return ensureNonUndefinedFields<JobsiteAddContractorsInput>({
    jobsiteId: data.jobsiteId.value,
    contractorIds: getUpdateInputValue('contractorIds', true),
    jobsiteContractorInput: {
      startDate: data.startDate.toDate(),
      parentContractorId: getUpdateInputValue('parentContractorId'),
      contactInput: {
        firstName: getUpdateInputValue('firstName'),
        lastName: getUpdateInputValue('lastName'),
        email: getUpdateInputValue('email'),
        phoneNumber: getPhoneNumberAsE164(getUpdateInputValue('phoneNumber')),
        title: getUpdateInputValue('title'),
      },
    },
  });
};
