import React from 'react';
import moment from 'moment';
import { FormInput, UseInputs, UseFormMethods, FormDefaultValue, FormInputTypes } from 'components/form';
import {
  generateJobsiteWorkerDocument,
  getDocumentPreviewUrl,
  getDocumentsWithExistingDocumentAlerts,
  workerDocumentsInput,
  workerDocumentsKeys,
} from 'containers/workerOnboarding/helpers/forms';
import {
  JobsiteWorker,
  WorkerDocumentsDataType,
  OnboardingStepProps,
  OnboardingStepKey,
  JwDocument,
} from 'containers/workerOnboarding/types';
import { otherCardTypes } from 'utils/constants';
import { DocumentKey } from 'containers/worker/utils';
import { SelectOptionsElement } from 'components/select';
import { ensureNonEmptyItems, useUpdatableState } from 'utils';

type UseNonUniqueDocumentsArgs = {
  documentKey: DocumentKey;
  documents: JwDocument[];
  addWhenEmpty?: boolean;
  loading: boolean;
};

const getNewDocumentKey = (documentKey: DocumentKey): string =>
  `${documentKey}-new-${moment().format('YYYYMMDDHHmmssSSS')}`;

export const useNonUniqueDocuments = (args: UseNonUniqueDocumentsArgs): [string[], () => void] => {
  const { documentKey, documents, addWhenEmpty, loading } = args;

  const initialDocuments = React.useMemo(() => {
    return documents.filter(({ key }) => key.startsWith(documentKey)).map(({ key }) => key);
  }, [JSON.stringify(documents)]);

  if (!loading && !initialDocuments.length && addWhenEmpty) {
    initialDocuments.push(getNewDocumentKey(documentKey));
  }

  const [nonUniqueDocuments, setNonUniqueDocuments] = useUpdatableState(initialDocuments);
  const addNonUniqueDocument = (): void => {
    setNonUniqueDocuments((current) => [...current, getNewDocumentKey(documentKey)]);
  };

  return [nonUniqueDocuments, addNonUniqueDocument];
};

type GetFormInputsArgs = Pick<
  OnboardingStepProps,
  'jobsiteWorker' | 'filteredDocumentTypeIds' | 'documents' | 'defaultFormValues'
> & {
  sstExempt: boolean;
  setSstExempt: (value: boolean) => void;
  genericDocuments: string[];
  additionalCertificationsDocuments: string[];
  addAdditionalCertificationsDocument: () => void;
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<WorkerDocumentsDataType> =>
  (form: UseFormMethods<WorkerDocumentsDataType>): FormInput<WorkerDocumentsDataType>[] => {
    const {
      jobsiteWorker,
      filteredDocumentTypeIds,
      documents,
      defaultFormValues,
      genericDocuments,
      additionalCertificationsDocuments,
      addAdditionalCertificationsDocument,
      sstExempt,
      setSstExempt,
    } = args;
    const { dirtyFields } = form?.formState ?? {};

    const withFilesDocumentIds = Object.fromEntries(
      documents
        .filter(({ key: doc }) =>
          defaultFormValues.some(
            ({ name: fieldName, value }) => value && [`${doc}-front`, `${doc}-back`].includes(fieldName as string),
          ),
        )
        .map(({ key, id }) => [key, id]),
    );

    const getPreviewUrl = (documentKey: string): string => {
      return getDocumentPreviewUrl(withFilesDocumentIds[documentKey]);
    };

    const documentsWithExistingDocumentAlerts = getDocumentsWithExistingDocumentAlerts({
      stepKey: OnboardingStepKey.WorkDocuments,
      jobsiteWorker,
      documentKeys: workerDocumentsKeys,
      documents,
    });

    const getShowExistingDocumentAlert = (documentKey: string): boolean => {
      return documentsWithExistingDocumentAlerts[documentKey];
    };

    return ensureNonEmptyItems([
      ...workerDocumentsInput({
        jobsiteWorker,
        filteredDocumentTypeIds,
        sstExempt,
        suppressRequiredWhenDocIsNotDirty: true,
        dirtyFields,
        onSSTExemptChange: setSstExempt,
        getPreviewUrl,
        getShowExistingDocumentAlert,
      }),
      ...additionalCertificationsDocuments.map((documentKey) => {
        return generateJobsiteWorkerDocument({
          documentKey,
          label: 'Additional certifications',
          getPreviewUrl,
          getShowExistingDocumentAlert,
          suppressRequiredWhenDocIsNotDirty: true,
          dirtyFields,
          requiredFields: ['front'],
        });
      }),
      addAdditionalCertificationsDocument && {
        name: 'add-additional-certifications-document',
        element: FormInputTypes.CustomContent,
        elementProps: {
          content: (
            <div onClick={addAdditionalCertificationsDocument} className="additional-document-add">
              <i className="fe fe-file-plus mr-2 odin-text-odin-primary" />
              Add another certification document
            </div>
          ),
        },
        layout: 'col-12',
      },
      ...genericDocuments.map((documentKey) => {
        return generateJobsiteWorkerDocument({
          documentKey,
          label: 'Additional document',
          options: otherCardTypes.map((otherCardType) => ({ name: otherCardType, value: otherCardType })),
          hasBack: true,
          getPreviewUrl,
        });
      }),
    ]);
  };

type DefaultFormValue = FormDefaultValue<WorkerDocumentsDataType>;

export function getDefaultValues(
  jobsiteWorker: JobsiteWorker,
  defaultFormValues: DefaultFormValue[],
): DefaultFormValue[] {
  const initialValues = {
    [`${DocumentKey.NycSiteSafetyTrainingCard}-exemption-reason`]: jobsiteWorker?.nycSstExemptionReason ?? '',
    [`${DocumentKey.NycSiteSafetyTrainingCard}-is-training-connect-card`]: false,
    [`${DocumentKey.NycSiteSafetyTrainingCard}-type`]: null as SelectOptionsElement,
    [`${DocumentKey.NycSiteSafetyTrainingCard}-issue-date`]: '',
    [`${DocumentKey.NycSiteSafetyTrainingCard}-expiration-date`]: '',

    [`${DocumentKey.OshaCard}-type`]: null as SelectOptionsElement,
    [`${DocumentKey.OshaCard}-issue-date`]: '',
    [`${DocumentKey.OshaCard}-number`]: '',

    [`${DocumentKey.GovernmentIssuedId}-type`]: null as SelectOptionsElement,
    [`${DocumentKey.GovernmentIssuedId}-state-issued`]: '',
    [`${DocumentKey.GovernmentIssuedId}-number`]: '',
    [`${DocumentKey.GovernmentIssuedId}-issue-date`]: '',
    [`${DocumentKey.GovernmentIssuedId}-expiration-date`]: '',

    [`${DocumentKey.LIRRBlueTraining}-expiration-date`]: '',
    [`${DocumentKey.ConfinedSpacesTraining}-expiration-date`]: '',
  };

  const fileValuesMap = Object.fromEntries(
    workerDocumentsKeys.flatMap((documentType) => [
      [`${documentType}-front`, null],
      [`${documentType}-back`, null],
    ]),
  );
  const defaultValuesMap = Object.fromEntries(defaultFormValues.map(({ name, value }) => [name, value]));
  const mergedDefaultValues = { ...initialValues, ...fileValuesMap, ...defaultValuesMap };

  return Object.entries(mergedDefaultValues).map(([name, value]) => ({ name, value }));
}
