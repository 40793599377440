import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, Table, TableContainer } from '@odin-labs/components';
import {
  useGetContractorContainerContractorWorkersQuery,
  GetContractorContainerContractorWorkersDocument,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useResettableState } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { LoadingError } from 'components/loadingError';
import { ContractorTabProps, ContractorWorkersFilters, ContractorWorker, Jobsite } from 'containers/contractor/types';
import { getWorkerColumns, getWorkerFilterItems } from 'containers/contractor/helpers/tables';
import { RemoveContractorWorkerModal } from 'containers/contractor/modals';

const workerProfileUrl = '/worker/';
const refetchQueries = [GetContractorContainerContractorWorkersDocument];

export function ContractorWorkersTabContainer(props: ContractorTabProps): ReactElement {
  const { contractorId, onTabApiChange } = props;

  const {
    value: selectedContractorWorkerId,
    setValue: openRemoveContractorWorkerModal,
    resetValue: closeRemoveContractorWorkerModal,
  } = useResettableState<string>(null);

  const { currentUser: user } = React.useContext(AuthContext);
  const history = useHistory();

  const { page, jobsiteIds, search, updateUrl } = usePageQueryParams();
  const offset = page * limit;

  const { data, loading, error, refetch } = useGetContractorContainerContractorWorkersQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      contractorId,
      includeArchived: true,
      contractorContractorWorkersInput: {
        paginationInput: { limit, offset },
        jobsiteIds,
        search,
      },
    },
  });

  const contractor = data?.getContractor;
  const jobsites = contractor?.jobsiteContractors.edges.map((jce) => jce.node.jobsite as Jobsite);

  const columns = React.useMemo(
    () => getWorkerColumns({ user, openRemoveContractorWorkerModal }),
    [user, openRemoveContractorWorkerModal],
  );
  const filterItems = React.useMemo(
    () => getWorkerFilterItems({ jobsites, jobsiteIds, search }),
    [jobsites, jobsiteIds, search],
  );

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<ContractorWorkersFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: contractorWorker }: { data: ContractorWorker }): void => {
    history.push(`${workerProfileUrl}${contractorWorker.worker.workerId}`);
  };

  const workers = contractor?.contractorWorkers.edges.map((c) => c.node) ?? [];
  const workersCount = contractor?.contractorWorkers?.count ?? 0;
  const pageCount = Math.ceil(workersCount / limit);

  return (
    <>
      <TableContainer className="contractor-workers-container">
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={workers}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
        />
      </TableContainer>
      <RemoveContractorWorkerModal
        isOpen={!!selectedContractorWorkerId}
        onCancel={closeRemoveContractorWorkerModal}
        onConfirm={closeRemoveContractorWorkerModal}
        contractorWorkerId={selectedContractorWorkerId}
        refetchQueries={refetchQueries}
      />
    </>
  );
}
