import React from 'react';
import cn from 'classnames';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { JobsiteReportHeader } from './JobsiteReportHeader';
import { JobsiteReportTitle } from './JobsiteReportTitle';
import { JobsiteReportDetails } from './JobsiteReportDetails';
import { JobsiteReportWorkforceStats } from './JobsiteReportWorkforceStats';
import { JobsiteReportWorkerSessions } from './jobsiteReportWorkerSessions';

export const classes = {
  container: cn('odin-flex odin-flex-col odin-space-y-9'),
};

export type JobsiteReportProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  reportDateTimeText: string;
};

export function JobsiteReport(props: JobsiteReportProps): React.ReactElement {
  const { jobsiteFormSubmission, reportDateTimeText } = props;

  return (
    <div className={classes.container}>
      <JobsiteReportHeader jobsiteFormSubmission={jobsiteFormSubmission} reportDateTimeText={reportDateTimeText} />
      <JobsiteReportTitle jobsiteFormSubmission={jobsiteFormSubmission} />
      <JobsiteReportDetails jobsiteFormSubmission={jobsiteFormSubmission} />
      <JobsiteReportWorkerSessions jobsiteFormSubmission={jobsiteFormSubmission} />
      <JobsiteReportWorkforceStats jobsiteFormSubmission={jobsiteFormSubmission} />
    </div>
  );
}
