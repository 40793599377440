import React from 'react';
import {
  useCompletePhoneNumberVerificationMutation,
  useStartPhoneNumberVerificationMutation,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { CodeInput } from 'components/codeInput';
import { getGraphQLError } from 'utils/error';
import { Timer } from 'components/timer';

export function VerifyMobileDeviceCompleteStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, navigation, localize, language } = props;
  const { worker, verificationPhoneNumber, basicInfo } = state;
  const { workerId } = worker ?? {};
  const { goToLastStep, goToPreviousStep } = navigation;

  const [isSaving, setIsSaving] = React.useState(false);
  const [timerResetKey, setTimerResetKey] = React.useState(0);
  const [codeResetKey, setCodeResetKey] = React.useState(0);

  const resetTimer = (): void => setTimerResetKey((key) => key + 1);
  const resetCode = (): void => setCodeResetKey((key) => key + 1);

  const [completePhoneNumberVerification] = useCompletePhoneNumberVerificationMutation();
  const [startPhoneNumberVerification] = useStartPhoneNumberVerificationMutation();

  const onSubmit = async (verificationCode: string): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      const result = await completePhoneNumberVerification({
        variables: { input: { workerId, verificationCode } },
      });

      setIsSaving(false);
      const {
        success,
        worker: { editableFields },
      } = result.data.completePhoneNumberVerification;
      if (success) {
        updateState({
          worker: { ...worker, editableFields },
          basicInfo: { ...basicInfo, phoneNumber: verificationPhoneNumber },
        });
        goToLastStep();
      }
    } catch (error) {
      setIsSaving(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', 'Invalid code');
    }
  };

  const requestAnotherCode = React.useCallback(async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const result = await startPhoneNumberVerification({
        variables: { input: { workerId, phoneNumber: verificationPhoneNumber } },
      });

      if (result.data.startPhoneNumberVerification.success) {
        resetTimer();
        resetCode();
      }
    } catch (error) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  }, []);

  return (
    <div>
      <h2 className="odin-text-1.5xl odin-text-odin-black odin-mt-7.5 odin-text-center">
        {localize(Copy.self_onboarding_confirm_phone_title)}
      </h2>
      <CodeInput
        onCodeFilled={onSubmit}
        onInvalidCodeFormat={(code: string): void => {
          AlertInstance.alert('tc', 'danger', 'Invalid code format!', `"${code}"`);
        }}
        resetKey={codeResetKey}
      />
      <div className="odin-pt-10">
        <Timer
          language={language}
          textCopy={Copy.self_onboarding_confirm_phone_time_to_complete}
          onTimeout={goToPreviousStep}
          resetKey={timerResetKey}
        />
      </div>
      <div className="odin-pt-6.5 odin-px-5 odin-text-sm odin-text-center">
        {localize(Copy.self_onboarding_confirm_phone_instruction)}{' '}
        <a href="" onClick={requestAnotherCode}>
          {localize(Copy.self_onboarding_confirm_phone_request_another_code)}
        </a>
      </div>
    </div>
  );
}
