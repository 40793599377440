import React from 'react';
import { ModalForm } from 'components/form';
import { BanReasonModalProps } from './types';
import { getDefaultValues, getFormInputs } from './BanReasonModal.forms';

export function BanReasonModal(props: BanReasonModalProps): React.ReactElement {
  const { isOpen, closeModal, onClosed, jobsiteBan } = props;
  const isSuspension = !!jobsiteBan?.jobsiteWorker.banExpiresAt;
  const formInputs = React.useMemo(() => getFormInputs({ isSuspension }), [isSuspension]);
  const defaultValues = React.useMemo(() => getDefaultValues(jobsiteBan), [jobsiteBan]);

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      afterLeave={onClosed}
      title={`Worker ${!jobsiteBan?.jobsiteWorker?.banExpiresAt ? 'ban' : 'suspension'} details`}
      inputs={formInputs}
      onSubmit={null}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      cancelText="Close"
      actionButton={(): React.ReactElement => null}
    />
  );
}
