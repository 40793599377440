import { FormInputTypes, GridColSpan, TypedFormInputs, UseFormMethods, UseInputs } from 'components/form';
import { EditCCureClearanceFormData } from 'containers/jobsiteConfiguration/types';
import { useDidUpdateEffect } from '@odin-labs/components';
import React from 'react';
import { EditCCureClearanceInputArgs } from './types';

export const getFormInputs = ({
  cCureEnvironmentsOptions,
  cCureClearancesOptions = [],
  cCureClearance,
  isSelectable,
}: EditCCureClearanceInputArgs): TypedFormInputs<EditCCureClearanceFormData> => ({
  cCureEnvironment: {
    element: FormInputTypes.OdinSelect,
    label: 'Environment',
    elementProps: {
      options: cCureEnvironmentsOptions,
      disabled: !!cCureClearance,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  cCureClearance: {
    element: FormInputTypes.OdinSelect,
    label: 'Clearance',
    elementProps: {
      options: cCureClearance
        ? cCureClearancesOptions.filter(
            (cco) =>
              cco.entity.cCureEnvironment.cCureEnvironmentId === cCureClearance.cCureEnvironment.cCureEnvironmentId,
          )
        : cCureClearancesOptions,
      clearToNull: true,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  isSelectable: {
    element: FormInputTypes.OdinToggle,
    label: `Selectable`,
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  isDefault: {
    element: FormInputTypes.OdinToggle,
    label: `Default (if Selectable)`,
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      toggleAlignment: 'right-with-space',
      disabled: !isSelectable,
    },
  },
});

export const getFormInputsHook =
  (args: EditCCureClearanceInputArgs): UseInputs<EditCCureClearanceFormData> =>
  ({ watch, setValue }: UseFormMethods<EditCCureClearanceFormData>): TypedFormInputs<EditCCureClearanceFormData> => {
    const { cCureClearance, cCureEnvironmentsOptions, cCureClearancesOptions } = args;

    const selectedEnvironment = watch('cCureEnvironment');
    const isSelectable = watch('isSelectable');
    useDidUpdateEffect((): void => {
      if (!isSelectable) setValue('isDefault', true);
    }, [isSelectable]);

    const currentClearanceOptions = React.useMemo(() => {
      return cCureClearancesOptions.filter((cco) => {
        return cco.entity.cCureEnvironment.cCureEnvironmentId === selectedEnvironment?.value;
      });
    }, [cCureClearancesOptions, selectedEnvironment]);
    useDidUpdateEffect(() => {
      setValue('cCureClearance', null);
    }, [selectedEnvironment, currentClearanceOptions]);

    return React.useMemo(
      () =>
        getFormInputs({
          cCureEnvironmentsOptions,
          cCureClearancesOptions: currentClearanceOptions,
          cCureClearance,
          isSelectable,
        }),
      [cCureEnvironmentsOptions, currentClearanceOptions, selectedEnvironment, isSelectable],
    );
  };

export const getDefaultValues = (args: EditCCureClearanceInputArgs): EditCCureClearanceFormData => {
  const { cCureClearance, cCureEnvironmentsOptions, cCureClearancesOptions } = args;
  const { cCureEnvironment, cCureClearanceObjectId, isDefault, isSelectable } = cCureClearance ?? {
    isDefault: true,
    isSelectable: false,
  };
  const { cCureEnvironmentId, namespace } = cCureEnvironment ?? {};

  return {
    isDefault,
    isSelectable,
    cCureEnvironment:
      (cCureEnvironmentId && cCureEnvironmentsOptions?.find((opt) => opt.value === cCureEnvironmentId)) ?? null,
    cCureClearance: cCureClearancesOptions?.find(
      (opt) => opt.value === `${namespace}:${cCureClearanceObjectId?.toString()}` ?? null,
    ),
  };
};
