import React from 'react';
import { ReviewCompletedProfiledTabProps } from 'containers/selfOnboarding/steps/types';
import { stepDocumentKeys } from 'containers/selfOnboarding/steps/documentStep';
import { ReviewCompletedProfileDocumentsAlerts } from './ReviewCompletedProfileDocumentsAlerts';
import { ReviewCompletedProfileDocument } from './ReviewCompletedProfileDocument';

export function ReviewCompletedProfileDocumentsTab(props: ReviewCompletedProfiledTabProps): React.ReactElement {
  const { state, loading, navigation, localize } = props;
  const { documents } = state;
  const stepDocuments = stepDocumentKeys.map((docKey) => documents?.find((d) => d.key === docKey)).filter(Boolean);

  return (
    <div className="odin-space-y-6">
      <ReviewCompletedProfileDocumentsAlerts {...props} />
      <div className="odin-grid odin-gap-6 odin-grid-cols-1 sm:odin-grid-cols-3">
        {stepDocuments?.map((document) => (
          <ReviewCompletedProfileDocument
            key={document.key}
            document={document}
            loading={loading}
            localize={localize}
            navigation={navigation}
          />
        ))}
      </div>
    </div>
  );
}
