import React from 'react';
import cn from 'classnames';
import { Spinner } from '@odin-labs/components';
import { FileType } from 'apollo/generated/client-operations';
import { useFilePreview } from 'components/filePreview';
import { PdfPreview } from 'components/thumbnail/PdfPreview';
import { DocumentProps } from './types';

export function Document(props: DocumentProps): React.ReactElement {
  const { file, className } = props;
  const { fileUrl, isLoading } = useFilePreview(file);
  const isPdf = file instanceof File ? file?.type.includes('pdf') : file.fileType === FileType.Pdf;

  if (isLoading) {
    return (
      <div className={cn(className, 'odin-h-96 odin-flex odin-items-center odin-justify-center')}>
        <Spinner size="base" className="odin-text-odin-primary" />
      </div>
    );
  }

  return (
    <a href={fileUrl} target="_blank" rel="noreferrer">
      {isPdf ? (
        <PdfPreview isThumbnail fileURL={fileUrl} alt="pdf-preview" className="odin-h-96" />
      ) : (
        <img src={fileUrl} alt="document-file" className="odin-w-full odin-h-96 odin-object-cover" />
      )}
    </a>
  );
}
