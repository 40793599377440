import { GetCurrentSessionQuery, UserRoleKey } from 'apollo/generated/client-operations';

export type Auth = GetCurrentSessionQuery['getCurrentSession'];
export type User = Auth['user'];
export type UserRole = User['roles'][number];

export const userHasAccess = (userRoles: UserRole[], aclRoles: UserRoleKey[], objectId?: string): boolean => {
  if (userRoles?.length) {
    if (objectId) {
      return userRoles.some((role) => role.object.objectId === objectId && aclRoles.includes(role.key));
    }
    return userRoles.some((role) => aclRoles.includes(role.key));
  }
  return false;
};

export const userHasAnyRole = (user: Auth['user'], aclRoles: UserRoleKey[]): boolean => {
  return user?.roles.some((role) => aclRoles.includes(role.key)) ?? false;
};

const adminRoles = [UserRoleKey.Admin, UserRoleKey.Super];
export const userIsAdmin = (user: Auth['user']): boolean => userHasAnyRole(user, adminRoles);

export const userHasSomeRoleOtherThan = (user: Auth['user'], aclRoles: UserRoleKey[]): boolean => {
  return user?.roles.some((role) => !aclRoles.includes(role.key)) ?? false;
};

export const userHasRole = (user: Auth['user'], aclRole: UserRoleKey): boolean => {
  return user?.roles.some((role) => role.key === aclRole) ?? false;
};

export const userHasNoRoles = (user: Auth['user'], aclRoles: UserRoleKey[]): boolean => !userHasAnyRole(user, aclRoles);
