import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, SortingRule, Table, TableContainer } from '@odin-labs/components';
import { paginationSizePerPage as limit } from 'utils/constants';
import { useQueryOrderBy, useResettableState } from 'utils';
import { LoadingError } from 'components/loadingError';
import {
  GetContractorContainerContractorSubcontractorsDocument,
  GetContractorContainerContractorUsersDocument,
  GetUsersOrderByField,
  UserAssignmentType,
  useGetContractorContainerContractorUsersQuery,
} from 'apollo/generated/client-operations';
import { ContractorTabProps, ContractorUsersFilters, ContractorMember } from 'containers/contractor/types';
import { AuthContext } from 'auth';
import { RemoveUserRoleModal } from 'containers/users/modals';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { SortOrder } from 'utils/useQueryOrderBy';
import { getFilterItems, getColumns, orderByFields } from './ContractorUsersTabContainer.tables';

const workerProfileUrl = '/worker/';
const refetchQueries = [
  GetContractorContainerContractorSubcontractorsDocument,
  GetContractorContainerContractorUsersDocument,
];

export function ContractorUsersTabContainer(props: ContractorTabProps): ReactElement {
  const { contractorId, onTabApiChange } = props;

  const {
    value: selectedContractorMemberId,
    setValue: openRemoveContractorUserModal,
    resetValue: closeRemoveContractorUserModal,
  } = useResettableState<string>(null);

  const { currentUser: user } = React.useContext(AuthContext);
  const history = useHistory();

  const { page, orderBy: defaultSortField, orderByDesc: isDescending, updateUrl } = usePageQueryParams();
  const offset = page * limit;

  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetUsersOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetUsersOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const { data, loading, error, refetch } = useGetContractorContainerContractorUsersQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      contractorId,
      includeArchived: true,
      contractorContractorMemberInput: {
        paginationInput: { limit, offset },
        orderBy,
      },
      userRolesInput: {
        objectName: 'Contractor',
        objectId: contractorId,
      },
    },
  });

  const contractor = data?.getContractor;

  const columns = React.useMemo(
    () => getColumns({ user, contractorId, openRemoveContractorUserModal }),
    [user, contractorId, openRemoveContractorUserModal],
  );
  const filterItems = React.useMemo(() => getFilterItems(), []);

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const onSortByChangeHandler = (sortBy: Array<SortingRule<ContractorMember>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<ContractorUsersFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: contractorWorker }: { data: ContractorMember }): void => {
    history.push(`${workerProfileUrl}${contractorWorker?.user?.worker.workerId}/user-roles`);
  };

  const users = contractor?.contractorMembers.edges.map((c) => c.node) ?? [];
  const usersCount = contractor?.contractorMembers?.count ?? 0;
  const pageCount = Math.ceil(usersCount / limit);

  return (
    <>
      <TableContainer className="contractor-users-container">
        <Filter items={filterItems} loading={loading} onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={users}
          initialState={{ sortBy: tableSortBy, pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          onSortByChange={onSortByChangeHandler}
          disableGlobalFilter
        />
      </TableContainer>
      <RemoveUserRoleModal
        isOpen={!!selectedContractorMemberId}
        onCancel={closeRemoveContractorUserModal}
        onConfirm={closeRemoveContractorUserModal}
        userRoleType={UserAssignmentType.Contractor}
        memberUserAccountId={null}
        memberUserAssignmentId={selectedContractorMemberId}
        refetchQueries={refetchQueries}
      />
    </>
  );
}
