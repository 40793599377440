import React from 'react';
import cn from 'classnames';
import { Table, TableContainer } from '@odin-labs/components';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { getColumns } from './FormSubmissionReportContractorWorkersCount.tables';

const classes = {
  container: cn('print:odin-break-inside-avoid'),
};

export type FormSubmissionReportContractorWorkersCountProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  loading: boolean;
};

export function FormSubmissionReportContractorWorkersCount(
  props: FormSubmissionReportContractorWorkersCountProps,
): React.ReactElement {
  const { jobsiteFormSubmission, loading } = props;

  const contractors = React.useMemo(() => {
    return (
      jobsiteFormSubmission?.jobsiteContractors.edges
        .filter(({ node: jfsC }) => jfsC.extraData?.workersCount)
        .map(({ node: jfsC }) => {
          const { jobsiteContractor, extraData } = jfsC;
          const { contractor } = jobsiteContractor;
          const { contractorId, organization } = contractor;
          const { name: contractorName } = organization;
          const { workersCount } = extraData ?? {};
          return {
            contractorId,
            contractorName,
            workersCount,
          };
        }) ?? []
    );
  }, [jobsiteFormSubmission]);

  const columns = React.useMemo(() => getColumns(), []);

  return (
    <div>
      <TableContainer className={classes.container}>
        <Table loading={loading} columns={columns} data={contractors} remote disableGlobalFilter disableSortBy />
      </TableContainer>
    </div>
  );
}
