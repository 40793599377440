import React from 'react';
import { Form, FormOnSubmit } from 'components/form';
import { Copy } from 'containers/userPendingChange/helpers/languages';
import { StepActions } from 'containers/userPendingChange/steps/components/StepActions';
import { StepInfo } from 'containers/userPendingChange/steps/components/StepInfo';
import { StepInfoTitle } from 'containers/userPendingChange/steps/components/StepInfoTitle';
import { PendingChangeInfoFormData } from 'containers/userPendingChange/steps/types';
import { UserPendingChangeStep, UserPendingChangeStepProps } from 'containers/userPendingChange/types';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { getUserFullName } from 'utils';
import { UserPendingChangeType } from 'apollo/generated/client-operations';
import { getFormInputs, getDefaultValues } from './PendingChangeInfoStep.forms';

export function PendingChangeInfoStep(props: UserPendingChangeStepProps): React.ReactElement {
  const { localize, language, setCurrentStep, changeType, change, openCancelUserChangeModal } = props;

  const onSubmit: FormOnSubmit<PendingChangeInfoFormData> = async (): Promise<void> => {
    setCurrentStep(UserPendingChangeStep.UserValidation);
  };

  const inputs = React.useMemo(() => getFormInputs({ changeType }), [changeType]);
  const defaultValues = React.useMemo(() => getDefaultValues(changeType, change), [changeType, change]);
  const localization = React.useMemo(() => ({ localize, copy: Copy }), [localize]);

  const changeUserName = getUserFullName(change.submittedBy);
  const titleCopy =
    changeType === UserPendingChangeType.Email ? Copy.email_pending_change_header : Copy.phone_pending_change_header;
  const subtitleCopy =
    changeType === UserPendingChangeType.Email
      ? Copy.email_pending_change_instructions
      : Copy.phone_pending_change_instructions;

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle title={localize(titleCopy)} subtitle={localize(subtitleCopy, { userName: changeUserName })} />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        renderBelow={
          <StepActions
            localize={localize}
            continueActionCopy={Copy.get_started}
            cancelActionCopy={Copy.cancel_change}
            onCancel={openCancelUserChangeModal}
          />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        localization={localization}
      />
    </div>
  );
}
