import React from 'react';
import { SelfOnboardingStepKey, useGetSelfOnboardingCurrentStepLazyQuery } from 'apollo/generated/client-operations';
import { DeferredPromise } from 'utils';

export function useGetIsSelfOnboardingClosed(): { isSelfOnboardingClosed: () => Promise<boolean> } {
  const promiseRef = React.useRef(new DeferredPromise<boolean>());

  const [fetchIsSelfOnboardingClosed] = useGetSelfOnboardingCurrentStepLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (response): void => {
      const isSelfOnboardingClosed =
        response.getCurrentSession.user.worker.selfOnboardingCurrentStep === SelfOnboardingStepKey.Closed;
      promiseRef.current.resolve(isSelfOnboardingClosed);
    },
    onError: (error) => {
      promiseRef.current.reject(error);
    },
  });

  const isSelfOnboardingClosed = (): Promise<boolean> => {
    fetchIsSelfOnboardingClosed();
    return promiseRef.current.promise;
  };

  return { isSelfOnboardingClosed };
}
