import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { ExtraProps, Tab, useTabs, UseTabsArgs, UseTabsResult } from '@odin-labs/components';
import { ScrollSpyTabsPagesContainerId } from './ScrollSpyTabsPages';

const useCurrentRoutedTab = (tabs: Tab[]): Tab => {
  const location = useLocation();
  const currentTab = tabs.find((tab) => {
    const { pathname, hash } = location;
    return matchPath(pathname + (hash ?? ''), {
      path: tab.path,
    })?.isExact;
  });

  React.useEffect(() => {
    if (currentTab) {
      if (location.hash) {
        document.getElementById(location.hash.substring(1))?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        document.getElementById(ScrollSpyTabsPagesContainerId)?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [currentTab?.name]);

  return currentTab;
};

export type UseRoutedTabsArgs<T extends ExtraProps = ExtraProps> = Omit<UseTabsArgs<T>, 'currentTabIndex'>;
export type UseRoutedTabsResult = UseTabsResult;

export const useScrollSpyTabs = <T extends ExtraProps = ExtraProps>(args: UseRoutedTabsArgs<T>): UseRoutedTabsResult =>
  useTabs<T>({
    ...args,
    useCurrentTab: useCurrentRoutedTab,
  });
