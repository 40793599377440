import React from 'react';

type AcknowledgmentProgressProps = { value?: string };

export const AcknowledgmentProgress = React.forwardRef(
  (props: AcknowledgmentProgressProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value } = props;
    const text = `${(Number(value || 0) * 100).toFixed(0)}%`;
    return (
      <div className="odin-mb-5.5" ref={ref}>
        <div className="odin-mb-2">Acknowledgment progress</div>
        <div className="odin-text-base odin-font-medium odin-h-10 odin-flex odin-items-center">{text}</div>
      </div>
    );
  },
);
