import React from 'react';
import { ServerFile } from 'types';
import { DocumentFilePreview } from 'components/documentFilePreview';
import { getFormattedDate, getUserFullName, kebabToTitleCase } from 'utils';
import { getPrettyFormattedShortDateTime } from 'utils/dates';
import { WorkerOnboardingDocumentReportProps } from './types';

export interface DocumentDetailProps {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

function DocumentDetail({ label, value }: DocumentDetailProps): JSX.Element {
  return value ? (
    <div>
      <span className="odin-text-sm odin-font-medium">{label}</span>:&nbsp;
      <span className="odin-text-sm">{value}</span>
    </div>
  ) : null;
}

const hiddenFields = ['acknowledgment-progress-value', 'acknowledgment-status'];

export function WorkerOnboardingDocumentReport(props: WorkerOnboardingDocumentReportProps): React.ReactElement {
  const { jobsiteWorkerDocument, singleItemPerRow } = props;
  const { latestVersion: document, jobsiteWorkerDocumentType } = jobsiteWorkerDocument ?? {};

  const { workerDocumentType, jobsite } = jobsiteWorkerDocumentType ?? {};
  const { name: docTypeName } = workerDocumentType;
  const fieldValues = document?.additionalFieldValues?.filter(Boolean);
  const { updatedBy, createdBy, updatedAt, createdAt } = document?.objectHistory ?? {};
  const uploadedBy = getUserFullName(updatedBy ?? createdBy);
  const uploadedOn = getFormattedDate(updatedAt ?? createdAt);

  const documentScope = workerDocumentType?.isGlobal ? 'Global' : `Jobsite: ${jobsite?.name}`;

  const getDocumentDetailProps = (key: string, value: string): DocumentDetailProps => {
    const label = kebabToTitleCase(key);
    switch (key) {
      case 'acknowledgment-date':
        return { label, value: getPrettyFormattedShortDateTime(value) };
      case 'acknowledgment-ip-address':
        return { label: 'Acknowledgment IP Address', value };
      case 'acknowledgment-progress-fraction':
        return { label: 'Acknowledgment Progress', value: `${(Number(value || 0) * 100).toFixed(0)}%` };

      default:
        if (key.endsWith('date')) {
          return { label, value: getFormattedDate(value) };
        }
        return { label, value };
    }
  };

  return (
    <div
      className={
        'odin-h-full odin-border odin-border-gray-200 odin-rounded-md odin-p-6 odin-space-y-6 ' +
        'odin-divide-y odin-divide-gray-200'
      }
    >
      <div>
        <h3 className="odin-mb-1">{docTypeName}</h3>
        <div className="odin-space-y-1">
          <div
            className={
              'odin-flex odin-flex-col sm:odin-flex-row oodin-gap-y-1 sm:odin-gap-x-3 ' +
              'din-text-sm odin-text-odin-black'
            }
          >
            <span>{documentScope ?? <>&nbsp;</>}</span>
            <DocumentDetail label="Uploaded on" value={uploadedOn} />
            <DocumentDetail label="Uploaded by" value={uploadedBy} />
          </div>
          <div className="odin-flex odin-flex-col sm:odin-flex-row odin-gap-y-1 sm:odin-gap-x-3">
            {fieldValues
              ?.filter(({ key }) => !hiddenFields.includes(key))
              .map(({ key, value }) => (
                <DocumentDetail key={key} {...getDocumentDetailProps(key, value)} />
              ))}
          </div>
        </div>
      </div>
      <div className="odin-grid odin-grid-cols-1 odin-gap-y-3 sm:odin-grid-cols-2 sm:odin-gap-x-3">
        {document?.files.map((file: ServerFile) => (
          <DocumentFilePreview key={file.fileId} file={file} singleItemPerRow={singleItemPerRow} />
        ))}
      </div>
    </div>
  );
}
