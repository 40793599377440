import React from 'react';
import { UserAssignmentType, GetUsersOrderByField, Role } from 'apollo/generated/client-operations';
import { getFormattedPhoneNumber, getInitialsForUser } from 'utils';
import { User, UsersFilters } from 'containers/users/types';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  FilterItems,
} from '@odin-labs/components';
import { Contractor } from 'graphql/server/queries/types';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { DateTimeCell, DateTimeCellData } from 'containers/jobsiteAccessActivity/helpers/cells';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';

export type UserColumn = Column<User>;

export const getColumns = (): UserColumn[] => [
  {
    id: 'user',
    Header: 'User',
    accessor: (user: User): string => {
      const { firstName, lastName } = user.identity ?? {};
      return [firstName, lastName].join(' ');
    },
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} hideDetails />,
    componentProps: (user: User): AvatarCellComponentProps => ({
      src: user?.worker?.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(user),
      objectFit: 'cover',
      size: ['md', 'xl:xs'],
    }),
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: (user: User): string => user.identity.email,
  },
  {
    id: 'phoneNumber',
    Header: 'Phone Number',
    accessor: (user: User): string => getFormattedPhoneNumber(user.identity.phoneNumber),
  },
  {
    id: 'role',
    Header: 'Role',
    // cellClassName: cn('odin-whitespace-pre-wrap'),
    accessor: (): string[] => [],
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (user: User): ChipsCellComponentProps => ({
      chips: user.roles.map((role) => ({
        text: role.object.objectDetails.name,
        secondaryText: role.displayName,
        withDot: false,
      })),
    }),
    disableSortBy: true,
  },
  {
    id: 'lastSessionDate',
    Header: 'Last Session',
    accessor: (user: User): DateTimeCellData => {
      const { createdAt } = user.lastSession ?? {};
      const timeZoneName = ''; // moment.tz(createdAt, timeZone).zoneAbbr();
      return createdAt && { date: new Date(createdAt), timeZoneName };
    },
    Cell: DateTimeCell,
  },
  {
    id: 'lastSessionVersion',
    Header: 'Odin version',
    accessor: (user: User): string => user.lastSession?.appVersion,
  },
];

export const getFilterItems = ({
  userAssignmentType,
  jobsites,
  contractors,
  roles,
  developerIds,
  jobsiteIds,
  contractorIds,
  roleKeys,
  search,
}: {
  userAssignmentType: UserAssignmentType;
  jobsites: Jobsite[];
  contractors: Contractor[];
  roles: Role[];
  developerIds: string[];
  jobsiteIds: string[];
  contractorIds: string[];
  roleKeys: string[];
  search: string;
}): FilterItems<UsersFilters> => ({
  ...(userAssignmentType === UserAssignmentType.Jobsite
    ? {
        developerIds: {
          header: 'Client',
          type: 'dropdown',
          defaultValue: developerIds,
          componentProps: {
            options: getDeveloperOptionsFromJobsites(jobsites),
            allowSearch: false,
          },
        },
        jobsiteIds: {
          header: 'Jobsite',
          type: 'dropdown',
          defaultValue: jobsiteIds,
          componentProps: {
            options: getJobsitesOptions(jobsites) ?? [],
          },
        },
      }
    : undefined),
  ...(userAssignmentType === UserAssignmentType.Contractor
    ? {
        contractorIds: {
          header: 'Contractor',
          type: 'dropdown',
          defaultValue: contractorIds,
          componentProps: {
            options: getContractorsOptions(contractors) ?? [],
            isVirtual: true,
          },
        },
      }
    : undefined),
  roleKeys: {
    header: 'Role',
    type: 'dropdown',
    defaultValue: roleKeys,
    componentProps: {
      options: roles?.map((role) => ({ value: role.key, label: role.displayName })) ?? [],
      isVirtual: true,
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});

export const orderByFields: Record<string, GetUsersOrderByField> = {
  user: GetUsersOrderByField.Name,
  email: GetUsersOrderByField.Email,
  phoneNumber: GetUsersOrderByField.PhoneNumber,
  lastSessionDate: GetUsersOrderByField.LastActiveAt,
  lastSessionVersion: GetUsersOrderByField.AppVersion,
};
