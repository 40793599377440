import { link, meta } from 'pages/assets';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { PageProps } from './types';

export function Page(props: PageProps): ReactElement {
  const { route, children } = props;
  return (
    <>
      <Helmet title={route.title} link={link} meta={[...meta, ...route.meta]} />
      {children}
    </>
  );
}
