import React from 'react';
import { SearchBoxInputViewProps, SearchContext, getFaIcon } from '@odin-labs/components';
import cn from 'classnames';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { AutocompleteSearchType } from './types';
import { DEFAULT_AUTOCOMPLETE_MINIMUM_CHARACTERS } from './utils';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  onAutocompleteSearchStarted?: (autocompleteSearchType: AutocompleteSearchType) => void;
};

const classes = {
  input: cn(
    'odin-max-w-4xl',
    '!odin-text-base !odin-leading-5 ip odin-pl-9 odin-pr-2.5 odin-py-2.5',
    'odin-border odin-border-transparent odin-rounded-md !odin-shadow-none',
    'odin-bg-gray-100 focus:odin-bg-white',
  ),
  iconHolder: cn(
    'odin-absolute odin-z-10 odin-inset-y-0 odin-left-0 odin-pl-3',
    'odin-flex odin-items-center odin-pointer-events-none',
  ),
};

const SearchIcon = getFaIcon({ icon: faSearch, className: 'odin-text-odin-primary' });

export function SearchBoxInputView(props: SearchBoxInputViewProps): React.ReactElement {
  const { getAutocomplete, getInputProps, openMenu } = props;

  const {
    driver: { setSearchTerm },
  } = React.useContext(SearchContext);

  const { className, onAutocompleteSearchStarted, ...restInputProps } = getInputProps<InputProps>({
    placeholder: 'Find a worker',
    onFocus: (ev: React.FocusEvent<HTMLInputElement, Element>): void => {
      if (!ev.target.value) {
        onAutocompleteSearchStarted?.(AutocompleteSearchType.suggestions);
        setSearchTerm('', { autocompleteSuggestions: true, autocompleteResults: false });
        openMenu();
      }
    },
    onChange: (ev: React.ChangeEvent<HTMLInputElement>): void => {
      if (ev.target.value.length >= DEFAULT_AUTOCOMPLETE_MINIMUM_CHARACTERS) {
        onAutocompleteSearchStarted?.(AutocompleteSearchType.results);
      } else if (!ev.target.value) {
        onAutocompleteSearchStarted?.(AutocompleteSearchType.suggestions);
      }
    },
  });

  const inputRef = React.useRef<HTMLInputElement>(null);
  const onSearchIconClick = React.useCallback((): void => inputRef.current?.focus(), []);

  return (
    <div className="sui-search-box__wrapper odin-relative">
      <div className={classes.iconHolder} onClick={onSearchIconClick}>
        <SearchIcon aria-hidden="true" />
      </div>
      <input {...restInputProps} className={cn(className, classes.input)} ref={inputRef} />
      {getAutocomplete()}
    </div>
  );
}
