import React from 'react';
import cn from 'classnames';
import { Column } from '@odin-labs/components';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistoryFormat } from 'components/objectHistory/types';
import { WorkerComment, ContractorWorker } from 'containers/print/types';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import moment from 'moment';
import { Link } from 'react-router-dom';

// comments table columns
export type WorkerCommentColumn = Column<WorkerComment>;

export const getCommentsColumns = (): WorkerCommentColumn[] => [
  {
    id: 'jobsite',
    Header: 'Jobsite',
    accessor: (comment: WorkerComment): string => comment?.jobsite?.name,
  },
  {
    id: 'createdAt',
    Header: 'Date',
    accessor: (comment: WorkerComment): WorkerComment => comment,
    Cell: ({ value }: { value: WorkerComment }): React.ReactElement => (
      <ObjectHistory object={value} format={ObjectHistoryFormat.Time} />
    ),
  },
  {
    id: 'createdBy',
    Header: 'Created By',
    accessor: (comment: WorkerComment): WorkerComment => comment,
    Cell: ({ value }: { value: WorkerComment }): React.ReactElement => (
      <ObjectHistory object={value} format={ObjectHistoryFormat.Name} />
    ),
  },
  {
    id: 'comment',
    Header: 'Comment',
    cellClassName: cn('odin-whitespace-pre-line odin-py-2'),
    accessor: 'comment',
  },
];

// employer table columns
export type ContractorWorkerColumn = Column<ContractorWorker>;

export const getContractorsColumns = (): ContractorWorkerColumn[] => [
  {
    id: 'company',
    Header: 'Company',
    accessor: (contractorWorker: ContractorWorker): React.ReactNode => {
      return (
        contractorWorker?.contractor && (
          <Link to={`/contractor/${contractorWorker.contractor.contractorId}`}>
            {contractorWorker.contractor.organization.name}
          </Link>
        )
      );
    },
  },
  {
    id: 'active',
    Header: 'Active',
    accessor: (contractorWorker: ContractorWorker): string => {
      const startDate = getISODateTimeToFormattedDate(contractorWorker?.startDate) || null;
      const endDate = getISODateTimeToFormattedDate(contractorWorker?.endDate) || null;
      const isActive = Boolean(
        (endDate && moment().isBetween(startDate, endDate)) || (!endDate && moment().isAfter(startDate)),
      );
      return isActive ? 'Yes' : 'No';
    },
  },
  {
    id: 'payrollId',
    Header: 'Payroll ID',
    accessor: (contractorWorker: ContractorWorker): string => contractorWorker?.payrollId,
  },
  {
    id: 'startDate',
    Header: 'Start Date',
    accessor: (contractorWorker: ContractorWorker): string =>
      getISODateTimeToFormattedDate(contractorWorker?.startDate),
  },
  {
    id: 'endDate',
    Header: 'End Date',
    accessor: (contractorWorker: ContractorWorker): string => getISODateTimeToFormattedDate(contractorWorker?.endDate),
  },
];
