import React from 'react';
import cn from 'classnames';

/**
 * This is a helper function which returns a function to be passed to the ref prop of a the referenced component.
 * It handles both innerRef and outerRef in a component.
 * `innerRef` is used for a ref which is used internally within the component.
 * `outerRef` is used for a ref which is passed outside the component, as forwarded ref.
 * @returns a function to be passed to the ref prop of the referenced component
 */
export function setRefFactory<T>(args: {
  innerRef?: React.ForwardedRef<T | undefined | null>;
  outerRef?: React.ForwardedRef<T>;
}): (input: T) => void {
  const { innerRef, outerRef } = args;
  return React.useCallback(
    (input: T): void => {
      if (innerRef) {
        if (typeof innerRef === 'function') {
          innerRef(input);
        } else {
          innerRef.current = input;
        }
      }
      if (outerRef) {
        if (typeof outerRef === 'function') {
          outerRef(input);
        } else {
          outerRef.current = input;
        }
      }
    },
    [innerRef, outerRef],
  );
}

export const toggleBorderClasses = cn('odin-shadow-sm odin-border odin-border-gray-100 odin-rounded-lg odin-p-6');
