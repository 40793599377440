import React, { ReactElement } from 'react';

import { ContractorJobsiteCard } from 'containers/contractor/tabs/ContractorJobsitesTabContainer/contractorJobsiteCard/ContractorJobsiteCard';
import { ContractorTabProps } from 'containers/contractor/types';

import { useGetContractorContainerContractorJobsitesQuery } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';

export function ContractorJobsitesTabContainer(props: ContractorTabProps): ReactElement {
  const { contractorId, userJobsites: jobsites, onTabApiChange } = props;

  const { data, loading, error, refetch } = useGetContractorContainerContractorJobsitesQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      contractorId,
      includeArchived: true,
    },
  });

  const contractor = data?.getContractor;

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <div className="contractor-jobsites-container odin-flex odin-flex-col odin-gap-y-7 odin-pb-7">
      {loading ? (
        <ContractorJobsiteCard loading />
      ) : (
        contractor?.jobsiteContractors.edges.map(
          ({ node }): ReactElement => (
            <ContractorJobsiteCard
              key={`${node.jobsite.jobsiteId}-jobsite-card`}
              contractor={contractor}
              jobsites={jobsites}
              jobsiteContractor={node}
            />
          ),
        )
      )}
    </div>
  );
}
