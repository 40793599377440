import React from 'react';
import cn from 'classnames';
import { SearchBoxAutocompleteViewProps as BaseSearchBoxAutocompleteViewProps } from '@odin-labs/components';
import { SearchBoxSuggestionsList } from './SearchBoxSuggestionsList';
import { SearchBoxAutocompleteList } from './SearchBoxAutocompleteList';
import { SearchBoxNoMatch } from './SearchBoxNoMatch';
import { SearchBoxInProgressList } from './SearchBoxInProgressList';
import { AutocompleteSearchType } from './types';
import { DEFAULT_AUTOCOMPLETE_MINIMUM_CHARACTERS } from './utils';

type SearchBoxAutocompleteViewProps = BaseSearchBoxAutocompleteViewProps & {
  autocompleteSearchType: AutocompleteSearchType;
  isAutocompleteSearchInProgress: boolean;
};
type ContentType = (props: SearchBoxAutocompleteViewProps) => React.ReactElement;

export function SearchBoxAutocompleteView(props: SearchBoxAutocompleteViewProps): React.ReactElement {
  const {
    autocompleteSearchType,
    isAutocompleteSearchInProgress,
    autocompletedSuggestions,
    autocompletedResults,
    getMenuProps,
    className,
    inputValue,
  } = props;

  if (inputValue && inputValue.length < DEFAULT_AUTOCOMPLETE_MINIMUM_CHARACTERS) {
    return null;
  }

  const hasAutocompletedSuggestions = !!Object.values(autocompletedSuggestions ?? {})[0]?.length;
  const hasAutocompletedResults = !!autocompletedResults.length;

  let Content: ContentType;
  switch (autocompleteSearchType) {
    case AutocompleteSearchType.results:
      if (isAutocompleteSearchInProgress) {
        Content = SearchBoxInProgressList;
      } else {
        Content = hasAutocompletedResults ? SearchBoxAutocompleteList : SearchBoxNoMatch;
      }
      break;

    case AutocompleteSearchType.suggestions:
      if (hasAutocompletedSuggestions) {
        Content = SearchBoxSuggestionsList;
      }
      break;

    default:
      break;
  }

  if (!Content) {
    return null;
  }

  return (
    <div
      {...getMenuProps({
        className: cn(
          'sui-search-box__autocomplete-container',
          'odin-max-w-4xl',
          'ip',
          'odin-rounded-md',
          'odin-py-0',
          className,
        ),
      })}
    >
      <Content {...props} />
    </div>
  );
}
