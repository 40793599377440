import { getCurrentDomain } from 'utils';
import { AppConfig, AppConfigState } from './types';

export const appConfig: AppConfig = {} as AppConfig;

export const initializeAppConfig = (appConfigState: AppConfigState): AppConfig => {
  return Object.assign(appConfig, appConfigState);
};

export const getApiBaseUrl = (): string => {
  const { graphQLUrl } = appConfig;
  if (graphQLUrl.startsWith('/')) {
    // assume the url is: /api/graphql
    const relativeUrlParts = graphQLUrl.split('/');
    relativeUrlParts[relativeUrlParts.length - 1] = ''; // get rid of the 'graphql' suffix
    const localDomainUrl = new URL(relativeUrlParts.join('/'), getCurrentDomain());
    return localDomainUrl.toString();
  }
  const url = new URL(graphQLUrl);
  return url.origin;
};

export const generateApiUrl = (...pathComponents: string[]): string => {
  return new URL(pathComponents.join('/').replace('//', '/'), getApiBaseUrl()).toString();
};
