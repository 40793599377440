import React, { ReactElement, useState } from 'react';
import { Col } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  JobsiteWorker,
  MutationUpdateJobsiteWorkerArgs,
  MutationUploadSingleFileArgs,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { AuthContext } from 'auth/authContext';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import { BaseModal } from 'components/modals';
import { signatureFormInput } from 'containers/workerOnboarding/helpers/forms';
import {
  CREATE_JOBSITE_WORKER_DOCUMENT,
  CREATE_JOBSITE_WORKER_DOCUMENT_VERSION,
  FILE_UPLOAD,
  UPDATE_JOBSITE_WORKER,
} from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { getVisibleDocuments } from 'containers/workerOnboarding/helpers/utils';
import { SignatureDataType, OnboardingStepProps, UploadFileResponse } from 'containers/workerOnboarding/types';
import { getFullNameForUser, uploadCompressedFile, useDeferredFormSubmission, useIsMounted } from 'utils';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey } from 'containers/worker/utils';
import { StepLoading } from './StepLoading';

const documentKeys = [DocumentKey.Signature];

export function SignatureStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<SignatureDataType>();
  const [removeDocumentKey, setRemoveDocumentKey] = useState<string>('');

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;
  const developerName = jobsiteWorker?.jobsiteContractor?.jobsite?.developer?.name ?? 'the developer of this jobsite';

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments();
    },
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
    awaitRefetchQueries: true,
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT, {
    onCompleted: () => {
      updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            signatureCompletedAt: getCurrentISOFormattedDateTime(),
            signatureSkipReason: null,
          },
        },
      });
    },
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT_VERSION, {
    onCompleted: () => {
      updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            signatureCompletedAt: getCurrentISOFormattedDateTime(),
            signatureSkipReason: null,
          },
        },
      });
    },
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const onSubmit: FormOnSubmit<SignatureDataType> = async (data): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      await Promise.all(
        documentKeys.map(async (documentKey) => {
          const fileIds = [];
          if (data[`${documentKey}-front` as keyof SignatureDataType]) {
            const frontFileRow = data[`${documentKey}-front` as keyof SignatureDataType];
            const serverFile = frontFileRow as ServerFile[];
            if (serverFile[0]?.fileId) {
              fileIds.push(serverFile[0]?.fileId);
            } else {
              const defaultFile = frontFileRow as File[];
              if (defaultFile[0]) {
                const fileId = await uploadCompressedFile(defaultFile[0] as File, uploadFile);
                if (fileId) {
                  fileIds.push(fileId);
                }
              }
            }
          }

          const jobsiteWorkerDocumentId = documents.find(({ key }) => key === documentKey)?.id;

          if (jobsiteWorkerDocumentId) {
            return createJobsiteWorkerDocumentVersion({
              variables: {
                jobsiteWorkerDocumentId,
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: [],
                },
              },
            });
          }

          return createJobsiteWorkerDocument({
            variables: {
              jobsiteWorkerDocumentInput: {
                jobsiteWorkerId,
                jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[documentKey],
                key: documentKey,
              },
              jobsiteWorkerDocumentVersionInput: {
                fileIds,
                additionalFieldValues: [],
              },
            },
          });
        }),
      );

      deferredSubmission.resolve(true);
    } catch (error) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    }
  };

  const overrideComplete = (reason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          signatureCompletedAt: getCurrentISOFormattedDateTime(),
          signatureSkipReason: reason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          signatureCompletedAt: null,
          signatureSkipReason: null,
        },
      },
    });
  };

  const fullName = getFullNameForUser(jobsiteWorker?.contractorWorker?.worker?.user);
  const visibleDocuments = getVisibleDocuments(jobsiteWorker);
  const loading = parentLoading || fetching;

  const inputs = React.useMemo(() => {
    return filteredDocumentTypeIds[DocumentKey.Signature] && visibleDocuments?.includes(DocumentKey.Signature)
      ? [signatureFormInput()]
      : [];
  }, [filteredDocumentTypeIds, visibleDocuments]);

  return (
    <>
      <Col xs="12">
        <fieldset disabled={!!isContractorMember}>
          <Header title="Signature" />
          <StepLoading loading={loading} hasTransparentBackground={!inputs.length} />
          {!!inputs.length && (
            <p>
              I permit {developerName}, or its assignee, to populate a sign-in sheet with the signature below based on
              below based on turnstile entry and exit times.
            </p>
          )}
          <Form
            inputs={inputs}
            ref={formRef}
            defaultValues={defaultFormValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
          />
          <BaseModal
            title="Delete document"
            onAction={(): void => {
              createJobsiteWorkerDocument({
                variables: {
                  jobsiteWorkerDocumentInput: {
                    jobsiteWorkerId,
                    jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[removeDocumentKey],
                    fileIds: [],
                    key: removeDocumentKey,
                    additionalFieldValues: [],
                  },
                },
              }).then(() => {
                refetchJobsiteWorkerDocuments();
              });
              setRemoveDocumentKey('');
            }}
            onCancel={(): void => {
              setRemoveDocumentKey('');
            }}
            isOpen={!!removeDocumentKey}
            actionText="Ok"
          >
            <div className="text-center">
              <p>
                You are about to delete <b>{fullName}&lsquo;s</b> file.
                <br />
                This action cannot be undone.
              </p>
            </div>
          </BaseModal>
        </fieldset>
      </Col>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
      />
    </>
  );
}
