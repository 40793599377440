import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import {
  JobsiteWorkerDocumentType,
  QueryGetAllDocumentsForJobsiteWorkerArgs,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { GET_ALL_DOCUMENTS_FOR_JOBSITE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import {
  GetAllDocumentsForJobsiteWorker,
  JobsiteWorkerOnboardingDocuments,
  UseJobsiteWorkerOnboardingDocumentsArgs,
} from 'containers/workerOnboarding/types';
import { to } from 'acl';
import { useJobsiteWorkerDocumentTypes } from './useJobsiteWorkerDocumentTypes';

export function useJobsiteWorkerOnboardingDocuments(
  args: UseJobsiteWorkerOnboardingDocumentsArgs,
): JobsiteWorkerOnboardingDocuments {
  const { jobsiteWorker } = args;
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    jobsiteWorkerDocumentTypes,
    error: jobsiteWorkerDocumentTypesError,
    loading: jobsiteWorkerDocumentTypesLoading,
  } = useJobsiteWorkerDocumentTypes(jobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId);

  const {
    data: allDocumentsForJobsiteWorkerData,
    error: allDocumentsForJobsiteWorkerError,
    loading: allDocumentsForJobsiteWorkerLoading,
    refetch: refetchAllDocumentsForJobsiteWorker,
  } = useQuery<GetAllDocumentsForJobsiteWorker, QueryGetAllDocumentsForJobsiteWorkerArgs>(
    GET_ALL_DOCUMENTS_FOR_JOBSITE_WORKER,
    {
      variables: {
        jobsiteWorkerId: jobsiteWorker?.jobsiteWorkerId,
        includePhi: user.isAllowed(to.accessPhiDocuments, jobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId),
      },
      fetchPolicy: 'no-cache',
      skip: !jobsiteWorker || !user.roles,
      notifyOnNetworkStatusChange: true,
    },
  );

  const loading: boolean = allDocumentsForJobsiteWorkerLoading || jobsiteWorkerDocumentTypesLoading;

  const error: ApolloError = allDocumentsForJobsiteWorkerError || jobsiteWorkerDocumentTypesError;

  const jobsiteWorkerDocuments = allDocumentsForJobsiteWorkerData?.getAllDocumentsForJobsiteWorker || [];

  const filteredDocumentTypeIds: Record<string, string> = jobsiteWorkerDocumentTypes.reduce(
    (result, jwDocumentType: JobsiteWorkerDocumentType) => ({
      ...result,
      [jwDocumentType?.workerDocumentType?.key]: jwDocumentType?.id,
    }),
    {},
  );

  return {
    loading,
    error,
    jobsiteWorkerDocumentTypes,
    jobsiteWorkerDocuments,
    filteredDocumentTypeIds,
    refetchJobsiteWorkerDocuments: refetchAllDocumentsForJobsiteWorker,
  };
}
