import { Moment, MomentInput } from 'moment';
import { RadioGroupOption, SelectOptionElement } from '@odin-labs/components';
import { JobsiteWorker, Worker } from 'containers/worker/types';

export enum AssignWorkerStep {
  AssignWorker = 'assignWorker',
  AlreadyAssignedWorker = 'alreadyAssignedWorker',
  CompleteJobsiteAssignment = 'completeJobsiteAssignment',
}

export type ContractorOptionElement = SelectOptionElement & { startDate: MomentInput };
export type JobsiteOptionElement = SelectOptionElement & { parentId: string };

export type AssignWorkerFormData = {
  jobsiteId: JobsiteOptionElement;
  contractorId: ContractorOptionElement;
  orientationDate: Moment;
};

export enum JobsiteAssignmentType {
  WorkingAtMultipleSites,
  WorkingAtOneSiteOnly,
}

export type CompleteJobsiteAssignmentFormData = {
  jobsiteAssignmentType: RadioGroupOption<JobsiteAssignmentType>;
};

export type AssignWorkerModalContentProps = {
  closeModal: () => void;
  onConfirm: (
    data: AssignWorkerFormData & { siblingJobsiteWorkers?: JobsiteWorker[] },
    nextStep: AssignWorkerStep,
  ) => void;
  selectedWorker: Worker;
  defaultContractorId?: string;
  jobsiteWorkers?: JobsiteWorker[];
};

export interface CompleteJobsiteAssignmentModalContentProps {
  closeModal: () => void;
  onConfirm: (nextStep: AssignWorkerStep) => void;
  selectedWorker: Worker;
  assignWorkerModalState: AssignWorkerFormData & { siblingJobsiteWorkers?: JobsiteWorker[] };
}
