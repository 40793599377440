import React, { ReactElement } from 'react';
import { FormDialog } from 'dialog';
import { ChangeType } from 'apollo/generated/client-operations';
import { getIcon } from 'utils/ui';
import { FormOnSubmit } from 'components/form';
import { ensureNonUndefinedFields } from 'utils';
import {
  EditFormSubmissionDocumentModalProps,
  EditFormSubmissionDocumentFormData,
  EditableJobsiteFormSubmissionDocument,
} from './types';
import { getDefaultValues, getFormInputs } from './EditFormSubmissionDocumentModal.forms';

const CheckIcon = getIcon('fal fa-check');

export function EditFormSubmissionDocumentModal(props: EditFormSubmissionDocumentModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, document } = props;
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const onSubmit: FormOnSubmit<EditFormSubmissionDocumentFormData> = (
    data: EditFormSubmissionDocumentFormData,
    event,
    dirtyFields,
  ): void => {
    const { file, description } = data;
    onConfirm?.(
      ensureNonUndefinedFields<EditableJobsiteFormSubmissionDocument>({
        id: document.id,
        description,
        files: dirtyFields.file
          ? [Object.assign(file, { changeType: ChangeType.Updated, fileId: document.files[0].fileId })]
          : undefined,
      }),
    );
  };

  const formInputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues(document), [document]);

  return (
    <FormDialog
      id="editFormSubmissionDocument"
      open={isOpen}
      setOpen={onCancel}
      onIsDirtyChange={setIsFormDirty}
      title="Edit Attachment"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionIcon={CheckIcon}
      actionButtonEnabled={isFormDirty}
    />
  );
}
