import React from 'react';
import { Alert } from '@odin-labs/components';
import {
  UserPendingChangeType,
  useAuthenticateLimitedTokenDetailsMutation,
  useUserConfirmPendingChangeMutation,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { Copy } from 'containers/userPendingChange/helpers/languages';
import { StepActions } from 'containers/userPendingChange/steps/components/StepActions';
import { StepInfo } from 'containers/userPendingChange/steps/components/StepInfo';
import { StepInfoTitle } from 'containers/userPendingChange/steps/components/StepInfoTitle';
import { UserValidationFormData } from 'containers/userPendingChange/steps/types';
import { UserPendingChangeStep, UserPendingChangeStepProps } from 'containers/userPendingChange/types';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { isNotEmpty, useIsMounted } from 'utils';
import { MatchBy, getGraphQLError, hasGraphQLErrorMessage } from 'utils/error';
import { AuthContext } from 'auth';
import { getFormInputs } from './UserValidationStep.forms';

export function UserValidationStep(props: UserPendingChangeStepProps): React.ReactElement {
  const { localize, language, setCurrentStep, workerId, changeType, openCancelUserChangeModal } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState(false);
  const { authState } = React.useContext(AuthContext);

  const [authenticateLimitedTokenDetails] = useAuthenticateLimitedTokenDetailsMutation();
  const [confirmPendingChange] = useUserConfirmPendingChangeMutation();

  const onSubmit: FormOnSubmit<UserValidationFormData> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (isNotEmpty(dirtyFields)) {
        const { birthDate } = data;
        const response = await authenticateLimitedTokenDetails({ variables: { input: { birthDate, workerId } } });
        const { token } = response.data.authenticateLimitedTokenDetails;

        if (token) {
          authState.signIn(token, null);
          await confirmPendingChange({ variables: { input: { changeType, workerId } } });
          setCurrentStep(UserPendingChangeStep.PendingChangeConfirmation);
        } else {
          AlertInstance.alert('tc', 'danger', 'Authentication Failed', 'Authentication was unsuccessful');
        }
      }
    } catch (error) {
      event.preventDefault();
      if (hasGraphQLErrorMessage(error, 'passwordless', MatchBy.innerContentOfMessage)) {
        formApi.setError('birthDate', {
          message: (
            <div className="odin-my-9">
              <Alert text={localize(Copy.auth_validation_error)} />
            </div>
          ),
          shouldFocus: true,
        });
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const inputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo((): UserValidationFormData => ({ birthDate: null }), []);
  const localization = React.useMemo(() => ({ localize, copy: Copy }), [localize]);

  const subtitleCopy =
    changeType === UserPendingChangeType.Email
      ? Copy.user_validation_email_change_instructions
      : Copy.user_validation_phone_change_instructions;

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle title={localize(Copy.user_validation_header)} subtitle={localize(subtitleCopy)} />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        renderBelow={
          <StepActions localize={localize} onCancel={openCancelUserChangeModal} continueActionWithSpinner={isSaving} />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        localization={localization}
      />
    </div>
  );
}
