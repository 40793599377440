import { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { SelectOptionElement } from '@odin-labs/components';
import {
  ContractorWorker,
  GetWorkerDataQuery,
  JobsiteWorkerCheckInDirection,
  WorkerCitizenshipStatus,
  JobsiteWorkerDocument,
  StateAbbreviation,
  UserUpdateIdentityInput,
  User,
  UserRole,
  JobsiteAccessEventRejectionReason,
  WorkerInput,
} from 'apollo/generated/client-operations';

export type Worker = GetWorkerDataQuery['getWorker'];
export type WorkerComment = Worker['workerComments']['edges'][number]['node'];
export type WorkerAccessHistory = Worker['accessHistory'][number];
export type UserIdentityPendingChanges = Worker['user']['identity']['pendingChanges'];
export type JobsiteWorker = Worker['jobsiteWorkers']['edges'][number]['node'];
export type JobsiteWorkerAccess = JobsiteWorker['currentAccess'];
export type Jobsite = JobsiteWorker['jobsiteContractor']['jobsite'];
export type JobsiteModule = Jobsite['modules'][number];
export type JobsiteOnboardingModule = Extract<JobsiteModule, { __typename?: 'JobsiteOnboardingModule' }>;
export type JobsiteAppCheckInModule = Extract<JobsiteModule, { __typename?: 'JobsiteAppCheckInModule' }>;

export interface UpdateWorkerAndIdentityResponse {
  updateWorker: ContractorWorker;
  updateUserIdentity: User;
}

export type MutationUpdateWorkerAndIdentityArgs = {
  workerId: string;
  workerInput: WorkerInput;
  userUpdateIdentityInput: UserUpdateIdentityInput;
};

export interface UseWorkerData {
  loading: boolean;
  error: ApolloError;
  worker: Worker;
  isLocked: boolean;
  jobsiteWorkers: JobsiteWorker[];
  jobsiteIds: string[];
  developerIds: string[];
  jobsiteNames: string[];
  memberJobsitesIds: string[];
  refetch: () => void;
  updateWorkerState: (
    updWorker: Partial<
      Pick<Worker, 'trade' | 'jobTitle'> & {
        profilePictureCropped: Pick<Worker['profilePictureCropped'], 'downloadUrl'>;
      }
    >,
  ) => void;
}

export type WorkerTabApi = {
  refetchData: () => void;
};

export type WorkerTabProps = {
  loading?: boolean;
  worker: Worker;
  isLocked: boolean;
  workerComments?: Array<WorkerComment>;
  jobsiteWorkers?: Array<JobsiteWorker>;
  userRoles?: Array<UserRole>;
  onTabApiChange?: (api: WorkerTabApi) => void;
  refetchWorkerData?: () => void;
  updateWorkerState?: UseWorkerData['updateWorkerState'];
  openChangeWorkerAccessModal?: (jobsiteWorker: JobsiteWorker) => void;
  jobsiteIds?: Array<string>;
  developerIds?: Array<string>;
  jobsiteNames?: Array<string>;
  memberJobsitesIds?: Array<string>;
  showJobsiteAccessHistory?: boolean;
};

export interface WorkerCommentTableRowData {
  comment: string;
  jobsite: string;
  createdBy: ReactElement;
  createdAt: ReactElement;
}

export interface JobsiteWorkerTableRowData {
  projectName: string;
  startDate: string;
  endDate: string;
  jobsiteWorker: JobsiteWorker;
  stickerNumber: string;
  orientationStatus: ReactElement;
  siteSafetyStatus: ReactElement;
  siteAccess: ReactElement;
}

export interface EditEmployerInfoFormData {
  supervisorName?: string;
  supervisorPhoneNumber?: string;
  cancelButton?: undefined;
  submitButton?: undefined;
}

export interface EditPersonalInfoFormDefaultData {
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  suffix?: string;
  birthDate?: string;
  ssnLastFour?: string;
  race?: string;
  gender?: string;
  primaryLanguage?: string;
  isVeteran?: boolean | string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: StateAbbreviation;
  addressZipCode?: string;
  phoneNumber?: string;
  email?: string;
  jobTitle?: string;
  emergencyContactName?: string;
  emergencyContactRelationship?: string;
  emergencyContactPhone?: string;
  trade?: string;
  unionAffiliation?: string;
  cancelButton?: undefined;
  submitButton?: undefined;
  mobileLoginPhoneNumber?: string;
}

export type EditPersonalInfoFormData = {
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  birthDate: string;
  ssnLastFour: string;
  race: SelectOptionElement;
  gender: SelectOptionElement;
  primaryLanguage: SelectOptionElement;
  citizenshipStatus: SelectOptionElement<WorkerCitizenshipStatus>;
  isVeteran: SelectOptionElement;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: SelectOptionElement<StateAbbreviation>;
  addressZipCode: string;
  phoneNumber: string;
  email: string;
  jobTitle: SelectOptionElement;
  trade: SelectOptionElement;
  unionAffiliation: SelectOptionElement;
  emergencyContactName: string;
  emergencyContactRelationship: SelectOptionElement;
  emergencyContactPhone: string;
};

export interface EditWorkInfoFormDefaultData {
  jobTitle?: string;
  trade?: string;
  unionAffiliation?: string;
  isSkilled?: boolean;
  isSupervisor?: boolean;
  cancelButton?: undefined;
  submitButton?: undefined;
}

export interface EditWorkInfoFormData {
  jobTitle?: SelectOptionElement;
  trade?: SelectOptionElement;
  unionAffiliation?: SelectOptionElement;
  isSkilled?: boolean;
  isSupervisor?: boolean;
  cancelButton?: undefined;
  submitButton?: undefined;
}

export interface JobsiteAccessEventFormData {
  jobsiteId: SelectOptionElement & Pick<Jobsite, 'timeZone'>;
  checkInDirection: SelectOptionElement<JobsiteWorkerCheckInDirection>;
  checkInDate: string;
  checkInTime: string;
  reason: SelectOptionElement;
}

export interface JobAssignmentDataType {
  layoutCol: undefined;
  jobAssignment: string;
  jobAssignmentExpirationDate: string;
  jobAssignmentStartDate: string;
  isAntipassbackExempt: boolean;
  submitButton: undefined;
}

export interface JobsiteMedicalDocumentTableRowData {
  docType: string;
  testReason: string;
  testDate: string;
  testResult: ReactElement;
  preview: ReactElement;
  updatedBy: ReactElement;
  jobsiteDocument: JobsiteWorkerDocument;
  jobsiteDocumentId: string;
  additionalNotes: string;
}

export interface GetWorkerDocumentsResponse {
  getWorkerDocuments: {
    workerDocuments: Array<JobsiteWorkerDocument>;
  };
}

export interface WorkerActivityTableRowData {
  jobsite: string;
  eventDateTime: string;
  // localizedDate: string;
  // localizedTime: string;
  status: ReactElement;
  direction: string;
  gateway: string;
  proximityCardNumber: string;
  rejectionReason: JobsiteAccessEventRejectionReason;
  actions: ReactElement;
}

export interface WorkerObservedHoursTableRowData {
  jobsite: string;
  eventDateTime: string;
  totalHoursWorked: string;
  compliant: string;
  start: string;
  end: string;
}

export interface WorkerAccessHistoryTableRowData {
  changeDate: string;
  changeToAccess: string;
  jobsiteName: string;
  banEndDate: string;
  changeApprovedBy: string;
  updatedBy: string;
  changeCategory: string;
  changeReason: string;
}

export interface CreateBadgeInfo {
  proximityCardNumber?: string;
  proximityCardFormatId?: SelectOptionElement;
  bluetoothCardNumber?: string;
  badgeExpirationDate?: Array<Date>;
  accessReason?: string;
}

export enum BadgingState {
  ChooseBadgeType = 'chooseBadgeType',
  AssignQRCode = 'assignQRCode',
  ReassignQRCode = 'reassignQRCode',
  AssignExistingBadge = 'assignExistingBadge',
  ReassignExistingBadge = 'reassignExistingBadge',
  CreatePrintJob = 'createPrintJob',
  Printing = 'printing',
  PrintCompletedNoCardNumber = 'printCompletedNoCardNumber',
  BadgeCreated = 'badgeCreated',
  ProximityCardDeactivationPrompt = 'proximityCardDeactivationPrompt',
  QRCodeDeactivationPrompt = 'qrCodeDeactivationPrompt',
  ConfirmFinished = 'confirmFinished',
  Failed = 'failed',
  IssueMobileBadge = 'issueMobileBadge',
  ReactivateBadge = 'reactivateBadge',
  IssueFacialEnrollment = 'issueFacialEnrollment',
}
