import React from 'react';
import { Link } from 'react-router-dom';
import { JobsiteContractor, contractorUrl } from 'containers/contractor/types';

export function ParentJobsiteContractorLink({
  parentContractor,
}: {
  parentContractor: JobsiteContractor['parentJobsiteContractor']['contractor'];
}): React.ReactElement {
  return parentContractor ? (
    <Link to={contractorUrl + parentContractor.contractorId}>{parentContractor.organization.name}</Link>
  ) : (
    <>N/A</>
  );
}
