import React from 'react';
import { LoadingError } from 'components/loadingError';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { useGetWorkerOnboardingDocumentReportQuery } from 'apollo/generated/client-operations';
import { Col, Container } from 'components/container';
import { WorkerOnboardingDocumentReport } from './WorkerOnboardingDocumentReport';

export function WorkerOnboardingDocumentPrintContainer(): React.ReactElement {
  const { jobsiteWorkerDocumentId } = useParams<{ jobsiteWorkerDocumentId: string }>();

  const { data, error, loading } = useGetWorkerOnboardingDocumentReportQuery({
    variables: { jobsiteWorkerDocumentId },
    fetchPolicy: 'no-cache',
  });

  const jobsiteWorkerDocument = data?.getJobsiteWorkerDocument;

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }
  return (
    <Container>
      <Col className="odin-mb-9">
        <Helmet title="ODIN - Onboarding Document Report" />
        <div className="odin-space-y-9">
          <WorkerOnboardingDocumentReport jobsiteWorkerDocument={jobsiteWorkerDocument} singleItemPerRow />
        </div>
      </Col>
    </Container>
  );
}
