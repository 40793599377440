import React from 'react';
import { useGetEntityChangesQuery } from 'apollo/generated/client-operations';
import { EntityChange } from 'containers/entityChange/types';
import { NewHeader } from 'components/header/NewHeader';
import { WorkerChangeTabProps } from 'containers/entityChange/worker/types';
import { LoadingError } from 'components/loadingError';
import { getChangesByLayout, getColumns } from 'containers/entityChange/worker/tabs/tables';
import { Table, TableContainer } from '@odin-labs/components';

function getChangesByEntityId(entityIds: string[], changes: EntityChange[]): Record<string, EntityChange[]> {
  const initialValue: Record<string, EntityChange[]> = entityIds.reduce(
    (result, entityId) => Object.assign(result, { [entityId]: [] }),
    {},
  );
  return (
    changes?.reduce((result, change) => {
      result[change.entityId].push(change);
      return result;
    }, initialValue) ?? initialValue
  );
}

export function WorkerBadges(props: WorkerChangeTabProps): React.ReactElement {
  const { loading: parentLoading, worker, changesLayout, onTabApiChange } = props;
  const { workerId } = worker ?? {};

  const workerCards = worker?.workerCards.edges.map(({ node }) => node) ?? [];
  const wcIds = workerCards.map((wc) => wc.workerCardId);

  const {
    data: workerCardsChangesData,
    loading: workerCardsChangesLoading,
    error: workerCardsChangesError,
    refetch: refetchWorkerCardsChanges,
  } = useGetEntityChangesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        // paginationInput: { limit, offset },
        filters: [{ tableName: 'worker_card', entityIds: wcIds }],
      },
    },
    skip: !workerId,
  });

  const workerCardsChanges = React.useMemo(() => {
    return getChangesByEntityId(
      wcIds,
      getChangesByLayout(workerCardsChangesData?.getEntityChanges.edges.map(({ node }) => node) ?? [], changesLayout),
    );
  }, [wcIds, workerCardsChangesData, changesLayout]);

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerCardsChanges }), [refetchWorkerCardsChanges]);

  const loading = parentLoading || workerCardsChangesLoading;
  const error = workerCardsChangesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <div className="odin-flex odin-flex-col odin-gap-9">
      {workerCards.map((workerCard) => {
        const wcChanges = workerCardsChanges[workerCard.workerCardId];
        const wcColumns = React.useMemo(() => getColumns(wcChanges, changesLayout), [wcChanges, changesLayout]);
        const wcTitle = workerCard?.cardNumber;

        return (
          <div key={workerCard.workerCardId} className="odin-flex odin-flex-col odin-gap-y-6">
            <TableContainer>
              <NewHeader size="md" title={`${wcTitle} - Changes`} actionsProps={{ headerActions: null }} />
              <Table
                loading={loading}
                columns={wcColumns}
                data={wcChanges}
                // initialState={{ sortBy: tableSortBy, pageSize: limit }}
                // pageCount={pageCount}
                // pageIndex={page}
                // remote
                // enablePagination
              />
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
}
