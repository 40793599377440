import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserRoleKey, useGetCurrentSessionHomeContainerQuery } from 'apollo/generated/client-operations';
import { to } from 'acl';
import { AuthContext } from 'auth';
import { LoadingError } from 'components/loadingError';

export function HomeContainer(): React.ReactElement {
  const { currentUser: user } = React.useContext(AuthContext);

  const { data, loading, error } = useGetCurrentSessionHomeContainerQuery({ fetchPolicy: 'no-cache' });

  const { contractorMembers, jobsiteMembers } = data?.getCurrentSession.user ?? {};

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  if (jobsiteMembers?.count) {
    const shouldRedirectToSiteOrientations = user.hasAnyRole([
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
    ]);
    if (shouldRedirectToSiteOrientations) {
      return <Redirect to="/site-orientations" />;
    }
    if (user.hasPaidAccess && user.isAllowed(to.redirectToDashboard)) {
      return <Redirect to="/dashboard" />;
    }
    return <Redirect to="/workers" />;
  }

  if (contractorMembers?.count) {
    return <Redirect to="/workers" />;
  }

  return <h3>This user is not a member of any jobsite or contractor</h3>;
}
