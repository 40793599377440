import React, { ReactElement } from 'react';
import { WorkerHeaderInfoProps } from './types';
import { classes } from './WorkerHeaderInfo.style';

export function WorkerHeaderInfo({ worker, className }: WorkerHeaderInfoProps): ReactElement {
  const user = worker?.user;
  const { firstName, lastName } = user?.identity ?? {};
  const lastActiveAt = user?.lastSession?.lastActiveAt ?? worker?.objectHistory?.createdAt;
  const lastActiveAtText = lastActiveAt ? new Date(lastActiveAt).toLocaleDateString() : null;

  return (
    <div data-test="worker-header-info" className={classes.headerInfo(className)}>
      <span className={classes.headerWorkerName}>
        {firstName} {lastName}
      </span>
      <div className={classes.headerWorkerInfo}>
        <div className={classes.headerWorkerInfoItemContainer}>
          <i className={classes.headerWorkerInfoItemIcon('fal fa-clock')} aria-hidden="true" />
          Last Active {lastActiveAtText}
        </div>
        {user?.hasAuth && (
          <div className={classes.headerWorkerInfoItemContainer}>
            <i className={classes.headerWorkerInfoItemIcon('fal fa-mobile')} aria-hidden="true" />
            Mobile
          </div>
        )}
        <div className={classes.headerWorkerInfoItemContainer}>
          <i className={classes.headerWorkerInfoItemIcon('fal fa-shield-check')} aria-hidden="true" />
          {worker?.quickCode}
        </div>
      </div>
    </div>
  );
}
