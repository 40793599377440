import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

export interface FeatureFlags {
  siteSafetyEnabled: boolean;
  workerBetaVersionEnabled: boolean;
  announcementsEnabled: boolean;
  profileLockingEnabled: boolean;
}

export const useFlags = (): FeatureFlags => useLDFlags() as FeatureFlags;
