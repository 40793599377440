import React from 'react';

export type AlreadyExistingBadgeMessageProps = {
  errorMessage: string;
  onClick?: () => void;
  canBeReactivated?: boolean;
  linkText?: string;
  afterLinkText?: string;
};

export function AlreadyExistingBadgeMessage(props: AlreadyExistingBadgeMessageProps): React.ReactElement {
  const { errorMessage, onClick, canBeReactivated, linkText, afterLinkText } = props;

  if (onClick && (canBeReactivated || afterLinkText)) {
    return (
      <span>
        {`${errorMessage}, click `}
        <a
          href=""
          onClick={(ev): void => {
            onClick();
            ev.preventDefault();
          }}
        >
          {linkText ?? 'here'}
        </a>
        {afterLinkText ?? ' to reactivate it'}
      </span>
    );
  }

  return <>{errorMessage}</>; // eslint-disable-line react/jsx-no-useless-fragment
}
