import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { faEdit, faFileInvoiceDollar, faTrash, faWrench, faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { NewMenuItemProps, SiteAccessBadge, EllipsisDropdownButton, getFixedWidthFaIcon } from '@odin-labs/components';
import {
  CreateContractorStripeInvoiceMutation,
  useCreateContractorStripeInvoiceMutation,
  useJobsiteCreateInvitationMutation,
} from 'apollo/generated/client-operations';
import { MergeUnionType } from 'types';
import { ensureNonEmptyItems, getCurrentDomain, getFormattedPhoneNumber, useBoolean } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getPrettyFormattedDate, isAfterToday } from 'utils/dates';
import { AuthContext } from 'auth';
import { to } from 'acl';
import {
  EditJobsiteAssignmentModal,
  RemoveJobsiteAssignmentModal,
  RemoveOnboardingLinkModal,
} from 'containers/contractor/modals';
import { AlertInstance } from 'components/alertNotification';
import { CopyLink } from 'components/copyLink';
import { AssociateJobsiteContractorPaymentMethodModal } from 'containers/contractor/modals/AssociateJobsiteContractorPaymentMethod';
import { ContractorJobsiteDetail } from './ContractorJobsiteDetail';
import { ContractorJobsiteCardLoadingProps, ContractorJobsiteCardProps } from './types';
import { ParentJobsiteContractorLink } from './ParentJobsiteContractorLink';
import { SiteAccessText } from './SiteAccessText';
import { PdfInstructionsDownloadLink } from './PdfInstructionsDownloadLink';

const classes = {
  skeleton: cn('odin-text-transparent odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1'),
  actionsSkeleton: cn('odin-animate-pulse odin-bg-gray-300 odin-h-8.5 odin-w-8.5 odin-rounded-md -odin-ml-1'),
};

const EditIcon = getFixedWidthFaIcon({ icon: faEdit });
const WrenchIcon = getFixedWidthFaIcon({ icon: faWrench });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });
const InvoiceIcon = getFixedWidthFaIcon({ icon: faFileInvoiceDollar });
const PaymentIcon = getFixedWidthFaIcon({ icon: faCreditCard });

type OnboardingLinkState = {
  jobsiteInvitationId: string;
  onboardingLink: string;
  paymentsOnboardingLink?: string;
};

export const generateSelfOnboardingInvitationLink = (jobsiteInvitationId: string): string => {
  if (!jobsiteInvitationId) return null;
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/self-onboarding/${jobsiteInvitationId}`;
};

export function ContractorJobsiteCard(
  props: ContractorJobsiteCardProps | ContractorJobsiteCardLoadingProps,
): ReactElement {
  const { contractor, jobsites, jobsiteContractor, disableActions, loading } = props as MergeUnionType<
    ContractorJobsiteCardProps | ContractorJobsiteCardLoadingProps
  >;

  const { currentUser: user } = React.useContext(AuthContext);

  const {
    value: isRemoveJobsiteAssignmentModalOpen,
    setTrue: openRemoveJobsiteAssignmentModal,
    setFalse: closeRemoveJobsiteAssignmentModal,
  } = useBoolean(false);
  const {
    value: isEditJobsiteAssignmentModalOpen,
    setTrue: openEditJobsiteAssignmentModal,
    setFalse: closeEditJobsiteAssignmentModal,
  } = useBoolean(false);
  const {
    value: isRemoveOnboardingLinkModalOpen,
    setTrue: openRemoveOnboardingLinkModal,
    setFalse: closeRemoveOnboardingLinkModal,
  } = useBoolean(false);
  const {
    value: isAssignPaymentMethodModalOpen,
    setTrue: openAssignPaymentMethodModal,
    setFalse: closeAssignPaymentMethodModal,
  } = useBoolean(false);

  const hasAccess = !jobsiteContractor?.endDate || isAfterToday(jobsiteContractor.endDate);

  const location = useLocation();
  const baseUrl = location.pathname;

  const paymentUrlParts = ['contractor', contractor?.contractorId, 'payment-methods'];
  const initialJobsiteInvitationId = jobsiteContractor?.jobsiteInvitation?.jobsiteInvitationId;
  const initialOnboardingLink = generateSelfOnboardingInvitationLink(initialJobsiteInvitationId);
  const initialPaymentsOnboardingLink = new URL(paymentUrlParts.join('/'), getCurrentDomain());

  const [onboardingLinkState, setOnboardingLinkState] = React.useState<OnboardingLinkState>({
    jobsiteInvitationId: initialJobsiteInvitationId,
    onboardingLink: initialOnboardingLink,
    paymentsOnboardingLink: initialPaymentsOnboardingLink.toString(),
  });
  const { jobsiteInvitationId, onboardingLink, paymentsOnboardingLink } = onboardingLinkState;

  const [jobsiteCreateInvitation, { loading: createInvitationLoading }] = useJobsiteCreateInvitationMutation({
    variables: { input: { jobsiteContractorId: jobsiteContractor?.id } },
    onCompleted: (result): void => {
      const { jobsiteInvitationId: newJobsiteInvitationId } = result.jobsiteCreateInvitation.jobsiteInvitation;
      setOnboardingLinkState({
        jobsiteInvitationId: newJobsiteInvitationId,
        onboardingLink: generateSelfOnboardingInvitationLink(newJobsiteInvitationId),
      });
      AlertInstance.alert('tc', 'success', 'Success', 'Worker onboarding link generated');
    },
    onError: (error): void => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
  });
  const [createContractorStripeInvoice] = useCreateContractorStripeInvoiceMutation({
    variables: {
      input: {
        contractorId: contractor?.contractorId,
        jobsiteContractorId: jobsiteContractor?.id,
      },
    },
    onCompleted: (result: CreateContractorStripeInvoiceMutation): void => {
      AlertInstance.alert('tc', 'success', 'Success', 'Invoice generated in Stripe, opening in new tab');
      if (result?.createContractorStripeInvoice?.url) {
        window.open(result.createContractorStripeInvoice.url, '_blank');
      }
    },
    onError: (error): void => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  const menuItems = ensureNonEmptyItems<NewMenuItemProps>([
    user.isAllowed(to.editContractorJobsiteAssignments) && {
      icon: EditIcon,
      text: 'Edit Assignment',
      theme: 'secondary',
      onClick: openEditJobsiteAssignmentModal,
    },
    user.isAllowed(to.manageWorkerOnboardingLinks) &&
      (jobsiteInvitationId
        ? {
            icon: TrashIcon,
            text: 'Delete worker onboarding link',
            theme: 'danger',
            onClick: openRemoveOnboardingLinkModal,
          }
        : {
            icon: WrenchIcon,
            text: 'Generate worker onboarding link',
            theme: 'secondary',
            onClick: async (): Promise<void> => {
              jobsiteCreateInvitation();
            },
          }),
    user.isAllowed(to.removeContractorJobsiteAssignments) && {
      icon: TrashIcon,
      text: 'Delete Assignment',
      theme: 'danger',
      onClick: openRemoveJobsiteAssignmentModal,
    },
    user.isAllowed(to.editContractorPayments, contractor?.contractorId) && {
      icon: PaymentIcon,
      text: 'Manage payment method',
      theme: 'secondary',
      onClick: (): void => {
        openAssignPaymentMethodModal();
      },
    },
    user.isAllowed(to.generateContractorInvoices, contractor?.contractorId) && {
      icon: InvoiceIcon,
      text: 'Generate stripe invoice',
      theme: 'secondary',
      onClick: (): void => {
        createContractorStripeInvoice();
      },
    },
  ]);

  const { firstName, lastName, title, email, phoneNumber } = jobsiteContractor?.contact ?? {};
  const contactName = [firstName, lastName].filter(Boolean).join(' ');
  const jobsiteContactText = [contactName, title].filter(Boolean).join(', ');
  const emailLink = email && (
    <a href={`mailto:${email}`} target="blank">
      {email}
    </a>
  );
  const phoneNumberLink = phoneNumber && <a href={`tel:${phoneNumber}`}>{getFormattedPhoneNumber(phoneNumber)}</a>;

  const { jobsiteId, name: jobsiteName } = jobsiteContractor?.jobsite ?? {};
  const jobsiteLink = <Link to={`/jobsite/${jobsiteId}`}>{jobsiteName}</Link>;

  return (
    <>
      <div className="odin-flex odin-gap-x-4 odin-border odin-shadow-odin-normal odin-rounded-md odin-p-6">
        <div className="odin-flex-grow odin-grid odin-grid-cols-1 odin-gap-y-4 sm:odin-grid-cols-3 sm:odin-gap-x-3">
          <div className="odin-space-y-3">
            <h1 className="odin-mb-0 odin-mt-1">
              <span className={cn(loading && classes.skeleton)}>{loading ? 'Loading Jobsite Data' : jobsiteLink}</span>
              {user.isAllowed(to.editContractorPayments, contractor?.contractorId) &&
                jobsiteContractor?.stripePaymentMethodId && (
                  <span onClick={openAssignPaymentMethodModal} className="odin-ml-3 odin-gap-x-3 odin-text-base">
                    <PaymentIcon />
                  </span>
                )}
            </h1>
            <SiteAccessBadge
              loading={loading}
              className="odin-flex-grow-0"
              status={hasAccess ? 'allowed' : 'completed'}
            />
            <SiteAccessText hasAccess={hasAccess} endDate={jobsiteContractor?.endDate} />
          </div>
          <div className="odin-col-span-2 odin-flex odin-flex-col odin-gap-y-3">
            <ContractorJobsiteDetail label="Prime Contractor" loading={loading}>
              <ParentJobsiteContractorLink parentContractor={jobsiteContractor?.parentJobsiteContractor?.contractor} />
            </ContractorJobsiteDetail>
            <ContractorJobsiteDetail label="Subcontractors" loading={loading}>
              <Link to={`${baseUrl}/subcontractors`}>
                {jobsiteContractor?.subcontractedJobsiteContractors.count} Subcontractors
              </Link>
            </ContractorJobsiteDetail>
            <ContractorJobsiteDetail label="Workers" loading={loading}>
              <Link to={`${baseUrl}/workers?jobsiteIds=${jobsiteId}`}>
                {jobsiteContractor?.jobsiteWorkers.count} Workers
              </Link>
            </ContractorJobsiteDetail>
            <ContractorJobsiteDetail
              label="Start Date"
              value={getPrettyFormattedDate(jobsiteContractor?.startDate)}
              loading={loading}
            />
            {jobsiteContractor?.endDate && (
              <ContractorJobsiteDetail
                label="End Date"
                value={getPrettyFormattedDate(jobsiteContractor?.endDate)}
                loading={loading}
              />
            )}
            {user.isAllowed(to.manageWorkerOnboardingLinks) && onboardingLink && (
              <ContractorJobsiteDetail label="Worker Onboarding Link" loading={loading || createInvitationLoading}>
                <div className="odin-flex odin-gap-x-10">
                  <CopyLink link={onboardingLink} />
                  <PdfInstructionsDownloadLink
                    onboardingLink={onboardingLink}
                    jobsiteName={jobsiteName}
                    contractorName={contractor.organization.name}
                  />
                </div>
              </ContractorJobsiteDetail>
            )}
            {user.isAllowed(to.editContractorPayments, contractor?.contractorId) && (
              <ContractorJobsiteDetail label="Payment onboarding link" loading={loading}>
                <div className="odin-flex odin-gap-x-10">
                  <CopyLink link={paymentsOnboardingLink} />
                </div>
              </ContractorJobsiteDetail>
            )}
            <div className="odin-border-t odin-border-gray-200 odin-my-3" />
            <ContractorJobsiteDetail label="Jobsite Contact" loading={loading} value={jobsiteContactText} />
            <ContractorJobsiteDetail label="Email Address" loading={loading} value={emailLink} />
            <ContractorJobsiteDetail label="Phone Number" loading={loading} value={phoneNumberLink} />
          </div>
        </div>
        <div>
          {disableActions !== true &&
            (loading ? (
              <div className={classes.actionsSkeleton} />
            ) : (
              menuItems.length > 0 && <EllipsisDropdownButton menuItems={menuItems} popupWidthFitContent />
            ))}
        </div>
      </div>
      {jobsiteContractor && (
        <>
          <EditJobsiteAssignmentModal
            jobsiteContractor={jobsiteContractor}
            isOpen={isEditJobsiteAssignmentModalOpen}
            onCancel={closeEditJobsiteAssignmentModal}
            contractor={contractor}
            jobsites={jobsites}
          />
          <RemoveJobsiteAssignmentModal
            isOpen={isRemoveJobsiteAssignmentModalOpen}
            onCancel={closeRemoveJobsiteAssignmentModal}
            jobsiteContractorId={jobsiteContractor?.id}
          />
          <RemoveOnboardingLinkModal
            isOpen={isRemoveOnboardingLinkModalOpen}
            onCancel={closeRemoveOnboardingLinkModal}
            onConfirm={(): void => {
              closeRemoveOnboardingLinkModal();
              setOnboardingLinkState({
                jobsiteInvitationId: null,
                onboardingLink: null,
              });
            }}
            jobsiteInvitationId={jobsiteInvitationId}
          />
          <AssociateJobsiteContractorPaymentMethodModal
            contractor={contractor}
            jobsiteContractor={jobsiteContractor}
            isOpen={isAssignPaymentMethodModalOpen}
            onCancel={closeAssignPaymentMethodModal}
            onConfirm={closeAssignPaymentMethodModal}
          />
        </>
      )}
    </>
  );
}
