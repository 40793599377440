import React from 'react';
import cn from 'classnames';
import { PrintFormSectionItem } from 'containers/jobsiteFormSubmission/types';

export type FormSubmissionReportDataItem = PrintFormSectionItem;

export type FormSubmissionReportDataColumns = 2 | 3 | 4;

export type FormSubmissionReportDataProps = {
  title: string;
  columns: FormSubmissionReportDataColumns;
  items: FormSubmissionReportDataItem[];
};

const itemsContainerClasses = {
  2: 'md:odin-grid-cols-2',
  3: 'md:odin-grid-cols-3',
  4: 'md:odin-grid-cols-4',
};

const classes = {
  title: 'odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium odin-mr-9',
  itemsContainer: (columns: FormSubmissionReportDataColumns): string =>
    cn('odin-grid odin-gap-y-6 odin-gap-x-3', itemsContainerClasses[columns]),
  itemContainer: (className: string | string[]): string => cn('odin-flex odin-flex-col odin-gap-y-1.5', className),
  itemLabel: (className: string | string[]): string =>
    cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-medium odin-whitespace-pre-wrap', className),
  itemText: (className: string | string[]): string =>
    cn('odin-text-gray-900 odin-text-base odin-leading-6 odin-font-normal odin-whitespace-pre-wrap', className),
};

export function FormSubmissionReportData(props: FormSubmissionReportDataProps): React.ReactElement {
  const { title, items, columns } = props;

  return (
    <div>
      <div className="odin-mb-6">
        <span className={classes.title}>{title}</span>
      </div>
      <div className={classes.itemsContainer(columns)}>
        {items.map(({ label, text, itemClassName, labelClassName, textClassName }) => (
          <div key={label} className={classes.itemContainer(itemClassName)}>
            <div className={classes.itemLabel(labelClassName)}>{label}</div>
            <div className={classes.itemText(textClassName)}>{text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
