import React from 'react';
import { getPrettyFormattedDate } from 'utils/dates';
import { InfoRowProps } from 'components/infoRow/types';
import { JobsiteInfo } from './types';

export type JobsiteInfoRowConfig = Omit<InfoRowProps, 'onEditClick' | 'allowEdit'>;

export function getInfoRows(jobsite: JobsiteInfo): JobsiteInfoRowConfig[] {
  const { objectHistory, addressLine1, addressLine2, addressCity, addressState, addressZipCode } = jobsite ?? {};

  const jobsiteAddress = jobsite && (
    <>
      {addressLine1} {addressLine2} <br />
      {[addressCity, addressState].filter(Boolean).join(', ')} {addressZipCode}
    </>
  );

  return [
    { label: 'Display Name', value: jobsite?.name },
    { label: 'Client Name', value: jobsite?.developer?.organization?.name },
    { label: 'Address', value: jobsiteAddress },
    { label: 'Created On', value: getPrettyFormattedDate(objectHistory?.createdAt), withBottomBorder: true },
  ];
}
