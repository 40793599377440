import moment from 'moment';
import { EditJobsiteFormData } from './types';

export { getFormInputs, getAsNumber } from './EditJobsiteModal.forms';

export const getDefaultValues = (): EditJobsiteFormData => ({
  name: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: null,
  addressZipCode: '',
  developerId: null,
  startDate: moment().startOf('day'),
  endDate: null,
  timeZone: null,
  latitude: null,
  longitude: null,
});
