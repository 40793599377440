import { RadioGroupOption } from '@odin-labs/components';
import { WorkerErrorInfo } from 'apollo/generated/client-operations';
import { BadgeSelection } from 'components/badgeChecklist/types';
import { SelectOptionElement } from 'components/select/types';

export type CreateCardPrintJobFormData = {
  photo?: undefined;
  profilePictureFile?: File;
  trade?: string;
  jobTitle?: string;
  badgeTemplateName: SelectOptionElement;
};

export type AssignBadgeFormData = {
  badgeNumber: string;
  proximityCardFormatId?: SelectOptionElement;
  isPermanentBadge: boolean;
  expirationDate: moment.Moment;
  tempBadgeReason: string;
  badges: BadgeSelection[];
};

export type AssignQRFormData = {
  qrCodeNumber: string;
  badges: BadgeSelection[];
};

export type IssueMobileBadgeFormData = {
  phoneNumber: string;
  badges: BadgeSelection[];
};

export type IssueFacialRecognitionBadgeFormData = {
  phoneNumber: string;
  // email: string;
  badges: BadgeSelection[];
};

export enum BadgeType {
  QrCode,
  ExistingBadge,
  NewBadge,
  MobileBadge,
  FacialRecognition,
}

export type BadgeTypeOption = RadioGroupOption<BadgeType> & {
  workerCardFormatId?: string;
};

export type ChooseBadgeTypeData = {
  jobsiteId: SelectOptionElement;
  badgeType: BadgeTypeOption;
};

export interface DeactivateWorkerBadgesData {
  badges: BadgeSelection[];
}

export interface ReactivateWorkerBadgeData {
  badges: BadgeSelection[];
}

export type WorkerCardErrorInfo = { workerCardId: string } & WorkerErrorInfo;

export type ReassignBadgeState = {
  reassignBadgeAction: () => Promise<void>;
  workerCardInfo: WorkerCardErrorInfo[];
};

export type WorkerCardAlreadyExistsErrorExtensions = {
  workerCardInfo?: WorkerCardErrorInfo[];
  canBeReactivated?: boolean;
  scope: 'onSameWorker' | 'onDifferentWorker';
};

export type WorkerAlreadyExistsErrorExtensions = {
  workerInfo?: WorkerErrorInfo[];
};
