import React, { ReactElement, useState } from 'react';
import { Modal } from '@odin-labs/components';

import { getIcon } from 'utils/ui';
import { DeleteCCureClearanceModalProps } from './types';

const TrashIcon = getIcon('fal fa-trash');

export function DeleteCCureClearanceModal(props: DeleteCCureClearanceModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, cCureClearance } = props;
  const [clearanceName, setClearanceName] = useState<string>();

  const onAction = (): void => {
    onConfirm?.(cCureClearance);
  };

  React.useEffect(() => {
    if (!cCureClearance) return;
    const {
      name,
      cCureEnvironment: { namespace },
    } = cCureClearance;
    setClearanceName(`${name} | ${namespace}`);
  }, [cCureClearance]);

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title={`Delete "${clearanceName}" C-CURE Clearance?`}
      titleAlignment="center"
      textAlignment="center"
      onAction={onAction}
      actionText="Delete C-CURE Clearance"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
    />
  );
}
