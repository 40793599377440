import React, { MouseEventHandler } from 'react';
import cn from 'classnames';

export const classes = {
  headerActionItemButton: (extraClasses: string): string =>
    cn('odin-px-3.5', 'odin-py-3', 'odin-rounded-md', 'odin-text-odin-primary', 'hover:odin-bg-gray-200', extraClasses),
  headerActionItemIcon: (icon: string): string => cn(icon, 'odin-font-normal', 'odin-text-sm'),
};

export interface NewHeaderButtonProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconClassName?: string;
}

export function NewHeaderButton({
  children,
  className,
  iconClassName,
  onClick,
}: React.PropsWithChildren<NewHeaderButtonProps>): React.ReactElement {
  return (
    <button className={classes.headerActionItemButton(className)} onClick={onClick}>
      <i className={classes.headerActionItemIcon(iconClassName)} />
      {children}
    </button>
  );
}
