import { NewButton, Table } from '@odin-labs/components';
import { NewHeader } from 'components/header/NewHeader';
import React from 'react';
import { getIcon } from 'utils/ui';
import { useModalChangeActions } from 'utils/useModalChangeActions';
import { getColumns, CCureClearanceColumn } from './CCureClearances.tables';
import {
  AddCCureClearanceModal,
  DeleteCCureClearanceModal,
  EditableCCureClearance,
  EditCCureClearanceModal,
} from './modals';
import { CCureClearancesProps } from './types';

const PlusIcon = getIcon('fal fa-plus');

export const CCureClearances = React.forwardRef(
  (props: CCureClearancesProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value, onChange, loading, cCureEnvironmentsOptions, cCureClearancesOptions } = props;

    const { change, closeModal, openAddModal, openEditModal, openDeleteModal, updateValueAndCloseModal } =
      useModalChangeActions<EditableCCureClearance>({ items: value, onChange, idField: 'cCureClearanceId' });

    const columns = React.useMemo<CCureClearanceColumn[]>(
      () => getColumns({ openEditModal, openDeleteModal }),
      [openEditModal, openDeleteModal],
    );

    const items = React.useMemo(() => {
      return value?.filter((item) => item.changeType !== 'removed');
    }, [value]);

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-gap-y-6">
          <NewHeader
            title="Clearances"
            size="md"
            actionsProps={{
              headerActions: null,
              children: <NewButton icon={PlusIcon} text="Add Clearance" size="sm" onClick={openAddModal} />,
            }}
          />
          <Table loading={loading} columns={columns} data={items} remote disableGlobalFilter disableSortBy />
        </div>
        <AddCCureClearanceModal
          isOpen={change?.type === 'create'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          cCureEnvironmentsOptions={cCureEnvironmentsOptions}
          cCureClearancesOptions={cCureClearancesOptions}
        />
        <EditCCureClearanceModal
          isOpen={change?.type === 'update'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          cCureClearance={change?.item}
          cCureEnvironmentsOptions={cCureEnvironmentsOptions}
          cCureClearancesOptions={cCureClearancesOptions}
        />
        <DeleteCCureClearanceModal
          isOpen={change?.type === 'remove'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          cCureClearance={change?.item}
        />
      </>
    );
  },
);
