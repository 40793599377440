import React, { ReactElement } from 'react';
import { TabConfig } from '@odin-labs/components';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { NewHeader } from 'components/header/NewHeader';

import { Container } from 'components/container';
import { GeneralInformation } from './tabs/GeneralInformation';
import { JobsiteInformation } from './tabs/JobsiteInformation';

const baseRoute = '/settings';
export const tabsConfig: TabConfig[] = [
  { name: 'General', relativePath: '', component: GeneralInformation },
  { name: 'Jobsites', relativePath: '/jobsites', component: JobsiteInformation },
];

export function SettingsContainer(): ReactElement {
  const tabsConfigWithBadges = tabsConfig.map((tab) => ({ ...tab }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: tabsConfigWithBadges, baseRoute });

  return (
    <Container className="settings-container">
      <NewHeader title="Settings" tabsProps={{ tabs, currentTab }} />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} />
    </Container>
  );
}
