import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';
import { LoadingErrorProps } from './types';
import * as styles from './scss/loading-error.scss';

const cx = classNames.bind(styles);

export function LoadingError(props: LoadingErrorProps): ReactElement {
  const { loading, error, noPadding, small } = props;
  if (!loading && !error) return null;

  return (
    <div
      className={cx('loading-error', {
        'loading-error--no-padding': noPadding,
        'loading-error--small': small,
      })}
    >
      <div className={cx('loading-error__loader')}>
        {loading ? (
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        ) : (
          <h2>Uh oh... Something went wrong.</h2>
        )}
      </div>
    </div>
  );
}
