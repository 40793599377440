import { BadgeColor, SelectOptionElement } from '@odin-labs/components';
import { camelToTitleCase } from 'utils';

export enum JobsiteIncidentType {
  Automotive = 'Automotive',
  Equipment = 'Equipment',
  GeneralLiability = 'GeneralLiability',
  Injury = 'Injury',
  Other = 'Other',
  Property = 'Property',
  TheftOrVandalism = 'TheftOrVandalism',
  Utility = 'Utility',
}

export const incidentTypeColor: Record<JobsiteIncidentType, BadgeColor> = {
  Injury: 'red',
  GeneralLiability: 'blue',
  Automotive: 'pink',
  Equipment: 'yellow',
  TheftOrVandalism: 'purple',
  Property: 'indigo',
  Utility: 'gray',
  Other: 'gray',
};

export const incidentTypeOptions: SelectOptionElement<JobsiteIncidentType>[] = Object.values(JobsiteIncidentType).map(
  (value) => ({
    value,
    label: camelToTitleCase(value),
    color: incidentTypeColor[value],
  }),
);
