import React from 'react';
import { useJobsitesSelection } from 'graphql/client/useJobsitesSelection';
import { openLookerReportDialogReplace } from 'looker';
import { LookerFilter, LookerReport } from 'looker/types';

export function ReportsRedirectContainer(): React.ReactElement {
  const { loading, selectedJobsites, areAllJobsitesSelected } = useJobsitesSelection();

  React.useEffect(() => {
    if (!loading) {
      const selectedJobsiteNames = areAllJobsitesSelected ? '' : selectedJobsites?.map(({ name }) => name).join() ?? '';
      openLookerReportDialogReplace(LookerReport.Dashboard, { [LookerFilter.Jobsite]: selectedJobsiteNames });
    }
  }, [loading, selectedJobsites, areAllJobsitesSelected]);

  return null;
}
