import React, { ReactElement, useState } from 'react';
import { Moment } from 'moment';
import cn from 'classnames';
import { useMutation } from '@apollo/client';
import { Col } from 'reactstrap';
import {
  JobsiteWorker,
  MutationUpdateJobsiteWorkerArgs,
  MutationUploadSingleFileArgs,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { AuthContext } from 'auth';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import { workerDocumentsKeys } from 'containers/workerOnboarding/helpers/forms';
import {
  CREATE_JOBSITE_WORKER_DOCUMENT,
  CREATE_JOBSITE_WORKER_DOCUMENT_VERSION,
  FILE_UPLOAD,
  UPDATE_JOBSITE_WORKER,
} from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { getVisibleDocuments } from 'containers/workerOnboarding/helpers/utils';
import { OnboardingStepProps, UploadFileResponse, WorkerDocumentsDataType } from 'containers/workerOnboarding/types';
import { uploadDFilesDistinctly, useDeferredFormSubmission, useIsMounted, useUpdatableState } from 'utils';
import { getCurrentISOFormattedDateTime, getISOFormattedDateFromCleave } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey } from 'containers/worker/utils';
import { StepLoading } from './StepLoading';
import { getDefaultValues, getFormInputsHook, useNonUniqueDocuments } from './WorkDocumentsStep.forms';

export function WorkDocumentsStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const visibleDocuments = getVisibleDocuments(jobsiteWorker);
  const isDocumentTypeVisible = (documentKey: DocumentKey): boolean => {
    return filteredDocumentTypeIds[documentKey] && visibleDocuments?.includes(documentKey);
  };

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<WorkerDocumentsDataType>();
  const [sstExempt, setSstExempt] = useUpdatableState(!!jobsiteWorker?.nycSstExemptionReason);

  const [genericDocuments, addGenericDocument] = useNonUniqueDocuments({
    documentKey: DocumentKey.Generic,
    documents,
    loading: parentLoading,
  });

  const [additionalCertificationsDocuments, addAdditionalCertificationsDocument] = useNonUniqueDocuments({
    documentKey: DocumentKey.AdditionalCertifications,
    documents,
    addWhenEmpty: isDocumentTypeVisible(DocumentKey.AdditionalCertifications),
    loading: parentLoading,
  });

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments();
    },
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT, {
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT_VERSION);

  const getDocumentTypeData = (documentKey: string): { documentTypeKey: string; documentTypeId: string } => {
    let documentTypeKey = documentKey;
    if (documentKey.includes(DocumentKey.Generic)) {
      documentTypeKey = DocumentKey.Generic;
    } else if (documentKey.includes(DocumentKey.AdditionalCertifications)) {
      documentTypeKey = DocumentKey.AdditionalCertifications;
    }
    return {
      documentTypeKey,
      documentTypeId: filteredDocumentTypeIds[documentTypeKey],
    };
  };

  const onSubmit: FormOnSubmit<WorkerDocumentsDataType> = async (data, _error, dirtyFields): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      const dirtyFieldKeys = Object.keys(dirtyFields);

      let allDocKeys = [...workerDocumentsKeys, ...additionalCertificationsDocuments, ...genericDocuments];
      let exemptReason = null;

      if (sstExempt) {
        allDocKeys = allDocKeys.filter((key: string) => key !== DocumentKey.NycSiteSafetyTrainingCard);
        exemptReason = data[`${DocumentKey.NycSiteSafetyTrainingCard}-exemption-reason`] as string;
      }

      const filesToUpload = allDocKeys
        .flatMap((doc) => [
          { fieldName: `${doc}-front`, file: data[`${doc}-front`]?.[0] },
          { fieldName: `${doc}-back`, file: data[`${doc}-back`]?.[0] },
        ])
        .filter(
          (docFile): docFile is { fieldName: string; file: File } =>
            docFile.file && !(docFile.file as ServerFile).fileId,
        );
      const uploadedFiles = await uploadDFilesDistinctly(filesToUpload, uploadFile);

      await Promise.all(
        allDocKeys.map(async (documentKey) => {
          const isDirty = dirtyFieldKeys.some((dirtyFieldKey) => dirtyFieldKey.startsWith(`${documentKey}-`));
          const fileIds = [];
          const additionalFields = [];

          if (!isDirty) {
            return;
          }

          const frontFileFieldName = `${documentKey}-front`;
          const frontFile = data[frontFileFieldName];
          if (frontFile) {
            const serverFile = frontFile as ServerFile[];
            if (serverFile[0]?.fileId) {
              fileIds.push(serverFile[0]?.fileId);
            } else {
              const { fileId: frontFileId } = uploadedFiles[frontFileFieldName] ?? {};
              if (frontFileId) {
                fileIds.push(frontFileId);
              }
            }
          }

          const backFileFieldName = `${documentKey}-back`;
          const backFile = data[backFileFieldName];
          if (backFile) {
            const serverFile = backFile as ServerFile[];
            if (serverFile[0]?.fileId) {
              fileIds.push(serverFile[0]?.fileId);
            } else {
              const { fileId: frontFileId } = uploadedFiles[frontFileFieldName] ?? {};
              const { fileId: backFileId } = uploadedFiles[backFileFieldName] ?? {};
              if (backFileId && backFileId !== frontFileId) {
                fileIds.push(backFileId);
              }
            }
          }

          const expirationDateRaw = data[`${documentKey}-expiration-date`] as string;
          if (expirationDateRaw) {
            const expirationDate = getISOFormattedDateFromCleave(expirationDateRaw);
            additionalFields.push({
              key: 'expiration-date',
              value: expirationDate,
              type: 'date',
            });
          }

          const issueDateRaw = data[`${documentKey}-issue-date`] as string;
          if (issueDateRaw) {
            additionalFields.push({
              key: 'issue-date',
              value: getISOFormattedDateFromCleave(issueDateRaw),
              type: 'date',
            });
          }

          const numberRaw = data[`${documentKey}-number`];
          if (numberRaw) {
            additionalFields.push({
              key: 'number',
              value: numberRaw,
              type: 'string',
            });
          }

          const docType = data[`${documentKey}-type`];
          if (docType) {
            additionalFields.push({
              key: 'type',
              value: docType,
              type: 'string',
            });
          }

          const cardType = data[`${documentKey}-card-type`];
          if (cardType) {
            additionalFields.push({
              key: 'card-type',
              value: cardType,
              type: 'string',
            });
          }

          const stateIssued = data[`${documentKey}-state-issued`];
          if (stateIssued) {
            additionalFields.push({
              key: `state-issued`,
              value: stateIssued,
              type: 'string',
            });
          }

          const isTrainingConnectCard = data[`${documentKey}-is-training-connect-card`];
          if (isTrainingConnectCard !== undefined) {
            additionalFields.push({
              key: 'is-training-connect-card',
              value: isTrainingConnectCard.toString(),
              type: 'string',
            });
          }

          const jobsiteWorkerDocumentId = documents.find(({ key }) => key === documentKey)?.id;

          if (jobsiteWorkerDocumentId) {
            await createJobsiteWorkerDocumentVersion({
              variables: {
                jobsiteWorkerDocumentId,
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: additionalFields,
                },
              },
            });
          } else {
            const { documentTypeKey, documentTypeId } = getDocumentTypeData(documentKey);
            await createJobsiteWorkerDocument({
              variables: {
                jobsiteWorkerDocumentInput: {
                  jobsiteWorkerId,
                  jobsiteWorkerDocumentTypeId: documentTypeId,
                  key: documentTypeKey,
                },
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: additionalFields,
                },
              },
            });
          }
        }),
      );

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            documentsCompletedAt: getCurrentISOFormattedDateTime(),
            documentsCompletedSkipReason: null,
            nycSstExemptionReason: sstExempt ? exemptReason : null,
          },
        },
      });

      deferredSubmission.resolve(true);
    } catch (error) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    }
  };

  const overrideComplete = (reason: string, expirationDate: Moment, exemptionReason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          documentsCompletedAt: getCurrentISOFormattedDateTime(),
          documentsCompletedSkipReason: reason,
          documentsCompletedSkipExpiresAt: expirationDate.toDate(),
          documentExemptionReason: exemptionReason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          documentsCompletedAt: null,
          documentsCompletedSkipReason: null,
        },
      },
    });
  };

  const defaultValues = React.useMemo(
    () => getDefaultValues(jobsiteWorker, defaultFormValues),
    [defaultFormValues, jobsiteWorker],
  );

  const inputs = getFormInputsHook({
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    genericDocuments,
    additionalCertificationsDocuments,
    addAdditionalCertificationsDocument: isDocumentTypeVisible(DocumentKey.AdditionalCertifications)
      ? addAdditionalCertificationsDocument
      : undefined,
    sstExempt,
    setSstExempt,
  });

  const loading = parentLoading || fetching;

  return (
    <>
      <Col xs="12">
        <fieldset disabled={!!isContractorMember}>
          <Header title="Work documents" />
          <StepLoading loading={loading} hasTransparentBackground={!inputs().length} />
          <Form
            inputs={inputs}
            ref={formRef}
            defaultValues={defaultValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
          />
          {isDocumentTypeVisible(DocumentKey.Generic) && (
            <div
              onClick={addGenericDocument}
              className={cn('additional-document-add', !genericDocuments.length && 'odin-mt-4')}
            >
              <i className="fe fe-file-plus mr-2 odin-text-odin-primary" />
              Add other document
            </div>
          )}
        </fieldset>
      </Col>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        hideLockSession
        showConditionalPassExpiration
        showExemptionReason
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
      />
    </>
  );
}
