import React from 'react';
import { getFaIcon } from '@odin-labs/components';
import {
  faEnvelope,
  faHelmetSafety,
  faIdBadge,
  faPhone,
  faShieldCheck,
  faTools,
} from '@fortawesome/pro-light-svg-icons';
import { classes } from './SearchWorkerInfo.style';
import { SearchWorkerInfoItemProps, SearchWorkerInfoItemType } from './types';

const icons: { [key in SearchWorkerInfoItemType]: ReturnType<typeof getFaIcon> } = {
  quickCode: getFaIcon({ icon: faShieldCheck }),
  badgeId: getFaIcon({ icon: faIdBadge }),
  phone: getFaIcon({ icon: faPhone }),
  email: getFaIcon({ icon: faEnvelope }),
  trade: getFaIcon({ icon: faHelmetSafety }),
  unionAffiliation: getFaIcon({ icon: faTools }),
};

export function SearchWorkerInfoItem(props: SearchWorkerInfoItemProps): React.ReactElement {
  const { type, text = null } = props;
  const Icon = icons[type];
  return (
    text && (
      <div className={classes.workerInfoItem}>
        <Icon className={classes.workerInfoItemIcon} />
        {text}
      </div>
    )
  );
}
