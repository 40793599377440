import React from 'react';
import cn from 'classnames';
import { MomentInput } from 'moment';
import { getISODateTimeToFormattedDate } from 'utils/dates';

export const classes = {
  siteAccessText: cn('odin-text-2xs odin-text-odin-dark-gray'),
};

type SiteAccessTextProps = {
  hasAccess: boolean;
  endDate: MomentInput;
};

export function SiteAccessText({ hasAccess, endDate }: SiteAccessTextProps): React.ReactElement {
  if (hasAccess && endDate) {
    return (
      <p className={cn('odin-mb-0', classes.siteAccessText)}>
        Site access expires on {getISODateTimeToFormattedDate(endDate)}
      </p>
    );
  }
  return endDate ? (
    <p className={cn('odin-mb-0', classes.siteAccessText)}>
      Site access expired on {getISODateTimeToFormattedDate(endDate)}
    </p>
  ) : null;
}
