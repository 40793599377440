import React from 'react';
import { Table, TableContainer } from '@odin-labs/components';
import { useGetEntityChangesQuery } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { EntityChange } from 'containers/entityChange/types';
import { WorkerChangeTabProps } from 'containers/entityChange/worker/types';
import { NewHeader } from 'components/header/NewHeader';
import { getColumns, getChangesByLayout } from './WorkerDocuments.tables';

function getChangesByEntityId(entityIds: string[], changes: EntityChange[]): Record<string, EntityChange[]> {
  const initialValue: Record<string, EntityChange[]> = entityIds.reduce(
    (result, entityId) => Object.assign(result, { [entityId]: [] }),
    {},
  );
  return (
    changes?.reduce((result, change) => {
      result[change.entityId].push(change);
      return result;
    }, initialValue) ?? initialValue
  );
}

export function WorkerDocuments(props: WorkerChangeTabProps): React.ReactElement {
  const { loading: parentLoading, worker, changesLayout, onTabApiChange } = props;
  const { workerId } = worker ?? {};

  const documents = worker?.documents.edges.map(({ node }) => node) ?? [];
  const docVersionIds = documents.map((doc) => doc.latestVersion.jobsiteWorkerDocumentVersionId);

  const {
    data: workerDocumentsChangesData,
    loading: workerDocumentsChangesLoading,
    error: workerDocumentsChangesError,
    refetch: refetchWorkerDocumentsChanges,
  } = useGetEntityChangesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        // paginationInput: { limit, offset },
        filters: [{ tableName: 'jobsite_worker_document_version', entityIds: docVersionIds }],
      },
    },
    skip: !workerId,
  });

  const workerDocumentsChanges = React.useMemo(() => {
    return getChangesByEntityId(
      docVersionIds,
      getChangesByLayout(
        workerDocumentsChangesData?.getEntityChanges.edges.map(({ node }) => node) ?? [],
        changesLayout,
      ),
    );
  }, [workerDocumentsChangesData]);

  React.useEffect(
    () => onTabApiChange({ refetchData: refetchWorkerDocumentsChanges }),
    [refetchWorkerDocumentsChanges],
  );

  const loading = parentLoading || workerDocumentsChangesLoading;
  const error = workerDocumentsChangesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <div className="odin-flex odin-flex-col odin-gap-9">
      {documents.map((document) => {
        const { jobsiteWorkerDocumentVersionId } = document.latestVersion;
        const docChanges = workerDocumentsChanges[jobsiteWorkerDocumentVersionId];
        const docColumns = React.useMemo(() => getColumns(docChanges, changesLayout), [docChanges, changesLayout]);

        const docTitle = [
          document?.jobsiteWorkerDocumentType.jobsite?.name,
          document?.jobsiteWorkerDocumentType.workerDocumentType.name,
        ].join(' / ');

        return (
          <div key={jobsiteWorkerDocumentVersionId} className="odin-flex odin-flex-col odin-gap-y-6">
            <TableContainer>
              <NewHeader size="md" title={`${docTitle} - Changes`} actionsProps={{ headerActions: null }} />
              <Table
                loading={loading}
                columns={docColumns}
                data={docChanges}
                // initialState={{ sortBy: tableSortBy, pageSize: limit }}
                // pageCount={pageCount}
                // pageIndex={page}
                // remote
                // enablePagination
              />
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
}
