import { SelectOptionElement } from '@odin-labs/components';
import {
  JobsiteAppCheckInModule,
  JobsiteFeaturesModule,
  JobsiteModule,
  JobsiteOnboardingModule,
  CCureEntityMapping,
  CCureEntitySelectOptionElement,
  HidEntityOption,
  HidFargoSelectOptionElement,
  BadgeTemplateOptionElement,
  BadgeTemplate,
  UserForm,
} from 'containers/jobsiteConfiguration/types';

export { languageOptions } from 'containers/selfOnboarding/utils';

export const getOnboardingModule = (modules: JobsiteModule[]): JobsiteOnboardingModule =>
  modules?.find((m): m is JobsiteOnboardingModule => {
    return m.__typename === 'JobsiteOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getFeaturesModule = (modules: JobsiteModule[]): JobsiteFeaturesModule =>
  modules?.find((m): m is JobsiteFeaturesModule => {
    return m.__typename === 'JobsiteFeaturesModule'; // eslint-disable-line no-underscore-dangle
  });

export const getAppCheckInModule = (modules: JobsiteModule[]): JobsiteAppCheckInModule =>
  modules?.find((m): m is JobsiteAppCheckInModule => {
    return m.__typename === 'JobsiteAppCheckInModule'; // eslint-disable-line no-underscore-dangle
  });

export const getCCureEntityMappingsOptions = (
  entityMappings: CCureEntityMapping[],
  appendNamespaceToLabel = true,
): CCureEntitySelectOptionElement[] =>
  entityMappings?.map((cem) => ({
    value: `${cem.cCureEnvironment?.namespace}:${cem.objectId}`,
    label: appendNamespaceToLabel ? `${cem.name} [${cem.cCureEnvironment?.namespace}]` : cem.name,
    entity: cem,
  })) ?? [];

export const getHidFargoEntityOptions = (
  entityOptions: HidEntityOption[],
  typeName: string,
): HidFargoSelectOptionElement[] => {
  return entityOptions
    .filter((entity) => entity.entityType.includes(typeName))
    ?.map((entity) => ({
      value: entity.id,
      label: `${entity.name} [${typeName.includes('Printer') ? entity.locationName : entity.proxyInstance}]`,
      entity,
    }));
};

export const getBadgeTemplateOptions = (badgeTemplates: BadgeTemplate[]): BadgeTemplateOptionElement[] =>
  badgeTemplates?.map((badgeTemplate) => ({
    value: badgeTemplate.id,
    label: badgeTemplate.displayName,
    badgeTemplate,
  })) ?? [];

export const getFormOptions = (forms: UserForm[]): SelectOptionElement[] => {
  return forms?.map((form) => ({
    value: form.id,
    label: form.name,
  }));
};
