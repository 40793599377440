import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';

export type ValueRef = MutableRefObject<string>;
export type LocalStorageHookTuple = [ValueRef, (value: string) => void, () => void];

export const useLocalStorage = (key: string, defaultValue?: string): LocalStorageHookTuple => {
  const getInitialState = useCallback(() => {
    const item = window.localStorage.getItem(key);
    if (defaultValue != null) {
      return defaultValue;
    }

    return item;
  }, [key, defaultValue]);

  // Using a ref over a `useState` hook to avoid unnecessary re-renders
  // and faster performance.
  const storedRef = useRef(getInitialState());

  const setValueRef = useCallback(
    (value: string): void => {
      if (value !== storedRef.current) {
        storedRef.current = value;
      }
    },
    [storedRef.current],
  );

  const storageEventHandler = useCallback(
    (e: StorageEvent) => {
      const { key: changeKey, newValue } = e;
      if (key === changeKey) {
        setValueRef(newValue);
      }
    },
    [key],
  );

  // Listen for any storage events in case something updates the value
  // with localstorage directly, the ref will still stay in sync.
  useEffect(() => {
    window.addEventListener('storage', storageEventHandler);
  }, []);

  return useMemo(() => {
    const setValue = (value: string): void => {
      if (value !== storedRef.current) {
        setValueRef(value);
        window.localStorage.setItem(key, value);
      }
    };

    const clearValue = (): void => {
      setValueRef(null);
      window.localStorage.removeItem(key);
    };

    return [storedRef, setValue, clearValue];
  }, [storedRef.current, key]);
};
