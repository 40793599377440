import React from 'react';
import { FormOnSubmit, ModalForm } from 'components/form';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { useIsMounted } from 'utils';
import { useMutation } from '@apollo/client';
import { CREATE_WORKER_COMMENT } from 'containers/worker/helpers/queries';
import { getGraphQLError } from 'utils/error';
import { AlertInstance } from 'components/alertNotification';
import { getDefaultValues, getFormInputs, AddWorkerCommentFormData } from './AddWorkerCommentModal.forms';
import { AddWorkerCommentModalProps } from './types';

export function AddWorkerCommentModal(props: AddWorkerCommentModalProps): React.ReactElement {
  const { isOpen, closeModal, onConfirm, worker, jobsites, refetchQueries } = props;
  const { workerId } = worker ?? {};

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [createWorkerComment] = useMutation(CREATE_WORKER_COMMENT, { refetchQueries });

  const onSubmit: FormOnSubmit<AddWorkerCommentFormData> = async (data, event): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const { comment, jobsiteId } = data;

      await createWorkerComment({
        variables: {
          workerCommentInput: {
            workerId,
            jobsiteId: jobsiteId.value,
            comment,
          },
        },
      });

      onConfirm();
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const jobsitesOptions = React.useMemo(() => getJobsitesOptions(jobsites) ?? [], [jobsites]);
  const formInputs = React.useMemo(() => getFormInputs({ jobsitesOptions }), [jobsitesOptions]);
  const defaultValues = React.useMemo(() => getDefaultValues({ jobsitesOptions }), [jobsitesOptions]);

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      title="Add comment"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add Comment"
      actionButtonWithSpinner={isSaving}
    />
  );
}
