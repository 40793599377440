import { DropdownFilterOption } from '@odin-labs/components';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { ensureDistinctItems } from 'utils';

export function getDeveloperOptionsFromJobsites(jobsites: Jobsite[]): DropdownFilterOption[] {
  return (
    ensureDistinctItems<DropdownFilterOption>(
      jobsites?.map(({ developer: d }) => ({ value: d.developerId, label: d.name })),
      'value',
    ) ?? []
  );
}

export function getJobsiteIdsForDevelopers(jobsites: Jobsite[], developerIds: string[]): { jobsiteIds: string[] } {
  return (
    developerIds && {
      jobsiteIds: jobsites?.filter(({ developer: d }) => developerIds.includes(d.developerId)).map((j) => j.jobsiteId),
    }
  );
}
