import React, { ReactElement } from 'react';

export function AlertBody(title: string, message: string): ReactElement {
  return (
    <div className="alert-text">
      <div className="alert-title" data-notify="title">
        <b>{title}</b>
      </div>
      <div data-notify="message">{message}</div>
    </div>
  );
}
