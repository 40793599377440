import { duration } from 'moment';
import { DeepMap } from 'react-hook-form';
import { stringifyEmptyFields, tryFileCompression, typedComponentProps } from 'utils';
import { DocumentKey } from 'containers/worker/utils';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { getOnboardingModule } from 'containers/jobsiteConfiguration/helpers/utils';
import { DocumentFile, EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import {
  ChangeType,
  FileChangeInput,
  JobsiteOnboardingDocumentFileInput,
  JobsiteOnboardingDocumentInput,
} from 'apollo/generated/client-operations';
import { DocumentFiles } from 'containers/jobsiteConfiguration/components';
import { Editable } from 'types';
import { getAsDurationString, toggleBorderClasses } from './utils';

export const documentsSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['documents']> = {
  osha: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      oshaEnabled: {
        element: FormInputTypes.OdinToggle,
        label: 'Osha Card',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      oshaRequiredForOnboarding: {
        element: FormInputTypes.OdinToggle,
        label: 'Required for onboarding',
        layout: [GridColSpan.SpanFull],
      },
    },
  },
  nycSst: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      nycSstEnabled: {
        element: FormInputTypes.OdinToggle,
        label: 'NYC SST',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      nycSstRequiredForOnboarding: {
        element: FormInputTypes.OdinToggle,
        label: 'Required for onboarding',
        layout: [GridColSpan.SpanFull],
      },
    },
  },
  governmentIdEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Government ID',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  siteSpecificOrientation: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      siteSpecificOrientationEnabled: {
        element: FormInputTypes.OdinToggle,
        label: 'Site-Specific Orientation',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      siteSpecificOrientationGracePeriodInterval: {
        element: FormInputTypes.OdinField,
        label: 'Grace Period Interval',
        layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
        elementProps: { fieldType: 'number', innerRightLabel: 'Hours' },
      },
      siteSpecificOrientationRefresherInterval: {
        element: FormInputTypes.OdinField,
        label: 'Refresher Interval',
        layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
        elementProps: { fieldType: 'number', innerRightLabel: 'Months' },
      },
      siteSpecificOrientationRequiredForOnboarding: {
        element: FormInputTypes.OdinToggle,
        label: 'Required for onboarding',
        layout: [GridColSpan.SpanFull],
      },
    },
  },
  jobsiteSafetyVideo: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      jobsiteSafetyVideoEnabled: {
        element: FormInputTypes.OdinToggle,
        label: 'Jobsite Safety Video Acknowledgment',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      jobsiteSafetyVideoFiles: {
        element: FormInputTypes.CustomInput,
        elementProps: {
          customInput: DocumentFiles,
          ...typedComponentProps<typeof DocumentFiles>({ accept: { 'video/*': [] } }),
        },
        layout: [GridColSpan.SpanFull],
      },
      jobsiteSafetyVideoRequiredForOnboarding: {
        element: FormInputTypes.OdinToggle,
        label: 'Required for onboarding',
        layout: [GridColSpan.SpanFull],
      },
    },
  },
  jobsiteSafetyDocument: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      jobsiteSafetyDocumentEnabled: {
        element: FormInputTypes.OdinToggle,
        label: 'Jobsite Safety Document Acknowledgment',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      jobsiteSafetyDocumentFiles: {
        element: FormInputTypes.CustomInput,
        elementProps: {
          customInput: DocumentFiles,
          ...typedComponentProps<typeof DocumentFiles>({ accept: { 'text/html': ['.html', '.htm'] } }),
        },
        layout: [GridColSpan.SpanFull],
      },
      jobsiteSafetyDocumentRequiredForOnboarding: {
        element: FormInputTypes.OdinToggle,
        label: 'Required for onboarding',
        layout: [GridColSpan.SpanFull],
      },
    },
  },
  workerConsentDocument: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      workerConsentDocumentEnabled: {
        element: FormInputTypes.OdinToggle,
        label: 'Worker Consent Document',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      workerConsentDocumentFiles: {
        element: FormInputTypes.CustomInput,
        elementProps: {
          customInput: DocumentFiles,
          ...typedComponentProps<typeof DocumentFiles>({ accept: { 'text/html': ['.html', '.htm'] } }),
        },
        layout: [GridColSpan.SpanFull],
      },
      workerConsentDocumentRequiredForOnboarding: {
        element: FormInputTypes.OdinToggle,
        label: 'Required for onboarding',
        layout: [GridColSpan.SpanFull],
      },
    },
  },

  additionalCertificationsEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Additional Certifications',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  genericEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Generic',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  medicalDrugTestingConsentFormEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Medical Drug Testing Consent Form',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  medicalCovid19OrientationAndProceduresEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Medical Covid19 Orientation And Procedures',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  medicalBreathAlcoholTestEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Medical Breath Alcohol Test',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  medicalUrineDrugTestEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Medical Urine Drug Test',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  postEmploymentBreathAlcoholTestEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Post Employment Breath Alcohol Test',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  postEmploymentUrineDrugTestEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Post Employment Urine Drug Test',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  signatureEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Signature',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  hudsonYardsEmployeeOrientationEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Hudson Yards Employee Orientation',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  monadnockEyeProtectionAcknowledgementEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Monadnock Eye Protection Acknowledgement',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  lirrBlueTraining: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      lirrBlueTrainingEnabled: {
        element: FormInputTypes.OdinToggle,
        label: 'LIRR Blue Training',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      lirrBlueTrainingRequiredForOnboarding: {
        element: FormInputTypes.OdinToggle,
        label: 'Required for onboarding',
        layout: [GridColSpan.SpanFull],
      },
    },
  },
  confinedSpacesTrainingEnabled: {
    element: FormInputTypes.OdinToggle,
    label: 'Confined Spaces Training',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
};

export const getDocumentsSectionDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['documents'] => {
  const documentTypes = jobsite?.documentTypes.edges.map(({ node }) => node);

  const onboardingModule = getOnboardingModule(jobsite?.modules);
  const { documents } = onboardingModule ?? {};

  const isDocumentRequired = (docKey: DocumentKey): boolean => {
    return documents?.find((d) => d.key === docKey)?.isRequired ?? false;
  };

  const isDocumentAssigned = (docKey: DocumentKey): boolean => {
    return documentTypes?.some((d) => d.workerDocumentType.key === docKey) ?? false;
  };

  const getDocumentFiles = (docKey: DocumentKey): Editable<DocumentFile>[] => {
    const workerDocumentType = documentTypes?.find((d) => d.workerDocumentType.key === docKey);
    return (
      workerDocumentType?.files?.map((file) => ({
        id: file.fileId,
        language: file.language,
        title: file.title,
        file,
      })) ?? []
    );
  };

  const isDocumentVisible = (docKey: DocumentKey): boolean => {
    return documents?.find((d) => d.key === docKey)?.isVisible ?? false;
  };

  const isDocumentEnabled = (docKey: DocumentKey): boolean => isDocumentVisible(docKey) && isDocumentAssigned(docKey);

  const getDocumentGracePeriod = (docKey: DocumentKey): string => {
    const gracePeriod = documents?.find((d) => d.key === docKey)?.gracePeriod ?? null;
    return gracePeriod && duration(gracePeriod).asHours().toString();
  };

  const getDocumentExpiration = (docKey: DocumentKey): string => {
    const expiration = documents?.find((d) => d.key === docKey)?.expiration ?? null;
    return expiration && duration(expiration).asMonths().toString();
  };

  return {
    osha: {
      oshaEnabled: isDocumentEnabled(DocumentKey.OshaCard),
      oshaRequiredForOnboarding: isDocumentRequired(DocumentKey.OshaCard),
    },
    nycSst: {
      nycSstEnabled: isDocumentEnabled(DocumentKey.NycSiteSafetyTrainingCard),
      nycSstRequiredForOnboarding: isDocumentRequired(DocumentKey.NycSiteSafetyTrainingCard),
    },
    governmentIdEnabled: isDocumentEnabled(DocumentKey.GovernmentIssuedId),
    siteSpecificOrientation: {
      siteSpecificOrientationEnabled: isDocumentEnabled(DocumentKey.SiteSpecificOrientation),
      ...stringifyEmptyFields({
        siteSpecificOrientationGracePeriodInterval: getDocumentGracePeriod(DocumentKey.SiteSpecificOrientation),
        siteSpecificOrientationRefresherInterval: getDocumentExpiration(DocumentKey.SiteSpecificOrientation),
      }),
      siteSpecificOrientationRequiredForOnboarding: isDocumentRequired(DocumentKey.SiteSpecificOrientation),
    },
    jobsiteSafetyVideo: {
      jobsiteSafetyVideoEnabled: isDocumentEnabled(DocumentKey.JobsiteSafetyVideo),
      jobsiteSafetyVideoRequiredForOnboarding: isDocumentRequired(DocumentKey.JobsiteSafetyVideo),
      jobsiteSafetyVideoFiles: getDocumentFiles(DocumentKey.JobsiteSafetyVideo),
    },
    jobsiteSafetyDocument: {
      jobsiteSafetyDocumentEnabled: isDocumentEnabled(DocumentKey.JobsiteSafetyDocument),
      jobsiteSafetyDocumentRequiredForOnboarding: isDocumentRequired(DocumentKey.JobsiteSafetyDocument),
      jobsiteSafetyDocumentFiles: getDocumentFiles(DocumentKey.JobsiteSafetyDocument),
    },
    workerConsentDocument: {
      workerConsentDocumentEnabled: isDocumentEnabled(DocumentKey.WorkerConsentDocument),
      workerConsentDocumentRequiredForOnboarding: isDocumentRequired(DocumentKey.WorkerConsentDocument),
      workerConsentDocumentFiles: getDocumentFiles(DocumentKey.WorkerConsentDocument),
    },

    additionalCertificationsEnabled: isDocumentEnabled(DocumentKey.AdditionalCertifications),
    genericEnabled: isDocumentEnabled(DocumentKey.Generic),

    medicalDrugTestingConsentFormEnabled: isDocumentEnabled(DocumentKey.MedicalDrugTestingConsentForm),
    medicalCovid19OrientationAndProceduresEnabled: isDocumentEnabled(
      DocumentKey.MedicalCovid19OrientationAndProcedures,
    ),
    medicalBreathAlcoholTestEnabled: isDocumentEnabled(DocumentKey.MedicalBreathAlcoholTest),
    medicalUrineDrugTestEnabled: isDocumentEnabled(DocumentKey.MedicalUrineDrugTest),
    postEmploymentBreathAlcoholTestEnabled: isDocumentEnabled(DocumentKey.PostEmploymentBreathAlcoholTest),
    postEmploymentUrineDrugTestEnabled: isDocumentEnabled(DocumentKey.PostEmploymentUrineDrugTest),

    signatureEnabled: isDocumentEnabled(DocumentKey.Signature),
    hudsonYardsEmployeeOrientationEnabled: isDocumentEnabled(DocumentKey.HudsonYardsEmployeeOrientation),
    monadnockEyeProtectionAcknowledgementEnabled: isDocumentEnabled(DocumentKey.MonadnockEyeProtectionAcknowledgement),

    lirrBlueTraining: {
      lirrBlueTrainingEnabled: isDocumentEnabled(DocumentKey.LIRRBlueTraining),
      lirrBlueTrainingRequiredForOnboarding: isDocumentRequired(DocumentKey.LIRRBlueTraining),
    },
    confinedSpacesTrainingEnabled: isDocumentEnabled(DocumentKey.ConfinedSpacesTraining),
  };
};

export type OnboardingModuleDocumentInputs = {
  documents: JobsiteOnboardingDocumentInput[];
};

export const getOnboardingModuleDocumentInputs = (
  documents: EditJobsiteConfigurationFormData['documents'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['documents'], true>,
): OnboardingModuleDocumentInputs => {
  const {
    siteSpecificOrientation,
    jobsiteSafetyVideo,
    jobsiteSafetyDocument,
    workerConsentDocument,
    nycSst,
    osha,
    lirrBlueTraining,
  } = documents;

  const getUpdateInputValue = getUpdateInputValueFunction(documents, dirtyFields);
  const getOshaUpdateInputValue = getUpdateInputValueFunction(osha, dirtyFields?.osha);
  const getNycSstUpdateInputValue = getUpdateInputValueFunction(nycSst, dirtyFields?.nycSst);
  const getSsoUpdateInputValue = getUpdateInputValueFunction(
    siteSpecificOrientation,
    dirtyFields?.siteSpecificOrientation,
  );
  const getJobsiteSafetyVideoUpdateInputValue = getUpdateInputValueFunction(
    jobsiteSafetyVideo,
    dirtyFields?.jobsiteSafetyVideo,
  );
  const getJobsiteSafetyDocumentUpdateInputValue = getUpdateInputValueFunction(
    jobsiteSafetyDocument,
    dirtyFields?.jobsiteSafetyDocument,
  );
  const getWorkerConsentDocumentUpdateInputValue = getUpdateInputValueFunction(
    workerConsentDocument,
    dirtyFields?.workerConsentDocument,
  );
  const getLirrUpdateInputValue = getUpdateInputValueFunction(lirrBlueTraining, dirtyFields?.lirrBlueTraining);

  const byNonUndefinedFields = (documentInput: JobsiteOnboardingDocumentInput): boolean => {
    return Object.entries(documentInput).some(([field, value]) => field !== 'key' && value !== undefined);
  };

  const documentsInputs: JobsiteOnboardingDocumentInput[] = [
    {
      key: DocumentKey.OshaCard,
      isVisible: getOshaUpdateInputValue('oshaEnabled'),
      isRequired: getOshaUpdateInputValue('oshaRequiredForOnboarding'),
    },
    {
      key: DocumentKey.NycSiteSafetyTrainingCard,
      isVisible: getNycSstUpdateInputValue('nycSstEnabled'),
      isRequired: getNycSstUpdateInputValue('nycSstRequiredForOnboarding'),
    },
    {
      key: DocumentKey.GovernmentIssuedId,
      isVisible: getUpdateInputValue('governmentIdEnabled'),
    },
    {
      key: DocumentKey.SiteSpecificOrientation,
      isVisible: getSsoUpdateInputValue('siteSpecificOrientationEnabled'),
      isRequired: getSsoUpdateInputValue('siteSpecificOrientationRequiredForOnboarding'),
      gracePeriod: getAsDurationString(getSsoUpdateInputValue('siteSpecificOrientationGracePeriodInterval'), 'hours'),
      expiration: getAsDurationString(getSsoUpdateInputValue('siteSpecificOrientationRefresherInterval'), 'months'),
    },
    {
      key: DocumentKey.JobsiteSafetyVideo,
      isVisible:
        getJobsiteSafetyVideoUpdateInputValue('jobsiteSafetyVideoEnabled') ??
        // force document to be updated if file only has changed
        (dirtyFields?.jobsiteSafetyVideo?.jobsiteSafetyVideoFiles
          ? jobsiteSafetyVideo.jobsiteSafetyVideoEnabled ?? false
          : undefined),
      isRequired: getJobsiteSafetyVideoUpdateInputValue('jobsiteSafetyVideoRequiredForOnboarding'),
    },
    {
      key: DocumentKey.JobsiteSafetyDocument,
      isVisible:
        getJobsiteSafetyDocumentUpdateInputValue('jobsiteSafetyDocumentEnabled') ??
        // force document to be updated if file only has changed
        (dirtyFields?.jobsiteSafetyDocument?.jobsiteSafetyDocumentFiles
          ? jobsiteSafetyDocument.jobsiteSafetyDocumentEnabled ?? false
          : undefined),
      isRequired: getJobsiteSafetyDocumentUpdateInputValue('jobsiteSafetyDocumentRequiredForOnboarding'),
    },
    {
      key: DocumentKey.WorkerConsentDocument,
      isVisible:
        getWorkerConsentDocumentUpdateInputValue('workerConsentDocumentEnabled') ??
        // force document to be updated if file only has changed
        (dirtyFields?.workerConsentDocument?.workerConsentDocumentFiles
          ? workerConsentDocument.workerConsentDocumentEnabled ?? false
          : undefined),
      isRequired: getWorkerConsentDocumentUpdateInputValue('workerConsentDocumentRequiredForOnboarding'),
    },

    {
      key: DocumentKey.AdditionalCertifications,
      isVisible: getUpdateInputValue('additionalCertificationsEnabled'),
    },
    {
      key: DocumentKey.Generic,
      isVisible: getUpdateInputValue('genericEnabled'),
    },
    {
      key: DocumentKey.MedicalDrugTestingConsentForm,
      isVisible: getUpdateInputValue('medicalDrugTestingConsentFormEnabled'),
    },
    {
      key: DocumentKey.MedicalCovid19OrientationAndProcedures,
      isVisible: getUpdateInputValue('medicalCovid19OrientationAndProceduresEnabled'),
    },
    {
      key: DocumentKey.MedicalBreathAlcoholTest,
      isVisible: getUpdateInputValue('medicalBreathAlcoholTestEnabled'),
    },
    {
      key: DocumentKey.MedicalUrineDrugTest,
      isVisible: getUpdateInputValue('medicalUrineDrugTestEnabled'),
    },
    {
      key: DocumentKey.PostEmploymentBreathAlcoholTest,
      isVisible: getUpdateInputValue('postEmploymentBreathAlcoholTestEnabled'),
    },
    {
      key: DocumentKey.PostEmploymentUrineDrugTest,
      isVisible: getUpdateInputValue('postEmploymentUrineDrugTestEnabled'),
    },
    {
      key: DocumentKey.Signature,
      isVisible: getUpdateInputValue('signatureEnabled'),
    },
    {
      key: DocumentKey.HudsonYardsEmployeeOrientation,
      isVisible: getUpdateInputValue('hudsonYardsEmployeeOrientationEnabled'),
    },
    {
      key: DocumentKey.MonadnockEyeProtectionAcknowledgement,
      isVisible: getUpdateInputValue('monadnockEyeProtectionAcknowledgementEnabled'),
    },
    {
      key: DocumentKey.LIRRBlueTraining,
      isVisible: getLirrUpdateInputValue('lirrBlueTrainingEnabled'),
      isRequired: getLirrUpdateInputValue('lirrBlueTrainingRequiredForOnboarding'),
    },
    {
      key: DocumentKey.ConfinedSpacesTraining,
      isVisible: getUpdateInputValue('confinedSpacesTrainingEnabled'),
    },
  ];

  return {
    documents: documentsInputs.filter(byNonUndefinedFields),
  };
};

export const getOnboardingDocumentFilesInputs = async (
  jobsite: Jobsite,
  documents: EditJobsiteConfigurationFormData['documents'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['documents'], true>,
): Promise<JobsiteOnboardingDocumentFileInput[]> => {
  const { jobsiteSafetyVideo, jobsiteSafetyDocument, workerConsentDocument } = documents;
  const getJobsiteSafetyVideoUpdateInputValue = getUpdateInputValueFunction(
    jobsiteSafetyVideo,
    dirtyFields?.jobsiteSafetyVideo,
  );
  const getJobsiteSafetyDocumentUpdateInputValue = getUpdateInputValueFunction(
    jobsiteSafetyDocument,
    dirtyFields?.jobsiteSafetyDocument,
  );
  const getWorkerConsentDocumentUpdateInputValue = getUpdateInputValueFunction(
    workerConsentDocument,
    dirtyFields?.workerConsentDocument,
  );

  const byNonUndefinedFields = (documentFileInput: JobsiteOnboardingDocumentFileInput): boolean => {
    return Object.entries(documentFileInput).some(
      ([field, value]) => field !== 'documentTypeKey' && value !== undefined,
    );
  };

  const getFilesInput = async (files: Editable<DocumentFile>[]): Promise<FileChangeInput[]> => {
    return (
      files &&
      Promise.all(
        files
          .filter((df) => df.changeType)
          .map(async (df): Promise<FileChangeInput> => {
            const fileData =
              df.changeType !== ChangeType.Removed && df.file instanceof Blob
                ? await tryFileCompression(df.file as File)
                : undefined;
            return {
              changeType: df.changeType as ChangeType,
              fileId: df.changeType !== ChangeType.Created ? df.id : undefined,
              fileInput:
                df.changeType !== ChangeType.Removed
                  ? { uploadData: fileData, isPublic: false, language: df.language, title: df.title }
                  : undefined,
            };
          }),
      )
    );
  };

  const documentsInputs: JobsiteOnboardingDocumentFileInput[] = [
    {
      documentTypeKey: DocumentKey.JobsiteSafetyVideo,
      files: await getFilesInput(getJobsiteSafetyVideoUpdateInputValue('jobsiteSafetyVideoFiles')),
    },
    {
      documentTypeKey: DocumentKey.JobsiteSafetyDocument,
      files: await getFilesInput(getJobsiteSafetyDocumentUpdateInputValue('jobsiteSafetyDocumentFiles')),
    },
    {
      documentTypeKey: DocumentKey.WorkerConsentDocument,
      files: await getFilesInput(getWorkerConsentDocumentUpdateInputValue('workerConsentDocumentFiles')),
    },
  ];

  return documentsInputs.filter(byNonUndefinedFields);
};
