import { RadioGroupOption } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { CompleteJobsiteAssignmentFormData, JobsiteAssignmentType } from './types';

export type CompleteJobsiteAssignmentInputsArgs = {
  jobsiteName: string;
};

export type JobsiteAssignmentTypeOption = RadioGroupOption<JobsiteAssignmentType>;

export const getJobsiteAssignmentTypeOptions = ({
  jobsiteName,
}: CompleteJobsiteAssignmentInputsArgs): JobsiteAssignmentTypeOption[] => [
  {
    value: JobsiteAssignmentType.WorkingAtMultipleSites,
    label: 'Working at multiple sites',
  },
  {
    value: JobsiteAssignmentType.WorkingAtOneSiteOnly,
    label: `Only working at ${jobsiteName}`,
  },
];

export const getFormInputs = ({
  jobsiteName,
  assignmentTypeOptions,
}: {
  jobsiteName: string;
  assignmentTypeOptions: JobsiteAssignmentTypeOption[];
}): TypedFormInputs<CompleteJobsiteAssignmentFormData> => ({
  jobsiteAssignmentType: {
    element: FormInputTypes.RadioGroup,
    label: `Will the worker be actively working at both sites or are they moving permanently to ${jobsiteName}`,
    elementProps: {
      options: assignmentTypeOptions,
    },
    layout: GridColSpan.SpanFull,
  },
});

export const getDefaultValues = ({
  assignmentTypeOptions,
}: {
  assignmentTypeOptions: JobsiteAssignmentTypeOption[];
}): CompleteJobsiteAssignmentFormData => {
  return {
    jobsiteAssignmentType: assignmentTypeOptions.find(
      (opt) => opt.value === JobsiteAssignmentType.WorkingAtOneSiteOnly,
    ),
  };
};
