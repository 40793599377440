import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { getIcon } from 'utils/ui';
import { emailValidation, phoneNumberValidation, webURLValidation, zipCodeValidation } from 'utils/validation';
import { statesOptions } from 'utils/constants';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { ContractorInfo } from 'containers/contractor/types';
import { stringifyEmptyFields } from 'utils';
import { AddContractorModalProps, EditContractorFormData } from './types';

const MapMarkerIcon = getIcon('fal fa-map-marker-alt');
const EnvelopeIcon = getIcon('fal fa-envelope');

export const getFormInputs = (props?: AddContractorModalProps): TypedFormInputs<EditContractorFormData> => ({
  // organization
  name: {
    element: FormInputTypes.OdinField,
    label: 'Display name',
    elementProps: {
      disabled: !!props?.existingOrganization,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  legalName: {
    element: FormInputTypes.OdinField,
    elementProps: {
      disabled: !!props?.existingOrganization,
    },
    label: 'Legal Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine1: {
    element: FormInputTypes.NewPlacesAutocomplete,
    label: 'Address',
    elementProps: {
      disabled: !!props?.existingOrganization,
      placeholder: 'Address',
      icon: MapMarkerIcon,
      onCommit: fillAddressDetails,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine2: {
    element: FormInputTypes.OdinField,
    elementProps: {
      disabled: !!props?.existingOrganization,
    },
    label: 'Address, Line 2',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressCity: {
    element: FormInputTypes.OdinField,
    elementProps: {
      disabled: !!props?.existingOrganization,
    },
    label: 'City',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressState: {
    element: FormInputTypes.OdinSelect,
    label: 'State',
    elementProps: {
      disabled: !!props?.existingOrganization,
      options: statesOptions,
    },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  addressZipCode: {
    element: FormInputTypes.OdinField,
    label: 'ZIP Code',
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
    elementProps: {
      disabled: !!props?.existingOrganization,
      fieldType: 'zipcode',
    },
    validation: {
      pattern: zipCodeValidation,
    },
  },
  // billing contact
  contactFirstName: {
    element: FormInputTypes.OdinField,
    label: 'Billing Contact First Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  contactLastName: {
    element: FormInputTypes.OdinField,
    label: 'Billing Contact Last Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  contactEmail: {
    element: FormInputTypes.OdinField,
    label: 'Billing Email',
    elementProps: {
      icon: EnvelopeIcon,
    },
    validation: { pattern: emailValidation },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  contactPhoneNumber: {
    element: FormInputTypes.OdinField,
    label: 'Billing Phone',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: {
      pattern: phoneNumberValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  websiteUrl: {
    element: FormInputTypes.OdinField,
    label: 'Website URL',
    elementProps: {
      icon: getIcon('fal fa-globe'),
    },
    validation: {
      pattern: webURLValidation,
    },
    layout: [GridColSpan.SpanFull],
  },
  description: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Description',
    layout: [GridColSpan.SpanFull],
  },
});

export const getDefaultValues = (contractor: ContractorInfo): EditContractorFormData => {
  const { organization, billingContact, websiteUrl, description } = contractor ?? {};
  const { name, legalName, addressLine1, addressLine2, addressCity, addressState, addressZipCode } = organization ?? {};
  const {
    firstName: contactFirstName,
    lastName: contactLastName,
    email: contactEmail,
    phoneNumber: contactPhoneNumber,
  } = billingContact ?? {};

  return {
    ...stringifyEmptyFields({
      // organization
      name,
      legalName,
      addressLine1,
      addressLine2,
      addressCity,
      addressZipCode,

      // billing contact
      contactFirstName,
      contactLastName,
      contactEmail,
      contactPhoneNumber,

      websiteUrl,
      description,
    }),
    addressState: statesOptions.find((option) => option.value === addressState) ?? null,
  };
};
