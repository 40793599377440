import React, { ReactElement } from 'react';
import { AuthContext } from 'auth';
import { useAuthRedirectStorage } from 'auth/hooks';
import { Redirect } from 'react-router-dom';

export function AuthContainer(): ReactElement {
  const [redirectRef] = useAuthRedirectStorage();
  const authContext = React.useContext(AuthContext);
  const isAuthenticated = authContext?.authState?.getIsAuthenticated();

  // If we got redirected back from FusionAuth,
  // send the user to their last request page
  if (isAuthenticated && redirectRef.current && redirectRef.current !== 'null') {
    return <Redirect push to={redirectRef.current} />;
  }

  return <Redirect push to="/" />;
}
