import React from 'react';
import cn from 'classnames';
import { WeatherConditions } from 'apollo/generated/client-operations';
import { getDateTimeMoment } from 'utils/dates';

export type WeatherItemProps = {
  weather: WeatherConditions;
  timeZone: string;
};

const classes = {
  container: cn(
    'odin-flex odin-flex-col odin-w-auto odin-items-center odin-p-3 odin-gap-y-1.5',
    'odin-rounded-md odin-border odin-border-gray-200',
  ),
  temp: cn('odin-capitalize odin-text-base odin-font-medium odin-text-gray-900'),
  time: cn('odin-whitespace-nowrap odin-text-xs odin-text-gray-600'),
  description: cn('odin-text-center odin-capitalize odin-text-xs odin-text-gray-900'),
  wind: cn('odin-self-end odin-whitespace-nowrap odin-text-center odin-text-xs odin-text-gray-900'),
};

export function WeatherItem({ weather, timeZone }: WeatherItemProps): React.ReactElement {
  const { timestamp, icon, temp, description, windSpeed, windDirectionCardinal } = weather;
  return (
    <div className={classes.container} style={{ minWidth: 115 }}>
      <img src={icon} alt="weather-img" width={46} height={28} />
      <div className={classes.temp}>{`${Math.round(temp)}º`}</div>
      <div className={classes.time}>
        {getDateTimeMoment({ date: timestamp, timeZone, isUTC: true }).format('hh:mm A')}
      </div>
      <div className={classes.description}>{description}</div>
      <div className="odin-flex odin-flex-grow">
        <div className={classes.wind}>{`${windSpeed.toFixed(0)} MPH ${windDirectionCardinal}`}</div>
      </div>
    </div>
  );
}
