import { SelectOptionElement } from '@odin-labs/components';
import { UserAssignmentType, UserRole, UserRoleKey } from 'apollo/generated/client-operations';
import { isContractorMemberRoleKey } from 'utils/contractorMemberUser';
import { UserRoleType, JobsiteForOption } from './types';

export type { JobsiteForOption };
export const getJobsitesOptions = (jobsites: JobsiteForOption[]): SelectOptionElement[] =>
  jobsites
    ?.map(({ jobsiteId: value, name: label }) => ({ value, label }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getUserRoleType = (userRoleKey: UserRoleKey): UserRoleType | undefined => {
  if (userRoleKey) {
    return isContractorMemberRoleKey(userRoleKey) ? UserAssignmentType.Contractor : UserAssignmentType.Jobsite;
  }
  return undefined;
};

const adminRoles = [UserRoleKey.Admin, UserRoleKey.Super];
export type UserRoleForOption = Pick<UserRole, 'displayName' | 'key'>;
export const getUserRolesOptions = ({
  userRoles,
  userRoleType,
  showAdminRoles,
}: {
  userRoles: UserRoleForOption[];
  userRoleType?: UserRoleType;
  showAdminRoles?: boolean;
}): SelectOptionElement<UserRoleKey>[] => {
  const filteredUserRoles = (
    userRoleType ? userRoles?.filter(({ key }) => getUserRoleType(key) === userRoleType) : userRoles
  )?.filter(({ key }) => showAdminRoles || !adminRoles.includes(key));
  return filteredUserRoles?.map(({ key: value, displayName: label }) => ({ value, label }));
};
