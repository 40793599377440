import React from 'react';
import { NewButton, NewButtonProps } from '@odin-labs/components';

export type WorkerAdminActionProps = React.PropsWithChildren<{
  label: string;
  actionText: string;
  onClick: NewButtonProps['onClick'];
}>;

export function WorkerAdminAction(props: WorkerAdminActionProps): React.ReactElement {
  const { children, label, actionText, onClick } = props;
  return (
    <div>
      <h3>{label}</h3>
      <div className="odin-flex odin-items-center">
        <NewButton theme="secondary" text={actionText} onClick={onClick} />
        {children}
      </div>
    </div>
  );
}
