import React from 'react';
import cn from 'classnames';
import { Column } from '@odin-labs/components';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistoryFormat } from 'components/objectHistory/types';
import { Change, EntityChange } from 'containers/entityChange/types';
import { ensureDistinctItems, ensureNonEmptyItems } from 'utils';

export type EntityChangeColumn = Column<EntityChange>;
export type ChangeValues = Record<string, Change>;

const excludedIdFields = ['fusionAuthId'];

export const renderValue = (value: string, type: 'old' | 'new', className = ''): React.ReactNode => {
  return (
    <div
      className={cn(
        type === 'old' ? 'odin-bg-red-200' : 'odin-bg-green-200',
        value?.length > 55 && 'odin-w-96 odin-whitespace-pre-line',
        className,
      )}
    >
      {value}
    </div>
  );
};

export const getColumns = (changes: EntityChange[], layout: 'vertical' | 'horizontal'): EntityChangeColumn[] => {
  const changedFields =
    layout === 'horizontal' ? ensureDistinctItems(changes.flatMap((change) => Object.keys(change.values))) : [];

  return ensureNonEmptyItems([
    {
      id: 'tableName',
      Header: 'Entity',
      accessor: 'tableName',
    },
    {
      id: 'entityId',
      Header: 'Entity Id',
      accessor: 'entityId',
    },
    {
      id: 'entityDbId',
      Header: 'Entity Db Id',
      accessor: 'entityDbId',
    },
    {
      id: 'apiDbHandler',
      Header: 'Api Db Handler',
      accessor: 'apiDbHandler',
    },
    {
      id: 'changedBy',
      Header: 'Changed By',
      accessor: (entityChange: EntityChange): EntityChange => entityChange,
      Cell: ({ value }: { value: EntityChange }): React.ReactElement => (
        <ObjectHistory object={value} format={ObjectHistoryFormat.Name} />
      ),
    },
    {
      id: 'changedAt',
      Header: 'Changed At',
      accessor: (entityChange: EntityChange): EntityChange => entityChange,
      Cell: ({ value }: { value: EntityChange }): React.ReactElement => (
        <ObjectHistory object={value} format={ObjectHistoryFormat.Time} />
      ),
    },
    ...(layout === 'vertical'
      ? [
          {
            id: 'fieldName',
            Header: 'Field',
            accessor: 'fieldName',
          },
          {
            id: 'newValue',
            Header: 'New Value',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.new?.toString(), 'new');
            },
          },
          {
            id: 'oldValue',
            Header: 'Old Value',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.old?.toString(), 'old');
            },
          },
          {
            id: 'newText',
            Header: 'New Text',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.newText, 'new');
            },
          },
          {
            id: 'oldText',
            Header: 'Old Text',
            accessor: (entityChange: EntityChange): Change => entityChange.value,
            Cell: ({ value }: { value: Change }): React.ReactNode => {
              return renderValue(value?.oldText, 'old');
            },
          },
        ]
      : []),
    ...changedFields.reduce((result, fieldName) => {
      result.push({
        id: fieldName,
        Header: fieldName,
        accessor: (entityChange: EntityChange): Change => entityChange.values[fieldName],
        Cell: ({ value }: { value: Change }): React.ReactElement => {
          return (
            <div className="odin-flex odin-flex-col odin-gap-y-0.5">
              {renderValue(value?.new?.toString(), 'new')}
              {renderValue(value?.old?.toString(), 'old')}
            </div>
          );
        },
      });
      const isIdField = fieldName.endsWith('Id');
      const isDbIdField = fieldName.endsWith('DbId');
      if ((isIdField || isDbIdField) && !excludedIdFields.includes(fieldName)) {
        const fieldHeader = fieldName.slice(0, isDbIdField ? -4 : -2);
        result.push({
          id: fieldHeader,
          Header: fieldHeader,
          accessor: (entityChange: EntityChange): Change => {
            return entityChange.values[fieldName];
          },
          Cell: (props: { value: Change }): React.ReactElement => {
            const { value } = props;
            return (
              <div className="odin-flex odin-flex-col odin-gap-y-0.5">
                {renderValue(value?.newText, 'new')}
                {renderValue(value?.oldText, 'old')}
              </div>
            );
          },
        });
      }

      return result;
    }, []),
  ]);
};

export const getChangesByLayout = (changes: EntityChange[], layout: 'vertical' | 'horizontal'): EntityChange[] => {
  return layout === 'horizontal'
    ? changes
    : changes.flatMap((change) =>
        Object.entries(change.values as Record<string, Change>).map(([fieldName, value]) => ({
          ...change,
          fieldName,
          value,
        })),
      );
};
