import { Moment } from 'moment';
import cn from 'classnames';
import {
  BadgeCell,
  BadgeCellComponentProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownFilterOption,
  FilterItems,
} from '@odin-labs/components';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { SessionDateCell, SessionDateCellData } from 'containers/jobsiteAccessActivity/helpers/cells';
import {
  JobsiteAccessSession,
  WorkerJobsiteAccessSessionsFilters,
  WorkerJobsiteAccessSessionsFiltersOptions,
} from './types';

export type JobsiteAccessSessionColumn = Column<JobsiteAccessSession>;

export const getColumns = (): JobsiteAccessSessionColumn[] => [
  {
    id: 'jobsiteContractor',
    Header: 'Jobsite / Contractor',
    accessor: (): string[] => [],
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (session: JobsiteAccessSession): ChipsCellComponentProps => ({
      chips: [
        {
          text: session?.jobsiteWorker?.jobsiteContractor.jobsite.name,
          secondaryText: session.jobsiteWorker?.contractorWorker.contractor.organization.name,
          isActive: session.jobsiteWorker?.currentAccess.isAllowed,
        },
      ],
    }),
  },
  {
    id: 'date',
    Header: 'Date',
    accessor: (session: JobsiteAccessSession): SessionDateCellData => ({
      date: getISODateTimeToFormattedDate(session?.proximitySession?.day),
      isOvernight: session?.overnightSession ?? false,
    }),
    Cell: SessionDateCell,
  },
  {
    id: 'start',
    Header: 'Start',
    accessor: (session: JobsiteAccessSession): string => session.proximitySession?.locale?.time?.start,
  },
  {
    id: 'end',
    Header: 'End',
    accessor: (session: JobsiteAccessSession): string => session.proximitySession?.locale?.time?.end,
  },
  {
    id: 'totalHoursWorked',
    Header: 'Total Hours Worked',
    accessor: (session: JobsiteAccessSession): string => session.proximitySession?.span?.hours?.toFixed(2) || '0',
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: BadgeCell,
    componentProps: (session: JobsiteAccessSession): BadgeCellComponentProps => ({
      text: session.proximitySession?.compliant ? 'Compliant' : 'Non-Compliant',
      color: session.proximitySession?.compliant ? 'green' : 'red',
      size: 'lg',
    }),
  },
];

export const sessionStatusOptions: DropdownFilterOption[] = [
  {
    value: 'yes',
    label: 'Compliant',
  },
  {
    value: 'no',
    label: 'Non-Compliant',
  },
];

export const getFilterItems = ({
  filtersOptions,
  jobsiteIds,
  contractorIds,
  compliant,
  startDate,
  endDate,
}: {
  filtersOptions: WorkerJobsiteAccessSessionsFiltersOptions;
  jobsiteIds: string[];
  contractorIds: string[];
  compliant: string;
  startDate: Moment | string;
  endDate: Moment | string;
}): FilterItems<WorkerJobsiteAccessSessionsFilters> => ({
  dateRange: {
    header: 'Date',
    type: 'datePicker',
    defaultValue: { startDate, endDate },
    layout: cn('odin-col-span-full'),
    componentProps: {
      isRange: true,
    },
  },
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: filtersOptions?.jobsites ?? [],
    },
  },
  contractorIds: {
    header: 'Contractor',
    type: 'dropdown',
    defaultValue: contractorIds,
    componentProps: {
      options: filtersOptions?.contractors ?? [],
      isVirtual: true,
    },
  },
  compliant: {
    header: 'Compliant',
    type: 'dropdown',
    defaultValue: compliant,
    componentProps: {
      options: sessionStatusOptions,
      isMulti: false,
    },
  },
});
