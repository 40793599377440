import React, { ReactElement } from 'react';
import { CleaveInput } from 'components/cleaveInput';

import { LockModalProps } from './types';
import { BaseModal } from './BaseModal';

export function LockModal({
  isOpen = false,
  title,
  toggle,
  onCancel,
  onAction,
  lockCode,
  onLockCodeChanged,
  actionText,
}: LockModalProps): ReactElement {
  const subtitle =
    // eslint-disable-next-line max-len
    'This will lock the current session to the current url until you re-enter the lock code allowing you to navigate away.';
  return (
    <BaseModal
      actionButtonEnabled={lockCode.length === 6}
      title={title}
      subtitle={subtitle}
      onCancel={onCancel}
      onAction={onAction}
      isOpen={isOpen}
      toggle={toggle}
      cancelText="Cancel"
      actionText={actionText}
    >
      <CleaveInput
        onChange={(value: string): void => {
          onLockCodeChanged(value);
        }}
        value={lockCode}
        onSave={onAction}
        label="Lock Code"
        placeholder="000-000"
        cleaveType="lockCode"
      />
    </BaseModal>
  );
}
