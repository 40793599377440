import React from 'react';
import {
  AppErrorCode,
  useRegisterWorkerForFacialRecognitionBadgeMutation,
  UserErrorCode,
  useUpdateWorkerMutation,
  useUploadSingleFileMutation,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { InvalidPhotoAlert } from 'components/invalidPhotoAlert';
import {
  ProfilePhotoDirections,
  StepActions,
  StepInfo,
  StepInfoTitle,
} from 'containers/facialRecognition/steps/components';
import { FacialRecognitionStep, FacialRecognitionStepProps } from 'containers/facialRecognition/types';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { SelfOnboardingProfilePhotoFormData } from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { uploadCompressedFile } from 'utils';
import { getGraphQLError, hasGraphQLErrorCode } from 'utils/error';
import { AuthContext } from 'auth';
import { getDefaultValues, getFormInputs } from './ProfilePhotoStep.forms';

export function ProfilePhotoStep(props: FacialRecognitionStepProps): React.ReactElement {
  const { localize, language, stepNumberInfo, workerId, workerCardId, setCurrentStep } = props;

  const [isSaving, setIsSaving] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [updateWorker] = useUpdateWorkerMutation();
  const [uploadFile] = useUploadSingleFileMutation();
  const [registerWorkerForFacialRecognitionBadge] = useRegisterWorkerForFacialRecognitionBadgeMutation();

  const onSubmit: FormOnSubmit<SelfOnboardingProfilePhotoFormData> = async (
    data,
    event,
    dirtyFields,
    formApi,
  ): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const { file } = data;
      const profilePictureFileId = await uploadCompressedFile(file, uploadFile);

      try {
        await updateWorker({
          variables: {
            workerId,
            workerInput: {
              profilePictureFileId,
            },
          },
        });

        await registerWorkerForFacialRecognitionBadge({ variables: { workerId, workerCardId } });

        setIsSaving(false);
        setCurrentStep(FacialRecognitionStep.ProfilePhotoConfirmation);
      } catch (error) {
        event.preventDefault();
        setIsSaving(false);
        if (
          hasGraphQLErrorCode(
            error,
            AppErrorCode.AlcatrazImageRejected,
            AppErrorCode.AlcatrazProfileAlreadyAssociated,
            AppErrorCode.AlcatrazProfileConflict,
          )
        ) {
          formApi.setError('file', {
            message: <InvalidPhotoAlert localize={localize} />,
            shouldFocus: true,
          });
        } else {
          AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
        }
      }
    } catch (error) {
      if (hasGraphQLErrorCode(AppErrorCode.BadInput)) event.preventDefault();
      setIsSaving(false);
      AlertInstance.alert('tc', 'danger', 'Error uploading profile picture', getGraphQLError(error));
      if (hasGraphQLErrorCode(UserErrorCode.NotAuthenticated)) {
        setCurrentStep(FacialRecognitionStep.UserValidation);
      }
    }
  };

  const { currentUser: user } = React.useContext(AuthContext);
  const profilePicture = user?.profilePictureCropped;

  const inputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues(profilePicture), [profilePicture]);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.self_onboarding_profile_photo_header)}
          subtitle={localize(Copy.self_onboarding_profile_photo_instructions)}
          stepNumberInfo={stepNumberInfo}
        />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onIsDirtyChange={setIsFormDirty}
        autoFocus
        renderBelow={
          <div className="odin-mt-6">
            <ProfilePhotoDirections localize={localize} />
            <StepActions
              localize={localize}
              actions="continue"
              continueActionEnabled={isFormDirty || !!profilePicture}
              continueActionWithSpinner={isSaving}
            />
          </div>
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
      />
    </div>
  );
}
