import React from 'react';

export function ClearanceChecklistHeader(): React.ReactElement {
  return (
    <>
      <br />
      <ul>
        <li>
          <h2 className="odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900 odin-mt-0">Legend:</h2>
        </li>
        <li>Auto-assigned clearances are disabled</li>
        <li>* indicates it is configured as default</li>
      </ul>
    </>
  );
}
