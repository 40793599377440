import { Table, TableContainer } from '@odin-labs/components';
import React, { ReactElement } from 'react';
import { ContractorPaymentMethod, ContractorTabProps } from 'containers/contractor/types';
import {
  GetContractorContainerPaymentMethodsDocument,
  useGetContractorContainerPaymentMethodsQuery,
} from 'apollo/generated/client-operations';
import { getPaymentMethodsColumns } from 'containers/contractor/helpers/tables';
import { useResettableState } from 'utils';
import { RemovePaymentMethodModal } from './RemovePaymentMethodModal';
import { SetDefaultPaymentMethodModal } from './SetDefaultPaymentMethodModal';

export function ContractorPaymentMethodsTabContainer(props: ContractorTabProps): ReactElement {
  const { contractorId, onTabApiChange } = props;
  const refetchQueries = [GetContractorContainerPaymentMethodsDocument];

  const {
    data,
    loading,
    refetch: refetchPaymentMethods,
  } = useGetContractorContainerPaymentMethodsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      contractorId,
      includeArchived: true,
    },
  });

  const {
    value: removePaymentMethodId,
    setValue: openRemovePaymentMethodModal,
    resetValue: closeRemovePaymentMethodModal,
  } = useResettableState<string>(null);

  const {
    value: defaultPaymentMethodId,
    setValue: openSetDefaultPaymentMethodModal,
    resetValue: closeSetDefaultPaymentMethodModal,
  } = useResettableState<string>(null);

  const paymentMethods = React.useMemo(
    () =>
      (data?.getContractor?.stripePaymentMethods ?? []).map((spm) => {
        const matching = data?.getContractor?.jobsiteContractors?.edges?.filter(
          (jc) => jc.node.stripePaymentMethodId === spm.id,
        );
        return {
          ...spm,
          jobsites: matching?.map((jc) => jc.node.jobsite),
        } as ContractorPaymentMethod;
      }),
    [data, refetchPaymentMethods],
  );
  const columns = React.useMemo(
    () => getPaymentMethodsColumns({ openRemovePaymentMethodModal, openSetDefaultPaymentMethodModal }),
    [openRemovePaymentMethodModal, openSetDefaultPaymentMethodModal],
  );

  const paymentMethodsCount = paymentMethods?.length?.toString();
  React.useEffect(
    () => onTabApiChange({ refetchData: refetchPaymentMethods, paymentMethodsCount }),
    [refetchPaymentMethods, paymentMethodsCount],
  );

  return (
    <div id="payment-methods-container">
      <TableContainer className="contractor-payment-methods-container">
        <Table loading={loading} columns={columns} data={paymentMethods} />
      </TableContainer>
      <RemovePaymentMethodModal
        contractorId={contractorId}
        paymentMethodId={removePaymentMethodId}
        isOpen={!!removePaymentMethodId}
        onCancel={closeRemovePaymentMethodModal}
        onConfirm={closeRemovePaymentMethodModal}
        refetchQueries={refetchQueries}
      />
      <SetDefaultPaymentMethodModal
        contractorId={contractorId}
        paymentMethodId={defaultPaymentMethodId}
        isOpen={!!defaultPaymentMethodId}
        onCancel={closeSetDefaultPaymentMethodModal}
        onConfirm={closeSetDefaultPaymentMethodModal}
        refetchQueries={refetchQueries}
      />
    </div>
  );
}
