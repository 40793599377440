import { InfoRowProps } from 'components/infoRow/types';
import { DeveloperInfo } from 'containers/developer/types';
import React from 'react';
import { getPrettyFormattedDate } from 'utils/dates';

export type DeveloperInfoRowConfig = Omit<InfoRowProps, 'onEditClick' | 'allowEdit'>;

const getName = ({ firstName, lastName }: { firstName?: string; lastName?: string } = {}): string =>
  [firstName, lastName].filter(Boolean).join(' ');

export function getInfoRows(developer: DeveloperInfo): DeveloperInfoRowConfig[] {
  const { organization, objectHistory } = developer ?? {};
  const {
    createdBy: { identity: createdByIdentity = {} },
    createdAt,
  } = objectHistory ?? { createdBy: {} };

  const { addressLine1, addressLine2, addressCity, addressState, addressZipCode, name, legalName, email, phoneNumber } =
    organization ?? {};
  const organizationAddress = organization && (
    <>
      {addressLine1} {addressLine2} <br />
      {[addressCity, addressState].filter(Boolean).join(', ')} {addressZipCode}
    </>
  );

  return [
    { label: 'Display Name', value: name },
    { label: 'Legal Name', value: legalName },
    { label: 'Email', value: email },
    { label: 'Phone Number', value: phoneNumber },
    { label: 'Address', value: organizationAddress },
    { label: 'Created By', value: getName(createdByIdentity) },
    { label: 'Created On', value: getPrettyFormattedDate(createdAt), withBottomBorder: true },
  ];
}
