import React from 'react';
import cn from 'classnames';

type ItemProps<T extends HTMLElement> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;
type AdditionalProps<T extends HTMLElement> = Omit<ItemProps<T>, 'ref'> & {
  onEnterKey?: (e?: React.KeyboardEvent<T>) => void;
  onEscapeKey?: (e?: React.KeyboardEvent<T>) => void;
};

type ArrowNavigationResult<T extends HTMLElement> = {
  selectedIndex: number;
  getItemProps: (index: number, additionalProps?: AdditionalProps<T>) => ItemProps<T>;
};

export function useArrowNavigation<T extends HTMLElement>(
  itemsCount: number,
  defaultIndex?: number,
): ArrowNavigationResult<T> {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(defaultIndex ?? 0);
  const itemsRefs = React.useRef<T[]>([]);

  const getItemProps: ArrowNavigationResult<T>['getItemProps'] = (
    index: number,
    additionalProps?: AdditionalProps<T>,
  ) => {
    const { className, onKeyDown, onEnterKey, onEscapeKey, onFocus, ...restAdditionalProperties } =
      additionalProps ?? {};
    return {
      ref: (ref): void => {
        itemsRefs.current[index] = ref;
      },
      tabIndex: index,
      className: cn(
        className,
        'odin-outline-none focus:odin-bg-odin-highlight',
        selectedIndex === index ? 'odin-bg-odin-highlight' : null,
      ),
      onKeyDown: (e: React.KeyboardEvent<T>): void => {
        onKeyDown?.(e);
        if (!e.defaultPrevented) {
          if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
            const newIndex = index + (e.key === 'ArrowUp' ? -1 : 1);
            if (newIndex > -1 && newIndex < itemsCount) {
              itemsRefs.current[newIndex].focus();
              e.preventDefault();
            }
          } else if (e.key === 'Enter') {
            onEnterKey?.(e);
          } else if (e.key === 'Escape') {
            onEscapeKey?.(e);
          }
        }
      },
      onFocus: (e: React.FocusEvent<T>): void => {
        setSelectedIndex(index);
        onFocus?.(e);
      },
      ...restAdditionalProperties,
    };
  };

  return {
    selectedIndex,
    getItemProps,
  };
}
