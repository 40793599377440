import { JobsiteOnboardingField, JobsiteOnboardingStep } from 'apollo/generated/client-operations';
import { Jobsite, JobsiteModule, JobsiteOnboardingModule } from 'containers/jobsiteContractorWorker/types';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';

export { OnboardingStepKey };

export const getOnboardingModule = (modules: JobsiteModule[]): JobsiteOnboardingModule =>
  modules?.find((m): m is JobsiteOnboardingModule => {
    return m.__typename === 'JobsiteOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getStepConfig = (modules: JobsiteModule[], stepKey: OnboardingStepKey): JobsiteOnboardingStep => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return jobsiteOnboardingModule?.steps?.find((step) => step.key === stepKey);
};

export const getStepFieldsConfig = (
  stepKey: OnboardingStepKey,
  jobsite: Jobsite,
): Record<string, JobsiteOnboardingField> => {
  if (!jobsite) return {};
  const stepConfig = getStepConfig(jobsite.modules, stepKey);
  return stepConfig?.fields?.reduce((result, field) => ({ ...result, [field.key]: field }), {}) ?? {};
};
