import moment from 'moment';

import { JobsiteEquipmentStatusColumn } from './types';

// Equipment
export const getColumns = (): JobsiteEquipmentStatusColumn[] => [
  {
    id: 'deviceName',
    Header: 'Device',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    id: 'deviceType',
    Header: 'Device Type',
    accessor: 'type',
    disableSortBy: true,
  },
  {
    id: 'deviceId',
    Header: 'Device ID',
    accessor: 'guid',
    disableSortBy: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },
  {
    id: 'lastOnlineDate',
    Header: 'Last Online Date',
    accessor: (item): string =>
      item?.lastOnlineDate ? moment(item?.lastOnlineDate).format('M/D/YY h:mm:ss a') : 'Unknown',
    disableSortBy: true,
  },
  {
    id: 'lastOfflineDate',
    Header: 'Last Offline Date',
    accessor: (item): string =>
      item?.lastOfflineDate ? moment(item?.lastOfflineDate).format('M/D/YY h:mm:ss a') : 'Unknown',
    disableSortBy: true,
  },
];
