import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, SortingRule, Table, TableContainer } from '@odin-labs/components';
import { paginationSizePerPage as limit } from 'utils/constants';
import { useQueryOrderBy, useResettableState } from 'utils';
import { LoadingError } from 'components/loadingError';
import {
  useGetJobsiteContainerMembersQuery,
  GetJobsiteContainerMembersDocument,
  UserAssignmentType,
  GetUsersOrderByField,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { RemoveUserRoleModal } from 'containers/users/modals';
import { JobsiteTabProps } from 'containers/jobsite/types';
import { SortOrder } from 'utils/useQueryOrderBy';
import { JobsiteMember, JobsiteUserColumn, JobsiteUserFilters } from './types';
import { getFilterItems, getColumns, orderByFields } from './tables';

const workerProfileUrl = '/worker/';
const refetchQueries = [GetJobsiteContainerMembersDocument];

export function JobsiteUsersTabContainer(props: JobsiteTabProps): ReactElement {
  const { jobsiteId, onTabApiChange } = props;
  const {
    value: jobsiteUserToRemove,
    setValue: openRemoveJobsiteUserModal,
    resetValue: closeRemoveJobsiteUserModal,
  } = useResettableState<{ jobsiteMemberId: string; memberUserAccountId: string }>(null, null);
  const { jobsiteMemberId, memberUserAccountId } = jobsiteUserToRemove ?? {};

  const { currentUser: user } = React.useContext(AuthContext);
  const history = useHistory();

  const { page, search, orderBy: defaultSortField, orderByDesc: isDescending, updateUrl } = usePageQueryParams();
  const offset = page * limit;

  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetUsersOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetUsersOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const { data, loading, error, refetch } = useGetJobsiteContainerMembersQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      jobsiteId,
      jobsiteJobsiteMembersInput: {
        paginationInput: { limit, offset },
        search,
        orderBy,
      },
      userRolesInput: {
        objectName: 'Jobsite',
        objectId: jobsiteId,
      },
    },
  });

  const jobsite = data?.getJobsite;

  const columns = React.useMemo<JobsiteUserColumn[]>(
    () => getColumns({ user, jobsiteId, openRemoveJobsiteUserModal }),
    [user, jobsiteId, openRemoveJobsiteUserModal],
  );
  const filterItems = React.useMemo(() => getFilterItems({ search }), [search]);

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const onSortByChangeHandler = (sortBy: Array<SortingRule<JobsiteMember>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<JobsiteUserFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: jobsiteMember }: { data: JobsiteMember }): void => {
    history.push(`${workerProfileUrl}${jobsiteMember?.user?.worker.workerId}/user-roles`);
  };

  const users = jobsite?.jobsiteMembers?.edges.map(({ node }) => node);
  const usersCount = jobsite?.jobsiteMembers?.count ?? 0;
  const pageCount = Math.ceil(usersCount / limit);

  return (
    <>
      <TableContainer className="jobsite-users-container">
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={users}
          initialState={{ sortBy: tableSortBy, pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          onSortByChange={onSortByChangeHandler}
        />
      </TableContainer>
      <RemoveUserRoleModal
        isOpen={!!jobsiteMemberId}
        onCancel={closeRemoveJobsiteUserModal}
        onConfirm={closeRemoveJobsiteUserModal}
        userRoleType={UserAssignmentType.Jobsite}
        memberUserAccountId={memberUserAccountId}
        memberUserAssignmentId={jobsiteMemberId}
        refetchQueries={refetchQueries}
      />
    </>
  );
}
