import React, { ReactElement } from 'react';
import { DeveloperIcon } from 'containers/developer/types';
import { Organization } from 'containers/organizations/types';
import { ContractorIcon } from 'containers/contractor/types';

export type ElementListCellData = {
  elements: ReactElement[];
  extra: ReactElement;
};

export function OrganizationIconsCell({ value }: { value: Organization }): React.ReactElement {
  return (
    <div>
      {value?.developers?.count ? <DeveloperIcon className="odin-mr-2" /> : undefined}
      {value?.contractors?.count ? <ContractorIcon className="odin-mr-2" /> : undefined}
    </div>
  );
}
