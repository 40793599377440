import React from 'react';
import { Alert } from '@odin-labs/components';
import { LockedFeatureAlertProps } from './types';

const defaultMessage = (
  <>
    This feature is part of Worker Access. Contact&nbsp;
    <a href="mailto:support@useodin.com" target="blank">
      support@useodin.com
    </a>
    &nbsp;to learn how Worker Access can help you track workers on your jobsite in real-time, create timecards, and view
    workforce reporting.
  </>
);

export function LockedFeatureAlert(props: LockedFeatureAlertProps): React.ReactElement {
  const { message = defaultMessage } = props;
  return <Alert text={message} />;
}
