import { AuthContainer } from 'containers/auth';
import { LoginContainer } from 'containers/login';
import { RoutePage } from 'pages/RoutePage';
import { AuthRouteProps } from 'routes/types';

export const authRoutes: AuthRouteProps[] = [
  {
    component: RoutePage,
    container: LoginContainer,
    path: '/auth/login',
    exact: true,
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: 'Login',
      },
    ],
  },
  {
    component: RoutePage,
    container: AuthContainer,
    path: '/auth/complete',
    exact: true,
    title: 'Auth Complete',
    meta: [
      {
        name: 'description',
        content: 'OAuth Complete',
      },
    ],
  },
];
