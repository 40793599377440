import React from 'react';
import { FormDialog } from 'dialog';
import { getIcon } from 'utils/ui';
import { EditDocumentFileFormData } from 'containers/jobsiteConfiguration/types';
import { getDefaultValues, getFormInputs } from './AddDocumentFileModal.forms';
import { AddDocumentFileModalProps } from './types';

const PlusIcon = getIcon('fal fa-plus');

export function AddDocumentFileModal(props: AddDocumentFileModalProps): React.ReactElement {
  const { isOpen, onCancel, onConfirm, languageOptions, accept } = props;
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const onSubmit = (data: EditDocumentFileFormData): void => {
    const { language, title, file } = data;
    onConfirm?.({
      id: language.value,
      language: language.value,
      title: title?.trim() || null,
      file,
    });
  };

  const formInputs = React.useMemo(
    () => getFormInputs({ accept, languageOptions, editType: 'create' }),
    [accept, languageOptions],
  );

  const defaultValues = React.useMemo(
    () => getDefaultValues({ languageOptions, editType: 'create' }),
    [languageOptions],
  );

  return (
    <FormDialog
      id="addDocumentFile"
      open={isOpen}
      setOpen={onCancel}
      title="Add File"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add File"
      actionIcon={PlusIcon}
      onIsDirtyChange={setIsFormDirty}
      actionButtonEnabled={isFormDirty}
    />
  );
}
