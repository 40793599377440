/* eslint-disable import/no-import-module-exports */
import { matchPath } from 'react-router';
import { FeatureFlags } from 'featureFlags';
// containers
import { MobileOnlyContainer } from 'containers/mobileOnly';
// layouts
import { AppLayout } from 'layouts/application';
import { AuthLayout } from 'layouts/auth';
import { SelfOnboardingLayout } from 'layouts/selfOnboarding';
// pages
import { RoutePage } from 'pages/RoutePage';
// redirects
import { PreOnboardingRedirectContainer } from 'containers/redirects/PreOnboarding';
// sub-routes
import { AuthUser } from 'acl';
import { ExtendedRouteProps, ParentRouteProps } from './types';
import { appRoutes } from './app';
import { authRoutes } from './auth';
import { facialRecognitionRoutes } from './facialRecognition';
import { userChangeConfirmationRoutes } from './userChangeConfirmation';
import { mobileBadgeRoutes } from './mobileBadge';
import { selfOnboardingRoutes } from './onboarding';

type CreateRoutesArgs = {
  featureFlags: FeatureFlags;
  user: AuthUser;
};

const getEnabledRoutes = <T extends ExtendedRouteProps>(args: { routes: T[] } & CreateRoutesArgs): T[] => {
  const { routes, featureFlags, user } = args;
  return routes.filter(({ enabled }: T) => {
    return (
      enabled === undefined ||
      (typeof enabled === 'string' ? !!featureFlags?.[enabled] : enabled({ featureFlags, user }))
    );
  });
};

export const createRoutes = (args?: CreateRoutesArgs): ParentRouteProps[] => {
  return [
    {
      component: PreOnboardingRedirectContainer,
      path: '/pre-onboarding',
    },
    {
      component: SelfOnboardingLayout,
      path: '/self-onboarding',
      routes: getEnabledRoutes({ routes: selfOnboardingRoutes, ...args }),
      hasAuthDisabled: true,
    },
    {
      component: SelfOnboardingLayout,
      path: '/mobile-badge',
      routes: getEnabledRoutes({ routes: mobileBadgeRoutes, ...args }),
      hasAuthDisabled: true,
    },
    {
      component: SelfOnboardingLayout,
      path: '/facial-recognition',
      routes: getEnabledRoutes({ routes: facialRecognitionRoutes, ...args }),
      hasAuthDisabled: true,
    },
    {
      component: SelfOnboardingLayout,
      path: '/user-change-confirmation',
      routes: getEnabledRoutes({ routes: userChangeConfirmationRoutes, ...args }),
      hasAuthDisabled: true,
    },
    {
      component: AuthLayout,
      path: '/auth',
      routes: getEnabledRoutes({ routes: authRoutes, ...args }),
    },
    {
      component: RoutePage,
      path: '/mobile-only',
      exact: true,
      title: 'Mobile Only',
      container: MobileOnlyContainer,
      meta: [
        {
          name: 'description',
          content: 'Mobile Only',
        },
      ],
    },
    {
      component: AppLayout,
      routes: getEnabledRoutes({ routes: appRoutes, ...args }),
    },
  ];
};

if (module.hot) {
  module.hot.accept();
}

const toPreAuthRoutes = (result: ParentRouteProps[], route: ParentRouteProps): ParentRouteProps[] => {
  if (route.hasAuthDisabled) {
    result.push(route);
  } else if (route.routes) {
    (route.routes as ParentRouteProps[]).reduce(toPreAuthRoutes, result);
  }
  return result;
};

const preAuthRoutes = createRoutes().reduce(toPreAuthRoutes, []);

export const isPreAuthRoute = (pathname: string): boolean => {
  return preAuthRoutes.some((route) => matchPath(pathname, { path: route.path }));
};
