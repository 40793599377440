import React from 'react';
import cn from 'classnames';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { getFaIcon, Logo, NewMenuItemProps, NewDropdownButton } from '@odin-labs/components';
import { Copy } from './helpers/languages';
import { FacialRecognitionStepProps } from './types';
import { languageOptions } from './utils';

export type FacialRecognitionHeaderProps = Pick<
  FacialRecognitionStepProps,
  'language' | 'localize' | 'onLanguageChange'
> & {
  canGoToPreviousStep: boolean;
};
export type LanguageMenuItem = NewMenuItemProps & Pick<FacialRecognitionHeaderProps, 'language'>;

const GlobeIcon = getFaIcon({ icon: faGlobe });

const classes = {
  container: cn(
    'odin-sticky odin-top-0 odin-z-40',
    'odin-bg-odin-primary',
    'odin-flex odin-justify-between odin-items-center',
    'odin-px-7.5 odin-py-6.5',
  ),
  innerContainer: 'odin-mx-auto odin-w-full odin-max-w-7xl odin-flex odin-justify-between',
  leftContainer: 'odin-flex odin-items-center',
  logoHolder: cn('odin-flex odin-justify-start'),
};

export function FacialRecognitionHeader(props: FacialRecognitionHeaderProps): React.ReactElement {
  const { language, onLanguageChange, localize } = props;

  const menuItems: LanguageMenuItem[] = languageOptions.map((opt) => ({
    onClick: (): void => onLanguageChange(opt),
    text: localize(Copy.language, { locale: opt.value }),
    language: opt.value,
  }));

  const languageText = menuItems.find((mi) => mi.language === language)?.text;

  return (
    <header className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.logoHolder}>
            <span className="sr-only">ODIN</span>
            <Logo logoStyle="textOnly" size="md" />
          </div>
        </div>
        <NewDropdownButton
          menuItems={menuItems}
          icon={GlobeIcon}
          text={languageText}
          size="sm"
          theme="twoTone"
          hideFocusBorder
        />
      </div>
    </header>
  );
}
