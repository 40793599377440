import { SelfOnboardingState, SelfOnboardingStepKey } from 'containers/selfOnboarding/types';

export type SequentialStepState = { worker: Pick<SelfOnboardingState['worker'], 'isAssignedToMultipleJobsites'> };
export type SequentialStep =
  | SelfOnboardingStepKey
  | {
      key: SelfOnboardingStepKey;
      isVisible: (state: SequentialStepState) => boolean;
    };

export const sequentialSteps: SequentialStep[] = [
  SelfOnboardingStepKey.LanguageSelection,
  SelfOnboardingStepKey.BasicInfo,
  SelfOnboardingStepKey.Profile,
  SelfOnboardingStepKey.EmergencyContactInfo,
  SelfOnboardingStepKey.GovernmentIssuedId,
  SelfOnboardingStepKey.NycSiteSafetyTrainingCard,
  SelfOnboardingStepKey.OshaCard,
  SelfOnboardingStepKey.JobsiteSafetyVideo,
  SelfOnboardingStepKey.JobsiteSafetyDocument,
  SelfOnboardingStepKey.LIRRBlueTraining,
  SelfOnboardingStepKey.ConfinedSpacesTraining,
  SelfOnboardingStepKey.ProfilePhoto,
  {
    key: SelfOnboardingStepKey.VerifyMobileDevice,
    isVisible: (state): boolean => !state?.worker?.isAssignedToMultipleJobsites,
  },
  SelfOnboardingStepKey.ReviewCompletedProfile,
];

const lastSequentialStep = sequentialSteps[sequentialSteps.length - 1];
export const lastStep = typeof lastSequentialStep === 'string' ? lastSequentialStep : lastSequentialStep.key;

export const noOrderInfoSteps: SelfOnboardingStepKey[] = [
  SelfOnboardingStepKey.LanguageSelection,
  SelfOnboardingStepKey.BasicInfo,
  SelfOnboardingStepKey.VerifyMobileDeviceToSignIn,
  SelfOnboardingStepKey.ConfirmMobileDevice,
  SelfOnboardingStepKey.ConfirmMobileDeviceToSignIn,
  SelfOnboardingStepKey.ReviewCompletedProfile,
];

export const noGoBackSteps: SelfOnboardingStepKey[] = [SelfOnboardingStepKey.BasicInfo, SelfOnboardingStepKey.Closed];

type WhiteListedStepState = Pick<SelfOnboardingState, 'didWorkerMatch'>;
export type WhiteListedStep =
  | SelfOnboardingStepKey
  | {
      key: SelfOnboardingStepKey;
      isWhiteListed: (state: WhiteListedStepState) => boolean;
    };

function isWhiteListedIfWorkerMatched(state: WhiteListedStepState): boolean {
  return state?.didWorkerMatch;
}

export const whiteListedSteps: WhiteListedStep[] = [
  SelfOnboardingStepKey.LanguageSelection,
  SelfOnboardingStepKey.BasicInfo,
  SelfOnboardingStepKey.WorkerConsentDocument,
  { key: SelfOnboardingStepKey.AlreadyCompleted, isWhiteListed: isWhiteListedIfWorkerMatched },
  { key: SelfOnboardingStepKey.VerifyMobileDeviceToSignIn, isWhiteListed: isWhiteListedIfWorkerMatched },
  { key: SelfOnboardingStepKey.ConfirmMobileDeviceToSignIn, isWhiteListed: isWhiteListedIfWorkerMatched },
  { key: SelfOnboardingStepKey.UnableToVerifyAccount, isWhiteListed: isWhiteListedIfWorkerMatched },
];
