import React from 'react';

import { classes } from './WorkerHeader.style';

// eslint-disable-next-line react/function-component-definition
export const WorkerHeader: React.FC = ({ children }) => (
  <div data-test="worker-header" className={classes.header}>
    {children}
  </div>
);

// eslint-disable-next-line react/require-default-props,react/function-component-definition
export const WorkerHeaderRow: React.FC<{ className?: string; 'data-test'?: string }> = ({
  children,
  className,
  'data-test': dataTest,
}) => (
  <div data-test={dataTest} className={className}>
    {children}
  </div>
);
