import { gql } from '@apollo/client';
import { JobsiteAccessEventStatus, CardPrintJob } from 'apollo/generated/client-operations';
import { OBJECT_HISTORY_GRAPHQL_PROPS } from 'utils/queries';

export const UPDATE_WORKER_AND_IDENTITY = gql`
  mutation UpdateWorker(
    $workerId: ID!
    $workerInput: WorkerInput!
    $userAccountId: ID!
    $userUpdateIdentityInput: UserUpdateIdentityInput!
  ) {
    updateWorker(workerId: $workerId, workerInput: $workerInput) {
      middleInitial
      user {
        identity {
          firstName
        }
      }
    }
    userUpdateIdentity(input: $userUpdateIdentityInput) {
      user {
        identity {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_WORKER_COMMENTS = gql`
  query GetWorkerComments($workerId: ID!, $offset: Int, $limit: Int, $includeArchived: Boolean) {
    getWorkerComments(workerId: $workerId, offset: $offset, limit: $limit, includeArchived: $includeArchived) {
      totalCount
      edges {
        workerCommentId
        comment
        jobsite {
          name
          jobsiteId
        }
        ${OBJECT_HISTORY_GRAPHQL_PROPS}
      }
    }
  }
`;

export const CREATE_WORKER_COMMENT = gql`
  mutation CreateWorkerComment($workerCommentInput: WorkerCommentInput!) {
    createWorkerComment(workerCommentInput: $workerCommentInput) {
      workerCommentId
      comment
    }
  }
`;

export const UPDATE_JOBSITE_WORKER = gql`
  mutation UpdateJobsiteWorker($jobsiteWorkerId: ID!, $jobsiteWorkerInput: JobsiteWorkerInput!) {
    updateJobsiteWorker(jobsiteWorkerId: $jobsiteWorkerId, jobsiteWorkerInput: $jobsiteWorkerInput) {
      orientationDate
    }
  }
`;

export const REMOVE_CARD_FROM_WORKER = gql`
  mutation RemoveCardFromWorker($workerCardIds: [String!]!) {
    removeCardFromWorker(workerCardIds: $workerCardIds) {
      success
    }
  }
`;

export const REISSUE_MOBILE_BADGE = gql`
  mutation ReissueMobileBadge($workerCardId: ID!, $newPhoneNumber: String) {
    reissueMobileBadge(workerCardId: $workerCardId, newPhoneNumber: $newPhoneNumber) {
      cardNumber
      workerCardId
    }
  }
`;

export const GET_WORKER_DOCUMENTS = gql`
  query GetWorkerDocuments($workerId: ID!, $includePhi: Boolean, $includeArchived: Boolean) {
    getWorkerDocuments(workerId: $workerId, includePhi: $includePhi, includeArchived: $includeArchived) {
      workerDocuments {
        jobsiteWorkerDocumentId
        jobsiteWorkerDocumentType {
          expiration
          jobsite {
            name
            jobsiteId
            developer {
              name
              developerId
            }
          }
          workerDocumentType {
            name
            key
            expirationDate
            isGlobal
            documentLevel
          }
        }
        jobsiteWorker {
          jobsiteContractor {
            jobsite {
              jobsiteId
            }
          }
          jobsiteWorkerId
        }
        jobsiteWorkerDocumentVersions {
          jobsiteWorkerDocumentVersionId
          files {
            fileId
            fileType
            downloadUrl
          }
          additionalFieldValues {
            key
            value
          }
          ${OBJECT_HISTORY_GRAPHQL_PROPS}
        }
      }
    }
  }
`;

export const getWorkerAccessEventStatus = (accessEventStatus: string | string[]): JobsiteAccessEventStatus => {
  if (typeof accessEventStatus === 'string' && accessEventStatus.length > 0) {
    return accessEventStatus === JobsiteAccessEventStatus.Admit
      ? JobsiteAccessEventStatus.Admit
      : JobsiteAccessEventStatus.Reject;
  }
  return null;
};

export const ARCHIVE_WORKER = gql`
  mutation ArchiveWorker($workerId: ID!) {
    archiveWorker(workerId: $workerId) {
      user {
        identity {
          firstName
          lastName
        }
      }
    }
  }
`;

export interface getCurrentCardPrintJobResponse {
  getCurrentCardPrintJob: CardPrintJob;
}

export const GET_CURRENT_CARD_PRINT_JOB = gql`
  query GetCurrentCardPrintJob($workerId: ID!) {
    getCurrentCardPrintJob(workerId: $workerId) {
      cardPrintJobId
      status
      statusMessage
      startedAt
      completion {
        cardNumber
        completedAt
      }
      jobsite {
        jobsiteId
      }
      workerCard {
        workerCardId
        cardNumber
      }
    }
  }
`;
