export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    JobsiteModule: [
      'JobsiteAppCheckInModule',
      'JobsiteFeaturesModule',
      'JobsiteOnboardingModule',
      'JobsitePreOnboardingModule',
    ],
    JobsiteWorkerCheckInArea: ['JobsiteAppCheckInModuleArea'],
    JobsiteWorkerCheckInFlag: ['JobsiteWorkerCheckInAppFarFromAreaFlag', 'JobsiteWorkerCheckInManualOverrideFlag'],
    ValidModule: [
      'JobsiteAppCheckInModule',
      'JobsiteFeaturesModule',
      'JobsiteOnboardingModule',
      'JobsitePreOnboardingModule',
    ],
  },
};
export default result;
