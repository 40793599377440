import cn from 'classnames';
import {
  Column,
  AvatarCell,
  AvatarSkeleton,
  AvatarCellComponentProps,
  BadgeCell,
  BadgeCellComponentProps,
} from '@odin-labs/components';

import { getInitialsForUser, getUserFullName } from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { GetJobsiteAccessSessionsQuery } from 'apollo/generated/client-operations';

export type JobsiteAccessSession =
  GetJobsiteAccessSessionsQuery['getCurrentSession']['user']['jobsiteAccessSessions']['edges'][0]['node'];

export type JobsiteWorkerSessionColumn = Column<JobsiteAccessSession>;

export const getColumns = (): JobsiteWorkerSessionColumn[] => [
  {
    Header: 'Worker',
    accessor: (session: JobsiteAccessSession): string =>
      getUserFullName(session.jobsiteWorker.contractorWorker.worker.user),
    Cell: AvatarCell,
    CellLoading: AvatarSkeleton,
    componentProps: (session: JobsiteAccessSession): AvatarCellComponentProps => ({
      src: session.jobsiteWorker?.contractorWorker.worker.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(session.jobsiteWorker?.contractorWorker.worker.user),
      objectFit: 'cover',
      // detail: getUserRole(incident),
    }),
  },
  {
    Header: 'Date',
    accessor: (session: JobsiteAccessSession): string => getISODateTimeToFormattedDate(session.proximitySession.day),
  },
  {
    Header: 'Jobsite',
    accessor: (session: JobsiteAccessSession): string => session.jobsiteWorker?.jobsiteContractor.jobsite.name,
  },
  {
    Header: 'Start',
    accessor: (session: JobsiteAccessSession): string => session.proximitySession?.locale?.time?.start,
  },
  {
    Header: 'End',
    accessor: (session: JobsiteAccessSession): string => session.proximitySession?.locale?.time?.end,
  },
  {
    Header: 'Hours Worked',
    accessor: (session: JobsiteAccessSession): string => session.proximitySession?.span?.hours?.toLocaleString('en-US'),
  },
  {
    Header: 'Compliant',
    Cell: BadgeCell,
    componentProps: (session: JobsiteAccessSession): BadgeCellComponentProps => ({
      text: session.proximitySession?.compliant.toString(),
      color: session.proximitySession?.compliant ? 'green' : 'red',
      background: 'transparent',
      withDot: true,
      size: 'lg',
      className: cn('odin-capitalize'),
    }),
  },
];
