import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { Form } from 'components/form';
import { LoadingError } from 'components/loadingError';
import { UploadDocumentModalContainerProps } from 'containers/modals/types';
import { DocumentKey } from 'containers/worker/utils';
import { AuthContext } from 'auth';
import {
  generateJobsiteMedicalWorkerDocument,
  JobsiteMedicalWorkerDocumentArgs,
} from 'containers/workerOnboarding/helpers/forms';
import { alcoholBreathTestOptions } from 'utils/constants';
import { FormDefaultValue, FormOnSubmit } from 'components/form/types';
import { useMedicalDocumentsUpload } from 'containers/workerOnboarding/helpers/useMedicalDocumentsUpload';
import { MedicalConditionsDataType } from 'containers/workerOnboarding/types';
import { useJobsiteWorkerOnboardingDocuments } from 'containers/workerOnboarding/helpers/useJobsiteWorkerOnboardingDocuments';

type MedicalDocumentKeys = Extract<
  DocumentKey,
  | DocumentKey.MedicalDrugTestingConsentForm
  | DocumentKey.MedicalCovid19OrientationAndProcedures
  | DocumentKey.PostEmploymentBreathAlcoholTest
  | DocumentKey.PostEmploymentUrineDrugTest
>;

const inputsArgsByDocType: Record<
  MedicalDocumentKeys,
  Omit<JobsiteMedicalWorkerDocumentArgs, 'documentKey' | 'previewUrl' | 'showExistingDocumentAlert'>
> = {
  [DocumentKey.MedicalDrugTestingConsentForm]: {
    label: 'Drug Testing Consent Form',
    showDateSigned: true,
    requiredFields: ['date-signed', 'front'],
  },
  [DocumentKey.PostEmploymentBreathAlcoholTest]: {
    label: 'Breath alcohol test',
    selectOptions: alcoholBreathTestOptions,
    showReason: true,
    showNotes: true,
    requiredFields: ['result', 'front'],
  },
  [DocumentKey.PostEmploymentUrineDrugTest]: {
    label: 'Urine drug test',
    selectOptions: alcoholBreathTestOptions,
    showReason: true,
    showNotes: true,
    requiredFields: ['result', 'front'],
  },
  [DocumentKey.MedicalCovid19OrientationAndProcedures]: {
    label: 'COVID-19 Orientation and Procedures',
    showDateSigned: true,
    requiredFields: ['date-signed', 'front'],
  },
};

const getDefaultValues = (documentType: string): FormDefaultValue<MedicalConditionsDataType>[] => [
  {
    name: `${documentType}-front`,
    value: null,
  },
  {
    name: `${documentType}-back`,
    value: null,
  },
  {
    name: `${documentType}-test-taken-date`,
    value: '',
  },
  {
    name: `${documentType}-result`,
    value: '',
  },
  {
    name: `${documentType}-test-reason`,
    value: '',
  },
  {
    name: `${documentType}-notes`,
    value: '',
  },
  {
    name: `${documentType}-conditional-access-expiration-date`,
    value: '',
  },
  {
    name: `${documentType}-date-signed`,
    value: '',
  },
  {
    name: `${documentType}-test-date`,
    value: '',
  },
];

export function UploadMedicalDocumentModalContainer({
  isModalOpen,
  jobsiteWorker,
  jobsiteWorkerDocumentTypeKey,
  setModalOpen,
}: UploadDocumentModalContainerProps): ReactElement {
  const documentType = jobsiteWorkerDocumentTypeKey as MedicalDocumentKeys;

  const { currentUser: user } = React.useContext(AuthContext);
  const formRef = React.useRef<HTMLFormElement>();

  const {
    filteredDocumentTypeIds,
    jobsiteWorkerDocuments,
    refetchJobsiteWorkerDocuments,
    error: documentsError,
    loading: documentsLoading,
  } = useJobsiteWorkerOnboardingDocuments({ jobsiteWorker });

  const documents = jobsiteWorkerDocuments?.map((document) => {
    return {
      key: document?.jobsiteWorkerDocumentType?.workerDocumentType?.key,
      id: document?.jobsiteWorkerDocumentId,
    };
  });

  const { uploadDocuments, fetching, setFetching } = useMedicalDocumentsUpload({
    user,
    jobsiteWorker,
    documentTypes: [documentType],
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
    onUploadCompleted: () => setModalOpen(false),
  });

  const onSubmit: FormOnSubmit<MedicalConditionsDataType> = (data, _event, dirtyFields): void => {
    uploadDocuments({ data, dirtyFields });
  };

  const inputsArgs = inputsArgsByDocType[documentType];
  const inputs = inputsArgs && [
    generateJobsiteMedicalWorkerDocument({
      ...inputsArgs,
      documentKey: documentType,
      hideDocumentLabel: true,
      previewUrl: null,
      showExistingDocumentAlert: false,
    }),
  ];
  const documentTypeName = inputsArgs?.label;
  const defaultValues = getDefaultValues(documentType);

  return (
    <Modal
      title={`Update ${documentTypeName}`}
      actionText="Save"
      onAction={(): void => {
        formRef.current?.requestSubmit();
      }}
      setOpen={(): void => {
        setFetching(false);
        setModalOpen(false);
      }}
      open={isModalOpen}
      actionButtonWithSpinner={fetching}
    >
      {fetching || documentsLoading || documentsError ? (
        <LoadingError loading={fetching || documentsLoading} error={documentsError} />
      ) : (
        <div>
          <Form inputs={inputs} ref={formRef} defaultValues={defaultValues} onSubmit={onSubmit} />
        </div>
      )}
    </Modal>
  );
}
