import cn from 'classnames';

export const classes = {
  container: cn(
    'not-found-container',
    'odin-min-h-full',
    'odin-px-4',
    'odin-py-16',
    'sm:odin-px-6',
    'sm:odin-py-24',
    'md:odin-grid',
    'md:odin-place-items-center',
    'lg:odin-px-8',
  ),
  containerContent: cn('odin-max-w-max', 'odin-mx-auto'),
  actionContent: cn(
    'odin-mt-10',
    'odin-flex',
    'odin-space-x-3',
    'sm:odin-border-l',
    'sm:odin-border-transparent',
    'sm:odin-pl-6',
  ),
};
