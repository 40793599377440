import React from 'react';
import { faCalendarDay, faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DefaultCellSkeleton,
  FilterItems,
  getFaIcon,
} from '@odin-labs/components';
import { GetJobsiteWorkersOrderByField } from 'apollo/generated/client-operations';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { getInitialsForUser, getUserFullName } from 'utils';
import { getPrettyFormattedDate } from 'utils/dates';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';
import { Worker, Contractor, WorkersFilters } from 'containers/workers/types';
import { getContractorsOptions } from 'containers/contractor/helpers';

export type WorkerColumn = Column<Worker>;

const ShieldCheckIcon = getFaIcon({
  icon: faShieldCheck,
  className: 'odin-text-odin-primary odin-mr-1 md:odin-hidden',
});

const ShieldCalendarDay = getFaIcon({
  icon: faCalendarDay,
  className: 'odin-text-odin-primary odin-mr-1 md:odin-hidden',
});

export const getColumns = (): WorkerColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: (worker: Worker): string => getUserFullName(worker.user),
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} />,
    componentProps: (worker: Worker): AvatarCellComponentProps => ({
      src: worker.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(worker.user),
      objectFit: 'cover',
      size: ['md', 'xl:xs'],
      detail: [worker.trade, worker.jobTitle].filter(Boolean).join(', '),
    }),
  },
  {
    id: 'quickCode',
    Header: 'Quick Code',
    cellContainerClassName: '!odin-inline-block md:!odin-table-cell',
    accessor: 'quickCode',
    Cell: ({ value: quickCode }: { value: string }): React.ReactElement => (
      <span className="odin-text-sm odin-text-gray-700 md:odin-text-gray-900">
        <ShieldCheckIcon />
        <span>{quickCode}</span>
      </span>
    ),
    CellLoading: (): React.ReactElement => <DefaultCellSkeleton className="odin-w-24 md:odin-w-full" />,
  },
  {
    id: 'dateOfBirth',
    Header: 'Date of Birth',
    cellContainerClassName: '!odin-inline-block !odin-pl-2 md:!odin-table-cell',
    accessor: (worker: Worker): string => getPrettyFormattedDate(worker.birthDate),
    Cell: ({ value: dateOfBirth }: { value: string }): React.ReactElement => (
      <span className="odin-text-sm odin-text-gray-700 md:odin-text-gray-900">
        <ShieldCalendarDay />
        <span>{dateOfBirth}</span>
      </span>
    ),
    CellLoading: (): React.ReactElement => <DefaultCellSkeleton className="odin-w-24 md:odin-w-full" />,
  },
  {
    id: 'jobsiteAssignments',
    Header: 'Jobsite Assignments',
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (worker: Worker): ChipsCellComponentProps => ({
      chips: worker.jobsiteWorkers.edges.map(({ node }) => ({
        text: node.jobsiteContractor.jobsite.name,
        secondaryText: node.jobsiteContractor.contractor.organization.name,
        isActive: node.currentAccess.isAllowed,
      })),
    }),
    disableSortBy: true,
  },
];

export const getFilterItems = ({
  jobsites,
  contractors,
  developerIds,
  jobsiteIds,
  contractorIds,
  search,
}: {
  jobsites: Jobsite[];
  contractors: Contractor[];
  developerIds: string[];
  jobsiteIds: string[];
  contractorIds: string[];
  search: string;
}): FilterItems<WorkersFilters> => ({
  developerIds: {
    header: 'Client',
    type: 'dropdown',
    defaultValue: developerIds,
    componentProps: {
      options: getDeveloperOptionsFromJobsites(jobsites),
      allowSearch: false,
    },
  },
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
    },
  },
  contractorIds: {
    header: 'Contractor',
    type: 'dropdown',
    defaultValue: contractorIds,
    componentProps: {
      options: getContractorsOptions(contractors) ?? [],
      isVirtual: true,
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});

export const orderByFields: Record<string, GetJobsiteWorkersOrderByField> = {
  name: GetJobsiteWorkersOrderByField.Name,
  jobsiteName: GetJobsiteWorkersOrderByField.JobsiteName,
  birthDate: GetJobsiteWorkersOrderByField.BirthDate,
  trade: GetJobsiteWorkersOrderByField.Trade,
  contractor: GetJobsiteWorkersOrderByField.Contractor,
  orientationDate: GetJobsiteWorkersOrderByField.OrientationDate,
  orientationStatus: GetJobsiteWorkersOrderByField.OrientationStatus,
  siteSpecificStatus: GetJobsiteWorkersOrderByField.SiteSpecificStatus,
};
