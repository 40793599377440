import React, { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { faBriefcase, faPlus, faWrench } from '@fortawesome/pro-light-svg-icons';
import { NewDropdownButton, NewMenuItemProps, TabConfig, getFaIcon } from '@odin-labs/components';
import { useBoolean } from 'utils';
import { NewHeader } from 'components/header/NewHeader';
import { Container } from 'components/container';
import {
  GetUsersDocument,
  UserRoleKey,
  useGetRolesQuery,
  UserAssignmentType,
} from 'apollo/generated/client-operations';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { AddUserModal } from './modals';
import { UsersTab, UsersTabProps, UsersTabApi } from './UsersTab';

const JobsiteIcon = getFaIcon({ icon: faBriefcase });
const ContractorIcon = getFaIcon({ icon: faWrench });
const PlusIcon = getFaIcon({ icon: faPlus });

const refetchQueries = [GetUsersDocument];

const baseRoute = `/users`;
const contractorUsersRoute = `${baseRoute}/contractor-users`;

const tabsConfig: TabConfig<UsersTabProps>[] = [
  { name: 'jobsiteUsers', text: `Jobsite Users`, relativePath: '', component: UsersTab },
  {
    name: 'contractorUsers',
    text: `Contractor Users`,
    relativePath: '/contractor-users',
    component: UsersTab,
  },
];

export function UsersContainer(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const {
    value: isAddJobsiteUserModalOpen,
    setTrue: openAddJobsiteUserModal,
    setFalse: closeAddJobsiteUserModal,
  } = useBoolean(false);
  const {
    value: isAddContractorUserModalOpen,
    setTrue: openAddContractorUserModal,
    setFalse: closeAddContractorUserModal,
  } = useBoolean(false);

  const { data: rolesData } = useGetRolesQuery({ fetchPolicy: 'network-only' });
  const userRoles = rolesData?.getRoles.filter(({ key }) => key !== UserRoleKey.Worker);

  const [tabApi, setTabApi] = React.useState<UsersTabApi>(null);

  const tabsCount: Record<string, string> = {
    jobsiteUsers: tabApi?.jobsiteUsersCount?.toString() ?? '...',
    contractorUsers: tabApi?.contractorUsersCount?.toString() ?? '...',
  };

  const tabsConfigWithBadges = tabsConfig.map((tab) => ({ ...tab, badge: tabsCount[tab.name] }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: tabsConfigWithBadges, baseRoute });

  const closeModalAndNavigateToJobsiteUsersIfNeeded = React.useCallback((): void => {
    if (location.pathname !== baseRoute) {
      history.push(baseRoute);
    }
    closeAddJobsiteUserModal();
  }, [closeAddJobsiteUserModal, history, location]);

  const closeModalAndNavigateToContractorUsersIfNeeded = React.useCallback((): void => {
    if (location.pathname !== contractorUsersRoute) {
      history.push(contractorUsersRoute);
    }
    closeAddContractorUserModal();
  }, [closeAddContractorUserModal, history, location]);

  const menuItems: NewMenuItemProps[] = [
    {
      onClick: openAddJobsiteUserModal,
      text: 'Jobsite User',
      icon: JobsiteIcon,
    },
    {
      onClick: openAddContractorUserModal,
      text: 'Contractor User',
      icon: ContractorIcon,
    },
  ];

  const tabsPageProps: UsersTabProps = {
    onTabApiChange: setTabApi,
    userRoles,
    userAssignmentType:
      currentTab.name === 'contractorUsers' ? UserAssignmentType.Contractor : UserAssignmentType.Jobsite,
  };

  return (
    <Container className="users-container">
      <NewHeader
        title="Users"
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          children: !!menuItems.length && <NewDropdownButton menuItems={menuItems} icon={PlusIcon} text="Add" />,
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      <AddUserModal
        isOpen={isAddJobsiteUserModalOpen}
        onCancel={closeAddJobsiteUserModal}
        onConfirm={closeModalAndNavigateToJobsiteUsersIfNeeded}
        refetchQueries={refetchQueries}
        userRoleType={UserAssignmentType.Jobsite}
        userRoles={userRoles}
      />
      <AddUserModal
        isOpen={isAddContractorUserModalOpen}
        onCancel={closeAddContractorUserModal}
        onConfirm={closeModalAndNavigateToContractorUsersIfNeeded}
        refetchQueries={refetchQueries}
        userRoleType={UserAssignmentType.Contractor}
        userRoles={userRoles}
      />
    </Container>
  );
}
