import React from 'react';
import { SearchBoxAutocompleteViewProps } from '@odin-labs/components';
import { SearchWorkerResult } from './types';
import { classes } from './SearchBoxAutocompleteList.style';
import { SearchWorkerInfo } from './searchWorkerInfo/SearchWorkerInfo';

export function SearchBoxAutocompleteList(props: SearchBoxAutocompleteViewProps): React.ReactElement {
  const { autocompletedResults, getItemProps, inputValue } = props;

  return (
    <>
      <div className={classes.autoCompleteListContainer}>
        <ul className={classes.autoCompleteList}>
          {autocompletedResults.map((autocompletedResult: SearchWorkerResult, i) => {
            const itemProps = getItemProps({
              key: autocompletedResult.id,
              item: autocompletedResult,
              index: i,
            });
            return (
              <li {...itemProps} className={classes.autoCompleteListItem}>
                <SearchWorkerInfo searchWorkerResult={autocompletedResult} />
              </li>
            );
          })}
        </ul>
      </div>
      <div className={classes.seeAllResultsContainer}>
        <button type="submit" className={classes.seeAllResultsButton}>
          <i className={classes.seeAllResultsIcon('fal fa-search')} aria-hidden="true" />
          See all results for “{inputValue}”
        </button>
      </div>
    </>
  );
}
