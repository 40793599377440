import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import { useArchiveJobsiteMutation } from 'apollo/generated/client-operations';
import { getIcon } from 'utils/ui';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import { ArchiveJobsiteModalProps } from './types';

const TrashIcon = getIcon('fal fa-trash');

export function ArchiveJobsiteModal(props: ArchiveJobsiteModalProps): ReactElement {
  const { isOpen, closeModal, jobsite } = props;
  const { jobsiteId, name } = jobsite ?? {};

  const [archiveJobsite, { loading }] = useArchiveJobsiteMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      closeModal();
      AlertInstance.alert('tc', 'success', 'Success', 'Jobsite successfully archived');
    },
    onError: (responseError: ApolloError) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    variables: { jobsiteId },
  });

  return (
    <Modal
      open={isOpen}
      setOpen={closeModal}
      size="xs"
      title={`Archive "${name}" Jobsite?`}
      titleAlignment="center"
      textAlignment="center"
      onAction={archiveJobsite}
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionText="Archive Jobsite"
      actionButtonWithSpinner={loading}
    />
  );
}
