import React from 'react';
import cn from 'classnames';
import { getFormattedDate } from 'utils';
import { getMedicalDocType, getWorkerDocumentFieldValue } from 'containers/worker/utils';
import { JfsHistoricalJobsiteWorkerDocument } from 'containers/jobsiteFormSubmission/print/types';

const classes = {
  jobsite: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-medium'),
  date: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  time: cn('odin-text-gray-600 odin-text-sm odin-leading-5 odin-font-normal'),
};

export function JobsiteCell({ value }: { value: string }): React.ReactElement {
  return <div className={classes.jobsite}>{value}</div>;
}

export function DateTimeCell({ value }: { value: JfsHistoricalJobsiteWorkerDocument }): React.ReactElement {
  const { latestVersion } = value ?? {};
  const testDate = getWorkerDocumentFieldValue(latestVersion, 'test-taken-date');
  const date = getFormattedDate(testDate);

  return (
    <>
      <div className={classes.date}>{date}</div>
      {/* <div className={classes.time}>{'time'}</div> */}
    </>
  );
}

export function TestTypeCell({ value }: { value: JfsHistoricalJobsiteWorkerDocument }): React.ReactElement {
  const { latestVersion } = value ?? {};
  const testReason = getWorkerDocumentFieldValue(latestVersion, 'test-reason');
  return (
    <>
      <div className={classes.date}>{getMedicalDocType(value?.jobsiteWorkerDocumentType?.workerDocumentType?.key)}</div>
      {testReason && <div className={classes.time}>{`${testReason} Test`}</div>}
    </>
  );
}
