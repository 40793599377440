import React, { ReactElement } from 'react';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { getFormattedPhoneNumber } from 'utils';
import { GridField } from 'components/gridField';
import { WorkerPersonalInfoProps } from './types';
import { classes } from './WorkerReportPersonalInfo.style';

export function WorkerReportPersonalInfo({ worker }: WorkerPersonalInfoProps): ReactElement {
  if (!worker) {
    return null;
  }

  const addressValue = (
    <>
      {worker?.addressLine1} {worker?.addressLine2}
      <br />
      {worker?.addressCity}, {worker?.addressState} {worker?.addressZipCode}
    </>
  );

  const emergencyContactValue = (
    <>
      {worker?.emergencyContactName}
      <br />
      {worker?.emergencyContactRelationship}
      <br />
      {getFormattedPhoneNumber(worker?.emergencyContactPhone)}
    </>
  );

  const workerBirthDate = getISODateTimeToFormattedDate(convertUTCtoLocalDate(worker?.birthDate));
  const workerPhoneNumber = getFormattedPhoneNumber(worker?.user?.identity?.phoneNumber);
  const workerEmail = worker?.user?.identity?.email;

  return (
    <div data-test="worker-personal-info" className={classes.workerPersonalInfo}>
      <div data-test="worker-personal-info-data" className={classes.workerPersonalInfoGrid}>
        <GridField label="Date of Birth" value={workerBirthDate} />
        <GridField
          label="Address"
          value={addressValue}
          labelClassName={classes.addressLabel}
          valueClassName={classes.addressValue}
        />
        <GridField
          label="Union affiliation"
          value={worker?.unionAffiliation}
          labelClassName={classes.unionAffiliationLabel}
          valueClassName={classes.unionAffiliationValue}
        />
        <GridField
          label="Gender"
          value={worker?.gender}
          labelClassName={classes.genderLabel}
          valueClassName={classes.genderValue}
        />
        <GridField
          label="Phone"
          value={workerPhoneNumber}
          labelClassName={classes.phoneLabel}
          valueClassName={classes.phoneValue}
        />
        <GridField
          label="Emergency Contact"
          value={emergencyContactValue}
          labelClassName={classes.emergencyContactLabel}
          valueClassName={classes.emergencyContactValue}
        />

        <GridField
          label="Primary Language"
          value={worker?.primaryLanguage}
          labelClassName={classes.primaryLanguageLabel}
          valueClassName={classes.primaryLanguageValue}
        />
        <GridField
          label="Email address"
          value={workerEmail}
          labelClassName={classes.emailLabel}
          valueClassName={classes.emailValue}
        />
      </div>
    </div>
  );
}
