import { Column } from '@odin-labs/components';

export type JobsiteFormSubmissionContractorWorkersCount = {
  contractorId: string;
  contractorName: string;
  workersCount: number;
};
export type JobsiteFormSubmissionContractorWorkersCountColumn = Column<JobsiteFormSubmissionContractorWorkersCount>;

export const getColumns = (): JobsiteFormSubmissionContractorWorkersCountColumn[] => [
  {
    id: 'contractor',
    Header: 'Contractor',
    accessor: (contractor: JobsiteFormSubmissionContractorWorkersCount): string => contractor.contractorName,
  },
  {
    id: 'contractorWorkersCount',
    Header: 'Number of Workers',
    accessor: (contractor: JobsiteFormSubmissionContractorWorkersCount): string => contractor.workersCount.toString(),
  },
];
