import React, { ReactElement } from 'react';
import { Avatar } from 'components/avatar';
import { LoadingError } from 'components/loadingError';
import { Link, matchPath, useHistory } from 'react-router-dom';
import { Col, Collapse, Container, Nav, Navbar, NavbarToggler, Row } from 'reactstrap';
import { getFullNameForUser, getInitialsForUser, useBoolean } from 'utils';

import { useGetJobsiteWorkerQuery } from 'apollo/generated/client-operations';
import { getVisibleSteps, getSidebarElements, isValidStep } from './helpers/utils';
import { JobsiteWorkerOnboardingNavbar } from './navbar/JobsiteWorkerOnboardingNavbar';
import { JobsiteWorkerOnboardingSidebarElement } from './navbar/JobsiteWorkerOnboardingSidebarElement';
import { WorkerOnboardingSidebarContainerProps, OnboardingStepKey, OnboardingUrlParams } from './types';
import { JobsiteWorkerOnboardingActions } from './navbar/JobsiteWorkerOnboardingActions';

export function WorkerOnboardingSidebarContainer({
  matchedPath,
  pathname,
}: WorkerOnboardingSidebarContainerProps): ReactElement {
  const history = useHistory();

  const { jobsiteWorkerId, step } = matchPath<OnboardingUrlParams>(pathname, { path: matchedPath })?.params ?? {};
  const { value: isNavbarOpen, setFalse: closeNavbar, toggle: toggleNavbar } = useBoolean(false);

  const {
    data: jobsiteWorkerData,
    error,
    loading,
    refetch: jobsiteWorkerRefetch,
  } = useGetJobsiteWorkerQuery({
    variables: {
      jobsiteWorkerId,
    },
    fetchPolicy: 'no-cache',
  });

  const jobsiteWorker = jobsiteWorkerData?.getJobsiteWorker;
  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};
  const { jobsiteId } = jobsite ?? {};

  const visibleSteps = React.useMemo(() => getVisibleSteps(jobsite), [jobsite]);
  const sidebarElements = React.useMemo(
    () => getSidebarElements(jobsiteWorker, visibleSteps),
    [jobsiteWorker, visibleSteps],
  );

  React.useEffect(() => {
    if (!step || (visibleSteps && !visibleSteps.includes(step)) || !isValidStep(step)) {
      history.replace(`/onboarding/${jobsiteWorkerId}/${OnboardingStepKey.PersonalInfo}`);
    }
  }, [step, visibleSteps]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const getOnClickHandler = (stepKey: OnboardingStepKey) => (): void => {
    history.replace(`/onboarding/${jobsiteWorkerId}/${stepKey}`);
    jobsiteWorkerRefetch({ jobsiteWorkerId });
    closeNavbar();
  };

  return (
    <>
      <JobsiteWorkerOnboardingNavbar jobsiteWorker={jobsiteWorker} jobsiteId={jobsiteId} step={step} />
      <Navbar
        className="navbar-vertical fixed-left navbar-expand-md jobsite-worker-orientation-sidebar !odin-items-start"
        light
      >
        {loading && (
          <div className="table-backdrop odin-hidden md:odin-block -odin-ml-6 -odin-mt-3">
            <LoadingError loading />
          </div>
        )}
        <Container fluid className="jobsite-worker-orientation-sidebar-container">
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />

          <div className="md:odin-hidden">
            <JobsiteWorkerOnboardingActions jobsiteWorker={jobsiteWorker} step={step} />
          </div>

          <Collapse isOpen={isNavbarOpen} navbar>
            <Nav navbar>
              <Row>
                <Col>
                  <h3 className="jobsite-worker-orientation-sidebar__header">Worker onboarding</h3>
                  <hr className="mb-0 odin-mt-6" />
                </Col>
              </Row>
              {sidebarElements?.map((sidebarElement) => (
                <JobsiteWorkerOnboardingSidebarElement
                  {...sidebarElement}
                  onClick={getOnClickHandler(sidebarElement.key)}
                  failed={!!jobsiteWorker?.bannedReason}
                  active={step === sidebarElement.key}
                />
              ))}
            </Nav>
            <div className="mt-auto" />
            <hr className="p-0" />
            <div>
              <Link to={`/worker/${jobsiteWorker?.contractorWorker?.worker?.workerId}`}>
                <Avatar
                  src={jobsiteWorker?.contractorWorker?.worker?.profilePictureCropped?.downloadUrl}
                  size="sm"
                  label={getInitialsForUser(jobsiteWorker?.contractorWorker?.worker?.user)}
                  className="d-inline-block"
                />
                <div className="d-inline-block ml-2">
                  {getFullNameForUser(jobsiteWorker?.contractorWorker?.worker?.user)}
                </div>
              </Link>
            </div>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}
