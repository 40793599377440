import React from 'react';
import cn from 'classnames';
import { NewButton, getFaIcon } from '@odin-labs/components';
import { faPencil } from '@fortawesome/pro-light-svg-icons';

import type { InfoRowProps } from './types';

const PencilIcon = getFaIcon({ icon: faPencil });

const classes = {
  container: (withPadding: boolean, withBottomBorder: boolean): string =>
    cn(
      'odin-mb-0 odin-grid odin-grid-cols-12 odin-gap-x-2',
      withPadding && 'odin-py-4.5',
      withBottomBorder && '!odin-border-b odin-border-b-gray-200',
    ),
  label: (withLgResponsiveness: boolean): string =>
    cn(
      'odin-my-auto odin-text-sm odin-font-normal odin-text-gray-600',
      'odin-col-span-4',
      withLgResponsiveness && 'lg:odin-col-span-2',
    ),
  value: (withLgResponsiveness: boolean, showEditIcon: boolean): string =>
    cn(
      'odin-my-auto odin-text-sm sm:odin-text-base odin-font-normal odin-text-gray-900',
      'odin-overflow-hidden odin-text-ellipsis',
      showEditIcon ? 'odin-col-span-7' : 'odin-col-span-8',
      withLgResponsiveness && (showEditIcon ? 'lg:odin-col-span-9' : 'lg:odin-col-span-10'),
    ),
  valueSkeleton: cn('odin-animate-pulse odin-bg-gray-200 odin-h-6 odin-rounded-md -odin-ml-1'),
  editButtonHolder: cn('odin-mb-0 odin-col-span-1 odin-mt-0 odin-text-right'),
};

export function InfoRow(props: InfoRowProps): React.ReactElement {
  const {
    label,
    value,
    onEditClick,
    loading,
    allowEdit,
    hideEditIcon,
    withBottomBorder,
    noPadding,
    noLgResponsiveness,
  } = props;

  const valueContent = loading ? <div className={classes.valueSkeleton} /> : value;
  const showEditIcon = !hideEditIcon;
  const withPadding = !noPadding;
  const withLgResponsiveness = !noLgResponsiveness;

  return (
    <dl className={classes.container(withPadding, withBottomBorder)}>
      <dt className={classes.label(withLgResponsiveness)}>{label}</dt>
      <dd className={classes.value(withLgResponsiveness, showEditIcon)}>{valueContent}</dd>
      {showEditIcon && (
        <dd className={classes.editButtonHolder}>
          {allowEdit && (
            <NewButton theme="naked" size="sm" onClick={onEditClick} disabled={loading}>
              <PencilIcon className="odin-h-4 odin-w-4" />
            </NewButton>
          )}
        </dd>
      )}
    </dl>
  );
}
