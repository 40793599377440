import React, { ReactElement } from 'react';
import { useFlags } from 'featureFlags';

import { WorkerContainer } from './WorkerContainer';
import { WorkerTailwindContainer } from './beta/WorkerTailwindContainer';

export function WorkerContainerResolver(): ReactElement {
  const { workerBetaVersionEnabled } = useFlags();
  const ResolvedWorkerContainer = workerBetaVersionEnabled ? WorkerTailwindContainer : WorkerContainer;
  return <ResolvedWorkerContainer />;
}
