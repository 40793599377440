import React from 'react';
import {
  Localize,
  SelfOnboardingBasicInfoFormData,
  SelfOnboardingEmergencyContactFormData,
  SelfOnboardingProfileFormData,
  SelfOnboardingStepConfig,
} from 'containers/selfOnboarding/types';
import { getFields } from 'types';
import { EditJobsiteConfigurationFormData } from 'containers/jobsiteConfiguration/types';
import { FormData, FormFieldConfig, FormProps } from 'components/form';
import { Copy } from './languages';

const jobsiteConfigWorkerInfo = getFields<EditJobsiteConfigurationFormData['workerInfo']>();
const onboardingBasicInfo = getFields<SelfOnboardingBasicInfoFormData>();
const onboardingProfile = getFields<SelfOnboardingProfileFormData>();
const onboardingEmergencyContact = getFields<SelfOnboardingEmergencyContactFormData>();

type JobsiteConfigWorkerInfoKey = keyof typeof jobsiteConfigWorkerInfo;

// this maps onboarding fields to jobsiteConfiguration field whenever they differ
export const configFieldMap: Record<string, JobsiteConfigWorkerInfoKey | FormFieldConfig<JobsiteConfigWorkerInfoKey>> =
  {
    [onboardingBasicInfo.phoneNumber]: jobsiteConfigWorkerInfo.phone,
    [onboardingProfile.addressLine1]: jobsiteConfigWorkerInfo.address,
    [onboardingProfile.addressLine2]: { key: jobsiteConfigWorkerInfo.address, isRequired: false },
    [onboardingProfile.addressCity]: jobsiteConfigWorkerInfo.address,
    [onboardingProfile.addressState]: jobsiteConfigWorkerInfo.address,
    [onboardingProfile.isVeteran]: jobsiteConfigWorkerInfo.veteranStatus,
    [onboardingProfile.jobTitle]: jobsiteConfigWorkerInfo.title,
    [onboardingEmergencyContact.emergencyContactName]: jobsiteConfigWorkerInfo.emergencyContact,
    [onboardingEmergencyContact.emergencyContactRelationship]: jobsiteConfigWorkerInfo.emergencyContact,
    [onboardingEmergencyContact.emergencyContactPhone]: jobsiteConfigWorkerInfo.emergencyContact,
  };

export function useSelfOnboardingFormCommonProps(
  stepConfig: SelfOnboardingStepConfig,
  localize: Localize,
): Pick<FormProps<FormData>, 'fieldsConfig' | 'localization'> {
  const { fields } = stepConfig;
  const fieldsConfig = React.useMemo(() => ({ fields, inputToFieldMap: configFieldMap }), [fields]);
  const localization = React.useMemo(() => ({ localize, copy: Copy }), [localize]);
  return { fieldsConfig, localization };
}
