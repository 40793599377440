import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { Input } from '@odin-labs/components';
import type { CodeInputProps } from './types';

const classes = {
  codeDigitsContainer: 'odin-flex odin-justify-evenly',
  codeDigitText: cn(
    '!odin-p-0 !odin-w-12 !odin-h-18 odin-text-center',
    'odin-text-gray-900 odin-text-2.33xl selection:odin-bg-transparent odin-caret-transparent',
  ),
};

const CODE_LENGTH = 6;
const EMPTY_INPUT_CHAR = '';
const codeDigitsArray = Array<string>(CODE_LENGTH).fill(null);

export function CodeInput(props: CodeInputProps): React.ReactElement {
  const { onChange, onCodeFilled, onInvalidCodeFormat, resetKey } = props;
  const [code, setCode] = useState(codeDigitsArray);
  const codeInputRefs = useRef<HTMLInputElement[]>([]);

  const codeText = code.join('');
  const isValidCodeFormat = (text: string): boolean => /[0-9]{1,6}/.test(text);

  useEffect(() => {
    codeInputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    onChange?.(codeText);
    if (codeText.length === CODE_LENGTH && isValidCodeFormat(codeText)) {
      onCodeFilled(codeText);
    }
  }, [codeText]);

  useEffect(() => {
    if (resetKey > 0) {
      setCode(codeDigitsArray);
      codeInputRefs.current[0]?.focus();
    }
  }, [resetKey]);

  const toDigitInput = (_value: string, idx: number): React.ReactElement => {
    const digit = code[idx] ?? EMPTY_INPUT_CHAR;

    return (
      <Input
        key={idx}
        ref={(ref): void => {
          codeInputRefs.current[idx] = ref;
        }}
        className={classes.codeDigitText}
        value={digit}
        maxLength={1}
        type="number"
        inputMode="numeric"
        autoComplete="one-time-code"
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => {
          const input = e.target as HTMLInputElement;
          if (!input.value && idx > 0 && e.key === 'Backspace') {
            const newCode = [...code];
            newCode[idx - 1] = null;
            setCode(newCode);
            codeInputRefs.current[idx - 1]?.focus();
          }
          if (input.selectionStart !== 0 || input.selectionEnd !== 1) {
            input.setSelectionRange(0, 1);
          }
        }}
        onChange={(value): void => {
          const newCode = [...code];
          newCode[idx] = value;
          setCode(newCode);
          if (value) {
            codeInputRefs.current[idx + 1]?.focus();
          }
        }}
        onPaste={(e: React.ClipboardEvent<HTMLInputElement>): void => {
          e.preventDefault();
          const text = e.clipboardData?.getData('text');
          if (isValidCodeFormat(text)) {
            setCode(text.split(''));
            codeInputRefs.current[CODE_LENGTH - 1]?.focus();
          } else {
            onInvalidCodeFormat?.(text);
          }
        }}
      />
    );
  };

  return (
    <div>
      <div className={classes.codeDigitsContainer}>{codeDigitsArray.map(toDigitInput)}</div>
    </div>
  );
}
