import React from 'react';
import moment from 'moment-timezone';
import { Filter, SortingRule, Table, TableContainer } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import {
  Maybe,
  useGetSiteSafetyOrientationJobsiteWorkersQuery,
  GetJobsiteWorkersOrderByField,
  JobsiteWorkerOrientationStatusFilter,
  OrderByDirection,
  SiteSpecificOrientationStatus,
} from 'apollo/generated/client-operations';
import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { useHistory } from 'react-router';
import { getCurrentISOFormattedDate, isQueryLoading, useQueryOrderBy } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { getJobsiteIdsForDevelopers } from 'utils/filters';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { SortOrder } from 'utils/useQueryOrderBy';
import { getColumns, getFilterItems, orderByFields, SiteSafetyOrientationJobsiteWorkerColumn } from './helpers/tables';
import { SiteSafetyOrientationStatusFilterValue, siteSafetyOrientationStatusMapping } from './helpers/utils';
import { SiteSafetyOrientationFilters, SiteSafetyOrientationJobsiteWorkerAccess } from './types';

const initialOrderBy = [
  {
    field: GetJobsiteWorkersOrderByField.CompletedBadgingAt,
    direction: OrderByDirection.Descending,
  },
];

export function WorkerSafetyOrientationsContainer(): React.ReactElement {
  const history = useHistory();

  const defaultStartDate = moment().subtract({ weeks: 1 });
  const defaultEndDate = moment(getCurrentISOFormattedDate());

  const {
    page,
    orderBy: defaultSortField,
    orderByDesc: isDescending,
    startDate,
    endDate,
    developerIds,
    jobsiteIds,
    contractorIds,
    siteSafetyOrientationStatus,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({
    updateJobsiteSelection: true,
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  });

  const offset = page * limit;

  const filterSiteSafetyOrientationStatus = siteSafetyOrientationStatus as SiteSafetyOrientationStatusFilterValue;
  const siteSpecificOrientationStatus: Maybe<SiteSpecificOrientationStatus> =
    siteSafetyOrientationStatusMapping[filterSiteSafetyOrientationStatus] ?? null;

  // this check will be used in various places. No date picker needed if checking for expired
  const isExpiredStatus: boolean = siteSpecificOrientationStatus === SiteSpecificOrientationStatus.Expired;

  // get all sorting info necessary for the query and the table.
  // `setNewOrderBy` is called whenever the table sorting properties change
  // and the hook will return an updated `orderBy` which will be passed to the query
  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetJobsiteWorkersOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetJobsiteWorkersOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const getOrientationDate = (date: moment.Moment, defaultDate: moment.Moment): Date =>
    isExpiredStatus ? null : (date ?? defaultDate).toDate();

  const {
    data: jobsiteWorkerData,
    error: jobsiteWorkerError,
    networkStatus: jobsiteWorkerNetworkStatus,
    refetch,
  } = useGetSiteSafetyOrientationJobsiteWorkersQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
    skip: isUrlLoading,
    variables: {
      jobsiteIds,
      contractorIds,
      orientationStartDate: getOrientationDate(startDate, defaultStartDate),
      orientationEndDate: getOrientationDate(endDate, defaultEndDate),
      limit,
      offset,
      activeOnly: true,
      // timezoneName: moment.tz.guess(),
      orientationStatus: JobsiteWorkerOrientationStatusFilter.Complete,
      siteSpecificOrientationStatus,
      hideBanned: true,
      withContractorOnly: true,
      orderBy: orderBy ?? initialOrderBy,
    },
  });

  const { user: userData } = jobsiteWorkerData?.getCurrentSession ?? {};
  const contractors = userData?.contractors.edges.map(({ node }) => node);

  const jobsiteWorkerAccesses = jobsiteWorkerData?.getJobsiteWorkers?.edges;
  const jobsiteWorkerAccessesCount = jobsiteWorkerData?.getJobsiteWorkers?.totalCount;
  const pageCount = Math.ceil(jobsiteWorkerAccessesCount / limit);

  const canSortByStatus = !siteSpecificOrientationStatus;
  const columns = React.useMemo<SiteSafetyOrientationJobsiteWorkerColumn[]>(
    () => getColumns({ canSortByStatus }),
    [canSortByStatus],
  );
  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        jobsites,
        contractors,
        developerIds,
        jobsiteIds,
        contractorIds,
        startDate,
        endDate,
        siteOrientationStatus: siteSafetyOrientationStatus,
      }),
    [jobsites, contractors, developerIds, jobsiteIds, contractorIds, startDate, endDate, siteSafetyOrientationStatus],
  );

  const onSortByChangeHandler = (sortBy: Array<SortingRule<SiteSafetyOrientationJobsiteWorkerAccess>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  const onFilterChangeHandler = (changedFilters: Partial<SiteSafetyOrientationFilters>): void => {
    const { dateRange: dateRangeFilter, ...restChangedFilters } = changedFilters;
    const dateRange = dateRangeFilter === null ? { startDate: null, endDate: null } : dateRangeFilter;
    updateUrl({
      page: null,
      ...dateRange,
      ...restChangedFilters,
      ...getJobsiteIdsForDevelopers(jobsites, changedFilters.developerIds),
    });
  };

  const onRowClickHandler = ({
    data: jobsiteWorkerAccess,
  }: {
    data: SiteSafetyOrientationJobsiteWorkerAccess;
  }): void => {
    const { jobsiteWorker } = jobsiteWorkerAccess ?? {};
    const { jobsiteWorkerId } = jobsiteWorker ?? {};
    const { workerId } = jobsiteWorker.contractorWorker.worker ?? {};

    // if the toggle is for expired go to the worker's documents
    // otherwise go to site specific orientation step of orientation
    if (isExpiredStatus) {
      if (workerId) {
        history.push(`/worker/${workerId}/documents`);
      }
    } else if (jobsiteWorkerId) {
      history.push(`/onboarding/${jobsiteWorkerId}/site-specific-orientation`);
    }
  };

  const loading: boolean = isQueryLoading(jobsiteWorkerNetworkStatus) || userJobsitesLoading;
  const error: ApolloError = jobsiteWorkerError || userJobsitesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Container className="worker-site-safety-orientations-container">
      <NewHeader title="Site Orientations" actionsProps={{ onReloadPressed: refetch }} />
      <TableContainer>
        <Filter items={filterItems} loading={loading} onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={jobsiteWorkerAccesses}
          initialState={{ sortBy: tableSortBy, pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          onSortByChange={onSortByChangeHandler}
          disableGlobalFilter
        />
      </TableContainer>
    </Container>
  );
}
