import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import {
  GetJobsiteFormSubmissionsDocument,
  useJobsiteArchiveFormSubmissionMutation,
} from 'apollo/generated/client-operations';
import { getIcon } from 'utils/ui';
import { getPrettyFormattedDate } from 'utils/dates';
import { RemoveFormSubmissionModalProps } from './types';

const TrashIcon = getIcon('fal fa-trash');

export function RemoveFormSubmissionModal(props: RemoveFormSubmissionModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, form, jobsiteFormSubmission } = props;
  const { id, startAt, jobsiteForm } = jobsiteFormSubmission ?? {};

  const [archiveFormSubmission, { loading }] = useJobsiteArchiveFormSubmissionMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      onConfirm();
      AlertInstance.alert('tc', 'success', 'Success', `${form?.name} entry successfully removed`);
    },
    onError: (responseError: ApolloError) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    variables: { input: { id } },
    refetchQueries: [GetJobsiteFormSubmissionsDocument],
  });

  const submissionDate = getPrettyFormattedDate(startAt);

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title={`Are you sure you want to delete this ${form?.name} entry?`}
      titleAlignment="center"
      textAlignment="center"
      onAction={archiveFormSubmission}
      actionText="Delete Entry"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={loading}
    >
      <p className="odin-text-sm odin-font-normal odin-text-black odin-text-center odin-whitespace-pre-wrap">
        You are about to delete a <b>{form?.name}</b> entry on <b>{jobsiteForm?.jobsite.name}</b>
        {' from '}
        <b>{submissionDate}</b>. This will remove the entry permanently: are you sure you want to proceed?
      </p>
    </Modal>
  );
}
