import { ContractorWithSubcontractors, Subcontractor } from 'containers/contractor/types';
import React from 'react';

const getSubcontractors = (contractor: ContractorWithSubcontractors): Subcontractor[] => {
  const subcontractorsById: Record<string, Subcontractor> = {};

  contractor?.jobsiteContractors.edges.forEach(({ node: jobsiteContractor }) => {
    jobsiteContractor?.subcontractedJobsiteContractors?.edges.forEach(
      ({
        node: subcontractedJobsiteContractor,
        node: {
          contractor: {
            contractorId,
            organization: { name },
          },
        },
      }) => {
        if (!subcontractorsById[contractorId]) {
          subcontractorsById[contractorId] = { contractorId, name, jobsiteContractors: [] };
        }
        subcontractorsById[contractorId].jobsiteContractors.push(subcontractedJobsiteContractor);
      },
    );
  });

  return Object.values(subcontractorsById).sort((a, b) => a.name.localeCompare(b.name));
};

export function useSubcontractors(contractor: ContractorWithSubcontractors): Subcontractor[] {
  return React.useMemo(() => getSubcontractors(contractor), [contractor]);
}
