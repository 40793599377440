import React, { ReactElement, useState } from 'react';
import { Navbar } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { BaseModal } from 'components/modals';
import { JobsiteWorkerOnboardingNavbarProps } from 'containers/workerOnboarding/types';

import 'containers/workerOnboarding/scss/worker-onboarding.scss';
import { JobsiteWorkerOnboardingActions } from './JobsiteWorkerOnboardingActions';

export function JobsiteWorkerOnboardingNavbar(props: JobsiteWorkerOnboardingNavbarProps): ReactElement {
  const { step } = props;
  const history = useHistory();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  return (
    <Navbar light className="odin-hidden md:odin-flex" fixed="top">
      <div className="odin-hidden md:odin-flex odin-flex-grow odin-justify-end odin-pr-4">
        <JobsiteWorkerOnboardingActions {...props} />
      </div>
      <BaseModal
        title="Are you sure you want to exit?"
        subtitle="Any unsaved information will be lost."
        actionText="Leave orientation"
        actionColor="danger"
        onAction={(): void => {
          if (step === 'site-specific-training') {
            history.push(`/site-orientations`);
            return;
          }
          history.push(`/onboardings`);
        }}
        onCancel={(): void => setShowConfirmModal(false)}
        isOpen={showConfirmModal}
        toggle={(): void => setShowConfirmModal(false)}
      />
    </Navbar>
  );
}
