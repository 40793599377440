import { Moment } from 'moment';
import { RadioGroupOption, SelectOptionElement } from '@odin-labs/components';
import { JobsiteWorker } from 'containers/worker/types';
import { WorkerAccessRevokingCategory } from 'containers/worker/utils';

export enum ChangeWorkerAccessStep {
  ChooseAccessRevokingType = 'chooseAccessRevokingType',
  RevokeAccess = 'revokeAccess',
  RestoreAccess = 'restoreAccess',
  OnboardWorker = 'onboardWorker',
}

export type ContractorOptionElement = SelectOptionElement & { startDate: Date };
export type JobsiteOptionElement = SelectOptionElement & { parentId: string };

export type RestoreWorkerAccessFormData = {
  approvedBy: string;
  category: SelectOptionElement<WorkerAccessRevokingCategory>;
  reason: string;
};

export type RevokeWorkerAccessFormData = {
  approvedBy: string;
  category: SelectOptionElement<WorkerAccessRevokingCategory>;
  reason: string;
  banExpiresAt: Moment;
  endDate: Moment;
};

export enum WorkerAccessChangeAction {
  Revoke,
  Restore,
}

export enum AccessRevokingType {
  Suspend = 'suspend',
  Ban = 'ban',
  Offboard = 'offboard',
}

export enum AccessRestoringType {
  Unsuspend = 'unsuspend',
  Unban = 'unban',
  Onboard = 'onboard',
}

export type ChangeWorkerAccessState =
  | {
      step: ChangeWorkerAccessStep.ChooseAccessRevokingType;
    }
  | {
      step: ChangeWorkerAccessStep.RevokeAccess;
      accessRevokingType: AccessRevokingType;
    }
  | {
      step: ChangeWorkerAccessStep.RestoreAccess;
      accessRestoringType: AccessRestoringType;
    };

export type AccessRevokingTypeFormData = {
  accessRevokingType: RadioGroupOption<AccessRevokingType>;
  alert: unknown;
};

export interface ChooseAccessRevokingTypeModalContentProps {
  closeModal: () => void;
  onConfirm: (accessRevokingType: AccessRevokingType) => void;
  jobsiteWorker: JobsiteWorker;
}

export type RevokeWorkerAccessModalContentProps = {
  closeModal: () => void;
  onConfirm: () => void;
  jobsiteWorker: JobsiteWorker;
  accessRevokingType: AccessRevokingType;
};

export type RestoreWorkerAccessModalContentProps = {
  closeModal: () => void;
  onConfirm: () => void;
  jobsiteWorker: JobsiteWorker;
  accessRestoringType: AccessRestoringType;
};

export type OnboardWorkerModalContentProps = {
  closeModal: () => void;
  onConfirm: () => void;
  jobsiteWorker: JobsiteWorker;
};
