import React, { ReactElement } from 'react';
import { GetWorkerDataDocument, useUpdateJobsiteWorkerMutation } from 'apollo/generated/client-operations';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { getGraphQLError } from 'utils/error';
import { AlertInstance } from 'components/alertNotification';
import { useIsMounted } from 'utils';
import { getDefaultValues, getFormInputs } from './RestoreWorkerAccessModalContent.forms';
import { AccessRestoringType, RestoreWorkerAccessFormData, RestoreWorkerAccessModalContentProps } from './types';

export function RestoreWorkerAccessModalContent(props: RestoreWorkerAccessModalContentProps): ReactElement {
  const { closeModal, onConfirm, jobsiteWorker, accessRestoringType } = props;
  const { jobsiteWorkerId } = jobsiteWorker ?? {};

  const isOnboarding = accessRestoringType === AccessRestoringType.Onboard;
  const isNotOnboarding = !isOnboarding;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    refetchQueries: [GetWorkerDataDocument],
  });

  const onSubmit: FormOnSubmit<RestoreWorkerAccessFormData> = async (data, event): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    const { category, approvedBy, reason } = data;

    try {
      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            ...(isOnboarding
              ? { endDate: null }
              : {
                  siteAccessChangeApprovedBy: approvedBy,
                  isBanned: false,
                  banCategory: category.value,
                  banRemovedReason: reason,
                  bannedAt: null,
                  bannedReason: null,
                  banExpiresAt: null,
                }),
          },
        },
      });
      onConfirm();
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  const formInputs = React.useMemo(() => (isNotOnboarding ? getFormInputs() : null), [isNotOnboarding]);
  const defaultValues = React.useMemo(
    () => (isNotOnboarding ? getDefaultValues(jobsiteWorker) : null),
    [isNotOnboarding, jobsiteWorker],
  );

  return (
    <ModalFormContent
      setOpen={closeModal}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Restore Access"
      actionButtonWithSpinner={isSaving}
    />
  );
}
