import {
  EditFormDefaultDataType,
  OnboardingStepKey,
  OnboardingStepName,
  SidebarElement,
  JobsiteOnboardingDocument,
  JobsiteOnboardingField,
  JobsiteModule,
  JobsiteOnboardingModule,
  JobsiteOnboardingStep,
  JobsiteWorker,
  Jobsite,
} from 'containers/workerOnboarding/types';
import { DocumentKey } from 'containers/worker/utils';

export const sidebarIsInProgress = (defaultData: EditFormDefaultDataType): boolean => {
  return Object.values(defaultData).some((data) => data);
};

export const getOnboardingModule = (modules: JobsiteModule[]): JobsiteOnboardingModule =>
  modules?.find((m): m is JobsiteOnboardingModule => {
    return m.__typename === 'JobsiteOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getStepsConfig = (modules: JobsiteModule[]): Partial<Record<string, JobsiteOnboardingStep>> => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return jobsiteOnboardingModule?.steps?.reduce((result, step) => ({ ...result, [step.key]: step }), {}) ?? {};
};

export const getStepConfig = (modules: JobsiteModule[], stepKey: OnboardingStepKey): JobsiteOnboardingStep => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return jobsiteOnboardingModule?.steps?.find((step) => step.key === stepKey);
};

export const getVisibleSteps = (jobsite: Jobsite): string[] => {
  if (!jobsite) return null;
  const jobsiteOnboardingModule = getOnboardingModule(jobsite.modules);
  return jobsiteOnboardingModule?.steps?.filter((step) => step.isVisible).map(({ key }) => key);
};

export const getCompletedSteps = (jobsiteWorker: JobsiteWorker): Record<OnboardingStepKey, boolean> => {
  return {
    [OnboardingStepKey.PersonalInfo]: !!jobsiteWorker.profileCompletedAt,
    [OnboardingStepKey.WorkDocuments]: !!jobsiteWorker.documentsCompletedAt,
    [OnboardingStepKey.MedicalInfo]: !!jobsiteWorker.passedMedicalExamAt,
    [OnboardingStepKey.OnboardingTraining]: !!jobsiteWorker.onboardingSignedAt,
    [OnboardingStepKey.Signature]: !!jobsiteWorker.signatureCompletedAt,
    [OnboardingStepKey.Badging]: !!jobsiteWorker.badgingCompletedAt,
    [OnboardingStepKey.SiteSpecificOrientation]: !!jobsiteWorker.siteSpecificTrainingCompletedAt,
  };
};

export const getSidebarElements = (jobsiteWorker: JobsiteWorker, visibleSteps: string[] | null): SidebarElement[] => {
  if (!jobsiteWorker) return null;

  const completedSteps = getCompletedSteps(jobsiteWorker);

  const sidebarElements: SidebarElement[] = [
    {
      key: OnboardingStepKey.PersonalInfo,
      title: OnboardingStepName.PersonalInfo,
      icon: 'fa-user',
      skipped: jobsiteWorker.profileCompleteSkipReason,
      completed: completedSteps[OnboardingStepKey.PersonalInfo],
    },
    {
      key: OnboardingStepKey.WorkDocuments,
      title: OnboardingStepName.WorkDocuments,
      icon: 'fa-file-alt',
      skipped: jobsiteWorker.documentsCompletedSkipReason,
      completed: completedSteps[OnboardingStepKey.WorkDocuments],
    },
    {
      key: OnboardingStepKey.MedicalInfo,
      title: OnboardingStepName.MedicalInfo,
      icon: 'fa-comment-medical',
      skipped: jobsiteWorker.medicalExamSkipReason,
      completed: completedSteps[OnboardingStepKey.MedicalInfo],
    },
    {
      key: OnboardingStepKey.OnboardingTraining,
      title: OnboardingStepName.OnboardingTraining,
      icon: 'fa-newspaper',
      skipped: jobsiteWorker.onboardingSkipReason,
      completed: completedSteps[OnboardingStepKey.OnboardingTraining],
    },
    {
      key: OnboardingStepKey.Signature,
      title: OnboardingStepName.Signature,
      icon: 'fa-file-signature',
      skipped: jobsiteWorker.signatureSkipReason,
      completed: completedSteps[OnboardingStepKey.Signature],
    },
    {
      key: OnboardingStepKey.Badging,
      title: OnboardingStepName.Badging,
      icon: 'fa-id-badge',
      skipped: jobsiteWorker.badgingSkipReason,
      completed: completedSteps[OnboardingStepKey.Badging],
    },
    {
      key: OnboardingStepKey.SiteSpecificOrientation,
      title: OnboardingStepName.SiteSpecificOrientation,
      icon: 'fa-hard-hat',
      skipped: jobsiteWorker.siteSpecificTrainingSkipReason,
      completed: completedSteps[OnboardingStepKey.SiteSpecificOrientation],
    },
  ];

  const stepsConfig = getStepsConfig(jobsiteWorker.jobsiteContractor?.jobsite?.modules);

  return sidebarElements
    .filter(({ key }) => visibleSteps?.includes(key))
    .sort((a, b) => (stepsConfig[a.key]?.index ?? 999) - (stepsConfig[b.key]?.index ?? 999));
};

export const isValidStep = (step: string): boolean => Object.values<string>(OnboardingStepKey).includes(step);

export const getDocumentsConfig = (
  modules: JobsiteModule[],
): Partial<Record<DocumentKey, JobsiteOnboardingDocument>> => {
  const jobsiteOnboardingModule = getOnboardingModule(modules);
  return (
    jobsiteOnboardingModule?.documents?.reduce((result, document) => ({ ...result, [document.key]: document }), {}) ??
    {}
  );
};

export const getVisibleDocuments = (jobsiteWorker: JobsiteWorker): string[] => {
  if (!jobsiteWorker) return null;

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};
  const jobsiteOnboardingModule = getOnboardingModule(jobsite.modules);
  return jobsiteOnboardingModule?.documents?.filter((doc) => doc.isVisible).map(({ key }) => key);
};

export const getDocumentFieldsConfig = (
  documentKey: DocumentKey,
  jobsiteWorker: JobsiteWorker,
): Record<string, JobsiteOnboardingField> => {
  if (!jobsiteWorker) return {};

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const documentsConfig = getDocumentsConfig(jobsite.modules)[documentKey];
  return documentsConfig?.fields?.reduce((result, field) => ({ ...result, [field.key]: field }), {}) ?? {};
};

export const getStepFieldsConfig = (
  stepKey: OnboardingStepKey,
  jobsiteWorker: JobsiteWorker,
): Record<string, JobsiteOnboardingField> => {
  if (!jobsiteWorker) return {};

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const stepConfig = getStepConfig(jobsite.modules, stepKey);
  return stepConfig?.fields?.reduce((result, field) => ({ ...result, [field.key]: field }), {}) ?? {};
};
