import React from 'react';
import { faUserTimes } from '@fortawesome/pro-light-svg-icons';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  NewMenuItemProps,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { ensureNonEmptyItems, getFormattedPhoneNumber, getInitialsForUser } from 'utils';
import { ContractorMember, ContractorUserColumn, ContractorUsersFilters, Jobsite } from 'containers/contractor/types';
import { AuthUser } from 'auth/types';
import { to } from 'acl';
import { DateTimeCell, DateTimeCellData } from 'containers/jobsiteAccessActivity/helpers/cells';
import { DeveloperWorker, DeveloperWorkerColumn, DeveloperWorkersFilters } from 'containers/developer/types';

// DeveloperWorker
export const getWorkerColumns = (): DeveloperWorkerColumn[] => {
  return ensureNonEmptyItems<DeveloperWorkerColumn>([
    {
      id: 'worker',
      Header: 'Worker',
      accessor: (developerWorker: DeveloperWorker): string => {
        const { firstName, lastName } = developerWorker?.user?.identity ?? {};
        return [firstName, lastName].join(' ');
      },
      Cell: AvatarCell,
      CellLoading: <AvatarSkeleton size="xs" hideDetails />,
      componentProps: (developerWorker: DeveloperWorker): AvatarCellComponentProps => ({
        src: developerWorker.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(developerWorker.user),
        objectFit: 'cover',
        size: 'xs',
      }),
      disableSortBy: true,
    },
    {
      id: 'quickCode',
      Header: <>Quick&nbsp;Code</>,
      accessor: (developerWorker: DeveloperWorker): string => developerWorker.quickCode,
      disableSortBy: true,
    },
    {
      id: 'badgeNumber',
      Header: <>Badge&nbsp;Numbers</>,
      accessor: (developerWorker: DeveloperWorker): string =>
        developerWorker?.workerCards?.edges?.map(({ node }) => node.cardNumber).join(', '),
      disableSortBy: true,
    },
    {
      id: 'jobsiteAssignments',
      Header: 'Jobsite Assignments',
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (developerWorker: DeveloperWorker): ChipsCellComponentProps => ({
        chips: developerWorker?.jobsiteWorkers?.edges?.map(({ node }) => ({
          text: node.jobsiteContractor.jobsite.name,
          secondaryText: node.jobsiteContractor.contractor.organization.name,
          isActive: node.currentAccess.isAllowed,
        })),
      }),
      disableSortBy: true,
    },
  ]);
};

export const getWorkerFilterItems = ({
  jobsites,
  jobsiteIds,
  search,
}: {
  jobsites: Jobsite[];
  jobsiteIds: string[];
  search: string;
}): FilterItems<DeveloperWorkersFilters> => ({
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
      isMulti: true,
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});

const UserTimesIcon = getFixedWidthFaIcon({ icon: faUserTimes });

// ContractorUser
export const getUserColumns = ({
  user,
  openRemoveContractorUserModal,
}: {
  user: AuthUser;
  openRemoveContractorUserModal: (contractorMemberId: string) => void;
}): ContractorUserColumn[] => {
  const canRemoveContractorUsers = user.isAllowed(to.removeContractorUsers, null);
  const isActionsButtonVisible = canRemoveContractorUsers;

  return ensureNonEmptyItems<ContractorUserColumn>([
    {
      id: 'user',
      Header: 'User',
      accessor: (contractorMember: ContractorMember): string => {
        const { firstName, lastName } = contractorMember.user.identity ?? {};
        return [firstName, lastName].join(' ');
      },
      Cell: AvatarCell,
      CellLoading: <AvatarSkeleton size="xs" hideDetails />,
      componentProps: (contractorMember: ContractorMember): AvatarCellComponentProps => ({
        src: contractorMember.user?.worker?.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(contractorMember.user),
        objectFit: 'cover',
        size: 'xs',
      }),
      disableSortBy: true,
    },
    {
      id: 'email',
      Header: <>Email</>,
      accessor: (contractorMember: ContractorMember): string => contractorMember.user.identity.email,
      disableSortBy: true,
    },
    {
      id: 'phoneNumber',
      Header: 'Phone Number',
      accessor: (contractorMember: ContractorMember): string =>
        getFormattedPhoneNumber(contractorMember.user.identity.phoneNumber),
      disableSortBy: true,
    },
    {
      id: 'role',
      Header: 'Role',
      // cellClassName: cn('odin-whitespace-pre-wrap'),
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (contractorMember: ContractorMember): ChipsCellComponentProps => ({
        chips: contractorMember.user.roles.map((role) => ({
          text: role.object.objectDetails.name,
          secondaryText: role.displayName,
          withDot: false,
        })),
      }),
      disableSortBy: true,
    },
    {
      id: 'lastSessionDate',
      Header: 'Last Session',
      accessor: (contractorMember: ContractorMember): DateTimeCellData => {
        const { createdAt } = contractorMember.user.lastSession ?? {};
        const timeZoneName = ''; // moment.tz(createdAt, timeZone).zoneAbbr();
        return createdAt && { date: new Date(createdAt), timeZoneName };
      },
      Cell: DateTimeCell,
      disableSortBy: true,
    },
    {
      id: 'lastSessionVersion',
      Header: 'Odin version',
      accessor: (contractorMember: ContractorMember): string => contractorMember.user.lastSession?.appVersion,
      disableSortBy: true,
    },
    isActionsButtonVisible && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (contractorMember: ContractorMember): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<NewMenuItemProps>([
          canRemoveContractorUsers && {
            icon: UserTimesIcon,
            text: 'Remove User Role',
            theme: 'danger',
            onClick: (): void => openRemoveContractorUserModal(contractorMember.id),
          },
        ]),
      }),
    },
  ]);
};

export const getUserFilterItems = (): FilterItems<ContractorUsersFilters> => ({});
