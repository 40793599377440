import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { JobsiteWorker } from 'containers/worker/types';
import { ClearanceChecklist } from 'components/clearanceChecklist/ClearanceChecklist';
import { ClearanceSelection } from 'components/clearanceChecklist/types';
import { EditJobsiteClearanceAssignmentsFormData, Jobsite } from './types';

export const getFormInputs = (): TypedFormInputs<EditJobsiteClearanceAssignmentsFormData> => ({
  assignedClearances: {
    element: FormInputTypes.CustomInput,
    elementProps: {
      customInput: ClearanceChecklist,
    },
    layout: GridColSpan.SpanFull,
  },
});

export const getDefaultValues = (
  jobsiteWorker: JobsiteWorker,
  jobsite: Jobsite,
): EditJobsiteClearanceAssignmentsFormData => {
  if (!jobsiteWorker || !jobsite) return { assignedClearances: [] };
  const { cCureClearances } = jobsite ?? {};
  const { clearanceAssignments: assignmentsConnection } = jobsiteWorker ?? {};
  const clearanceAssignments = assignmentsConnection?.edges.map(({ node }) => node) ?? [];
  const jobsiteClearances = cCureClearances?.edges.map(({ node }) => node) ?? [];
  const useJobsiteDefaults = clearanceAssignments?.length < 1;
  const clearanceSelections = jobsiteClearances.map((clearance) => {
    const clearanceAssignment = clearanceAssignments.find(
      (ca) => ca.clearance?.cCureClearanceId === clearance.cCureClearanceId,
    );
    return {
      clearance,
      clearanceAssignment,
      ...(clearanceAssignment ? { selected: true } : { selected: useJobsiteDefaults ? clearance.isDefault : false }),
    } as ClearanceSelection;
  });
  return {
    assignedClearances: clearanceSelections,
  };
};
