import { RadioGroupOption, SelectOptionElement } from '@odin-labs/components';
import { JobsiteWorker } from 'apollo/generated/client-operations';
import { RefetchQueries } from 'apollo/types';

export enum ChangeType {
  FromBeginning,
  Today,
}

export type ChangeContractorFormData = {
  newContractorId?: SelectOptionElement;
  changeType: RadioGroupOption<ChangeType>;
  alert: unknown;
};

export interface ChangeContractorModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onClosed?: () => void;
  refetchQueries?: RefetchQueries;
  jobsiteId: string;
  contractorId: string;
  jobsiteWorkers?: Pick<JobsiteWorker, 'jobsiteWorkerId'>[];
}
