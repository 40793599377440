export const OBJECT_HISTORY_GRAPHQL_PROPS = `
objectHistory {
  createdAt
  createdBy {
    userAccountId
    identity {
      firstName
      lastName
      email
    }
  }
  updatedAt  
  updatedBy {
    userAccountId
    identity {
      firstName
      lastName
      email
    }
  }      
}
`;
