import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { JobsiteWorkerOnboardingActionsProps, OnboardingStepKey } from 'containers/workerOnboarding/types';
import { NewButton } from '@odin-labs/components';

export function JobsiteWorkerOnboardingActions(props: JobsiteWorkerOnboardingActionsProps): ReactElement {
  const { step, jobsiteWorker } = props;

  return (
    <>
      <NewButton
        as={Link}
        to={`/worker/${jobsiteWorker?.contractorWorker?.worker?.workerId}`}
        text="Exit to profile"
        className="odin-mr-1.5 hover:odin-text-white"
      />
      <NewButton
        as={Link}
        to={step === OnboardingStepKey.SiteSpecificOrientation ? '/site-orientations' : '/onboardings'}
        text={step === OnboardingStepKey.SiteSpecificOrientation ? 'Exit to orientations' : 'Exit to onboardings'}
        className="hover:odin-text-white"
      />
    </>
  );
}
