import React from 'react';
import { Avatar } from '@odin-labs/components';
import { getInitialsForUser } from 'utils';
import { SearchWorkerInfoProps } from './types';
import { classes } from './SearchWorkerInfo.style';
import { SearchWorkerInfoItem } from './SearchWorkerInfoItem';
import { SearchWorkerInfoJobsiteLabel } from './SearchWorkerInfoJobsiteLabel';

export function SearchWorkerInfo(props: SearchWorkerInfoProps): React.ReactElement {
  const { searchWorkerResult, className, isClickable = true, infoType = 'basic' } = props;
  const { firstName, lastName, imageUrl, quickCode, badgeId, phone, email, trade, tradeClass, unionAffiliation } =
    searchWorkerResult;
  const user = { identity: { firstName, lastName } };
  const showInfoOnNewRow = infoType === 'full';

  return (
    <div className={classes.itemContainer(isClickable, className)}>
      <div className={classes.workerInfoRow(showInfoOnNewRow)}>
        <div className={classes.workerNameContainer}>
          <Avatar size="sm" src={imageUrl} placeholder={getInitialsForUser(user)} objectFit="cover" />
          <div className={classes.workerName}>
            {firstName} {lastName}
          </div>
        </div>
        <div className={classes.workerInfoContainer}>
          <SearchWorkerInfoItem type="quickCode" text={quickCode} />
          <SearchWorkerInfoItem type="trade" text={[trade, tradeClass].filter(Boolean).join(', ')} />
          <SearchWorkerInfoItem type="unionAffiliation" text={unionAffiliation} />
          {infoType === 'full' && (
            <>
              <SearchWorkerInfoItem type="badgeId" text={badgeId} />
              <SearchWorkerInfoItem type="phone" text={phone} />
              <SearchWorkerInfoItem type="email" text={email} />
            </>
          )}
        </div>
      </div>
      <div className={classes.jobsiteInfoRow(!searchWorkerResult.jobsitesInfo?.length)}>
        {searchWorkerResult.jobsitesInfo?.map((jobsiteInfo) => (
          <SearchWorkerInfoJobsiteLabel
            key={jobsiteInfo.jobsiteWorkerId}
            isJobsiteAccessAllowed={jobsiteInfo.isJobsiteAccessAllowed}
            jobsiteName={jobsiteInfo.jobsiteName}
            contractorName={jobsiteInfo.contractorName}
          />
        ))}
      </div>
    </div>
  );
}
