import React from 'react';
import { Copy } from 'containers/userPendingChange/helpers/languages';
import { UserPendingChangeStepProps } from 'containers/userPendingChange/types';
import { UserPendingChangeType } from 'apollo/generated/client-operations';

export function ConfirmationStep(props: UserPendingChangeStepProps): React.ReactElement {
  const { localize, changeType } = props;

  const textCopy =
    changeType === UserPendingChangeType.Email
      ? Copy.email_pending_change_confirmation_text
      : Copy.phone_pending_change_confirmation_text;

  return (
    <div className="odin-p-7.5 odin-flex odin-flex-col odin-gap-y-6">
      <div className="odin-p-6 odin-rounded-xl odin-bg-odin-gradient-2 odin-text-white odin-text-center">
        <h2 className="">{localize(Copy.pending_change_confirmation_header)}</h2>
        <div className="odin-text">{localize(textCopy)}</div>
      </div>
    </div>
  );
}
