import React from 'react';
import { Dialog } from 'dialog';
import { useUpdatableState } from 'utils';
import {
  JobsiteFormSubmissionWorkerEdit,
  OnWorkerConfirmed,
} from 'containers/jobsiteFormSubmission/components/formSubmissionWorkers/types';
import { FormSubmissionWorkerEditSearch } from 'containers/jobsiteFormSubmission/components/formSubmissionWorkerEditSearch';
import { EditFormSubmissionWorkerModalProps } from './types';
import { FormSubmissionWorkerEditForm } from './components/formSubmissionWorkerEditForm/FormSubmissionWorkerEditForm';
import { getInitialJfsWorkerEdit } from './utils';

export function EditFormSubmissionWorkerModal(props: EditFormSubmissionWorkerModalProps): React.ReactElement {
  const { isOpen, closeModal, onConfirm, jobsiteId, jobsiteFormSubmissionId, jfsWorker, associationType } = props;
  const initialJfsWorkerEdit = React.useMemo(() => getInitialJfsWorkerEdit(jfsWorker), [jfsWorker]);
  const [jfsWorkerEdit, setJfsWorkerEdit] = useUpdatableState<JobsiteFormSubmissionWorkerEdit | null>(
    initialJfsWorkerEdit,
  );

  const confirmWorkerAndResetState: OnWorkerConfirmed = React.useCallback(
    (...args) => {
      onConfirm(...args);
      setJfsWorkerEdit(null);
    },
    [onConfirm, setJfsWorkerEdit],
  );

  const closeModalAndResetState = React.useCallback(() => {
    closeModal();
    setJfsWorkerEdit(null);
  }, [closeModal, setJfsWorkerEdit]);

  return (
    <Dialog
      id="editFormSubmissionWorker"
      open={isOpen}
      setOpen={closeModalAndResetState}
      title={`${jfsWorkerEdit?.editType === 'update' ? 'Update' : 'Add'} Worker`}
      actionsPanel={null}
    >
      {jfsWorkerEdit ? (
        <FormSubmissionWorkerEditForm
          jfsWorkerEdit={jfsWorkerEdit}
          onConfirm={confirmWorkerAndResetState}
          onCancel={(): void => setJfsWorkerEdit(null)}
        />
      ) : (
        <FormSubmissionWorkerEditSearch
          jobsiteFormSubmissionId={jobsiteFormSubmissionId}
          onSelected={(searchWorkerResult): void => {
            const { jobsitesInfo } = searchWorkerResult;
            const jobsiteWorker =
              jobsitesInfo.find((ji) => ji.jobsiteWorker.jobsiteContractor.jobsite.jobsiteId === jobsiteId)
                ?.jobsiteWorker ?? jobsitesInfo[0]?.jobsiteWorker;
            setJfsWorkerEdit({
              id: searchWorkerResult.id,
              jobsiteWorker,
              extraData: { participantType: null },
              jobsitesInfo: searchWorkerResult.jobsitesInfo,
              editType: 'create',
              searchWorkerResult,
              associationType,
            });
          }}
        />
      )}
    </Dialog>
  );
}
