import { ObjectHistory as GqlObjectHistory, User } from 'apollo/generated/client-operations';

export type ObjectHistoryUser = {
  identity?: Pick<User['identity'], 'firstName' | 'lastName' | 'email'>;
};

export type ObjectHistory = Pick<GqlObjectHistory, 'createdAt' | 'updatedAt'> & {
  createdBy?: ObjectHistoryUser;
  updatedBy?: ObjectHistoryUser;
};

export interface ObjectWithHistory {
  objectHistory?: ObjectHistory;
}

export enum ObjectHistorySource {
  Worker = 'Worker',
  JobsiteWorker = 'JobsiteWorker',
}

export enum ObjectHistoryFormat {
  FullText = 'FullText',
  TimeName = 'TimeName',
  NameTime = 'NameTime',
  Name = 'Name',
  Time = 'Time',
}

export interface ObjectHistoryProps {
  object: ObjectWithHistory;
  format?: ObjectHistoryFormat;
  className?: string;
  source?: ObjectHistorySource;
}
