import React from 'react';
import { SearchBoxAutocompleteViewProps } from '@odin-labs/components';
import { classes } from './SearchBoxInProgressList.style';

const ROW_COUNT = 3;
const rows = [...Array(ROW_COUNT)].map((_, idx) => idx);

export function SearchBoxInProgressList(props: SearchBoxAutocompleteViewProps): React.ReactElement {
  const { getItemProps } = props;

  return (
    <div className={classes.inProgressListContainer}>
      <ul className={classes.inProgressList}>
        {rows.map((index) => {
          const itemProps = getItemProps({
            key: index,
            item: index,
            index,
          });

          return (
            <li {...itemProps} className="!odin-mx-0 !odin-px-0">
              <div className={classes.inProgressItemContainer}>
                <div className="odin-flex odin-animate-pulse odin-flex-row odin-items-center">
                  <div className="odin-bg-gray-300 odin-w-10 odin-h-10 odin-rounded-full odin-shrink-0" />
                  <div className="odin-flex odin-flex-col odin-space-y-1 odin-ml-4 odin-grow">
                    <div className="odin-bg-gray-300 odin-h-4 odin-rounded-md" />
                    <div className="odin-w-5/6 odin-bg-gray-300 odin-h-4 odin-rounded-md" />
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
