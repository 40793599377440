import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBuildingColumns as falBuildingColumns,
  faShieldCheck as falShieldCheck,
  faIdBadge as falIdBadge,
  faPhone as falPhone,
  faEnvelope as falEnvelope,
  faTools as falTools,
  faPrint as falPrint,
} from '@fortawesome/pro-light-svg-icons';

// import {
// } from '@fortawesome/pro-solid-svg-icons';

library.add(
  // light
  falBuildingColumns,
  falShieldCheck,
  falIdBadge,
  falPhone,
  falEnvelope,
  falTools,
  falPrint,

  // solid
);
