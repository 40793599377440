import React from 'react';
import { HTMLFormElementWithApi } from 'components/form/types';
import { DeferredPromise } from './deferredPromise';

export type DeferredFormSubmission<TFields> = {
  formRef: React.MutableRefObject<HTMLFormElementWithApi<TFields>>;
  deferredSubmission: DeferredPromise<boolean>;
  submitForm: () => Promise<boolean>;
};

export function useDeferredFormSubmission<TFields>(): DeferredFormSubmission<TFields> {
  const formRef = React.useRef<HTMLFormElementWithApi<TFields>>();
  const deferredSubmission = new DeferredPromise<boolean>();

  const submitForm = async (): Promise<boolean> => {
    const isValid = await formRef.current.api.trigger();
    formRef.current.requestSubmit();
    if (isValid) {
      return deferredSubmission.promise;
    }
    return Promise.resolve(false);
  };

  return { formRef, deferredSubmission, submitForm };
}
