import React, { ReactElement } from 'react';
import moment from 'moment';
import { Filter, NewButton, Table, TableContainer, TimePicker } from '@odin-labs/components';
import {
  JobsiteAccessEventRejectionReason,
  JobsiteAccessEventStatus,
  useGetJobsiteAccessActivityCurrentSessionQuery,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { NewHeader } from 'components/header/NewHeader';
import { ArrowToBottomIcon } from 'components/icons';
import { LoadingError } from 'components/loadingError';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { getLookerReportUrl } from 'looker';
import { LookerFilter, LookerReport, LookerTimeRangeType } from 'looker/types';
import { useHistory } from 'react-router-dom';
import { getCurrentISOFormattedDate, isQueryLoading, isStringEnumValue, momentISODateFormatter } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { getJobsiteDateTime } from 'utils/dates';
import { getDeveloperOptionsFromJobsites, getJobsiteIdsForDevelopers } from 'utils/filters';
import { usePageQueryParams } from 'utils/usePageQueryParams';

import { Container } from 'components/container';
import { useJobsitesSelection } from 'graphql/client/useJobsitesSelection';
import { getColumns, getFilterItems, JobsiteAccessEventColumn } from './helpers/tables';
import { JobsiteAccessActivityFilters, JobsiteAccessEvent, JobsiteAccessEventsFiltersOptions } from './types';

export function JobsiteAccessActivityContainer(): ReactElement {
  const history = useHistory();

  const defaultStartDate = moment(getCurrentISOFormattedDate());
  const defaultEndDate = moment(getCurrentISOFormattedDate());
  const defaultStartTime = TimePicker.DAY_START_TIME;
  const defaultEndTime = TimePicker.DAY_END_TIME;

  const {
    page,
    // orderBy: defaultSortField,
    // orderByDesc: isDescending,
    search,
    developerIds,
    jobsiteIds,
    contractorIds,
    gatewayIds,
    startDate,
    startTime,
    endDate,
    endTime,
    eventStatus,
    rejectionReasons,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({
    updateJobsiteSelection: true,
    defaultValues: {
      startDate: defaultStartDate,
      startTime: defaultStartTime,
      endDate: defaultEndDate,
      endTime: defaultEndTime,
    },
  });

  const offset = page * limit;

  const { currentUser: user } = React.useContext(AuthContext);
  const isFeatureLocked = !user.hasPaidAccess;

  const startDateTime = getJobsiteDateTime({
    date: startDate,
    time: startTime || defaultStartTime,
  });
  const endDateTime = getJobsiteDateTime({
    date: endDate,
    time: endTime || defaultEndTime,
    isEnd: !!endTime, // defaultEndTime already contains end interval seconds and milliseconds
  });

  const isActiveSession =
    moment(startDateTime).isSame(defaultEndDate, 'day') && moment(endDateTime).isSame(defaultEndDate, 'day');

  const validRejectionReasons = React.useMemo(() => {
    return rejectionReasons?.filter((rr): rr is JobsiteAccessEventRejectionReason =>
      isStringEnumValue(JobsiteAccessEventRejectionReason, rr),
    );
  }, [rejectionReasons]);
  const validStatus = isStringEnumValue(JobsiteAccessEventStatus, eventStatus) ? eventStatus : null;

  const { data, error, refetch, networkStatus } = useGetJobsiteAccessActivityCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    pollInterval: isActiveSession ? 10000 : 0,
    notifyOnNetworkStatusChange: true,
    skip: isUrlLoading || isFeatureLocked,
    variables: {
      userJobsiteAccessEventsInput: {
        paginationInput: { limit, offset },
        jobsiteIds,
        contractorIds,
        gatewayIds,
        startDateTime,
        endDateTime,
        status: validStatus,
        rejectionReasons: validRejectionReasons,
        search,
      },
    },
  });

  const userData = data?.getCurrentSession.user;
  const jobsiteAccessEvents = userData?.jobsiteAccessEvents.edges.map((w) => w.node) ?? [];

  const jobsiteAccessEventsCount = userData?.jobsiteAccessEvents.count ?? 0;
  const pageCount = Math.ceil(jobsiteAccessEventsCount / limit);

  const { availableJobsites, selectedJobsites } = useJobsitesSelection();
  const filtersOptions: JobsiteAccessEventsFiltersOptions = {
    ...userData?.jobsiteAccessEvents.filtersOptions,
    jobsites: availableJobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })),
    developers: getDeveloperOptionsFromJobsites(availableJobsites),
  };

  const columns = React.useMemo<JobsiteAccessEventColumn[]>(() => getColumns(), []);
  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        filtersOptions,
        developerIds,
        jobsiteIds,
        contractorIds,
        gatewayIds,
        eventStatus: validStatus,
        rejectionReasons: validRejectionReasons,
        startDate,
        startTime,
        endDate,
        endTime,
        search,
      }),
    [
      filtersOptions,
      developerIds,
      jobsiteIds,
      contractorIds,
      gatewayIds,
      validStatus,
      validRejectionReasons,
      startDate,
      startTime,
      endDate,
      endTime,
      search,
    ],
  );

  const headerActionsProps = React.useMemo(() => ({ onReloadPressed: refetch }), [refetch]);

  const reportJobsites = selectedJobsites?.length ? selectedJobsites : availableJobsites;
  const selectedJobsiteNames = reportJobsites?.map(({ name }) => name).join();

  const lookerReportUrl = getLookerReportUrl(LookerReport.JobsiteAccessActivity, {
    [LookerFilter.Jobsite]: selectedJobsiteNames,
    [LookerFilter.TimeRangeType]: LookerTimeRangeType.DateRange,
    [LookerFilter.StartDate]: startDate?.format(momentISODateFormatter),
    [LookerFilter.EndDate]: endDate?.format(momentISODateFormatter),
    [LookerFilter.SwipeStatus]: eventStatus,
    [LookerFilter.SwipeRejectionReason]: rejectionReasons?.[0],
  });

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  const onFilterChangeHandler = (changedFilters: Partial<JobsiteAccessActivityFilters>): void => {
    updateUrl({
      page: null,
      ...changedFilters,
      ...getJobsiteIdsForDevelopers(availableJobsites, changedFilters.developerIds),
    });
  };

  const onRowClickHandler = ({ data: jobsiteAccessEvent }: { data: JobsiteAccessEvent }): void => {
    if (jobsiteAccessEvent.worker?.workerId) {
      history.push(`/worker/${jobsiteAccessEvent.worker.workerId}/access-activity`);
    }
  };

  if (error) {
    return <LoadingError error={error} />;
  }

  if (isFeatureLocked) {
    return (
      <div className="odin-p-8">
        <LockedFeatureAlert />
      </div>
    );
  }

  const loading = isQueryLoading(networkStatus);

  return (
    <Container className="jobsite-access-events-container">
      <NewHeader title="Access Activity" titleInfo={jobsiteAccessEventsCount} actionsProps={headerActionsProps} />
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler}>
          {!user.isContractor && (
            <NewButton
              as="a"
              href={lookerReportUrl}
              target="_blank"
              theme="white"
              size={['base', 'md:xs']}
              text="Download"
              hideTextOnMobile
              icon={ArrowToBottomIcon}
            />
          )}
        </Filter>
        <Table
          loading={loading}
          columns={columns}
          data={jobsiteAccessEvents}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
    </Container>
  );
}
