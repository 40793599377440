import { Modal, ModalProps, SelectOptionElement } from '@odin-labs/components';
import { MatchedWorkerCard } from 'containers/workerOnboarding/types';
import React from 'react';
import {
  AssignBluetoothCardData,
  AssignBluetoothCardModalContent,
  ReassignBluetoothCardModalContent,
} from './components';
import { BluetoothCardModalType } from './types';

export type AddBluetoothCardModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  workerId: string;
  workerCard: MatchedWorkerCard;
  jobsitesOptions: SelectOptionElement[];
};

export function AddBluetoothCardModal(props: AddBluetoothCardModalProps): React.ReactElement {
  const { isOpen, closeModal, workerId, workerCard, jobsitesOptions } = props;

  const [contentType, setContentType] = React.useState<BluetoothCardModalType>(BluetoothCardModalType.Assign);
  const [reassignData, setReassignData] = React.useState<AssignBluetoothCardData>(null);

  const getModalProps = (): Omit<ModalProps, 'open' | 'setOpen'> => {
    switch (contentType) {
      case BluetoothCardModalType.Assign:
        return {
          actionsPanel: null,
          title: 'Set bluetooth card number',
          children: (
            <AssignBluetoothCardModalContent
              setOpen={closeModal}
              onAction={(assignData?: AssignBluetoothCardData): void => {
                setReassignData(assignData);
                if (assignData) {
                  setContentType(BluetoothCardModalType.Reassign);
                } else {
                  closeModal();
                }
              }}
              workerId={workerId}
              workerCard={workerCard}
              jobsitesOptions={jobsitesOptions}
            />
          ),
        };
      case BluetoothCardModalType.Reassign:
        return {
          actionsPanel: null,
          title: 'This bluetooth card is currently assigned to another worker',
          subtitle: 'Would you like to reassign this badge to this worker?',
          children: (
            <ReassignBluetoothCardModalContent
              setOpen={(): void => {
                setReassignData(null);
                closeModal();
              }}
              onAction={(): void => {
                setReassignData(null);
                closeModal();
              }}
              workerId={workerId}
              reassignData={reassignData}
            />
          ),
        };
      default:
        return {};
    }
  };

  const modalProps = getModalProps();

  return <Modal open={isOpen} setOpen={closeModal} size="md" {...modalProps} />;
}
