import React, { ReactElement } from 'react';
import cn from 'classnames';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { BaseModalProps } from './types';

// eslint-disable-next-line react/function-component-definition
export const BaseModal: React.FC<BaseModalProps> = ({
  isOpen = false,
  toggle,
  title,
  subtitle,
  onCancel,
  onClosed,
  onAction,
  blockButton = false,
  actionColor = 'primary',
  cancelText = 'Cancel',
  actionText = 'Save',
  shouldHideCancel = false,
  shouldHideClose = false,
  children,
  shouldHideAction = false,
  actionButtonType = 'button',
  actionButtonEnabled = true,
  formId,
  cancelIcon = null,
  actionIcon = null,
  classNames,
}): ReactElement => {
  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered fade={false} className={classNames?.container} onClosed={onClosed}>
      <ModalHeader
        toggle={toggle}
        close={!shouldHideClose ? closeBtn : null}
        className={cn('border-0 mt-2', classNames?.header)}
      />
      <ModalBody className={cn('justify-content-center', classNames?.body)}>
        <Row>
          <Col xs="12" className="text-center">
            {title ? <h1 className="display-4 text-center mb-4 mt-2">{title}</h1> : null}
            {subtitle ? <p>{subtitle}</p> : null}
          </Col>
          <Col xs="12">{children}</Col>
        </Row>
      </ModalBody>
      {shouldHideCancel && shouldHideAction ? null : (
        <ModalFooter className={cn('justify-content-center', classNames?.footer)}>
          <Row className="w-100">
            {!shouldHideCancel ? (
              <Col className="text-center">
                <Button
                  title="Cancel"
                  onClick={onCancel}
                  className={cn('btn-white', classNames?.cancelButton)}
                  block={blockButton}
                >
                  {cancelIcon}
                  {cancelText}
                </Button>
              </Col>
            ) : null}
            {!shouldHideAction ? (
              <Col className="text-center">
                <Button
                  color={actionColor}
                  className={cn('mb-3', classNames?.actionButton)}
                  disabled={!actionButtonEnabled}
                  onClick={onAction}
                  type={actionButtonType}
                  form={formId}
                  block={blockButton}
                >
                  {actionIcon}
                  {actionText}
                </Button>
              </Col>
            ) : null}
          </Row>
        </ModalFooter>
      )}
    </Modal>
  );
};
