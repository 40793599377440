import moment from 'moment';

import { JobsiteEquipmentHistoryColumn } from './types';

// Equipment History
export const getColumns = (): JobsiteEquipmentHistoryColumn[] => [
  {
    id: 'deviceName',
    Header: 'Device',
    accessor: 'deviceName',
    disableSortBy: true,
  },
  {
    id: 'deviceType',
    Header: 'Device Type',
    accessor: 'deviceType',
    disableSortBy: true,
  },
  {
    id: 'deviceId',
    Header: 'Device ID',
    accessor: 'deviceGuid',
    disableSortBy: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'deviceStatus',
    disableSortBy: true,
  },
  {
    id: 'date',
    Header: 'Date',
    accessor: (item): string => (item?.eventDate ? moment(item?.eventDate).format('M/D/YY h:mm:ss a') : 'Unknown'),
    disableSortBy: true,
  },
];
