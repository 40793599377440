import React from 'react';
import { classes } from './SearchWorkerInfo.style';
import { SearchWorkerInfoJobsiteLabelProps } from './types';

export function SearchWorkerInfoJobsiteLabel(props: SearchWorkerInfoJobsiteLabelProps): React.ReactElement {
  const { isJobsiteAccessAllowed, jobsiteName, contractorName } = props;
  return (
    <div className={classes.jobsiteLabel}>
      <svg viewBox="0 0 8 8" className={classes.jobsiteAccessStatus(isJobsiteAccessAllowed)}>
        <circle cx="4" cy="4" r="4" />
      </svg>
      <span className={classes.jobsiteNameLabel}>{jobsiteName}</span>
      <span className={classes.jobsiteConstructorNameLabel}>{contractorName}</span>
    </div>
  );
}
