/* eslint-disable max-len */
import { addTranslations } from 'utils/localization';

export enum Copy {
  language = 'language',
  complete_registration = 'complete_registration',
  language_selector_label = 'language_selector_label',
  self_onboarding_worker_match_header = 'self_onboarding_worker_match_header',
  preonboarding_sub_heading_step_1 = 'preonboarding_sub_heading_step_1',
  preonboarding_step_1_instructions = 'preonboarding_step_1_instructions',
  preonboarding_heading_step_2 = 'preonboarding_heading_step_2',
  preonboarding_heading_step_3 = 'preonboarding_heading_step_3',
  preonboarding_heading_step_4 = 'preonboarding_heading_step_4',
  preonboarding_sub_heading_step_4 = 'preonboarding_sub_heading_step_4',
  preonboarding_message_phone_match_issue = 'preonboarding_message_phone_match_issue',
  preonboarding_message_worker_match = 'preonboarding_message_worker_match',
  preonboarding_message_phone_prompt = 'preonboarding_message_phone_prompt',
  preonboarding_message_existing_profile = 'preonboarding_message_existing_profile',
  preonboarding_message_email_match_issue = 'preonboarding_message_email_match_issue',
  preonboarding_message_email_prompt = 'preonboarding_message_email_prompt',
  preonboarding_message_expired_link_prompt = 'preonboarding_message_expired_link_prompt',
  preonboarding_message_worker_match_no_action = 'preonboarding_message_worker_match_no_action',
  preonboarding_message_next_steps = 'preonboarding_message_next_steps',
  worker_onboarding_header = 'worker_onboarding_header',
  full_name_label = 'full_name_label',
  first_name_label = 'first_name_label',
  first_name_mask_text = 'first_name_mask_text',
  first_name_invalid_value_validation = 'first_name_invalid_value_validation',
  first_name_missing_value_validation = 'first_name_missing_value_validation',
  last_name_label = 'last_name_label',
  last_name_mask_text = 'last_name_mask_text',
  last_name_invalid_value_validation = 'last_name_invalid_value_validation',
  last_name_missing_value_validation = 'last_name_missing_value_validation',
  middle_initial_label = 'middle_initial_label',
  middle_initial_mask_text = 'middle_initial_mask_text',
  middle_initial_invalid_value_validation = 'middle_initial_invalid_value_validation',
  middle_initial_missing_value_validation = 'middle_initial_missing_value_validation',
  birth_date_label = 'birth_date_label',
  birth_date_mask_text = 'birth_date_mask_text',
  birth_date_invalid_value_validation = 'birth_date_invalid_value_validation',
  birth_date_missing_value_validation = 'birth_date_missing_value_validation',
  email_label = 'email_label',
  email_mask_text = 'email_mask_text',
  email_invalid_value_validation = 'email_invalid_value_validation',
  email_missing_value_validation = 'email_missing_value_validation',
  phone_number_label = 'phone_number_label',
  phone_number_mask_text = 'phone_number_mask_text',
  phone_number_invalid_value_validation = 'phone_number_invalid_value_validation',
  phone_number_missing_value_validation = 'phone_number_missing_value_validation',
  address_line_1_label = 'address_line_1_label',
  address_line_1_mask_text = 'address_line_1_mask_text',
  address_line_1_invalid_value_validation = 'address_line_1_invalid_value_validation',
  address_line_1_missing_value_validation = 'address_line_1_missing_value_validation',
  address_line_2_label = 'address_line_2_label',
  address_line_2_mask_text = 'address_line_2_mask_text',
  address_line_2_invalid_value_validation = 'address_line_2_invalid_value_validation',
  address_line_2_missing_value_validation = 'address_line_2_missing_value_validation',
  address_city_label = 'address_city_label',
  address_city_mask_text = 'address_city_mask_text',
  address_city_invalid_value_validation = 'address_city_invalid_value_validation',
  address_city_missing_value_validation = 'address_city_missing_value_validation',
  address_state_label = 'address_state_label',
  address_state_missing_value_validation = 'address_state_missing_value_validation',
  address_zip_code_label = 'address_zip_code_label',
  address_zip_code_mask_text = 'address_zip_code_mask_text',
  address_zip_code_invalid_value_validation = 'address_zip_code_invalid_value_validation',
  address_zip_code_missing_value_validation = 'address_zip_code_missing_value_validation',
  primary_language_label = 'primary_language_label',
  ssn_last_four_label = 'ssn_last_four_label',
  ssn_last_four_mask_text = 'ssn_last_four_mask_text',
  ssn_last_four_invalid_value_validation = 'ssn_last_four_invalid_value_validation',
  ssn_last_four_missing_value_validation = 'ssn_last_four_missing_value_validation',
  emergency_contact_name_label = 'emergency_contact_name_label',
  emergency_contact_name_mask_text = 'emergency_contact_name_mask_text',
  emergency_contact_name_invalid_value_validation = 'emergency_contact_name_invalid_value_validation',
  emergency_contact_name_missing_value_validation = 'emergency_contact_name_missing_value_validation',
  emergency_contact_relationship_label = 'emergency_contact_relationship_label',
  emergency_contact_relationship_mask_text = 'emergency_contact_relationship_mask_text',
  emergency_contact_relationship_invalid_value_validation = 'emergency_contact_relationship_invalid_value_validation',
  emergency_contact_relationship_missing_value_validation = 'emergency_contact_relationship_missing_value_validation',
  emergency_contact_relationship_option_boyfriend = 'emergency_contact_relationship_option_boyfriend',
  emergency_contact_relationship_option_child = 'emergency_contact_relationship_option_child',
  emergency_contact_relationship_option_co_worker = 'emergency_contact_relationship_option_co_worker',
  emergency_contact_relationship_option_family = 'emergency_contact_relationship_option_family',
  emergency_contact_relationship_option_girlfriend = 'emergency_contact_relationship_option_girlfriend',
  emergency_contact_relationship_option_grandparent = 'emergency_contact_relationship_option_grandparent',
  emergency_contact_relationship_option_friend = 'emergency_contact_relationship_option_friend',
  emergency_contact_relationship_option_parent = 'emergency_contact_relationship_option_parent',
  emergency_contact_relationship_option_partner = 'emergency_contact_relationship_option_partner',
  emergency_contact_relationship_option_spouse = 'emergency_contact_relationship_option_spouse',
  emergency_contact_relationship_option_other = 'emergency_contact_relationship_option_other',
  emergency_contact_phone_label = 'emergency_contact_phone_label',
  emergency_contact_phone_mask_text = 'emergency_contact_phone_mask_text',
  emergency_contact_phone_invalid_value_validation = 'emergency_contact_phone_invalid_value_validation',
  emergency_contact_phone_missing_value_validation = 'emergency_contact_phone_missing_value_validation',
  trade_label = 'trade_label',
  trade_mask_text = 'trade_mask_text',
  trade_invalid_value_validation = 'trade_invalid_value_validation',
  trade_missing_value_validation = 'trade_missing_value_validation',
  trade_option_bricklayer = 'trade_option_bricklayer',
  trade_option_carpenter = 'trade_option_carpenter',
  trade_option_cement_masons = 'trade_option_cement_masons',
  trade_option_concrete_safety_manager = 'trade_option_concrete_safety_manager',
  trade_option_concrete_workers = 'trade_option_concrete_workers',
  trade_option_crane_operator = 'trade_option_crane_operator',
  trade_option_demolition = 'trade_option_demolition',
  trade_option_dockbuilder = 'trade_option_dockbuilder',
  trade_option_drill_operator = 'trade_option_drill_operator',
  trade_option_driver = 'trade_option_driver',
  trade_option_electrician = 'trade_option_electrician',
  trade_option_elevator = 'trade_option_elevator',
  trade_option_engineer = 'trade_option_engineer',
  trade_option_executive = 'trade_option_executive',
  trade_option_excavators = 'trade_option_excavators',
  trade_option_fire_safety = 'trade_option_fire_safety',
  trade_option_flagger = 'trade_option_flagger',
  trade_option_flooring = 'trade_option_flooring',
  trade_option_glazier = 'trade_option_glazier',
  trade_option_hvac = 'trade_option_hvac',
  trade_option_inspector = 'trade_option_inspector',
  trade_option_insulator = 'trade_option_insulator',
  trade_option_ironworker = 'trade_option_ironworker',
  trade_option_laborer = 'trade_option_laborer',
  trade_option_lighting = 'trade_option_lighting',
  trade_option_maintenance = 'trade_option_maintenance',
  trade_option_manager = 'trade_option_manager',
  trade_option_masonry = 'trade_option_masonry',
  trade_option_mechanic = 'trade_option_mechanic',
  trade_option_medical = 'trade_option_medical',
  trade_option_metal_lathers = 'trade_option_metal_lathers',
  trade_option_na = 'trade_option_na',
  trade_option_office = 'trade_option_office',
  trade_option_oiler = 'trade_option_oiler',
  trade_option_operating_engineer = 'trade_option_operating_engineer',
  trade_option_operator = 'trade_option_operator',
  trade_option_painter = 'trade_option_painter',
  trade_option_pipefitter = 'trade_option_pipefitter',
  trade_option_plasterer = 'trade_option_plasterer',
  trade_option_plumber = 'trade_option_plumber',
  trade_option_project_manager = 'trade_option_project_manager',
  trade_option_rebar = 'trade_option_rebar',
  trade_option_rigger = 'trade_option_rigger',
  trade_option_roofer = 'trade_option_roofer',
  trade_option_safety = 'trade_option_safety',
  trade_option_scaffolding = 'trade_option_scaffolding',
  trade_option_sheetmetal = 'trade_option_sheetmetal',
  trade_option_sprinkler = 'trade_option_sprinkler',
  trade_option_steamfitter = 'trade_option_steamfitter',
  trade_option_steelworker = 'trade_option_steelworker',
  trade_option_superintendent = 'trade_option_superintendent',
  trade_option_supervisor = 'trade_option_supervisor',
  trade_option_surveyor = 'trade_option_surveyor',
  trade_option_security = 'trade_option_security',
  trade_option_tapers = 'trade_option_tapers',
  trade_option_teamsters = 'trade_option_teamsters',
  trade_option_telecommunications = 'trade_option_telecommunications',
  trade_option_tiler = 'trade_option_tiler',
  trade_option_timekeeper = 'trade_option_timekeeper',
  trade_option_union_representative = 'trade_option_union_representative',
  trade_option_waterproofer = 'trade_option_waterproofer',
  trade_option_welders = 'trade_option_welders',
  trade_option_other = 'trade_option_other',
  job_title_label = 'job_title_label',
  job_title_mask_text = 'job_title_mask_text',
  job_title_invalid_value_validation = 'job_title_invalid_value_validation',
  job_title_missing_value_validation = 'job_title_missing_value_validation',
  job_title_class_label = 'job_title_class_label',
  job_title_class_mask_text = 'job_title_class_mask_text',
  job_title_class_invalid_value_validation = 'job_title_class_invalid_value_validation',
  job_title_class_missing_value_validation = 'job_title_class_missing_value_validation',
  job_title_option_apprentice = 'job_title_option_apprentice',
  job_title_option_foreman = 'job_title_option_foreman',
  job_title_option_journeyman = 'job_title_option_journeyman',
  job_title_option_laborer = 'job_title_option_laborer',
  job_title_option_medic = 'job_title_option_medic',
  job_title_option_medic_supervisor = 'job_title_option_medic_supervisor',
  job_title_option_operating_engineer = 'job_title_option_operating_engineer',
  job_title_option_provisional_apprentice = 'job_title_option_provisional_apprentice',
  job_title_option_provisional_foreman = 'job_title_option_provisional_foreman',
  job_title_option_provisional_journeyman = 'job_title_option_provisional_journeyman',
  job_title_option_shop_steward = 'job_title_option_shop_steward',
  job_title_option_supervisor = 'job_title_option_supervisor',
  job_title_option_superintendent = 'job_title_option_superintendent',
  job_title_option_office = 'job_title_option_office',
  job_title_option_other = 'job_title_option_other',
  job_title_option_n_a = 'job_title_option_n_a',
  union_affiliation_label = 'union_affiliation_label',
  union_affiliation_mask_text = 'union_affiliation_mask_text',
  union_affiliation_invalid_value_validation = 'union_affiliation_invalid_value_validation',
  union_affiliation_missing_value_validation = 'union_affiliation_missing_value_validation',
  citizenship_status_label = 'citizenship_status_label',
  citizenship_status_invalid_value_validation = 'citizenship_status_invalid_value_validation',
  citizenship_status_missing_value_validation = 'citizenship_status_missing_value_validation',
  citizenship_status_option_citizen = 'citizenship_status_option_citizen',
  citizenship_status_option_non_citizen = 'citizenship_status_option_non_citizen',
  is_veteran_label = 'is_veteran_label',
  is_veteran_mask_text = 'is_veteran_mask_text',
  is_veteran_invalid_value_validation = 'is_veteran_invalid_value_validation',
  is_veteran_missing_value_validation = 'is_veteran_missing_value_validation',
  is_veteran_option_veteran = 'is_veteran_option_veteran',
  is_veteran_option_non_veteran = 'is_veteran_option_non_veteran',
  contractor_label = 'contractor_label',
  contractor_mask_text = 'contractor_mask_text',
  contractor_invalid_value_validation = 'contractor_invalid_value_validation',
  contractor_missing_value_validation = 'contractor_missing_value_validation',
  race_label = 'race_label',
  race_mask_text = 'race_mask_text',
  race_invalid_value_validation = 'race_invalid_value_validation',
  race_missing_value_validation = 'race_missing_value_validation',
  race_option_asian_native_hawaiian_or_pacific_islander = 'race_option_asian_native_hawaiian_or_pacific_islander',
  race_option_black_african_american = 'race_option_black_african_american',
  race_option_native_american_alaskan_native = 'race_option_native_american_alaskan_native',
  race_option_hispanic_latino = 'race_option_hispanic_latino',
  race_option_white = 'race_option_white',
  race_option_other_prefer_not_to_respond = 'race_option_other_prefer_not_to_respond',
  gender_label = 'gender_label',
  gender_mask_text = 'gender_mask_text',
  gender_invalid_value_validation = 'gender_invalid_value_validation',
  gender_missing_value_validation = 'gender_missing_value_validation',
  gender_option_male = 'gender_option_male',
  gender_option_female = 'gender_option_female',
  gender_option_other = 'gender_option_other',
  submit_button_text = 'submit_button_text',
  field_required_label = 'field_required_label',
  field_optional_label = 'field_optional_label',
  picklist_mask_general = 'picklist_mask_general',
  thank_you_message = 'thank_you_message',

  self_onboarding_get_started_text = 'self_onboarding_get_started_text',
  self_onboarding_error_heading = 'self_onboarding_error_heading',
  self_onboarding_error_text = 'self_onboarding_error_text',
  self_onboarding_profile_header = 'self_onboarding_profile_header',
  self_onboarding_profile_instructions = 'self_onboarding_profile_instructions',
  self_onboarding_matched_worker_heading = 'self_onboarding_matched_worker_heading',
  self_onboarding_matched_worker_text = 'self_onboarding_matched_worker_text',
  self_onboarding_emergency_contact_instructions = 'self_onboarding_emergency_contact_instructions',
  self_onboarding_government_issued_id_header = 'self_onboarding_government_issued_id_header',
  self_onboarding_government_issued_id_instructions = 'self_onboarding_government_issued_id_instructions',
  front_file_label = 'front_file_label',
  back_file_label = 'back_file_label',
  id_type_label = 'id_type_label',
  state_issued_label = 'state_issued_label',
  government_id_number_label = 'government_id_number_label',
  issue_date_label = 'issue_date_label',
  expiration_date_label = 'expiration_date_label',
  self_onboarding_osha_card_header = 'self_onboarding_osha_card_header',
  self_onboarding_osha_card_instructions = 'self_onboarding_osha_card_instructions',
  card_type_label = 'card_type_label',
  course_date_label = 'course_date_label',
  osha_card_number_label = 'osha_card_number_label',
  self_onboarding_ny_sst_card_header = 'self_onboarding_ny_sst_card_header',
  self_onboarding_ny_sst_card_instructions = 'self_onboarding_ny_sst_card_instructions',
  training_connect_card_label = 'training_connect_card_label',
  self_onboarding_profile_photo_header = 'self_onboarding_profile_photo_header',
  self_onboarding_profile_photo_instructions = 'self_onboarding_profile_photo_instructions',
  take_photo = 'take_photo',
  self_onboarding_profile_photo_direction_1 = 'self_onboarding_profile_photo_instruction_1',
  self_onboarding_profile_photo_direction_2 = 'self_onboarding_profile_photo_instruction_2',
  self_onboarding_profile_photo_direction_3 = 'self_onboarding_profile_photo_instruction_3',
  self_onboarding_profile_photo_direction_4 = 'self_onboarding_profile_photo_instruction_4',
  self_onboarding_profile_photo_help_text = 'self_onboarding_profile_photo_help_text',
  self_onboarding_verify_phone_header = 'self_onboarding_verify_phone_header',
  self_onboarding_verify_phone_instructions = 'self_onboarding_verify_phone_instructions',
  self_onboarding_verify_phone_instructions_no_mobile_app = 'self_onboarding_verify_phone_instructions_no_mobile_app',
  self_onboarding_verify_phone_help_text = 'self_onboarding_verify_phone_help_text',
  self_onboarding_verify_phone_to_sign_in_header = 'self_onboarding_verify_phone_to_sign_in_header',
  self_onboarding_verify_phone_to_sign_in_instructions = 'self_onboarding_verify_phone_to_sign_in_instructions',
  self_onboarding_verify_phone_to_sign_in_action = 'self_onboarding_verify_phone_to_sign_in_action',
  self_onboarding_verify_phone_to_sign_in_skip = 'self_onboarding_verify_phone_to_sign_in_skip',
  self_onboarding_confirm_phone_time_to_complete = 'self_onboarding_confirm_phone_time_to_complete',
  self_onboarding_review_completed_profile_header = 'self_onboarding_review_completed_profile_header',
  self_onboarding_review_completed_profile_instructions = 'self_onboarding_review_completed_profile_instructions',
  self_onboarding_unable_to_verify_account_header = 'self_onboarding_unable_to_verify_account_header',
  self_onboarding_unable_to_verify_account_instructions = 'self_onboarding_unable_to_verify_account_instructions',
  add_mobile_badge = 'add_mobile_badge',
  download_odin_app = 'download_odin_app',
  worker_info = 'worker_info',
  documents = 'documents',
  self_onboarding_no_osha_card = 'self_onboarding_no_osha_card',
  self_onboarding_no_ny_sst_card = 'self_onboarding_no_ny_sst_card',
  self_onboarding_no_government_id = 'self_onboarding_no_government_id',
  self_onboarding_worker_match_instructions = 'self_onboarding_worker_match_instructions',
  continue = 'continue',
  skip_for_now = 'skip_for_now',
  save = 'save',
  cancel = 'cancel',
  self_onboarding_emergency_contact_header = 'self_onboarding_emergency_contact_header',
  self_onboarding_confirm_phone_title = 'self_onboarding_confirm_phone_title',
  self_onboarding_confirm_phone_instruction = 'self_onboarding_confirm_phone_instruction',
  self_onboarding_confirm_phone_request_another_code = 'self_onboarding_confirm_phone_request_another_code',
  self_onboarding_confirm_phone_code_not_received = 'self_onboarding_confirm_phone_code_not_received',
  add = 'add',
  self_onboarding_jobsite_safety_video_header = 'self_onboarding_jobsite_safety_video_header',
  self_onboarding_jobsite_safety_video_instructions = 'self_onboarding_jobsite_safety_video_instructions',
  self_onboarding_jobsite_safety_video_not_acknowledged = 'self_onboarding_jobsite_safety_video_not_acknowledged',
  self_onboarding_jobsite_safety_document_header = 'self_onboarding_jobsite_safety_document_header',
  self_onboarding_jobsite_safety_document_instructions = 'self_onboarding_jobsite_safety_document_instructions',
  self_onboarding_jobsite_safety_document_not_acknowledged = 'self_onboarding_jobsite_safety_document_not_acknowledged',
  self_onboarding_lirr_blue_training_header = 'self_onboarding_lirr_blue_training_header',
  self_onboarding_lirr_blue_training_instructions = 'self_onboarding_lirr_blue_training_instructions',
  self_onboarding_no_lirr_blue_training = 'self_onboarding_no_lirr_blue_training',
  self_onboarding_confined_spaces_training_header = 'self_onboarding_confined_spaces_training_header',
  self_onboarding_confined_spaces_training_instructions = 'self_onboarding_confined_spaces_training_instructions',
  self_onboarding_no_confined_spaces_training = 'self_onboarding_no_confined_spaces_training',
  self_onboarding_worker_consent_header = 'self_onboarding_worker_consent_header',
  self_onboarding_worker_consent_instructions = 'self_onboarding_worker_consent_instructions',
  self_onboarding_worker_consent_text_start = 'self_onboarding_worker_consent_text_start',
  self_onboarding_worker_consent_text_end = 'self_onboarding_worker_consent_text_end',
  self_onboarding_acknowledgment_doc_confirmed_label = 'self_onboarding_acknowledgment_doc_confirmed_label',
  self_onboarding_acknowledgment_doc_confirmed_description = 'self_onboarding_acknowledgment_doc_confirmed_description',
  self_onboarding_acknowledgment_video_confirmed_label = 'self_onboarding_acknowledgment_video_confirmed_label',
  self_onboarding_acknowledgment_video_confirmed_description = 'self_onboarding_acknowledgment_video_confirmed_description',
  self_onboarding_acknowledgment_video_not_started_label = 'self_onboarding_acknowledgment_video_not_started_label',
  self_onboarding_acknowledgment_video_not_started_disabled_description = 'self_onboarding_acknowledgment_video_not_started_disabled_description',
  self_onboarding_acknowledgment_video_in_progress_label = 'self_onboarding_acknowledgment_video_in_progress_label',
}

const en: Record<Copy, string> = {
  [Copy.language]: 'English',
  [Copy.complete_registration]: 'Complete Registration',
  [Copy.language_selector_label]: 'Form Language',
  [Copy.self_onboarding_worker_match_header]: 'Welcome to Odin!',
  [Copy.preonboarding_sub_heading_step_1]:
    "You've received this link because you'll be working on an Odin jobsite. By entering your information here before you arrive, you'll save time at the trailer. We're committed to your safety.",
  [Copy.preonboarding_step_1_instructions]: 'First we need to confirm your identity:',
  [Copy.preonboarding_heading_step_2]: 'To complete your profile, please provide the following information',
  [Copy.preonboarding_heading_step_3]: 'Great, just one more step! Please provide a contact for emergencies.',
  [Copy.preonboarding_heading_step_4]: 'Congratulations!',
  [Copy.preonboarding_sub_heading_step_4]:
    'Thank you for completing your profile. The onsite manager at your jobsite will help you finish your orientation.',
  [Copy.preonboarding_message_phone_match_issue]: 'Phone number does not match. Please try again.',
  [Copy.preonboarding_message_worker_match]: "It looks like you've worked with Odin before!",
  [Copy.preonboarding_message_phone_prompt]: 'To confirm your identity, please provide your phone number:',
  [Copy.preonboarding_message_existing_profile]:
    "Based on the information you provided, you may already have an Odin profile. If so, you'll be able to skip this process and go through expedited orientation when you arrive at your jobsite.",
  [Copy.preonboarding_message_email_match_issue]: 'Email does not match. Please try again.',
  [Copy.preonboarding_message_email_prompt]: 'To confirm your identity, please provide your account email:',
  [Copy.preonboarding_message_expired_link_prompt]:
    'Welcome to Odin! Unfortunately, we were unable to find the page you were looking for. The link you were given may have expired. Please contact your manager for further assistance.',
  [Copy.preonboarding_message_worker_match_no_action]:
    'It looks like this worker already exists in Odin: it is not necessary to add any information at this time.',
  [Copy.preonboarding_message_next_steps]:
    'Worker details can be confirmed and updated during onboarding on the first day at the jobsite.',
  [Copy.worker_onboarding_header]: 'Personal Information',
  [Copy.full_name_label]: 'Name',
  [Copy.first_name_label]: 'First Name',
  [Copy.first_name_mask_text]: 'First name',
  [Copy.first_name_invalid_value_validation]: 'Enter a valid first name',
  [Copy.first_name_missing_value_validation]: 'First name required',
  [Copy.last_name_label]: 'Last Name',
  [Copy.last_name_mask_text]: 'Last name',
  [Copy.last_name_invalid_value_validation]: 'Enter a valid last name',
  [Copy.last_name_missing_value_validation]: 'Last name required',
  [Copy.middle_initial_label]: 'Middle Initial',
  [Copy.middle_initial_mask_text]: 'Middle initial',
  [Copy.middle_initial_invalid_value_validation]: 'Enter a valid middle initial',
  [Copy.middle_initial_missing_value_validation]: 'Middle initial required',
  [Copy.birth_date_label]: 'Date of Birth',
  [Copy.birth_date_mask_text]: 'MM/DD/YYYY',
  [Copy.birth_date_invalid_value_validation]: 'Enter a valid date',
  [Copy.birth_date_missing_value_validation]: 'Date of birth required',
  [Copy.email_label]: 'Email Address',
  [Copy.email_mask_text]: 'Email address',
  [Copy.email_invalid_value_validation]: 'Enter a valid email',
  [Copy.email_missing_value_validation]: 'Email required',
  [Copy.phone_number_label]: 'Mobile phone',
  [Copy.phone_number_mask_text]: 'Mobile phone',
  [Copy.phone_number_invalid_value_validation]: 'Enter a valid mobile phone',
  [Copy.phone_number_missing_value_validation]: 'Mobile phone required',
  [Copy.address_line_1_label]: 'Address',
  [Copy.address_line_1_mask_text]: 'Enter an address',
  [Copy.address_line_1_invalid_value_validation]: 'Enter a valid address',
  [Copy.address_line_1_missing_value_validation]: 'Address required',
  [Copy.address_line_2_label]: 'Address Line 2',
  [Copy.address_line_2_mask_text]: 'Address line 2',
  [Copy.address_line_2_invalid_value_validation]: 'Enter a valid address line 2',
  [Copy.address_line_2_missing_value_validation]: 'Address line 2 required',
  [Copy.address_city_label]: 'City',
  [Copy.address_city_mask_text]: 'City',
  [Copy.address_city_invalid_value_validation]: 'Enter a valid city',
  [Copy.address_city_missing_value_validation]: 'City required',
  [Copy.address_state_label]: 'State',
  [Copy.address_state_missing_value_validation]: 'State required',
  [Copy.address_zip_code_label]: 'ZIP Code',
  [Copy.address_zip_code_mask_text]: 'ZIP Code',
  [Copy.address_zip_code_invalid_value_validation]: 'Enter a valid ZIP Code',
  [Copy.address_zip_code_missing_value_validation]: 'ZIP Code required',
  [Copy.primary_language_label]: 'Preferred Language',
  [Copy.ssn_last_four_label]: 'Last 4 Digits of Social Security Number',
  [Copy.ssn_last_four_mask_text]: 'Last 4 of Social Security Number',
  [Copy.ssn_last_four_invalid_value_validation]: 'Enter a valid number',
  [Copy.ssn_last_four_missing_value_validation]: 'Last 4 required',
  [Copy.emergency_contact_name_label]: 'Emergency Contact Name',
  [Copy.emergency_contact_name_mask_text]: 'Emergency contact name',
  [Copy.emergency_contact_name_invalid_value_validation]: 'Enter a valid emergency contact name',
  [Copy.emergency_contact_name_missing_value_validation]: 'Emergency contact name required',
  [Copy.emergency_contact_relationship_label]: 'Emergency Contact Relationship',
  [Copy.emergency_contact_relationship_mask_text]: 'Emergency contact relationship',
  [Copy.emergency_contact_relationship_invalid_value_validation]: 'Enter a valid emergency contact relationship',
  [Copy.emergency_contact_relationship_missing_value_validation]: 'Emergency contact relationship required',
  [Copy.emergency_contact_relationship_option_boyfriend]: 'Boyfriend',
  [Copy.emergency_contact_relationship_option_child]: 'Child',
  [Copy.emergency_contact_relationship_option_co_worker]: 'Co-worker',
  [Copy.emergency_contact_relationship_option_family]: 'Family',
  [Copy.emergency_contact_relationship_option_girlfriend]: 'Girlfriend',
  [Copy.emergency_contact_relationship_option_grandparent]: 'Grandparent',
  [Copy.emergency_contact_relationship_option_friend]: 'Friend',
  [Copy.emergency_contact_relationship_option_parent]: 'Parent',
  [Copy.emergency_contact_relationship_option_partner]: 'Partner',
  [Copy.emergency_contact_relationship_option_spouse]: 'Spouse',
  [Copy.emergency_contact_relationship_option_other]: 'Other',
  [Copy.emergency_contact_phone_label]: 'Emergency Contact Phone',
  [Copy.emergency_contact_phone_mask_text]: 'Emergency contact phone',
  [Copy.emergency_contact_phone_invalid_value_validation]: 'Enter a valid phone number',
  [Copy.emergency_contact_phone_missing_value_validation]: 'Phone number required',
  [Copy.trade_label]: 'Trade',
  [Copy.trade_mask_text]: 'Trade',
  [Copy.trade_invalid_value_validation]: 'Enter a valid trade',
  [Copy.trade_missing_value_validation]: 'Trade required',
  [Copy.trade_option_bricklayer]: 'Bricklayer',
  [Copy.trade_option_carpenter]: 'Carpenter',
  [Copy.trade_option_cement_masons]: 'Cement Masons',
  [Copy.trade_option_concrete_safety_manager]: 'Concrete Safety Manager',
  [Copy.trade_option_concrete_workers]: 'Concrete Workers',
  [Copy.trade_option_crane_operator]: 'Crane Operator',
  [Copy.trade_option_demolition]: 'Demolition',
  [Copy.trade_option_dockbuilder]: 'Dockbuilder',
  [Copy.trade_option_drill_operator]: 'Drill Operator',
  [Copy.trade_option_driver]: 'Driver',
  [Copy.trade_option_electrician]: 'Electrician',
  [Copy.trade_option_elevator]: 'Elevator',
  [Copy.trade_option_engineer]: 'Engineer',
  [Copy.trade_option_executive]: 'Executive',
  [Copy.trade_option_excavators]: 'Excavators',
  [Copy.trade_option_fire_safety]: 'Fire Safety',
  [Copy.trade_option_flagger]: 'Flagger',
  [Copy.trade_option_flooring]: 'Flooring',
  [Copy.trade_option_glazier]: 'Glazier',
  [Copy.trade_option_hvac]: 'HVAC',
  [Copy.trade_option_inspector]: 'Inspector',
  [Copy.trade_option_insulator]: 'Insulator',
  [Copy.trade_option_ironworker]: 'Ironworker',
  [Copy.trade_option_laborer]: 'Laborer',
  [Copy.trade_option_lighting]: 'Lighting',
  [Copy.trade_option_maintenance]: 'Maintenance',
  [Copy.trade_option_manager]: 'Manager',
  [Copy.trade_option_masonry]: 'Masonry',
  [Copy.trade_option_mechanic]: 'Mechanic',
  [Copy.trade_option_medical]: 'Medical',
  [Copy.trade_option_metal_lathers]: 'Metal Lathers',
  [Copy.trade_option_na]: 'NA',
  [Copy.trade_option_office]: 'Office',
  [Copy.trade_option_oiler]: 'Oiler',
  [Copy.trade_option_operating_engineer]: 'Operating Engineer',
  [Copy.trade_option_operator]: 'Operator',
  [Copy.trade_option_painter]: 'Painter',
  [Copy.trade_option_pipefitter]: 'Pipefitter',
  [Copy.trade_option_plasterer]: 'Plasterer',
  [Copy.trade_option_plumber]: 'Plumber',
  [Copy.trade_option_project_manager]: 'Project Manager',
  [Copy.trade_option_rebar]: 'Rebar',
  [Copy.trade_option_rigger]: 'Rigger',
  [Copy.trade_option_roofer]: 'Roofer',
  [Copy.trade_option_safety]: 'Safety',
  [Copy.trade_option_scaffolding]: 'Scaffolding',
  [Copy.trade_option_sheetmetal]: 'Sheetmetal',
  [Copy.trade_option_sprinkler]: 'Sprinkler',
  [Copy.trade_option_steamfitter]: 'Steamfitter',
  [Copy.trade_option_steelworker]: 'Steelworker',
  [Copy.trade_option_superintendent]: 'Superintendent',
  [Copy.trade_option_supervisor]: 'Supervisor',
  [Copy.trade_option_surveyor]: 'Surveyor',
  [Copy.trade_option_security]: 'Security',
  [Copy.trade_option_tapers]: 'Tapers',
  [Copy.trade_option_teamsters]: 'Teamsters',
  [Copy.trade_option_telecommunications]: 'Telecommunications',
  [Copy.trade_option_tiler]: 'Tiler',
  [Copy.trade_option_timekeeper]: 'Timekeeper',
  [Copy.trade_option_union_representative]: 'Union Representative',
  [Copy.trade_option_waterproofer]: 'Waterproofer',
  [Copy.trade_option_welders]: 'Welders',
  [Copy.trade_option_other]: 'Other',
  [Copy.job_title_label]: 'Job title',
  [Copy.job_title_mask_text]: 'Job title',
  [Copy.job_title_invalid_value_validation]: 'Enter a valid job title',
  [Copy.job_title_missing_value_validation]: 'Job title required',
  [Copy.job_title_class_label]: 'Job Title Class',
  [Copy.job_title_class_mask_text]: 'Job title class',
  [Copy.job_title_class_invalid_value_validation]: 'Job title class required',
  [Copy.job_title_class_missing_value_validation]: 'Enter a valid job title class',
  [Copy.job_title_option_apprentice]: 'Apprentice',
  [Copy.job_title_option_foreman]: 'Foreman',
  [Copy.job_title_option_journeyman]: 'Journeyman',
  [Copy.job_title_option_laborer]: 'Laborer',
  [Copy.job_title_option_medic]: 'Medic',
  [Copy.job_title_option_medic_supervisor]: 'Medic Supervisor',
  [Copy.job_title_option_operating_engineer]: 'Operating Engineer',
  [Copy.job_title_option_provisional_apprentice]: 'Provisional Apprentice',
  [Copy.job_title_option_provisional_foreman]: 'Provisional Foreman',
  [Copy.job_title_option_provisional_journeyman]: 'Provisional Journeyman',
  [Copy.job_title_option_shop_steward]: 'Shop Steward',
  [Copy.job_title_option_supervisor]: 'Supervisor',
  [Copy.job_title_option_superintendent]: 'Superintendent',
  [Copy.job_title_option_office]: 'Office',
  [Copy.job_title_option_other]: 'Other',
  [Copy.job_title_option_n_a]: 'N/A',
  [Copy.union_affiliation_label]: 'Union Affiliation',
  [Copy.union_affiliation_mask_text]: 'Union affiliation',
  [Copy.union_affiliation_invalid_value_validation]: 'Enter a valid union affiliation',
  [Copy.union_affiliation_missing_value_validation]: 'Union affiliation required',
  [Copy.citizenship_status_label]: 'Citizenship Status',
  [Copy.citizenship_status_invalid_value_validation]: 'Select a valid citizenship status',
  [Copy.citizenship_status_missing_value_validation]: 'Citizenship status required',
  [Copy.citizenship_status_option_citizen]: 'Citizen',
  [Copy.citizenship_status_option_non_citizen]: 'Non-Citizen',
  [Copy.is_veteran_label]: 'Veteran Status',
  [Copy.is_veteran_mask_text]: 'Veteran status',
  [Copy.is_veteran_invalid_value_validation]: 'Enter a valid veteran status',
  [Copy.is_veteran_missing_value_validation]: 'Veteran status required',
  [Copy.is_veteran_option_veteran]: 'Veteran',
  [Copy.is_veteran_option_non_veteran]: 'Non-Veteran',
  [Copy.contractor_label]: 'Contractor',
  [Copy.contractor_mask_text]: 'Contractor',
  [Copy.contractor_invalid_value_validation]: 'Enter a valid contractor',
  [Copy.contractor_missing_value_validation]: 'Contractor required',
  [Copy.race_label]: 'Race',
  [Copy.race_mask_text]: 'Race',
  [Copy.race_invalid_value_validation]: 'Enter a valid race',
  [Copy.race_missing_value_validation]: 'Race required',
  [Copy.race_option_asian_native_hawaiian_or_pacific_islander]: 'Asian/Native Hawaiian or Pacific Islander',
  [Copy.race_option_black_african_american]: 'Black/African American',
  [Copy.race_option_native_american_alaskan_native]: 'Native American/Alaskan Native',
  [Copy.race_option_hispanic_latino]: 'Hispanic/Latino',
  [Copy.race_option_white]: 'White',
  [Copy.race_option_other_prefer_not_to_respond]: 'Other/Prefer not to respond',
  [Copy.gender_label]: 'Gender',
  [Copy.gender_mask_text]: 'Gender',
  [Copy.gender_invalid_value_validation]: 'Enter a valid gender',
  [Copy.gender_missing_value_validation]: 'Gender required',
  [Copy.gender_option_male]: 'Male',
  [Copy.gender_option_female]: 'Female',
  [Copy.gender_option_other]: 'Other',
  [Copy.submit_button_text]: 'Submit',
  [Copy.field_required_label]: 'Required',
  [Copy.field_optional_label]: 'Optional',
  [Copy.picklist_mask_general]: 'Select...',
  [Copy.thank_you_message]: 'Thank you!',

  [Copy.self_onboarding_get_started_text]: 'To get started, please choose your preferred language',
  [Copy.self_onboarding_error_heading]: 'Sorry, something went wrong',
  [Copy.self_onboarding_error_text]:
    'There may be an issue with the information you provided: please check with your on-site supervisor or jobsite staff to complete orientation.',
  [Copy.self_onboarding_profile_header]: 'Your Profile',
  [Copy.self_onboarding_profile_instructions]:
    'Please complete the information below to get started. All fields are required unless marked “Optional”.',
  [Copy.self_onboarding_matched_worker_heading]: "You're all set!",
  [Copy.self_onboarding_matched_worker_text]:
    'It looks like you may already have an Odin profile. Please check with your on-site supervisor or jobsite staff to complete orientation.',
  [Copy.self_onboarding_emergency_contact_instructions]: 'Please let us know who to contact during an emergency.',
  [Copy.self_onboarding_government_issued_id_header]: 'Government Issued ID',
  [Copy.self_onboarding_government_issued_id_instructions]:
    'Please upload your Drivers License or other official ID that includes your full name and photo.',
  [Copy.front_file_label]: 'Add Front',
  [Copy.back_file_label]: 'Add Back',
  [Copy.id_type_label]: 'ID Type',
  [Copy.state_issued_label]: 'State Issued',
  [Copy.government_id_number_label]: 'ID Number',
  [Copy.issue_date_label]: 'Issue Date',
  [Copy.expiration_date_label]: 'Expiration Date',
  [Copy.self_onboarding_osha_card_header]: 'OSHA Card',
  [Copy.self_onboarding_osha_card_instructions]: 'Please upload your OSHA Card.',
  [Copy.card_type_label]: 'Card Type',
  [Copy.course_date_label]: 'Course Date',
  [Copy.osha_card_number_label]: 'ID Number',
  [Copy.self_onboarding_ny_sst_card_header]: 'NYC Site Safety Training (SST) Card',
  [Copy.self_onboarding_ny_sst_card_instructions]: 'Please upload your NY SST Card.',
  [Copy.training_connect_card_label]: 'Training Connect Card',
  [Copy.self_onboarding_profile_photo_header]: 'Your Profile Photo',
  [Copy.self_onboarding_profile_photo_instructions]:
    "One more thing! Please take a photo of yourself using your device's camera.",
  [Copy.take_photo]: 'Take photo',
  [Copy.self_onboarding_profile_photo_direction_1]: 'Look straight into the camera',
  [Copy.self_onboarding_profile_photo_direction_2]: 'Include your full head and shoulders',
  [Copy.self_onboarding_profile_photo_direction_3]: 'No hats, sunglasses, or other obstructions',
  [Copy.self_onboarding_profile_photo_direction_4]: 'No smiling or other facial expressions',
  [Copy.self_onboarding_profile_photo_help_text]:
    'If you are unable to take a photo meeting the requirements, you will be photographed on site.',
  [Copy.self_onboarding_verify_phone_header]: 'Verify Mobile Device',
  [Copy.self_onboarding_verify_phone_instructions]:
    'With Odin, you can use your mobile device to clock in and out of the jobsite. To get started, we need to verify your device via text message.',
  [Copy.self_onboarding_verify_phone_instructions_no_mobile_app]:
    'With Odin, you may receive texts if critical information needs to be shared about the jobsite. To get started, we need to verify your device via text message.',
  [Copy.self_onboarding_verify_phone_help_text]: 'Please use the number associated with this device.',
  [Copy.self_onboarding_verify_phone_to_sign_in_header]: 'Verification needed',
  [Copy.self_onboarding_verify_phone_to_sign_in_instructions]:
    'Welcome! It looks like you may already have an Odin profile. Please follow the instructions below to continue.',
  [Copy.self_onboarding_verify_phone_to_sign_in_action]: 'Send text to number ending in',
  [Copy.self_onboarding_verify_phone_to_sign_in_skip]: 'My number has changed',
  [Copy.self_onboarding_confirm_phone_time_to_complete]: '{{time}} to complete',
  [Copy.self_onboarding_review_completed_profile_header]: 'Download the Odin App',
  [Copy.self_onboarding_review_completed_profile_instructions]:
    'Get started with the Odin Construction App: clock in and out of the jobsite, update your worker profile, and view your jobsites from your mobile phone',
  [Copy.self_onboarding_unable_to_verify_account_header]: 'Unable to verify account',
  [Copy.self_onboarding_unable_to_verify_account_instructions]:
    'Please show this screen to the on-site team to finalize onboarding and get to work',
  [Copy.add_mobile_badge]: 'Add Mobile Badge',
  [Copy.download_odin_app]: 'Download Odin App',
  [Copy.worker_info]: 'Worker Info',
  [Copy.documents]: 'Documents',
  [Copy.self_onboarding_no_osha_card]: 'No OSHA Card was uploaded',
  [Copy.self_onboarding_no_ny_sst_card]: 'No NY SST Card was uploaded',
  [Copy.self_onboarding_no_government_id]: 'No Goverment ID was uploaded',
  [Copy.self_onboarding_worker_match_instructions]:
    "We're here to gather everything that's required to be a worker on this site. To get started, please enter the following information.",
  [Copy.continue]: 'Continue',
  [Copy.skip_for_now]: 'Skip for now',
  [Copy.save]: 'Save',
  [Copy.cancel]: 'Cancel',
  [Copy.self_onboarding_emergency_contact_header]: 'Emergency Contact',
  [Copy.self_onboarding_confirm_phone_title]: 'Please enter the code you received',
  [Copy.self_onboarding_confirm_phone_instruction]:
    "You should have received a 6 digit code to the phone number you provided. If you haven't received the code after a few minutes, you can",
  [Copy.self_onboarding_confirm_phone_request_another_code]: 'request another code here.',
  [Copy.self_onboarding_confirm_phone_code_not_received]: 'I never received my code',
  [Copy.add]: 'Add',
  [Copy.self_onboarding_jobsite_safety_video_header]: 'Worker Safety Video Acknowledgement',
  [Copy.self_onboarding_jobsite_safety_video_instructions]:
    'Please watch the following video to complete your training',
  [Copy.self_onboarding_jobsite_safety_video_not_acknowledged]: 'Site safety video has not been watched',
  [Copy.self_onboarding_jobsite_safety_document_header]: 'Site Safety Training',
  [Copy.self_onboarding_jobsite_safety_document_instructions]:
    'Please read the following document to complete your training.',
  [Copy.self_onboarding_jobsite_safety_document_not_acknowledged]: 'Site safety training document has not been read',
  [Copy.self_onboarding_lirr_blue_training_header]: 'LIRR Blue Training',
  [Copy.self_onboarding_lirr_blue_training_instructions]: 'Please upload your LIRR Blue Training document.',
  [Copy.self_onboarding_no_lirr_blue_training]: 'No LIRR Blue Training document was uploaded',
  [Copy.self_onboarding_confined_spaces_training_header]: 'Confined Spaces Training',
  [Copy.self_onboarding_confined_spaces_training_instructions]: 'Please upload your Confined Spaces Training document.',
  [Copy.self_onboarding_no_confined_spaces_training]: 'No Confined Spaces Training document was uploaded',
  [Copy.self_onboarding_worker_consent_header]: 'Worker Consent Document',
  [Copy.self_onboarding_worker_consent_instructions]:
    'Please read this document in its entirety and provide your consent.',
  [Copy.self_onboarding_worker_consent_text_start]: 'By checking this box, I acknowledge that I have read the',
  [Copy.self_onboarding_worker_consent_text_end]: 'document in its entirety and agree to its contents.',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_label]: 'I have read the document',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_description]:
    'By checking this box, I acknowledge that I have read the training document in its entirety and understand its contents.',
  [Copy.self_onboarding_acknowledgment_video_confirmed_label]: 'I have watched the video',
  [Copy.self_onboarding_acknowledgment_video_confirmed_description]:
    'By checking this box, I acknowledge that I have viewed the training video in its entirety and understand its contents.',
  [Copy.self_onboarding_acknowledgment_video_not_started_label]: 'I have watched the video',
  [Copy.self_onboarding_acknowledgment_video_not_started_disabled_description]: 'Press play to begin',
  [Copy.self_onboarding_acknowledgment_video_in_progress_label]: 'I have watched the video',
};

const es: Record<Copy, string> = {
  [Copy.language]: 'Español',
  [Copy.complete_registration]: 'Registro completo',
  [Copy.language_selector_label]: 'Idioma del formulario',
  [Copy.self_onboarding_worker_match_header]: '¡Bienvenido a Odin!',
  [Copy.preonboarding_sub_heading_step_1]:
    'Has recibido este enlace porque vas a trabajar en una obra de Odin. Si introduces tus datos aquí antes de llegar, ahorrarás tiempo en el remolque. Estamos comprometidos con tu seguridad.',
  [Copy.preonboarding_step_1_instructions]: 'Primero necesitamos confirmar tu identidad:',
  [Copy.preonboarding_heading_step_2]: 'Para completar tu perfil, por favor, proporciona la siguiente información',
  [Copy.preonboarding_heading_step_3]:
    'Genial, ¡sólo un paso más! Por favor, proporciona un contacto para emergencias.',
  [Copy.preonboarding_heading_step_4]: '¡Felicidades!',
  [Copy.preonboarding_sub_heading_step_4]:
    'Gracias por completar tu perfil. El gerente en el sitio de su lugar de trabajo lo ayudará a terminar su orientación.',
  [Copy.preonboarding_message_phone_match_issue]: 'El número de teléfono no coincide. Por favor, inténtalo de nuevo.',
  [Copy.preonboarding_message_worker_match]: 'Parece que ya has trabajado con Odin.',
  [Copy.preonboarding_message_phone_prompt]:
    'Para confirmar tu identidad, por favor, proporciona tu número de teléfono:',
  [Copy.preonboarding_message_existing_profile]:
    'Según la información que ha proporcionado, es posible que ya tenga un perfil en Odin. Si es así, podrá saltarse este proceso y pasar por la orientación acelerada cuando llegue a su lugar de trabajo.',
  [Copy.preonboarding_message_email_match_issue]: 'El correo electrónico no coincide. Por favor, inténtalo de nuevo.',
  [Copy.preonboarding_message_email_prompt]:
    'Para confirmar tu identidad, por favor, proporciona el correo electrónico de tu cuenta:',
  [Copy.preonboarding_message_expired_link_prompt]:
    '¡Bienvenido a Odin! Lamentablemente, no hemos podido encontrar la página que buscaba. Es posible que el enlace que se le proporcionó haya caducado. Por favor, contacta con tu gestor para que te ayude.',
  [Copy.preonboarding_message_worker_match_no_action]:
    'Parece que este trabajador ya existe en Odin: no es necesario añadir ninguna información en este momento.',
  [Copy.preonboarding_message_next_steps]:
    'Los datos del trabajador pueden confirmarse y actualizarse durante la incorporación al puesto de trabajo el primer día.',
  [Copy.worker_onboarding_header]: 'Información personal',
  [Copy.full_name_label]: 'Nombre',
  [Copy.first_name_label]: 'Nombre',
  [Copy.first_name_mask_text]: 'Nombre',
  [Copy.first_name_invalid_value_validation]: 'Introduce un nombre válido',
  [Copy.first_name_missing_value_validation]: 'Nombre requerido',
  [Copy.last_name_label]: 'Apellido',
  [Copy.last_name_mask_text]: 'Apellido',
  [Copy.last_name_invalid_value_validation]: 'Introduce un apellido válido',
  [Copy.last_name_missing_value_validation]: 'Apellido requerido',
  [Copy.middle_initial_label]: 'Inicial del medio',
  [Copy.middle_initial_mask_text]: 'Inicial del medio',
  [Copy.middle_initial_invalid_value_validation]: 'Introduce una inicial del medio válida',
  [Copy.middle_initial_missing_value_validation]: 'Se requiere la inicial del medio',
  [Copy.birth_date_label]: 'Fecha de nacimiento',
  [Copy.birth_date_mask_text]: 'MES/DÍA/AÑO',
  [Copy.birth_date_invalid_value_validation]: 'Introduce una fecha válida',
  [Copy.birth_date_missing_value_validation]: 'Fecha de nacimiento requerida',
  [Copy.email_label]: 'Dirección de correo electrónico',
  [Copy.email_mask_text]: 'Dirección de correo electrónico',
  [Copy.email_invalid_value_validation]: 'Introduce un correo electrónico válido',
  [Copy.email_missing_value_validation]: 'Correo electrónico requerido',
  [Copy.phone_number_label]: 'Teléfono móvil',
  [Copy.phone_number_mask_text]: 'Teléfono móvil',
  [Copy.phone_number_invalid_value_validation]: 'Introduce un número de teléfono válido',
  [Copy.phone_number_missing_value_validation]: 'Número de teléfono requerido',
  [Copy.address_line_1_label]: 'Dirección',
  [Copy.address_line_1_mask_text]: 'Introduce una dirección',
  [Copy.address_line_1_invalid_value_validation]: 'Introduce una dirección válida',
  [Copy.address_line_1_missing_value_validation]: 'Dirección requerida',
  [Copy.address_line_2_label]: 'Línea de dirección 2',
  [Copy.address_line_2_mask_text]: 'Línea de dirección 2',
  [Copy.address_line_2_invalid_value_validation]: 'Introduce una dirección válida línea 2',
  [Copy.address_line_2_missing_value_validation]: 'Línea de dirección 2 requerida',
  [Copy.address_city_label]: 'Ciudad',
  [Copy.address_city_mask_text]: 'Ciudad',
  [Copy.address_city_invalid_value_validation]: 'Introduce una ciudad válida',
  [Copy.address_city_missing_value_validation]: 'Ciudad requerida',
  [Copy.address_state_label]: 'Estado',
  [Copy.address_state_missing_value_validation]: 'Estado requerido',
  [Copy.address_zip_code_label]: 'Código postal',
  [Copy.address_zip_code_mask_text]: 'Código postal',
  [Copy.address_zip_code_invalid_value_validation]: 'Introduce un código postal válido',
  [Copy.address_zip_code_missing_value_validation]: 'Código postal requerido',
  [Copy.primary_language_label]: 'Idioma preferido',
  [Copy.ssn_last_four_label]: 'Últimos 4 dígitos del número de la Seguridad Social',
  [Copy.ssn_last_four_mask_text]: 'Últimos 4 del número de la Seguridad Social',
  [Copy.ssn_last_four_invalid_value_validation]: 'Introduce un número válido',
  [Copy.ssn_last_four_missing_value_validation]: 'Se requieren los 4 últimos dígitos',
  [Copy.emergency_contact_name_label]: 'Nombre del contacto de emergencia',
  [Copy.emergency_contact_name_mask_text]: 'Nombre del contacto de emergencia',
  [Copy.emergency_contact_name_invalid_value_validation]: 'Introduce un nombre de contacto de emergencia válido',
  [Copy.emergency_contact_name_missing_value_validation]: 'Nombre del contacto de emergencia requerido',
  [Copy.emergency_contact_relationship_label]: 'Relación del contacto de emergencia',
  [Copy.emergency_contact_relationship_mask_text]: 'Relación del contacto de emergencia',
  [Copy.emergency_contact_relationship_invalid_value_validation]:
    'Introduce una relación de contacto de emergencia válida',
  [Copy.emergency_contact_relationship_missing_value_validation]: 'Se requiere una relación de contacto de emergencia',
  [Copy.emergency_contact_relationship_option_boyfriend]: 'Novio',
  [Copy.emergency_contact_relationship_option_child]: 'Hijo',
  [Copy.emergency_contact_relationship_option_co_worker]: 'Compañero de trabajo',
  [Copy.emergency_contact_relationship_option_family]: 'Familia',
  [Copy.emergency_contact_relationship_option_girlfriend]: 'Novia',
  [Copy.emergency_contact_relationship_option_grandparent]: 'Abuelo/a',
  [Copy.emergency_contact_relationship_option_friend]: 'Amigo',
  [Copy.emergency_contact_relationship_option_parent]: 'Padre o madre',
  [Copy.emergency_contact_relationship_option_partner]: 'Pareja',
  [Copy.emergency_contact_relationship_option_spouse]: 'Cónyuge',
  [Copy.emergency_contact_relationship_option_other]: 'Otro',
  [Copy.emergency_contact_phone_label]: 'Teléfono de contacto de emergencia',
  [Copy.emergency_contact_phone_mask_text]: 'Teléfono de contacto de emergencia',
  [Copy.emergency_contact_phone_invalid_value_validation]: 'Introduce un número de teléfono válido',
  [Copy.emergency_contact_phone_missing_value_validation]: 'Número de teléfono requerido',
  [Copy.trade_label]: 'Oficio',
  [Copy.trade_mask_text]: 'Oficio',
  [Copy.trade_invalid_value_validation]: 'Introduce un oficio válido',
  [Copy.trade_missing_value_validation]: 'Oficio requerido',
  [Copy.trade_option_bricklayer]: 'Albañil',
  [Copy.trade_option_carpenter]: 'Carpintero',
  [Copy.trade_option_cement_masons]: 'Albañiles de cemento',
  [Copy.trade_option_concrete_safety_manager]: 'Gerente de seguridad del hormigón',
  [Copy.trade_option_concrete_workers]: 'Trabajadores del hormigón',
  [Copy.trade_option_crane_operator]: 'Operador de grúa',
  [Copy.trade_option_demolition]: 'Demolición',
  [Copy.trade_option_dockbuilder]: 'Constructor de muelles',
  [Copy.trade_option_drill_operator]: 'Operador de perforación',
  [Copy.trade_option_driver]: 'Conductor',
  [Copy.trade_option_electrician]: 'Electricista',
  [Copy.trade_option_elevator]: 'Ascensor',
  [Copy.trade_option_engineer]: 'Ingeniero',
  [Copy.trade_option_executive]: 'Ejecutivo',
  [Copy.trade_option_excavators]: 'Excavadoras',
  [Copy.trade_option_fire_safety]: 'Seguridad contra incendios',
  [Copy.trade_option_flagger]: 'Señalador',
  [Copy.trade_option_flooring]: 'Piso',
  [Copy.trade_option_glazier]: 'Vidrio',
  [Copy.trade_option_hvac]: 'Climatización',
  [Copy.trade_option_inspector]: 'Inspector',
  [Copy.trade_option_insulator]: 'Aislante',
  [Copy.trade_option_ironworker]: 'Herrero',
  [Copy.trade_option_laborer]: 'Trabajador',
  [Copy.trade_option_lighting]: 'Iluminación',
  [Copy.trade_option_maintenance]: 'Mantenimiento',
  [Copy.trade_option_manager]: 'Gerente',
  [Copy.trade_option_masonry]: 'Albañilería',
  [Copy.trade_option_mechanic]: 'Mecánico',
  [Copy.trade_option_medical]: 'Médico',
  [Copy.trade_option_metal_lathers]: 'Espumas de metal',
  [Copy.trade_option_na]: 'NA',
  [Copy.trade_option_office]: 'Oficina',
  [Copy.trade_option_oiler]: 'Engrasador',
  [Copy.trade_option_operating_engineer]: 'Ingeniero de operaciones',
  [Copy.trade_option_operator]: 'Operador',
  [Copy.trade_option_painter]: 'Pintor',
  [Copy.trade_option_pipefitter]: 'Instalador de tuberías',
  [Copy.trade_option_plasterer]: 'Yesero',
  [Copy.trade_option_plumber]: 'Fontanero',
  [Copy.trade_option_project_manager]: 'Gerente de Proyecto',
  [Copy.trade_option_rebar]: 'Barra de refuerzo',
  [Copy.trade_option_rigger]: 'Aparejador',
  [Copy.trade_option_roofer]: 'Techador',
  [Copy.trade_option_safety]: 'Seguridad',
  [Copy.trade_option_scaffolding]: 'Andamiaje',
  [Copy.trade_option_sheetmetal]: 'Chapa',
  [Copy.trade_option_sprinkler]: 'Aspersor',
  [Copy.trade_option_steamfitter]: 'Instalador de vapor',
  [Copy.trade_option_steelworker]: 'Trabajador siderúrgico',
  [Copy.trade_option_superintendent]: 'Superintendente',
  [Copy.trade_option_supervisor]: 'Supervisor',
  [Copy.trade_option_surveyor]: 'Topógrafo',
  [Copy.trade_option_security]: 'Seguridad',
  [Copy.trade_option_tapers]: 'Tapers',
  [Copy.trade_option_teamsters]: 'Cameleros',
  [Copy.trade_option_telecommunications]: 'Telecomunicaciones',
  [Copy.trade_option_tiler]: 'Alicatador',
  [Copy.trade_option_timekeeper]: 'cronometrador',
  [Copy.trade_option_union_representative]: 'Representante sindical',
  [Copy.trade_option_waterproofer]: 'Impermeabilizante',
  [Copy.trade_option_welders]: 'Soldadores',
  [Copy.trade_option_other]: 'Otro',
  [Copy.job_title_label]: 'Título del trabajo',
  [Copy.job_title_mask_text]: 'Título del trabajo',
  [Copy.job_title_invalid_value_validation]: 'Introduce un título de trabajo válido',
  [Copy.job_title_missing_value_validation]: 'Título de trabajo requerido',
  [Copy.job_title_class_label]: 'Clase de título del trabajo',
  [Copy.job_title_class_mask_text]: 'Clase de título del trabajo',
  [Copy.job_title_class_invalid_value_validation]: 'Clase de título del trabajo requerido',
  [Copy.job_title_class_missing_value_validation]: 'Introduce una clase de título de trabajo válido',
  [Copy.job_title_option_apprentice]: 'Aprendiz',
  [Copy.job_title_option_foreman]: 'Capataz',
  [Copy.job_title_option_journeyman]: 'Journeyman',
  [Copy.job_title_option_laborer]: 'Trabajador',
  [Copy.job_title_option_medic]: 'Médico',
  [Copy.job_title_option_medic_supervisor]: 'Supervisor médico',
  [Copy.job_title_option_operating_engineer]: 'Ingeniero de operaciones',
  [Copy.job_title_option_provisional_apprentice]: 'Aprendiz provisional',
  [Copy.job_title_option_provisional_foreman]: 'Capataz provisional',
  [Copy.job_title_option_provisional_journeyman]: 'Oficial provisional',
  [Copy.job_title_option_shop_steward]: 'Delegado sindical',
  [Copy.job_title_option_supervisor]: 'Supervisor',
  [Copy.job_title_option_superintendent]: 'Superintendente',
  [Copy.job_title_option_office]: 'Oficina',
  [Copy.job_title_option_other]: 'Otro',
  [Copy.job_title_option_n_a]: 'N/A',
  [Copy.union_affiliation_label]: 'Afiliación sindical',
  [Copy.union_affiliation_mask_text]: 'Afiliación sindical',
  [Copy.union_affiliation_invalid_value_validation]: 'Introduce una afiliación sindical válida',
  [Copy.union_affiliation_missing_value_validation]: 'Afiliación sindical requerida',
  [Copy.citizenship_status_label]: 'Estado de ciudadanía',
  [Copy.citizenship_status_invalid_value_validation]: 'Seleccione un estado de ciudadanía válido',
  [Copy.citizenship_status_missing_value_validation]: 'Estado de ciudadanía requerido',
  [Copy.citizenship_status_option_citizen]: 'Ciudadano',
  [Copy.citizenship_status_option_non_citizen]: 'No ciudadano',
  [Copy.is_veteran_label]: 'Condición de veterano',
  [Copy.is_veteran_mask_text]: 'Condición de veterano',
  [Copy.is_veteran_invalid_value_validation]: 'Introduce una condición de veterano válida',
  [Copy.is_veteran_missing_value_validation]: 'Se requiere la condición de veterano',
  [Copy.is_veteran_option_veteran]: 'Veterano',
  [Copy.is_veteran_option_non_veteran]: 'No veterano',
  [Copy.contractor_label]: 'Contratista',
  [Copy.contractor_mask_text]: 'Contratista',
  [Copy.contractor_invalid_value_validation]: 'Introduce un contratista válido',
  [Copy.contractor_missing_value_validation]: 'Contratista requerido',
  [Copy.race_label]: 'Raza',
  [Copy.race_mask_text]: 'Raza',
  [Copy.race_invalid_value_validation]: 'Introduce una raza válida',
  [Copy.race_missing_value_validation]: 'Raza requerida',
  [Copy.race_option_asian_native_hawaiian_or_pacific_islander]: 'Asiático/nativo de Hawái o de las islas del Pacífico',
  [Copy.race_option_black_african_american]: 'Negro/Afroamericano',
  [Copy.race_option_native_american_alaskan_native]: 'Nativo americano/nativo de Alaska',
  [Copy.race_option_hispanic_latino]: 'Hispano / latino',
  [Copy.race_option_white]: 'Blanco',
  [Copy.race_option_other_prefer_not_to_respond]: 'Otro/Prefiero no responder',
  [Copy.gender_label]: 'Género',
  [Copy.gender_mask_text]: 'Género',
  [Copy.gender_invalid_value_validation]: 'Introduce un género válido',
  [Copy.gender_missing_value_validation]: 'Género requerido',
  [Copy.gender_option_male]: 'Hombre',
  [Copy.gender_option_female]: 'Mujer',
  [Copy.gender_option_other]: 'Otro',
  [Copy.submit_button_text]: 'Enviar',
  [Copy.field_required_label]: 'Obligatorio',
  [Copy.field_optional_label]: 'Opcional',
  [Copy.picklist_mask_general]: 'Seleccionar...',
  [Copy.thank_you_message]: '¡Gracias!',

  [Copy.self_onboarding_get_started_text]: 'Para empezar, elige tu idioma preferido',
  [Copy.self_onboarding_error_heading]: 'Lo sentimos, algo ha ido mal',
  [Copy.self_onboarding_error_text]:
    'Es posible que haya algún problema con la información que ha facilitado: consulte con su supervisor o con el personal del lugar de trabajo para completar la orientación.',
  [Copy.self_onboarding_profile_header]: 'Tu perfil',
  [Copy.self_onboarding_profile_instructions]:
    'Por favor, completa la siguiente información para empezar. Todos los campos son obligatorios a menos que estén marcados como "Opcional".',
  [Copy.self_onboarding_matched_worker_heading]: '¡Listo!',
  [Copy.self_onboarding_matched_worker_text]:
    'Parece que ya tienes un perfil de Odin. Consulta a tu supervisor o al personal de la obra para completar la orientación.',
  [Copy.self_onboarding_emergency_contact_instructions]:
    'Por favor, indícanos con quién contactar en caso de emergencia.',
  [Copy.self_onboarding_government_issued_id_header]: 'Documento de identidad expedido por el gobierno',
  [Copy.self_onboarding_government_issued_id_instructions]:
    'Adjunta tu carnet de conducir u otra identificación oficial que incluya tu nombre completo y foto.',
  [Copy.front_file_label]: 'Añadir anverso',
  [Copy.back_file_label]: 'Añadir reverso',
  [Copy.id_type_label]: 'Tipo de DNI',
  [Copy.state_issued_label]: 'Emitido por el Estado',
  [Copy.government_id_number_label]: 'Número de DNI',
  [Copy.issue_date_label]: 'Fecha de expedición',
  [Copy.expiration_date_label]: 'Fecha de caducidad',
  [Copy.self_onboarding_osha_card_header]: 'Tarjeta OSHA',
  [Copy.self_onboarding_osha_card_instructions]: 'Por favor, adjunta tu tarjeta OSHA.',
  [Copy.card_type_label]: 'Tipo de tarjeta',
  [Copy.course_date_label]: 'Fecha del curso',
  [Copy.osha_card_number_label]: 'Número ID',
  [Copy.self_onboarding_ny_sst_card_header]: 'Tarjeta de Formación en Seguridad en Obras (SST) de NYC',
  [Copy.self_onboarding_ny_sst_card_instructions]: 'Adjunta tu tarjeta NY SST.',
  [Copy.training_connect_card_label]: 'Tarjeta Training Connect',
  [Copy.self_onboarding_profile_photo_header]: 'Su foto de perfil',
  [Copy.self_onboarding_profile_photo_instructions]: '¡Una cosa más! Hazte una foto con la cámara de tu dispositivo.',
  [Copy.take_photo]: 'Hazte una foto',
  [Copy.self_onboarding_profile_photo_direction_1]: 'Mira directamente a la cámara',
  [Copy.self_onboarding_profile_photo_direction_2]: 'Incluye toda la cabeza y los hombros',
  [Copy.self_onboarding_profile_photo_direction_3]: 'Sin sombreros, gafas de sol u otros obstáculos.',
  [Copy.self_onboarding_profile_photo_direction_4]: 'Sin sonrisas ni otras expresiones faciales',
  [Copy.self_onboarding_profile_photo_help_text]:
    'Si no puedes hacerte una foto que cumpla los requisitos, se te fotografiará en las instalaciones.',
  [Copy.self_onboarding_verify_phone_header]: 'Verificar el dispositivo móvil',
  [Copy.self_onboarding_verify_phone_instructions]:
    'Con Odin, puedes utilizar tu dispositivo móvil para fichar a la entrada y a la salida del lugar de trabajo. Para empezar, necesitamos verificar tu dispositivo a través de un mensaje de texto.',
  [Copy.self_onboarding_verify_phone_instructions_no_mobile_app]:
    'Con Odin, puede recibir mensajes de texto si es necesario compartir información crítica sobre el lugar de trabajo. Para comenzar, necesitamos verificar su dispositivo mediante un mensaje de texto.',
  [Copy.self_onboarding_verify_phone_help_text]: 'Por favor, utiliza el número asociado a este dispositivo.',
  [Copy.self_onboarding_verify_phone_to_sign_in_header]: 'Se necesita verificación',
  [Copy.self_onboarding_verify_phone_to_sign_in_instructions]:
    '¡Bienvenido! Parece que es posible que ya tengas un perfil de Odin. Siga las instrucciones a continuación para continuar.',
  [Copy.self_onboarding_verify_phone_to_sign_in_action]: 'Enviar texto al número que termina en',
  [Copy.self_onboarding_verify_phone_to_sign_in_skip]: 'Mi número ha cambiado',
  [Copy.self_onboarding_confirm_phone_time_to_complete]: '{{time}} para completar',
  [Copy.self_onboarding_review_completed_profile_header]: 'Felicidades, ¡ya has terminado!',
  [Copy.self_onboarding_review_completed_profile_instructions]:
    'Muestra esta pantalla al equipo de la obra para finalizar la incorporación y ponte a trabajar.',
  [Copy.self_onboarding_unable_to_verify_account_header]: 'No se puede verificar la cuenta',
  [Copy.self_onboarding_unable_to_verify_account_instructions]:
    'Muestre esta pantalla al equipo en el sitio para finalizar la incorporación y ponerse a trabajar',
  [Copy.add_mobile_badge]: 'Añadir insignia móvil',
  [Copy.download_odin_app]: 'Descargar aplicación Odin',
  [Copy.worker_info]: 'Información del trabajador',
  [Copy.documents]: 'Documentos',
  [Copy.self_onboarding_no_osha_card]: 'No se ha adjuntado tarjeta OSHA',
  [Copy.self_onboarding_no_ny_sst_card]: 'No se ha adjuntado tarjeta NY SST',
  [Copy.self_onboarding_no_government_id]: 'No se ha adjuntado identificación gubernamental',
  [Copy.self_onboarding_worker_match_instructions]:
    'Estamos aquí para recopilar todo lo que se requiere para ser un trabajador en este sitio. Para comenzar, ingrese la siguiente información.',
  [Copy.continue]: 'Continuar',
  [Copy.skip_for_now]: 'Saltar por ahora',
  [Copy.save]: 'Guardar',
  [Copy.cancel]: 'Cancelar',
  [Copy.self_onboarding_emergency_contact_header]: 'Contacto de emergencia',
  [Copy.self_onboarding_confirm_phone_title]: 'Por favor, introduce el código que has recibido',
  [Copy.self_onboarding_confirm_phone_instruction]:
    'Deberías haber recibido un código de 6 dígitos en el número de teléfono que proporcionaste. Si no ha recibido el código después de unos minutos, puede',
  [Copy.self_onboarding_confirm_phone_request_another_code]: 'solicitar otro código aquí.',
  [Copy.self_onboarding_confirm_phone_code_not_received]: 'Nunca recibí mi código',
  [Copy.add]: 'Agregar',
  [Copy.self_onboarding_jobsite_safety_video_header]: 'Video de reconocimiento sobre seguridad del trabajador',
  [Copy.self_onboarding_jobsite_safety_video_instructions]:
    'Por favor, mira el siguiente vídeo para completar tu formación',
  [Copy.self_onboarding_jobsite_safety_video_not_acknowledged]: 'No se ha visto el vídeo de seguridad del sitio',
  [Copy.self_onboarding_jobsite_safety_document_header]: 'Capacitación sobre seguridad en el sitio',
  [Copy.self_onboarding_jobsite_safety_document_instructions]:
    'Lea el siguiente documento para completar su formación.',
  [Copy.self_onboarding_jobsite_safety_document_not_acknowledged]:
    'No se ha leído el documento de formación sobre seguridad en el lugar',
  [Copy.self_onboarding_lirr_blue_training_header]: 'Entrenamiento azul LIRR',
  [Copy.self_onboarding_lirr_blue_training_instructions]: 'Cargue su documento de capacitación LIRR Blue',
  [Copy.self_onboarding_no_lirr_blue_training]: 'No se cargó ningún documento de capacitación LIRR Blue',
  [Copy.self_onboarding_confined_spaces_training_header]: 'Entrenamiento en espacios confinados',
  [Copy.self_onboarding_confined_spaces_training_instructions]:
    'Cargue su documento de capacitación en espacios confinados',
  [Copy.self_onboarding_no_confined_spaces_training]:
    'No se cargó ningún documento de capacitación en espacios confinados',
  [Copy.self_onboarding_worker_consent_header]: 'Documento de Consentimiento del Trabajador',
  [Copy.self_onboarding_worker_consent_instructions]:
    'Por favor, lea este documento en su totalidad y proporcione su consentimiento.',
  [Copy.self_onboarding_worker_consent_text_start]: 'Al marcar esta casilla, reconozco que he leído el',
  [Copy.self_onboarding_worker_consent_text_end]: 'documento en su totalidad y estoy de acuerdo con su contenido.',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_label]: 'He leído el documento',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_description]:
    'Al marcar esta casilla, reconozco que he leído el documento de capacitación en su totalidad y entiendo su contenido.',
  [Copy.self_onboarding_acknowledgment_video_confirmed_label]: 'He visto el vídeo',
  [Copy.self_onboarding_acknowledgment_video_confirmed_description]:
    'Al marcar esta casilla, reconozco que he visto el video de capacitación en su totalidad y entiendo su contenido.',
  [Copy.self_onboarding_acknowledgment_video_not_started_label]: 'He visto el vídeo',
  [Copy.self_onboarding_acknowledgment_video_not_started_disabled_description]: 'Presiona reproducir para comenzar',
  [Copy.self_onboarding_acknowledgment_video_in_progress_label]: 'He visto el vídeo',
};

const zhCN: Record<Copy, string> = {
  [Copy.language]: '简体中文',
  [Copy.complete_registration]: '完成注册',
  [Copy.language_selector_label]: '表单语言',
  [Copy.self_onboarding_worker_match_header]: '欢迎来到奥丁！',
  [Copy.preonboarding_sub_heading_step_1]:
    '您收到此链接是因为您将在奥丁建筑工地工作。开始之前,请在此处输入您的信息,以节省您的时间。我们致力于保障您的上网安全。',
  [Copy.preonboarding_step_1_instructions]: '首先我们需要确认您的身份：',
  [Copy.preonboarding_heading_step_2]: '为了完成您的个人资料,请提供以下信息',
  [Copy.preonboarding_heading_step_3]: '太棒了,最后一步！ 请提供紧急情况的联系方式。',
  [Copy.preonboarding_heading_step_4]: '祝贺您！',
  [Copy.preonboarding_sub_heading_step_4]: '感谢您完成个人资料。您工作现场的现场经理将帮助您完成入职培训。',
  [Copy.preonboarding_message_phone_match_issue]: '电话号码不匹配。请再试一次。',
  [Copy.preonboarding_message_worker_match]: '您似乎以前曾与奥丁合作过',
  [Copy.preonboarding_message_phone_prompt]: '为了确认您的身份,请提供您的电话号码：',
  [Copy.preonboarding_message_existing_profile]:
    '根据您提供的信息,您可能已经完成了 奥丁个人资料。如果确实如此,当您到达建筑工地时,您可跳过此过程并完成快速指导。',
  [Copy.preonboarding_message_email_match_issue]: '电子邮件不匹配。请再试一次。',
  [Copy.preonboarding_message_email_prompt]: '为了确认您的身份,请提供您的帐户电子邮件：',
  [Copy.preonboarding_message_expired_link_prompt]:
    '欢迎来到奥丁！ 很遗憾,我们无法找到您要查找的页面。您获得的链接可能已过期。请联系您的经理以获得进一步的帮助。',
  [Copy.preonboarding_message_worker_match_no_action]: '看起来该名工人已注册奥丁：此时不需要添加任何信息。',
  [Copy.preonboarding_message_next_steps]: '在建筑工地首次入职时,可以确认和更新工人的详细信息。',
  [Copy.worker_onboarding_header]: '个人信息',
  [Copy.full_name_label]: '姓名',
  [Copy.first_name_label]: '名',
  [Copy.first_name_mask_text]: '名',
  [Copy.first_name_invalid_value_validation]: '请输入一个有效的名字',
  [Copy.first_name_missing_value_validation]: '名字必填',
  [Copy.last_name_label]: '姓',
  [Copy.last_name_mask_text]: '姓',
  [Copy.last_name_invalid_value_validation]: '请输入一个有效的姓氏',
  [Copy.last_name_missing_value_validation]: '姓氏必填',
  [Copy.middle_initial_label]: '中间名首字母',
  [Copy.middle_initial_mask_text]: '中间名首字母',
  [Copy.middle_initial_invalid_value_validation]: '请输入一个有效的中间名首字母',
  [Copy.middle_initial_missing_value_validation]: '中间名首字母必填',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '请输入一个有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.email_label]: '电子邮件地址',
  [Copy.email_mask_text]: '电子邮件地址',
  [Copy.email_invalid_value_validation]: '请输入一个有效的电子邮件',
  [Copy.email_missing_value_validation]: '电子邮件必填',
  [Copy.phone_number_label]: '手機',
  [Copy.phone_number_mask_text]: '手機',
  [Copy.phone_number_invalid_value_validation]: '请输入一个有效的电话号码',
  [Copy.phone_number_missing_value_validation]: '电话号码必填',
  [Copy.address_line_1_label]: '地址',
  [Copy.address_line_1_mask_text]: '请输入一个地址',
  [Copy.address_line_1_invalid_value_validation]: '请输入一个有效的地址',
  [Copy.address_line_1_missing_value_validation]: '地址必填',
  [Copy.address_line_2_label]: '地址行 2',
  [Copy.address_line_2_mask_text]: '地址行 2',
  [Copy.address_line_2_invalid_value_validation]: '请输入一个有效的地址行 2',
  [Copy.address_line_2_missing_value_validation]: '地址行 2 必填',
  [Copy.address_city_label]: '城市',
  [Copy.address_city_mask_text]: '城市',
  [Copy.address_city_invalid_value_validation]: '请输入一个有效的城市',
  [Copy.address_city_missing_value_validation]: '城市必填',
  [Copy.address_state_label]: '州',
  [Copy.address_state_missing_value_validation]: '州必填',
  [Copy.address_zip_code_label]: '邮政编码',
  [Copy.address_zip_code_mask_text]: '邮政编码',
  [Copy.address_zip_code_invalid_value_validation]: '请输入一个有效的邮政编码',
  [Copy.address_zip_code_missing_value_validation]: '邮政编码必填',
  [Copy.primary_language_label]: '首选语言',
  [Copy.ssn_last_four_label]: '社会保险号的最后 4 位数字',
  [Copy.ssn_last_four_mask_text]: '社会安全号码的最后 4 位数字',
  [Copy.ssn_last_four_invalid_value_validation]: '请输入一个有效的数字',
  [Copy.ssn_last_four_missing_value_validation]: '最后 4 位数字必填',
  [Copy.emergency_contact_name_label]: '紧急联络名字',
  [Copy.emergency_contact_name_mask_text]: '紧急联络姓名',
  [Copy.emergency_contact_name_invalid_value_validation]: '请输入有效的紧急联系人姓名',
  [Copy.emergency_contact_name_missing_value_validation]: '紧急联系人姓名必填',
  [Copy.emergency_contact_relationship_label]: '紧急联系人关系',
  [Copy.emergency_contact_relationship_mask_text]: '紧急联系人关系',
  [Copy.emergency_contact_relationship_invalid_value_validation]: '请输入有效的紧急联系人关系',
  [Copy.emergency_contact_relationship_missing_value_validation]: '紧急联系人关系必填',
  [Copy.emergency_contact_relationship_option_boyfriend]: '男朋友',
  [Copy.emergency_contact_relationship_option_child]: '子女',
  [Copy.emergency_contact_relationship_option_co_worker]: '同事',
  [Copy.emergency_contact_relationship_option_family]: '家人',
  [Copy.emergency_contact_relationship_option_girlfriend]: '女朋友',
  [Copy.emergency_contact_relationship_option_grandparent]: '祖父母',
  [Copy.emergency_contact_relationship_option_friend]: '朋友',
  [Copy.emergency_contact_relationship_option_parent]: '家长',
  [Copy.emergency_contact_relationship_option_partner]: '合作伙伴',
  [Copy.emergency_contact_relationship_option_spouse]: '伴侣',
  [Copy.emergency_contact_relationship_option_other]: '其他',
  [Copy.emergency_contact_phone_label]: '紧急联络电话',
  [Copy.emergency_contact_phone_mask_text]: '紧急联络电话',
  [Copy.emergency_contact_phone_invalid_value_validation]: '请输入一个有效的电话号码',
  [Copy.emergency_contact_phone_missing_value_validation]: '电话号码必填',
  [Copy.trade_label]: '交易',
  [Copy.trade_mask_text]: '交易',
  [Copy.trade_invalid_value_validation]: '请输入一个有效的交易',
  [Copy.trade_missing_value_validation]: '交易必填',
  [Copy.trade_option_bricklayer]: '瓦工',
  [Copy.trade_option_carpenter]: '木匠',
  [Copy.trade_option_cement_masons]: '水泥泥瓦匠',
  [Copy.trade_option_concrete_safety_manager]: '混凝土安全经理',
  [Copy.trade_option_concrete_workers]: '混凝土工人',
  [Copy.trade_option_crane_operator]: '起重机操作员',
  [Copy.trade_option_demolition]: '拆除',
  [Copy.trade_option_dockbuilder]: '码头建造者',
  [Copy.trade_option_drill_operator]: '钻探操作员',
  [Copy.trade_option_driver]: '驱动程序',
  [Copy.trade_option_electrician]: '电工',
  [Copy.trade_option_elevator]: '电梯',
  [Copy.trade_option_engineer]: '工程师',
  [Copy.trade_option_executive]: '执行',
  [Copy.trade_option_excavators]: '挖掘机',
  [Copy.trade_option_fire_safety]: '消防安全',
  [Copy.trade_option_flagger]: '标记',
  [Copy.trade_option_flooring]: '地板',
  [Copy.trade_option_glazier]: '玻璃',
  [Copy.trade_option_hvac]: '暖通空调',
  [Copy.trade_option_inspector]: '检查员',
  [Copy.trade_option_insulator]: '绝缘体',
  [Copy.trade_option_ironworker]: '钢铁工人',
  [Copy.trade_option_laborer]: '劳动者',
  [Copy.trade_option_lighting]: '照明',
  [Copy.trade_option_maintenance]: '维护',
  [Copy.trade_option_manager]: '经理',
  [Copy.trade_option_masonry]: '砌体',
  [Copy.trade_option_mechanic]: '机械师',
  [Copy.trade_option_medical]: '医疗',
  [Copy.trade_option_metal_lathers]: '金属泡沫',
  [Copy.trade_option_na]: '不适用',
  [Copy.trade_option_office]: '办公室',
  [Copy.trade_option_oiler]: '加油',
  [Copy.trade_option_operating_engineer]: '操作工程师',
  [Copy.trade_option_operator]: '操作员',
  [Copy.trade_option_painter]: '画家',
  [Copy.trade_option_pipefitter]: '管道安装器',
  [Copy.trade_option_plasterer]: '抹灰工',
  [Copy.trade_option_plumber]: '水管工',
  [Copy.trade_option_project_manager]: '项目经理',
  [Copy.trade_option_rebar]: '钢筋',
  [Copy.trade_option_rigger]: '装配工',
  [Copy.trade_option_roofer]: '屋顶工',
  [Copy.trade_option_safety]: '安全',
  [Copy.trade_option_scaffolding]: '脚手架',
  [Copy.trade_option_sheetmetal]: '钣金件',
  [Copy.trade_option_sprinkler]: '洒水器',
  [Copy.trade_option_steamfitter]: 'Steamfitter',
  [Copy.trade_option_steelworker]: '钢铁工人',
  [Copy.trade_option_superintendent]: '主管',
  [Copy.trade_option_supervisor]: '主管',
  [Copy.trade_option_surveyor]: '测量员',
  [Copy.trade_option_security]: '安全',
  [Copy.trade_option_tapers]: '锥度',
  [Copy.trade_option_teamsters]: '卡车司机',
  [Copy.trade_option_telecommunications]: '电信',
  [Copy.trade_option_tiler]: '瓷砖',
  [Copy.trade_option_timekeeper]: '计时器',
  [Copy.trade_option_union_representative]: '工会代表',
  [Copy.trade_option_waterproofer]: '防水剂',
  [Copy.trade_option_welders]: '焊工',
  [Copy.trade_option_other]: '其他',
  [Copy.job_title_label]: '职位名称',
  [Copy.job_title_mask_text]: '职位名称',
  [Copy.job_title_invalid_value_validation]: '请输入一个有效的职位名称',
  [Copy.job_title_missing_value_validation]: '职位名称必填',
  [Copy.job_title_class_label]: '职称类别',
  [Copy.job_title_class_mask_text]: '职称类别',
  [Copy.job_title_class_invalid_value_validation]: '职称类别必填',
  [Copy.job_title_class_missing_value_validation]: '请输入一个有效的职位类别',
  [Copy.job_title_option_apprentice]: '学徒',
  [Copy.job_title_option_foreman]: '工头',
  [Copy.job_title_option_journeyman]: '熟练工',
  [Copy.job_title_option_laborer]: '劳动者',
  [Copy.job_title_option_medic]: '医生',
  [Copy.job_title_option_medic_supervisor]: '医务主管',
  [Copy.job_title_option_operating_engineer]: '操作工程师',
  [Copy.job_title_option_provisional_apprentice]: '临时学徒',
  [Copy.job_title_option_provisional_foreman]: '临时工头',
  [Copy.job_title_option_provisional_journeyman]: '临时熟练工',
  [Copy.job_title_option_shop_steward]: '商店管家',
  [Copy.job_title_option_supervisor]: '主管',
  [Copy.job_title_option_superintendent]: '主管',
  [Copy.job_title_option_office]: '办公室',
  [Copy.job_title_option_other]: '其他',
  [Copy.job_title_option_n_a]: '不适用',
  [Copy.union_affiliation_label]: '工会隶属关系',
  [Copy.union_affiliation_mask_text]: '工会隶属关系',
  [Copy.union_affiliation_invalid_value_validation]: '请输入一个有效的工会附属机构',
  [Copy.union_affiliation_missing_value_validation]: '工会附属机构必填',
  [Copy.citizenship_status_label]: '公民身份',
  [Copy.citizenship_status_invalid_value_validation]: '选择有效的公民身份',
  [Copy.citizenship_status_missing_value_validation]: '需要公民身份',
  [Copy.citizenship_status_option_citizen]: '公民',
  [Copy.citizenship_status_option_non_citizen]: '非公民',
  [Copy.is_veteran_label]: '退伍军人身份',
  [Copy.is_veteran_mask_text]: '退伍军人身份',
  [Copy.is_veteran_invalid_value_validation]: '请输入有效的退伍军人身份',
  [Copy.is_veteran_missing_value_validation]: '退伍军人身份必填',
  [Copy.is_veteran_option_veteran]: '退伍军人',
  [Copy.is_veteran_option_non_veteran]: '非退伍军人',
  [Copy.contractor_label]: '承包商',
  [Copy.contractor_mask_text]: '承包商',
  [Copy.contractor_invalid_value_validation]: '请输入有效的承包商',
  [Copy.contractor_missing_value_validation]: '承包商必填',
  [Copy.race_label]: '种族',
  [Copy.race_mask_text]: '种族',
  [Copy.race_invalid_value_validation]: '请输入有效的种族',
  [Copy.race_missing_value_validation]: '种族必填',
  [Copy.race_option_asian_native_hawaiian_or_pacific_islander]: '亚洲/夏威夷原住民或太平洋岛民',
  [Copy.race_option_black_african_american]: '黑人/非裔美国人',
  [Copy.race_option_native_american_alaskan_native]: '美洲原住民/阿拉斯加原住民',
  [Copy.race_option_hispanic_latino]: '西班牙裔/拉丁裔',
  [Copy.race_option_white]: '白色的',
  [Copy.race_option_other_prefer_not_to_respond]: '其他/不想回应',
  [Copy.gender_label]: '性别',
  [Copy.gender_mask_text]: '性别',
  [Copy.gender_invalid_value_validation]: '请输入有效的性别',
  [Copy.gender_missing_value_validation]: '性别必填',
  [Copy.gender_option_male]: '男性',
  [Copy.gender_option_female]: '女性',
  [Copy.gender_option_other]: '其他',
  [Copy.submit_button_text]: '提交',
  [Copy.field_required_label]: '必填',
  [Copy.field_optional_label]: '可选',
  [Copy.picklist_mask_general]: '选择...',
  [Copy.thank_you_message]: '谢谢！',

  [Copy.self_onboarding_get_started_text]: '首先,请选择您的首选语言',
  [Copy.self_onboarding_error_heading]: '抱歉,出了一些问题',
  [Copy.self_onboarding_error_text]: '您提供的信息可能有问题：请咨询您的现场主管或现场工作人员以完成入职培训。',
  [Copy.self_onboarding_profile_header]: '您的个人资料',
  [Copy.self_onboarding_profile_instructions]: '请完成以下信息,并开始使用。所有字段均为必填,除非标记为“可选”。',
  [Copy.self_onboarding_matched_worker_heading]: '一切就绪！',
  [Copy.self_onboarding_matched_worker_text]:
    '您可能已经有 Odin 个人资料。请与您的现场主管或现场工作人员核实以完成入职培训。',
  [Copy.self_onboarding_emergency_contact_instructions]: '请告知我们您的紧急联系人',
  [Copy.self_onboarding_government_issued_id_header]: '由政府签发的身份证件',
  [Copy.self_onboarding_government_issued_id_instructions]: '请上传包含您的全名和照片的驾驶执照或其他官方身份证件。',
  [Copy.front_file_label]: '添加证件的正面扫描件',
  [Copy.back_file_label]: '添加证件的反面扫描件',
  [Copy.id_type_label]: '身份证件类型',
  [Copy.state_issued_label]: '国家签发',
  [Copy.government_id_number_label]: '身份证号',
  [Copy.issue_date_label]: '签发日期',
  [Copy.expiration_date_label]: '截止日期',
  [Copy.self_onboarding_osha_card_header]: '职业安全与健康标准(OSHA)卡',
  [Copy.self_onboarding_osha_card_instructions]: '请上传您的 OSHA 卡。',
  [Copy.card_type_label]: '卡类型',
  [Copy.course_date_label]: '课程日期',
  [Copy.osha_card_number_label]: '身份证件号',
  [Copy.self_onboarding_ny_sst_card_header]: '纽约市现场安全培训 (SST) 卡',
  [Copy.self_onboarding_ny_sst_card_instructions]: '请上传您的纽约州 SST 卡。',
  [Copy.training_connect_card_label]: '培训连接卡',
  [Copy.self_onboarding_profile_photo_header]: '您的个人资料照片',
  [Copy.self_onboarding_profile_photo_instructions]: '还有一件事！ 请使用您设备上的相机给自己拍张照片。',
  [Copy.take_photo]: '拍照',
  [Copy.self_onboarding_profile_photo_direction_1]: '直视相机',
  [Copy.self_onboarding_profile_photo_direction_2]: '包括您的整个头部和肩膀',
  [Copy.self_onboarding_profile_photo_direction_3]: '不得戴帽子、太阳镜或其他遮盖面部的物品',
  [Copy.self_onboarding_profile_photo_direction_4]: '无微笑或其他面部表情',
  [Copy.self_onboarding_profile_photo_help_text]: '如无法拍摄符合要求的照片,则需进行现场拍照。',
  [Copy.self_onboarding_verify_phone_header]: '验证移动设备',
  [Copy.self_onboarding_verify_phone_instructions]:
    '使用 Odin,您可以使用移动设备在工作现场打卡。首先,我们需要通过短信验证您的设备。',
  [Copy.self_onboarding_verify_phone_instructions_no_mobile_app]:
    '通过 Odin，如果需要共享有关工作现场的重要信息，您可能会收到短信。 首先，我们需要通过短信验证您的设备。',
  [Copy.self_onboarding_verify_phone_help_text]: '请使用与此设备关联的号码。',
  [Copy.self_onboarding_verify_phone_to_sign_in_header]: '需要验证',
  [Copy.self_onboarding_verify_phone_to_sign_in_instructions]:
    '欢迎！ 您似乎已经有 Odin 个人资料了。 请按照以下说明继续。',
  [Copy.self_onboarding_verify_phone_to_sign_in_action]: "发送文本至以'结尾的号码",
  [Copy.self_onboarding_verify_phone_to_sign_in_skip]: '我的号码变了',
  [Copy.self_onboarding_confirm_phone_time_to_complete]: '距离完成还有{{time}}',
  [Copy.self_onboarding_review_completed_profile_header]: '恭喜,您已经完成了！',
  [Copy.self_onboarding_review_completed_profile_instructions]: '请向现场团队出示此屏幕以完成入职并开始工作',
  [Copy.self_onboarding_unable_to_verify_account_header]: '无法验证帐户',
  [Copy.self_onboarding_unable_to_verify_account_instructions]: '请向现场团队展示此屏幕，以完成入职并开始工作',
  [Copy.add_mobile_badge]: '添加移动徽章',
  [Copy.download_odin_app]: '下载 Odin 应用程序',
  [Copy.worker_info]: '员工信息',
  [Copy.documents]: '文件',
  [Copy.self_onboarding_no_osha_card]: '未上传 OSHA 卡',
  [Copy.self_onboarding_no_ny_sst_card]: '未上传纽约 SST 卡',
  [Copy.self_onboarding_no_government_id]: '未上传任何由政府签发的身份证件',
  [Copy.self_onboarding_worker_match_instructions]:
    '我们在这里收集成为本网站工作人员所需的一切。要开始使用,请输入以下信息。',
  [Copy.continue]: '继续',
  [Copy.skip_for_now]: '暂时跳过',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.self_onboarding_emergency_contact_header]: '紧急联系人',
  [Copy.self_onboarding_confirm_phone_title]: '请输入你收到的代码',
  [Copy.self_onboarding_confirm_phone_instruction]:
    '你提供的电话号码应该已收到6 位数的代码。 如果几分钟后你还没有收到验证码,你可以',
  [Copy.self_onboarding_confirm_phone_request_another_code]: '在此处请求提供另一个代码',
  [Copy.self_onboarding_confirm_phone_code_not_received]: '我从未收到我的代码',
  [Copy.add]: '添加',
  [Copy.self_onboarding_jobsite_safety_video_header]: '工人安全视频致谢',
  [Copy.self_onboarding_jobsite_safety_video_instructions]: '请观看以下视频来完成您的培训',
  [Copy.self_onboarding_jobsite_safety_video_not_acknowledged]: '尚未观看现场安全视频',
  [Copy.self_onboarding_jobsite_safety_document_header]: '现场安全培训',
  [Copy.self_onboarding_jobsite_safety_document_instructions]: '请阅读以下文件以完成您的培训。',
  [Copy.self_onboarding_jobsite_safety_document_not_acknowledged]: '现场安全培训文件尚未阅读',
  [Copy.self_onboarding_lirr_blue_training_header]: 'LIRR 蓝色训练',
  [Copy.self_onboarding_lirr_blue_training_instructions]: '请上传您的 LIRR Blue 培训文档。',
  [Copy.self_onboarding_no_lirr_blue_training]: '未上传 LIRR Blue 培训文档',
  [Copy.self_onboarding_confined_spaces_training_header]: '密闭空间训练',
  [Copy.self_onboarding_confined_spaces_training_instructions]: '请上传您的密闭空间培训文件。',
  [Copy.self_onboarding_no_confined_spaces_training]: '未上传密闭空间培训文件',
  [Copy.self_onboarding_worker_consent_header]: '工人同意书',
  [Copy.self_onboarding_worker_consent_instructions]: '请完整阅读此文件，并提供您的同意。',
  [Copy.self_onboarding_worker_consent_text_start]: '通过勾选此框，我确认我已阅读了',
  [Copy.self_onboarding_worker_consent_text_end]: '整份文件并同意其内容。',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_label]: '我已阅读文档',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_description]:
    '通过选中此框，我确认我已完整阅读培训文档并了解其内容。',
  [Copy.self_onboarding_acknowledgment_video_confirmed_label]: '我已观看视频',
  [Copy.self_onboarding_acknowledgment_video_confirmed_description]:
    '通过选中此框，我确认我已完整观看培训视频并了解其内容。',
  [Copy.self_onboarding_acknowledgment_video_not_started_label]: '我已观看视频',
  [Copy.self_onboarding_acknowledgment_video_not_started_disabled_description]: '按播放开始',
  [Copy.self_onboarding_acknowledgment_video_in_progress_label]: '我已观看视频',
};

const zhTW: Record<Copy, string> = {
  [Copy.language]: '繁體中文',
  [Copy.complete_registration]: '完成註冊',
  [Copy.language_selector_label]: '表單語言',
  [Copy.self_onboarding_worker_match_header]: '歡迎來到奧丁！',
  [Copy.preonboarding_sub_heading_step_1]:
    '您收到此鏈接是因為您將在奧丁建築工地工作。開始之前,請在此處輸入您的信息,以節省您的時間。我們致力於保障您的上網安全。',
  [Copy.preonboarding_step_1_instructions]: '首先我們需要確認您的身份：',
  [Copy.preonboarding_heading_step_2]: '為了完成您的個人資料,請提供以下信息',
  [Copy.preonboarding_heading_step_3]: '太棒了,最後一步！請提供緊急情況的聯繫方式。',
  [Copy.preonboarding_heading_step_4]: '祝賀您！',
  [Copy.preonboarding_sub_heading_step_4]: '感謝您完成個人資料。您工作現場的現場經理將幫助您完成入職培訓。',
  [Copy.preonboarding_message_phone_match_issue]: '電話號碼不匹配。請再試一次。',
  [Copy.preonboarding_message_worker_match]: '您似乎以前曾與奧丁合作過！',
  [Copy.preonboarding_message_phone_prompt]: '為了確認您的身份,請提供您的電話號碼：',
  [Copy.preonboarding_message_existing_profile]:
    '根據您提供的信息,您可能已經完成了 奧丁個人資料。如果確實如此,當您到達建築工地時,您可跳過此過程並完成快速指導。',
  [Copy.preonboarding_message_email_match_issue]: '電子郵件不匹配。請再試一次。',
  [Copy.preonboarding_message_email_prompt]: '為了確認您的身份,請提供您的帳戶電子郵件：',
  [Copy.preonboarding_message_expired_link_prompt]:
    '歡迎來到奧丁！很遺憾,我們無法找到您要查找的頁面。您獲得的鏈接可能已過期。請聯繫您的經理以獲得進一步的幫助。',
  [Copy.preonboarding_message_worker_match_no_action]: '看起來該名工人已註冊奧丁：此時不需要添加任何信息。',
  [Copy.preonboarding_message_next_steps]: '在建築工地首次入職時,可以確認和更新工人的詳細信息。',
  [Copy.worker_onboarding_header]: '個人信息',
  [Copy.full_name_label]: '姓名',
  [Copy.first_name_label]: '名',
  [Copy.first_name_mask_text]: '名',
  [Copy.first_name_invalid_value_validation]: '請輸入一個有效的名字',
  [Copy.first_name_missing_value_validation]: '名字必填',
  [Copy.last_name_label]: '姓',
  [Copy.last_name_mask_text]: '姓',
  [Copy.last_name_invalid_value_validation]: '請輸入一個有效的姓氏',
  [Copy.last_name_missing_value_validation]: '姓氏必填',
  [Copy.middle_initial_label]: '中間名首字母',
  [Copy.middle_initial_mask_text]: '中間名首字母',
  [Copy.middle_initial_invalid_value_validation]: '請輸入一個有效的中間名首字母',
  [Copy.middle_initial_missing_value_validation]: '中間名首字母必填',
  [Copy.birth_date_label]: '出生日期',
  [Copy.birth_date_mask_text]: '月/日/年',
  [Copy.birth_date_invalid_value_validation]: '請輸入一個有效的日期',
  [Copy.birth_date_missing_value_validation]: '出生日期必填',
  [Copy.email_label]: '電子郵件地址',
  [Copy.email_mask_text]: '電子郵件地址',
  [Copy.email_invalid_value_validation]: '請輸入一個有效的電子郵件',
  [Copy.email_missing_value_validation]: '電子郵件必填',
  [Copy.phone_number_label]: '電話號碼',
  [Copy.phone_number_mask_text]: '電話號碼',
  [Copy.phone_number_invalid_value_validation]: '請輸入一個有效的電話號碼',
  [Copy.phone_number_missing_value_validation]: '電話號碼必填',
  [Copy.address_line_1_label]: '地址',
  [Copy.address_line_1_mask_text]: '請輸入一個地址',
  [Copy.address_line_1_invalid_value_validation]: '請輸入一個有效的地址',
  [Copy.address_line_1_missing_value_validation]: '地址必填',
  [Copy.address_line_2_label]: '地址行 2',
  [Copy.address_line_2_mask_text]: '地址行 2',
  [Copy.address_line_2_invalid_value_validation]: '請輸入一個有效的地址行 2',
  [Copy.address_line_2_missing_value_validation]: '地址行 2 必填',
  [Copy.address_city_label]: '城市',
  [Copy.address_city_mask_text]: '城市',
  [Copy.address_city_invalid_value_validation]: '請輸入一個有效的城市',
  [Copy.address_city_missing_value_validation]: '城市必填',
  [Copy.address_state_label]: '州',
  [Copy.address_state_missing_value_validation]: '州必填',
  [Copy.address_zip_code_label]: '郵政編碼',
  [Copy.address_zip_code_mask_text]: '郵政編碼',
  [Copy.address_zip_code_invalid_value_validation]: '請輸入一個有效的郵政編碼',
  [Copy.address_zip_code_missing_value_validation]: '郵政編碼必填',
  [Copy.primary_language_label]: '首選語言',
  [Copy.ssn_last_four_label]: '社會保險號的最後 4 位數字',
  [Copy.ssn_last_four_mask_text]: '社會安全號碼的最後 4 位數字',
  [Copy.ssn_last_four_invalid_value_validation]: '請輸入一個有效的數字',
  [Copy.ssn_last_four_missing_value_validation]: '最後 4 位數字必填',
  [Copy.emergency_contact_name_label]: '緊急聯絡名字',
  [Copy.emergency_contact_name_mask_text]: '緊急聯絡姓名',
  [Copy.emergency_contact_name_invalid_value_validation]: '請輸入有效的緊急聯繫人姓名',
  [Copy.emergency_contact_name_missing_value_validation]: '緊急聯繫人姓名必填',
  [Copy.emergency_contact_relationship_label]: '緊急聯繫人關係',
  [Copy.emergency_contact_relationship_mask_text]: '緊急聯繫人關係',
  [Copy.emergency_contact_relationship_invalid_value_validation]: '請輸入有效的緊急聯繫人關係',
  [Copy.emergency_contact_relationship_missing_value_validation]: '緊急聯繫人關係必填',
  [Copy.emergency_contact_relationship_option_boyfriend]: '男朋友',
  [Copy.emergency_contact_relationship_option_child]: '子女',
  [Copy.emergency_contact_relationship_option_co_worker]: '同事',
  [Copy.emergency_contact_relationship_option_family]: '家人',
  [Copy.emergency_contact_relationship_option_girlfriend]: '女朋友',
  [Copy.emergency_contact_relationship_option_grandparent]: '祖父母',
  [Copy.emergency_contact_relationship_option_friend]: '朋友',
  [Copy.emergency_contact_relationship_option_parent]: '家長',
  [Copy.emergency_contact_relationship_option_partner]: '合作夥伴',
  [Copy.emergency_contact_relationship_option_spouse]: '伴侶',
  [Copy.emergency_contact_relationship_option_other]: '其他',
  [Copy.emergency_contact_phone_label]: '緊急聯絡電話',
  [Copy.emergency_contact_phone_mask_text]: '緊急聯絡電話',
  [Copy.emergency_contact_phone_invalid_value_validation]: '請輸入一個有效的電話號碼',
  [Copy.emergency_contact_phone_missing_value_validation]: '電話號碼必填',
  [Copy.trade_label]: '交易',
  [Copy.trade_mask_text]: '交易',
  [Copy.trade_invalid_value_validation]: '請輸入一個有效的交易',
  [Copy.trade_missing_value_validation]: '交易必填',
  [Copy.trade_option_bricklayer]: '瓦工',
  [Copy.trade_option_carpenter]: '木匠',
  [Copy.trade_option_cement_masons]: '水泥泥瓦匠',
  [Copy.trade_option_concrete_safety_manager]: '混凝土安全經理',
  [Copy.trade_option_concrete_workers]: '混凝土工人',
  [Copy.trade_option_crane_operator]: '起重機操作員',
  [Copy.trade_option_demolition]: '拆除',
  [Copy.trade_option_dockbuilder]: '碼頭建造者',
  [Copy.trade_option_drill_operator]: '鑽探操作員',
  [Copy.trade_option_driver]: '驅動程式',
  [Copy.trade_option_electrician]: '電工',
  [Copy.trade_option_elevator]: '電梯',
  [Copy.trade_option_engineer]: '工程師',
  [Copy.trade_option_executive]: '執行',
  [Copy.trade_option_excavators]: '挖掘機',
  [Copy.trade_option_fire_safety]: '消防安全',
  [Copy.trade_option_flagger]: '標記',
  [Copy.trade_option_flooring]: '地板',
  [Copy.trade_option_glazier]: '玻璃',
  [Copy.trade_option_hvac]: '暖通空調',
  [Copy.trade_option_inspector]: '檢查員',
  [Copy.trade_option_insulator]: '絕緣體',
  [Copy.trade_option_ironworker]: '鋼鐵工人',
  [Copy.trade_option_laborer]: '勞動者',
  [Copy.trade_option_lighting]: '照明',
  [Copy.trade_option_maintenance]: '維護',
  [Copy.trade_option_manager]: '經理',
  [Copy.trade_option_masonry]: '砌體',
  [Copy.trade_option_mechanic]: '機械師',
  [Copy.trade_option_medical]: '醫療',
  [Copy.trade_option_metal_lathers]: '金屬泡沫',
  [Copy.trade_option_na]: '不適用',
  [Copy.trade_option_office]: '辦公室',
  [Copy.trade_option_oiler]: '加油',
  [Copy.trade_option_operating_engineer]: '操作工程師',
  [Copy.trade_option_operator]: '操作員',
  [Copy.trade_option_painter]: '畫家',
  [Copy.trade_option_pipefitter]: '管道安裝器',
  [Copy.trade_option_plasterer]: '抹灰工',
  [Copy.trade_option_plumber]: '水管工',
  [Copy.trade_option_project_manager]: '專案經理',
  [Copy.trade_option_rebar]: '鋼筋',
  [Copy.trade_option_rigger]: '裝配工',
  [Copy.trade_option_roofer]: '屋頂工',
  [Copy.trade_option_safety]: '安全',
  [Copy.trade_option_scaffolding]: '腳手架',
  [Copy.trade_option_sheetmetal]: '鈑金件',
  [Copy.trade_option_sprinkler]: '灑水器',
  [Copy.trade_option_steamfitter]: 'Steamfitter',
  [Copy.trade_option_steelworker]: '鋼鐵工人',
  [Copy.trade_option_superintendent]: '主管',
  [Copy.trade_option_supervisor]: '主管',
  [Copy.trade_option_surveyor]: '測量員',
  [Copy.trade_option_security]: '安全',
  [Copy.trade_option_tapers]: '錐度',
  [Copy.trade_option_teamsters]: '卡車司機',
  [Copy.trade_option_telecommunications]: '電信',
  [Copy.trade_option_tiler]: '磁磚',
  [Copy.trade_option_timekeeper]: '計時器',
  [Copy.trade_option_union_representative]: '工會代表',
  [Copy.trade_option_waterproofer]: '防水劑',
  [Copy.trade_option_welders]: '焊工',
  [Copy.trade_option_other]: '其他',
  [Copy.job_title_label]: '職位名稱',
  [Copy.job_title_mask_text]: '職位名稱',
  [Copy.job_title_invalid_value_validation]: '請輸入一個有效的職位名稱',
  [Copy.job_title_missing_value_validation]: '職位名稱必填',
  [Copy.job_title_class_label]: '職稱類別',
  [Copy.job_title_class_mask_text]: '職稱類別',
  [Copy.job_title_class_invalid_value_validation]: '職稱類別必填',
  [Copy.job_title_class_missing_value_validation]: '請輸入一個有效的職位類別',
  [Copy.job_title_option_apprentice]: '學徒',
  [Copy.job_title_option_foreman]: '工頭',
  [Copy.job_title_option_journeyman]: '熟練工',
  [Copy.job_title_option_laborer]: '勞動者',
  [Copy.job_title_option_medic]: '醫生',
  [Copy.job_title_option_medic_supervisor]: '醫事主管',
  [Copy.job_title_option_operating_engineer]: '操作工程師',
  [Copy.job_title_option_provisional_apprentice]: '暫時學徒',
  [Copy.job_title_option_provisional_foreman]: '臨時工頭',
  [Copy.job_title_option_provisional_journeyman]: '臨時熟練工',
  [Copy.job_title_option_shop_steward]: '商店管家',
  [Copy.job_title_option_supervisor]: '主管',
  [Copy.job_title_option_superintendent]: '主管',
  [Copy.job_title_option_office]: '辦公室',
  [Copy.job_title_option_other]: '其他',
  [Copy.job_title_option_n_a]: '不適用',
  [Copy.union_affiliation_label]: '工會隸屬關係',
  [Copy.union_affiliation_mask_text]: '工會隸屬關係',
  [Copy.union_affiliation_invalid_value_validation]: '請輸入一個有效的工會附屬機構',
  [Copy.union_affiliation_missing_value_validation]: '工會附屬機構必填',
  [Copy.citizenship_status_label]: '公民身份',
  [Copy.citizenship_status_invalid_value_validation]: '選擇有效的公民身份',
  [Copy.citizenship_status_missing_value_validation]: '需要公民身份',
  [Copy.citizenship_status_option_citizen]: '公民',
  [Copy.citizenship_status_option_non_citizen]: '非公民',
  [Copy.is_veteran_label]: '退伍軍人身份',
  [Copy.is_veteran_mask_text]: '退伍軍人身份',
  [Copy.is_veteran_invalid_value_validation]: '請輸入有效的退伍軍人身份',
  [Copy.is_veteran_missing_value_validation]: '退伍軍人身份必填',
  [Copy.is_veteran_option_veteran]: '退伍軍人',
  [Copy.is_veteran_option_non_veteran]: '非退伍軍人',
  [Copy.contractor_label]: '承包商',
  [Copy.contractor_mask_text]: '承包商',
  [Copy.contractor_invalid_value_validation]: '請輸入有效的承包商',
  [Copy.contractor_missing_value_validation]: '承包商必填',
  [Copy.race_label]: '種族',
  [Copy.race_mask_text]: '種族',
  [Copy.race_invalid_value_validation]: '請輸入有效的種族',
  [Copy.race_missing_value_validation]: '種族必填',
  [Copy.race_option_asian_native_hawaiian_or_pacific_islander]: '亞洲/夏威夷原住民或太平洋島民',
  [Copy.race_option_black_african_american]: '黑人/非裔美國人',
  [Copy.race_option_native_american_alaskan_native]: '美洲原住民/阿拉斯加原住民',
  [Copy.race_option_hispanic_latino]: '西班牙裔/拉丁裔',
  [Copy.race_option_white]: '白色的',
  [Copy.race_option_other_prefer_not_to_respond]: '其他/不想回應',
  [Copy.gender_label]: '性別',
  [Copy.gender_mask_text]: '性別',
  [Copy.gender_invalid_value_validation]: '請輸入有效的性別',
  [Copy.gender_missing_value_validation]: '性別必填',
  [Copy.gender_option_male]: '男性',
  [Copy.gender_option_female]: '女性',
  [Copy.gender_option_other]: '其他',
  [Copy.submit_button_text]: '提交',
  [Copy.field_required_label]: '必填',
  [Copy.field_optional_label]: '可選',
  [Copy.picklist_mask_general]: '選擇...',
  [Copy.thank_you_message]: '謝謝！',

  [Copy.self_onboarding_get_started_text]: '首先,請選擇您的首選語言',
  [Copy.self_onboarding_error_heading]: '抱歉,出了一些問題',
  [Copy.self_onboarding_error_text]: '您提供的信息可能有問題：請諮詢您的現場主管或現場工作人員以完成入職培訓。',
  [Copy.self_onboarding_profile_header]: '您的個人資料',
  [Copy.self_onboarding_profile_instructions]: '請完成以下信息,並開始使用。所有字段均為必填,除非標記為“可選”。',
  [Copy.self_onboarding_matched_worker_heading]: '一切就緒！',
  [Copy.self_onboarding_matched_worker_text]:
    '您可能已經有 Odin 個人資料。請與您的現場主管或現場工作人員核實以完成入職培訓。',
  [Copy.self_onboarding_emergency_contact_instructions]: '請告知我們您的緊急聯繫人',
  [Copy.self_onboarding_government_issued_id_header]: '由政府簽發的身份證件',
  [Copy.self_onboarding_government_issued_id_instructions]: '請上傳包含您的全名和照片的駕駛執照或其他官方身份證件。',
  [Copy.front_file_label]: '添加證件的正面掃描件',
  [Copy.back_file_label]: '添加證件的反面掃描件',
  [Copy.id_type_label]: '身份證件類型',
  [Copy.state_issued_label]: '國家簽發',
  [Copy.government_id_number_label]: '身份證號',
  [Copy.issue_date_label]: '簽發日期',
  [Copy.expiration_date_label]: '截止日期',
  [Copy.self_onboarding_osha_card_header]: '職業安全與健康標準(OSHA)卡',
  [Copy.self_onboarding_osha_card_instructions]: '請上傳您的 OSHA 卡。',
  [Copy.card_type_label]: '卡類型',
  [Copy.course_date_label]: '課程日期',
  [Copy.osha_card_number_label]: '身份證件號',
  [Copy.self_onboarding_ny_sst_card_header]: '紐約市現場安全培訓 (SST) 卡',
  [Copy.self_onboarding_ny_sst_card_instructions]: '請上傳您的紐約州 SST 卡。',
  [Copy.training_connect_card_label]: '培訓連接卡',
  [Copy.self_onboarding_profile_photo_header]: '您的個人資料照片',
  [Copy.self_onboarding_profile_photo_instructions]: '還有一件事！請使用您設備上的相機給自己拍張照片。',
  [Copy.take_photo]: '拍照',
  [Copy.self_onboarding_profile_photo_direction_1]: '直視相機',
  [Copy.self_onboarding_profile_photo_direction_2]: '包括您的整個頭部和肩膀',
  [Copy.self_onboarding_profile_photo_direction_3]: '不得戴帽子、太陽鏡或其他遮蓋面部的物品',
  [Copy.self_onboarding_profile_photo_direction_4]: '無微笑或其他面部表情',
  [Copy.self_onboarding_profile_photo_help_text]: '如無法拍攝符合要求的照片,則需進行現場拍照。',
  [Copy.self_onboarding_verify_phone_header]: '驗證移動設備',
  [Copy.self_onboarding_verify_phone_instructions]:
    '使用 Odin,您可以使用移動設備在工作現場打卡。首先,我們需要通過短信驗證您的設備。',
  [Copy.self_onboarding_verify_phone_instructions_no_mobile_app]:
    '通过 Odin，如果需要共享有关工作现场的重要信息，您可能会收到短信。 首先，我们需要通过短信验证您的设备。',
  [Copy.self_onboarding_verify_phone_help_text]: '請使用與此設備關聯的號碼。',
  [Copy.self_onboarding_verify_phone_to_sign_in_header]: '需要驗證',
  [Copy.self_onboarding_verify_phone_to_sign_in_instructions]:
    '歡迎！ 您似乎已經有 Odin 個人資料了。 請按照以下說明繼續。',
  [Copy.self_onboarding_verify_phone_to_sign_in_action]: "發送文字至以'結尾的號碼",
  [Copy.self_onboarding_verify_phone_to_sign_in_skip]: '我的號碼變了',
  [Copy.self_onboarding_confirm_phone_time_to_complete]: '距離完成還有{{time}}',
  [Copy.self_onboarding_review_completed_profile_header]: '恭喜,您已經完成了！',
  [Copy.self_onboarding_review_completed_profile_instructions]: '請向現場團隊出示此屏幕以完成入職並開始工作',
  [Copy.self_onboarding_unable_to_verify_account_header]: '無法驗證帳戶',
  [Copy.self_onboarding_unable_to_verify_account_instructions]: '請向現場團隊展示此螢幕，以完成入職並開始工作',
  [Copy.add_mobile_badge]: '添加移動徽章',
  [Copy.download_odin_app]: '下載 Odin 應用程序',
  [Copy.worker_info]: '員工信息',
  [Copy.documents]: '文件',
  [Copy.self_onboarding_no_osha_card]: '未上傳 OSHA 卡',
  [Copy.self_onboarding_no_ny_sst_card]: '未上傳紐約 SST 卡',
  [Copy.self_onboarding_no_government_id]: '未上傳任何由政府簽發的身份證件',
  [Copy.self_onboarding_worker_match_instructions]:
    '我們在這裡收集成為本網站工作人員所需的一切。要開始使用,請輸入以下信息。',
  [Copy.continue]: '繼續',
  [Copy.skip_for_now]: '暫時跳過',
  [Copy.save]: '保存',
  [Copy.cancel]: '取消',
  [Copy.self_onboarding_emergency_contact_header]: '緊急聯繫人',
  [Copy.self_onboarding_confirm_phone_title]: '請輸入你收到的代碼',
  [Copy.self_onboarding_confirm_phone_instruction]:
    '你提供的電話號碼應該已收到6 位數的代碼。如果幾分鐘後你還沒有收到驗證碼,你可以',
  [Copy.self_onboarding_confirm_phone_request_another_code]: '在此處請求提供另一個代碼',
  [Copy.self_onboarding_confirm_phone_code_not_received]: '我從未收到我的代碼',
  [Copy.add]: '添加',
  [Copy.self_onboarding_jobsite_safety_video_header]: '工人安全影片致謝',
  [Copy.self_onboarding_jobsite_safety_video_instructions]: '請觀看以下影片來完成您的培訓',
  [Copy.self_onboarding_jobsite_safety_video_not_acknowledged]: '尚未觀看現場安全影片',
  [Copy.self_onboarding_jobsite_safety_document_header]: '現場安全培訓',
  [Copy.self_onboarding_jobsite_safety_document_instructions]: '請閱讀以下文件以完成您的培訓。',
  [Copy.self_onboarding_jobsite_safety_document_not_acknowledged]: '現場安全培訓文件尚未閱讀',
  [Copy.self_onboarding_lirr_blue_training_header]: 'LIRR 藍色訓練',
  [Copy.self_onboarding_lirr_blue_training_instructions]: '請上傳您的 LIRR Blue 培訓文件。',
  [Copy.self_onboarding_no_lirr_blue_training]: '未上傳 LIRR Blue 培訓文件',
  [Copy.self_onboarding_confined_spaces_training_header]: '密閉空間訓練',
  [Copy.self_onboarding_confined_spaces_training_instructions]: '請上傳您的密閉空間培訓文件。',
  [Copy.self_onboarding_no_confined_spaces_training]: '未上傳密閉空間培訓文件',
  [Copy.self_onboarding_worker_consent_header]: '工人同意書',
  [Copy.self_onboarding_worker_consent_instructions]: '請完整閱讀此文件，並提供您的同意。',
  [Copy.self_onboarding_worker_consent_text_start]: '通過勾選此框，我確認我已閱讀了',
  [Copy.self_onboarding_worker_consent_text_end]: '整份文件並同意其內容。',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_label]: '我已閱讀該文件',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_description]:
    '透過勾選此框，我確認我已完整閱讀訓練文件並瞭解其內容。',
  [Copy.self_onboarding_acknowledgment_video_confirmed_label]: '我已觀看影片',
  [Copy.self_onboarding_acknowledgment_video_confirmed_description]:
    '透過選取此框，我承認我已完整觀看了培訓影片並理解其內容。',
  [Copy.self_onboarding_acknowledgment_video_not_started_label]: '我已觀看影片',
  [Copy.self_onboarding_acknowledgment_video_not_started_disabled_description]: '按播放開始',
  [Copy.self_onboarding_acknowledgment_video_in_progress_label]: '我已觀看影片',
};

const pl: Record<Copy, string> = {
  [Copy.language]: 'Polski',
  [Copy.complete_registration]: 'Zakończyć rejestrację',
  [Copy.language_selector_label]: 'Język formularza',
  [Copy.self_onboarding_worker_match_header]: 'Witamy w Odin!',
  [Copy.preonboarding_sub_heading_step_1]:
    'Otrzymałeś ten link, ponieważ będziesz pracować wykorzystując platformę Odin. Wprowadzając tutaj swoje dane przed przyjazdem, zaoszczędzisz czas. Zależy nam na Twoim bezpieczeństwie.',
  [Copy.preonboarding_step_1_instructions]: 'Na początek musimy potwierdzić Twoją tożsamość:',
  [Copy.preonboarding_heading_step_2]: 'Podaj następujące informację, aby uzupełnić swój profil',
  [Copy.preonboarding_heading_step_3]:
    'Świetnie, pozostał jeszcze tylko jeden krok! Proszę podać kontakt do osoby w nagłych wypadkach.',
  [Copy.preonboarding_heading_step_4]: 'Gratulacje!',
  [Copy.preonboarding_sub_heading_step_4]:
    'Dziękujemy za uzupełnienie profilu. Kierownik na miejscu w miejscu pracy pomoże ci dokończyć orientację.',
  [Copy.preonboarding_message_phone_match_issue]: 'Numer telefonu jest niezgodny. Proszę spróbować ponownie.',
  [Copy.preonboarding_message_worker_match]: 'Wygląda na to, że pracowałeś już z platformą Odin!',
  [Copy.preonboarding_message_phone_prompt]: 'Podaj swój numer telefonu, aby potwierdzić swoją tożsamość:',
  [Copy.preonboarding_message_existing_profile]:
    'Na podstawie podanych przez Ciebie informacji wygląda, że posiadasz już profil na platformie Odin. W tym przypadku będziesz mógł pominąć ten proces i przejść przez przyspieszoną procedurę po przybyciu na miejsce pracy.',
  [Copy.preonboarding_message_email_match_issue]: 'Adres e-mail jest niezgodny. Proszę spróbować ponownie.',
  [Copy.preonboarding_message_email_prompt]: 'Podaj swój adres e-mail, aby potwierdzić swoją tożsamość:',
  [Copy.preonboarding_message_expired_link_prompt]:
    'Witamy na platformie Odin! Niestety nie mogliśmy znaleźć strony, której szukasz. Otrzymany link mógł wygasnąć. Skontaktuj się ze swoim menedżerem, aby uzyskać dalszą pomoc.',
  [Copy.preonboarding_message_worker_match_no_action]:
    'Wygląda na to, że profil tego pracownika istnieje już na platformie Odin: obecnie nie trzeba podawać żadnych informacji.',
  [Copy.preonboarding_message_next_steps]:
    'Dane pracownika można potwierdzić i zaktualizować podczas onboardingu pierwszego dnia na miejscu pracy.',
  [Copy.worker_onboarding_header]: 'Informacje osobiste',
  [Copy.full_name_label]: 'Imię',
  [Copy.first_name_label]: 'Imię',
  [Copy.first_name_mask_text]: 'Imię',
  [Copy.first_name_invalid_value_validation]: 'Proszę wpisać prawidłowe imię',
  [Copy.first_name_missing_value_validation]: 'Podanie imienia jest wymagane',
  [Copy.last_name_label]: 'Nazwisko',
  [Copy.last_name_mask_text]: 'Nazwisko',
  [Copy.last_name_invalid_value_validation]: 'Proszę wpisać prawidłowe nazwisko',
  [Copy.last_name_missing_value_validation]: 'Podanie nazwiska jest wymagane',
  [Copy.middle_initial_label]: 'Inicjał drugiego imienia',
  [Copy.middle_initial_mask_text]: 'Inicjał drugiego imienia',
  [Copy.middle_initial_invalid_value_validation]: 'Wpisz poprawny inicjał',
  [Copy.middle_initial_missing_value_validation]: 'Wymaganie inicjału drugiego imienia jest wymagane',
  [Copy.birth_date_label]: 'Data urodzenia',
  [Copy.birth_date_mask_text]: 'MM/DD/RRRR',
  [Copy.birth_date_invalid_value_validation]: 'Wpisz poprawną datę',
  [Copy.birth_date_missing_value_validation]: 'Podanie daty urodzenia jest wymagane',
  [Copy.email_label]: 'Adres e-mail',
  [Copy.email_mask_text]: 'Adres e-mail',
  [Copy.email_invalid_value_validation]: 'Wpisz poprawny adres e-mail',
  [Copy.email_missing_value_validation]: 'Numer telefonu jest wymagany',
  [Copy.phone_number_label]: 'Telefon komórkowy',
  [Copy.phone_number_mask_text]: 'Telefon komórkowy',
  [Copy.phone_number_invalid_value_validation]: 'Prosimy wpisać poprawny numer telefonu',
  [Copy.phone_number_missing_value_validation]: 'Numer telefonu jest wymagany',
  [Copy.address_line_1_label]: 'Adres zamieszkania',
  [Copy.address_line_1_mask_text]: 'Proszę podać adres',
  [Copy.address_line_1_invalid_value_validation]: 'Proszę podać poprawny adres',
  [Copy.address_line_1_missing_value_validation]: 'Adres jest wymagany',
  [Copy.address_line_2_label]: 'Adres zamieszkania kontynuacja',
  [Copy.address_line_2_mask_text]: 'Adres zamieszkania kontynuacja',
  [Copy.address_line_2_invalid_value_validation]: 'Wpisz poprawny adres w wierszu 2',
  [Copy.address_line_2_missing_value_validation]: 'Druga linijka adresu zamieszkania jest wymagana',
  [Copy.address_city_label]: 'Miasto',
  [Copy.address_city_mask_text]: 'Miasto',
  [Copy.address_city_invalid_value_validation]: 'Wpisz prawidłowe miasto',
  [Copy.address_city_missing_value_validation]: 'Podanie miasta jest wymagane',
  [Copy.address_state_label]: 'Stan',
  [Copy.address_state_missing_value_validation]: 'Podanie stanu jest wymagane',
  [Copy.address_zip_code_label]: 'Kod pocztowy',
  [Copy.address_zip_code_mask_text]: 'Kod pocztowy',
  [Copy.address_zip_code_invalid_value_validation]: 'Podaj prawidłowy kod pocztowy',
  [Copy.address_zip_code_missing_value_validation]: 'Kod pocztowy jest wymagany',
  [Copy.primary_language_label]: 'Preferowany język',
  [Copy.ssn_last_four_label]: 'Ostatnie 4 cyfry numeru ubezpieczenia społecznego',
  [Copy.ssn_last_four_mask_text]: 'Ostatnie 4 cyfry numeru ubezpieczenia społecznego',
  [Copy.ssn_last_four_invalid_value_validation]: 'Wpisz poprawny numer',
  [Copy.ssn_last_four_missing_value_validation]: 'Ostatnie 4 cyfry numeru ubezpieczenia społecznego są wymagane',
  [Copy.emergency_contact_name_label]: 'Imię i nazwisko osoby kontaktowej w nagłych wypadkach',
  [Copy.emergency_contact_name_mask_text]: 'Imię i nazwisko osoby kontaktowej w nagłych wypadkach',
  [Copy.emergency_contact_name_invalid_value_validation]: 'Wpisz prawidłowe dane osoby kontaktowej w nagłych wypadkach',
  [Copy.emergency_contact_name_missing_value_validation]:
    'Wymagane imię i nazwisko osoby kontaktowej w nagłych wypadkach',
  [Copy.emergency_contact_relationship_label]: 'Rodzaj pokrewieństwa podanej osoby kontaktowej',
  [Copy.emergency_contact_relationship_mask_text]: 'Rodzaj pokrewieństwa podanej osoby kontaktowej',
  [Copy.emergency_contact_relationship_invalid_value_validation]: 'Podaj poprawne dane pokrewieństwa osoby kontaktowej',
  [Copy.emergency_contact_relationship_missing_value_validation]:
    'Podanie danych pokrewieństwa osoby kontaktowej jest wymagane',
  [Copy.emergency_contact_relationship_option_boyfriend]: 'Chłopak',
  [Copy.emergency_contact_relationship_option_child]: 'Dziecko',
  [Copy.emergency_contact_relationship_option_co_worker]: 'Współpracownik',
  [Copy.emergency_contact_relationship_option_family]: 'Rodzina',
  [Copy.emergency_contact_relationship_option_girlfriend]: 'Dziewczyna',
  [Copy.emergency_contact_relationship_option_grandparent]: 'Dziadek/Babcia',
  [Copy.emergency_contact_relationship_option_friend]: 'Przyjaciel',
  [Copy.emergency_contact_relationship_option_parent]: 'Rodzic',
  [Copy.emergency_contact_relationship_option_partner]: 'Partner',
  [Copy.emergency_contact_relationship_option_spouse]: 'Współmałżonek',
  [Copy.emergency_contact_relationship_option_other]: 'Inne',
  [Copy.emergency_contact_phone_label]: 'Telefon kontaktowy w nagłych wypadkach',
  [Copy.emergency_contact_phone_mask_text]: 'Telefon kontaktowy w nagłych wypadkach',
  [Copy.emergency_contact_phone_invalid_value_validation]: 'Wpisz poprawny numer telefonu',
  [Copy.emergency_contact_phone_missing_value_validation]: 'Numer telefonu jest wymagany',
  [Copy.trade_label]: 'Zawód',
  [Copy.trade_mask_text]: 'Zawód',
  [Copy.trade_invalid_value_validation]: 'Wprowadź prawidłową nazwę zawodu',
  [Copy.trade_missing_value_validation]: 'Podanie zawodu jest wymagane',
  [Copy.trade_option_bricklayer]: 'Murarz',
  [Copy.trade_option_carpenter]: 'Stolarz',
  [Copy.trade_option_cement_masons]: 'Masoni cementowi',
  [Copy.trade_option_concrete_safety_manager]: 'Menedżer ds. bezpieczeństwa betonu',
  [Copy.trade_option_concrete_workers]: 'Betoniści',
  [Copy.trade_option_crane_operator]: 'Operator dźwigu',
  [Copy.trade_option_demolition]: 'Rozbiórka',
  [Copy.trade_option_dockbuilder]: 'Konstruktor doków',
  [Copy.trade_option_drill_operator]: 'Operator wiertarki',
  [Copy.trade_option_driver]: 'Sterownik',
  [Copy.trade_option_electrician]: 'Elektryk',
  [Copy.trade_option_elevator]: 'Winda',
  [Copy.trade_option_engineer]: 'Inżynier',
  [Copy.trade_option_executive]: 'Dyrektor wykonawczy',
  [Copy.trade_option_excavators]: 'Koparki',
  [Copy.trade_option_fire_safety]: 'Bezpieczeństwo przeciwpożarowe',
  [Copy.trade_option_flagger]: 'Zgłaszający',
  [Copy.trade_option_flooring]: 'Podłoga',
  [Copy.trade_option_glazier]: 'szklarz',
  [Copy.trade_option_hvac]: 'HVAC',
  [Copy.trade_option_inspector]: 'Inspektor',
  [Copy.trade_option_insulator]: 'Izolator',
  [Copy.trade_option_ironworker]: 'Ślusarz',
  [Copy.trade_option_laborer]: 'Pracownik',
  [Copy.trade_option_lighting]: 'Oświetlenie',
  [Copy.trade_option_maintenance]: 'Konserwacja',
  [Copy.trade_option_manager]: 'Menedżer',
  [Copy.trade_option_masonry]: 'Mur',
  [Copy.trade_option_mechanic]: 'Mechanik',
  [Copy.trade_option_medical]: 'Medyczne',
  [Copy.trade_option_metal_lathers]: 'Piany metalowe',
  [Copy.trade_option_na]: 'NA',
  [Copy.trade_option_office]: 'Biuro',
  [Copy.trade_option_oiler]: 'Olejarka',
  [Copy.trade_option_operating_engineer]: 'Inżynier operacyjny',
  [Copy.trade_option_operator]: 'Operator',
  [Copy.trade_option_painter]: 'Malarz',
  [Copy.trade_option_pipefitter]: 'Montaż rurociągów',
  [Copy.trade_option_plasterer]: 'tynkarz',
  [Copy.trade_option_plumber]: 'Hydraulik',
  [Copy.trade_option_project_manager]: 'Kierownik Projektu',
  [Copy.trade_option_rebar]: 'Zbrojenie',
  [Copy.trade_option_rigger]: 'Rigger',
  [Copy.trade_option_roofer]: 'Dekarz',
  [Copy.trade_option_safety]: 'Bezpieczeństwo',
  [Copy.trade_option_scaffolding]: 'Rusztowanie',
  [Copy.trade_option_sheetmetal]: 'Blacha',
  [Copy.trade_option_sprinkler]: 'Zraszacz',
  [Copy.trade_option_steamfitter]: 'Montaż parowy',
  [Copy.trade_option_steelworker]: 'Hutnik',
  [Copy.trade_option_superintendent]: 'Nadinspektor',
  [Copy.trade_option_supervisor]: 'Nadzorca',
  [Copy.trade_option_surveyor]: 'Gadżet',
  [Copy.trade_option_security]: 'Bezpieczeństwo',
  [Copy.trade_option_tapers]: 'Zwężające się',
  [Copy.trade_option_teamsters]: 'Teamsterzy',
  [Copy.trade_option_telecommunications]: 'Telekomunikacja',
  [Copy.trade_option_tiler]: 'Glazurnik',
  [Copy.trade_option_timekeeper]: 'Chronometrażysta',
  [Copy.trade_option_union_representative]: 'Przedstawiciel Unii',
  [Copy.trade_option_waterproofer]: 'Wodoodporny',
  [Copy.trade_option_welders]: 'Spawacze',
  [Copy.trade_option_other]: 'Inne',
  [Copy.job_title_label]: 'Nazwa stanowiska',
  [Copy.job_title_mask_text]: 'Nazwa stanowiska',
  [Copy.job_title_invalid_value_validation]: 'Wpisz poprawny tytuł stanowiska',
  [Copy.job_title_missing_value_validation]: 'Podanie tytułu stanowiska jest wymagane',
  [Copy.job_title_class_label]: 'Klasa stanowiska pracy',
  [Copy.job_title_class_mask_text]: 'Klasa stanowiska pracy',
  [Copy.job_title_class_invalid_value_validation]: 'Podanie klasy stanowiska pracy jest wymagane',
  [Copy.job_title_class_missing_value_validation]: 'Wpisz prawidłową klasę tytułu zawodowego',
  [Copy.job_title_option_apprentice]: 'Uczeń',
  [Copy.job_title_option_foreman]: 'Majster',
  [Copy.job_title_option_journeyman]: 'Cladnik',
  [Copy.job_title_option_laborer]: 'Pracownik',
  [Copy.job_title_option_medic]: 'Medyk',
  [Copy.job_title_option_medic_supervisor]: 'Nadzorca Medyka',
  [Copy.job_title_option_operating_engineer]: 'Inżynier operacyjny',
  [Copy.job_title_option_provisional_apprentice]: 'Tymczasowy praktykant',
  [Copy.job_title_option_provisional_foreman]: 'Majster tymczasowy',
  [Copy.job_title_option_provisional_journeyman]: 'Tymczasowy czeladnik',
  [Copy.job_title_option_shop_steward]: 'Mężczyzna w sklepie',
  [Copy.job_title_option_supervisor]: 'Przełożony',
  [Copy.job_title_option_superintendent]: 'Superintendent',
  [Copy.job_title_option_office]: 'Biuro',
  [Copy.job_title_option_other]: 'Inne',
  [Copy.job_title_option_n_a]: 'nie dotyczy',
  [Copy.union_affiliation_label]: 'Przynależność do Unii',
  [Copy.union_affiliation_mask_text]: 'Przynależność do Unii',
  [Copy.union_affiliation_invalid_value_validation]: 'Wpisz prawidłową przynależność związkową',
  [Copy.union_affiliation_missing_value_validation]: 'Podanie przynależności związkowej jest wymagane',
  [Copy.citizenship_status_label]: 'Status obywatelstwa',
  [Copy.citizenship_status_invalid_value_validation]: 'Wybierz ważny status obywatelstwa',
  [Copy.citizenship_status_missing_value_validation]: 'Wymagane obywatelstwo',
  [Copy.citizenship_status_option_citizen]: 'Obywatel',
  [Copy.citizenship_status_option_non_citizen]: 'Nieobywatel',
  [Copy.is_veteran_label]: 'Status weterana',
  [Copy.is_veteran_mask_text]: 'Status weterana',
  [Copy.is_veteran_invalid_value_validation]: 'Podaj poprawny status weterana',
  [Copy.is_veteran_missing_value_validation]: 'Wymagany status weterana',
  [Copy.is_veteran_option_veteran]: 'Weteran',
  [Copy.is_veteran_option_non_veteran]: 'Nie-weteran',
  [Copy.contractor_label]: 'Kontrahent',
  [Copy.contractor_mask_text]: 'Kontrahent',
  [Copy.contractor_invalid_value_validation]: 'Wpisz prawidłowe dane kontrahenta',
  [Copy.contractor_missing_value_validation]: 'Podanie danych kontrahenta jest wymagane',
  [Copy.race_label]: 'Rasa',
  [Copy.race_mask_text]: 'Rasa',
  [Copy.race_invalid_value_validation]: 'Podaj poprawna rasę',
  [Copy.race_missing_value_validation]: 'Podanie rasy jest wymagane',
  [Copy.race_option_asian_native_hawaiian_or_pacific_islander]:
    'Azjatycki/rdzenny mieszkaniec Hawajów lub wysp Pacyfiku',
  [Copy.race_option_black_african_american]: 'Czarny / Afroamerykanin',
  [Copy.race_option_native_american_alaskan_native]: 'Rdzenni Amerykanie / rdzenni mieszkańcy Alaski',
  [Copy.race_option_hispanic_latino]: 'Latynos/Latynos',
  [Copy.race_option_white]: 'Biały',
  [Copy.race_option_other_prefer_not_to_respond]: 'Inne/Wolę nie odpowiadać',
  [Copy.gender_label]: 'Płeć',
  [Copy.gender_mask_text]: 'Płeć',
  [Copy.gender_invalid_value_validation]: 'Podaj poprawną płeć',
  [Copy.gender_missing_value_validation]: 'Podanie płci jest wymagane',
  [Copy.gender_option_male]: 'Mężczyzna',
  [Copy.gender_option_female]: 'Kobieta',
  [Copy.gender_option_other]: 'Inne',
  [Copy.submit_button_text]: 'Prześlij',
  [Copy.field_required_label]: 'wymagane',
  [Copy.field_optional_label]: 'opcjonalne',
  [Copy.picklist_mask_general]: 'Wybierz...',
  [Copy.thank_you_message]: 'Dziękuję Ci!',

  [Copy.self_onboarding_get_started_text]: 'Aby rozpocząć, wybierz preferowany język',
  [Copy.self_onboarding_error_heading]: 'Przepraszamy, coś poszło nie tak',
  [Copy.self_onboarding_error_text]:
    'Możliwe, że wystąpił problem z podanymi danymi: skontaktuj się ze swoim przełożonym lub personelem w miejscu pracy, aby uzyskać pełne przeszkolenie.',
  [Copy.self_onboarding_profile_header]: 'Twój profil',
  [Copy.self_onboarding_profile_instructions]:
    'Uzupełnij poniższe informacje, aby rozpocząć. Wszystkie pola, które nie są oznaczone jako "opcjonalne" są wymagane.',
  [Copy.self_onboarding_matched_worker_heading]: 'Wszystko jest już gotowe!',
  [Copy.self_onboarding_matched_worker_text]:
    'Wygląda na to, że posiadasz już profil w aplikacji Odin. Skontaktuj się z przełożonym lub personelem w miejscu pracy, aby uzyskać pełne przeszkolenie.',
  [Copy.self_onboarding_emergency_contact_instructions]:
    'Prosimy o informację, z kim należy się kontaktować w nagłych przypadkach.',
  [Copy.self_onboarding_government_issued_id_header]: 'Dowód osobisty lub paszport',
  [Copy.self_onboarding_government_issued_id_instructions]:
    'Prosimy przesłać swoje prawo jazdy lub inny oficjalny dowód tożsamości, który zawiera Twoje imię i nazwisko oraz zdjęcie.',
  [Copy.front_file_label]: 'Dodaj skan przodu',
  [Copy.back_file_label]: 'Dodaj skan tyłu',
  [Copy.id_type_label]: 'Typ dokumentu tożsamości',
  [Copy.state_issued_label]: 'Stan w którym wydano dokument',
  [Copy.government_id_number_label]: 'Numer identyfikacyjny',
  [Copy.issue_date_label]: 'Data wydania',
  [Copy.expiration_date_label]: 'Termin ważności',
  [Copy.self_onboarding_osha_card_header]: 'Karta OSHA',
  [Copy.self_onboarding_osha_card_instructions]: 'Prosimy przesłać swoją kartę OSHA.',
  [Copy.card_type_label]: 'Typ karty',
  [Copy.course_date_label]: 'Data kursu',
  [Copy.osha_card_number_label]: 'Numer identyfikacyjny',
  [Copy.self_onboarding_ny_sst_card_header]:
    'Karta szkolenia w zakresie bezpieczeństwa na terenie miasta Nowy Jork (SST).',
  [Copy.self_onboarding_ny_sst_card_instructions]: 'Prześlij swoją kartę SST NY.',
  [Copy.training_connect_card_label]: 'Karta treningowa typu connect',
  [Copy.self_onboarding_profile_photo_header]: 'Twoje zdjęcie profilowe',
  [Copy.self_onboarding_profile_photo_instructions]:
    'Jeszcze tylko jedna rzecz! Prosimy o zrobienie sobie zdjęcia aparatem swojego urządzenia',
  [Copy.take_photo]: 'Zrób zdjęcie',
  [Copy.self_onboarding_profile_photo_direction_1]: 'Patrz prosto na kamerę',
  [Copy.self_onboarding_profile_photo_direction_2]: 'Upewnij się, że widoczna jest cała głowa i ramiona',
  [Copy.self_onboarding_profile_photo_direction_3]:
    'Bez nakryć głowy, okularów przeciwsłonecznych ani innych elementów zakrywających twarz',
  [Copy.self_onboarding_profile_photo_direction_4]: 'Bez uśmiechu oraz innych wyrazów twarzy',
  [Copy.self_onboarding_profile_photo_help_text]:
    'Jeśli nie możesz zrobić zdjęcia spełniającego wymagania, zostaniesz sfotografowany na miejscu.',
  [Copy.self_onboarding_verify_phone_header]: 'Zweryfikuj telefon',
  [Copy.self_onboarding_verify_phone_instructions]:
    'Dzięki aplikacji Odin możesz używać swojego urządzenia mobilnego do rejestrowania wejścia i wyjścia z miejsca pracy. Musimy zweryfikować Twoje urządzenie za pomocą SMS-a, aby rozpocząć.',
  [Copy.self_onboarding_verify_phone_instructions_no_mobile_app]:
    'Dzięki Odinowi możesz otrzymywać SMS-y, jeśli zajdzie potrzeba udostępnienia krytycznych informacji na temat miejsca pracy. Aby rozpocząć, musimy zweryfikować Twoje urządzenie za pomocą wiadomości SMS.',
  [Copy.self_onboarding_verify_phone_help_text]: 'Użyj numeru powiązanego z tym urządzeniem.',
  [Copy.self_onboarding_verify_phone_to_sign_in_header]: 'Potrzebna weryfikacja',
  [Copy.self_onboarding_verify_phone_to_sign_in_instructions]:
    'Powitanie! Wygląda na to, że możesz już mieć profil Odina. Aby kontynuować, postępuj zgodnie z poniższymi instrukcjami.',
  [Copy.self_onboarding_verify_phone_to_sign_in_action]: 'Wyślij SMS-a na numer kończący się na',
  [Copy.self_onboarding_verify_phone_to_sign_in_skip]: 'Mój numer się zmienił',
  [Copy.self_onboarding_confirm_phone_time_to_complete]: 'Pozostało {{time}} na zakończenie tego kroku',
  [Copy.self_onboarding_review_completed_profile_header]: 'Gratulacje, wszystko jest już gotowe!',
  [Copy.self_onboarding_review_completed_profile_instructions]:
    'Pokaż ten ekran zespołowi na miejscu, aby zakończyć proces wdrożenia i zabrać się do pracy',
  [Copy.self_onboarding_unable_to_verify_account_header]: 'Nie można zweryfikować konta',
  [Copy.self_onboarding_unable_to_verify_account_instructions]:
    'Pokaż ten ekran zespołowi na miejscu, aby sfinalizować wdrożenie i zabrać się do pracy',
  [Copy.add_mobile_badge]: 'Dodaj odznakę mobilną',
  [Copy.download_odin_app]: 'Pobierz aplikację Odin',
  [Copy.worker_info]: 'Informacje o pracowniku',
  [Copy.documents]: 'Dokumenty',
  [Copy.self_onboarding_no_osha_card]: 'Nie przesłano karty OSHA',
  [Copy.self_onboarding_no_ny_sst_card]: 'Nie przesłano karty NYC SST',
  [Copy.self_onboarding_no_government_id]: 'Nie przesłano żadnego dokumentu tożsamości',
  [Copy.self_onboarding_worker_match_instructions]:
    'Jesteśmy tutaj, aby zebrać wszystko, co jest wymagane, aby być pracownikiem w tej witrynie. Aby rozpocząć, wprowadź następujące informacje.',
  [Copy.continue]: 'Kontynuuj',
  [Copy.skip_for_now]: 'Pomiń to na razie',
  [Copy.save]: 'Zapisz',
  [Copy.cancel]: 'Anuluj',
  [Copy.self_onboarding_emergency_contact_header]: 'Kontakt w nagłych wypadkach',
  [Copy.self_onboarding_confirm_phone_title]: 'Proszę wpisać otrzymany kod',
  [Copy.self_onboarding_confirm_phone_instruction]:
    'Powinieneś otrzymać 6-cyfrowy kod na podany numer telefonu. Jeśli nie otrzymałeś/aś kodu po kilku minutach, możesz',
  [Copy.self_onboarding_confirm_phone_request_another_code]: 'poprosić o inny kod tutaj.',
  [Copy.self_onboarding_confirm_phone_code_not_received]: 'Nigdy nie otrzymałem mojego kodu',
  [Copy.add]: 'Dodać',
  [Copy.self_onboarding_jobsite_safety_video_header]: 'Potwierdzenie wideo dotyczące bezpieczeństwa pracowników',
  [Copy.self_onboarding_jobsite_safety_video_instructions]: 'Proszę obejrzeć poniższy film, aby ukończyć szkolenie',
  [Copy.self_onboarding_jobsite_safety_video_not_acknowledged]:
    'Nie obejrzano filmu dotyczącego bezpieczeństwa witryny',
  [Copy.self_onboarding_jobsite_safety_document_header]: 'Szkolenie dotyczące bezpieczeństwa w miejscu pracy',
  [Copy.self_onboarding_jobsite_safety_document_instructions]:
    'Proszę przeczytać poniższy dokument, aby ukończyć szkolenie',
  [Copy.self_onboarding_jobsite_safety_document_not_acknowledged]:
    'Nie przeczytano dokumentu szkoleniowego dotyczącego bezpieczeństwa na miejscu',
  [Copy.self_onboarding_lirr_blue_training_header]: 'Niebieskie szkolenie LIRR',
  [Copy.self_onboarding_lirr_blue_training_instructions]: 'Proszę przesłać dokument szkoleniowy LIRR Blue.',
  [Copy.self_onboarding_no_lirr_blue_training]: 'Nie przesłano żadnego dokumentu szkoleniowego LIRR Blue',
  [Copy.self_onboarding_confined_spaces_training_header]: 'Szkolenie dotyczące przestrzeni zamkniętych',
  [Copy.self_onboarding_confined_spaces_training_instructions]:
    'Proszę przesłać dokument dotyczący szkolenia w zakresie przestrzeni zamkniętych”',
  [Copy.self_onboarding_no_confined_spaces_training]:
    'Nie przesłano dokumentu szkoleniowego dotyczącego przestrzeni zamkniętych',
  [Copy.self_onboarding_worker_consent_header]: 'Dokument Zgody Pracownika',
  [Copy.self_onboarding_worker_consent_instructions]: 'Proszę przeczytać ten dokument w całości i wyrazić swoją zgodę.',
  [Copy.self_onboarding_worker_consent_text_start]: 'Zaznaczając to pole, potwierdzam, że przeczytałem',
  [Copy.self_onboarding_worker_consent_text_end]: 'dokument w całości i zgadzam się z jego treścią.',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_label]: 'Przeczytałem dokument',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_description]:
    'Zaznaczając to pole, potwierdzam, że przeczytałem w całości dokument szkoleniowy i rozumiem jego treść.',
  [Copy.self_onboarding_acknowledgment_video_confirmed_label]: 'Oglądałem film',
  [Copy.self_onboarding_acknowledgment_video_confirmed_description]:
    'Zaznaczając to pole, potwierdzam, że obejrzałem w całości film szkoleniowy i rozumiem jego treść.',
  [Copy.self_onboarding_acknowledgment_video_not_started_label]: 'Oglądałem film',
  [Copy.self_onboarding_acknowledgment_video_not_started_disabled_description]:
    'Naciśnij przycisk odtwarzania, aby rozpocząć',
  [Copy.self_onboarding_acknowledgment_video_in_progress_label]: 'Oglądałem film',
};

const ru: Record<Copy, string> = {
  [Copy.language]: 'Русский',
  [Copy.complete_registration]: 'Завершить регистрацию',
  [Copy.language_selector_label]: 'Язык формы',
  [Copy.self_onboarding_worker_match_header]:
    'Добро пожаловать в Odin! К сожалению, мы не смогли найти страницу, которую вы искали. Возможно, истек срок действия ссылки. Пожалуйста, свяжитесь с вашим менеджером для решения данной проблемы.',
  [Copy.preonboarding_sub_heading_step_1]:
    'Вы получили эту ссылку, поскольку будете работать на стройплощадке Odin. Предоставив свою информацию здесь до прибытия, вы сэкономите время на трейлере. Мы заботимся о вашей безопасности.',
  [Copy.preonboarding_step_1_instructions]: 'Сначала нам необходимо подтвердить вашу личность:',
  [Copy.preonboarding_heading_step_2]: 'Пожалуйста, заполните свой профиль, предоставив следующую информацию.',
  [Copy.preonboarding_heading_step_3]:
    'Отлично, еще один шаг! Пожалуйста, укажите контактное лицо для связи в экстренных случаях.',
  [Copy.preonboarding_heading_step_4]: 'Поздравляем!',
  [Copy.preonboarding_sub_heading_step_4]:
    'Спасибо за заполнение вашего профиля. Менеджер на вашем рабочем месте поможет вам завершить ориентацию.',
  [Copy.preonboarding_message_phone_match_issue]: 'Номер телефона не найден. Пожалуйста, попробуйте еще раз.',
  [Copy.preonboarding_message_worker_match]: 'Похоже, что вы уже работали с Odin!',
  [Copy.preonboarding_message_phone_prompt]: 'Чтобы подтвердить вашу личность, пожалуйста, укажите ваш номер телефона:',
  [Copy.preonboarding_message_existing_profile]:
    'Исходя из предоставленной вами информации, возможно, у вас уже есть профиль в Odin. Если это так, вы сможете пропустить эту процедуру и пройти ускоренный инструктаж на рабочем месте.',
  [Copy.preonboarding_message_email_match_issue]: 'Адрес электронной почты не найден. Пожалуйста, попробуйте еще раз.',
  [Copy.preonboarding_message_email_prompt]:
    'Чтобы подтвердить вашу личность, пожалуйста, укажите адрес электронной почты, привязанный к вашему аккаунту:',
  [Copy.preonboarding_message_expired_link_prompt]:
    'Добро пожаловать в Odin! К сожалению, мы не смогли найти страницу, которую вы искали. Возможно, истек срок действия ссылки. Пожалуйста, свяжитесь с вашим менеджером для решения данной проблемы.',
  [Copy.preonboarding_message_worker_match_no_action]:
    'Похоже, что этот работник уже существует в Odin: сейчас нет необходимости добавлять какую-либо информацию.',
  [Copy.preonboarding_message_next_steps]:
    'Информацию о работнике можно подтвердить и обновить во время регистрации в первый день на рабочем месте.',
  [Copy.worker_onboarding_header]: 'Личная информация',
  [Copy.full_name_label]: 'Имя',
  [Copy.first_name_label]: 'Имя',
  [Copy.first_name_mask_text]: 'Имя',
  [Copy.first_name_invalid_value_validation]: 'Введите действительное значение для поля "Имя"',
  [Copy.first_name_missing_value_validation]: 'Требуется заполнить поле "Имя"',
  [Copy.last_name_label]: 'Фамилия',
  [Copy.last_name_mask_text]: 'Фамилия',
  [Copy.last_name_invalid_value_validation]: 'Введите действительное значение для поля "Фамилия"',
  [Copy.last_name_missing_value_validation]: 'Требуется заполнить поле "Фамилия"',
  [Copy.middle_initial_label]: 'Среднее имя',
  [Copy.middle_initial_mask_text]: 'Среднее имя',
  [Copy.middle_initial_invalid_value_validation]: 'Введите действительное значение для поля "Среднее имя"',
  [Copy.middle_initial_missing_value_validation]: 'Требуется заполнить поле "Среднее имя"',
  [Copy.birth_date_label]: 'Дата рождения',
  [Copy.birth_date_mask_text]: 'ММ/ДД/ГГГГ',
  [Copy.birth_date_invalid_value_validation]: 'Введите действительное значение для поля "Дата рождения"',
  [Copy.birth_date_missing_value_validation]: 'Требуется заполнить поле "Дата рождения"',
  [Copy.email_label]: 'Адрес электронной почты',
  [Copy.email_mask_text]: 'Адрес электронной почты',
  [Copy.email_invalid_value_validation]: 'Введите действительное значение для поля "Адрес электронной почты"',
  [Copy.email_missing_value_validation]: 'Требуется заполнить поле "Адрес электронной почты"',
  [Copy.phone_number_label]: 'Мобильный телефон',
  [Copy.phone_number_mask_text]: 'Мобильный телефон',
  [Copy.phone_number_invalid_value_validation]: 'Введите действительное значение для поля "Номер телефона"',
  [Copy.phone_number_missing_value_validation]: 'Требуется заполнить поле "Номер телефона"',
  [Copy.address_line_1_label]: 'Адрес',
  [Copy.address_line_1_mask_text]: 'Введите адрес',
  [Copy.address_line_1_invalid_value_validation]: 'Введите действительное значение для поля "Адрес"',
  [Copy.address_line_1_missing_value_validation]: 'Требуется заполнить поле "Адрес"',
  [Copy.address_line_2_label]: 'Адресная строка 2',
  [Copy.address_line_2_mask_text]: 'Адресная строка 2',
  [Copy.address_line_2_invalid_value_validation]: 'Введите действительное значение в адресную строку 2',
  [Copy.address_line_2_missing_value_validation]: 'Требуется заполнить поле "Адресная строка 2"',
  [Copy.address_city_label]: 'Город',
  [Copy.address_city_mask_text]: 'Город',
  [Copy.address_city_invalid_value_validation]: 'Введите действительное значение для поля "Город"',
  [Copy.address_city_missing_value_validation]: 'Требуется заполнить поле "Город"',
  [Copy.address_state_label]: 'Штат',
  [Copy.address_state_missing_value_validation]: 'Требуется заполнить поле "Штат"',
  [Copy.address_zip_code_label]: 'Почтовый индекс',
  [Copy.address_zip_code_mask_text]: 'Почтовый индекс',
  [Copy.address_zip_code_invalid_value_validation]: 'Введите действительное значение для поля "Почтовый индекс"',
  [Copy.address_zip_code_missing_value_validation]: 'Требуется заполнить поле "Почтовый индекс"',
  [Copy.primary_language_label]: 'Предпочитаемый язык',
  [Copy.ssn_last_four_label]: 'Последние 4 цифры номера социального страхования',
  [Copy.ssn_last_four_mask_text]: 'Последние 4 цифры номера социального страхования',
  [Copy.ssn_last_four_invalid_value_validation]:
    'Введите действительное значение для поля "Последние 4 цифры социального страхования"',
  [Copy.ssn_last_four_missing_value_validation]: 'Требуется заполнить поле "Последние 4 цифры социального страхования"',
  [Copy.emergency_contact_name_label]: 'Имя контактного лица для связи в чрезвычайной ситуации',
  [Copy.emergency_contact_name_mask_text]: 'Имя контактного лица для связи в чрезвычайной ситуации',
  [Copy.emergency_contact_name_invalid_value_validation]:
    'Введите действительное значение для поля "Имя контактного лица для связи в чрезвычайной ситуации"',
  [Copy.emergency_contact_name_missing_value_validation]:
    'Требуется заполнить поле "Имя контактного лица для связи в чрезвычайной ситуации"',
  [Copy.emergency_contact_relationship_label]: 'Отношения с контактным лицом',
  [Copy.emergency_contact_relationship_mask_text]: 'Отношения с контактным лицом для связи в чрезвычайной ситуации',
  [Copy.emergency_contact_relationship_invalid_value_validation]:
    'Введите действительное значение для поля "Отношения с контактным лицом"',
  [Copy.emergency_contact_relationship_missing_value_validation]: 'Требуется ввести отношения с контактным лицом',
  [Copy.emergency_contact_relationship_option_boyfriend]: 'Парень',
  [Copy.emergency_contact_relationship_option_child]: 'Ребенок',
  [Copy.emergency_contact_relationship_option_co_worker]: 'Коллега по работе',
  [Copy.emergency_contact_relationship_option_family]: 'Семья',
  [Copy.emergency_contact_relationship_option_girlfriend]: 'Девушка',
  [Copy.emergency_contact_relationship_option_grandparent]: 'Бабушка или дедушка',
  [Copy.emergency_contact_relationship_option_friend]: 'Друг',
  [Copy.emergency_contact_relationship_option_parent]: 'Родитель',
  [Copy.emergency_contact_relationship_option_partner]: 'Партнер',
  [Copy.emergency_contact_relationship_option_spouse]: 'Супруг',
  [Copy.emergency_contact_relationship_option_other]: 'Другое',
  [Copy.emergency_contact_phone_label]: 'Номер телефона контактного лица для связи в чрезвычайной ситуации',
  [Copy.emergency_contact_phone_mask_text]: 'Номер телефона контактного лица для связи в чрезвычайной ситуации',
  [Copy.emergency_contact_phone_invalid_value_validation]:
    'Введите действительное значение для поля "Номер телефона контактного лица для связи в чрезвычайной ситуации"',
  [Copy.emergency_contact_phone_missing_value_validation]:
    'Требуется заполнить поле "Номер телефона контактного лица для связи в чрезвычайной ситуации"',
  [Copy.trade_label]: 'Род занятий',
  [Copy.trade_mask_text]: 'Род занятий',
  [Copy.trade_invalid_value_validation]: 'Введите действительное значение для поля "Род занятий"',
  [Copy.trade_missing_value_validation]: 'Требуется заполнить поле "Род занятий"',
  [Copy.trade_option_bricklayer]: 'Каменщик',
  [Copy.trade_option_carpenter]: 'Плотник',
  [Copy.trade_option_cement_masons]: 'Цементные каменщики',
  [Copy.trade_option_concrete_safety_manager]: 'Менеджер по безопасности бетона',
  [Copy.trade_option_concrete_workers]: 'Бетонные рабочие',
  [Copy.trade_option_crane_operator]: 'Крановщик',
  [Copy.trade_option_demolition]: 'Снос',
  [Copy.trade_option_dockbuilder]: 'Строитель доков',
  [Copy.trade_option_drill_operator]: 'Оператор буровой установки',
  [Copy.trade_option_driver]: 'Драйвер',
  [Copy.trade_option_electrician]: 'Электрик',
  [Copy.trade_option_elevator]: 'Лифт',
  [Copy.trade_option_engineer]: 'Инженер',
  [Copy.trade_option_executive]: 'Руководитель',
  [Copy.trade_option_excavators]: 'Экскаваторы',
  [Copy.trade_option_fire_safety]: 'Пожарная безопасность',
  [Copy.trade_option_flagger]: 'Флаггер',
  [Copy.trade_option_flooring]: 'Пол',
  [Copy.trade_option_glazier]: 'Стекольщик',
  [Copy.trade_option_hvac]: 'ОВиК',
  [Copy.trade_option_inspector]: 'Инспектор',
  [Copy.trade_option_insulator]: 'Изолятор',
  [Copy.trade_option_ironworker]: 'Стальщик',
  [Copy.trade_option_laborer]: 'Рабочий',
  [Copy.trade_option_lighting]: 'Освещение',
  [Copy.trade_option_maintenance]: 'Обслуживание',
  [Copy.trade_option_manager]: 'Менеджер',
  [Copy.trade_option_masonry]: 'Кладка',
  [Copy.trade_option_mechanic]: 'Механик',
  [Copy.trade_option_medical]: 'Медицинский',
  [Copy.trade_option_metal_lathers]: 'Металлическая пена',
  [Copy.trade_option_na]: 'Нет',
  [Copy.trade_option_office]: 'Офис',
  [Copy.trade_option_oiler]: 'Масленка',
  [Copy.trade_option_operating_engineer]: 'Инженер по эксплуатации',
  [Copy.trade_option_operator]: 'Оператор',
  [Copy.trade_option_painter]: 'Художник',
  [Copy.trade_option_pipefitter]: 'Трубомонтажник',
  [Copy.trade_option_plasterer]: 'Штукач',
  [Copy.trade_option_plumber]: 'Сантехник',
  [Copy.trade_option_project_manager]: 'Менеджер проекта',
  [Copy.trade_option_rebar]: 'Арматура',
  [Copy.trade_option_rigger]: 'Риггер',
  [Copy.trade_option_roofer]: 'Кровельщик',
  [Copy.trade_option_safety]: 'Безопасность',
  [Copy.trade_option_scaffolding]: 'Леса',
  [Copy.trade_option_sheetmetal]: 'Листовой металл',
  [Copy.trade_option_sprinkler]: 'Разбрызгиватель',
  [Copy.trade_option_steamfitter]: 'Паровой монтажник',
  [Copy.trade_option_steelworker]: 'Сталевар',
  [Copy.trade_option_superintendent]: 'Суперинтендант',
  [Copy.trade_option_supervisor]: 'Супервизор',
  [Copy.trade_option_surveyor]: 'Геодезист',
  [Copy.trade_option_security]: 'Безопасность',
  [Copy.trade_option_tapers]: 'Конусы',
  [Copy.trade_option_teamsters]: 'Командисты',
  [Copy.trade_option_telecommunications]: 'Телекоммуникации',
  [Copy.trade_option_tiler]: 'Плиточник',
  [Copy.trade_option_timekeeper]: 'Хронометрист',
  [Copy.trade_option_union_representative]: 'Представитель профсоюза',
  [Copy.trade_option_waterproofer]: 'Водонепроницаемый',
  [Copy.trade_option_welders]: 'Сварщики',
  [Copy.trade_option_other]: 'Другое',
  [Copy.job_title_label]: 'Профессия',
  [Copy.job_title_mask_text]: 'Профессия',
  [Copy.job_title_invalid_value_validation]: 'Введите действительное значение для поля "Профессия"',
  [Copy.job_title_missing_value_validation]: 'Требуется заполнить поле "Профессия"',
  [Copy.job_title_class_label]: 'Класс профессии',
  [Copy.job_title_class_mask_text]: 'Класс профессии',
  [Copy.job_title_class_invalid_value_validation]: 'Требуется заполнить поле "Класс профессии"',
  [Copy.job_title_class_missing_value_validation]: 'Введите действительное значение для поля "Класс профессии"',
  [Copy.job_title_option_apprentice]: 'Ученик',
  [Copy.job_title_option_foreman]: 'Бригадир',
  [Copy.job_title_option_journeyman]: 'Подмастерье',
  [Copy.job_title_option_laborer]: 'Разнорабочий',
  [Copy.job_title_option_medic]: 'Медик',
  [Copy.job_title_option_medic_supervisor]: 'Медицинский руководитель',
  [Copy.job_title_option_operating_engineer]: 'Инженер по эксплуатации',
  [Copy.job_title_option_provisional_apprentice]: 'Временный ученик',
  [Copy.job_title_option_provisional_foreman]: 'Временный бригадир',
  [Copy.job_title_option_provisional_journeyman]: 'Временный подмастерье',
  [Copy.job_title_option_shop_steward]: 'Продавец',
  [Copy.job_title_option_supervisor]: 'Супервайзер',
  [Copy.job_title_option_superintendent]: 'Суперинтендант',
  [Copy.job_title_option_office]: 'Офис',
  [Copy.job_title_option_other]: 'Другое',
  [Copy.job_title_option_n_a]: 'Н/Д',
  [Copy.union_affiliation_label]: 'Принадлежность к профсоюзу',
  [Copy.union_affiliation_mask_text]: 'Принадлежность к профсоюзу',
  [Copy.union_affiliation_invalid_value_validation]:
    'Введите действительное значение для поля "Принадлежность к профсоюзу"',
  [Copy.union_affiliation_missing_value_validation]: 'Требуется заполнить поле "Принадлежность к профсоюзу"',
  [Copy.citizenship_status_label]: 'Статус гражданства',
  [Copy.citizenship_status_invalid_value_validation]: 'Выберите действительный статус гражданства',
  [Copy.citizenship_status_missing_value_validation]: 'Требуется статус гражданства',
  [Copy.citizenship_status_option_citizen]: 'Гражданин',
  [Copy.citizenship_status_option_non_citizen]: 'Не гражданин',
  [Copy.is_veteran_label]: 'Статус военнослужащего',
  [Copy.is_veteran_mask_text]: 'Статус военнослужащего',
  [Copy.is_veteran_invalid_value_validation]: 'Введите действительное значение для поля "Статус военнослужащего"',
  [Copy.is_veteran_missing_value_validation]: 'Требуется заполнить поле "Статус военнослужащего"',
  [Copy.is_veteran_option_veteran]: 'Служил в армии',
  [Copy.is_veteran_option_non_veteran]: 'Не служил в армии',
  [Copy.contractor_label]: 'Подрядчик',
  [Copy.contractor_mask_text]: 'Подрядчик',
  [Copy.contractor_invalid_value_validation]: 'Введите действительное значение для поля "Подрядчик"',
  [Copy.contractor_missing_value_validation]: 'Требуется заполнить поле "Подрядчик"',
  [Copy.race_label]: 'Расовая принадлежность',
  [Copy.race_mask_text]: 'Расовая принадлежность',
  [Copy.race_invalid_value_validation]: 'Введите действительное значение для поля "Расовая принадлежность"',
  [Copy.race_missing_value_validation]: 'Требуется заполнить поле "Расовая принадлежность"',
  [Copy.race_option_asian_native_hawaiian_or_pacific_islander]:
    'Азиат/коренной житель Гавайских островов или островов Тихого океана',
  [Copy.race_option_black_african_american]: 'Черный / афроамериканец',
  [Copy.race_option_native_american_alaskan_native]: 'Коренной американец/коренной житель Аляски',
  [Copy.race_option_hispanic_latino]: 'Латиноамериканец/латиноамериканец',
  [Copy.race_option_white]: 'Белый',
  [Copy.race_option_other_prefer_not_to_respond]: 'Другое/предпочитаю не отвечать',
  [Copy.gender_label]: 'Пол',
  [Copy.gender_mask_text]: 'Пол',
  [Copy.gender_invalid_value_validation]: 'Введите действительное значение для поля "Пол"',
  [Copy.gender_missing_value_validation]: 'Требуется заполнить поле "Пол"',
  [Copy.gender_option_male]: 'Мужской',
  [Copy.gender_option_female]: 'Женский',
  [Copy.gender_option_other]: 'Другое',
  [Copy.submit_button_text]: 'Отправить',
  [Copy.field_required_label]: 'Обязательно',
  [Copy.field_optional_label]: 'Необязательно',
  [Copy.picklist_mask_general]: 'Выберите...',
  [Copy.thank_you_message]: 'Благодарю вас!',

  [Copy.self_onboarding_get_started_text]: 'Чтобы начать, пожалуйста, выберите язык',
  [Copy.self_onboarding_error_heading]: 'Извините, что-то пошло не так',
  [Copy.self_onboarding_error_text]:
    'Возможно, возникла проблема с предоставленной вами информацией: пожалуйста, обратитесь к непосредственному руководителю или работникам на стройплощадке.',
  [Copy.self_onboarding_profile_header]: 'Ваш профиль',
  [Copy.self_onboarding_profile_instructions]: 'Заполните все необходимые поля, чтобы начать.',
  [Copy.self_onboarding_matched_worker_heading]: 'Все готово!',
  [Copy.self_onboarding_matched_worker_text]:
    'Похоже, что у вас уже есть профиль Odin. Пожалуйста, обратитесь к своему руководителю или работникам на стройплощадке.',
  [Copy.self_onboarding_emergency_contact_instructions]:
    'Пожалуйста, оставьте контакты лица, с которым можно связаться в чрезвычайной ситуации.',
  [Copy.self_onboarding_government_issued_id_header]: 'Документ, выданный государством',
  [Copy.self_onboarding_government_issued_id_instructions]:
    'Пожалуйста, загрузите водительское удостоверение или другое официальное удостоверение личности с вашим полным именем и фотографией.',
  [Copy.front_file_label]: 'Добавить лицевую сторону',
  [Copy.back_file_label]: 'Добавить обратную сторону',
  [Copy.id_type_label]: 'Тип документа',
  [Copy.state_issued_label]: 'Страна, выдавшая документ',
  [Copy.government_id_number_label]: 'Номер документа',
  [Copy.issue_date_label]: 'Дата выдачи',
  [Copy.expiration_date_label]: 'Дата истечения срока действия',
  [Copy.self_onboarding_osha_card_header]: 'OSHA Card',
  [Copy.self_onboarding_osha_card_instructions]: 'Пожалуйста, загрузите OSHA Card.',
  [Copy.card_type_label]: 'Тип',
  [Copy.course_date_label]: 'Дата курса',
  [Copy.osha_card_number_label]: 'Номер документа',
  [Copy.self_onboarding_ny_sst_card_header]:
    'Удостоверение о прохождении обучения по технике безопасности (NYC SST) в штате Нью-Йорк',
  [Copy.self_onboarding_ny_sst_card_instructions]: 'Пожалуйста, загрузите NY SST Card.',
  [Copy.training_connect_card_label]: 'Training Connect Card',
  [Copy.self_onboarding_profile_photo_header]: 'Ваше фото профиля',
  [Copy.self_onboarding_profile_photo_instructions]:
    'И еще кое-что! Пожалуйста, сфотографируйтесь на камеру своего устройства.',
  [Copy.take_photo]: 'Сделать фото',
  [Copy.self_onboarding_profile_photo_direction_1]: 'Смотрите прямо в камеру',
  [Copy.self_onboarding_profile_photo_direction_2]: 'В кадре должны быть голова и плечи',
  [Copy.self_onboarding_profile_photo_direction_3]: 'Снимите головной убор, очки и прочие аксессуары',
  [Copy.self_onboarding_profile_photo_direction_4]: 'Не улыбайтесь, сохраняйте спокойное выражение лица',
  [Copy.self_onboarding_profile_photo_help_text]:
    'Если вы не можете сделать фотографию, соответствующую требованиям, вас сфотографируют на месте.',
  [Copy.self_onboarding_verify_phone_header]: 'Проверить мобильное устройство',
  [Copy.self_onboarding_verify_phone_instructions]:
    'С помощью Odin вы можете использовать свой телефон для учета рабочего времени на площадке. Для начала нам необходимо подтвердить ваше устройство с помощью сообщения.',
  [Copy.self_onboarding_verify_phone_instructions_no_mobile_app]:
    'С помощью Odin вы можете получать текстовые сообщения, если необходимо поделиться важной информацией о рабочей площадке. Для начала нам необходимо подтвердить ваше устройство с помощью текстового сообщения.',
  [Copy.self_onboarding_verify_phone_help_text]: 'Пожалуйста, используйте номер, связанный с данным устройством.',
  [Copy.self_onboarding_verify_phone_to_sign_in_header]: 'Необходима проверка',
  [Copy.self_onboarding_verify_phone_to_sign_in_instructions]:
    'Добро пожаловать! Похоже, у вас уже есть профиль Odin. Чтобы продолжить, следуйте инструкциям ниже.',
  [Copy.self_onboarding_verify_phone_to_sign_in_action]: 'Отправить текст на номер, заканчивающийся на',
  [Copy.self_onboarding_verify_phone_to_sign_in_skip]: 'Мой номер изменился',
  [Copy.self_onboarding_confirm_phone_time_to_complete]: '{{time}} до завершения',
  [Copy.self_onboarding_review_completed_profile_header]: 'Поздравляем, все готово!',
  [Copy.self_onboarding_review_completed_profile_instructions]:
    'Пожалуйста, покажите этот экран команде на площадке, чтобы завершить процесс регистрации и приступить к работе',
  [Copy.self_onboarding_unable_to_verify_account_header]: 'Невозможно подтвердить учетную запись',
  [Copy.self_onboarding_unable_to_verify_account_instructions]:
    'Покажите этот экран команде на месте, чтобы завершить адаптацию и приступить к работе',
  [Copy.add_mobile_badge]: 'Добавить значок',
  [Copy.download_odin_app]: 'Скачать приложение Odin',
  [Copy.worker_info]: 'Информация о работнике',
  [Copy.documents]: 'Документы',
  [Copy.self_onboarding_no_osha_card]: 'Не загружен следующий документ: OSHA Card',
  [Copy.self_onboarding_no_ny_sst_card]: 'Не загружен следующий документ: NY SST Card',
  [Copy.self_onboarding_no_government_id]: 'Не загружен следующий документ: удостоверение личности',
  [Copy.self_onboarding_worker_match_instructions]:
    'Мы здесь, чтобы собрать все, что требуется для работы на этом сайте. Чтобы начать, введите следующую информацию.',
  [Copy.continue]: 'Продолжить',
  [Copy.skip_for_now]: 'Пропустить сейчас',
  [Copy.save]: 'Сохранить',
  [Copy.cancel]: 'Отменить',
  [Copy.self_onboarding_emergency_contact_header]: 'Контактное лицо для экстренной помощи',
  [Copy.self_onboarding_confirm_phone_title]: 'Пожалуйста, введите полученный код',
  [Copy.self_onboarding_confirm_phone_instruction]:
    'Вы должны были получить 6-значный код на указанный вами номер телефона. Если вы не получили код через несколько минут, вы можете',
  [Copy.self_onboarding_confirm_phone_request_another_code]: 'запросить другой код.',
  [Copy.self_onboarding_confirm_phone_code_not_received]: 'Я так и не получил свой код',
  [Copy.add]: 'Добавлять',
  [Copy.self_onboarding_jobsite_safety_video_header]: 'Видео-признание по безопасности труда',
  [Copy.self_onboarding_jobsite_safety_video_instructions]:
    'Пожалуйста, посмотрите следующее видео, чтобы завершить обучение',
  [Copy.self_onboarding_jobsite_safety_video_not_acknowledged]: 'Видео о безопасности на объекте не было просмотрено',
  [Copy.self_onboarding_jobsite_safety_document_header]: 'Тренинг по безопасности на объекте',
  [Copy.self_onboarding_jobsite_safety_document_instructions]:
    'Пожалуйста, прочитайте следующий документ, чтобы завершить обучение',
  [Copy.self_onboarding_jobsite_safety_document_not_acknowledged]:
    'Документ по обучению технике безопасности на объекте не прочитан',
  [Copy.self_onboarding_lirr_blue_training_header]: 'Синий тренинг LIRR',
  [Copy.self_onboarding_lirr_blue_training_instructions]: 'Пожалуйста, загрузите документ об обучении LIRR Blue',
  [Copy.self_onboarding_no_lirr_blue_training]: 'Документ LIRR Blue Training не был загружен',
  [Copy.self_onboarding_confined_spaces_training_header]: 'Тренинг в замкнутых пространствах',
  [Copy.self_onboarding_confined_spaces_training_instructions]:
    'Пожалуйста, загрузите документ об обучении работе в замкнутых пространствах',
  [Copy.self_onboarding_no_confined_spaces_training]:
    'Документ по обучению работе в замкнутых пространствах не загружен',
  [Copy.self_onboarding_worker_consent_header]: 'Документ Согласия Работника',
  [Copy.self_onboarding_worker_consent_instructions]:
    'Пожалуйста, прочтите этот документ полностью и дайте свое согласие.',
  [Copy.self_onboarding_worker_consent_text_start]: 'Отметив этот флажок, я подтверждаю, что прочитал',
  [Copy.self_onboarding_worker_consent_text_end]: 'документ в целом и согласен с его содержанием.',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_label]: 'Я прочитал документ',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_description]:
    'Установив этот флажок, я подтверждаю, что прочитал учебный документ полностью и понимаю его содержание.',
  [Copy.self_onboarding_acknowledgment_video_confirmed_label]: 'Я посмотрел видео',
  [Copy.self_onboarding_acknowledgment_video_confirmed_description]:
    'Установив этот флажок, я подтверждаю, что я просмотрел обучающее видео полностью и понимаю его содержание.',
  [Copy.self_onboarding_acknowledgment_video_not_started_label]: 'Я посмотрел видео',
  [Copy.self_onboarding_acknowledgment_video_not_started_disabled_description]: 'Нажмите воспроизведение, чтобы начать',
  [Copy.self_onboarding_acknowledgment_video_in_progress_label]: 'Я посмотрел видео',
};

const hu: Record<Copy, string> = {
  [Copy.language]: 'Magyar',
  [Copy.complete_registration]: 'Teljes regisztráció',
  [Copy.language_selector_label]: 'Formanyelv',
  [Copy.self_onboarding_worker_match_header]: 'Üdvözöljük az Odinon!',
  [Copy.preonboarding_sub_heading_step_1]:
    'Azért kapta ezt a linket, mert az Odin egyik munkaterületén fog dolgozni. Ha megérkezésed előtt megadod itt az adataidat, időt takarítasz meg a pótkocsinál. Elkötelezettek vagyunk az Ön biztonsága iránt.',
  [Copy.preonboarding_step_1_instructions]: 'Először meg kell erősítenünk a személyazonosságát:',
  [Copy.preonboarding_heading_step_2]: 'A profil kitöltéséhez kérjük, adja meg a következő információkat',
  [Copy.preonboarding_heading_step_3]:
    'Nagyszerű, már csak egy lépés! Kérjük, adjon meg egy elérhetőséget vészhelyzet esetére.',
  [Copy.preonboarding_heading_step_4]: 'Gratulálunk!',
  [Copy.preonboarding_sub_heading_step_4]:
    'Köszönjük, hogy kitöltötte profilját. A munkahelyén a helyszíni menedzser segít a tájékozódás befejezésében.',
  [Copy.preonboarding_message_phone_match_issue]: 'A telefonszám nem egyezik. Kérjük, próbálja meg újra.',
  [Copy.preonboarding_message_worker_match]: 'Úgy tűnik, Ön már dolgozott korábban az Odinnal!',
  [Copy.preonboarding_message_phone_prompt]: 'A személyazonosságának megerősítéséhez kérjük, adja meg telefonszámát:',
  [Copy.preonboarding_message_existing_profile]:
    'Az Ön által megadott információk alapján előfordulhat, hogy már rendelkezik Odin-profillal. Ha igen, akkor ezt a folyamatot átugorhatja, és a munkahelyére érkezve gyorsított eligazításon vehet részt.',
  [Copy.preonboarding_message_email_match_issue]: 'Az e-mail címe nem felel meg. Kérjük, próbálja meg újra.',
  [Copy.preonboarding_message_email_prompt]: 'Azonosságának megerősítéséhez kérjük, adja meg fiókja e-mail címét:',
  [Copy.preonboarding_message_expired_link_prompt]:
    'Üdvözöljük az Odinnál! Sajnos nem találtuk meg az Ön által keresett oldalt. Lehet, hogy a megadott link már lejárt. Kérjük, további segítségért forduljon a menedzseréhez.',
  [Copy.preonboarding_message_worker_match_no_action]:
    'Úgy tűnik, hogy ez a dolgozó már létezik az Odinban: jelenleg nem szükséges semmilyen információt hozzáadni.',
  [Copy.preonboarding_message_next_steps]:
    'A munkavállaló adatait a munkaterületre történő felvétel során lehet megerősíteni és frissíteni az első munkanapon.',
  [Copy.worker_onboarding_header]: 'Személyes adatok',
  [Copy.full_name_label]: 'Név',
  [Copy.first_name_label]: 'Keresztnév',
  [Copy.first_name_mask_text]: 'Keresztnév',
  [Copy.first_name_invalid_value_validation]: 'Adjon meg egy érvényes keresztnevet',
  [Copy.first_name_missing_value_validation]: 'Keresztnév kötelező',
  [Copy.last_name_label]: 'Vezetéknév',
  [Copy.last_name_mask_text]: 'Vezetéknév',
  [Copy.last_name_invalid_value_validation]: 'Adjon meg egy érvényes vezetéknevet',
  [Copy.last_name_missing_value_validation]: 'Utónév kötelező',
  [Copy.middle_initial_label]: 'Középső név kezdőbetű',
  [Copy.middle_initial_mask_text]: 'Középső név kezdőbetű',
  [Copy.middle_initial_invalid_value_validation]: 'Adjon meg egy érvényes középső név kezdőbetűt',
  [Copy.middle_initial_missing_value_validation]: 'Középső név kezdőbetű kötelező',
  [Copy.birth_date_label]: 'Születési dátum',
  [Copy.birth_date_mask_text]: 'HH/NN/ÉÉÉÉ',
  [Copy.birth_date_invalid_value_validation]: 'Adjon meg egy érvényes dátumot',
  [Copy.birth_date_missing_value_validation]: 'Születési dátum kötelező',
  [Copy.email_label]: 'E-mail cím',
  [Copy.email_mask_text]: 'E-mail cím',
  [Copy.email_invalid_value_validation]: 'Adjon meg egy érvényes e-mailt',
  [Copy.email_missing_value_validation]: 'E-mail kötelező',
  [Copy.phone_number_label]: 'Mobiltelefon',
  [Copy.phone_number_mask_text]: 'Mobiltelefon',
  [Copy.phone_number_invalid_value_validation]: 'Adjon meg egy érvényes telefonszámot',
  [Copy.phone_number_missing_value_validation]: 'Telefonszám kötelező',
  [Copy.address_line_1_label]: 'Cím',
  [Copy.address_line_1_mask_text]: 'Adjon meg egy címet',
  [Copy.address_line_1_invalid_value_validation]: 'Adjon meg egy érvényes címet',
  [Copy.address_line_1_missing_value_validation]: 'A cím megadása kötelező',
  [Copy.address_line_2_label]: 'Cím 2. sor',
  [Copy.address_line_2_mask_text]: 'Cím 2. sor',
  [Copy.address_line_2_invalid_value_validation]: 'Adjon meg egy érvényes 2. sor címet',
  [Copy.address_line_2_missing_value_validation]: 'Cím 2. sor kötelező',
  [Copy.address_city_label]: 'Város',
  [Copy.address_city_mask_text]: 'Város',
  [Copy.address_city_invalid_value_validation]: 'Adjon meg egy érvényes várost',
  [Copy.address_city_missing_value_validation]: 'Város kötelező',
  [Copy.address_state_label]: 'Állam',
  [Copy.address_state_missing_value_validation]: 'Állam kötelező',
  [Copy.address_zip_code_label]: 'Irányítószám',
  [Copy.address_zip_code_mask_text]: 'Irányítószám',
  [Copy.address_zip_code_invalid_value_validation]: 'Adjon meg egy érvényes irányítószámot',
  [Copy.address_zip_code_missing_value_validation]: 'Irányítószám kötelező',
  [Copy.primary_language_label]: 'Preferált nyelv',
  [Copy.ssn_last_four_label]: 'A társadalombiztosítási szám utolsó 4 számjegye',
  [Copy.ssn_last_four_mask_text]: 'A társadalombiztosítási szám utolsó 4 számjegye',
  [Copy.ssn_last_four_invalid_value_validation]: 'Adjon meg egy érvényes számot',
  [Copy.ssn_last_four_missing_value_validation]: 'Az utolsó 4 kötelező',
  [Copy.emergency_contact_name_label]: 'Vészhelyzeti kapcsolattartó neve',
  [Copy.emergency_contact_name_mask_text]: 'Vészhelyzeti kapcsolattartó neve',
  [Copy.emergency_contact_name_invalid_value_validation]: 'Adja meg a vészhelyzeti kapcsolattartó érvényes nevét',
  [Copy.emergency_contact_name_missing_value_validation]: 'Vészhelyzeti kapcsolattartó neve kötelező',
  [Copy.emergency_contact_relationship_label]: 'Vészhelyzeti kapcsolattartó kapcsolata',
  [Copy.emergency_contact_relationship_mask_text]: 'Vészhelyzeti kapcsolattartó kapcsolata',
  [Copy.emergency_contact_relationship_invalid_value_validation]:
    'Adjon meg egy érvényes vészhelyzeti kapcsolattartási kapcsolatot',
  [Copy.emergency_contact_relationship_missing_value_validation]: 'Sürgősségi kapcsolattartási kapcsolat kötelező',
  [Copy.emergency_contact_relationship_option_boyfriend]: 'Barát',
  [Copy.emergency_contact_relationship_option_child]: 'Gyermek',
  [Copy.emergency_contact_relationship_option_co_worker]: 'Munkatárs',
  [Copy.emergency_contact_relationship_option_family]: 'Családtag',
  [Copy.emergency_contact_relationship_option_girlfriend]: 'Barátnő',
  [Copy.emergency_contact_relationship_option_grandparent]: 'Nagyszülő',
  [Copy.emergency_contact_relationship_option_friend]: 'Barát',
  [Copy.emergency_contact_relationship_option_parent]: 'Szülő',
  [Copy.emergency_contact_relationship_option_partner]: 'Partner',
  [Copy.emergency_contact_relationship_option_spouse]: 'Házastárs',
  [Copy.emergency_contact_relationship_option_other]: 'Egyéb',
  [Copy.emergency_contact_phone_label]: 'Vészhelyzeti kapcsolattartó telefonszáma',
  [Copy.emergency_contact_phone_mask_text]: 'Vészhelyzeti kapcsolattartó telefonszáma',
  [Copy.emergency_contact_phone_invalid_value_validation]: 'Adjon meg egy érvényes telefonszámot',
  [Copy.emergency_contact_phone_missing_value_validation]: 'Telefonszám kötelező',
  [Copy.trade_label]: 'Szakma',
  [Copy.trade_mask_text]: 'Szakma',
  [Copy.trade_invalid_value_validation]: 'Adjon meg egy érvényes szakmát',
  [Copy.trade_missing_value_validation]: 'Szakma kötelező',
  [Copy.trade_option_bricklayer]: 'Kőműves',
  [Copy.trade_option_carpenter]: 'Asztalos',
  [Copy.trade_option_cement_masons]: 'Cement Masons',
  [Copy.trade_option_concrete_safety_manager]: 'Concrete Safety Manager',
  [Copy.trade_option_concrete_workers]: 'Betonmunkások',
  [Copy.trade_option_crane_operator]: 'Crane Operator',
  [Copy.trade_option_demolition]: 'Demolition',
  [Copy.trade_option_dockbuilder]: 'Dockbuilder',
  [Copy.trade_option_drill_operator]: 'Fúrókezelő',
  [Copy.trade_option_driver]: 'Sofőr',
  [Copy.trade_option_electrician]: 'villanyszerelő',
  [Copy.trade_option_elevator]: 'Lift',
  [Copy.trade_option_engineer]: 'mérnök',
  [Copy.trade_option_executive]: 'Vezető',
  [Copy.trade_option_excavators]: 'Kotrógépek',
  [Copy.trade_option_fire_safety]: 'Tűzbiztonság',
  [Copy.trade_option_flagger]: 'Flagger',
  [Copy.trade_option_flooring]: 'Padlóburkolat',
  [Copy.trade_option_glazier]: 'Glazier',
  [Copy.trade_option_hvac]: 'HVAC',
  [Copy.trade_option_inspector]: 'Felügyelő',
  [Copy.trade_option_insulator]: 'Szigetelő',
  [Copy.trade_option_ironworker]: 'Ironworker',
  [Copy.trade_option_laborer]: 'Munkásember',
  [Copy.trade_option_lighting]: 'Lighting',
  [Copy.trade_option_maintenance]: 'Karbantartás',
  [Copy.trade_option_manager]: 'Manager',
  [Copy.trade_option_masonry]: 'Kőműves',
  [Copy.trade_option_mechanic]: 'Mechanic',
  [Copy.trade_option_medical]: 'Orvosi',
  [Copy.trade_option_metal_lathers]: 'Fémhabok',
  [Copy.trade_option_na]: 'NA',
  [Copy.trade_option_office]: 'Iroda',
  [Copy.trade_option_oiler]: 'Oiler',
  [Copy.trade_option_operating_engineer]: 'Üzemeltető mérnök',
  [Copy.trade_option_operator]: 'Operator',
  [Copy.trade_option_painter]: 'Festő',
  [Copy.trade_option_pipefitter]: 'Pipefitter',
  [Copy.trade_option_plasterer]: 'Gipszoló',
  [Copy.trade_option_plumber]: 'Plumber',
  [Copy.trade_option_project_manager]: 'Projektmenedzser',
  [Copy.trade_option_rebar]: 'Rebar',
  [Copy.trade_option_rigger]: 'Rigger',
  [Copy.trade_option_roofer]: 'Tetőfedő',
  [Copy.trade_option_safety]: 'Biztonság',
  [Copy.trade_option_scaffolding]: 'Scaffolding',
  [Copy.trade_option_sheetmetal]: 'Sheetmetal',
  [Copy.trade_option_sprinkler]: 'Sprrinkler',
  [Copy.trade_option_steamfitter]: 'Steamfitter',
  [Copy.trade_option_steelworker]: 'Acélmunkás',
  [Copy.trade_option_superintendent]: 'Superintendent',
  [Copy.trade_option_supervisor]: 'Supervisor',
  [Copy.trade_option_surveyor]: 'Fölmérő',
  [Copy.trade_option_security]: 'Biztonság',
  [Copy.trade_option_tapers]: 'Tapers',
  [Copy.trade_option_teamsters]: 'Teamsters',
  [Copy.trade_option_telecommunications]: 'Telekommunikáció',
  [Copy.trade_option_tiler]: 'Csempéző',
  [Copy.trade_option_timekeeper]: 'Timekeeper',
  [Copy.trade_option_union_representative]: 'Unió képviselője',
  [Copy.trade_option_waterproofer]: 'Waterproofer',
  [Copy.trade_option_welders]: 'Hegesztők',
  [Copy.trade_option_other]: 'Egyéb',
  [Copy.job_title_label]: 'Munkakör címe',
  [Copy.job_title_mask_text]: 'Munkakör címe',
  [Copy.job_title_invalid_value_validation]: 'Adjon meg egy érvényes munkaköri címet',
  [Copy.job_title_missing_value_validation]: 'Munkakör címe kötelező',
  [Copy.job_title_class_label]: 'Munkakör cím osztály',
  [Copy.job_title_class_mask_text]: 'Munkakör cím osztály',
  [Copy.job_title_class_invalid_value_validation]: 'Munkakör cím osztály kötelező',
  [Copy.job_title_class_missing_value_validation]: 'Adjon meg egy érvényes munkaköri cím osztályt',
  [Copy.job_title_option_apprentice]: 'Tanonc',
  [Copy.job_title_option_foreman]: 'Foreman',
  [Copy.job_title_option_journeyman]: 'Journeyman',
  [Copy.job_title_option_laborer]: 'Munkásos',
  [Copy.job_title_option_medic]: 'Medic',
  [Copy.job_title_option_medic_supervisor]: 'Medic Supervisor',
  [Copy.job_title_option_operating_engineer]: 'Üzemeltető mérnök',
  [Copy.job_title_option_provisional_apprentice]: 'Ideiglenes tanuló',
  [Copy.job_title_option_provisional_foreman]: 'Ideiglenes Foreman',
  [Copy.job_title_option_provisional_journeyman]: 'Ideiglenes Journeyman',
  [Copy.job_title_option_shop_steward]: 'Szolgáltató',
  [Copy.job_title_option_supervisor]: 'Supervisor',
  [Copy.job_title_option_superintendent]: 'Felügyelő',
  [Copy.job_title_option_office]: 'Iroda',
  [Copy.job_title_option_other]: 'Egyéb',
  [Copy.job_title_option_n_a]: 'N/A',
  [Copy.union_affiliation_label]: 'Szakszervezeti hovatartozás',
  [Copy.union_affiliation_mask_text]: 'Szakszervezeti hovatartozás',
  [Copy.union_affiliation_invalid_value_validation]: 'Adjon meg egy érvényes szakszervezeti tagságot',
  [Copy.union_affiliation_missing_value_validation]: 'Szakszervezeti hovatartozás kötelező',
  [Copy.citizenship_status_label]: 'Állampolgári státusz',
  [Copy.citizenship_status_invalid_value_validation]: 'Válasszon ki egy érvényes állampolgársági státuszt',
  [Copy.citizenship_status_missing_value_validation]: 'Állampolgársági státusz szükséges',
  [Copy.citizenship_status_option_citizen]: 'Polgár',
  [Copy.citizenship_status_option_non_citizen]: 'Nem Állampolgár',
  [Copy.is_veteran_label]: 'Veterán státusz',
  [Copy.is_veteran_mask_text]: 'Veterán státusz',
  [Copy.is_veteran_invalid_value_validation]: 'Adja meg az érvényes veterán státuszt',
  [Copy.is_veteran_missing_value_validation]: 'Veterán státusz kötelező',
  [Copy.is_veteran_option_veteran]: 'Veterán',
  [Copy.is_veteran_option_non_veteran]: 'Nem veterán',
  [Copy.contractor_label]: 'Vállalkozó',
  [Copy.contractor_mask_text]: 'Vállalkozó',
  [Copy.contractor_invalid_value_validation]: 'Adjon meg egy érvényes vállalkozót',
  [Copy.contractor_missing_value_validation]: 'Vállalkozó kötelező',
  [Copy.race_label]: 'Faj',
  [Copy.race_mask_text]: 'Faj',
  [Copy.race_invalid_value_validation]: 'Adjon meg egy érvényes versenyt',
  [Copy.race_missing_value_validation]: 'Faj kötelező',
  [Copy.race_option_asian_native_hawaiian_or_pacific_islander]:
    'Ázsiai/bennszülött hawaii vagy csendes-óceáni szigetlakó',
  [Copy.race_option_black_african_american]: 'Fekete/afrikai amerikai',
  [Copy.race_option_native_american_alaskan_native]: 'Bennszülött amerikai/alaszkai bennszülött',
  [Copy.race_option_hispanic_latino]: 'Spanyol/latin',
  [Copy.race_option_white]: 'Fehér',
  [Copy.race_option_other_prefer_not_to_respond]: 'Egyéb/Nem akarok válaszolni',
  [Copy.gender_label]: 'Nem',
  [Copy.gender_mask_text]: 'Nem',
  [Copy.gender_invalid_value_validation]: 'Adjon meg egy érvényes nemet',
  [Copy.gender_missing_value_validation]: 'Nem kötelező',
  [Copy.gender_option_male]: 'Férfi',
  [Copy.gender_option_female]: 'Nő',
  [Copy.gender_option_other]: 'Egyéb',
  [Copy.submit_button_text]: 'Elküldeni',
  [Copy.field_required_label]: 'Kötelező',
  [Copy.field_optional_label]: 'Nem kötelező',
  [Copy.picklist_mask_general]: 'Válassza ki...',
  [Copy.thank_you_message]: 'Köszönöm!',

  [Copy.self_onboarding_get_started_text]: 'A kezdéshez kérjük, válassza ki az Ön által preferált nyelvet',
  [Copy.self_onboarding_error_heading]: 'Sajnáljuk, valami rosszul sült el',
  [Copy.self_onboarding_error_text]:
    'Lehet, hogy probléma van az Ön által megadott adatokkal: kérjük, érdeklődjön a helyszíni felügyelőjénél vagy a munkaterület munkatársainál, hogy befejezhesse az eligazodást.',
  [Copy.self_onboarding_profile_header]: 'Az Ön profilja',
  [Copy.self_onboarding_profile_instructions]:
    'Kérjük, töltse ki az alábbi információkat a kezdéshez. Minden mező kitöltése kötelező, kivéve, ha a "Nem kötelező" jelölés szerepel.',
  [Copy.self_onboarding_matched_worker_heading]: 'Minden készen van!',
  [Copy.self_onboarding_matched_worker_text]:
    'Úgy tűnik, hogy Önnek már van Odin profilja. Kérjük, érdeklődjön a helyszíni felügyelőjénél vagy a munkaterület munkatársainál az eligazítás befejezéséhez.',
  [Copy.self_onboarding_emergency_contact_instructions]:
    'Kérjük, tudassa velünk, hogy vészhelyzet esetén kihez forduljon.',
  [Copy.self_onboarding_government_issued_id_header]: 'Kormány által kiállított személyi igazolvány',
  [Copy.self_onboarding_government_issued_id_instructions]:
    'Kérjük, töltse fel a jogosítványát vagy más hivatalos személyi igazolványát, amely tartalmazza a teljes nevét és fényképét.',
  [Copy.front_file_label]: 'Elülső oldal hozzáadása',
  [Copy.back_file_label]: 'Hátsó oldal hozzáadása',
  [Copy.id_type_label]: 'Személyi igazolvány típusa',
  [Copy.state_issued_label]: 'Állam által kiállított',
  [Copy.government_id_number_label]: 'Személyi igazolvány száma',
  [Copy.issue_date_label]: 'Kiadási dátum',
  [Copy.expiration_date_label]: 'Lejárati dátum',
  [Copy.self_onboarding_osha_card_header]: 'OSHA-kártya',
  [Copy.self_onboarding_osha_card_instructions]: 'Kérjük, töltse fel OSHA-kártyáját.',
  [Copy.card_type_label]: 'Kártya típusa',
  [Copy.course_date_label]: 'Tanfolyam dátuma',
  [Copy.osha_card_number_label]: 'Azonosítószám',
  [Copy.self_onboarding_ny_sst_card_header]: 'NYC Site Safety Training (SST) kártya',
  [Copy.self_onboarding_ny_sst_card_instructions]: 'Kérjük, töltse fel a NY SST kártyáját.',
  [Copy.training_connect_card_label]: 'Képzési Connect kártya',
  [Copy.self_onboarding_profile_photo_header]: 'Az Ön profilképe',
  [Copy.self_onboarding_profile_photo_instructions]:
    'Még egy dolog! Kérjük, készítsen egy fényképet magáról a készülék kamerájával.',
  [Copy.take_photo]: 'Fénykép készítése',
  [Copy.self_onboarding_profile_photo_direction_1]: 'Nézzen egyenesen a kamerába',
  [Copy.self_onboarding_profile_photo_direction_2]: 'A képen a teljes feje és a válla legyen rajta',
  [Copy.self_onboarding_profile_photo_direction_3]: 'Ne viseljen kalapot, napszemüveget vagy más akadályozó tárgyat',
  [Copy.self_onboarding_profile_photo_direction_4]: 'Semmi mosolygás vagy más arckifejezés',
  [Copy.self_onboarding_profile_photo_help_text]:
    'Ha nem tud a követelményeknek megfelelő fényképet készíteni, akkor a helyszínen fogják lefényképezni.',
  [Copy.self_onboarding_verify_phone_header]: 'Mobilkészülék ellenőrzése',
  [Copy.self_onboarding_verify_phone_instructions]:
    'Az Odin segítségével a mobilkészülékét használhatja a munkaterületen történő be- és kijelentkezéshez. A kezdéshez szöveges üzenetben kell igazolnunk a készülékét.',
  [Copy.self_onboarding_verify_phone_instructions_no_mobile_app]:
    'Az Odinnal szöveges üzeneteket kaphat, ha kritikus információkat kell megosztani a munkaterületről. A kezdéshez igazolnunk kell eszközét szöveges üzenetben.',
  [Copy.self_onboarding_verify_phone_help_text]: 'Kérjük, használja a készülékhez tartozó számot.',
  [Copy.self_onboarding_verify_phone_to_sign_in_header]: 'Ellenőrzés szükséges',
  [Copy.self_onboarding_verify_phone_to_sign_in_instructions]:
    'Üdvözöljük! Úgy tűnik, már rendelkezhet Odin-profillal. A folytatáshoz kövesse az alábbi utasításokat.',
  [Copy.self_onboarding_verify_phone_to_sign_in_action]: 'Send text to number végződő',
  [Copy.self_onboarding_verify_phone_to_sign_in_skip]: 'A számom megváltozott',
  [Copy.self_onboarding_confirm_phone_time_to_complete]: '{{time}} a befejezéshez',
  [Copy.self_onboarding_review_completed_profile_header]: 'Gratulálunk, minden kész!',
  [Copy.self_onboarding_review_completed_profile_instructions]:
    'Kérjük, mutassa meg ezt a képernyőt a helyszíni csapatnak, hogy véglegesítse a beilleszkedést és munkához láthasson',
  [Copy.self_onboarding_unable_to_verify_account_header]: 'Nem sikerült ellenőrizni a fiókot',
  [Copy.self_onboarding_unable_to_verify_account_instructions]:
    'Kérjük, mutassa meg ezt a képernyőt a helyszíni csapatnak, hogy véglegesítse a felvételt és kezdje el a munkát',
  [Copy.add_mobile_badge]: 'Mobil jelvény hozzáadása',
  [Copy.download_odin_app]: 'Odin alkalmazás letöltése',
  [Copy.worker_info]: 'Munkavállalói információk',
  [Copy.documents]: 'Dokumentumok',
  [Copy.self_onboarding_no_osha_card]: 'Nem töltött fel OSHA-kártyát',
  [Copy.self_onboarding_no_ny_sst_card]: 'Nem töltött fel NY SST kártya',
  [Copy.self_onboarding_no_government_id]: 'Nem töltött fel kormányzati azonosítót',
  [Copy.self_onboarding_worker_match_instructions]:
    'Azért vagyunk itt, hogy összegyűjtsünk mindent, ami ahhoz szükséges, hogy ezen a webhelyen dolgozhassunk. A kezdéshez adja meg a következő információkat.',
  [Copy.continue]: 'Folytatni',
  [Copy.skip_for_now]: 'Most hagyd ki',
  [Copy.save]: 'Menteni',
  [Copy.cancel]: 'Megszüntetni',
  [Copy.self_onboarding_emergency_contact_header]: 'Vész esetén értesítendő',
  [Copy.self_onboarding_confirm_phone_title]: 'Kérjük, írja be a kapott kódot',
  [Copy.self_onboarding_confirm_phone_instruction]:
    'Egy 6 számjegyű kódot kellett volna kapnia a megadott telefonszámra. Ha néhány percen belül nem kapja meg a kódot, megteheti',
  [Copy.self_onboarding_confirm_phone_request_another_code]: 'Kérjen másik kódot itt.',
  [Copy.self_onboarding_confirm_phone_code_not_received]: 'Soha nem kaptam meg a kódomat',
  [Copy.add]: 'Hozzáadás',
  [Copy.self_onboarding_jobsite_safety_video_header]: 'Munkabiztonsági videó elismerése',
  [Copy.self_onboarding_jobsite_safety_video_instructions]:
    'Kérjük, nézze meg a következő videót az edzés befejezéséhez',
  [Copy.self_onboarding_jobsite_safety_video_not_acknowledged]: 'A webhely biztonsági videóját nem nézték meg',
  [Copy.self_onboarding_jobsite_safety_document_header]: 'Helyszínbiztonsági képzés',
  [Copy.self_onboarding_jobsite_safety_document_instructions]:
    'Kérjük, olvassa el a következő dokumentumot a képzés befejezéséhez.',
  [Copy.self_onboarding_jobsite_safety_document_not_acknowledged]:
    'A helyszíni biztonsági oktatási dokumentumot nem olvasták el',
  [Copy.self_onboarding_lirr_blue_training_header]: 'LIRR Blue Training',
  [Copy.self_onboarding_lirr_blue_training_instructions]: 'Kérjük, töltse fel LIRR Blue Training dokumentumát.',
  [Copy.self_onboarding_no_lirr_blue_training]: 'Nem lett feltöltve LIRR Blue Training dokumentum',
  [Copy.self_onboarding_confined_spaces_training_header]: 'Zárt terek képzése',
  [Copy.self_onboarding_confined_spaces_training_instructions]: 'Kérjük, töltse fel a zárt terek képzési dokumentumát.',
  [Copy.self_onboarding_no_confined_spaces_training]: 'Nem töltöttek fel zárt terek képzési dokumentumot',
  [Copy.self_onboarding_worker_consent_header]: 'Munkavállalói Hozzájárulás Dokumentuma',
  [Copy.self_onboarding_worker_consent_instructions]:
    'Kérjük, olvassa el ezt a dokumentumot teljes egészében, és adja meg hozzájárulását.',
  [Copy.self_onboarding_worker_consent_text_start]: 'A doboz bejelölésével elismerem, hogy elolvastam a',
  [Copy.self_onboarding_worker_consent_text_end]: 'dokumentumot teljes egészében és egyetértek a tartalmával.',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_label]: 'Elolvastam a dokumentumot',
  [Copy.self_onboarding_acknowledgment_doc_confirmed_description]:
    'A négyzet bejelölésével elismerem, hogy a képzési dokumentumot teljes egészében elolvastam, és megértettem annak tartalmát.',
  [Copy.self_onboarding_acknowledgment_video_confirmed_label]: 'Megnéztem a videót',
  [Copy.self_onboarding_acknowledgment_video_confirmed_description]:
    'A négyzet bejelölésével elismerem, hogy az oktatóvideót teljes egészében megnéztem, és megértettem a tartalmát.',
  [Copy.self_onboarding_acknowledgment_video_not_started_label]: 'Megnéztem a videót',
  [Copy.self_onboarding_acknowledgment_video_not_started_disabled_description]: 'Nyomja meg a lejátszást a kezdéshez',
  [Copy.self_onboarding_acknowledgment_video_in_progress_label]: 'Megnéztem a videót',
};

export const Languages = {
  en,
  es,
  zhCN,
  zhTW,
  pl,
  ru,
  hu,
};

addTranslations({ ...Languages, en_US: en });
