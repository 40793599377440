import { useQuery } from '@apollo/client';
import { QueryGetAllJobsiteWorkerDocumentTypesArgs } from 'apollo/generated/client-operations';
import { GET_JOBSITE_WORKER_DOCUMENT_TYPES } from 'containers/workerOnboarding/helpers/queries';
import { GetJobsiteWorkerDocumentTypesResponse, JobsiteWorkerDocumentTypes } from 'containers/workerOnboarding/types';

export function useJobsiteWorkerDocumentTypes(jobsiteId: string): JobsiteWorkerDocumentTypes {
  const { data, error, loading } = useQuery<
    GetJobsiteWorkerDocumentTypesResponse,
    QueryGetAllJobsiteWorkerDocumentTypesArgs
  >(GET_JOBSITE_WORKER_DOCUMENT_TYPES, {
    variables: {
      jobsiteId,
    },
    fetchPolicy: 'no-cache',
    skip: !jobsiteId,
  });

  return {
    loading,
    error,
    jobsiteWorkerDocumentTypes: data?.getAllJobsiteWorkerDocumentTypes || [],
  };
}
