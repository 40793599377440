import { InMemoryCache, makeVar } from '@apollo/client';
import { ApolloState } from 'apollo/types';
// generated by Fragment Matcher plugin
import introspectionQueryResultData from 'apollo/generated/introspection-result';

export const selectedJobsiteIds = makeVar<string[]>([]);
export const environment = makeVar<string>('');
export const appVersion = makeVar<string>('');
export const beta = makeVar<boolean>(true);
export const isSidebarOpen = makeVar<boolean>(false);

export const generateCache = (initialState: ApolloState): InMemoryCache => {
  const cache = new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          selectedJobsiteIds: (): string[] => selectedJobsiteIds(),
          environment: (): string => environment(),
          appVersion: (): string => appVersion(),
          beta: (): boolean => beta(),
          isSidebarOpen: (): boolean => isSidebarOpen(),
        },
      },
    },
  }).restore(initialState);
  return cache;
};
