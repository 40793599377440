import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { JobsiteWorkerOnboardingSidebarElementProps } from 'containers/workerOnboarding/types';
import * as styles from 'containers/workerOnboarding/scss/worker-onboarding.scss';

const cx = classNames.bind(styles);

export function JobsiteWorkerOnboardingSidebarElement({
  title,
  subtitle,
  icon,
  completed,
  skipped,
  failed,
  active,
  onClick,
}: JobsiteWorkerOnboardingSidebarElementProps): ReactElement {
  const getStatusText = (): string => {
    if (failed) {
      return 'Did not complete';
    }
    if (skipped) {
      return `Skipped - ${skipped}`;
    }
    if (completed) {
      return 'Completed';
    }
    if (active) {
      return 'In progress';
    }
    return 'Not started';
  };

  const getStatusColor = (): string => {
    if (failed) {
      return 'jobsite-worker-orientation-sidebar-element__icon--status-banned';
    }
    if (skipped) {
      return 'jobsite-worker-orientation-sidebar-element__icon--status-warning';
    }
    if (completed) {
      return 'jobsite-worker-orientation-sidebar-element__icon--status-success';
    }
    if (active) {
      return 'jobsite-worker-orientation-sidebar-element__icon--active';
    }
    return '';
  };

  const getStatusIcon = (): string => {
    if (failed) {
      return 'fa-exclamation-triangle';
    }
    if (skipped) {
      return 'fa-exclamation-triangle';
    }
    if (completed) {
      return 'fa-check';
    }
    if (active) {
      return 'fa-pencil';
    }
    return 'fa-exclamation-triangle';
  };

  return (
    <NavItem
      className={cx('nav-link', 'p-0', 'border-bottom', 'jobsite-worker-orientation-sidebar-element', {
        'jobsite-worker-orientation-sidebar-element--active': active,
      })}
      onClick={onClick}
    >
      <NavLink className="mb-2 mt-1 flex-column align-items-start cursor-pointer">
        <h4>
          <i className={cx('mb-2', 'mr-2', 'mt-2', 'far', icon)} /> {title}
        </h4>
        {subtitle ? <h5>{subtitle}</h5> : null}
        <div className={cx(getStatusColor())}>
          <i className={cx('far', getStatusIcon())} /> {getStatusText()}
        </div>
      </NavLink>
    </NavItem>
  );
}
