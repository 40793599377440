import React from 'react';
import JSONTree from 'react-json-tree';
import { useGetWorkerCCureDataLazyQuery } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { WorkerAdminAction } from './WorkerAdminAction';

export interface WorkerCCureDataProps {
  workerId: string;
}

export function WorkerCCureDataContainer({ workerId }: WorkerCCureDataProps): React.ReactElement {
  const [getWorkerCCureData, { data, loading, error }] = useGetWorkerCCureDataLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { workerId },
  });

  if (loading || error) {
    return <LoadingError error={error} loading={loading} />;
  }

  return (
    <div>
      <WorkerAdminAction
        label="Worker C-Cure Data"
        actionText="Get C-Cure Data"
        onClick={(): void => {
          getWorkerCCureData();
        }}
      />
      {data && (
        <div className="odin-mt-4">
          <JSONTree data={data?.getWorkerCCureData?.data} />
        </div>
      )}
    </div>
  );
}
