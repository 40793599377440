import { statesOptions } from 'utils/constants';
import { AddContractorModalProps, EditContractorFormData } from './types';

export { getFormInputs } from './EditContractorModal.forms';

export const getDefaultValues = (props?: AddContractorModalProps): EditContractorFormData => ({
  // organization
  name: props?.existingOrganization?.name ?? '',
  legalName: props?.existingOrganization?.legalName ?? '',
  addressLine1: props?.existingOrganization?.addressLine1 ?? '',
  addressLine2: props?.existingOrganization?.addressLine2 ?? '',
  addressCity: props?.existingOrganization?.addressCity ?? '',
  addressState: statesOptions.find((option) => option.value === props?.existingOrganization?.addressState) ?? null,
  addressZipCode: props?.existingOrganization?.addressZipCode ?? '',

  // billing contact
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhoneNumber: '',

  websiteUrl: '',
  description: '',
});
