import { WorkerDashboardData } from 'containers/dashboard/types';
import { ensureNonEmptyItems } from 'utils';
import { Breakdown, DashboardStatsOverviewItemProps } from './types';

const overviewStatsFields = {
  totalManPower: 'Total Manpower',
  totalHours: 'Total Hours',
  uniqueWorkers: 'Unique Workers',
  averageHours: 'Average Hours',
  maxHours: 'Max Hours',
};

export const getBreakdown = (breakdowns: Breakdown[], name: string): Breakdown =>
  breakdowns?.find((b) => b.name === name);

export const getBreakdownCount = (breakdowns: Breakdown[], name: string): string => {
  const breakdown = getBreakdown(breakdowns, name);
  const theCount = breakdown?.name === overviewStatsFields.averageHours ? breakdown.compliantCount : breakdown?.count;
  return (theCount ?? 0).toLocaleString('en-US');
};

export const getBreakdownPercentage = (breakdowns: Breakdown[], name: string): string => {
  return `${getBreakdown(breakdowns, name)?.percentage ?? 0}%`;
};

type OverviewStatsItem = Omit<DashboardStatsOverviewItemProps, 'loading'>;

export const getOverviewStats = (
  workerReportsData: WorkerDashboardData,
  isCurrentlyOnsiteVisible: boolean,
): OverviewStatsItem[] => {
  const { overviewBreakdowns, compliantBreakdowns, activeBreakdowns } = workerReportsData ?? {};

  return ensureNonEmptyItems<OverviewStatsItem>([
    ...Object.entries(overviewStatsFields).map(
      ([key, label]): OverviewStatsItem => ({
        key,
        label: label === overviewStatsFields.averageHours ? 'Average Time Spent Onsite' : label,
        value: getBreakdownCount(overviewBreakdowns, label),
      }),
    ),
    {
      key: 'accessCompliant',
      label: 'Access Compliant',
      value: getBreakdownPercentage(compliantBreakdowns, 'Yes'),
    },
    isCurrentlyOnsiteVisible && {
      key: 'currentlyOnSite',
      label: 'Currently on site',
      value: getBreakdownPercentage(activeBreakdowns, 'Yes'),
    },
  ]);
};
