import { faSitemap } from '@fortawesome/pro-light-svg-icons';
import { getFaIcon } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import { GetOrganizationContainerOrganizationInfoQuery } from 'apollo/generated/client-operations';
import cn from 'classnames';

export type OrganizationInfo = GetOrganizationContainerOrganizationInfoQuery['getOrganization'];

export const OrganizationIcon = getFaIcon({
  icon: faSitemap,
  className: cn('odin-text-base odin-text-odin-primary'),
});

export type OrganizationTabApi = {
  refetchData: () => void;
};

export type OrganizationTabProps = {
  organization: OrganizationInfo;
  loading?: boolean;
  error?: ApolloError;
  onTabApiChange?: (api: OrganizationTabApi) => void;
  refetchOrganizationData?: () => void;
  openAddDeveloperModal?: () => void;
  openAddContractorModal?: () => void;
};
