import React from 'react';

type AcknowledgmentDocumentFooterProps = {
  workerName: string;
  acknowledgmentDate: string;
  ipAddress: string;
};

export function AcknowledgmentDocumentFooter(props: AcknowledgmentDocumentFooterProps): React.ReactElement {
  const { workerName, acknowledgmentDate, ipAddress } = props;
  return (
    <div className="odin-mt-6 odin-grid odin-grid-cols-2 odin-gap-x-9 odin-gap-y-6 odin-w-4/5">
      <div className="odin-divide-y-2 odin-divide-gray-900">
        <div
          className="odin-px-2 odin-pb-2 odin-leading-8"
          style={{ fontFamily: 'Dancing Script', fontSize: 35, letterSpacing: -0.35 }}
        >
          {workerName}
        </div>
        <div className="odin-px-2 odin-pt-2 odin-text-lg">Worker signature</div>
      </div>
      <div className="odin-divide-y-2 odin-divide-gray-900">
        <div className="odin-px-2 odin-pb-2 odin-text-2.33xl">{workerName}</div>
        <div className="odin-px-2 odin-pt-2 odin-text-lg">Worker name</div>
      </div>
      <div className="odin-divide-y-2 odin-divide-gray-900">
        <div className="odin-px-2 odin-pb-2 odin-text-2.33xl">{acknowledgmentDate}</div>
        <div className="odin-px-2 odin-pt-2 odin-text-lg">Worker acknowledged date</div>
      </div>
      <div className="odin-divide-y-2 odin-divide-gray-900">
        <div className="odin-px-2 odin-pb-2 odin-text-2.33xl">{ipAddress}</div>
        <div className="odin-px-2 odin-pt-2 odin-text-lg">Worker IP Address</div>
      </div>
    </div>
  );
}
