import { gql } from '@apollo/client';

export const GET_MEMBER_JOBSITES = gql`
  query GetMemberJobsites($memberUserAccountId: ID) {
    getMemberJobsites(memberUserAccountId: $memberUserAccountId) {
      count
      jobsiteMembers {
        title
        jobsite {
          jobsiteId
          name
          parentId
          featuresModule {
            announcementsEnabled
            archivingAccessEventsEnabled
          }
        }
      }
      selectedJobsiteMember {
        title
        jobsite {
          jobsiteId
          name
          featuresModule {
            announcementsEnabled
            archivingAccessEventsEnabled
          }
        }
      }
    }
  }
`;
