import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import {
  GetJobsiteContainerDocument,
  GetJobsiteContainerContractorsDocument,
  useJobsiteRemoveContractorMutation,
} from 'apollo/generated/client-operations';
import { getGraphQLError } from 'utils/error';
import { AlertInstance } from 'components/alertNotification';
import { TrashIcon } from 'components/icons';
import { RemoveContractorAssignmentModalProps } from './types';

export function RemoveContractorAssignmentModal(props: RemoveContractorAssignmentModalProps): ReactElement {
  const { isOpen, jobsiteContractorId, closeModal, onClosed } = props;

  const [jobsiteRemoveContractor, { loading }] = useJobsiteRemoveContractorMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      closeModal();
      AlertInstance.alert('tc', 'success', 'Success', 'Contractor user successfully removed');
    },
    onError: (responseError: ApolloError) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    variables: { input: { jobsiteContractorId } },
    refetchQueries: [GetJobsiteContainerContractorsDocument, GetJobsiteContainerDocument],
  });

  return (
    <Modal
      open={isOpen}
      setOpen={closeModal}
      afterLeave={onClosed}
      size="xs"
      title="Delete this Contractor Assignment?"
      titleAlignment="center"
      text={`Deleting this assignment will remove it from the database and 
could block jobsite access for this contractor. Are you sure you want to delete this assignment?`}
      textAlignment="center"
      onAction={jobsiteRemoveContractor}
      actionText="Delete Assignment"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={loading}
    />
  );
}
