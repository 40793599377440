import React from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { useApolloClient } from '@apollo/client';
import { SearchBox, SearchBoxAutocompleteViewProps, SearchBoxView, SearchDriverOptions } from '@odin-labs/components';
import { Maybe } from 'types';
import { SearchQueryType, useSaveUserSearchQueryMutation } from 'apollo/generated/client-operations';
import { SearchBoxAutocompleteView } from './SearchBoxAutocompleteView';
import { SearchBoxInputView } from './SearchBoxInputView';
import { OdinAPIConnector } from './SearchApiConnector';
import { SearchWorkerResult, AutocompleteSearchType, OnSelectAutocompleteHook } from './types';

const defaultConfig: SearchDriverOptions = {
  trackUrlState: false,
  alwaysSearchOnInitialLoad: false,
  apiConnector: null,
  hasA11yNotifications: true,
  initialState: {},
  searchQuery: {},
  autocompleteQuery: {
    results: {
      // Defines max number of worker suggestions to display in autocomplete dropdown
      resultsPerPage: 8,
      current: 1,
    },
    suggestions: {
      // Defines max number of previous user searches to display in autocomplete dropdown
      size: 5,
    },
  },
};

interface AutocompleteSearchStatus {
  autocompleteSearchType: AutocompleteSearchType;
  isAutocompleteSearchInProgress: boolean;
}

const initialAutocompleteSearchStatus: AutocompleteSearchStatus = {
  autocompleteSearchType: AutocompleteSearchType.none,
  isAutocompleteSearchInProgress: false,
};

interface SearchBoxWrapperProps {
  className?: string;
}

export function SearchBoxWrapper({ className }: SearchBoxWrapperProps): React.ReactElement {
  const history = useHistory();
  const client = useApolloClient();
  const [autocompleteSearchStatus, setAutocompleteSearchStatus] = React.useState<AutocompleteSearchStatus>(
    initialAutocompleteSearchStatus,
  );

  const updateAutocompleteSearchStatus = (upd: Partial<AutocompleteSearchStatus>): void =>
    setAutocompleteSearchStatus((state) => ({ ...state, ...upd }));

  const [saveUserSearchQuery] = useSaveUserSearchQueryMutation();

  const saveSearchQuery = React.useCallback(
    async (searchTerm: Maybe<string>): Promise<void> => {
      if (searchTerm?.trim()) {
        saveUserSearchQuery({
          variables: {
            input: {
              searchQuery: searchTerm.trim(),
              searchQueryType: SearchQueryType.Worker,
            },
          },
        });
      }
    },
    [saveUserSearchQuery],
  );

  const autocompleteView = (props: SearchBoxAutocompleteViewProps): React.ReactElement => (
    <SearchBoxAutocompleteView {...props} {...autocompleteSearchStatus} />
  );

  const onSelectAutocompleteHandler = React.useCallback(
    (
      selected: SearchWorkerResult & { suggestion?: string },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      options: unknown,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      defaultOnSelectAutocomplete: OnSelectAutocompleteHook,
    ): void => {
      if (selected?.suggestion) {
        history.push(`/search?query=${selected.suggestion}`);
      } else if (selected?.id) {
        history.push(`/worker/${selected.id}`);
        saveSearchQuery(selected.searchTerm);
      }
    },
    [saveSearchQuery],
  );

  const onSubmitHandler = React.useCallback(
    (searchTerm: string): void => {
      if (searchTerm) {
        history.push(`/search?query=${searchTerm}`);
        saveSearchQuery(searchTerm);
      }
    },
    [saveSearchQuery],
  );

  const inputProps = React.useMemo(
    () => ({
      onAutocompleteSearchStarted: (autocompleteSearchType: AutocompleteSearchType): void => {
        updateAutocompleteSearchStatus({
          autocompleteSearchType,
          isAutocompleteSearchInProgress: true,
        });
      },
    }),
    [],
  );

  const config = React.useMemo(
    () => ({
      ...defaultConfig,
      apiConnector: new OdinAPIConnector({
        client,
        onAutocompleteSearchCompleted: (): void => {
          updateAutocompleteSearchStatus({ isAutocompleteSearchInProgress: false });
        },
      }),
    }),
    [],
  );

  return (
    <div className={cn('search-box-wrapper odin-grow', className)}>
      <SearchBox
        config={config}
        view={SearchBoxView}
        inputView={SearchBoxInputView}
        autocompleteView={autocompleteView}
        inputProps={inputProps}
        onSelectAutocomplete={onSelectAutocompleteHandler}
        onSubmit={onSubmitHandler}
        debounceLength={400}
      />
    </div>
  );
}

SearchBoxWrapper.defaultProps = {
  className: '',
};
