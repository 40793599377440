import { ApolloError, useQuery } from '@apollo/client';
import { QueryGetAllContractorsForWorkerArgs, useGetJobsiteWorkerQuery } from 'apollo/generated/client-operations';
import { GET_ALL_CONTRACTORS_FOR_WORKER } from 'containers/workerOnboarding/helpers/queries';
import {
  GetAllContractorsForWorkerResponse,
  JobsiteWorkerOnboardingData,
  UseJobsiteWorkerOnboardingDataArgs,
} from 'containers/workerOnboarding/types';
import { useJobsiteWorkerOnboardingDocuments } from './useJobsiteWorkerOnboardingDocuments';

export function useJobsiteWorkerOnboardingData(args: UseJobsiteWorkerOnboardingDataArgs): JobsiteWorkerOnboardingData {
  const { jobsiteWorkerId } = args;

  const {
    data: jobsiteWorkerData,
    error: jobsiteWorkerError,
    loading: jobsiteWorkerLoading,
    refetch: refetchJobsiteWorker,
  } = useGetJobsiteWorkerQuery({
    variables: {
      jobsiteWorkerId,
    },
    fetchPolicy: 'no-cache',
  });

  const jobsiteWorker = jobsiteWorkerData?.getJobsiteWorker;

  const {
    jobsiteWorkerDocumentTypes,
    jobsiteWorkerDocuments,
    filteredDocumentTypeIds,
    refetchJobsiteWorkerDocuments,
    error: documentsError,
    loading: documentsLoading,
  } = useJobsiteWorkerOnboardingDocuments({ jobsiteWorker });

  const {
    data: allContractorsForWorker,
    error: allContractorsForWorkerError,
    loading: allContractorsForWorkerLoading,
  } = useQuery<GetAllContractorsForWorkerResponse, QueryGetAllContractorsForWorkerArgs>(
    GET_ALL_CONTRACTORS_FOR_WORKER,
    {
      variables: {
        workerId: jobsiteWorker?.contractorWorker?.worker?.workerId,
      },
      fetchPolicy: 'no-cache',
      skip: !jobsiteWorker,
    },
  );

  const loading: boolean = jobsiteWorkerLoading || documentsLoading || allContractorsForWorkerLoading;
  const error: ApolloError = jobsiteWorkerError || documentsError || allContractorsForWorkerError;
  const workerContractors = allContractorsForWorker?.getAllContractorsForWorker || [];

  return {
    loading,
    error,
    jobsiteWorker,
    jobsiteWorkerDocumentTypes,
    jobsiteWorkerDocuments,
    filteredDocumentTypeIds,
    workerContractors,
    refetchJobsiteWorker,
    refetchJobsiteWorkerDocuments,
  };
}
