import moment from 'moment';
import { getUpdateInputValueFunction } from 'components/form';
import { DeepMap } from 'react-hook-form';
import { ensureNonUndefinedFields, getPhoneNumberAsE164 } from 'utils';
import { ContractorAddJobsitesInput } from 'apollo/generated/client-operations';
import type {
  EditJobsiteAssignmentFormData,
  EditJobsiteAssignmentInputsArgs,
} from './EditJobsiteAssignmentModal.forms';

export { type EditJobsiteAssignmentFormData, getFormInputsHook } from './EditJobsiteAssignmentModal.forms';

export const getDefaultValues = (
  args: Omit<EditJobsiteAssignmentInputsArgs, 'editType' | 'contractorId' | 'jobsiteContractor'>,
): EditJobsiteAssignmentFormData => {
  const { contractorsOptions, jobsitesOptions } = args;

  return {
    contractorId: contractorsOptions?.length === 1 ? contractorsOptions[0] : null,
    jobsiteIds: jobsitesOptions?.length === 1 ? jobsitesOptions : null,
    startDate: moment(),
    endDate: null,
    parentContractorId: null,
    contactInfoLabel: undefined,

    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
  };
};

export const getAddInput = (
  data: EditJobsiteAssignmentFormData,
  dirtyFields: DeepMap<EditJobsiteAssignmentFormData, true>,
): ContractorAddJobsitesInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return ensureNonUndefinedFields<ContractorAddJobsitesInput>({
    contractorId: getUpdateInputValue('contractorId', true),
    jobsiteIds: getUpdateInputValue('jobsiteIds', true),
    jobsiteContractorInput: {
      startDate: getUpdateInputValue('startDate', true),
      endDate: getUpdateInputValue('endDate'),
      parentContractorId: getUpdateInputValue('parentContractorId'),
      contactInput: {
        firstName: getUpdateInputValue('firstName'),
        lastName: getUpdateInputValue('lastName'),
        email: getUpdateInputValue('email'),
        phoneNumber: getPhoneNumberAsE164(getUpdateInputValue('phoneNumber')),
        title: getUpdateInputValue('title'),
      },
    },
  });
};
