import React from 'react';

export function HorizontalLine(): React.ReactElement {
  return (
    <div className="odin-hidden sm:odin-block" aria-hidden="true">
      <div className="odin-py-5">
        <div className="odin-border-t odin-border-gray-200" />
      </div>
    </div>
  );
}
