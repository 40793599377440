import React from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { useBoolean } from 'utils';
import { SidebarContainer } from 'containers/sidebar';
import { NavbarContainer } from 'containers/navbar';
import { AppLayoutContentProps } from './types';

export function AppLayoutContent(props: AppLayoutContentProps): React.ReactElement {
  const { routes, currentRoute, history, pathName, currentSession } = props;
  const { value: isSidebarOpen, setTrue: openSidebar, setFalse: closeSidebar } = useBoolean();
  const SidebarContainerResolver = currentRoute?.sidebarContainer || SidebarContainer;
  return (
    <div className="odin-absolute odin-inset-0 md:odin-flex odin-bg-white">
      {!currentRoute?.fullLayout ? (
        <SidebarContainerResolver
          routes={routes}
          history={history}
          pathname={pathName}
          matchedPath={currentRoute?.path}
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
        />
      ) : null}
      <div className="odin-flex odin-flex-col odin-w-full odin-relative odin-z-0 odin-overflow-y-auto">
        {!currentRoute?.hideNavbar && !currentRoute?.fullLayout ? <NavbarContainer openSidebar={openSidebar} /> : null}
        <main className="odin-h-full odin-w-full">{renderRoutes(routes as RouteConfig[], { currentSession })}</main>
      </div>
    </div>
  );
}
