import React from 'react';
import cn from 'classnames';
import { Badge } from '@odin-labs/components';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { JobsiteIncidentType, incidentTypeColor } from 'containers/jobsiteFormSubmission/jobsiteSafety.utils';

export const classes = {
  row: cn(
    'odin-flex odin-flex-col',
    'odin-mt-5 odin-space-y-1.5',
    'sm:odin-flex-row sm:odin-justify-between sm:odin-space-x-6',
    'print:odin-break-inside-avoid-page',
  ),
  leftContainer: cn('odin-flex odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium'),
  rightContainer: cn(
    'odin-grid',
    'odin-grid-cols-2',
    'odin-gap-y-1.5',
    'odin-ml-11',
    'sm:odin-flex',
    'sm:odin-space-x-6',
  ),
  infoItem: cn(
    'odin-flex odin-items-center',
    'odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal',
    'odin-truncate',
  ),
  infoItemIcon: (icon: string): string =>
    cn(icon, 'odin-text-odin-primary', 'odin-text-sm', 'odin-font-normal', 'odin-mr-1.5'),
};

export type WorkerReportHeaderProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  reportDateTimeText: string;
};

export function WorkerReportHeader(props: WorkerReportHeaderProps): React.ReactElement {
  const { jobsiteFormSubmission, reportDateTimeText } = props;
  const { name, incidentType } = jobsiteFormSubmission.extraData ?? {};
  const { name: jobsiteName } = jobsiteFormSubmission.jobsiteForm.jobsite;

  return (
    <div className={classes.row}>
      <div className={classes.leftContainer}>Worker Report</div>
      <div className={classes.rightContainer}>
        <div className={classes.infoItem}>
          <i className={classes.infoItemIcon('fal fa-shield-check')} aria-hidden="true" />
          {name}
          {incidentType.map((it: JobsiteIncidentType) => (
            <Badge key={it} className="odin-ml-1" color={incidentTypeColor[it]} text={it.toString()} />
          ))}
        </div>
        <div className={classes.infoItem}>
          <i className={classes.infoItemIcon('fal fa-briefcase')} aria-hidden="true" />
          {jobsiteName}
        </div>
        <div className={classes.infoItem}>
          <i className={classes.infoItemIcon('fal fa-id-badge')} aria-hidden="true" />
          {reportDateTimeText}
        </div>
      </div>
    </div>
  );
}
