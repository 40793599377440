import React from 'react';
import { useGetEntityChangesQuery } from 'apollo/generated/client-operations';
import { EntityChange } from 'containers/entityChange/types';
import { NewHeader } from 'components/header/NewHeader';
import { WorkerChangeTabProps } from 'containers/entityChange/worker/types';
import { LoadingError } from 'components/loadingError';
import { getChangesByLayout, getColumns } from 'containers/entityChange/worker/tabs/tables';
import { Table, TableContainer } from '@odin-labs/components';

function getChangesByEntityId(
  entityIds: string[],
  changes: (EntityChange & { jwId: string })[],
): Record<string, EntityChange[]> {
  const initialValue: Record<string, EntityChange[]> = entityIds.reduce(
    (result, entityId) => Object.assign(result, { [entityId]: [] }),
    {},
  );
  return (
    changes?.reduce((result, change) => {
      result[change.jwId].push(change);
      return result;
    }, initialValue) ?? initialValue
  );
}

export function WorkerJobsites(props: WorkerChangeTabProps): React.ReactElement {
  const { loading: parentLoading, worker, changesLayout, onTabApiChange } = props;
  const { workerId } = worker ?? {};

  const jobsiteWorkers = worker?.jobsiteWorkers.edges.map(({ node }) => node) ?? [];
  const jwIds = jobsiteWorkers.map((jw) => jw.jobsiteWorkerId);
  const jwaIds = jobsiteWorkers.map((jw) => jw.currentAccess.id);
  const jwIdByJwa = Object.fromEntries(jobsiteWorkers.map((jw) => [jw.currentAccess.id, jw.jobsiteWorkerId]));

  const {
    data: workerJobsitesChangesData,
    loading: workerJobsitesChangesLoading,
    error: workerJobsitesChangesError,
    refetch: refetchWorkerJobsitesChanges,
  } = useGetEntityChangesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        // paginationInput: { limit, offset },
        filters: [
          { tableName: 'jobsite_worker', entityIds: jwIds },
          { tableName: 'jobsite_worker_access', entityIds: jwaIds },
        ],
      },
    },
    skip: !workerId || (!jwIds.length && !jwaIds.length),
  });

  const workerJobsitesChanges = React.useMemo(() => {
    return getChangesByEntityId(
      jwIds,
      getChangesByLayout(
        workerJobsitesChangesData?.getEntityChanges.edges.map(({ node }) => node) ?? [],
        changesLayout,
      ).map((change) => ({
        ...change,
        jwId: change.tableName === 'jobsite_worker_access' ? jwIdByJwa[change.entityId] : change.entityId,
      })),
    );
  }, [jwIds, workerJobsitesChangesData, changesLayout]);

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerJobsitesChanges }), [refetchWorkerJobsitesChanges]);

  const loading = parentLoading || workerJobsitesChangesLoading;
  const error = workerJobsitesChangesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <div className="odin-flex odin-flex-col odin-gap-9">
      {jobsiteWorkers.map((jobsiteWorker) => {
        const jwChanges = workerJobsitesChanges[jobsiteWorker.jobsiteWorkerId];
        const jwColumns = React.useMemo(() => getColumns(jwChanges, changesLayout), [jwChanges, changesLayout]);

        const jwTitle = jobsiteWorker?.jobsiteContractor.jobsite?.name;
        return (
          <div key={jobsiteWorker.jobsiteWorkerId} className="odin-flex odin-flex-col odin-gap-y-6">
            <TableContainer>
              <NewHeader size="md" title={`${jwTitle} - Changes`} actionsProps={{ headerActions: null }} />
              <Table
                loading={loading}
                columns={jwColumns}
                data={jwChanges}
                // initialState={{ sortBy: tableSortBy, pageSize: limit }}
                // pageCount={pageCount}
                // pageIndex={page}
                remote
                // enablePagination
              />
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
}
