import React from 'react';
import { useFlags } from 'featureFlags';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { createRoutes } from 'routes';
import { AuthContext } from 'auth';

export function Routes(): JSX.Element {
  const { currentUser: user } = React.useContext(AuthContext);
  const featureFlags = useFlags();
  const routes = createRoutes({ featureFlags, user }) as RouteConfig[];
  return renderRoutes(routes);
}
