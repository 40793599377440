import { InfoList } from 'components/infoList';
import { InfoRow } from 'components/infoRow';
import { LoadingError } from 'components/loadingError';
import { OrganizationTabProps } from 'containers/organization/types';
import React, { ReactElement } from 'react';
import { getInfoRows } from './utils';

export function OrganizationInfoTabContainer(props: OrganizationTabProps): ReactElement {
  const { onTabApiChange, loading, error, refetchOrganizationData } = props;

  const organizationInfoRows = getInfoRows(props);

  React.useEffect(() => onTabApiChange({ refetchData: refetchOrganizationData }), [refetchOrganizationData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  // const allowEdit = user.isAllowed(to.editOrganizations);

  return (
    <InfoList withBottomBorder loading={loading}>
      {organizationInfoRows.map((row) => (
        <InfoRow key={row.label} {...row} allowEdit={false} loading={loading} />
      ))}
    </InfoList>
  );
}
