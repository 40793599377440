import React from 'react';
import { SelfOnboardingStepKey, SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';

export type ReviewProfilePhotoContainer = React.PropsWithChildren<Pick<SelfOnboardingStepProps, 'navigation'>>;

export function ReviewProfilePhotoContainer(props: ReviewProfilePhotoContainer): React.ReactElement {
  const { children, navigation } = props;
  const { goToStep } = navigation;

  return (
    <div
      className="odin-flex"
      onClickCapture={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        e.stopPropagation();
        goToStep(SelfOnboardingStepKey.ProfilePhoto);
      }}
    >
      {children}
    </div>
  );
}
