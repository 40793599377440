import { BadgeColor } from '@odin-labs/components';
import { SiteSpecificOrientationStatus } from 'apollo/generated/client-operations';

export enum SiteSafetyOrientationStatusFilterValue {
  Completed = 'completed',
  NotStarted = 'not-started',
  Expired = 'expired',
}

export const siteSafetyOrientationStatusMapping: Record<
  SiteSafetyOrientationStatusFilterValue,
  SiteSpecificOrientationStatus
> = {
  [SiteSafetyOrientationStatusFilterValue.NotStarted]: SiteSpecificOrientationStatus.NotComplete,
  [SiteSafetyOrientationStatusFilterValue.Completed]: SiteSpecificOrientationStatus.Complete,
  [SiteSafetyOrientationStatusFilterValue.Expired]: SiteSpecificOrientationStatus.Expired,
};

export const getSiteOrientationBadgeProps = (
  siteSpecificTrainingCompletedAt: string,
  isSiteSpecificTrainingExpired: boolean,
): { text: string; color: BadgeColor } => {
  if (!siteSpecificTrainingCompletedAt) {
    return { text: 'Not started', color: 'gray' };
  }
  if (isSiteSpecificTrainingExpired) {
    return { text: 'Expired', color: 'red' };
  }
  return { text: 'Completed', color: 'green' };
};
