import React from 'react';
import { Alert } from '@odin-labs/components';
import { CardPrintJob, CardPrintJobStatus } from 'apollo/generated/client-operations';
import { BadgingState } from 'containers/worker/types';
import { getIcon } from 'utils/ui';
import { AuthContext } from 'auth';
import { to } from 'acl';

export type WorkerBadgesAlertsProps = {
  currentCardPrintJob: CardPrintJob;
  openAddBadgeModal: (badgingState: BadgingState) => void;
  hasExtraActiveCards: boolean;
  hasExtraActiveQRCodes: boolean;
};

const isCardPrintJobUnread = (cardPrintJob: CardPrintJob): boolean =>
  cardPrintJob?.status === CardPrintJobStatus.Printed &&
  cardPrintJob?.completion?.cardNumber == null &&
  cardPrintJob?.workerCard == null;

const IdCardIcon = getIcon('fal fa-id-card odin-mr-3 !odin-leading-5');
const PrintIcon = getIcon('fal fa-print odin-mr-3 !odin-leading-5');

const RedoIcon = getIcon('fal fa-redo');
const UserPlusIcon = getIcon('fal fa-user-plus');
const ArrowRightIcon = getIcon('fal fa-arrow-right');
const MinusOctagonIcon = getIcon('fal fa-minus-octagon');

export function WorkerBadgesAlerts(props: WorkerBadgesAlertsProps): React.ReactElement {
  const { currentCardPrintJob, openAddBadgeModal, hasExtraActiveCards, hasExtraActiveQRCodes } = props;

  const { currentUser: user } = React.useContext(AuthContext);
  const canAddBadges = user.isAllowed(to.addBadges);
  const canDeactivateBadges = user.isAllowed(to.deactivateBadges);

  return (
    <>
      {currentCardPrintJob?.status === CardPrintJobStatus.Failed && (
        <Alert
          type="danger"
          text="Your badge failed to print"
          {...(canAddBadges
            ? {
                onAction: (): void => {
                  openAddBadgeModal(BadgingState.CreatePrintJob);
                },
                actionText: 'Try Again',
                actionIcon: RedoIcon,
              }
            : undefined)}
        />
      )}
      {isCardPrintJobUnread(currentCardPrintJob) && (
        <Alert
          text={
            'A badge was printed for this worker, but not assigned. ' +
            'Please enter the number printed on the badge to assign it.'
          }
          icon={IdCardIcon}
          {...(canAddBadges
            ? {
                onAction: (): void => {
                  openAddBadgeModal(BadgingState.PrintCompletedNoCardNumber);
                },
                actionText: 'Assign Badge',
                actionIcon: UserPlusIcon,
              }
            : undefined)}
        />
      )}
      {currentCardPrintJob?.status === CardPrintJobStatus.Submitted && (
        <Alert
          text="A badge is currently printing for this worker"
          icon={PrintIcon}
          {...(canAddBadges
            ? {
                onAction: (): void => {
                  openAddBadgeModal(BadgingState.Printing);
                },
                actionText: 'Resume',
                actionIcon: ArrowRightIcon,
              }
            : undefined)}
        />
      )}
      {hasExtraActiveCards && (
        <Alert
          text={
            'This worker has multiple active badges. ' +
            'We recommend deactivating all but one active badge for security purposes.'
          }
          {...(canDeactivateBadges
            ? {
                onAction: (): void => {
                  openAddBadgeModal(BadgingState.ProximityCardDeactivationPrompt);
                },
                actionText: 'Deactivate',
                actionIcon: MinusOctagonIcon,
              }
            : undefined)}
        />
      )}
      {hasExtraActiveQRCodes && (
        <Alert
          text={
            'This worker has multiple active QR Codes. ' +
            'We recommend deactivating all but one active badge for security purposes.'
          }
          {...(canDeactivateBadges
            ? {
                onAction: (): void => {
                  openAddBadgeModal(BadgingState.QRCodeDeactivationPrompt);
                },
                actionText: 'Deactivate',
                actionIcon: MinusOctagonIcon,
              }
            : undefined)}
        />
      )}
    </>
  );
}
