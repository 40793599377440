import { useState } from 'react';
import { OrderByDirection } from 'apollo/generated/client-operations';
import { Maybe } from 'types';

export type QueryOrderByType<QueryFieldType> = {
  field?: Maybe<QueryFieldType>;
  direction?: Maybe<OrderByDirection>;
};

export type SortOrder = 'asc' | 'desc';

export type OrderByChangeState<T> = {
  field: T;
  order: SortOrder;
};

type TableSortInfo = {
  dataField: any;
  order: SortOrder;
};

export type OrderByResult<TableFieldType, QueryFieldType> = {
  tableSortInfo: TableSortInfo;
  orderBy: [QueryOrderByType<QueryFieldType>];
  setNewOrderBy: (newState: OrderByChangeState<TableFieldType>) => void;
};

/**
 * Converts the `table` sort info into `query` sort info and returns it
 * together with a function to apply future sort info updates.
 * `orderBy` object should be passed to the graphql query as it is provided
 * by this function, with no changes.
 * @param defaultOrderByField The initial value for the orderBy field.
 * @param isDescending If the initial sorting is descending.
 * @param getQueryField A function which will convert table field to query field.
 * @returns `{ tableSortInfo, orderBy, setNewOrderBy }` where:
 * - `tableSortInfo` should be passed to the table as `sort` prop,
 * so that the table will show the initial sorting info accordingly
 * - `orderBy` contains query sort info and should be passed to the graphql query directly,
 * - `setNewOrderBy` should be called whenever the table sorting changes

 */
export const useQueryOrderBy = <TableFieldType, QueryFieldType>(
  defaultOrderByField: TableFieldType,
  isDescending: boolean,
  getQueryField: (field: TableFieldType) => QueryFieldType,
): OrderByResult<TableFieldType, QueryFieldType> => {
  const defaultOrderByQueryField = getQueryField(defaultOrderByField);
  const defaultOrderBy: [QueryOrderByType<QueryFieldType>] = defaultOrderByQueryField
    ? [{ field: defaultOrderByQueryField }]
    : null;

  const tableSortInfo: TableSortInfo = defaultOrderByQueryField
    ? { dataField: defaultOrderByField, order: isDescending ? 'desc' : 'asc' }
    : undefined;

  if (isDescending && defaultOrderBy) {
    defaultOrderBy[0].direction = OrderByDirection.Descending;
  }

  const [orderBy, setOrderBy] = useState<[QueryOrderByType<QueryFieldType>]>(defaultOrderBy);

  const setNewOrderBy = (newState: OrderByChangeState<TableFieldType>): void => {
    if (!newState) {
      setOrderBy(null);
    } else {
      const { field, order } = newState;
      const queryField = getQueryField(field);
      if (queryField) {
        const newOrderBy: QueryOrderByType<QueryFieldType> = {
          field: queryField,
        };
        if (order === 'desc') {
          newOrderBy.direction = OrderByDirection.Descending;
        }
        setOrderBy([newOrderBy]);
      } else {
        throw new Error(`No query field found for "${field}"" table field.`);
      }
    }
  };

  return { tableSortInfo, orderBy, setNewOrderBy };
};
