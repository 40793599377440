import React from 'react';
import { ModalFormProps } from 'components/form';
import { DialogContext } from 'dialog/dialogContext';
import { DialogId } from './types';

const forwardedFormDialog = React.forwardRef(
  <TFields,>(
    props: ModalFormProps<TFields> & { id: DialogId },
    ref: React.ForwardedRef<HTMLFormElement>,
  ): React.ReactElement => {
    const { setDialogProps: setFormDialogProps } = React.useContext(DialogContext);
    const deps = [...Object.values(props), ref];

    React.useEffect(() => {
      setFormDialogProps(props.id, { ...props, ref });
    }, deps);

    return null;
  },
);

type ForwardedFormDialog = <TFields>(
  props: ModalFormProps<TFields> & { id: DialogId } & {
    ref?: React.ForwardedRef<HTMLFormElement>;
  },
) => ReturnType<typeof forwardedFormDialog>;

/**
 * This component should be used to display a modal nested form (react-hook-form)
 * (i.e. when needed to edit a form field with another form).
 * If nested directly without using this component, a submission on the child form
 * will trigger submissions on both child and parent forms.
 */
export const FormDialog = forwardedFormDialog as ForwardedFormDialog;
