import React, { ReactElement } from 'react';
import { Table, TableContainer } from '@odin-labs/components';
import { paginationSizePerPage as limit } from 'utils/constants';
import { LoadingError } from 'components/loadingError';
import { useGetJobsiteContainerEquipmentHistoryTabQuery } from 'apollo/generated/client-operations';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { JobsiteTabProps } from 'containers/jobsite/types';
import { getColumns } from './tables';

export function JobsiteEquipmentHistoryTabContainer(props: JobsiteTabProps): ReactElement {
  const { jobsiteId, onTabApiChange } = props;
  const { page, updateUrl } = usePageQueryParams();
  const offset = page * limit;

  const { data, loading, error, refetch } = useGetJobsiteContainerEquipmentHistoryTabQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      jobsiteId,
      limit,
      offset,
    },
  });

  const columns = React.useMemo(() => getColumns(), []);

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const jobsiteEquipmentHistoryCount = data?.getJobsiteEquipmentHistory?.count ?? 0;
  const pageCount = Math.ceil(jobsiteEquipmentHistoryCount / limit);

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <TableContainer className="jobsite-equipment-history-container">
      <Table
        loading={loading}
        columns={columns}
        data={data?.getJobsiteEquipmentHistory?.results}
        initialState={{ pageSize: limit }}
        pageCount={pageCount}
        remote
        enablePagination
        onPageChange={onPageChangeHandler}
        disableGlobalFilter
      />
    </TableContainer>
  );
}
