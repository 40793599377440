import React from 'react';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { DashboardTabProps } from 'containers/dashboard/tabs/types';
import { PageSearchParams } from 'utils';
import { OnRowClick } from '@odin-labs/components';
import { Col } from 'components/container';
import { DashboardStatsOverview } from './DashboardStatsOverview';
import { DashboardStatsFacet } from './DashboardStatsFacet';
import { Breakdown } from './types';

export function DashboardStatsTab(props: DashboardTabProps): React.ReactElement {
  const { data, loading, jobsites, isCurrentlyOnsiteVisible } = props;

  const { updateUrl } = usePageQueryParams();

  const getOnRowClickHandler =
    (fieldName: keyof PageSearchParams): OnRowClick<Breakdown> =>
    ({ data: entry }): void => {
      updateUrl({ [fieldName]: entry.name });
    };

  const onJobsiteFacetRowClick: OnRowClick<Breakdown> = ({ data: entry }): void => {
    const jobsiteId = jobsites.find((j) => j.name === entry.name)?.jobsiteId;
    if (jobsiteId) {
      updateUrl({ jobsiteIds: [jobsiteId] });
    }
  };

  return (
    <div className="odin-flex odin-flex-col odin-gap-y-9">
      <DashboardStatsOverview
        workerReportsData={data}
        isCurrentlyOnsiteVisible={isCurrentlyOnsiteVisible}
        loading={loading}
      />
      <div className="lg:odin-grid lg:odin-grid-cols-2 xl:odin-grid-cols-3 lg:odin-gap-9">
        <Col className="lg:odin-col-span-full xl:odin-col-span-1">
          {isCurrentlyOnsiteVisible && (
            <DashboardStatsFacet
              loading={loading}
              title="Currently on site"
              data={data?.activeBreakdowns}
              noResultsText="No data to show"
              onRowClick={getOnRowClickHandler('currentlyOnsite')}
            />
          )}
          <DashboardStatsFacet
            loading={loading}
            title="By Jobsite"
            data={data?.jobsiteBreakdowns}
            noResultsText="No data to show"
            onRowClick={onJobsiteFacetRowClick}
          />
          <DashboardStatsFacet
            loading={loading}
            title="By Title"
            data={data?.tradeClassBreakdowns}
            noResultsText="No workers to show"
            onRowClick={getOnRowClickHandler('tradeClass')}
          />
        </Col>
        <Col>
          <DashboardStatsFacet
            loading={loading}
            title="By Trade"
            data={data?.tradeBreakdowns}
            noResultsText="No workers to show"
            onRowClick={getOnRowClickHandler('trade')}
          />
        </Col>
        <Col>
          <DashboardStatsFacet
            loading={loading}
            title="By Contractor"
            data={data?.contractorBreakdowns}
            noResultsText="No workers to show"
            onRowClick={getOnRowClickHandler('contractor')}
          />
        </Col>
      </div>
    </div>
  );
}
