import React from 'react';
import { AuthContext } from 'auth';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { useFlags } from 'featureFlags';
import { useGetWorkerDataQuery } from 'apollo/generated/client-operations';
import { UseWorkerData } from 'containers/worker/types';
import { ensureDistinctItems } from 'utils';

/**
 * This hooks has been created by extracting the data fetching and transformation logic
 * from the WorkerContainer component.
 * This is a temporary solution until new gql queries will be provided.
 */
export const useWorkerData = (workerId: string): UseWorkerData => {
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    data: workerData,
    error: workerError,
    loading: workerLoading,
    refetch,
  } = useGetWorkerDataQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !workerId,
    variables: {
      workerId,
      includeArchivedWorker: true,
    },
  });

  const { jobsites: userJobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const loading = userJobsitesLoading || workerLoading;
  const error = userJobsitesError || workerError;

  const userJobsitesIds = userJobsites?.map(({ jobsiteId }) => jobsiteId);
  const userDeveloperIds =
    userJobsites && [...new Set(userJobsites.map(({ developer }) => developer?.developerId))].filter(Boolean);

  const worker = workerData?.getWorker;
  const [updatedWorker, setUpdatedWorker] = React.useState(worker);

  const updateWorkerState: UseWorkerData['updateWorkerState'] = (updWorker): void => {
    setUpdatedWorker((currentWorker) => ({
      ...worker,
      ...currentWorker,
      ...updWorker,
      profilePictureCropped: {
        ...worker.profilePictureCropped,
        ...currentWorker?.profilePictureCropped,
        ...updWorker.profilePictureCropped,
      },
    }));
  };

  const { jobsiteWorkers, developerIds, jobsiteIds, jobsiteNames } = React.useMemo(() => {
    const jws =
      workerData?.getWorker.jobsiteWorkers.edges
        .map(({ node }) => node)
        .sort(
          (a, b) => new Date(b.endDate ?? '2500-01-01').getTime() - new Date(a.endDate ?? '2500-01-01').getTime(),
        ) ?? [];
    return {
      jobsiteWorkers: jws,
      developerIds: ensureDistinctItems(
        jws.map((jw) => jw.jobsiteContractor?.jobsite?.developer?.developerId).filter(Boolean),
      ),
      jobsiteIds: jws.map((jw) => jw.jobsiteContractor?.jobsite?.jobsiteId),
      jobsiteNames: jws.map((jw) => jw.jobsiteContractor?.jobsite?.name),
    };
  }, [workerData]);

  const isOwnWorker = user.userAccountId === worker?.user?.userAccountId;

  const { profileLockingEnabled } = useFlags();

  const isLocked =
    profileLockingEnabled &&
    !isOwnWorker &&
    !user.isAdmin &&
    !user.isContractor &&
    !worker?.profileAccess?.isUnlocked &&
    !userDeveloperIds?.some((userDeveloperId) => developerIds?.includes(userDeveloperId));

  return {
    loading,
    error,
    worker: updatedWorker ?? worker,
    isLocked,
    jobsiteWorkers,
    jobsiteIds,
    developerIds,
    jobsiteNames,
    memberJobsitesIds: userJobsitesIds,
    refetch,
    updateWorkerState,
  };
};
