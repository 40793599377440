import React from 'react';
import { Table, TableContainer } from '@odin-labs/components';
import { NewHeader } from 'components/header/NewHeader';
import { Breakdown, DashboardStatsFacetProps } from './types';
import { columns } from './tables';

const emptyData: Breakdown[] = [];

export function DashboardStatsFacet(props: DashboardStatsFacetProps): React.ReactElement {
  const { title, data = emptyData, loading, onRowClick, noResultsText } = props;

  return (
    <TableContainer>
      <NewHeader size="md" title={title} titleInfo={data?.length} actionsProps={{ headerActions: null }} />
      <Table
        loading={loading}
        initialState={{ pageSize: 100 }}
        columns={columns}
        data={data}
        cellClassName="!odin-pl-5 !odin-table-cell"
        rowClassName="!odin-h-12"
        headerRowClassName="!odin-hidden"
        noResultsText={noResultsText}
        onRowClick={onRowClick}
      />
    </TableContainer>
  );
}
