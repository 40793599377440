import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { appConfig } from 'config/app';
import { AuthContextState } from 'auth/types';

export const useTrackDatadogRumUser = (authContextState: AuthContextState): void => {
  const isRumEnabled = appConfig.ddRumApplicationId && appConfig.ddRumClientToken;

  const { currentUser: user } = authContextState;
  const { identity, userAccountId: id } = user ?? {};
  const { firstName, lastName, email } = identity ?? {};
  const name = identity ? `${firstName} ${lastName}` : undefined;

  useEffect(() => {
    if (isRumEnabled && id) {
      // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context#identify-user-sessions
      datadogRum.setUser({ id, name, email });
    }
    return (): void => {
      datadogRum.removeUser();
    };
  }, [isRumEnabled, id, name, email]);
};
