import React from 'react';
import type { Localize } from 'utils/localization';
import { Languages } from './helpers/languages';

export type { Localize } from 'utils/localization';

export interface LanguageOption {
  label: string;
  value: keyof typeof Languages;
}

export type StepStatusInfo = { stepsCount: number; currentStep: number };

export enum FacialRecognitionStep {
  UserValidation = 'user-validation',
  ProfilePhoto = 'profile-photo',
  ProfilePhotoConfirmation = 'profile-photo-confirmation',
}

export type FacialRecognitionStepProps = {
  workerId: string;
  workerCardId: string;
  language: string;
  onLanguageChange: (language: LanguageOption) => void;
  localize: Localize;
  setCurrentStep: (step: FacialRecognitionStep) => void;
  stepNumberInfo: StepStatusInfo;
};

export type FacialRecognitionPhotoDirectionsProps = {
  localize: Localize;
};

export type FacialRecognitionStepComponent = (props: FacialRecognitionStepProps) => React.ReactElement;
