import React from 'react';
import { QueryStatus } from './types';
import { FormSubmissionPrintContext } from './FormSubmissionPrintContext';

export const useUpdateQueryStatus = (queryName: string, queryStatus: QueryStatus): void => {
  const { loading, error } = queryStatus;
  const { updateQueryStatus } = React.useContext(FormSubmissionPrintContext);

  React.useEffect(() => {
    updateQueryStatus(queryName, queryStatus);
  }, [loading, error]);
};
