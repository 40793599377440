import { EditType } from 'types';
import { SearchWorkerResult } from 'components/searchBox/types';
import {
  EditableFormSubmissionWorker,
  EditableEnhancedFormSubmissionWorker,
} from 'containers/jobsiteFormSubmission/types';

export type OnWorkerConfirmed = (jfsWorker: EditableEnhancedFormSubmissionWorker, editType: EditType) => void;

export type JobsiteFormSubmissionWorkerEdit = EditableEnhancedFormSubmissionWorker & {
  editType: EditType;
  searchWorkerResult: SearchWorkerResult;
};

export type FormSubmissionWorkersProps = {
  jobsiteFormSubmissionId?: string;
  associationType?: string;
  value?: EditableFormSubmissionWorker[];
  onChange?: (value: EditableFormSubmissionWorker[]) => void;
};

export enum ParticipantType {
  Injured = 'Injured',
  Other = 'Other',
  Responsible = 'Responsible',
  Suspected = 'Suspected',
  Witness = 'Witness',
}
