import React from 'react';
import cn from 'classnames';
import { NewHeaderProps } from 'components/header/types';
import { RoutedTabsHeader } from 'components/tabs';
import { Badge } from '@odin-labs/components';
import { NewHeaderActions } from './NewHeaderActions';

export const classes = {
  header: 'odin-header',
  headerLeftContent: 'odin-min-w-0 odin-space-y-1',
  title: cn('odin-flex odin-items-center odin-gap-x-3', 'odin-text-gray-900 odin-mb-0', 'sm:odin-truncate'),
  actionsAndTitleContainer: (hasLeftActions: boolean): string =>
    cn(
      'odin-flex',
      hasLeftActions
        ? 'odin-flex-col-reverse odin-gap-y-7'
        : 'odin-flex-wrap odin-gap-y-2 odin-justify-between odin-items-center',
    ),
};

export const titleBySizeClasses: { [key in NewHeaderProps['size']]: string } = {
  lg: cn(classes.title, 'odin-text-2.66xl odin-font-bold odin-leading-9'),
  md: cn(classes.title, 'odin-text-base odin-font-medium odin-leading-6'),
};

export function NewHeader(props: NewHeaderProps): React.ReactElement {
  const { className, title, titleInfo, subTitle, tabsProps, actionsProps, size = 'lg' } = props;
  const { tabs, currentTab } = tabsProps ?? {};

  const renderTitle = (): React.ReactNode => {
    return typeof title === 'string' ? (
      <h1 className={titleBySizeClasses[size]}>
        {title}
        {!!titleInfo && (
          <Badge className="odin-font-normal" text={titleInfo.toLocaleString('en-US')} color="gray" size={size} />
        )}
      </h1>
    ) : (
      title
    );
  };

  const renderSubTitle = (): React.ReactNode => {
    return typeof subTitle === 'string' ? <div>{subTitle}</div> : subTitle;
  };

  return (
    <div className={cn(classes.header, className)}>
      <div className={classes.actionsAndTitleContainer(actionsProps?.headerActions?.includes('back'))}>
        <div className={classes.headerLeftContent}>
          {renderTitle()}
          {renderSubTitle()}
        </div>
        <NewHeaderActions {...actionsProps} />
      </div>

      {tabs ? <RoutedTabsHeader tabs={tabs} currentTab={currentTab} /> : null}
    </div>
  );
}
