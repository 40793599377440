import { SelectOptionElement } from '@odin-labs/components';
import { StateAbbreviation } from 'apollo/generated/client-operations';
import { UseFormMethods } from 'components/form/types';
import { statesOptions } from 'utils/constants';
import { AddressComponent, AddressComponents, AddressComponentType, Place } from './types';

// ///////////////////////////////////////////////////////////////////////////////////////////////////
// Patch to avoid the following error:
//   Loading the Google Maps JavaScript API without a callback is not supported:
//   https://developers.google.com/maps/documentation/javascript/url-params#required_parameters
//
// The below solution was suggested
// at https://github.com/ErrorPro/react-google-autocomplete/issues/200#issuecomment-1401696101
const callbackName = '_gmapsapicb';
const window = global.window as unknown as Record<string, unknown>;
if (window && !window[callbackName]) {
  Object.assign(window, { [callbackName]: (): void => undefined });
}
export const getApiKeyWithCallback = (apiKey: string): string => `${apiKey}&callback=${callbackName}`;
// ///////////////////////////////////////////////////////////////////////////////////////////////////

const getAddressComponentFactory =
  (addressComponents: AddressComponent[]) =>
  (type: AddressComponentType, shortName?: boolean): string => {
    const result = addressComponents.find((ac) => ac.types.includes(type));
    return shortName ? result?.short_name : result?.long_name;
  };

export const getAddressComponents = (addressComponents: AddressComponent[]): AddressComponents => {
  const getAddressComponent = getAddressComponentFactory(addressComponents);

  const addressLine1 = [getAddressComponent('street_number'), getAddressComponent('route')].filter(Boolean).join(' ');
  const city =
    getAddressComponent('sublocality') ?? getAddressComponent('locality') ?? getAddressComponent('neighborhood') ?? '';
  const state = getAddressComponent('administrative_area_level_1', true) ?? '';
  const zipCode = getAddressComponent('postal_code') ?? '';

  return {
    addressLine1,
    city,
    state,
    zipCode,
  };
};

type AddressFields = {
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: SelectOptionElement<StateAbbreviation>;
  addressZipCode: string;
};

type SetValue = UseFormMethods<AddressFields>['setValue'];
type SetValueConfig = Parameters<SetValue>['2'];
const setValueConfig: SetValueConfig = { shouldValidate: true, shouldDirty: true };

const getFieldName = (fieldPrefix: string, fieldName: keyof AddressFields): string =>
  fieldPrefix ? [fieldPrefix, fieldName].join('.') : fieldName;

export const fillAddressDetailsFactory =
  (fieldPrefix?: string) =>
  (value: Place, { setValue }: { setValue: SetValue }): void => {
    if (value?.address_components) {
      const { addressLine1, city, state, zipCode } = getAddressComponents(value.address_components);
      const stateOption = statesOptions.find((opt) => opt.label === state);

      // when the user selects the same address as the previous one,
      // setValue won't re-render addressLine1 input - so addressLine1 will contain the full address
      // that's why we need to clear addressLine1 first
      setValue(getFieldName(fieldPrefix, 'addressLine1'), '');
      setValue(getFieldName(fieldPrefix, 'addressLine1'), addressLine1, setValueConfig);
      setValue(getFieldName(fieldPrefix, 'addressCity'), city, setValueConfig);
      setValue(getFieldName(fieldPrefix, 'addressState'), stateOption, setValueConfig);
      setValue(getFieldName(fieldPrefix, 'addressZipCode'), zipCode, setValueConfig);
    }
  };

export const fillAddressDetails = fillAddressDetailsFactory();
export const fillJobsiteAddressDetails = fillAddressDetailsFactory('jobsite');
