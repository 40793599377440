import React from 'react';
import { Table, TableContainer } from '@odin-labs/components';
import { JfsWorkerWithHistoricalData } from 'containers/jobsiteFormSubmission/print/types';
import { AuthContext } from 'auth';
import { ReportSectionTitle } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/components/reportSectionTitle';
import { to } from 'acl';
import { getColumns } from './helpers/tables';

export type WorkerReportMedicalDocumentsProps = {
  jfsWorker: JfsWorkerWithHistoricalData;
  jobsiteIds: string[];
};

export function WorkerReportMedicalDocuments(props: WorkerReportMedicalDocumentsProps): React.ReactElement {
  const { jfsWorker, jobsiteIds } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const medicalDocuments = !user.isAllowed(to.accessWorkerReportMedicalDocuments)
    ? []
    : jfsWorker?.historicalData.jobsiteWorkerAccess.jobsiteWorker.contractorWorker.worker.documents.edges
        ?.map(({ node }) => node)
        .filter((jobsiteDocument) => {
          const { jobsiteWorkerDocumentType } = jobsiteDocument;
          const { workerDocumentType } = jobsiteWorkerDocumentType ?? {};
          const { key: workerDocumentTypeKey } = workerDocumentType ?? {};
          return (
            (workerDocumentTypeKey?.includes('medical') || workerDocumentTypeKey?.includes('post-employment')) &&
            jobsiteIds.includes(jobsiteWorkerDocumentType?.jobsite?.jobsiteId)
          );
        }) ?? [];

  const columns = React.useMemo(() => getColumns(), []);

  return (
    <TableContainer>
      <ReportSectionTitle title="Medical History" count={medicalDocuments?.length} />
      <Table columns={columns} data={medicalDocuments} remote disableGlobalFilter disableSortBy />
    </TableContainer>
  );
}
