export enum LookerReport {
  Dashboard = '',
  JobsiteAccessActivity = 'Jobsite%20Access%20Activity',
  JobsiteAccessHistoryCompliance = 'Jobsite%20Access%20History%20and%20Compliance%20Report',
}

export enum LookerFilter {
  Jobsite = 'jobsite',
  TimeRangeType = 'timerangetype',
  StartDate = 'startdate',
  EndDate = 'enddate',
  WorkerQuickCode = 'worker.quick_code',
  Contractor = 'contractor_organization.name',
  Title = 'worker.job_title',
  Trade = 'worker.trade',
  SwipeStatus = 'swipe_events.status',
  SwipeRejectionReason = 'swipe_events.status_notes',
}

export enum LookerTimeRangeType {
  SpecificDate = 'specificdate',
  DateRange = 'daterange',
}

export type LookerFilterSpec = { [K in LookerFilter]?: string | LookerTimeRangeType };
