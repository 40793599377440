import { ChangeType, JobsiteUpdateFormSubmissionInput } from 'apollo/generated/client-operations';
import { DeepMap } from 'react-hook-form';
import { ensureNonEmptyItems } from 'utils';
import {
  AvailableJobsiteWorkerOption,
  CustomFormInputs,
  EditFormData,
  JobsiteFormSubmission,
} from 'containers/jobsiteFormSubmission/types';

type Form = JobsiteFormSubmission['jobsiteForm']['form'];

type WorkersUpdateInput = Required<Pick<JobsiteUpdateFormSubmissionInput, 'jobsiteWorkers'>>;
type JobsiteWorkerUpdateInput = WorkersUpdateInput['jobsiteWorkers'][number];

export const getJobsiteWorkersUpdateInput = (args: {
  data: CustomFormInputs<EditFormData>;
  dirtyFields: DeepMap<CustomFormInputs<EditFormData>, true>;
  defaultValues: CustomFormInputs<EditFormData>;
  form: Form;
}): WorkersUpdateInput => {
  const { data, dirtyFields, defaultValues, form } = args;
  const jobsiteWorkerFields = form.content.edit.jobsiteWorkerFields as string[];

  return {
    jobsiteWorkers: jobsiteWorkerFields?.flatMap((jwField): JobsiteWorkerUpdateInput[] => {
      if (dirtyFields?.[jwField]) {
        const jwFieldValue = data?.[jwField];
        if (Array.isArray(jwFieldValue)) {
          return jwFieldValue
            ?.filter((jfsw) => jfsw.changeType)
            .map((jfsw) => ({
              changeType: jfsw.changeType as ChangeType,
              id: jfsw.id,
              jobsiteWorkerId: jfsw.jobsiteWorker.jobsiteWorkerId,
              associationType: jfsw.associationType,
              extraData: jfsw.extraData,
            }));
        }
        if (jwFieldValue || defaultValues[jwField]) {
          const oldValue = defaultValues[jwField] as AvailableJobsiteWorkerOption;
          const newValue = jwFieldValue as AvailableJobsiteWorkerOption;
          return ensureNonEmptyItems([
            // remove the initial value
            oldValue && {
              changeType: ChangeType.Removed,
              id: oldValue.id,
              jobsiteWorkerId: oldValue.jobsiteWorker.jobsiteWorkerId,
              associationType: oldValue.associationType,
            },
            // add the new value
            {
              changeType: ChangeType.Created,
              id: newValue.id,
              jobsiteWorkerId: newValue.jobsiteWorker.jobsiteWorkerId,
              associationType: newValue.associationType,
            },
          ]);
        }
      }
      return undefined;
    }),
  };
};
