import { Auth, UserRole, UserRoleKey } from 'apollo/generated/client-operations';

const ContractorMemberRoles = [
  UserRoleKey.ContractorAdmin,
  UserRoleKey.ContractorForeman,
  UserRoleKey.ContractorReader,
  UserRoleKey.ContractorEditor,
];

export const isContractorMemberRoleKey = (roleKey: UserRoleKey): boolean => {
  return ContractorMemberRoles.includes(roleKey);
};

export const isContractorMemberUser = (user: { roles: Pick<UserRole, 'key'>[] }): boolean => {
  return user?.roles.some((role) => isContractorMemberRoleKey(role.key)) ?? false;
};

export const isContractorUserSession = (currentSession: Auth): boolean => {
  return currentSession?.user ? isContractorMemberUser(currentSession?.user) : false;
};
