import { dropzoneValidation } from 'components/dropzone/utils';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { SelfOnboardingProfilePhotoFormData, InputFile } from 'containers/selfOnboarding/steps/types';

export const getFormInputs = (): TypedFormInputs<SelfOnboardingProfilePhotoFormData> => {
  return {
    file: {
      element: FormInputTypes.NewDropzone,
      label: 'Profile photo',
      elementProps: {
        accept: { 'image/*': ['.jpg', '.jpeg'] },
        isAvatar: true,
      },
      validation: {
        required: true,
        validate: dropzoneValidation,
      },
      layout: [GridColSpan.SpanFull],
    },
  };
};

export const getDefaultValues = (profilePicture: InputFile): SelfOnboardingProfilePhotoFormData => {
  return {
    file: profilePicture,
  };
};
