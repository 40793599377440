import { SessionType } from 'apollo/generated/client-operations';
import { logout, refreshJwt, trackUserSession } from 'auth/fusionauth';
import { AuthState, SessionFetcher } from 'auth/types';
import { appConfig } from 'config/app';
import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { clearSessionCookie, setJwtCookie } from 'utils/cookies';
import { LocalStorageHookTuple, useLocalStorage } from 'utils/useLocalStorage';

export const useAuthRedirectStorage = (defaultValue?: string): LocalStorageHookTuple => {
  const [value, setValue, clearValue] = useLocalStorage('auth_redirected_from', defaultValue);
  return [value, setValue, clearValue];
};

export const useReportingRedirectStorage = (defaulValue?: string): LocalStorageHookTuple => {
  const [value, setValue, clearValue] = useLocalStorage('reporting_redirected_from', defaulValue);

  return [value, setValue, clearValue];
};

/**
 * Wrapper around the useLocalStorage hook that checks for nullish string values
 * and clears the storage if one happens to be passed
 *
 * @param storageKey - LocalStorage Key to use
 * @param defaultValue - optional default value to set
 * @returns
 */
const useTokenStorage = (storageKey: string, defaultValue?: string): LocalStorageHookTuple => {
  const isValid = (checkedValue?: string): boolean => {
    return checkedValue != null && checkedValue !== 'null' && checkedValue !== 'undefined';
  };
  const checkedDefaultValue = isValid(defaultValue) ? defaultValue : undefined;
  const [tokenValue, setValue, clearValue] = useLocalStorage(storageKey, checkedDefaultValue);

  const setTokenValue = (value: string): void => {
    if (isValid(value)) {
      setValue(value);
    } else {
      clearValue();
    }
  };

  return [tokenValue, setTokenValue, clearValue];
};

export const useAccessTokenStorage = (defaultValue?: string): LocalStorageHookTuple => {
  const [value, setValue, clearValue] = useTokenStorage(
    `odin_${appConfig.isProd ? 'prod' : appConfig.appEnv}_access`,
    defaultValue,
  );
  return [value, setValue, clearValue];
};

export const useRefreshTokenStorage = (defaultValue?: string): LocalStorageHookTuple => {
  const [value, setValue, clearValue] = useTokenStorage(
    `odin_${appConfig.isProd ? 'prod' : appConfig.appEnv}_refresh`,
    defaultValue,
  );
  return [value, setValue, clearValue];
};

export const useAuthenticationRef = (): [MutableRefObject<boolean>, (value: boolean) => void] => {
  const [accessTokenRef] = useAccessTokenStorage();
  const [refreshTokenRef] = useRefreshTokenStorage();

  const getInitialState = useCallback((): boolean => {
    const hasAccess =
      accessTokenRef.current != null && accessTokenRef.current !== 'null' && accessTokenRef.current !== 'undefined';
    const hasRefresh =
      refreshTokenRef.current != null && refreshTokenRef.current !== 'null' && refreshTokenRef.current !== 'undefined';

    return hasAccess && hasRefresh;
  }, [accessTokenRef.current, refreshTokenRef.current]);

  const authRef = useRef(getInitialState());

  const setAuthRef = (value: boolean): void => {
    authRef.current = value;
  };

  return [authRef, setAuthRef];
};

export const useAuth = (): AuthState => {
  const { fusionAuthBaseURL: fusionAuthURL, fusionAuthClientID, fusionAuthTenantID } = appConfig;

  const [accessTokenRef, setAccessToken, clearAccessToken] = useAccessTokenStorage();
  const [refreshTokenRef, setRefreshToken, clearRefreshToken] = useRefreshTokenStorage();
  const [isAuthenticatedRef, setIsAuthenticated] = useAuthenticationRef();

  const sessionFetcherRef = React.useRef<SessionFetcher>();

  return useMemo(() => {
    return {
      getIsAuthenticated: (): boolean => isAuthenticatedRef.current,
      getLoginUrl: (): string =>
        `${window.location.protocol}//${window.location.hostname}${
          window.location.hostname.includes('localhost') ? ':3000' : ''
        }/oauth/login`,
      getAccessToken: (): string => accessTokenRef.current,
      refreshAccessToken: (): Promise<string> =>
        refreshJwt({
          refreshToken: refreshTokenRef.current,
          accessToken: accessTokenRef.current,
        }).then((resp) => {
          setAccessToken(resp.accessToken);
          setRefreshToken(resp.refreshToken);

          trackUserSession(SessionType.RefreshToken, resp.accessToken).then(() => {
            sessionFetcherRef.current?.fetchCurrentSession?.();
          });
          return resp.accessToken;
        }),
      signOut: (): void => {
        const redirectURL = `${window.location.protocol}//${window.location.hostname}${
          window.location.hostname.includes('localhost') ? ':3000' : ''
        }/oauth/logout`;

        clearSessionCookie();
        clearAccessToken();
        clearRefreshToken();
        setIsAuthenticated(false);
        logout({ fusionAuthURL, fusionAuthClientID, fusionAuthTenantID, redirectURL });
      },
      signIn: (accessToken, refresh): void => {
        setAccessToken(accessToken);
        setRefreshToken(refresh);
        setJwtCookie({ accessToken });
        setIsAuthenticated(true);
        trackUserSession(SessionType.Password, accessToken);
      },
      initializeSessionFetcher: (sessionFetcher: SessionFetcher): void => {
        sessionFetcherRef.current = sessionFetcher;
      },
    };
  }, [isAuthenticatedRef, accessTokenRef, refreshTokenRef, sessionFetcherRef]);
};
