import React from 'react';
import { ApolloError, QueryResult, useQuery } from '@apollo/client';
import { selectedJobsiteIds as writeSelectedJobsiteIdsToCache } from 'apollo/cache';
import { GET_SELECTED_JOBSITE_IDS, QueryGetSelectedJobsiteIdsResponse } from './general';

export type UseSelectedJobsiteIdsResult = {
  selectedJobsiteIds: string[];
  /**
   * Used to get the updated selectedJobsitesIds immediately, right after setSelectedJobsiteIds
   */
  getSelectedJobsiteIds: () => string[];
  setSelectedJobsiteIds: (jobsiteIds: string[]) => void;
  loading: boolean;
  error: ApolloError;
  client: QueryResult['client'];
};

export function useSelectedJobsiteIds(): UseSelectedJobsiteIdsResult {
  const { data, loading, error, client } = useQuery<QueryGetSelectedJobsiteIdsResponse>(GET_SELECTED_JOBSITE_IDS);

  const { selectedJobsiteIds } = data ?? {};
  const selectedJobsiteIdsRef = React.useRef(selectedJobsiteIds);

  const setSelectedJobsiteIds = (jobsiteIds: string[]): void => {
    writeSelectedJobsiteIdsToCache(jobsiteIds);
    selectedJobsiteIdsRef.current = jobsiteIds;
    // refetch();
  };

  const getSelectedJobsiteIds = (): string[] => selectedJobsiteIdsRef.current;

  return { selectedJobsiteIds, getSelectedJobsiteIds, setSelectedJobsiteIds, loading, error, client };
}
