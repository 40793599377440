import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { ExternalIntegrationType, useTrackIntegrationHandoffMutation } from 'apollo/generated/client-operations';

export function CamerasContainer(): ReactElement {
  const history = useHistory();

  const [trackIntegrationHandoffMutation, { data }] = useTrackIntegrationHandoffMutation({
    fetchPolicy: 'no-cache',
    variables: { integrationType: ExternalIntegrationType.Avigilon },
  });
  const { url } = data?.trackIntegrationHandoff ?? {};

  React.useEffect(() => {
    trackIntegrationHandoffMutation();
  }, []);

  React.useEffect(() => {
    if (url) {
      window.open(url, '_blank');
      history.goBack();
    }
  }, [url]);

  return <div>Redirecting ...</div>;

  // return (
  //   <Container className="contractors-container">
  //     <NewHeader title="Cameras" titleInfo="" actionsProps={{ headerActions: [] }} />
  //   </Container>
  // );
}
