import React from 'react';
import moment, { Moment, MomentInput } from 'moment';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { JobsiteUpdateContractorInput, useGetJobsitesAndContractorsQuery } from 'apollo/generated/client-operations';
import { JobsiteContractor } from 'containers/contractor/types';
import {
  FormInputTypes,
  GridColSpan,
  UseInputs,
  TypedFormInputs,
  UseFormMethods,
  getUpdateInputValueFunction,
} from 'components/form';
import { BriefcaseIcon, EnvelopeIcon, WrenchIcon } from 'components/icons';
import { ensureNonUndefinedFields, getPhoneNumberAsE164, stringifyEmptyFields } from 'utils';
import { emailValidation, phoneNumberValidation } from 'utils/validation';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { JobsiteOptionElement } from './types';

export type EditJobsiteAssignmentFormData = {
  contractorId: SelectOptionElement;
  jobsiteIds: JobsiteOptionElement[];
  startDate: Moment;
  endDate: Moment;
  parentContractorId: SelectOptionElement;

  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: string;

  contactInfoLabel: undefined;
};

export type EditJobsiteAssignmentInputsArgs = {
  jobsiteContractor: JobsiteContractor;
  contractorId: string;
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: JobsiteOptionElement[];
  editType: 'create' | 'update';
};

type TypedFormInputsArgs = Pick<
  EditJobsiteAssignmentInputsArgs,
  'editType' | 'contractorsOptions' | 'jobsitesOptions'
> & {
  parentContractorsOptions: SelectOptionElement[];
  minStartDate: Moment;
};

const getFormInputs = ({
  editType,
  contractorsOptions,
  jobsitesOptions,
  parentContractorsOptions,
  minStartDate,
}: TypedFormInputsArgs): TypedFormInputs<EditJobsiteAssignmentFormData> => ({
  contractorId: {
    element: FormInputTypes.OdinSelect,
    label: 'Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: contractorsOptions,
      icon: WrenchIcon,
      disabled: (contractorsOptions?.length ?? 0) < 2,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  jobsiteIds: {
    element: FormInputTypes.OdinSelect,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsitesOptions,
      icon: BriefcaseIcon,
      disabled: editType === 'update' || (jobsitesOptions?.length ?? 0) < 2,
      multiple: true,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  startDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'Start Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
      minDate: minStartDate,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  endDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'End Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
      minDate: minStartDate,
    },
    validation: {
      validate: (value: MomentInput, form): boolean | string => {
        if (value) {
          const endMoment = moment(value);
          const startDate = form.getValues('startDate');
          if (startDate) {
            const startMoment = moment(startDate);
            if (endMoment.isValid() && startMoment.isValid() && startMoment.isAfter(endMoment, 'day')) {
              return 'The end date must be after the start date';
            }
          }
        }
        return true;
      },
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  parentContractorId: {
    element: FormInputTypes.OdinSelect,
    label: 'Parent Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: parentContractorsOptions,
      icon: WrenchIcon,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  contactInfoLabel: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: <h3 className="odin-pt-6 odin-mb-0 odin-border-t odin-border-gray-200">Jobsite Contact Info</h3>,
    },
    layout: [GridColSpan.SpanFull],
  },
  firstName: {
    element: FormInputTypes.OdinField,
    label: 'First Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  lastName: {
    element: FormInputTypes.OdinField,
    label: 'Last Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  title: {
    element: FormInputTypes.OdinField,
    label: 'Job Title',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  email: {
    element: FormInputTypes.OdinField,
    label: 'Email Address',
    elementProps: {
      icon: EnvelopeIcon,
    },
    validation: {
      pattern: emailValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  phoneNumber: {
    element: FormInputTypes.OdinField,
    label: 'Phone Number',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: {
      pattern: phoneNumberValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
});

export const useParentContractorsOptions = (jobsiteIds: string[], contractorId: string): SelectOptionElement[] => {
  const { data } = useGetJobsitesAndContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteIds?.length,
    variables: { userJobsitesInput: { jobsiteIds, includeAllJobsites: true } },
  });

  return React.useMemo(() => {
    if (!data) return [];

    const contractors = data.getCurrentSession.user.jobsites.edges.map(({ node: jobsite }) =>
      jobsite.jobsiteContractors.edges
        .map(({ node: { contractor } }) => contractor)
        .filter((c) => c.contractorId !== contractorId),
    );

    const [firstJobsiteContractors, ...otherJobsitesContractors] = contractors ?? [];
    const commonContractors =
      contractors.length === 1
        ? firstJobsiteContractors
        : firstJobsiteContractors?.filter((c) =>
            otherJobsitesContractors.every((ojcArray) =>
              ojcArray.some((ojcItem) => c.contractorId === ojcItem.contractorId),
            ),
          );

    return getContractorsOptions(commonContractors);
  }, [data, contractorId]);
};

export const getFormInputsHook =
  (args: Omit<EditJobsiteAssignmentInputsArgs, 'jobsiteContractor'>): UseInputs<EditJobsiteAssignmentFormData> =>
  ({
    watch,
    setValue,
  }: UseFormMethods<EditJobsiteAssignmentFormData>): TypedFormInputs<EditJobsiteAssignmentFormData> => {
    const { contractorId, contractorsOptions, jobsitesOptions, editType } = args;
    const selectedJobsiteOptions = watch('jobsiteIds');
    const parentContractorOption = watch('parentContractorId');

    const selectedJobsiteIds = selectedJobsiteOptions?.map(({ value }) => value);
    const selectedJobsiteStartDates = selectedJobsiteOptions?.map(({ startDate }) => startDate ?? null).filter(Boolean);
    const maxStartDate =
      selectedJobsiteStartDates?.reduce((result, date) => (moment(result).isAfter(date) ? result : date), null) ?? null;

    const parentContractorsOptions = useParentContractorsOptions(selectedJobsiteIds, contractorId);

    useDidUpdateEffect(() => {
      if (
        parentContractorOption &&
        !parentContractorsOptions?.some((pco) => pco.value === parentContractorOption.value)
      )
        setValue('parentContractorId', null);
    }, [selectedJobsiteIds]);

    return React.useMemo(
      () =>
        getFormInputs({
          editType,
          contractorsOptions,
          jobsitesOptions,
          parentContractorsOptions,
          minStartDate: maxStartDate && moment(maxStartDate),
        }),
      [editType, contractorsOptions, jobsitesOptions, parentContractorsOptions, maxStartDate],
    );
  };

export const getDefaultValues = (
  args: Omit<EditJobsiteAssignmentInputsArgs, 'editType'> & { parentContractorsOptions: SelectOptionElement[] },
): EditJobsiteAssignmentFormData => {
  const { contractorId, jobsiteContractor, contractorsOptions, parentContractorsOptions, jobsitesOptions } = args;
  const { jobsite, parentJobsiteContractor, contact, startDate, endDate } = jobsiteContractor ?? {};
  const { jobsiteId } = jobsite ?? {};
  const { contractorId: parentContractorId } = parentJobsiteContractor?.contractor ?? {};
  const { firstName, lastName, title, email, phoneNumber } = contact ?? {};

  return {
    contractorId: contractorsOptions.find(({ value }) => value === contractorId) ?? null,
    jobsiteIds: (jobsiteId && jobsitesOptions?.filter(({ value }) => value === jobsiteId)) ?? null,
    startDate: startDate ? moment(startDate) : null,
    endDate: endDate ? moment(endDate) : null,
    parentContractorId: parentContractorsOptions?.find(({ value }) => value === parentContractorId) ?? null,
    contactInfoLabel: undefined,

    ...stringifyEmptyFields({ firstName, lastName, title, email, phoneNumber }),
  };
};

export const getUpdateInput = (
  jobsiteContractorId: string,
  data: EditJobsiteAssignmentFormData,
  dirtyFields: DeepMap<EditJobsiteAssignmentFormData, true>,
): JobsiteUpdateContractorInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return ensureNonUndefinedFields<JobsiteUpdateContractorInput>({
    jobsiteContractorId,
    jobsiteContractorInput: {
      startDate: getUpdateInputValue('startDate', true),
      endDate: getUpdateInputValue('endDate'),
      parentContractorId: getUpdateInputValue('parentContractorId'),
      contactInput: {
        firstName: getUpdateInputValue('firstName'),
        lastName: getUpdateInputValue('lastName'),
        email: getUpdateInputValue('email'),
        phoneNumber: getPhoneNumberAsE164(getUpdateInputValue('phoneNumber')),
        title: getUpdateInputValue('title'),
      },
    },
  });
};
