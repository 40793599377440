import React from 'react';
import { Moment } from 'moment';
import classNames from 'classnames';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  BadgeCell,
  BadgeCellComponentProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownFilterOption,
  FilterItems,
} from '@odin-labs/components';
import { GetJobsiteWorkersOrderByField } from 'apollo/generated/client-operations';
import { OnboardingProgressProps } from 'components/onboardingProgress/types';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import {
  OnboardingJobsiteWorkerAccess,
  OnboardingFilters,
  OnboardingJobsiteStats,
  Contractor,
} from 'containers/workerOnboardings/types';
import { getInitialsForUser } from 'utils';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';
import { OnboardingProgressCell, statusColor, statusText } from './cells';
import { JobsiteWorkerOnboardingFilterValue } from './utils';

export type OnboardingJobsiteWorkerColumn = Column<OnboardingJobsiteWorkerAccess>;

export const getColumns = (): OnboardingJobsiteWorkerColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: (jwa: OnboardingJobsiteWorkerAccess): string => {
      const { firstName, lastName } = jwa.jobsiteWorker?.contractorWorker?.worker?.user.identity ?? {};
      return [firstName, lastName].join(' ');
    },
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} hideDetails />,
    componentProps: (jwa: OnboardingJobsiteWorkerAccess): AvatarCellComponentProps => {
      const { worker } = jwa.jobsiteWorker?.contractorWorker ?? {};
      return {
        src: worker?.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(worker?.user),
        objectFit: 'cover',
        size: ['md', 'xl:xs'],
      };
    },
  },
  {
    id: 'progress',
    Header: 'Progress',
    Cell: OnboardingProgressCell,
    componentProps: (jwa: OnboardingJobsiteWorkerAccess): OnboardingProgressProps => ({
      jobsiteWorker: jwa.jobsiteWorker,
    }),
    disableSortBy: true,
  },
  {
    id: 'jobsiteContractor',
    Header: 'Jobsite / Contractor',
    accessor: (): string => '',
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (jwa: OnboardingJobsiteWorkerAccess): ChipsCellComponentProps => ({
      chips: [
        {
          text: jwa.jobsiteWorker.jobsiteContractor.jobsite?.name,
          secondaryText: jwa.jobsiteWorker?.contractorWorker?.contractor?.organization?.name,
          withDot: false,
        },
      ],
    }),
  },
  {
    id: 'trade',
    Header: 'Trade',
    accessor: (jwa: OnboardingJobsiteWorkerAccess): string => jwa.jobsiteWorker?.contractorWorker?.worker?.trade,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: (): string => '',
    Cell: BadgeCell,
    componentProps: (jwa: OnboardingJobsiteWorkerAccess): BadgeCellComponentProps => {
      const status = jwa.jobsiteWorker?.orientationStatus;
      return {
        text: statusText[status],
        color: statusColor[status],
        size: 'lg',
      };
    },
  },
];

export const getOnboardingStatusFilterOptions = (jobsiteStats: OnboardingJobsiteStats): DropdownFilterOption[] => [
  {
    value: JobsiteWorkerOnboardingFilterValue.SelfOnboarding,
    label: 'Self Onboarding',
    count: jobsiteStats?.numberOfOrientationWorkersSelfOnboarding ?? 0,
  },
  {
    value: JobsiteWorkerOnboardingFilterValue.NotStarted,
    label: 'Not Started',
    count:
      (jobsiteStats?.numberOfOrientationWorkersTotal ?? 0) -
      (jobsiteStats?.numberOfOrientationWorkersInProgress ?? 0) -
      (jobsiteStats?.numberOfOrientationWorkersCompleted ?? 0),
  },
  {
    value: JobsiteWorkerOnboardingFilterValue.Medical,
    label: 'Needs Medical',
    count: jobsiteStats?.numberOfOrientationWorkersNeedingMedical ?? 0,
  },
  {
    value: JobsiteWorkerOnboardingFilterValue.AtBadging,
    label: 'At Badging',
    count: jobsiteStats?.numberOfOrientationWorkersNeedingBadging ?? 0,
  },
  {
    value: JobsiteWorkerOnboardingFilterValue.Complete,
    label: 'Complete',
    count: jobsiteStats?.numberOfOrientationWorkersCompleted ?? 0,
  },
];

export const getFilterItems = ({
  jobsites,
  jobsiteStats,
  developerIds,
  jobsiteIds,
  contractors,
  contractorIds,
  onboardingStatus,
  startDate,
  endDate,
}: {
  jobsites: Jobsite[];
  jobsiteStats: OnboardingJobsiteStats;
  contractors: Contractor[];
  developerIds: string[];
  jobsiteIds: string[];
  contractorIds: string[];
  onboardingStatus: string;
  startDate: Moment | string;
  endDate: Moment | string;
}): FilterItems<OnboardingFilters> => ({
  developerIds: {
    header: 'Client',
    type: 'dropdown',
    defaultValue: developerIds,
    componentProps: {
      options: getDeveloperOptionsFromJobsites(jobsites),
      allowSearch: false,
    },
  },
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
    },
  },
  contractorIds: {
    header: 'Contractor',
    type: 'dropdown',
    defaultValue: contractorIds,
    componentProps: {
      options: contractors?.map(({ contractorId: value, organization: { name: label } }) => ({ value, label })) ?? [],
      isVirtual: true,
    },
  },
  dateRange: {
    header: 'Date',
    type: 'datePicker',
    defaultValue: { startDate, endDate },
    layout: classNames('odin-col-span-full'),
    componentProps: {
      isRange: true,
    },
  },
  onboardingStatus: {
    header: 'Status',
    type: 'dropdown',
    defaultValue: onboardingStatus,
    componentProps: {
      options: getOnboardingStatusFilterOptions(jobsiteStats),
      isMulti: false,
    },
  },
});

export const orderByFields: Record<string, GetJobsiteWorkersOrderByField> = {
  worker: GetJobsiteWorkersOrderByField.Name,
  jobsiteContractor: GetJobsiteWorkersOrderByField.JobsiteName,
  trade: GetJobsiteWorkersOrderByField.Trade,
  status: GetJobsiteWorkersOrderByField.OrientationStatus,
};
