import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';

import { useSetDefaultStripePaymentMethodMutation } from 'apollo/generated/client-operations';
import { getIcon } from 'utils/ui';
import { SetDefaultPaymentMethodModalProps } from './types';

const TrashIcon = getIcon('fal fa-trash');

export function SetDefaultPaymentMethodModal(props: SetDefaultPaymentMethodModalProps): ReactElement {
  const { isOpen, paymentMethodId, contractorId, onCancel, onConfirm, refetchQueries } = props;

  const [setDefaultStripePaymentMethod, { loading }] = useSetDefaultStripePaymentMethodMutation({
    onCompleted: (): void => {
      AlertInstance.alert('tc', 'success', 'Success', 'Default Stripe payment method successfully set');
      onConfirm();
    },
    onError: (err): void => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(err));
    },
    variables: { input: { contractorId, paymentMethodId } },
    refetchQueries,
  });

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="sm"
      title="Set this payment method as default?"
      titleAlignment="center"
      text="Going forward this would become the default payment method without a specific jobsite override."
      textAlignment="center"
      onAction={setDefaultStripePaymentMethod}
      actionText="Set as default payment method"
      actionButtonTheme="secondary"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={loading}
    />
  );
}
