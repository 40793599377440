import React from 'react';
import moment, { duration } from 'moment';
import { Badge, BadgeProps, Column } from '@odin-labs/components';
import {
  JobsiteWorkerDocument,
  JobsiteWorkerDocumentVersion,
  WorkerDocumentLevel,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { getFormattedDate } from 'utils';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistoryFormat } from 'components/objectHistory/types';
import { Thumbnail } from 'components/thumbnail';
import { isAcknowledgmentDocument } from 'containers/selfOnboarding/steps/documentStep';
import { DocumentKey, getLatestWorkerDocument } from 'containers/worker/utils';

const getAppliesToValueFromDocumentLevel = (jobsiteDocument: JobsiteWorkerDocument): string => {
  const { jobsiteWorkerDocumentType } = jobsiteDocument ?? {};
  const { jobsite, workerDocumentType } = jobsiteWorkerDocumentType ?? {};
  const { documentLevel } = workerDocumentType ?? {};

  if (documentLevel === WorkerDocumentLevel.Jobsite) {
    return `${jobsite?.name}`;
  }
  if (documentLevel === WorkerDocumentLevel.Organization) {
    return jobsite?.developer?.name != null ? `${jobsite?.developer?.name}` : '';
  }
  return workerDocumentType?.documentLevel ?? null;
};

type GetDocumentStatusArgs = {
  docTypeKey: string;
  document: JobsiteWorkerDocument['jobsiteWorkerDocumentVersions'][number];
  ssoExpirationDate: moment.Moment;
};
type DocumentStatus = Pick<BadgeProps, 'text' | 'color'>;

const getDocumentStatus = (args: GetDocumentStatusArgs): DocumentStatus => {
  const { docTypeKey, document, ssoExpirationDate } = args;
  const fieldValues = (document?.additionalFieldValues ?? []).filter(Boolean);

  if (isAcknowledgmentDocument(docTypeKey as DocumentKey)) {
    const acknowledgmentProgressFraction = Number(
      fieldValues.find(({ key }) => key === 'acknowledgment-progress-fraction')?.value ?? 0,
    );
    const acknowledgmentDate = fieldValues.find(({ key }) => key === 'acknowledgment-date')?.value;

    if (acknowledgmentDate) {
      return { text: 'Completed', color: 'green' };
    }
    if (acknowledgmentProgressFraction) {
      return { text: 'In Progress', color: 'yellow' };
    }
    return { text: 'Not Started', color: 'red' };
  }

  if (docTypeKey === DocumentKey.NycSiteSafetyTrainingCard) {
    const isTrainingConnectCard =
      fieldValues.find(({ key }) => key === 'is-training-connect-card')?.value?.toLowerCase() === 'true';
    if (!isTrainingConnectCard) {
      return { text: 'Invalid', color: 'red' };
    }
  }

  const expirationDate = ssoExpirationDate ?? fieldValues.find(({ key }) => key === 'expiration-date')?.value;

  return expirationDate && moment().isAfter(expirationDate, 'day')
    ? { text: 'Expired', color: 'red' }
    : { text: 'Valid', color: 'green' };
};

export type DocumentData = {
  document: JobsiteWorkerDocument;
  latestVersion: JobsiteWorkerDocumentVersion;
  isGenericDoc: boolean;
  docTypeName: string;
  cardType: string;
  documentStatus: DocumentStatus;
  issueDate: string;
  orientationDate: string;
  acknowledgmentDate: string;
  expirationDate: string;
  ssoExpirationDate: moment.Moment;
};

type DocumentCellProps = { componentProps: DocumentData };

export const toDocumentData = (document: JobsiteWorkerDocument): DocumentData => {
  const { workerDocumentType, expiration } = document?.jobsiteWorkerDocumentType ?? {};
  const { name: docTypeName, key: docTypeKey } = workerDocumentType ?? {};
  const latestVersion = getLatestWorkerDocument(document);
  const fieldValues = (latestVersion?.additionalFieldValues ?? []).filter(Boolean);
  const issueDate = fieldValues.find(({ key }) => key === 'issue-date')?.value;
  const orientationDate = fieldValues.find(({ key }) => key === 'orientation-date')?.value;
  const acknowledgmentDate = fieldValues.find(({ key }) => key === 'acknowledgment-date')?.value;
  const expirationDate = fieldValues.find(({ key }) => key === 'expiration-date')?.value;
  const ssoExpirationDate =
    docTypeKey === DocumentKey.SiteSpecificOrientation && orientationDate && expiration
      ? moment(orientationDate).add(duration(expiration).asMonths(), 'months')
      : null;

  const isGenericDoc = docTypeKey === DocumentKey.Generic;
  const cardTypeKey = isGenericDoc ? 'card-type' : 'type';
  const cardType = fieldValues.find(({ key }): boolean => key === cardTypeKey)?.value;

  const documentStatus = getDocumentStatus({ docTypeKey, document: latestVersion, ssoExpirationDate });

  return {
    document,
    latestVersion,
    isGenericDoc,
    docTypeName,
    cardType,
    documentStatus,
    issueDate,
    orientationDate,
    acknowledgmentDate,
    expirationDate,
    ssoExpirationDate,
  };
};

export const getColumns = (): Column<DocumentData>[] => [
  {
    id: 'docType',
    Header: 'Document / Card Type',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { isGenericDoc, docTypeName, cardType } = componentProps ?? {};
      return (
        <div className="odin-flex odin-flex-col odin-gap-y-2">
          <span className="odin-text-sm odin-font-medium">{isGenericDoc ? 'Other' : docTypeName}</span>
          {cardType && <span>{cardType}</span>}
        </div>
      );
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { documentStatus = null } = componentProps ?? {};
      return (
        documentStatus && <Badge className="odin-uppercase odin-leading-relaxed odin-font-medium" {...documentStatus} />
      );
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'issueDate',
    Header: 'Issue date',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { issueDate, orientationDate, acknowledgmentDate } = componentProps ?? {};
      return getFormattedDate(issueDate ?? orientationDate ?? acknowledgmentDate);
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'expirationDate',
    Header: 'Expiration date',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { expirationDate, ssoExpirationDate } = componentProps ?? {};
      return getFormattedDate(expirationDate ?? ssoExpirationDate);
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'appliesTo',
    Header: 'Applies To',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      return getAppliesToValueFromDocumentLevel(componentProps?.document);
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'updatedBy',
    Header: 'Last Updated By',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { latestVersion } = componentProps ?? {};
      return <ObjectHistory object={latestVersion} format={ObjectHistoryFormat.Name} />;
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'preview',
    Header: 'Preview',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { document, latestVersion } = componentProps ?? {};
      return (
        <div className="odin-flex odin-space-x-3 odin-py-2">
          {latestVersion?.files.map((file: ServerFile, index: number) => {
            const fileDownloadUrl = file?.downloadUrl;
            const isHtmlFile = fileDownloadUrl?.endsWith('.html') || fileDownloadUrl?.includes('.html?');
            return (
              <a
                href={isHtmlFile ? `/onboarding/document/${document.jobsiteWorkerDocumentId}/print` : file?.downloadUrl}
                onClick={(e): void => {
                  e.stopPropagation();
                }}
                target="_blank"
                download={!isHtmlFile}
                className="avatar avatar-lg"
                rel="noreferrer"
                key={`${index}-${file?.downloadUrl}`} // eslint-disable-line react/no-array-index-key
              >
                <Thumbnail file={file} />
              </a>
            );
          })}
        </div>
      );
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
];
