import React from 'react';
import cn from 'classnames';
import { GetJobsiteFormSubmissionsOrderByField } from 'apollo/generated/client-operations';
import { faEdit, faFileChartLine, faTrash, faUserHardHat } from '@fortawesome/pro-light-svg-icons';
import {
  JobsiteFormSubmission,
  JobsiteFormSubmissionFilters,
  JobsiteFormSubmissionFiltersOptions,
  TypedJobsiteFormSubmission,
} from 'containers/forms/formsTab/types';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  getFaIcon,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { getPrettyFormattedDateTimeWithTz } from 'utils/dates';
import { getInitialsForUser, getUserFullName } from 'utils';
import { Moment } from 'moment';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';

type DefaultExtraData = Record<string, unknown>;

export type FormSubmissionColumn<TExtraData extends DefaultExtraData = DefaultExtraData> = Column<
  TypedJobsiteFormSubmission<TExtraData>
>;

const FileChartLineIcon = getFixedWidthFaIcon({ icon: faFileChartLine });
const EditIcon = getFixedWidthFaIcon({ icon: faEdit });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });
const UserHardHatIcon = getFaIcon({ icon: faUserHardHat });

const classes = {
  manPowerContainer: cn('odin-flex odin-items-center odin-gap-x-1.5'),
  manPowerIcon: cn('odin-text-base odin-text-odin-primary'),
  manPowerText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
};

export function ManpowerGatewayCell({ value }: { value: string }): React.ReactElement {
  return (
    <div className={classes.manPowerContainer}>
      <UserHardHatIcon className={classes.manPowerIcon} />
      <span className={classes.manPowerText}>{value}</span>
    </div>
  );
}

type GetAvatarColumnArgs<TExtraData extends DefaultExtraData = DefaultExtraData> = Omit<
  FormSubmissionColumn<TExtraData>,
  'Cell' | 'CellLoading' | 'componentProps'
> & {
  componentProps: (formSubmission: TypedJobsiteFormSubmission<TExtraData>) => AvatarCellComponentProps;
};

export function getAvatarColumn<TExtraData extends DefaultExtraData = DefaultExtraData>(
  args: GetAvatarColumnArgs<TExtraData>,
): FormSubmissionColumn<TExtraData> {
  return {
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} />,
    ...(args as FormSubmissionColumn<TExtraData>),
  };
}

export enum FormColumnName {
  JobsiteContractor = 'jobsiteContractor',
  Manpower = 'manpower',
  Date = 'date',
  StartTime = 'startTime',
  EndTime = 'endTime',
  CreatedBy = 'createdBy',
  Actions = 'actions',
}

export const getColumns = ({
  extraColumns = [],
  navigateToFormSubmissionPrintPage,
  navigateToFormSubmissionEditPage,
  openRemoveFormSubmissionModal,
}: {
  extraColumns: FormSubmissionColumn[];
  navigateToFormSubmissionPrintPage: (formSubmissionId: string) => void;
  navigateToFormSubmissionEditPage: (formSubmissionId: string) => void;
  openRemoveFormSubmissionModal: (jobsiteFormSubmission: JobsiteFormSubmission) => void;
}): FormSubmissionColumn[] => [
  {
    id: FormColumnName.JobsiteContractor,
    Header: 'Jobsite / Contractor',
    accessor: (): string[] => [],
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (formSubmission: JobsiteFormSubmission): ChipsCellComponentProps => ({
      chips: [
        {
          text: formSubmission?.jobsiteForm.jobsite.name,
          secondaryText: formSubmission.jobsiteContractors.edges.find(
            ({ node: jsfC }) => jsfC.associationType === 'assignment',
          )?.node.jobsiteContractor.contractor.organization.name,
          withDot: false,
          // isActive: formSubmission.jobsiteWorker?.currentAccess.isAllowed,
        },
      ],
    }),
  },
  ...extraColumns,
  {
    id: FormColumnName.CreatedBy,
    Header: 'Created by',
    accessor: (formSubmission: JobsiteFormSubmission): string =>
      getUserFullName(formSubmission.objectHistory.createdBy),
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} />,
    componentProps: (formSubmission: JobsiteFormSubmission): AvatarCellComponentProps => ({
      src: formSubmission.objectHistory.createdBy.worker.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(formSubmission.objectHistory.createdBy),
      objectFit: 'cover',
      size: ['md', 'xl:xs'],
      detail: getPrettyFormattedDateTimeWithTz(formSubmission.objectHistory.createdAt, formSubmission.timeZone, true),
    }),
  },
  {
    id: FormColumnName.Actions,
    Header: '',
    Cell: DropdownButtonCell,
    CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
    componentProps: (formSubmission: JobsiteFormSubmission): DropdownButtonCellComponentProps => ({
      cellAlignment: 'right',
      menuItems: [
        [
          {
            icon: FileChartLineIcon,
            text: 'View',
            theme: 'secondary',
            onClick: (): void => navigateToFormSubmissionPrintPage(formSubmission.id),
          },
          {
            icon: EditIcon,
            text: 'Edit',
            theme: 'secondary',
            onClick: (): void => navigateToFormSubmissionEditPage(formSubmission.id),
          },
          {
            icon: TrashIcon,
            text: 'Delete',
            theme: 'danger',
            onClick: (): void => openRemoveFormSubmissionModal(formSubmission),
          },
        ],
      ],
    }),
  },
];

export const getFilterItems = ({
  startDate,
  endDate,
  jobsites,
  filtersOptions,
  developerIds,
  jobsiteIds,
  contractorIds,
  createdByIds,
  hasAttachments,
}: {
  startDate: Moment | string;
  endDate: Moment | string;
  jobsites: Jobsite[];
  filtersOptions: JobsiteFormSubmissionFiltersOptions;
  developerIds: string[];
  jobsiteIds: string[];
  contractorIds: string[];
  createdByIds: string[];
  hasAttachments: boolean;
}): FilterItems<JobsiteFormSubmissionFilters> => ({
  dateRange: {
    header: 'Date',
    type: 'datePicker',
    defaultValue: { startDate, endDate },
    layout: cn('odin-col-span-full'),
    componentProps: {
      isRange: true,
    },
  },
  developerIds: {
    header: 'Client',
    type: 'dropdown',
    defaultValue: developerIds,
    componentProps: {
      options: getDeveloperOptionsFromJobsites(jobsites),
      allowSearch: false,
    },
  },
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: getJobsitesOptions(jobsites) ?? [],
    },
  },
  contractorIds: {
    header: 'Contractor',
    type: 'dropdown',
    defaultValue: contractorIds,
    componentProps: {
      options: filtersOptions?.contractors ?? [],
      isVirtual: true,
    },
  },
  createdByIds: {
    header: 'Created by',
    type: 'dropdown',
    defaultValue: createdByIds,
    componentProps: {
      options: filtersOptions?.createdByUsers ?? [],
    },
  },
  hasAttachments: {
    header: 'Has Attachment(s)',
    type: 'checkbox',
    defaultValue: hasAttachments,
  },
});

export const orderByFields: Record<string, GetJobsiteFormSubmissionsOrderByField> = {
  [FormColumnName.JobsiteContractor]: GetJobsiteFormSubmissionsOrderByField.Name,
  [FormColumnName.Date]: GetJobsiteFormSubmissionsOrderByField.Date,
  [FormColumnName.Manpower]: GetJobsiteFormSubmissionsOrderByField.Manpower,
  [FormColumnName.StartTime]: GetJobsiteFormSubmissionsOrderByField.StartTime,
  [FormColumnName.EndTime]: GetJobsiteFormSubmissionsOrderByField.EndTime,
  [FormColumnName.CreatedBy]: GetJobsiteFormSubmissionsOrderByField.CreatedBy,
};
