import { ApolloError } from '@apollo/client';
import { useGetJobsitesQuery, GetJobsitesQuery } from 'apollo/generated/client-operations';

export type Jobsite = GetJobsitesQuery['getCurrentSession']['user']['jobsites']['edges'][number]['node'];

export type UseAvailableJobsitesResult = {
  jobsites: Jobsite[];
  loading: boolean;
  error: ApolloError;
  refetch: ReturnType<typeof useGetJobsitesQuery>['refetch'];
};

export type UseAvailableJobsitesOptions = {
  resetCache?: boolean;
  includeAllJobsites?: boolean;
  skip?: boolean;
};

type ResetCache = {
  includeAllJobsites: Record<string, boolean>;
};

const resetCache: ResetCache = {
  includeAllJobsites: {
    true: true,
    false: true,
  },
};

/**
 * This custom hook provides available jobsites for the authenticated user.
 * The data will be retrieved from the cache, except for the first call when is retrieved from the server.
 * In order to force a cache refresh you have 2 options:
 * - either pass `resetCache: true` within `options` for immediate cache refresh
 * - or call `useAvailableJobsites.resetCacheOnNextCall()` and the cache will be refreshed on next call
 */
const useAvailableJobsitesHook = (options?: UseAvailableJobsitesOptions): UseAvailableJobsitesResult => {
  const { includeAllJobsites = false } = options ?? {};
  const shouldResetCache = options?.resetCache ?? resetCache.includeAllJobsites[includeAllJobsites.toString()];
  const { data, loading, error, refetch } = useGetJobsitesQuery({
    fetchPolicy: shouldResetCache ? 'network-only' : 'cache-first',
    skip: options?.skip,
    variables: {
      userJobsitesInput: {
        includeAllJobsites,
      },
    },
  });
  resetCache.includeAllJobsites[includeAllJobsites.toString()] = false;

  const availableJobsites = data?.getCurrentSession?.user?.jobsites?.edges
    .map(({ node }) => node)
    .sort((a, b) => a.name.localeCompare(b.name));

  return {
    jobsites: availableJobsites,
    loading,
    error,
    refetch,
  };
};

const resetCacheOnNextCall = (): void => {
  resetCache.includeAllJobsites = {
    true: true,
    false: true,
  };
};

export const useAvailableJobsites = Object.assign(useAvailableJobsitesHook, { resetCacheOnNextCall });
