import React from 'react';
import { Moment } from 'moment';
import cn from 'classnames';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  BadgeCell,
  BadgeCellComponentProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownFilterOption,
  FilterItems,
} from '@odin-labs/components';
import { GetJobsiteWorkersOrderByField } from 'apollo/generated/client-operations';
import {
  SiteSafetyOrientationFilters,
  SiteSafetyOrientationJobsiteWorkerAccess,
} from 'containers/workerSiteSafetyOrientations/types';
import { camelToTitleCase, ensureNonUndefinedFields, getInitialsForUser } from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { getSiteOrientationBadgeProps, SiteSafetyOrientationStatusFilterValue } from './utils';

export type SiteSafetyOrientationJobsiteWorkerColumn = Column<SiteSafetyOrientationJobsiteWorkerAccess>;

export const getColumns = ({
  canSortByStatus,
}: {
  canSortByStatus: boolean;
}): SiteSafetyOrientationJobsiteWorkerColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: (jwa: SiteSafetyOrientationJobsiteWorkerAccess): string => {
      const { firstName, lastName } = jwa.jobsiteWorker?.contractorWorker?.worker?.user.identity ?? {};
      return [firstName, lastName].join(' ');
    },
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} hideDetails />,
    componentProps: (jwa: SiteSafetyOrientationJobsiteWorkerAccess): AvatarCellComponentProps => {
      const { worker } = jwa.jobsiteWorker?.contractorWorker ?? {};
      return {
        src: worker?.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(worker?.user),
        objectFit: 'cover',
        size: ['md', 'xl:xs'],
      };
    },
  },
  {
    id: 'jobsiteContractor',
    Header: 'Jobsite / Contractor',
    accessor: (): string => '',
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (jwa: SiteSafetyOrientationJobsiteWorkerAccess): ChipsCellComponentProps => ({
      chips: [
        {
          text: jwa.jobsiteWorker.jobsiteContractor.jobsite?.name,
          secondaryText: jwa.jobsiteWorker?.contractorWorker?.contractor?.organization?.name,
          withDot: false,
        },
      ],
    }),
  },
  {
    id: 'trade',
    Header: 'Trade',
    accessor: (jwa: SiteSafetyOrientationJobsiteWorkerAccess): string =>
      jwa.jobsiteWorker?.contractorWorker?.worker?.trade,
  },
  {
    id: 'siteSafetyTrainingDate',
    Header: 'Last Site Specific Orientation',
    accessor: (jwa: SiteSafetyOrientationJobsiteWorkerAccess): string => {
      const { siteSpecificTrainingCompletedAt } = jwa?.jobsiteWorker ?? {};
      return siteSpecificTrainingCompletedAt ? getISODateTimeToFormattedDate(siteSpecificTrainingCompletedAt) : 'N/A';
    },
  },
  {
    id: 'siteSafetyStatus',
    Header: 'Status',
    accessor: (): string => '',
    Cell: BadgeCell,
    componentProps: (jwa: SiteSafetyOrientationJobsiteWorkerAccess): BadgeCellComponentProps => {
      const { siteSpecificTrainingCompletedAt, isSiteSpecificTrainingExpired } = jwa?.jobsiteWorker ?? {};
      const { text, color } = getSiteOrientationBadgeProps(
        siteSpecificTrainingCompletedAt,
        isSiteSpecificTrainingExpired,
      );
      return { size: 'lg', text, color };
    },
    disableSortBy: !canSortByStatus,
  },
];

export const siteOrientationStatusOptions: DropdownFilterOption[] = Object.entries(
  SiteSafetyOrientationStatusFilterValue,
).map(([key, value]) => ({
  value,
  label: camelToTitleCase(key),
}));

export const getFilterItems = ({
  jobsites,
  jobsiteIds,
  developerIds,
  contractors,
  contractorIds,
  siteOrientationStatus,
  startDate,
  endDate,
}: {
  jobsites: Jobsite[];
  contractors: { contractorId: string; organization: { name: string } }[];
  jobsiteIds: string[];
  developerIds: string[];
  contractorIds: string[];
  siteOrientationStatus: string;
  startDate: Moment | string;
  endDate: Moment | string;
}): FilterItems<SiteSafetyOrientationFilters> =>
  ensureNonUndefinedFields<FilterItems<SiteSafetyOrientationFilters>>({
    developerIds: {
      header: 'Client',
      type: 'dropdown',
      defaultValue: developerIds,
      componentProps: {
        options: getDeveloperOptionsFromJobsites(jobsites),
        allowSearch: false,
      },
    },
    jobsiteIds: {
      header: 'Jobsite',
      type: 'dropdown',
      defaultValue: jobsiteIds,
      componentProps: {
        options: jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
      },
    },
    contractorIds: {
      header: 'Contractor',
      type: 'dropdown',
      defaultValue: contractorIds,
      componentProps: {
        options: contractors?.map(({ contractorId: value, organization: { name: label } }) => ({ value, label })) ?? [],
        isVirtual: true,
      },
    },
    dateRange:
      siteOrientationStatus === SiteSafetyOrientationStatusFilterValue.Expired
        ? undefined
        : {
            header: 'Date',
            type: 'datePicker',
            defaultValue: { startDate, endDate },
            layout: cn('odin-col-span-full'),
            componentProps: {
              isRange: true,
            },
          },
    siteSafetyOrientationStatus: {
      header: 'Status',
      type: 'dropdown',
      defaultValue: siteOrientationStatus,
      componentProps: {
        options: siteOrientationStatusOptions,
        isMulti: false,
      },
    },
  });

export const orderByFields: Record<string, GetJobsiteWorkersOrderByField> = {
  worker: GetJobsiteWorkersOrderByField.Name,
  jobsiteContractor: GetJobsiteWorkersOrderByField.Contractor,
  trade: GetJobsiteWorkersOrderByField.Trade,
  siteSafetyTrainingDate: GetJobsiteWorkersOrderByField.CompletedSiteSpecificTrainingAt,
  siteSafetyStatus: GetJobsiteWorkersOrderByField.SiteSpecificStatus,
};
