import React from 'react';
import cn from 'classnames';
import { Badge, Label } from '@odin-labs/components';
import { getIcon } from 'utils/ui';

const classes = {
  title: cn('odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium'),
  count: cn('odin-ml-3'),
  icon: cn('odin-text-odin-primary odin-mr-1.5'),
  dates: cn('odin-ml-9'),
};

export type ReportSectionTitleProps = React.PropsWithChildren<{
  title: string;
  count: number;
  dates?: string;
}>;

export function ReportSectionTitle({ title, count, dates, children }: ReportSectionTitleProps): React.ReactElement {
  return (
    <div>
      <span className={classes.title}>{title}</span>
      <Badge className={classes.count} color="gray" text={count.toString()} />
      {dates && (
        <Label className={classes.dates} size="lg" text={dates} icon={getIcon(cn('fal fa-calendar', classes.icon))} />
      )}
      {children}
    </div>
  );
}
