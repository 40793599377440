import { getCurrentDomain, isIphone } from 'utils';

import prodChromeFavicon from 'assets/images/android-chrome-192x192.png';
import prodAppleFavicon from 'assets/images/apple-touch-icon.png';
import prodFavicon from 'assets/images/favicon-32x32.png';
import prodMsFavicon from 'assets/images/mstile-150x150.png';

import demoChromeFavicon from 'assets/images/demo-android-chrome-192x192.png';
import demoAppleFavicon from 'assets/images/demo-apple-touch-icon.png';
import demoFavicon from 'assets/images/demo-favicon-32x32.png';
import demoMsFavicon from 'assets/images/demo-mstile-150x150.png';

import stgChromeFavicon from 'assets/images/stg-android-chrome-144x144.png';
import stgAppleFavicon from 'assets/images/stg-apple-touch-icon.png';
import stgFavicon from 'assets/images/stg-favicon-32x32.png';
import stgMsFavicon from 'assets/images/stg-mstile-150x150.png';

import qaChromeFavicon from 'assets/images/qa-android-chrome-144x144.png';
import qaAppleFavicon from 'assets/images/qa-apple-touch-icon.png';
import qaFavicon from 'assets/images/qa-favicon-32x32.png';
import qaMsFavicon from 'assets/images/qa-mstile-150x150.png';

import devChromeFavicon from 'assets/images/dev-android-chrome-192x192.png';
import devAppleFavicon from 'assets/images/dev-apple-touch-icon.png';
import devFavicon from 'assets/images/dev-favicon-32x32.png';
import devMsFavicon from 'assets/images/dev-mstile-150x150.png';

import localChromeFavicon from 'assets/images/local-android-chrome-144x144.png';
import localAppleFavicon from 'assets/images/local-apple-touch-icon.png';
import localFavicon from 'assets/images/local-favicon-32x32.png';
import localMsFavicon from 'assets/images/local-mstile-150x150.png';

const metaAssets = (): { [assetKey: string]: string }[] => {
  const domain = getCurrentDomain();

  const getFavicon = (): string => {
    if (domain.includes('.demo.')) return demoMsFavicon;
    if (domain.includes('.stg.')) return stgMsFavicon;
    if (domain.includes('.qa.')) return qaMsFavicon;
    if (domain.includes('.dev.')) return devMsFavicon;
    if (domain.includes('.local.') || domain.includes('localhost:')) return localMsFavicon;
    return prodMsFavicon;
  };

  return [
    { charset: 'utf-8' },
    // Meta descriptions are commonly used on search engine result pages to
    // display preview snippets for a given page.
    { name: 'description', content: 'ODIN' },
    { name: 'robots', content: 'noindex, nofollow' },
    // Setting IE=edge tells Internet Explorer to use the latest engine to
    //  render the page and execute Javascript
    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
    // Using the viewport tag allows you to control the width and scaling of
    // the browser's viewport:
    // - include width=device-width to match the screen's width in
    // device-independent pixels
    // - include initial-scale=1 to establish 1:1 relationship between css pixels
    // and device-independent pixels
    // - ensure your page is accessible by not disabling user scaling.
    {
      name: 'viewport',
      /** On iOS `maximum-scale=1` will stop the auto-zooming, but still leave the user with the full ability
       * to pinch and zoom.
       * On Android the viewport meta tag is not updated, so it continues to use the default browser behavior
       * which does not auto-zoom. In both cases pinch and zoom behavior is preserved.
       * https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming
       * */
      content: `width=device-width, initial-scale=1, shrink-to-fit=no${isIphone() ? ', maximum-scale=1' : ''}`,
    },
    // Disable tap highlight on IE
    { name: 'msapplication-tap-highlight', content: 'no' },
    // Add to homescreen for Chrome on Android
    { name: 'mobile-web-app-capable', content: 'yes' },
    // Add to homescreen for Safari on IOS
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'apple-mobile-web-app-title', content: 'ODIN' },
    // Tile icon for Win8 (144x144 + tile color)
    { name: 'msapplication-TileImage', content: getFavicon() },
    { name: 'msapplication-TileColor', content: '#ffffff' },
  ];
};

const linkAssets = (): { rel: string; sizes?: string; href: string }[] => {
  const domain = getCurrentDomain();

  const getFavicon = (): string[] => {
    if (domain.includes('.demo.')) return [demoFavicon, demoChromeFavicon, demoAppleFavicon];
    if (domain.includes('.stg.')) return [stgFavicon, stgChromeFavicon, stgAppleFavicon];
    if (domain.includes('.qa.')) return [qaFavicon, qaChromeFavicon, qaAppleFavicon];
    if (domain.includes('.dev.')) return [devFavicon, devChromeFavicon, devAppleFavicon];
    if (domain.includes('.local.') || domain.includes('localhost:'))
      return [localFavicon, localChromeFavicon, localAppleFavicon];
    return [prodFavicon, prodChromeFavicon, prodAppleFavicon];
  };

  const [favicon, chromeFavicon, appleFavicon] = getFavicon();

  return [
    // Add to homescreen for Chrome on Android
    { rel: 'icon', href: favicon },
    { rel: 'icon', sizes: '192x192', href: chromeFavicon },
    // Add to homescreen for Safari on IOS
    { rel: 'apple-touch-icon', sizes: '152x152', href: appleFavicon },
  ];
};

export const title = 'ODIN';
export const meta = metaAssets();
export const link = linkAssets();
