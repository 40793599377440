import { to } from 'acl';
import { AuthContext } from 'auth';
import { InfoList } from 'components/infoList';
import { InfoRow } from 'components/infoRow';
import { LoadingError } from 'components/loadingError';
import { DeveloperTabProps } from 'containers/developer/types';
import { EditDeveloperModal } from 'containers/developers/modals/EditDeveloperModal';
import React, { ReactElement } from 'react';
import { useBoolean } from 'utils';
import { getInfoRows } from './utils';

export function DeveloperInfoTabContainer(props: DeveloperTabProps): ReactElement {
  const { developer, onTabApiChange, loading, error, refetchDeveloperData } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    value: isEditDeveloperModalOpen,
    setTrue: openEditDeveloperModal,
    setFalse: closeEditDeveloperModal,
  } = useBoolean(false);

  const developerInfoRows = getInfoRows(developer);

  React.useEffect(() => onTabApiChange({ refetchData: refetchDeveloperData }), [refetchDeveloperData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const allowEdit = user.isAllowed(to.editDevelopers);

  return (
    <>
      <InfoList withBottomBorder loading={loading}>
        {developerInfoRows.map((row) => (
          <InfoRow
            key={row.label}
            {...row}
            allowEdit={allowEdit}
            loading={loading}
            onEditClick={openEditDeveloperModal}
          />
        ))}
      </InfoList>
      <EditDeveloperModal
        isOpen={isEditDeveloperModalOpen}
        onCancel={closeEditDeveloperModal}
        onConfirm={closeEditDeveloperModal}
        developer={developer}
      />
    </>
  );
}
