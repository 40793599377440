import { DeepMap } from 'react-hook-form';
import { DeveloperCreateInput } from 'apollo/generated/client-operations';
import { ensureNonUndefinedFields } from 'utils';
import { EditDeveloperFormData } from './types';
import { getCommonOrganizationInput } from './EditDeveloperModal.forms';

export const getAddInput = (
  organizationId: string,
  data: EditDeveloperFormData,
  dirtyFields: DeepMap<EditDeveloperFormData, true>,
): DeveloperCreateInput => {
  return ensureNonUndefinedFields<DeveloperCreateInput>({
    organizationId,
    organizationInput: organizationId ? undefined : getCommonOrganizationInput(data, dirtyFields),
  });
};
