import React from 'react';

export type GradientCardProps = {
  headerText: string;
  text: string;
};

export function GradientCard(props: GradientCardProps): React.ReactElement {
  const { headerText, text } = props;
  return (
    <div className="odin-p-6 odin-rounded-xl odin-bg-odin-gradient-2 odin-text-white odin-text-center">
      <h2 className="odin-mb-2 odin-text-1.5xl odin-font-medium">{headerText}</h2>
      <div className="odin-text-sm">{text}</div>
    </div>
  );
}
