import cn from 'classnames';

export const classes = {
  butterBar: cn(
    'odin-border odin-border-opacity-30 odin-bg-odin-light-yellow odin-border-odin-warning',
    'odin-flex-1',
    'odin-rounded-md',
    'odin-flex',
    'odin-items-center',
    'odin-p-1 sm:odin-p-4',
    'odin-mb-3',
  ),
  jobsiteWorkerField: cn('odin-flex'),
  jobsiteWorkerFieldLabel: cn(
    'jobsite-worker-field-label',
    'odin-w-5/12',
    'odin-text-sm',
    'odin-font-medium',
    'odin-mb-2.5',
  ),
  jobsiteWorkerFieldValue: cn('jobsite-worker-field-value', 'odin-w-7/12', 'odin-text-sm', 'odin-ml-4'),
  siteAccessText: cn('odin-text-2xs odin-text-odin-dark-gray'),
  footerStatus: cn('odin-text-sm odin-font-medium odin-mb-0'),
  footerLabel: cn('odin-text-sm odin-font-normal !odin-text-[#12263f]'),
};
