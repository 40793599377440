import React from 'react';
import { UserPendingChangeType } from 'apollo/generated/client-operations';

type SubmitUserChangeActionProps = {
  isHidden: boolean;
  type: UserPendingChangeType;
  onClick: (value: { type: UserPendingChangeType }) => void;
};

export function CancelUserChangeAction(props: SubmitUserChangeActionProps): React.ReactElement {
  const { isHidden, type, onClick } = props;

  const onLinkClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();
      onClick?.({ type });
    },
    [onClick],
  );

  if (isHidden) return null;

  return (
    <a href="" onClick={onLinkClick} className="odin-underline">
      Cancel
    </a>
  );
}
