import { ApolloState } from 'apollo/types';
import { AppConfigState } from 'config/types';
import { File as GqlFile } from 'apollo/generated/client-operations';
import { SelectOptionElement } from '@odin-labs/components';

export type Maybe<T> = T | null;

export interface ClientAppState {
  apolloState: ApolloState;
  appConfigState?: AppConfigState;
}

export interface CustomWindow extends Window {
  __CLIENT_APP_STATE__?: ClientAppState;
}

/**
 * Returns all fields for a specified type <T>.
 */
export const getFields = <T>(): { [P in keyof T]: P } => {
  return new Proxy(
    {},
    {
      get: (_target, prop): string | number | symbol => prop,
    },
  ) as {
    [P in keyof T]: P;
  };
};

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type Editable<T> = T & {
  changeType?: 'created' | 'updated' | 'removed';
};

export type EditType = 'create' | 'update';
export type ChangeActionType = EditType | 'remove';
export type Change<T> = { type: ChangeActionType; item?: T };

export type ServerFile = Omit<GqlFile, 'uploadedAt'>;
export type TransformableFile = File & { isTransformationInProgress?: boolean };
export type InputFile = TransformableFile | ServerFile;

export type AcknowledgmentProgress = {
  fraction: number;
  value: number;
};

export type StringEnum = { [s: string]: string };

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::                                                                                       :::
// ::: Union Type Merging in Typescript                                                      :::
// ::: https://dev.to/lucianbc/union-type-merging-in-typescript-9al                          :::
type AllKeys<T> = T extends any ? keyof T : never;
type PickType<T, K extends AllKeys<T>> = T extends { [k in K]?: any } ? T[K] : undefined;
/** Merge a type union (of objects) into a single object type. */
export type MergeUnionType<T extends object> = {
  [k in AllKeys<T>]: PickType<T, k>;
};
// :::                                                                                       :::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export type EmailAlreadyExistsErrorExtensions = { workerId?: string };
export type PhoneAlreadyExistsErrorExtensions = { workerId?: string };

export type ReplaceSelectOptionElement<T> = {
  [K in keyof T]: T[K] extends SelectOptionElement<infer U>
    ? U
    : T[K] extends SelectOptionElement<infer U>[]
    ? U[]
    : T[K];
};

// Utility type to extract all field types
export type FieldTypes<T> = T[keyof T];
