import { ProviderConfig } from 'launchdarkly-react-client-sdk/lib/types';
import { User } from 'auth';
import { appConfig } from 'config/app';

export const getProviderConfig = (user: User): ProviderConfig => {
  const { userAccountId, identity, roles = [] } = user;

  const email = identity?.email;

  return {
    clientSideID: appConfig.featureFlagsSiteId,
    context: {
      kind: 'user',
      key: userAccountId,
      email,
      custom: {
        roles: Array.from(new Set(roles.map((role) => role.key))),
        hostname: window.location.hostname,
      },
    },
  };
};

export const areFeatureFlagsEnabled = (): boolean => !!appConfig.featureFlagsSiteId;
