import React from 'react';
import { generateApiUrl } from 'config/app';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { Copy } from 'containers/mobileBadge/helpers/languages';
import { StepInfo } from './components/StepInfo';
import { StepInfoTitle } from './components/StepInfoTitle';
import { MobileBadgeDownloadStepProps } from './types';

const googleImageSrc =
  'https://storage.googleapis.com/odin-static-assets/wallet/google/add-to-wallet-assets/' +
  'enUS_add_to_google_wallet_add-wallet-badge.png';

const appleImageSrc =
  'https://storage.googleapis.com/odin-static-assets/wallet/apple/add-to-wallet-assets/' +
  'US_UK/RGB/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';

export function MobileBadgeDownloadStep(props: MobileBadgeDownloadStepProps): React.ReactElement {
  const { localize, workerId, workerCardId } = props;

  const googleUrl = generateApiUrl('wallet', 'google', 'mobile-badge', 'generate', workerId, workerCardId);
  const appleUrl = generateApiUrl('wallet', 'apple', 'mobile-badge', 'generate', workerId, workerCardId);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.mobile_badging_download_header)}
          subtitle={localize(Copy.mobile_badging_download_instructions)}
        />
      </StepInfo>
      <div className="odin-p-7.5 sm:odin-p-0 odin-col-span-2 odin-flex odin-justify-around">
        <a href={appleUrl}>
          <img src={appleImageSrc} className="odin-h-10" alt="Download from Google Play Store" />
        </a>
        <a href={googleUrl}>
          <img src={googleImageSrc} className="odin-h-10" alt="Download from Apple Store" />
        </a>
      </div>
    </div>
  );
}
