import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NewDropdownButton, NewMenuItemProps, TabConfig } from '@odin-labs/components';

import { ensureNonEmptyItems, useBoolean } from 'utils';
import { NewHeader } from 'components/header/NewHeader';

import {
  GetContractorContainerContractorSubcontractorsDocument,
  GetContractorContainerContractorUsersDocument,
  UserAssignmentType,
  UserRoleKey,
  useGetContractorContainerContractorSubcontractorsQuery,
  useGetRolesQuery,
  GetContractorContainerPaymentMethodsDocument,
  useGetContractorContainerPaymentMethodsQuery,
} from 'apollo/generated/client-operations';
import { Container } from 'components/container';
import { PlusIcon, ToolboxIcon, UserIcon } from 'components/icons';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { AuthContext, AuthUser } from 'auth';
import { to } from 'acl';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { AddUserModal } from 'containers/users/modals';
import { AddStripePaymentMethodModal } from './modals/AddStripePaymentMethodModal';
import { ContractorPaymentMethodsTabContainer } from './tabs/ContractorPaymentMethodsTabContainer/ContractorPaymentMethodsTabContainer';
import { ContractorTabApi, ContractorTabProps } from './types';
import { ContractorJobsitesTabContainer } from './tabs/ContractorJobsitesTabContainer';
import { ContractorInfoTabContainer } from './tabs/ContractorInfoTabContainer';
import { ContractorUsersTabContainer } from './tabs/ContractorUsersTabContainer';
import { ContractorWorkersTabContainer } from './tabs/ContractorWorkersTabContainer';
import { ContractorSubcontractorsTabContainer } from './tabs/ContractorSubcontractorsTabContainer';
import { useSubcontractors } from './helpers/useSubcontractors';
import { AddJobsiteAssignmentModal } from './modals';
import { ContractorTitle } from './components';

const refetchQueries = [
  GetContractorContainerContractorSubcontractorsDocument,
  GetContractorContainerContractorUsersDocument,
];

const refetchPaymentQueries = [
  GetContractorContainerPaymentMethodsDocument,
  GetContractorContainerContractorSubcontractorsDocument,
];

const getTabsConfig = (user: AuthUser, contractorId: string): TabConfig<ContractorTabProps>[] =>
  ensureNonEmptyItems<TabConfig<ContractorTabProps>>([
    { name: `Jobsites`, relativePath: '', component: ContractorJobsitesTabContainer },
    { name: 'Contractor Info', relativePath: '/info', component: ContractorInfoTabContainer },
    { name: `Workers`, relativePath: '/workers', component: ContractorWorkersTabContainer },
    {
      name: `Subcontractors`,
      relativePath: '/subcontractors',
      component: ContractorSubcontractorsTabContainer,
    },
    user.isAllowed(to.seeContractorUsers, contractorId) && {
      name: `Users`,
      relativePath: '/users',
      component: ContractorUsersTabContainer,
    },
    user.isAllowed(to.editContractorPayments, contractorId) && {
      name: `PaymentMethods`,
      text: 'Payment Methods',
      relativePath: '/payment-methods',
      component: ContractorPaymentMethodsTabContainer,
    },
  ]);

export function ContractorContainer(): ReactElement {
  const { contractorId } = useParams<{ contractorId: string }>();
  const baseRoute = `/contractor/${contractorId}`;
  const history = useHistory();

  const {
    value: isAddContractorUserModalOpen,
    setTrue: openAddContractorUserModal,
    setFalse: closeAddContractorUserModal,
  } = useBoolean(false);

  const {
    value: isAddJobsiteAssignmentModalOpen,
    setTrue: openAddJobsiteAssignmentModal,
    setFalse: closeAddJobsiteAssignmentModal,
  } = useBoolean(false);

  const {
    value: isAddStripePaymentMethodModalOpen,
    setTrue: openAddStripePaymentMethodModal,
    setFalse: closeAddStripePaymentMethodModal,
  } = useBoolean(false);

  const {
    jobsites: userJobsites,
    loading: userJobsitesLoading,
    error: userJobsitesError,
  } = useAvailableJobsites({ includeAllJobsites: true });

  const { currentUser: user } = React.useContext(AuthContext);

  const { data, loading, error, refetch } = useGetContractorContainerContractorSubcontractorsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      contractorId,
      includeArchived: true,
    },
  });

  const { data: paymentMethodsData } = useGetContractorContainerPaymentMethodsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !user.isAllowed(to.editContractorPayments, contractorId),
    variables: {
      contractorId,
      includeArchived: true,
    },
  });

  const contractor = data?.getContractor;
  const jobsiteCounts = contractor?.jobsiteContractors.count.toString() ?? '';
  const userCount = contractor?.contractorMembers.count.toString() ?? '';
  const workerCount = contractor?.contractorWorkers.count.toString() ?? '';
  const paymentMethodCount = paymentMethodsData?.getContractor?.stripePaymentMethods?.length?.toString() ?? '';

  const [tabApi, setTabApi] = React.useState<ContractorTabApi>(null);

  const availableJobsitesToAssign = React.useMemo(() => {
    const assignedJobsiteIds = contractor?.jobsiteContractors.edges.map(({ node }) => node.jobsite.jobsiteId);
    return userJobsites?.filter((j) => !assignedJobsiteIds?.includes(j.jobsiteId));
  }, [contractor, userJobsites]);

  const subcontractors = useSubcontractors(contractor);
  const subcontractorCount = subcontractors?.length.toString() ?? '';

  const { data: rolesData } = useGetRolesQuery({ fetchPolicy: 'network-only' });
  const userRoles = rolesData?.getRoles.filter(({ key }) => key !== UserRoleKey.Worker);

  const tabsCount: Record<string, string> = {
    Jobsites: jobsiteCounts,
    Workers: workerCount,
    Subcontractors: subcontractorCount,
    Users: userCount,
    PaymentMethods: paymentMethodCount,
  };

  const tabsConfig = getTabsConfig(user, contractorId);
  const tabsConfigWithBadges = tabsConfig.map((tab) => ({ ...tab, badge: tabsCount[tab.name] }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: tabsConfigWithBadges, baseRoute });

  const menuItems = ensureNonEmptyItems<NewMenuItemProps>([
    user.isAllowed(to.addContractorJobsiteAssignments) && {
      onClick: openAddJobsiteAssignmentModal,
      text: 'Jobsite Assignment',
      icon: ToolboxIcon,
    },
    user.isAllowed(to.addContractorUsers, contractorId) && {
      onClick: openAddContractorUserModal,
      text: 'User',
      icon: UserIcon,
    },
    user.isAllowed(to.editContractorPayments, contractorId) && {
      onClick: openAddStripePaymentMethodModal,
      text: 'Payment Method',
      icon: UserIcon,
    },
  ]);

  const closeAddContractorUserModalAndNavigateToUsers = React.useCallback((): void => {
    closeAddContractorUserModal();
    history.push(`${baseRoute}/users`);
  }, [closeAddContractorUserModal, history, baseRoute]);

  const closeAddJobsiteAssignmentModalAndNavigateToJobsites = React.useCallback((): void => {
    closeAddJobsiteAssignmentModal();
    history.push(`${baseRoute}`);
  }, [closeAddJobsiteAssignmentModal, history, baseRoute]);

  const closeAddStripePaymentMethod = React.useCallback((): void => {
    closeAddStripePaymentMethodModal();
    if (currentTab.name === 'PaymentMethods') {
      tabApi?.refetchData();
    } else {
      history.push(`${baseRoute}/payment-methods`);
    }
  }, [closeAddStripePaymentMethodModal, history, baseRoute, currentTab.name, tabApi]);

  const tabsPageProps: ContractorTabProps = {
    loading: loading || userJobsitesLoading,
    error: error || userJobsitesError,
    refetchContractorData: refetch,
    onTabApiChange: setTabApi,
    userJobsites,
    contractor,
    contractorId,
  };

  return (
    <Container className="contractor-container">
      <NewHeader
        title={<ContractorTitle contractor={contractor} />}
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          headerActions: ['back'],
          children: menuItems.length > 0 && <NewDropdownButton menuItems={menuItems} icon={PlusIcon} text="Add" />,
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      {contractor && (
        <>
          <AddUserModal
            isOpen={isAddContractorUserModalOpen}
            onCancel={closeAddContractorUserModal}
            onConfirm={closeAddContractorUserModalAndNavigateToUsers}
            contractor={contractor}
            refetchQueries={refetchQueries}
            userRoleType={UserAssignmentType.Contractor}
            userRoles={userRoles}
          />
          <AddJobsiteAssignmentModal
            isOpen={isAddJobsiteAssignmentModalOpen}
            onCancel={closeAddJobsiteAssignmentModal}
            onConfirm={closeAddJobsiteAssignmentModalAndNavigateToJobsites}
            contractor={contractor}
            jobsites={availableJobsitesToAssign}
          />
          <AddStripePaymentMethodModal
            isOpen={isAddStripePaymentMethodModalOpen}
            onCancel={closeAddStripePaymentMethodModal}
            refetchQueries={refetchPaymentQueries}
            onConfirm={closeAddStripePaymentMethod}
            contractor={contractor}
          />
        </>
      )}
    </Container>
  );
}
