import React, { ReactElement, useState } from 'react';
import { Col } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  JobsiteWorker,
  MutationUpdateJobsiteWorkerArgs,
  MutationUploadSingleFileArgs,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { AuthContext } from 'auth';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormInput, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import { BaseModal } from 'components/modals';
import {
  generateJobsiteWorkerDocument,
  getDocumentPreviewUrl,
  JobsiteWorkerDocumentArgs,
} from 'containers/workerOnboarding/helpers/forms';
import {
  CREATE_JOBSITE_WORKER_DOCUMENT,
  CREATE_JOBSITE_WORKER_DOCUMENT_VERSION,
  FILE_UPLOAD,
  UPDATE_JOBSITE_WORKER,
} from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { getVisibleDocuments, getVisibleSteps } from 'containers/workerOnboarding/helpers/utils';
import {
  OnboardingStepKey,
  OnboardingStepProps,
  UploadFileResponse,
  WorkerDocumentsDataType,
} from 'containers/workerOnboarding/types';
import {
  ensureNonEmptyItems,
  getFullNameForUser,
  uploadCompressedFile,
  useDeferredFormSubmission,
  useIsMounted,
} from 'utils';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey } from 'containers/worker/utils';
import { StepLoading } from './StepLoading';

const documentKeys = [DocumentKey.HudsonYardsEmployeeOrientation, DocumentKey.MonadnockEyeProtectionAcknowledgement];

export function OnboardingTrainingStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<WorkerDocumentsDataType>();
  const [removeDocumentKey, setRemoveDocumentKey] = useState<string>(null);

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments();
    },
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT, {
    onError: (error) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT_VERSION);

  const onSubmit: FormOnSubmit<WorkerDocumentsDataType> = async (data): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      await Promise.all(
        documentKeys
          .filter((documentKey) => filteredDocumentTypeIds[documentKey])
          .map(async (documentKey) => {
            const fileIds = [];
            if (data[`${documentKey}-front`]) {
              const frontFileRow = data[`${documentKey}-front`];
              const serverFile = frontFileRow as ServerFile[];
              if (serverFile[0]?.fileId) {
                fileIds.push(serverFile[0]?.fileId);
              } else {
                const defaultFile = frontFileRow as File[];
                if (defaultFile[0]) {
                  const fileId = await uploadCompressedFile(defaultFile[0] as File, uploadFile);
                  if (fileId) {
                    fileIds.push(fileId);
                  }
                }
              }
            }

            const jobsiteWorkerDocumentId = documents.find(({ key }) => key === documentKey)?.id;

            if (jobsiteWorkerDocumentId) {
              return createJobsiteWorkerDocumentVersion({
                variables: {
                  jobsiteWorkerDocumentId,
                  jobsiteWorkerDocumentVersionInput: {
                    fileIds,
                    additionalFieldValues: [],
                  },
                },
              });
            }

            return createJobsiteWorkerDocument({
              variables: {
                jobsiteWorkerDocumentInput: {
                  jobsiteWorkerId,
                  jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[documentKey],
                  key: documentKey,
                },
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: [],
                },
              },
            });
          }),
      );

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            onboardingSignedAt: getCurrentISOFormattedDateTime(),
            onboardingSkipReason: null,
          },
        },
      });

      deferredSubmission.resolve(true);
    } catch (error) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    }
  };

  const overrideComplete = (reason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          onboardingSignedAt: getCurrentISOFormattedDateTime(),
          onboardingSkipReason: reason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          onboardingSignedAt: null,
          onboardingSkipReason: null,
        },
      },
    });
  };

  const fullName = getFullNameForUser(jobsiteWorker?.contractorWorker?.worker?.user);

  const visibleDocuments = getVisibleDocuments(jobsiteWorker);

  const generateJobsiteWorkerDocumentIfAssigned = (
    args: JobsiteWorkerDocumentArgs,
  ): FormInput<WorkerDocumentsDataType> | undefined => {
    return (
      filteredDocumentTypeIds[args.documentKey] &&
      visibleDocuments?.includes(args.documentKey) &&
      generateJobsiteWorkerDocument(args)
    );
  };

  const withFilesDocumentIds = Object.fromEntries(
    documents
      .filter(({ key: doc }) =>
        defaultFormValues.some(
          ({ name: fieldName, value }) => value && [`${doc}-front`, `${doc}-back`].includes(fieldName),
        ),
      )
      .map(({ key, id }) => [key, id]),
  );

  const getPreviewUrl = (documentKey: string): string => {
    return getDocumentPreviewUrl(withFilesDocumentIds[documentKey]);
  };

  const inputs = ensureNonEmptyItems([
    generateJobsiteWorkerDocumentIfAssigned({
      documentKey: DocumentKey.HudsonYardsEmployeeOrientation,
      label: 'Hudson Yards Employee Orientation',
      getPreviewUrl,
    }),
    generateJobsiteWorkerDocumentIfAssigned({
      documentKey: DocumentKey.MonadnockEyeProtectionAcknowledgement,
      label: 'Monadnock Eye Protection Acknowledgement',
      getPreviewUrl,
    }),
  ]);

  const visibleSteps = getVisibleSteps(jobsiteWorker?.jobsiteContractor?.jobsite);
  const loading = parentLoading || fetching;

  return !visibleSteps?.includes(OnboardingStepKey.OnboardingTraining) ? (
    <>
      <Col xs="12">
        <h2 className="mt-4">You don&lsquo;t have permission to view this page</h2>
      </Col>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        hideLockSession
        hideSave
        hideSkipOptions={isContractorMember}
        onResetComplete={(): void => resetComplete()}
        onForceComplete={(reason: string): void => overrideComplete(reason)}
      />
    </>
  ) : (
    <>
      <Col xs="12">
        <fieldset disabled={isContractorMember}>
          <Header title="Onboarding training" />
          <StepLoading loading={loading} hasTransparentBackground={!inputs.length} />
          <Form
            inputs={inputs}
            ref={formRef}
            defaultValues={defaultFormValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
          />
          <BaseModal
            title="Delete document"
            onAction={(): void => {
              createJobsiteWorkerDocument({
                variables: {
                  jobsiteWorkerDocumentInput: {
                    jobsiteWorkerId,
                    jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[removeDocumentKey],
                    fileIds: [],
                    key: removeDocumentKey,
                    additionalFieldValues: [],
                  },
                },
              }).then(() => {
                refetchJobsiteWorkerDocuments();
              });
              setRemoveDocumentKey('');
            }}
            onCancel={(): void => {
              setRemoveDocumentKey('');
            }}
            isOpen={!!removeDocumentKey}
            actionText="Ok"
          >
            <div className="text-center">
              <p>
                You are about to delete <b>{fullName}&lsquo;s</b> file.
                <br />
                This action cannot be undone.
              </p>
            </div>
          </BaseModal>
        </fieldset>
      </Col>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        hideLockSession
      />
    </>
  );
}
