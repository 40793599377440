import React from 'react';
import { FileType } from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { Thumbnail } from 'components/thumbnail';
import { PdfPreview } from 'components/thumbnail/PdfPreview';
import { HtmlRenderer } from 'components/htmlRenderer';

export function DocumentFilePreviewContent({ file }: { file: ServerFile }): React.ReactElement {
  switch (file.fileType) {
    case FileType.Pdf:
      return <PdfPreview fileURL={file.downloadUrl} alt={file.originalFileName} />;
    case FileType.Html:
      return <HtmlRenderer url={file.downloadUrl} />;
    default:
      return (
        <a
          href={file?.downloadUrl}
          onClick={(e): void => {
            e.stopPropagation();
          }}
          target="_blank"
          download
          className="mr-3 odin-h-full odin-w-full"
          rel="noreferrer"
          key={file.downloadUrl}
        >
          <Thumbnail file={file} className="odin-h-full odin-w-full" suppressAvatarClasses />
        </a>
      );
  }
}
