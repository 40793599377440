import React from 'react';

export interface BooleanReturn {
  value: boolean;
  setValue: (value: boolean) => void;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

/**
 * The `useBoolean` Hook is used to deal with toggling boolean values (true/false),
 * and provides functions to set the value to true/false, and a toggle function to toggle the value.
 * @param initialValue The value which will be returned during the initial render.
 * @returns an object containing the following fields:
 * - value: The current value of the boolean
 * - toggle: A function to toggle the current value (sets true if currently false, and false if currently true)
 * - setTrue: Sets the current value to true
 * - setFalse: Sets the current value to false
 */
export const useBoolean = (initialValue?: boolean): BooleanReturn => {
  const [value, setValue] = React.useState(initialValue ?? false);

  const setTrue = React.useCallback((): void => setValue(true), [setValue]);
  const setFalse = React.useCallback((): void => setValue(false), [setValue]);
  const toggle = React.useCallback((): void => setValue((x) => !x), [setValue]);

  return { value, setValue, setTrue, setFalse, toggle };
};
