import React from 'react';
import { Copy } from 'containers/facialRecognition/helpers/languages';
import { FacialRecognitionPhotoDirectionsProps } from 'containers/facialRecognition/types';

export function ConfirmationStep(props: Pick<FacialRecognitionPhotoDirectionsProps, 'localize'>): React.ReactElement {
  const { localize } = props ?? {};
  return (
    <div className="odin-p-7.5 odin-flex odin-flex-col odin-gap-y-6">
      <div className="odin-p-6 odin-rounded-xl odin-bg-odin-gradient-2 odin-text-white odin-text-center">
        <h2 className="">{localize(Copy.facial_recognition_confirmation_header)}</h2>
        <div className="odin-text">{localize(Copy.facial_recognition_confirmation_body)}</div>
      </div>
    </div>
  );
}
