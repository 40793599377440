import React from 'react';
import { SortingRule, Table, TableContainer } from '@odin-labs/components';
import { useQueryOrderBy } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { SortOrder } from 'utils/useQueryOrderBy';
import { LoadingError } from 'components/loadingError';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { useHistory } from 'react-router';
import { WorkerSession } from 'containers/dashboard/types';

import { NewHeader } from 'components/header/NewHeader';
import { DashboardTabProps } from 'containers/dashboard/tabs/types';
import { getColumns, orderByFields } from './tables';

export function DashboardWorkersTab(props: DashboardTabProps): React.ReactElement {
  const { data, loading, error } = props;

  const workerSessions = data?.results;
  const workerSessionsCount = data?.totalCount ?? 0;

  const pageCount = Math.ceil(workerSessionsCount / limit);

  const history = useHistory();
  const { page, orderBy: defaultSortField, orderByDesc: isDescending, updateUrl } = usePageQueryParams();

  const { tableSortInfo, setNewOrderBy } = useQueryOrderBy<string, string>(
    defaultSortField,
    isDescending,
    (tableField: string): string => orderByFields[tableField] ?? tableField,
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const columns = React.useMemo(() => getColumns(), []);

  if (error) {
    return <LoadingError error={error} />;
  }

  const onSortByChangeHandler = (sortBy: Array<SortingRule<WorkerSession>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onRowClickHandler = ({ data: row }: { data: WorkerSession }): void => {
    history.push(`/worker/${row?.workerWorkerId}`);
  };

  return (
    <TableContainer className="worker-reports-workers-tab">
      <NewHeader size="md" title="Workers" titleInfo={workerSessionsCount} actionsProps={{ headerActions: null }} />
      <Table
        loading={loading}
        columns={columns}
        data={workerSessions}
        initialState={{ sortBy: tableSortBy, pageSize: limit }}
        pageCount={pageCount}
        pageIndex={page}
        remote
        enablePagination
        onRowClick={onRowClickHandler}
        onPageChange={onPageChangeHandler}
        onSortByChange={onSortByChangeHandler}
        noResultsText="No workers to show"
      />
    </TableContainer>
  );
}
