import { GetRecentUserSearchQueriesQuery } from 'apollo/generated/client-operations';
import type { SearchWorkerResult } from './searchWorkerInfo/types';

export type { SearchWorkerResult, JobsiteInfo } from './searchWorkerInfo/types';

export type OnSelectAutocompleteHook = (selection: SearchWorkerResult) => void;

export enum AutocompleteSearchType {
  none,
  results,
  suggestions,
}

export type UserSearchQuery = GetRecentUserSearchQueriesQuery['getRecentUserSearchQueries']['results'][number];
