import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Card } from 'reactstrap';
import { Logo } from 'components/logo';
import QRCode from 'react-qr-code';

import placeholderImage from 'images/profile.png';

import { BadgePreviewProps } from './types';
import * as styles from './scss/badge-preview.scss';

const cx = classNames.bind(styles);

export function BadgePreview({ title, trade, name, imageUrl, qrUrl }: BadgePreviewProps): ReactElement {
  return (
    <Card className={cx('badge-preview')}>
      <div className={cx('badge-preview__body', 'd-flex', 'justify-content-between')}>
        <div className={cx('badge-preview__body-content', 'p-4')}>
          <Logo style={{ marginBottom: '24px' }} />
          <h3>{name}</h3>
          <h4 style={{ fontWeight: 400 }}>{title}</h4>
          <h4 style={{ fontWeight: 400 }}>{trade}</h4>
        </div>
        <div className={cx('badge-preview__body-images', 'text-right')}>
          <img className={cx('badge-preview__profile-image')} alt="badge" src={imageUrl || placeholderImage} />
          <QRCode className={cx('badge-preview__profile-qr')} value={qrUrl} size={120} />
        </div>
      </div>
    </Card>
  );
}
