import React, { ReactElement } from 'react';
import moment from 'moment';
import { Filter, NewButton, Table, TableContainer, TimePicker } from '@odin-labs/components';
import { to } from 'acl';
import {
  JobsiteAccessEventStatus,
  JobsiteAccessEventRejectionReason,
  useGetWorkerJobsiteAccessActivityQuery,
  JobsiteWorkerCheckInLocationStatus,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { ArrowToBottomIcon, PlusIcon } from 'components/icons';
import { LoadingError } from 'components/loadingError';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { AddJobsiteAccessEventModal, ArchiveJobsiteAccessEventModal } from 'containers/worker/modals';
import { WorkerTabProps } from 'containers/worker/types';
import { getLookerReportUrl } from 'looker';
import { LookerFilter, LookerReport, LookerTimeRangeType } from 'looker/types';
import { getCurrentISOFormattedDate, momentISODateFormatter, useBoolean, useResettableState } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { getJobsiteDateTime } from 'utils/dates';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { NewHeader } from 'components/header/NewHeader';
import { useJobsitesSelection } from 'graphql/client/useJobsitesSelection';
import { JobsiteAccessEvent, WorkerJobsiteAccessEventsFilters } from './types';
import { JobsiteAccessEventColumn, getColumns, getFilterItems } from './tables';

const ODIN_MOBILE_CHECK_IN = 'odin_mobile_checkin';

export function WorkerAccessActivity(props: WorkerTabProps): ReactElement {
  const { worker, jobsiteWorkers, isLocked, refetchWorkerData, onTabApiChange } = props;

  const defaultStartDate = moment().subtract(30, 'days');
  const defaultEndDate = moment(getCurrentISOFormattedDate());
  const defaultStartTime = TimePicker.DAY_START_TIME;
  const defaultEndTime = TimePicker.DAY_END_TIME;

  const { currentUser: user } = React.useContext(AuthContext);
  const canManageJobsiteAccessEvents = user.isAllowed(to.manageJobsiteAccessEvents);

  const isFeatureLocked = !user.hasPaidAccess;

  const {
    page,
    // orderBy: defaultSortField,
    // orderByDesc: isDescending,
    jobsiteIds,
    contractorIds,
    gatewayIds,
    startDate,
    startTime,
    endDate,
    endTime,
    eventStatus,
    rejectionReasons,
    locationStatuses,
    updateUrl,
  } = usePageQueryParams({
    defaultValues: {
      startDate: defaultStartDate,
      startTime: defaultStartTime,
      endDate: defaultEndDate,
      endTime: defaultEndTime,
    },
  });

  const offset = page * limit;

  const {
    value: isAddJobsiteAccessEventModalOpen,
    setTrue: openAddJobsiteAccessEventModalModal,
    setFalse: closeAddJobsiteAccessEventModalModal,
  } = useBoolean(false);
  const {
    value: selectedJobsiteAccessEvent,
    setValue: openArchiveJobsiteAccessEventsModal,
    resetValue: closeArchiveJobsiteAccessEventsModal,
  } = useResettableState<JobsiteAccessEvent>(null);

  const hasMobileEnabledJobsite: boolean = jobsiteWorkers
    ?.map((jobsiteWorker) => jobsiteWorker.jobsiteContractor?.jobsite.modules)
    .flat()
    // eslint-disable-next-line no-underscore-dangle
    .some((module) => module.__typename === 'JobsiteAppCheckInModule');

  const startDateTime = getJobsiteDateTime({
    date: startDate,
    time: startTime || defaultStartTime,
  });
  const endDateTime = getJobsiteDateTime({
    date: endDate,
    time: endTime || defaultEndTime,
    isEnd: !!endTime, // defaultEndTime already contains end interval seconds and milliseconds
  });

  const {
    data,
    loading,
    error,
    refetch: refetchWorkerJobsiteAccessActivity,
  } = useGetWorkerJobsiteAccessActivityQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !worker || worker?.isArchived || isLocked || isFeatureLocked,
    variables: {
      workerId: worker.workerId,
      includeArchived: worker.isArchived,
      workerJobsiteAccessEventsInput: {
        paginationInput: { limit, offset },
        jobsiteIds,
        contractorIds,
        gatewayIds,
        startDateTime,
        endDateTime,
        status: eventStatus as JobsiteAccessEventStatus,
        rejectionReasons: rejectionReasons as JobsiteAccessEventRejectionReason[],
        locationStatuses: locationStatuses as JobsiteWorkerCheckInLocationStatus[],
      },
    },
  });

  const workerData = data?.getWorker;
  const workerJobsiteAccessEvents = workerData?.jobsiteAccessEvents.edges.map(({ node }) => node);
  const filtersOptions = workerData?.jobsiteAccessEvents.filtersOptions;

  const workerJobsiteAccessEventsCount = workerData?.jobsiteAccessEvents.count ?? 0;
  const pageCount = Math.ceil(workerJobsiteAccessEventsCount / limit);

  const columns = React.useMemo<JobsiteAccessEventColumn[]>(
    () => getColumns({ user, openArchiveJobsiteAccessEventsModal, jobsiteWorkers }),
    [user, openArchiveJobsiteAccessEventsModal],
  );

  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        filtersOptions,
        jobsiteIds,
        contractorIds,
        gatewayIds,
        eventStatus,
        rejectionReasons,
        locationStatuses,
        startDate,
        startTime,
        endDate,
        endTime,
      }),
    [
      filtersOptions,
      jobsiteIds,
      contractorIds,
      gatewayIds,
      eventStatus,
      rejectionReasons,
      locationStatuses,
      startDate,
      startTime,
      endDate,
      endTime,
    ],
  );

  const { selectedJobsites } = useJobsitesSelection();
  const selectedJobsiteNames = selectedJobsites?.map(({ name }) => name).join();

  const lookerReportUrl = getLookerReportUrl(LookerReport.JobsiteAccessActivity, {
    [LookerFilter.Jobsite]: selectedJobsiteNames,
    [LookerFilter.TimeRangeType]: LookerTimeRangeType.DateRange,
    [LookerFilter.StartDate]: startDate?.format(momentISODateFormatter),
    [LookerFilter.EndDate]: endDate?.format(momentISODateFormatter),
    [LookerFilter.SwipeStatus]: eventStatus,
    [LookerFilter.WorkerQuickCode]: worker.quickCode,
  });

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  const onFilterChangeHandler = (changedFilters: Partial<WorkerJobsiteAccessEventsFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const closeRemoveJobsiteAccessEventsModalAndRefetch = (): void => {
    closeArchiveJobsiteAccessEventsModal();
    refetchWorkerJobsiteAccessActivity();
  };

  const closeAddJobsiteAccessEventModalModalAndRefetch = (): void => {
    closeAddJobsiteAccessEventModalModal();
    refetchWorkerJobsiteAccessActivity();
  };

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  if (isFeatureLocked) {
    return <LockedFeatureAlert />;
  }

  return (
    <>
      <TableContainer>
        <NewHeader
          size="md"
          title="Access activity"
          titleInfo={workerJobsiteAccessEventsCount}
          actionsProps={{ headerActions: null }}
        />
        <Filter items={filterItems} loading={loading} onChange={onFilterChangeHandler}>
          {!user.isContractor && (
            <div className="odin-flex odin-gap-2 md:odin-flex-wrap md:odin-justify-end md:odin-gap-x-2 md:odin-gap-y-1">
              {canManageJobsiteAccessEvents && hasMobileEnabledJobsite && (
                <NewButton
                  size={['base', 'md:xs']}
                  text="Add Event"
                  hideTextOnMobile
                  icon={PlusIcon}
                  onClick={openAddJobsiteAccessEventModalModal}
                />
              )}
              <NewButton
                as="a"
                href={lookerReportUrl}
                target="_blank"
                theme="white"
                size={['base', 'md:xs']}
                text="Download"
                hideTextOnMobile
                icon={ArrowToBottomIcon}
              />
            </div>
          )}
        </Filter>
        <Table
          loading={loading}
          columns={columns}
          data={workerJobsiteAccessEvents}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
          cellClassName="!odin-pl-5"
        />
      </TableContainer>
      <AddJobsiteAccessEventModal
        isOpen={isAddJobsiteAccessEventModalOpen}
        jobsiteWorkers={jobsiteWorkers}
        closeModal={closeAddJobsiteAccessEventModalModal}
        onConfirm={closeAddJobsiteAccessEventModalModalAndRefetch}
      />
      <ArchiveJobsiteAccessEventModal
        isOpen={!!selectedJobsiteAccessEvent}
        jobsiteWorkerCheckInId={
          selectedJobsiteAccessEvent?.eventType === ODIN_MOBILE_CHECK_IN ? selectedJobsiteAccessEvent?.externalId : null
        }
        accessEventId={selectedJobsiteAccessEvent?.id}
        sessionDate={selectedJobsiteAccessEvent?.sessionDate}
        closeModal={closeArchiveJobsiteAccessEventsModal}
        onConfirm={closeRemoveJobsiteAccessEventsModalAndRefetch}
      />
    </>
  );
}
