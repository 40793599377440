import { AvatarCell, AvatarCellComponentProps, AvatarSkeleton, Column, FilterItems } from '@odin-labs/components';
import { Organization, OrganizationsFilters } from 'containers/organizations/types';
import { OrganizationIcon } from 'containers/organization/types';
import React from 'react';
import { OrganizationIconsCell } from './cells';

export type OrganizationColumn = Column<Organization>;

export const getColumns = (): OrganizationColumn[] => [
  {
    id: 'name',
    Header: 'Name',
    accessor: (organization: Organization): string => {
      return organization.name;
    },
    Cell: AvatarCell,
    CellLoading: <AvatarSkeleton size="xs" type="rounded" hideDetails />,
    componentProps: (organization: Organization): AvatarCellComponentProps => ({
      icon: OrganizationIcon,
      type: 'rounded',
      placeholder: (organization.slug.substring(0, 2) || '??').toUpperCase(),
      objectFit: 'cover',
      size: 'xs',
    }),
  },
  {
    id: 'organizationIcons',
    Header: '',
    accessor: (organization: Organization): Organization => {
      return organization;
    },
    Cell: OrganizationIconsCell,
  },
  {
    id: 'legalName',
    Header: 'Legal Name',
    accessor: (organization: Organization): string => {
      return organization?.legalName;
    },
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: (organization: Organization): string => {
      return organization?.email;
    },
  },
  {
    id: 'location',
    Header: 'Location',
    accessor: (organization: Organization): string => {
      const { addressCity, addressState, addressLine1, addressLine2 } = organization ?? {};
      return [addressLine1, addressLine2, addressCity, addressState].filter(Boolean).join(', ');
    },
  },
];

export const getFilterItems = ({ search }: { search: string }): FilterItems<OrganizationsFilters> => ({
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
