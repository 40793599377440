import { UpdateWorkerMutationVariables } from 'apollo/generated/client-operations';
import { FormInputTypes, TypedFormInputs, getUpdateInputValueFunction, GridColSpan } from 'components/form';
import { DeepMap } from 'react-hook-form';
import { ensureNonUndefinedFields, getPhoneNumberAsE164 } from 'utils';
import {
  toFancySelectOptions,
  emergencyContactRelationshipOptions as emergencyContactRelationshipOptionsValues,
} from 'utils/constants';
import { phoneNumberValidation } from 'utils/validation';
import { SelfOnboardingEmergencyContactFormData, SelfOnboardingState } from 'containers/selfOnboarding/steps/types';

export const emergencyContactRelationshipOptions = toFancySelectOptions(emergencyContactRelationshipOptionsValues);

export const getFormInputs = (): TypedFormInputs<SelfOnboardingEmergencyContactFormData> => {
  return {
    emergencyContactName: {
      element: FormInputTypes.OdinField,
      label: 'Emergency Contact Name',
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    emergencyContactRelationship: {
      element: FormInputTypes.OdinSelect,
      label: 'Emergency Contact Relationship',
      elementProps: {
        options: emergencyContactRelationshipOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    emergencyContactPhone: {
      element: FormInputTypes.OdinField,
      label: 'Emergency Contact Phone',
      elementProps: {
        fieldType: 'phone',
        inputMode: 'numeric',
        showDefaultIcon: true,
      },
      validation: { pattern: phoneNumberValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};

export const getDefaultValues = (
  emergencyContact: SelfOnboardingEmergencyContactFormData,
): SelfOnboardingEmergencyContactFormData => {
  const { emergencyContactName, emergencyContactRelationship, emergencyContactPhone } = emergencyContact ?? {};

  return {
    emergencyContactName: emergencyContactName ?? '',
    emergencyContactRelationship: emergencyContactRelationship ?? null,
    emergencyContactPhone: emergencyContactPhone ?? '',
  };
};

export type SelfOnboardingEmergencyContactUpdateInput = Pick<UpdateWorkerMutationVariables, 'workerId'> & {
  workerInput: Pick<
    UpdateWorkerMutationVariables['workerInput'],
    'emergencyContactName' | 'emergencyContactRelationship' | 'emergencyContactPhone'
  >;
};

export const getUpdateInput = (
  worker: SelfOnboardingState['worker'],
  data: SelfOnboardingEmergencyContactFormData,
  dirtyFields: DeepMap<SelfOnboardingEmergencyContactFormData, true>,
): SelfOnboardingEmergencyContactUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const emergencyContactPhoneInputValue = dirtyFields.emergencyContactPhone
    ? getPhoneNumberAsE164(data.emergencyContactPhone) || null
    : undefined;

  return ensureNonUndefinedFields<SelfOnboardingEmergencyContactUpdateInput>({
    workerId: worker.workerId,
    workerInput: {
      emergencyContactName: getUpdateInputValue('emergencyContactName'),
      emergencyContactRelationship: getUpdateInputValue('emergencyContactRelationship'),
      emergencyContactPhone: emergencyContactPhoneInputValue,
    },
  });
};
