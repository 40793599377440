import cn from 'classnames';

export const classes = {
  fullContainer: (withBackground: boolean): string =>
    cn(
      'user-pending-change-container odin-absolute odin-min-h-full odin-w-full',
      withBackground && 'odin-bg-odin-gradient-2',
    ),
  container: cn('odin-mx-auto odin-max-w-7xl'),
  stepContainer: cn('sm:odin-px-15 sm:odin-py-10'),
};
