import moment from 'moment';
import { CardStatus, WorkerCard, WorkerCardType } from 'apollo/generated/client-operations';
import { BadgeChecklist } from 'components/badgeChecklist/BadgeChecklist';
import { FormInputTypes, GridColSpan, TypedFormInputs, UseInputs, UseFormMethods } from 'components/form';
import { getIcon } from 'utils/ui';
import React from 'react';
import { SelectOptionElement } from '@odin-labs/components';
import { AssignBadgeFormData } from './types';

const IdCardIcon = getIcon('fal fa-id-card');

const getFormInputs = (
  isPermanentBadge: boolean,
  proximityCardFormatOptions: SelectOptionElement[],
): TypedFormInputs<AssignBadgeFormData> => {
  return {
    badgeNumber: {
      element: FormInputTypes.OdinField,
      label: 'Badge Number',
      elementProps: {
        fieldType: 'number',
        icon: IdCardIcon,
      },
      validation: {
        required: true,
      },
      layout: GridColSpan.SpanFull,
    },
    isPermanentBadge: {
      element: FormInputTypes.OdinToggle,
      elementProps: {
        label: "Badge is permanent and doesn't have an expiration date",
      },
      layout: GridColSpan.SpanFull,
    },
    expirationDate: {
      element: FormInputTypes.NewDatePicker,
      elementProps: {
        label: 'Expiration Date',
        minDate: moment(),
        showDefaultIcon: true,
        numberOfMonths: 1,
        disabled: isPermanentBadge,
        placeholder: isPermanentBadge ? 'N/A' : '',
      },
      validation: {
        required: !isPermanentBadge,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    tempBadgeReason: {
      element: FormInputTypes.OdinField,
      label: 'Reason For Temp Badge',
      elementProps: {
        disabled: isPermanentBadge,
        placeholder: isPermanentBadge ? 'N/A' : '',
      },
      validation: {
        required: !isPermanentBadge,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    proximityCardFormatId: {
      element: FormInputTypes.OdinSelect,
      label: 'Card Format',
      elementProps: {
        disabled: (proximityCardFormatOptions?.length ?? 0) < 2,
        options: proximityCardFormatOptions ?? [],
        isClearable: false,
      },
      validation: { required: true },
      layout: GridColSpan.SpanFull,
    },
    badges: {
      element: FormInputTypes.CustomInput,
      elementProps: {
        customInput: BadgeChecklist,
      },
      layout: GridColSpan.SpanFull,
    },
  };
};

export const getFormInputsHook =
  (proximityCardFormatOptions: SelectOptionElement[]): UseInputs<AssignBadgeFormData> =>
  ({ watch, setValue }: UseFormMethods<AssignBadgeFormData>): TypedFormInputs<AssignBadgeFormData> => {
    const isPermanentBadge = watch('isPermanentBadge');
    React.useEffect(() => {
      if (isPermanentBadge) {
        setValue('expirationDate', null, { shouldValidate: true });
        setValue('tempBadgeReason', '', { shouldValidate: true });
      } else {
        setValue('expirationDate', moment().add({ day: 1 }));
      }
    }, [isPermanentBadge]);

    return React.useMemo(
      () => getFormInputs(isPermanentBadge, proximityCardFormatOptions),
      [isPermanentBadge, proximityCardFormatOptions],
    );
  };

export const getFormDefaultValues = (
  workerCards: WorkerCard[],
  defaultProximityCardFormatOption: SelectOptionElement,
): AssignBadgeFormData => {
  return {
    badges: workerCards
      ?.filter(
        ({ cardStatus, workerCardFormat }) =>
          cardStatus === CardStatus.Active && workerCardFormat.cardType === WorkerCardType.Proximity,
      )
      ?.map((workerCard) => ({ workerCard, selected: true })),
    badgeNumber: '',
    proximityCardFormatId: defaultProximityCardFormatOption ?? null,
    isPermanentBadge: false,
    expirationDate: moment().add({ day: 1 }),
    tempBadgeReason: '',
  };
};
