import { Column, BadgeCell, BadgeCellComponentProps } from '@odin-labs/components';
import { Breakdown } from './types';

export const columns: Column<Breakdown>[] = [
  {
    id: 'name',
    Header: 'Jobsite',
    accessor: 'name',
  },
  {
    id: 'count',
    Header: 'Count',
    Cell: BadgeCell,
    componentProps: ({ count }: Breakdown): BadgeCellComponentProps => ({
      text: count?.toString(),
    }),
  },
  {
    id: 'percentage',
    Header: 'Percentage',
    accessor: (breakdown): string => `${Math.round(breakdown.percentage)}%`,
  },
];
