import React from 'react';
import { useGetJobsiteFormSubmissionHistoricalDataQuery } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { FormSubmissionPrintViewProps } from 'containers/jobsiteFormSubmission/print/types';
import { getDateTimeMoment } from 'utils/dates';
import { JobsiteReport, WorkerReport, JobsiteIncidentReportDocuments } from './components';

export function JobsiteSafetyIncidentPrintView(props: FormSubmissionPrintViewProps): React.ReactElement {
  const { jobsiteFormSubmission, loading: parentLoading } = props;

  const {
    data,
    error,
    loading: historicalDataLoading,
  } = useGetJobsiteFormSubmissionHistoricalDataQuery({
    variables: { id: jobsiteFormSubmission?.id },
    fetchPolicy: 'no-cache',
    skip: !jobsiteFormSubmission,
  });

  const jfsWithHistoricalData = data?.getJobsiteFormSubmission;

  const loading = parentLoading || historicalDataLoading;

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  const reportDateTime = getDateTimeMoment({
    date: jobsiteFormSubmission.startAt,
    timeZone: jobsiteFormSubmission.timeZone,
    isUTC: true,
  });
  const reportDateTimeText = reportDateTime.format('MM/DD/YYYY - hh:mm a z');

  return (
    <>
      <JobsiteReport jobsiteFormSubmission={jobsiteFormSubmission} reportDateTimeText={reportDateTimeText} />
      {jfsWithHistoricalData?.jobsiteWorkers.edges.map(({ node: jfsWorker }) => (
        <WorkerReport
          key={jfsWorker.id}
          jobsiteFormSubmission={jobsiteFormSubmission}
          jfsWorker={jfsWorker}
          reportDateTime={reportDateTime}
          reportDateTimeText={reportDateTimeText}
        />
      ))}
      <JobsiteIncidentReportDocuments jobsiteFormSubmission={jobsiteFormSubmission} singleItemPerRow />
    </>
  );
}
