import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  JobsiteWorker,
  MutationUpdateJobsiteWorkerArgs,
  MutationUploadSingleFileArgs,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { AlertInstance } from 'components/alertNotification';
import {
  CREATE_JOBSITE_WORKER_DOCUMENT,
  CREATE_JOBSITE_WORKER_DOCUMENT_VERSION,
  FILE_UPLOAD,
  UPDATE_JOBSITE_WORKER,
} from 'containers/workerOnboarding/helpers/queries';
import {
  MedicalDocumentsUpload,
  MedicalConditionsDataType,
  UploadDocumentsArgs,
  UploadFileResponse,
  UseMedicalDocumentsUploadArgs,
} from 'containers/workerOnboarding/types';
import { getCurrentISOFormattedDate, getFullNameForUser, uploadCompressedFile, useIsMounted } from 'utils';
import {
  getCurrentISOFormattedDateTime,
  getISOFormattedDateFromCleave,
  getISOFormattedDateTimeFromCleave,
} from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey, WorkerAccessRevokingCategory } from 'containers/worker/utils';

export function useMedicalDocumentsUpload(args: UseMedicalDocumentsUploadArgs): MedicalDocumentsUpload {
  const {
    user,
    jobsiteWorker,
    documentTypes,
    filteredDocumentTypeIds,
    documents,
    deferredSubmission,
    onUploadCompleted,
    refetchJobsiteWorkerDocuments,
  } = args;

  const { jobsiteWorkerId } = jobsiteWorker ?? {};
  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments({ jobsiteWorkerId, includePhi: true });
      onUploadCompleted?.();
    },
    onError: (error) => {
      if (isMounted()) setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker', 'GetWorkerDocuments'],
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      if (isMounted()) setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT, {
    onError: (error) => {
      if (isMounted()) setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useMutation(CREATE_JOBSITE_WORKER_DOCUMENT_VERSION);

  const isServerFile = (file: File | ServerFile): file is ServerFile => {
    return Boolean(file && (file as any).fileId);
  };

  const uploadDocuments = async ({
    data,
    dirtyFields,
  }: UploadDocumentsArgs<MedicalConditionsDataType>): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      const dirtyFieldValues = Object.keys(dirtyFields);

      const failedResults = ['No pass'];

      const alcoholTestResult = data[`${DocumentKey.MedicalBreathAlcoholTest}-result`] as string;
      const drugUrineTestResult = data[`${DocumentKey.MedicalUrineDrugTest}-result`] as string;

      await Promise.all(
        documentTypes.map(async (documentType: string) => {
          const isDirty = dirtyFieldValues.some((val) => val.startsWith(documentType));
          const fileIds = [];
          const additionalFields = [];
          if (!isDirty) {
            return;
          }

          if (data[`${documentType}-front`]) {
            const [file] = (data[`${documentType}-front`] as Array<File | ServerFile>) ?? [];
            if (isServerFile(file)) {
              fileIds.push(file.fileId);
            } else if (file) {
              const fileId = await uploadCompressedFile(file, uploadFile);
              if (fileId) {
                fileIds.push(fileId);
              }
            }
          }

          additionalFields.push({
            key: 'test-taken-date',
            value: getCurrentISOFormattedDate(),
          });

          const resultRaw = data[`${documentType}-result`];
          if (resultRaw) {
            additionalFields.push({
              key: 'result',
              value: resultRaw,
            });
          }

          if (data[`${documentType}-test-reason`]) {
            additionalFields.push({
              key: 'test-reason',
              value: data[`${documentType}-test-reason`],
            });
          }

          if (data[`${documentType}-notes`]) {
            additionalFields.push({
              key: 'notes',
              value: data[`${documentType}-notes`],
            });
          }

          const expirationDateRaw = data[`${documentType}-conditional-access-expiration-date`] as string;
          if (expirationDateRaw && data[`${documentType}-result`] === 'Conditional pass') {
            additionalFields.push({
              key: 'conditional-access-expiration-date',
              value: getISOFormattedDateFromCleave(expirationDateRaw),
            });
          }

          const dateSignedRaw = data[`${documentType}-date-signed`] as string;
          if (dateSignedRaw) {
            additionalFields.push({
              key: 'date-signed',
              value: getISOFormattedDateFromCleave(dateSignedRaw),
            });
          }

          additionalFields.push({
            key: 'test-date',
            value: getCurrentISOFormattedDate(),
          });

          const existingDocument = documents?.find(({ key }) => key === documentType);

          if (existingDocument) {
            createJobsiteWorkerDocumentVersion({
              variables: {
                jobsiteWorkerDocumentId: existingDocument.id,
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: additionalFields,
                },
              },
            });
          } else {
            createJobsiteWorkerDocument({
              variables: {
                jobsiteWorkerDocumentInput: {
                  jobsiteWorkerId,
                  jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[documentType],
                  key: documentType,
                },
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: additionalFields,
                },
              },
            });
          }
        }),
      );

      if (failedResults.includes(alcoholTestResult) || failedResults.includes(drugUrineTestResult)) {
        await updateJobsiteWorker({
          variables: {
            jobsiteWorkerId,
            jobsiteWorkerInput: {
              isBanned: true,
              banCategory: WorkerAccessRevokingCategory.MedicalDrugTest,
              bannedReason: 'Unable to complete onboarding',
              bannedAt: getCurrentISOFormattedDateTime(),
              passedMedicalExamAt: null,
              medicalExamSkipReason: null,
              medicalNotes: data?.medicalNotes,
              siteAccessChangeApprovedBy: getFullNameForUser(user),
            },
          },
        });
        AlertInstance.alert('tc', 'danger', 'User ban!', 'This user has been banned from the jobsite.');
        return;
      }

      if (drugUrineTestResult === 'Conditional pass') {
        const medicalExamSkipExpiresAtDate = data[
          `${DocumentKey.MedicalUrineDrugTest}-conditional-access-expiration-date`
        ] as string;
        await updateJobsiteWorker({
          variables: {
            jobsiteWorkerId,
            jobsiteWorkerInput: {
              isBanned: false,
              bannedReason: null,
              bannedAt: null,
              passedMedicalExamAt: getCurrentISOFormattedDateTime(),
              medicalNotes: data?.medicalNotes,
              urineTestConditionalPassExpiresAt: getISOFormattedDateTimeFromCleave(medicalExamSkipExpiresAtDate),
              medicalExamSkipExpiresAt: getISOFormattedDateTimeFromCleave(medicalExamSkipExpiresAtDate),
            },
          },
        });
        return;
      }

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            passedMedicalExamAt: getCurrentISOFormattedDateTime(),
            medicalNotes: data?.medicalNotes,
            medicalExamSkipReason: null,
            medicalExamSkipExpiresAt: null,
            isBanned: false,
            bannedReason: null,
            bannedAt: null,
          },
        },
      });
      deferredSubmission?.resolve(true);
    } catch (error) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    }
  };

  return {
    uploadDocuments,
    updateJobsiteWorker,
    fetching,
    setFetching,
  };
}
