import cn from 'classnames';

export const classes = {
  headerInfo: (extraClasses: string): string => cn('odin-flex-1', 'odin-min-w-0', extraClasses),
  headerWorkerName: cn(
    'header-worker-name',
    'odin-text-2xl',
    'odin-font-bold',
    'odin-leading-7',
    'odin-text-odin-black',
    'sm:odin-text-3xl',
    'sm:odin-truncate',
  ),
  headerWorkerInfo: cn(
    'header-worker-info',
    'odin-text-odin-navy',
    'odin-mt-1',
    'odin-flex',
    'odin-flex-col',
    'sm:odin-flex-row',
    'sm:odin-flex-wrap',
    'sm:odin-mt-0',
    'sm:odin-space-x-6',
  ),
  headerWorkerInfoItemContainer: cn(
    'header-worker-info-item-container',
    'odin-mt-2',
    'odin-flex',
    'odin-items-center',
    'odin-text-sm',
  ),
  headerWorkerInfoItemIcon: (icon: string): string =>
    cn(icon, 'header-worker-info-item-icon', 'odin-text-odin-primary', 'odin-font-normal', 'odin-mr-1.5'),
};
