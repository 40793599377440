import classNames from 'classnames/bind';
import * as styles from './scss/search-box-suggestions-list.scss';

const cn = classNames.bind(styles);

export const classes = {
  suggestionsListContainer: cn(
    'suggestions-list-container',
    'odin-flex',
    'odin-flex-col',
    'odin-p-6',
    'odin-space-y-6',
    'sm:odin-justify-between',
    'sm:odin-flex-row',
    'sm:odin-space-x-2',
  ),
  suggestionsList: cn('sui-search-box__suggestion-list'),
  suggestionItem: cn('!odin-mx-0', '!odin-px-0', '!odin-py-0'),
  suggestionItemText: cn(
    'odin-cursor-pointer',
    'odin-h-11',
    'odin-py-3',
    'odin-text-base',
    'odin-font-medium',
    'odin-text-gray-900',
  ),

  infoContainer: cn(
    'odin-flex',
    'odin-flex-col',
    'odin-justify-center',
    'odin-rounded-md',
    'odin-p-6',
    'odin-bg-odin-highlight',
    'sm:odin-w-72',
  ),
  infoIcon: cn('info-icon', 'fal', 'fa-lightbulb-on', 'odin-text-odin-primary', 'odin-text-2xl', 'odin-font-normal'),
};
