import React from 'react';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  FilterItems,
} from '@odin-labs/components';
import { isAfterToday } from 'utils/dates';
import { Contractor, ContractorsFilters } from 'containers/contractors/types';
import { ContractorIcon } from 'containers/contractor/types';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';

export type ContractorColumn = Column<Contractor>;

export const getColumns = (): ContractorColumn[] => [
  {
    id: 'contractor',
    Header: 'Name',
    accessor: (contractor: Contractor): string => {
      return contractor.organization.name;
    },
    Cell: AvatarCell,
    CellLoading: <AvatarSkeleton size="xs" type="rounded" hideDetails />,
    componentProps: (contractor: Contractor): AvatarCellComponentProps => ({
      icon: ContractorIcon,
      type: 'rounded',
      placeholder: (contractor.organization.name.substring(0, 2) || '??').toUpperCase(),
      objectFit: 'cover',
      size: 'xs',
    }),
  },
  {
    id: 'location',
    Header: 'Location',
    accessor: (contractor: Contractor): string => {
      const { addressCity, addressState } = contractor?.organization ?? {};
      return [addressCity, addressState].filter(Boolean).join(', ');
    },
  },
  {
    id: 'jobsiteAssignments',
    Header: 'Jobsite Assignments',
    accessor: (): string[] => [],
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    cellClassName: 'odin-py-3',
    componentProps: (contractor: Contractor): ChipsCellComponentProps => ({
      chips: contractor.jobsiteContractors.edges.map(({ node }) => ({
        text: node.jobsite.name,
        secondaryText: `${node.jobsiteWorkers.count} workers `,
        isActive: !node.endDate || isAfterToday(node.endDate),
      })),
    }),
    disableSortBy: true,
  },
];

export const getFilterItems = ({
  jobsites,
  developerIds,
  jobsiteIds,
  search,
}: {
  jobsites: Jobsite[];
  developerIds: string[];
  jobsiteIds: string[];
  search: string;
}): FilterItems<ContractorsFilters> => ({
  developerIds: {
    header: 'Client',
    type: 'dropdown',
    defaultValue: developerIds,
    componentProps: {
      options: getDeveloperOptionsFromJobsites(jobsites),
      allowSearch: false,
    },
  },
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
