import React from 'react';
import { Table, TableContainer } from '@odin-labs/components';
import { useGetEntityChangesQuery } from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { NewHeader } from 'components/header/NewHeader';
import { WorkerChangeTabProps } from 'containers/entityChange/worker/types';
import { getColumns, getChangesByLayout } from 'containers/entityChange/worker/tabs/tables';

export function WorkerOverview(props: WorkerChangeTabProps): React.ReactElement {
  const { loading: parentLoading, worker, changesLayout, onTabApiChange } = props;
  const { workerId, user } = worker ?? {};
  const { id: userIdentityId } = user.identity ?? {};

  // get worker changes
  const {
    data: workerChangesData,
    loading: workerChangesLoading,
    error: workerChangesError,
    refetch: refetchWorkerChanges,
  } = useGetEntityChangesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        // paginationInput: { limit, offset },
        filters: [
          { tableName: 'worker', entityIds: [workerId] },
          { tableName: 'user_identity', entityIds: [userIdentityId] },
        ],
      },
    },
    skip: !workerId && !userIdentityId,
  });

  const workerChanges = React.useMemo(
    () => getChangesByLayout(workerChangesData?.getEntityChanges.edges.map(({ node }) => node) ?? [], changesLayout),
    [workerChangesData, changesLayout],
  );

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerChanges }), [refetchWorkerChanges]);

  const workerColumns = React.useMemo(() => getColumns(workerChanges, changesLayout), [workerChanges]);

  const error = workerChangesError;
  if (error) {
    return <LoadingError error={error} />;
  }

  const loading = parentLoading || workerChangesLoading;

  return (
    <div className="odin-flex odin-flex-col odin-gap-y-6">
      <TableContainer>
        <NewHeader size="md" title="Worker Changes" actionsProps={{ headerActions: null }} />
        <Table
          loading={loading}
          columns={workerColumns}
          data={workerChanges}
          // initialState={{ sortBy: tableSortBy, pageSize: limit }}
          // pageCount={pageCount}
          // pageIndex={page}
          remote
          // enablePagination
        />
      </TableContainer>
    </div>
  );
}
