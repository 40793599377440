import { PlacesAutocomplete } from './PlacesAutocomplete';
import { getAddressComponents } from './utils';

const name = 'PlacesAutocomplete';

export { PlacesAutocomplete, name, getAddressComponents };

export type {
  PlacesAutocompleteProps,
  Place,
  AddressComponentType,
  AddressComponent,
  AddressComponents,
} from './types';

export { NewPlacesAutocomplete } from './NewPlacesAutocomplete';
export type { NewPlacesAutocompleteProps } from './types';
