import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AuthContext } from 'auth';
import logo from 'images/ODIN.svg';
import type { ReactElement } from 'react';
import { generatePath } from 'utils/generatePath';
import { SidebarProps } from './types';
import { LockIcon } from './utils';

export function DesktopSidebar(props: SidebarProps): ReactElement {
  const { sidebarRoutes, getRouteSidebarProps, classes, appVersion } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  return (
    <div className={cn(classes.background, 'odin-hidden xl:odin-flex xl:odin-flex-col')}>
      <div
        className={cn(
          'odin-flex odin-grow',
          'odin-flex-col',
          'odin-pt-5',
          'odin-pb-4',
          'odin-px-6',
          'odin-overflow-y-auto',
        )}
      >
        <div className="odin-flex odin-items-center odin-shrink-0 odin-px-2">
          <img className="odin-h-6 odin-w-auto odin-m-2" src={logo} alt="ODIN" />
        </div>
        <nav className="odin-mt-5 odin-flex-1 odin-space-y-1">
          {sidebarRoutes.map((route): ReactElement => {
            if ((!route?.acl || user.hasAnyRole(route?.acl)) && route?.sidebar) {
              const { isLocked, isActive } = getRouteSidebarProps(route);

              return (
                <Link
                  key={route?.sidebar?.pathname}
                  to={generatePath(route?.sidebar?.pathname, {})}
                  className={cn(classes.item(isActive), 'odin-p-3 odin-text-base hover:odin-text-white')}
                >
                  <i className={cn(route?.sidebar?.icon, 'odin-text-lg odin-h-4 odin-w-6 odin-shrink-0')} />
                  <span className="odin-ml-3">{route?.sidebar?.item}</span>
                  {isLocked && <LockIcon />}
                </Link>
              );
            }
            return null;
          })}
        </nav>
      </div>
      <div className="odin-flex odin-justify-center odin-px-6 odin-py-5 odin-text-white odin-opacity-60">
        <span className="odin-text-base">v{appVersion}</span>
      </div>
    </div>
  );
}
