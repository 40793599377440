import { AlertBody } from './AlertBody';

type NotificationType = 'info' | 'warning' | 'danger' | 'success' | 'primary' | 'secondary';
type NotificationPlace = 'tc' | 'tr' | 'tl' | 'bc' | 'br' | 'bl';

export type AlertType = {
  notificationAlert: any;
};

export class AlertInstance {
  static alertRef: AlertType;

  static setAlert(alert: AlertType): void {
    this.alertRef = alert;
  }

  static alert(place: NotificationPlace, type: NotificationType, title: string, message: string): void {
    this.alertRef.notificationAlert({
      place,
      message: AlertBody(title, message),
      type,
      autoDismiss: 3,
    });
  }
}
