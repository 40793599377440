import React from 'react';
import { Form, FormOnSubmit } from 'components/form';
import { SelfOnboardingStepKey, useUpdateWorkerMutation } from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import { isNotEmpty } from 'utils';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { SelfOnboardingProfileFormData, SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { StepInfo, StepActions, StepInfoTitle } from 'containers/selfOnboarding/steps/components';
import { useGetIsSelfOnboardingClosed } from 'containers/selfOnboarding/helpers/useGetIsSelfOnboardingClosed';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { getFormInputsHook, getDefaultValues, getUpdateInput } from './ProfileStep.forms';

export function ProfileStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, navigation, stepConfig, localize, language, jobsiteInvitation } = props;
  const { worker, profile } = state;
  const { goToStep, goToNextStep, getStepNumberInfo, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;
  const stepNumberInfo = getStepNumberInfo();

  const [isSaving, setIsSaving] = React.useState(false);
  const [updateWorker] = useUpdateWorkerMutation();
  const { isSelfOnboardingClosed } = useGetIsSelfOnboardingClosed();

  const onSubmit: FormOnSubmit<SelfOnboardingProfileFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      if (await isSelfOnboardingClosed()) {
        goToStep(SelfOnboardingStepKey.Closed);
        return;
      }

      if (isNotEmpty(dirtyFields)) {
        const input = getUpdateInput(worker, data, dirtyFields);
        await updateWorker({ variables: input });
        updateState({ profile: data });
      }
      setIsSaving(false);
      goToNextStep();
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const inputs = getFormInputsHook({ language });
  const defaultValues = React.useMemo(
    () => getDefaultValues(profile, language),
    [
      profile,
      // , language // do not reset defaultValues `language` changes
    ],
  );

  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.self_onboarding_profile_header)}
          subtitle={localize(Copy.self_onboarding_profile_instructions)}
          stepNumberInfo={stepNumberInfo}
        />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingProfileFormData) ?? true}
        renderBelow={
          <StepActions
            jobsiteInvitation={jobsiteInvitation}
            localize={localize}
            isReview={isReview}
            actions={isReview ? 'all' : 'continue'}
            onSkip={goToNextStep}
            continueActionWithSpinner={isSaving}
          />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
