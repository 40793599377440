import cn from 'classnames';

export const classes = {
  workerPersonalInfo: cn('odin-flex', 'odin-p-5', 'odin-bg-odin-white', 'odin-shadow-odin-normal', 'odin-rounded-md'),
  workerPersonalInfoGrid: cn('odin-w-full', 'odin-grid', 'odin-gap-y-3', 'odin-grid-cols-grid-field-value-custom-1'),

  addressLabel: cn('odin-row-start-1 odin-col-start-3', 'odin-row-span-2'),
  addressValue: cn('odin-row-start-1 odin-col-start-4', 'odin-row-span-2'),
  unionAffiliationLabel: cn('odin-row-start-1 odin-col-start-5'),
  unionAffiliationValue: cn('odin-row-start-1 odin-col-start-6'),

  genderLabel: cn('odin-row-start-2 odin-col-start-1'),
  genderValue: cn('odin-row-start-2 odin-col-start-2'),
  emergencyContactLabel: cn('odin-row-start-2 odin-col-start-5', 'odin-row-span-2'),
  emergencyContactValue: cn('odin-row-start-2 odin-col-start-6', 'odin-row-span-2'),

  primaryLanguageLabel: cn('odin-row-start-3 odin-col-start-1'),
  primaryLanguageValue: cn('odin-row-start-3 odin-col-start-2'),
  phoneLabel: cn('odin-row-start-3 odin-col-start-3'),
  phoneValue: cn('odin-row-start-3 odin-col-start-4'),
  emailLabel: cn('odin-row-start-4 odin-col-start-3'),
  emailValue: cn('odin-row-start-4 odin-col-start-4'),
};
