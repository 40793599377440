import { NewButton } from '@odin-labs/components';
import { useJobsiteUpdateMutation } from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { LoadingError } from 'components/loadingError';
import {
  EditJobsiteConfigurationFormData,
  JobsiteConfigurationDataTabProps,
} from 'containers/jobsiteConfiguration/types';
import React from 'react';
import { useBoolean, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getIcon } from 'utils/ui';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import {
  getDefaultValues,
  getDropdownsOptions,
  getFormInputs,
  getJobsiteUpdateInput,
} from './JobsiteConfigurationEdit.forms';
import { ArchiveJobsiteModal } from './modals';

const TrashIcon = getIcon('fal fa-trash');

export const JobsiteConfigurationEdit = React.forwardRef<HTMLFormElement, JobsiteConfigurationDataTabProps>(
  (props, ref): React.ReactElement => {
    const {
      jobsite,
      userData,
      loading: jobsiteLoading,
      onIsDirtyChange,
      isSaving,
      setIsSaving,
      refetchJobsite,
      deferredSubmission,
    } = props;

    const isMounted = useIsMounted();
    const {
      value: isArchiveJobsiteModalOpen,
      setTrue: openArchiveJobsiteModal,
      setFalse: closeArchiveJobsiteModal,
    } = useBoolean(false);

    const [jobsiteUpdate] = useJobsiteUpdateMutation();

    const onSubmit: FormOnSubmit<EditJobsiteConfigurationFormData> = async (
      data,
      event,
      dirtyFields,
    ): Promise<void> => {
      if (isSaving) {
        AlertInstance.alert('tc', 'warning', 'Saving in progress!', '');
        return;
      }
      setIsSaving(true);

      try {
        const input = await getJobsiteUpdateInput(jobsite, data, dirtyFields);
        await jobsiteUpdate({ variables: { input } });

        setIsSaving(false, true);
        AlertInstance.alert('tc', 'success', 'Success', 'Jobsite configuration successfully updated');
        if (isMounted()) {
          refetchJobsite();
        }
        useAvailableJobsites.resetCacheOnNextCall();
        deferredSubmission.resolve(true);
      } catch (error) {
        event.preventDefault();
        setIsSaving(false);
        deferredSubmission.reject(error);
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    };

    const { formInputs, defaultValues } = React.useMemo(() => {
      const dropdownsOptions = getDropdownsOptions({ userData, jobsite });
      return {
        formInputs: getFormInputs(dropdownsOptions),
        defaultValues: getDefaultValues({ jobsite, ...dropdownsOptions }),
      };
    }, [userData, jobsite]);

    if (!jobsite) {
      return <LoadingError loading />;
    }

    return (
      <div className="odin-relative">
        {jobsiteLoading && (
          <div className="table-backdrop">
            <LoadingError loading />
          </div>
        )}
        <Form
          ref={ref}
          inputs={formInputs}
          onSubmit={onSubmit}
          onIsDirtyChange={onIsDirtyChange}
          defaultValues={defaultValues}
          className="odin-pr-3"
          inputsContainerClassName="odin-space-y-5 sm:odin-space-y-9 odin-divide-y odin-divide-gray-200 odin-pb-9"
          renderBelow={(): React.ReactElement => (
            <div className="odin-flex odin-justify-end odin-pb-9">
              <NewButton theme="danger" icon={TrashIcon} text="Archive Jobsite" onClick={openArchiveJobsiteModal} />
              <ArchiveJobsiteModal
                isOpen={isArchiveJobsiteModalOpen}
                closeModal={closeArchiveJobsiteModal}
                jobsite={jobsite}
              />
            </div>
          )}
        />
      </div>
    );
  },
);
