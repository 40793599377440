import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@odin-labs/components';

import { classes } from './NotFoundContainer.style';

export function NotFoundContainer(): ReactElement {
  const history = useHistory();
  return (
    <div className={classes.container}>
      <div className={classes.containerContent}>
        <main className="sm:odin-flex">
          <p className="odin-text-4xl odin-font-extrabold odin-text-indigo-600 sm:odin-text-5xl">404</p>
          <div className="sm:odin-ml-6">
            <div className="sm:odin-border-l sm:odin-border-gray-200 sm:odin-pl-6">
              <h1 className="odin-text-4xl odin-font-extrabold odin-text-gray-900 odin-tracking-tight sm:odin-text-5xl">
                Page not found
              </h1>
              <p className="odin-mt-1 odin-text-base odin-text-gray-500">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className={classes.actionContent}>
              <Button
                onClick={(): void => {
                  history.push('/');
                }}
              >
                Go back home
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
