import React, { ReactElement } from 'react';
import { Tab } from '@odin-labs/components';
import { JobsiteAnnouncement } from 'apollo/generated/client-operations';
import { AnnouncementFilters, AnnouncementPerformance, AnnouncementSummary } from 'containers/announcement/components';

type TabProps = {
  tab: Tab;
  announcement: JobsiteAnnouncement;
  counts: { totalCount: number; totalReachableCount: number };
};
export function JobsiteAnnouncementTab({ announcement, counts }: TabProps): ReactElement {
  return (
    <div className="odin-space-y-5 sm:odin-space-y-9 odin-divide-y odin-divide-gray-200 odin-pr-10 odin-pb-[50%]">
      <AnnouncementSummary announcement={announcement} />
      <AnnouncementFilters announcement={announcement} />
      <AnnouncementPerformance announcement={announcement} counts={counts} />
    </div>
  );
}
