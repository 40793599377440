import React from 'react';
import cn from 'classnames';
import { LoadingError } from 'components/loadingError';

export type StepLoadingProps = {
  loading: boolean;
  hasTransparentBackground?: boolean;
};

export function StepLoading(props: StepLoadingProps): React.ReactElement {
  const { loading, hasTransparentBackground } = props;

  return (
    loading && (
      <div className={cn('table-backdrop -odin-m-5', hasTransparentBackground && '!odin-bg-transparent')}>
        <LoadingError loading />
      </div>
    )
  );
}
