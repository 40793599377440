import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export type UpdatableResult<T> = [T, Dispatch<SetStateAction<T>>, boolean];
export type StateComparator<T> = (a?: T, b?: T) => boolean;
const defaultComparator = <T>(a: T, b: T): boolean => a === b;

/**
 * `useState` that updates based on changes to provided value. If `value`
 * changes, the `state` value returned by `useUpdatableState` will reflect the
 * new value. A third array element, `changed`, will be set to `true` for the
 * render cycle that caused the change, as a snapshot indicator that a change
 * took place. Future render cycles will not retain this `true` value.
 * @param value The external value that initially populates `state` and from
 *        where `state` receives updates.
 * @param areEqual An optional function (`(value, previous): boolean`) to
 *        compare the current and previous `value` properties and
 *        current `value` with `state`.
 *        If it returns `false` for both comparison, `state` will be updated
 *        and `changed` will be `true` until the component is rendered.
 *        If not provided, the new and previous values will be compared
 *        by a strict identity comparison (`===`).
 * @param skipUpdate If `true`, any `state` updates will be ignored.
 * @returns `[state, setState, changed]` where `state` and `setState` behave
 *          exactly like with the `useState` hook, including guarantees that
 *          `setState` will not change. `change` will be `true` when `value`
 *          changes between renders.
 */
export const useUpdatableState = <T>(
  value: T,
  // eslint-disable-next-line default-param-last
  areEqual: StateComparator<T> = defaultComparator,
  skipUpdate?: boolean,
): UpdatableResult<T> => {
  const [state, setState] = useState<T>(value);
  const [isChanged, setChanged] = useState(true);
  const previousValueRef = useRef(value);

  useEffect(() => {
    if (!skipUpdate) {
      previousValueRef.current = value;
    }
  });

  if (!skipUpdate) {
    if (!areEqual(value, previousValueRef.current) && !areEqual(value, state)) {
      setChanged(true);
      setState(value);
    } else if (isChanged) {
      setChanged(false);
    }
  }

  return [state, setState, isChanged];
};
