import React, { useState, ReactElement } from 'react';
import {
  GetJobsiteContainerContractorsDocument,
  useJobsiteUpdateContractorMutation,
} from 'apollo/generated/client-operations';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { getJobsitesOptions } from './utils';
import { EditContractorAssignmentModalProps } from './types';
import {
  getDefaultValues,
  getFormInputsHook,
  EditContractorAssignmentFormElements,
  useParentContractorsOptions,
} from './EditContractorAssignmentModal.forms';

export function EditContractorAssignmentModal(props: EditContractorAssignmentModalProps): ReactElement {
  const { jobsiteContractor, isOpen, contractors, jobsite, closeModal, onClosed } = props;
  const { jobsiteId } = jobsite ?? {};

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [jobsiteUpdateContractor] = useJobsiteUpdateContractorMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetJobsiteContainerContractorsDocument],
  });

  const onSubmit: FormOnSubmit<EditContractorAssignmentFormElements> = async (data, event): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    const { startDate, parentContractorId, firstName, lastName, email, phoneNumber, title } = data;

    try {
      await jobsiteUpdateContractor({
        variables: {
          input: {
            jobsiteContractorId: jobsiteContractor.id,
            jobsiteContractorInput: {
              startDate: startDate.toDate(),
              parentContractorId: parentContractorId?.value ?? null,
              contactInput: nullifyEmptyFields({ firstName, lastName, email, phoneNumber, title }),
            },
          },
        },
      });

      closeModal();
      AlertInstance.alert('tc', 'success', 'Success', 'Jobsite Assignment successfully updated');
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const { contractorsOptions, jobsitesOptions } = React.useMemo(
    () => ({
      contractorsOptions: getContractorsOptions(contractors),
      jobsitesOptions: getJobsitesOptions([jobsite].filter(Boolean)),
    }),
    [contractors, jobsite],
  );

  const parentContractorsOptions = useParentContractorsOptions(
    [jobsiteContractor?.contractor.contractorId].filter(Boolean),
    jobsiteId,
  );

  const formInputs = getFormInputsHook({
    editType: 'update',
    jobsiteId,
    contractorsOptions,
    jobsitesOptions,
  });

  const defaultValues = React.useMemo(
    () =>
      getDefaultValues({
        jobsiteId,
        jobsiteContractor,
        contractorsOptions,
        jobsitesOptions,
        parentContractorsOptions,
      }),
    [jobsiteId, jobsiteContractor, contractorsOptions, jobsitesOptions, parentContractorsOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      afterLeave={onClosed}
      title="Edit Contractor Assignment"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionButtonWithSpinner={isSaving}
    />
  );
}
