import React from 'react';
import { DeepMap } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { UseInputs, TypedFormInputs, UseFormMethods, getUpdateInputValueFunction, FormInput } from 'components/form';
import { ChangeType, JobsiteCreateFormSubmissionInput } from 'apollo/generated/client-operations';
import { AuthUser } from 'acl';
import { ReplaceSelectOptionElement } from 'types';
import { ensureNonUndefinedFields, evalJsCode } from 'utils';
import { getDateTime } from 'utils/dates';
import { AvailableJobsiteWorkerOption, WatchedFieldConfig } from 'containers/jobsiteFormSubmission/types';
import {
  EvalContext,
  getContextSelectOptions,
  getEvalContext,
} from 'containers/jobsiteFormSubmission/tabs/FormSubmissionEdit.forms';
import { Form } from 'containers/forms/formsTab/types';
import {
  useWatchedFields,
  formInputsAsArray,
  useAvailableJobsiteWorkerOptions,
} from 'containers/jobsiteFormSubmission/utils';
import { AddFormSubmissionFormData } from './types';
import { useJobsiteContractorsOptions } from './utils';

export type GetFormInputsArgs = {
  form: Form;
  defaultValues: AddFormSubmissionFormData;
  evalContext: EvalContext<AddFormSubmissionFormData>;
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<AddFormSubmissionFormData> =>
  (methods: UseFormMethods<AddFormSubmissionFormData>): FormInput<AddFormSubmissionFormData>[] => {
    const { watch, getValues, setValue } = methods;
    const { form, defaultValues, evalContext: baseEvalContext } = args;
    const {
      inputs: inputsExpression,
      defaultValues: defaultValuesExpression,
      watchedFields: watchedFieldsConfig,
    } = form?.content.modal ?? {};

    const watchedFields = (watchedFieldsConfig as WatchedFieldConfig<AddFormSubmissionFormData>[]).map((item) =>
      typeof item === 'string' ? item : item.field,
    );
    const watched = watch(watchedFields) as Partial<AddFormSubmissionFormData>;
    const state = { ...defaultValues, ...getValues(), ...watched };

    const selectedJobsiteOption = watch('jobsiteFormId');
    const selectedJobsiteId = selectedJobsiteOption?.jobsiteId;

    const contractorsOptions = useJobsiteContractorsOptions({
      jobsiteId: selectedJobsiteId,
      skip:
        !(inputsExpression as string)?.includes('ctx.options.contractors') &&
        !(defaultValuesExpression as string)?.includes('ctx.options.contractors'),
    });

    const { availableJobsiteWorkerOptions } = useAvailableJobsiteWorkerOptions({
      jobsiteFormSubmission: undefined,
      jobsiteId: selectedJobsiteId,
      skip:
        !(inputsExpression as string)?.includes('ctx.options.availableJobsiteWorkers') &&
        !(defaultValuesExpression as string)?.includes('ctx.options.availableJobsiteWorkers'),
    });

    useDidUpdateEffect(() => {
      setValue('jobsiteContractorId', contractorsOptions?.length === 1 ? contractorsOptions[0] : null);
    }, [contractorsOptions]);

    const evalContext: EvalContext<AddFormSubmissionFormData> = React.useMemo(() => {
      return (
        baseEvalContext && {
          ctx: {
            ...baseEvalContext.ctx,
            edit: state,
            form: methods,
            options: getContextSelectOptions({
              ...baseEvalContext.ctx.options,
              contractors: contractorsOptions ?? baseEvalContext.ctx.options.contractors,
              availableJobsiteWorkers:
                availableJobsiteWorkerOptions ?? baseEvalContext.ctx.options.availableJobsiteWorkers,
            }),
          },
        }
      );
    }, [baseEvalContext, JSON.stringify(state), methods, contractorsOptions, availableJobsiteWorkerOptions]);

    useWatchedFields(watchedFieldsConfig, watched, evalContext);

    return React.useMemo(() => {
      const computedInputs =
        evalContext && evalJsCode<TypedFormInputs<AddFormSubmissionFormData>>(inputsExpression, evalContext);
      return formInputsAsArray(computedInputs);
    }, [inputsExpression, evalContext]);
  };

type GetDefaultValuesArgs = {
  defaultValuesExpression: string;
  evalContext: EvalContext<AddFormSubmissionFormData>;
};

export const getDefaultValues = (args: GetDefaultValuesArgs): AddFormSubmissionFormData => {
  const { defaultValuesExpression, evalContext } = args;
  return evalContext && evalJsCode(defaultValuesExpression, evalContext);
};

type WorkersUpdateInput = Required<Pick<JobsiteCreateFormSubmissionInput, 'jobsiteWorkers'>>;
type JobsiteWorkerUpdateInput = WorkersUpdateInput['jobsiteWorkers'][number];

export const getJobsiteWorkersCreateInput = (args: {
  data: AddFormSubmissionFormData;
  dirtyFields: DeepMap<AddFormSubmissionFormData, true>;
  form: Form;
}): WorkersUpdateInput => {
  const { data, form } = args;
  const jobsiteWorkerFields = form.content.modal.jobsiteWorkerFields as string[];

  return {
    jobsiteWorkers: jobsiteWorkerFields?.flatMap((jwField): JobsiteWorkerUpdateInput[] => {
      const jwFieldValue = data?.[jwField];
      if (Array.isArray(jwFieldValue)) {
        return jwFieldValue
          ?.filter((jfsW) => jfsW.changeType)
          .map((jfsW) => ({
            changeType: jfsW.changeType as ChangeType,
            id: jfsW.id,
            jobsiteWorkerId: jfsW.jobsiteWorker.jobsiteWorkerId,
            associationType: jfsW.associationType,
            extraData: jfsW.extraData,
          }));
      }
      if (jwFieldValue) {
        const newValue = jwFieldValue as AvailableJobsiteWorkerOption;
        return [
          // add the new value
          {
            changeType: ChangeType.Created,
            id: newValue.id,
            jobsiteWorkerId: newValue.jobsiteWorker.jobsiteWorkerId,
            associationType: newValue.associationType,
          },
        ];
      }
      return undefined;
    }),
  };
};

type ContractorsUpdateInput = Required<Pick<JobsiteCreateFormSubmissionInput, 'jobsiteContractors'>>;
type JobsiteContractorUpdateInput = ContractorsUpdateInput['jobsiteContractors'][number];

export const getJobsiteContractorsCreateInput = (args: {
  data: AddFormSubmissionFormData;
  dirtyFields: DeepMap<AddFormSubmissionFormData, true>;
  form: Form;
}): ContractorsUpdateInput => {
  const { data, form } = args;
  const jobsiteContractorFields = (form.content.modal.jobsiteContractorFields as string[]) ?? [];

  return {
    jobsiteContractors: jobsiteContractorFields?.flatMap((jwField): JobsiteContractorUpdateInput[] => {
      const jcFieldValue = data?.[jwField];
      if (Array.isArray(jcFieldValue)) {
        return jcFieldValue
          ?.filter((jfsC) => jfsC.changeType)
          .map((jfsC) => ({
            changeType: jfsC.changeType as ChangeType,
            id: jfsC.id,
            jobsiteContractorId: jfsC.jobsiteContractor.id,
            associationType: jfsC.associationType,
            extraData: jfsC.extraData,
          }));
      }
      if (jcFieldValue) {
        const newValue = jcFieldValue as SelectOptionElement;
        return [
          // add the new value
          {
            changeType: ChangeType.Created,
            jobsiteContractorId: newValue.value,
            associationType: newValue.associationType ?? 'assignment',
          },
        ];
      }
      return undefined;
    }),
  };
};

export type GetJobsiteFormSubmissionCreateInputArgs = {
  user: AuthUser;
  dependencies: Record<string, unknown>;
  form: Form;
  data: AddFormSubmissionFormData;
  dirtyFields: DeepMap<AddFormSubmissionFormData, true>;
};

export const getJobsiteFormSubmissionCreateInput = ({
  user,
  dependencies,
  form,
  data,
  dirtyFields,
}: GetJobsiteFormSubmissionCreateInputArgs): JobsiteCreateFormSubmissionInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);
  const { updateInputs: updateInputsExpression } = form.content.modal ?? {};
  const extraDataFields = (form.content.modal.extraDataFields as string[]) ?? [];

  /**
   * This function receives `date` and `time` as arguments and returns a date object composed based on the arguments.
   * The returned Date object will be calculated based on the `date` arguments as it follows:
   *  - if `date` is a field name, then the form field value will be used;
   *  - if `date` is a Date, then the passed value will be used.
   * @returns
   */
  const getDateTimeUpdateInput = (args: { date: string | Date | Moment; time: string; timeZone: string }): Date => {
    const { date, time, timeZone } = args;
    const isDateAField = typeof date === 'string' && date in data;
    // get `updDate` only if a field name is specified through date parameter
    const updDate = isDateAField ? getUpdateInputValue(date, true) : undefined;
    const updTime = getUpdateInputValue(time) as string;
    return updDate || updTime
      ? getDateTime({
          date: updDate ?? moment.utc(date),
          time: updTime ?? (time in data ? (data[time] as string) : time),
          timeZone,
          isUTC: !time, // when time is not provided, the time is in UTC
        })
      : undefined;
  };

  const evalContext = getEvalContext({
    user,
    jobsiteFormSubmission: undefined,
    dependencies,
    edit: data,
    fn: { getDateTimeUpdateInput, getUpdateInputValue },
  });

  const customUpdateInputs = evalJsCode<ReplaceSelectOptionElement<AddFormSubmissionFormData>>(
    updateInputsExpression,
    evalContext,
  );

  const extraDataInput = Object.fromEntries(
    extraDataFields.map((field) => [field, customUpdateInputs[field] ?? getUpdateInputValue(field)]),
  );

  extraDataFields.forEach((field) => {
    delete customUpdateInputs[field];
  });

  const getDateTimeDefaultInput = (
    fieldName: keyof Pick<JobsiteCreateFormSubmissionInput, 'startAt' | 'endAt'>,
  ): Date => {
    if (fieldName in customUpdateInputs) return undefined; // update is handled by updateInputs in form definition
    return getDateTimeUpdateInput({ date: fieldName, time: '00:00', timeZone: data.jobsiteFormId.timeZone });
  };

  return ensureNonUndefinedFields<JobsiteCreateFormSubmissionInput>({
    extraData: extraDataInput,
    startAt: getDateTimeDefaultInput('startAt'),
    jobsiteFormId: getUpdateInputValue('jobsiteFormId', true),
    ...getJobsiteWorkersCreateInput({ data, dirtyFields, form }),
    ...getJobsiteContractorsCreateInput({ data, dirtyFields, form }),
    ...customUpdateInputs,
  });
};
