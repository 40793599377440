import { RadioGroupOption, SelectOptionElement } from '@odin-labs/components';
import { JobsiteWorker } from 'containers/worker/types';

export enum JobsiteAssignmentCorrectionType {
  Change,
  Deletion,
}

export type CorrectJobsiteAssignmentFormData = {
  jobsiteId: SelectOptionElement;
  correctionType: RadioGroupOption<JobsiteAssignmentCorrectionType>;
  newJobsiteId?: SelectOptionElement;
  newContractorId?: SelectOptionElement;
  alert: unknown;
};

export interface CorrectJobsiteAssignmentModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onClosed: () => void;
  jobsiteWorker: JobsiteWorker;
  jobsiteWorkers: JobsiteWorker[];
}
