import { JobsiteWorkerOrientationStatusFilter } from 'apollo/generated/client-operations';
import { JobsiteModule, JobsiteOnboardingModule, OnboardingJobsite } from 'containers/workerOnboardings/types';

export enum JobsiteWorkerOnboardingFilterValue {
  NotStarted = 'not-started',
  SelfOnboarding = 'self-onboarding',
  InProgress = 'in-progress',
  Complete = 'complete',
  Medical = 'medical',
  AtBadging = 'at-badging',
}

export const jobsiteWorkerOnboardingStatusMapping: Record<
  JobsiteWorkerOnboardingFilterValue,
  JobsiteWorkerOrientationStatusFilter
> = {
  [JobsiteWorkerOnboardingFilterValue.NotStarted]: JobsiteWorkerOrientationStatusFilter.NotStarted,
  [JobsiteWorkerOnboardingFilterValue.SelfOnboarding]: JobsiteWorkerOrientationStatusFilter.SelfOnboarding,
  [JobsiteWorkerOnboardingFilterValue.InProgress]: JobsiteWorkerOrientationStatusFilter.InProgress,
  [JobsiteWorkerOnboardingFilterValue.Complete]: JobsiteWorkerOrientationStatusFilter.Complete,
  [JobsiteWorkerOnboardingFilterValue.Medical]: JobsiteWorkerOrientationStatusFilter.NeedsMedical,
  [JobsiteWorkerOnboardingFilterValue.AtBadging]: JobsiteWorkerOrientationStatusFilter.AtBadging,
};

export const getOnboardingModule = (modules: JobsiteModule[]): JobsiteOnboardingModule =>
  modules?.find((m): m is JobsiteOnboardingModule => {
    return m.__typename === 'JobsiteOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getVisibleSteps = (jobsite: OnboardingJobsite): string[] => {
  if (!jobsite) return null;
  const jobsiteOnboardingModule = getOnboardingModule(jobsite.modules);
  return jobsiteOnboardingModule?.steps?.filter((step) => step.isVisible).map(({ key }) => key);
};
