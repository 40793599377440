import {
  AuthenticateLimitedTokenDetailsInput,
  useAuthenticateLimitedTokenDetailsMutation,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { Form, FormOnSubmit } from 'components/form';
import { generateApiUrl } from 'config/app';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { ensureNonUndefinedFields, isNotEmpty, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { setJwtCookie } from 'utils/cookies';
import { useQueryParams } from 'utils/useQueryParams';
import { Copy } from 'containers/mobileBadge/helpers/languages';
import { StepActions } from './components/StepActions';
import { StepInfo } from './components/StepInfo';
import { StepInfoTitle } from './components/StepInfoTitle';
import { MobileBadgeStep, UserValidationFormData, UserValidationStepProps } from './types';
import { getFormInputsHook } from './UserValidationStep.forms';

export function UserValidationStep(props: UserValidationStepProps): React.ReactElement {
  const { localize, language, setCurrentStep, workerId, workerCardId } = props;
  const urlSearchParams = useQueryParams();
  let mobilePlatform = urlSearchParams.get('mobilePlatform');

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState(false);
  const [authenticateLimitedTokenDetails] = useAuthenticateLimitedTokenDetailsMutation();

  const onSubmit: FormOnSubmit<UserValidationFormData> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      if (isNotEmpty(dirtyFields)) {
        const { birthDate } = data;
        const input = ensureNonUndefinedFields<AuthenticateLimitedTokenDetailsInput>({ birthDate, workerId });
        const response = await authenticateLimitedTokenDetails({ variables: { input } });
        const {
          data: {
            authenticateLimitedTokenDetails: { token },
          },
        } = response;

        if (token) {
          setJwtCookie({ accessToken: token });
          if (isAndroid) {
            mobilePlatform = 'google';
          } else if (isIOS) {
            mobilePlatform = 'apple';
          }
          if (mobilePlatform) {
            const redirectUrl = generateApiUrl(
              'wallet',
              mobilePlatform,
              'mobile-badge',
              'generate',
              workerId,
              workerCardId,
            );
            window.location.replace(redirectUrl);
          } else {
            setCurrentStep(MobileBadgeStep.BadgeDownload);
          }
        } else {
          AlertInstance.alert('tc', 'danger', 'Authentication Failed', 'Authentication was unsuccessful');
        }
      }
    } catch (error) {
      event.preventDefault();
      if ((error as Error)?.message?.includes('passwordless')) {
        formApi.setError('birthDate', {
          message: localize(Copy.mobile_badging_auth_error),
          shouldFocus: true,
        });
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const inputs = getFormInputsHook({ language });
  const defaultValues = React.useMemo(() => ({ birthDate: '' }), []);
  const localization = React.useMemo(() => ({ localize, copy: Copy }), [localize]);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.mobile_badging_header)}
          subtitle={localize(Copy.mobile_badging_instructions)}
        />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        renderBelow={<StepActions localize={localize} actions="continue" continueActionWithSpinner={isSaving} />}
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        localization={localization}
      />
    </div>
  );
}
