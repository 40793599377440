import cn from 'classnames';

export const classes = {
  headerPicture: (extraClasses: string): string => cn('odin-flex', extraClasses),
  headerEditPictureButton: cn(
    'header-worker-edit-picture-container',
    'odin-w-6',
    'odin-h-6',
    'odin-absolute',
    'odin-right-0',
    'odin-bottom-0',
    'odin-text-odin-primary',
    'odin-bg-odin-white',
    'odin-rounded-full',
    'odin-shadow-md',
    'hover:odin-bg-gray-200',
  ),
  headerEditPictureIcon: (icon: string): string => cn(icon, 'odin-text-xs', 'odin-font-normal'),
};
