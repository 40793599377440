import React from 'react';
import { AvatarCell, AvatarCellComponentProps, AvatarSkeleton, Column } from '@odin-labs/components';
import { getInitialsForCompactUser } from 'utils';
import { WorkerSession } from 'containers/dashboard/types';
import { SessionDateCell, SessionDateCellData } from 'containers/jobsiteAccessActivity/helpers/cells';
import { getISODateTimeToFormattedDate } from 'utils/dates';

export type WorkerSessionColumn = Column<WorkerSession>;

export const getColumns = (): WorkerSessionColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: (workerSession: WorkerSession): string => workerSession.userIdentityUserFullname,
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} hideDetails />,
    componentProps: (workerSession: WorkerSession): AvatarCellComponentProps => ({
      src: workerSession.publicUrl,
      placeholder: getInitialsForCompactUser({ fullName: workerSession.userIdentityUserFullname }),
      objectFit: 'cover',
      size: ['md', 'xl:xs'],
    }),
  },
  {
    id: 'jobsite',
    Header: 'Jobsite',
    accessor: 'jobsiteName',
  },
  {
    id: 'contractor',
    Header: 'Contractor',
    accessor: 'contractorOrganizationName',
  },
  {
    id: 'trade',
    Header: 'Trade',
    accessor: 'workerTrade',
  },
  {
    id: 'title',
    Header: 'Title',
    accessor: 'workerJobTitle',
  },
  {
    id: 'unionAffiliation',
    Header: 'Union',
    accessor: 'workerUnionAffiliation',
  },
  {
    id: 'sessionDate',
    Header: 'Date',
    accessor: (session: WorkerSession): SessionDateCellData => ({
      date: getISODateTimeToFormattedDate(session?.swipeSessionsSessionDate),
      isOvernight: session?.swipeSessionsOvernightSession?.toLowerCase() === true.toString(),
    }),
    Cell: SessionDateCell,
  },
  {
    id: 'startTime',
    Header: 'Start',
    accessor: 'swipeSessionsFirstInboundSwipeTimeOfDay',
  },
  {
    id: 'endTime',
    Header: 'End',
    accessor: 'swipeSessionsLastOutboundSwipeTimeOfDay',
  },
  {
    id: 'totalHours',
    Header: 'Hours',
    accessor: 'swipeSessionsDisplayValueSpanHours',
  },
];

export const orderByFields: Record<string, string> = {
  worker: 'name',
  jobsite: 'jobsiteName',
};
