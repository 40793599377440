import React from 'react';
import cn from 'classnames';
import { FileType } from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { DocumentFilePreviewContent } from './DocumentFilePreviewContent';

export type DocumentFilePreviewProps = {
  file: ServerFile;
  singleItemPerRow: boolean;
};

export function DocumentFilePreview({ file, singleItemPerRow }: DocumentFilePreviewProps): React.ReactElement {
  const isPdfFile = file.fileType === FileType.Pdf;
  return (
    <div className={cn('odin-w-full', singleItemPerRow || isPdfFile ? 'odin-col-span-full' : 'odin-max-h-48')}>
      <DocumentFilePreviewContent file={file} />
    </div>
  );
}
