import React from 'react';
import { NewButton, Table } from '@odin-labs/components';
import { NewHeader } from 'components/header/NewHeader';
import { getIcon } from 'utils/ui';
import { useModalChangeActions } from 'utils/useModalChangeActions';
import { DeleteSiteAreaModal, EditSiteAreaModal, EditableSiteArea, AddSiteAreaModal } from './modals';
import { SiteAreasProps } from './types';
import { getColumns, SiteAreaColumn } from './SiteAreas.tables';

const PlusIcon = getIcon('fal fa-plus');

export const SiteAreas = React.forwardRef(
  (props: SiteAreasProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value, onChange, loading } = props;

    const { change, closeModal, openAddModal, openEditModal, openDeleteModal, updateValueAndCloseModal } =
      useModalChangeActions<EditableSiteArea>({ items: value, onChange, idField: 'id' });

    const columns = React.useMemo<SiteAreaColumn[]>(
      () => getColumns({ openEditModal, openDeleteModal }),
      [openEditModal, openDeleteModal],
    );

    const items = React.useMemo(() => {
      return value?.filter((item) => item.changeType !== 'removed');
    }, [value]);

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-gap-y-6">
          <NewHeader
            title="Site Areas"
            size="md"
            actionsProps={{
              headerActions: null,
              children: <NewButton icon={PlusIcon} text="Add Area" size="sm" onClick={openAddModal} />,
            }}
          />
          <Table loading={loading} columns={columns} data={items} remote disableGlobalFilter disableSortBy />
        </div>
        <AddSiteAreaModal
          isOpen={change?.type === 'create'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
        />
        <EditSiteAreaModal
          isOpen={change?.type === 'update'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          siteArea={change?.item}
        />
        <DeleteSiteAreaModal
          isOpen={change?.type === 'remove'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          siteArea={change?.item}
        />
      </>
    );
  },
);
