import { getUpdateInputValueFunction } from 'components/form';
import {
  ChangeType,
  FileChangeInput,
  JobsiteFormSubmissionDocumentInput,
  JobsiteUpdateFormSubmissionInput,
} from 'apollo/generated/client-operations';
import { DeepMap } from 'react-hook-form';
import {
  EditableJobsiteFormSubmissionDocument,
  EditableJobsiteFormSubmissionDocumentFile,
} from 'containers/jobsiteFormSubmission/types';
import { tryFileCompression } from 'utils';

const toFileChangeInput = async (file: EditableJobsiteFormSubmissionDocumentFile): Promise<FileChangeInput> => {
  const fileData = await tryFileCompression(file as File);
  return {
    changeType: file.changeType,
    fileId: file.fileId,
    fileInput: { uploadData: fileData, isPublic: false },
  };
};

export const toUpdateInput = async (
  document: EditableJobsiteFormSubmissionDocument,
): Promise<JobsiteFormSubmissionDocumentInput> => {
  const { changeType, id, description, files } = document;

  return {
    changeType: changeType as ChangeType,
    id,
    description,
    files:
      document.changeType === 'removed'
        ? undefined
        : await Promise.all(files.filter((f) => f.changeType).map(toFileChangeInput)),
  };
};

type DocumentsSectionUpdateInput = Required<Pick<JobsiteUpdateFormSubmissionInput, 'documents'>>;

export type DocumentsSectionData = { documents: EditableJobsiteFormSubmissionDocument[] };

export const getDocumentsUpdateInput = async (
  data: DocumentsSectionData,
  dirtyFields: DeepMap<DocumentsSectionData, true>,
): Promise<DocumentsSectionUpdateInput> => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);
  const documents = getUpdateInputValue('documents');
  return {
    documents: documents && (await Promise.all(documents.filter((d) => d.changeType).map(toUpdateInput))),
  };
};
