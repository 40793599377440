import React, { ReactElement, useState } from 'react';
import cn from 'classnames';
import { Table } from '@odin-labs/components';
import { WorkerTabProps } from 'containers/print/types';
import workerImage from 'images/worker.png';
import { Badge, Card, CardFooter, CardHeader, CardImg, Col, Row } from 'reactstrap';
import {
  getFormattedPhoneNumber,
  getWorkerFullName,
  getWorkerOrientationStatus,
  getWorkerOrientationStatusBadge,
} from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { getCommentsColumns, getContractorsColumns } from './WorkerOverview.tables';

export function WorkerOverview(props: WorkerTabProps): ReactElement {
  const { loading, worker, workerComments, jobsiteWorkers, contractorWorkers } = props;
  const [hideSsn, setHideSsn] = useState<boolean>(false);

  const activeJobsiteWorkers = jobsiteWorkers?.filter(({ endDate }) => {
    return endDate == null || new Date(endDate) > new Date();
  });

  const commentsColumns = React.useMemo(() => getCommentsColumns(), []);
  const contractorsColumns = React.useMemo(() => getContractorsColumns(), []);

  return (
    <Row>
      <Col lg="4">
        <Card className="pt-4">
          <CardImg
            className="m-auto w-50"
            src={worker?.profilePictureCropped?.downloadUrl || workerImage}
            alt="Card image cap"
          />
          <div className="card-body text-center">
            <h2 className="card-title">{getWorkerFullName(worker)}</h2>
            <p className="small text-muted mb-0 text-gray-700">{worker?.trade}</p>
          </div>
        </Card>
        {activeJobsiteWorkers.length ? (
          <Card>
            <CardHeader>
              <h4 className="card-header-title mr-auto">Job Site Orientations</h4>
            </CardHeader>
            <CardFooter>
              {activeJobsiteWorkers?.map((jobsiteWorker) => {
                return (
                  <div key={jobsiteWorker.jobsiteWorkerId}>
                    <h2 className="m-0">{jobsiteWorker?.jobsiteContractor?.jobsite?.name}</h2>
                    <h2 className="mt-2 mb-0">
                      <Badge className={getWorkerOrientationStatusBadge(jobsiteWorker?.orientationStatus)}>
                        {getWorkerOrientationStatus(jobsiteWorker?.orientationStatus)}
                      </Badge>
                    </h2>
                    <p className="text-gray-600 mt-2 mb-0">
                      Orientation Date: {getISODateTimeToFormattedDate(jobsiteWorker?.orientationDate)}
                    </p>
                  </div>
                );
              })}
            </CardFooter>
          </Card>
        ) : null}
      </Col>
      <Col lg="8">
        <Card>
          <CardHeader>
            <h4 className="card-header-title mr-auto">Personal</h4>
          </CardHeader>
          <div className="card-footer card-footer-boxed">
            <Row>
              <Col lg="6">
                <Row className="mb-3">
                  <Col md="6">Date of Birth</Col>
                  <Col md="6" className="text-gray-600">
                    {getISODateTimeToFormattedDate(worker?.birthDate)}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">
                    Last 4 of SSN{' '}
                    <i
                      className={cn('fe print:odin-hidden text-primary', {
                        'fe-eye': !hideSsn,
                        'fe-eye-off': hideSsn,
                      })}
                      onClick={(): void => setHideSsn(!hideSsn)}
                    />
                  </Col>
                  <Col md="6" className="text-gray-600">
                    {!hideSsn ? worker?.ssnLastFour : null}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Race</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.race}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Gender</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.gender}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Primary Language</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.primaryLanguage}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Veteran Status</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.isVeteran ? 'Yes' : 'No'}
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row className="mb-3">
                  <Col md="6">Address</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.addressLine1} {worker?.addressLine2}
                    <br />
                    {worker?.addressCity}, {worker?.addressState} {worker?.addressZipCode}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Phone</Col>
                  <Col md="6" className="text-gray-600">
                    {getFormattedPhoneNumber(worker?.user?.identity?.phoneNumber)}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Email address</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.user?.identity?.email}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Emergency Contact</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.emergencyContactName}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Emergency Relationship</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.emergencyContactRelationship}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Emergency Phone</Col>
                  <Col md="6" className="text-gray-600">
                    {getFormattedPhoneNumber(worker?.emergencyContactPhone)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card>
        <Card>
          <CardHeader>
            <h4 className="card-header-title mr-auto">Work</h4>
          </CardHeader>
          <div className="card-footer card-footer-boxed">
            <Row>
              <Col md="6">
                <Row className="mb-3">
                  <Col md="6">Trade</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.trade}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Job Title</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.jobTitle}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">Union affiliation</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.unionAffiliation}
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row className="mb-3">
                  <Col md="6">Skilled Labor</Col>
                  <Col md="6" className="text-gray-600">
                    {worker?.isSkilled ? 'Yes' : 'No'}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card>
        <Card>
          <CardHeader className="!odin-border-b-0">
            <h4 className="card-header-title mr-auto">Comments</h4>
          </CardHeader>
          <div className="card-footer card-footer-boxed m-0 p-0">
            <Table
              loading={loading}
              columns={commentsColumns}
              data={workerComments}
              remote
              cellClassName="!odin-pl-5"
              disableGlobalFilter
              disableSortBy
            />
          </div>
        </Card>
        <Card>
          <CardHeader className="!odin-border-b-0">
            <h4 className="card-header-title mr-auto">Employer</h4>
          </CardHeader>
          <div className="card-footer card-footer-boxed m-0 p-0">
            <Table
              loading={loading}
              columns={contractorsColumns}
              data={contractorWorkers}
              remote
              disableGlobalFilter
              disableSortBy
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
}
