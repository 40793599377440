import { createBrowserHistory, History } from 'history';
import { initializeLoggingConfig } from 'config/logging';
import { AppConfig } from 'config/types';
import { Maybe } from 'types';

export const initializeApp = (appConfig: AppConfig): { history: History } => {
  initializeLoggingConfig({
    logLevel: appConfig.logLevel,
    datadogEnabled: appConfig.ddEnabled,
    appVersion: appConfig.appBuildVersion,
  });

  const history = createBrowserHistory();
  let previousPathname: Maybe<string> = null;
  history.listen((location) => {
    // scrolls user to top of page if its a new page
    if (previousPathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
    previousPathname = location.pathname;
  });

  return { history };
};
