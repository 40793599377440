import React from 'react';
import { AuthContext } from 'auth';

export function LoginContainer(): React.ReactElement {
  const authContext = React.useContext(AuthContext);

  const loginUrl = authContext?.authState?.getLoginUrl();
  window.location.href = loginUrl;

  return <span>Redirecting to login page ...</span>;
}
