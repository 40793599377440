import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    fontFamily: 'IBM Plex Sans',
    paddingVertical: 50,
    paddingHorizontal: 30,
    justifyContent: 'space-between',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: { width: 121, height: 42 },
  headerRight: {},
  jobsite: { fontWeight: 'medium', alignSelf: 'flex-end' },
  contractor: { marginTop: 5, alignSelf: 'flex-end' },
  body: {},
  title: {
    marginTop: 20,
    fontSize: 28,
    fontWeight: 'bold',
  },
  subtitle: { marginTop: 15, marginBottom: 20, fontSize: 21 },
  stepContainer: {
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  stepNumberHolder: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 22,
    backgroundColor: '#3e5bfe',
  },
  stepNumber: {
    color: '#fff',
  },
  stepText: {
    paddingLeft: 20,
    flex: 1,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  scanToBegin: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 16,
    color: '#3e5bfe',
    backgroundColor: '#e3f0ff',
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginRight: 20,
    borderRadius: 18,
  },
  scanToBeginArrow: {
    marginLeft: 5,
    width: 12,
    height: 12,
    color: '#3e5bfe',
  },
  qrCode: { width: 140, height: 140 },
});
