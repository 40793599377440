import React from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  formatArrayForUrl,
  parsePageQueryParams,
  formatTimeForUrl,
  momentISODateFormatter,
  PageSearchParams,
  PageUrlUpdateParams,
} from 'utils';
import { Entries } from 'types';
import { useJobsitesSelection } from 'graphql/client/useJobsitesSelection';

export type UsePageQueryParamsArgs = {
  updateJobsiteSelection?: boolean;
  includeAllJobsites?: boolean;
  defaultValues?: PageSearchParams;
  inUrlKeys?: (keyof PageSearchParams)[];
};

export type UsePageQueryParamsResult = PageSearchParams & {
  updateUrl: (params: PageSearchParams, shouldReplace?: boolean, hash?: string) => void;
  /**
   * If this is `true` it means that `params` are not completely initialized.
   * Calling components should wait to query for data based on `params` until `loading` is `false`.
   */
  loading: boolean;
};

const defaultInUrlKeys: (keyof PageSearchParams)[] = ['date', 'startDate', 'endDate'];
const initialDefaultValues: PageSearchParams = {};

const getUpdValue = (
  key: string,
  value: string | number | boolean | string[] | moment.Moment,
): string | null | undefined => {
  if (value == null) {
    return value as null | undefined;
  }
  if (typeof value === 'string') {
    if (key.endsWith('Time')) {
      return formatTimeForUrl(value);
    }
    return value;
  }
  if (moment.isMoment(value)) {
    return value.format(momentISODateFormatter);
  }
  if (Array.isArray(value)) {
    return formatArrayForUrl(value);
  }
  return value ? value.toString() : null;
};

export function usePageQueryParams(args?: UsePageQueryParamsArgs): UsePageQueryParamsResult {
  const { updateJobsiteSelection, includeAllJobsites } = args ?? {};
  const defaultValues: PageSearchParams = { ...initialDefaultValues, ...args?.defaultValues };
  const inUrlKeys = args?.inUrlKeys ?? defaultInUrlKeys;

  const location = useLocation();
  const history = useHistory();
  const {
    selectedJobsiteIds,
    getSelectedJobsiteIds,
    setSelectedJobsiteIds,
    loading: jobsitesSelectionLoading,
  } = useJobsitesSelection({ includeAllJobsites });

  const getUrlParams = (): { urlParams: PageSearchParams; defaultsToBeSetKeys: (keyof PageSearchParams)[] } => {
    const urlParams = parsePageQueryParams(location);

    // get keys for which defaults are to be applied
    const defaultsToBeSetKeys = (Object.entries(defaultValues) as Entries<PageSearchParams>)
      .filter(([key, defaultValue]) => inUrlKeys.includes(key) && !!defaultValue && !urlParams[key])
      .map(([key]) => key);

    const defaults =
      defaultsToBeSetKeys && Object.fromEntries(defaultsToBeSetKeys.map((key) => [key, defaultValues[key]]));

    return {
      urlParams: { ...urlParams, ...defaults }, // url params including defaults
      defaultsToBeSetKeys: defaultsToBeSetKeys.length ? defaultsToBeSetKeys : undefined,
    };
  };

  const { urlParams, defaultsToBeSetKeys } = React.useMemo(() => getUrlParams(), [location.search]);
  const { page, secondaryPage, ...restUrlParams } = urlParams;

  const checkAgainstDefault = (key: keyof PageSearchParams, defaultValue: string, value: string): string => {
    return inUrlKeys.includes(key) || value !== defaultValue ? value : null;
  };

  const shouldUpdateJobsiteSelection = (jobsiteIds: string[]): boolean => {
    return (
      jobsiteIds !== undefined &&
      JSON.stringify([...(jobsiteIds ?? [])].sort()) !== JSON.stringify([...(selectedJobsiteIds ?? [])].sort())
    );
  };

  const updateUrl = (params: PageSearchParams, shouldReplace?: boolean, hash?: string): void => {
    // clear jobsites selection if needed
    if (!shouldReplace && updateJobsiteSelection && shouldUpdateJobsiteSelection(params.jobsiteIds)) {
      setSelectedJobsiteIds(params.jobsiteIds);
    }

    const updParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value !== undefined));

    const newValues = Object.fromEntries(
      (Object.entries({ ...restUrlParams, ...updParams }) as Entries<PageSearchParams>).map(([key, value]) => [
        key,
        checkAgainstDefault(key, getUpdValue(key, defaultValues[key]), getUpdValue(key, value)),
      ]),
    );

    const queryParams: PageUrlUpdateParams = {
      page: page ? page + 1 : null,
      secondaryPage: secondaryPage ? secondaryPage + 1 : null,
      ...newValues,
    };

    const goTo = shouldReplace ? history.replace : history.push;
    const path = `${location.pathname}?${queryString.stringify(queryParams, {
      skipEmptyString: true,
      skipNull: true,
    })}`;
    goTo(path + (hash ?? ''));
  };

  // This flag is used when the url is going to be updated with selected jobsites
  // When `true`, it means that global jobsite selection has been applied to the url
  // and params are in final state.
  const [isInitialized, setIsInitialized] = React.useState(false);

  // add defaults within URL if they don't exist
  React.useEffect(() => {
    if (defaultsToBeSetKeys) {
      const defaultsToBeShownInUrl = Object.fromEntries(
        defaultsToBeSetKeys.map((key) => [key, getUpdValue(key, defaultValues[key])]),
      );
      updateUrl(defaultsToBeShownInUrl, true);
    }
  }, [defaultsToBeSetKeys]);

  const { jobsiteId, jobsiteIds } = urlParams;
  const urlJobsiteIds = [jobsiteId, ...(jobsiteIds ?? [])].filter(Boolean).sort();

  // update url with valid jobsiteIds
  React.useEffect(() => {
    if (!jobsitesSelectionLoading && updateJobsiteSelection && urlJobsiteIds.length) {
      const validSelectedJobsiteIds = setSelectedJobsiteIds(urlJobsiteIds);
      if (jobsiteId || jobsiteIds?.length !== validSelectedJobsiteIds?.length) {
        updateUrl({ jobsiteId: null, jobsiteIds: validSelectedJobsiteIds }, true);
      }
    }
  }, [jobsitesSelectionLoading, urlJobsiteIds.length]);

  // update url based on selected jobsites
  React.useEffect(() => {
    if (!jobsitesSelectionLoading && updateJobsiteSelection && !urlJobsiteIds.length) {
      // get the immediate selection, right after setSelectedJobsiteIds
      // this ensures that we have most updated selection if updateUrl was called before
      const selJobsiteIds = getSelectedJobsiteIds();
      if (selJobsiteIds?.length) {
        updateUrl({ jobsiteId: null, jobsiteIds: selJobsiteIds }, true);
        return;
      }
    }
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [jobsitesSelectionLoading, location.pathname, location.search]);

  return {
    ...urlParams,
    updateUrl,
    loading: jobsitesSelectionLoading || !isInitialized,
  };
}
