import React from 'react';
import { FormSubmissionWorkerEditSearchBox } from './FormSubmissionWorkerEditSearchBox';
import { FormSubmissionWorkerEditSearchResults } from './FormSubmissionWorkerEditSearchResults';
import { useWorkerSearchData } from './useWorkerSearchData';
import { FormSubmissionWorkerEditSearchInfo } from './FormSubmissionWorkerEditSearchInfo';
import { FormSubmissionWorkerEditSearchInProgress } from './FormSubmissionWorkerEditSearchInProgress';
import { FormSubmissionWorkerEditSearchProps } from './types';

export function FormSubmissionWorkerEditSearch(props: FormSubmissionWorkerEditSearchProps): React.ReactElement {
  const { jobsiteFormSubmissionId, onSelected } = props;
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const { searchWorkerResults, loading } = useWorkerSearchData(jobsiteFormSubmissionId, searchTerm);

  const getContent = (): React.ReactElement => {
    if (loading) {
      return <FormSubmissionWorkerEditSearchInProgress />;
    }

    if (searchWorkerResults?.length) {
      return (
        <FormSubmissionWorkerEditSearchResults searchWorkerResults={searchWorkerResults} onSelected={onSelected} />
      );
    }

    return <FormSubmissionWorkerEditSearchInfo />;
  };

  return (
    <div className="odin-space-y-6">
      <FormSubmissionWorkerEditSearchBox onSearchTermChange={setSearchTerm} />
      {getContent()}
    </div>
  );
}
