import React, { ReactElement } from 'react';
import moment from 'moment';
import { Filter, NewButton, Table, TableContainer } from '@odin-labs/components';
import { useGetWorkerJobsiteAccessSessionsQuery } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { ArrowToBottomIcon } from 'components/icons';
import { LoadingError } from 'components/loadingError';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { WorkerTabProps } from 'containers/worker/types';
import { getLookerReportUrl } from 'looker';
import { LookerFilter, LookerReport, LookerTimeRangeType } from 'looker/types';
import { getCurrentISOFormattedDate, momentISODateFormatter } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { NewHeader } from 'components/header/NewHeader';
import { getColumns, getFilterItems } from './tables';
import { WorkerJobsiteAccessSessionsFilters } from './types';

export function WorkerTimecards(props: WorkerTabProps): ReactElement {
  const { worker, jobsiteNames, isLocked, refetchWorkerData, onTabApiChange } = props;

  const defaultStartDate = moment().subtract(30, 'days');
  const defaultEndDate = moment(getCurrentISOFormattedDate());
  const { currentUser: user } = React.useContext(AuthContext);

  const isFeatureLocked = !user.hasPaidAccess;

  const { page, jobsiteIds, contractorIds, compliant, startDate, endDate, updateUrl } = usePageQueryParams({
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  });

  const { data, loading, error } = useGetWorkerJobsiteAccessSessionsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !startDate || !endDate || !worker || worker?.isArchived || isLocked || isFeatureLocked,
    variables: {
      workerId: worker.workerId,
      workerJobsiteAccessSessionsInput: {
        jobsiteIds,
        contractorIds,
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
        compliant: compliant ? compliant.toLowerCase() === 'yes' : null,
        paginationInput: {
          limit,
          offset: limit * page,
        },
      },
    },
  });

  const workerJobsiteAccessSessionsData = data?.getWorker.jobsiteAccessSessions;
  const workerJobsiteAccessSessions = workerJobsiteAccessSessionsData?.edges.map(({ node }) => node);
  const { filtersOptions } = workerJobsiteAccessSessionsData ?? {};
  const workerJobsiteAccessSessionsCount = workerJobsiteAccessSessionsData?.count ?? 0;
  const pageCount = Math.ceil(workerJobsiteAccessSessionsCount / limit);

  const columns = React.useMemo(() => getColumns(), []);
  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        filtersOptions,
        jobsiteIds,
        contractorIds,
        compliant,
        startDate,
        endDate,
      }),
    [filtersOptions, jobsiteIds, contractorIds, compliant, startDate, endDate],
  );

  const lookerReportUrl = getLookerReportUrl(LookerReport.JobsiteAccessHistoryCompliance, {
    [LookerFilter.Jobsite]: jobsiteNames.join(),
    [LookerFilter.TimeRangeType]: LookerTimeRangeType.DateRange,
    [LookerFilter.StartDate]: startDate?.format(momentISODateFormatter),
    [LookerFilter.EndDate]: endDate?.format(momentISODateFormatter),
    [LookerFilter.WorkerQuickCode]: worker.quickCode,
  });

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  const onFilterChangeHandler = (changedFilters: Partial<WorkerJobsiteAccessSessionsFilters>): void => {
    const { dateRange: dateRangeFilter, ...restChangedFilters } = changedFilters;
    const dateRange = dateRangeFilter === null ? { startDate: null, endDate: null } : dateRangeFilter;
    updateUrl({ page: null, ...dateRange, ...restChangedFilters });
  };

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  if (isFeatureLocked) {
    return <LockedFeatureAlert />;
  }

  return (
    <TableContainer>
      <NewHeader
        size="md"
        title="Timecards"
        titleInfo={workerJobsiteAccessSessionsCount}
        actionsProps={{ headerActions: null }}
      />
      <Filter items={filterItems} loading={loading} onChange={onFilterChangeHandler}>
        {!user.isContractor && (
          <NewButton
            as="a"
            href={lookerReportUrl}
            target="_blank"
            theme="white"
            size={['base', 'md:xs']}
            text="Download"
            hideTextOnMobile
            icon={ArrowToBottomIcon}
          />
        )}
      </Filter>
      <Table
        loading={loading}
        columns={columns}
        data={workerJobsiteAccessSessions}
        initialState={{ pageSize: limit }}
        pageCount={pageCount}
        pageIndex={page}
        remote
        enablePagination
        onPageChange={onPageChangeHandler}
        cellClassName="!odin-pl-5"
        disableGlobalFilter
        disableSortBy
      />
    </TableContainer>
  );
}
