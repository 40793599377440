import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';

import { getIcon } from 'utils/ui';
import { DeleteDocumentFileModalProps } from './types';

const TrashIcon = getIcon('fal fa-trash');

export function DeleteDocumentFileModal(props: DeleteDocumentFileModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, documentFile } = props;

  const onAction = (): void => {
    onConfirm?.(documentFile);
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title={`Delete "${documentFile?.language}" file?`}
      titleAlignment="center"
      textAlignment="center"
      onAction={onAction}
      actionText="Delete File"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
    />
  );
}
