import React from 'react';
import moment from 'moment';
import { faUserHardHat } from '@fortawesome/pro-light-svg-icons';
import { NewButton, getFaIcon, range } from '@odin-labs/components';
import {
  useGetAvailableJobsiteWorkersQuery,
  useGetFormSubmissionJobsiteWorkersQuery,
} from 'apollo/generated/client-operations';
import { EditType } from 'types';
import { getTempId, useBoolean } from 'utils';
import { AlertInstance } from 'components/alertNotification';
import { AvailableJobsiteWorker, EnhancedFormSubmissionWorker } from 'containers/jobsiteFormSubmission/types';
import { AddFormSubmissionWorkerModal } from './modals';
import { EditableWorker, EditableEnhancedWorker, FormSubmissionEditableWorkersProps } from './types';
import { FormSubmissionEditableWorker } from './FormSubmissionEditableWorker';

const UserHardHatIcon = getFaIcon({ icon: faUserHardHat });

export const toEditableJfsWorker = ({
  jfsWorker,
  onSiteJw,
  editedJfsWorker,
}: {
  jfsWorker?: EnhancedFormSubmissionWorker;
  onSiteJw?: AvailableJobsiteWorker;
  editedJfsWorker?: EditableWorker;
}): EditableEnhancedWorker => {
  return {
    ...jfsWorker,
    ...editedJfsWorker,
    jobsiteWorker: {
      ...jfsWorker?.jobsiteWorker,
      ...onSiteJw,
      contractorWorker: {
        ...jfsWorker?.jobsiteWorker.contractorWorker,
        ...onSiteJw?.contractorWorker,
      },
    },
  };
};

export const FormSubmissionEditableWorkers = React.forwardRef<HTMLDivElement, FormSubmissionEditableWorkersProps>(
  (props, ref): React.ReactElement => {
    const {
      value,
      onChange,
      jobsiteFormSubmissionId,
      jobsiteId,
      contractorIds,
      date,
      associationType,
      dropDownOptions,
    } = props;

    const {
      value: isAddFormSubmissionWorkerModalOpen,
      setTrue: openAddFormSubmissionWorkerModal,
      setFalse: closeAddFormSubmissionWorkerModal,
    } = useBoolean(false);

    const { data: onSiteWorkersData, loading: onSiteWorkersLoading } = useGetAvailableJobsiteWorkersQuery({
      fetchPolicy: 'no-cache',
      skip: !jobsiteId,
      variables: {
        jobsiteId,
        jobsiteJobsiteWorkersInput: {
          contractorIds,
          onSite: true,
          onSiteDate: date ? moment.utc(date).toDate() : null,
        },
      },
    });

    const { data: jfsWorkersData, loading: jfsWorkersLoading } = useGetFormSubmissionJobsiteWorkersQuery({
      fetchPolicy: 'no-cache',
      skip: !jobsiteFormSubmissionId,
      variables: { id: jobsiteFormSubmissionId, jfsInput: { reverseChronological: true } },
    });

    const { visibleJfsWorkers } = React.useMemo<{
      visibleJfsWorkers: EditableEnhancedWorker[];
      onSiteJobsiteWorkerIds: string[];
    }>(() => {
      const onSiteJobsiteWorkers = onSiteWorkersData?.getJobsite.jobsiteWorkers.edges.map(({ node }) => node) ?? [];
      const jfsWorkers = jfsWorkersData?.getJobsiteFormSubmission.jobsiteWorkers.edges.map(({ node }) => node) ?? [];
      const jfsWorkersByJwId = Object.fromEntries(
        jfsWorkers.map((jfsWorker) => [jfsWorker.jobsiteWorker.jobsiteWorkerId, jfsWorker]) ?? [],
      );

      const editedJfsWorkersByJwId = Object.fromEntries(
        value
          .filter((jfsW) => jfsW.changeType === 'created' || jfsW.changeType === 'updated')
          .map((jfsW) => [jfsW.jobsiteWorker.jobsiteWorkerId, jfsW]) ?? [],
      );

      const onSiteJfsWorkers = onSiteJobsiteWorkers.map((onSiteJw) =>
        toEditableJfsWorker({
          onSiteJw,
          jfsWorker: jfsWorkersByJwId[onSiteJw.jobsiteWorkerId],
          editedJfsWorker: editedJfsWorkersByJwId[onSiteJw.jobsiteWorkerId],
        }),
      );

      const onSiteJwIds = onSiteJobsiteWorkers.map((jw) => jw.jobsiteWorkerId) ?? [];
      const orphanJfsWorkers = jfsWorkers
        .filter((jfsWorker) => !onSiteJwIds.includes(jfsWorker.jobsiteWorker.jobsiteWorkerId))
        .map((jfsWorker) =>
          toEditableJfsWorker({
            jfsWorker,
            editedJfsWorker: editedJfsWorkersByJwId[jfsWorker.jobsiteWorker.jobsiteWorkerId],
          }),
        );

      const newJfsWorkers =
        value?.filter(
          (jfsW): jfsW is EditableEnhancedWorker =>
            jfsW.changeType === 'created' && !onSiteJwIds.includes(jfsW.jobsiteWorker.jobsiteWorkerId),
        ) ?? [];

      return {
        onSiteJobsiteWorkerIds: onSiteJwIds,
        visibleJfsWorkers: [...newJfsWorkers, ...orphanJfsWorkers, ...onSiteJfsWorkers].filter(
          (jfsW) => jfsW.changeType !== 'removed',
        ),
      };
    }, [onSiteWorkersData, jfsWorkersData, value]);

    const updateValue = React.useCallback(
      (jfsWorker: EditableEnhancedWorker, editType: EditType): void => {
        const { jobsiteWorkerId } = jfsWorker.jobsiteWorker;
        let newValue: EditableWorker[];
        if (editType === 'create' || (editType === 'update' && !jfsWorker.id)) {
          const existingJfsW = value?.find((jfsW) => {
            return jfsW.jobsiteWorker.jobsiteWorkerId === jobsiteWorkerId;
          });
          // if jfsW has been previously removed then it will be updated
          if (existingJfsW?.changeType === 'removed') {
            newValue = value.map((jfsW) =>
              // existing jfsW id is preserved
              jfsW.id !== existingJfsW.id ? jfsW : { ...jfsW, ...jfsWorker, id: jfsW.id, changeType: 'updated' },
            );
          } else if (existingJfsW) {
            AlertInstance.alert('tc', 'danger', '', 'Worker has already been assigned!');
          } else {
            // add new participant
            newValue = [...value, { id: getTempId(), ...jfsWorker, changeType: 'created', associationType }];
          }
        } else {
          newValue = value.map((jfsW) => (jfsW.id !== jfsWorker.id ? jfsW : { ...jfsW, ...jfsWorker }));
        }
        if (newValue) {
          onChange?.(newValue);
        }
      },
      [onChange, value],
    );

    const updateValueAndCloseModal = React.useCallback(
      (jfsWorker: EditableEnhancedWorker): void => {
        updateValue(jfsWorker, 'create');
        closeAddFormSubmissionWorkerModal();
      },
      [updateValue, closeAddFormSubmissionWorkerModal],
    );

    // const removeWorker = (jfsWorker: EditableEnhancedWorker): void => {
    //   const newValue: EditableWorker[] =
    //     jfsWorker.changeType === 'created'
    //       ? value.filter((p) => p.id !== jfsWorker.id)
    //       : value.map((p) => (p.id !== jfsWorker.id ? p : { ...p, changeType: 'removed' }));
    //   onChange?.(newValue);
    // };

    const loading = onSiteWorkersLoading || jfsWorkersLoading;
    const workersList = (
      <div className="odin-flex odin-flex-col odin-gap-y-5">
        {loading
          ? range(10, 0).map((index) => <FormSubmissionEditableWorker key={index} loading />)
          : visibleJfsWorkers.map((jfsWorker) => (
              <FormSubmissionEditableWorker
                key={jfsWorker.jobsiteWorker.jobsiteWorkerId}
                jfsWorker={jfsWorker}
                updateValue={updateValue}
                dropDownOptions={dropDownOptions}
              />
            ))}
      </div>
    );

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-gap-y-9">
          <NewButton
            text="Add Worker to Report"
            theme="dashed"
            className="odin-w-full odin-justify-center odin-h-20"
            icon={UserHardHatIcon}
            onClick={(): void => openAddFormSubmissionWorkerModal()}
          />
          {workersList}
        </div>
        <AddFormSubmissionWorkerModal
          isOpen={isAddFormSubmissionWorkerModalOpen}
          closeModal={closeAddFormSubmissionWorkerModal}
          onConfirm={updateValueAndCloseModal}
          jobsiteId={jobsiteId}
          jobsiteFormSubmissionId={jobsiteFormSubmissionId}
          associationType={associationType}
        />
      </>
    );
  },
);
